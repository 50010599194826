import { InviteValueProp } from './types'
import personAtComputerS from '../../assets/images/graphics/people/person-at-computer-S.webp'
import personAtComputerL from '../../assets/images/graphics/people/person-at-computer-L.webp'
import personDaydreamingS from '../../assets/images/graphics/people/person-daydreaming-S.webp'
import personDaydreamingL from '../../assets/images/graphics/people/person-daydreaming-L.webp'
import personJugglingBooksS from '../../assets/images/graphics/people/person-juggling-books-S.webp'
import personJugglingBooksL from '../../assets/images/graphics/people/person-juggling-books-L.webp'
import personReadingWithChatS from '../../assets/images/graphics/people/person-reading-w-chat-S.webp'
import personReadingWithChatL from '../../assets/images/graphics/people/person-reading-w-chat-L.webp'

export const referralIdParamKey = 'referralID'
export const referralStorageKey = 'referralStorageKey'

export const inviteValueProps: InviteValueProp[] = [
  {
    imageS: personAtComputerS,
    imageL: personAtComputerL,
    altText: 'person at a computer',
    title: 'A place to read and discuss with friends',
    subtitle:
      'Find your reading community with access to hundreds of unique book clubs. Or start your own to read with friends, family, and colleagues.',
  },
  {
    imageS: personDaydreamingS,
    imageL: personDaydreamingL,
    altText: 'person daydreaming',
    title: 'Designed for social reading',
    subtitle:
      'Our app is designed to make it easy to read together. Share your thoughts, annotations, highlights, and start discussions right inside the book—without worrying about spoilers.',
  },
  {
    imageS: personReadingWithChatS,
    imageL: personReadingWithChatL,
    altText: 'person reading with chat',
    title: 'Better book lists',
    subtitle:
      'Rate, review, and create beautiful book lists easily to keep track of your reading.',
  },
  {
    imageS: personJugglingBooksS,
    imageL: personJugglingBooksL,
    altText: 'person reading with chat',
    title: 'Book recommendations from tastemakers',
    subtitle:
      'Discover great new books with recommendations from authors, experts, tastemakers, and industry titans.',
  },
]
