import { useTheme } from '@fable/theme'
import { Text } from '@fable/components'

const GiftItemTitle = ({ text }: { text: string }) => {
  const { colors } = useTheme()

  return (
    <Text type="body" sizing="S" color={colors.greyNeutral}>
      <b>{text}</b>
    </Text>
  )
}

export default GiftItemTitle
