import { IconTypes } from '../icon'

export const getSocialLinks = <T>({
  profile,
  suffix = '_url',
}: {
  profile: T
  suffix?: string
}) => {
  if (!profile) return []

  const socialNetworks = [
    'facebook',
    'goodreads',
    'instagram',
    'linkedin',
    'tiktok',
    'twitter',
    'youtube',
    'website',
  ]

  const links = socialNetworks
    .filter((item) => !!profile[`${item}${suffix}` as keyof T])
    .map((item) => {
      return {
        id: item as IconTypes,
        url: profile[`${item}${suffix}` as keyof T] as string,
      }
    })

  return links
}
