import { css } from '@fable/theme'
import { PlanSelection } from './PlanSelection'
import { PlanSelectionHero } from './PlanSelectionHero'
import { PlanSelectionProps } from './types'

interface Props extends PlanSelectionProps {
  className?: string
  small?: boolean
}

export const PlanUpsell = ({
  className = '',
  small,
  ...nativeProps
}: Props) => (
  <div className={className}>
    <PlanSelectionHero small={small} />
    <div
      className={css`
        margin: -100px auto 0;
        max-width: 480px;
      `}
    >
      <PlanSelection {...nativeProps} />
    </div>
  </div>
)
