import { BooklistBooksResponse } from '@fable/types'
import { get } from '../fable'

const getBook = async (isbn: string) => await get(`/books/${isbn}/`)
const getOwnedBooks = async ({
  includePreorder,
}: {
  includePreorder?: boolean
}) =>
  await get(
    `/v2/books/owned/${
      includePreorder ? '?include=preorder&include=owned' : ''
    }`
  )

const getSimilarBooks = async (bookId: string) =>
  await get(`/books/${bookId}/similar_books`)

const getGenres = async () => await get('/genres?count=true')

const getBookList = async (id: string) =>
  await get(`/book_lists/${id}?include_user=true`)

const getBookListBooks = async ({
  id,
  pageParam,
  count = 0,
}: {
  id: string
  pageParam?: string
  count?: number
}) => {
  let url = `/book_lists/${id}/books?limit=20`
  if (count > 20) url += '&offset=20'
  if (!!pageParam?.length) url = pageParam

  return (await get(url)) as BooklistBooksResponse
}

export const book = {
  getBook,
  getSimilarBooks,
  getOwnedBooks,
  getGenres,
  getBookList,
  getBookListBooks,
}
