import { routes as storeRoutes } from '../../app/store/routes'
import { routes as marketingRoutes } from '../../app/marketing/routes'
import { routes as communityRoutes } from '../../app/community/routes'
import { HeaderNavItem } from './types'
import { CAREERS_LINK, FAQ_LINK, START_A_CLUB_LINK } from '../../constants'
import { builderIoRoutes } from '../../routes/builderIoRoutes'

export const PAGE_LINKS = {
  joinClub: {
    title: 'Join a club',
    path: marketingRoutes.clubsPage,
  },
  joinBookClub: {
    title: 'Join a book club',
    path: marketingRoutes.home,
  },
  startClub: {
    title: 'Start a book club',
    path: START_A_CLUB_LINK,
  },
  premiumClubs: {
    title: 'Premium book clubs',
    path: marketingRoutes.premiumClubs,
  },
  findBook: {
    title: 'Bookstore',
    path: storeRoutes.explore,
  },
  folios: {
    title: 'Folios',
    path: storeRoutes.folios,
  },
  genres: {
    title: 'Genres',
    path: storeRoutes.genres,
  },
  blog: {
    title: 'Blog',
    path: communityRoutes.blog,
  },
  browseAllBooks: {
    title: 'Browse all',
    path: storeRoutes.explore,
  },
  browseAllClubs: {
    title: 'Browse all',
    path: marketingRoutes.home,
  },
  about: {
    title: 'About',
    path: marketingRoutes.company,
  },
  business: {
    title: 'For Business',
    path: marketingRoutes.b2b,
  },
  education: {
    title: 'For Education',
    path: marketingRoutes.education,
  },
  events: {
    title: 'Events',
    path: communityRoutes.events,
  },
  support: {
    title: 'Help & Support',
    path: marketingRoutes.support,
  },
  company: {
    title: 'Company',
    path: marketingRoutes.company,
  },
  careers: {
    title: 'Careers',
    path: CAREERS_LINK,
    type: 'external',
  },
  communityPolicy: {
    title: 'Community Policy',
    path: marketingRoutes.communityGuidelines,
  },
  press: {
    title: 'Press',
    path: marketingRoutes.press,
  },
  faqs: {
    title: 'FAQs',
    path: FAQ_LINK,
    type: 'external',
  },
  selfPublishing: {
    title: 'Self Publishing',
    path: builderIoRoutes.selfPublishing,
  },
}

export const HEADER_LEFT_NAV_LINKS: HeaderNavItem[] = [
  {
    ...PAGE_LINKS.joinClub,
    /**
     * @note
     * Temporarily hiding for cyber monday changes
     */
    // subItems: [
    //   PAGE_LINKS.premiumClubs,
    //   PAGE_LINKS.careerClubs,
    //   PAGE_LINKS.pricing,
    //   PAGE_LINKS.browseAllClubs,
    // ],
  },
  {
    ...PAGE_LINKS.findBook,
    /**
     * @note
     * Temporarily hiding for cyber monday changes
     */
    // subItems: [
    //   PAGE_LINKS.folios,
    //   PAGE_LINKS.genres,
    //   PAGE_LINKS.browseAllBooks,
    // ],
  },
]

export const HEADER_RIGHT_NAV_LINKS: HeaderNavItem[] = [
  PAGE_LINKS.blog,
  PAGE_LINKS.business,
  PAGE_LINKS.education,
]

export const HEADER_SIDE_MENU_PRIMARY_LINKS_LEGACY: HeaderNavItem[] = [
  PAGE_LINKS.joinBookClub,
  PAGE_LINKS.findBook,
  PAGE_LINKS.blog,
]

export const HEADER_SIDE_MENU_PRIMARY_LINKS: HeaderNavItem[] = [
  PAGE_LINKS.joinClub,
  PAGE_LINKS.findBook,
  PAGE_LINKS.blog,
]

const HEADER_SIDE_MENU_LINKS_LEFT: HeaderNavItem[] = [
  PAGE_LINKS.company,
  PAGE_LINKS.careers,
  PAGE_LINKS.communityPolicy,
  PAGE_LINKS.press,
]

const HEADER_SIDE_MENU_LINKS_RIGHT: HeaderNavItem[] = [
  PAGE_LINKS.business,
  PAGE_LINKS.education,
  PAGE_LINKS.events,
  PAGE_LINKS.support,
  PAGE_LINKS.faqs,
]

export const HEADER_SIDE_MENU_LINKS = [
  {
    title: 'About',
    links: HEADER_SIDE_MENU_LINKS_LEFT,
  },
  {
    title: 'Learn more',
    links: HEADER_SIDE_MENU_LINKS_RIGHT,
  },
]
