import { Builder, withChildren } from '@builder.io/react'
import { Button as FableButton } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { analytics } from 'segment_analytics'
import { useActions } from '../../../../hooks'
import { defaultTextChild } from '../helpers'

interface ButtonColorProps {
  text?: string
  background?: string
}
interface Props {
  children: any
  title: string
  small: boolean
  event: string
  modal: boolean
  colors?: {
    idle?: ButtonColorProps
    hover?: ButtonColorProps
  }
}

const Button = ({
  children,
  title = 'Button',
  small = false,
  event,
  modal = false,
  colors,
}: Props) => {
  const { colors: themeColors, mediaQueries } = useTheme()
  const { openModal } = useActions()

  const getColors = () => {
    const idle = {
      color: colors?.idle?.text || themeColors.whiteFang,
      backgroundColor: colors?.idle?.background || themeColors.graphite,
      borderColor: colors?.idle?.background || themeColors.graphite,
    }

    const hover = {
      color: colors?.hover?.text || themeColors.whiteFang,
      backgroundColor: colors?.hover?.background || themeColors.blackSwan,
      borderColor: colors?.hover?.background || themeColors.blackSwan,
    }

    return { ...idle, '&:hover': hover }
  }

  const handleOnClick = () => {
    if (!!event && !Builder.isEditing && !Builder.isPreviewing) {
      analytics.track(event, { platform: 'web' })
    }

    if (modal) {
      openModal(
        <div
          className={css`
            padding: 0 24px 60px;
            ${mediaQueries.mobileL} {
              min-width: 400px;
              min-height: 400px;
            }
          `}
        >
          {children}
        </div>,
        'm-card'
      )
    }
  }

  return (
    <FableButton
      className={css`
        padding: 0 16px;
        width: auto;
      `}
      onClick={handleOnClick}
      small={small}
      label={title}
      custom={getColors()}
    />
  )
}

const ButtonWithChildren = withChildren(Button)

const colorObject = {
  type: 'object',
  subFields: [
    {
      name: 'background',
      type: 'text',
    },
    {
      name: 'text',
      type: 'text',
    },
  ],
}

export const registerButton = () => {
  Builder.registerComponent(ButtonWithChildren, {
    name: 'Button',
    image:
      'https://tabler-icons.io/static/tabler-icons/icons-png/hand-finger.webp',
    inputs: [
      { name: 'title', type: 'text' },
      { name: 'small', type: 'boolean' },
      { name: 'event', type: 'string' },
      { name: 'modal', type: 'boolean' },
      {
        name: 'colors',
        type: 'object',
        subFields: [
          {
            name: 'idle',
            ...colorObject,
          },
          {
            name: 'hover',
            ...colorObject,
          },
        ],
      },
    ],
    defaultChildren: [defaultTextChild],
  })
}
