import { css, cx, useTheme } from '@fable/theme'

interface Props {
  className?: string
  image: string
  altText: string
}

export const HomeFeaturesIllo = ({ className = '', image, altText }: Props) => {
  const { mediaQueries } = useTheme()

  return (
    <div
      className={cx(
        css`
          margin-bottom: -32px;
          display: block;
          width: 100%;
          position: relative;
          z-index: 3;
          ${mediaQueries.mobileL} {
            width: 60%;
          }
          ${mediaQueries.tablet} {
            width: 100%;
            max-width: 500px;
            right: auto;
          }
        `,
        className
      )}
    >
      <img
        className={css`
          display: block;
          width: 100%;
        `}
        src={image}
        alt={altText}
      />
    </div>
  )
}
