import { IconSVGProps } from '../types'

export const ChevronLeftIcon: React.VFC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    data-testid="chevron-left-icon"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill={fill}
  >
    <path d="M2.82798 4.99999L5.65698 2.17199L4.24298 0.756989L-1.71661e-05 4.99999L4.24298 9.24299L5.65698 7.82799L2.82798 4.99999Z" />
  </svg>
)
