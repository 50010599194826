import './Notification.scss'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { NOTIFICATION_MESSAGES } from '../../constants'

export const Notification = ({ customConfig = {} }) => {
  const [notificationContent, setNotificationContent] = useState({})
  const [showNotification, setShowNotification] = useState(false)
  const { clearClipboard } = useActions()
  const {
    deletedWishlistBook,
    modifyBooklistsState,
    removedBookFromList,
    editedBookList,
    addBookListState,
    removedBookList,
  } = useTypedSelector(({ books }) => books)
  const { newMembership } = useTypedSelector(({ clubs }) => clubs)
  const { copied } = useTypedSelector(({ clipboard }) => clipboard)
  const { cardAdded, cardDeleted, planDeleted } = useTypedSelector(
    ({ billing }) => billing
  )
  const { profileUpdated, newsletter } = useTypedSelector(({ user }) => user)

  const notificationBlock = useRef()

  const createNotificationMessage = useCallback(
    (notification, isPersistant, duration = 3000) => {
      let timer

      setNotificationContent(notification)

      if (!isPersistant) {
        timer = setTimeout(() => {
          setShowNotification(false)
        }, duration)
      }

      return () => {
        clearTimeout(timer)
      }
    },
    []
  )

  useEffect(() => {
    if (customConfig) {
      const { isSingleInstance, content, isPersistant } = customConfig
      if (isSingleInstance) {
        createNotificationMessage(content, isPersistant, 5000)
        setShowNotification(true)
      }
    }
  }, [customConfig, createNotificationMessage])

  // Book saved
  useEffect(() => {
    if (
      !isEmpty(modifyBooklistsState.data) &&
      modifyBooklistsState.data.bookSaved
    ) {
      if (
        modifyBooklistsState.data.bookListIdsToRemove?.length &&
        modifyBooklistsState.data.bookSaved
      ) {
        createNotificationMessage(
          NOTIFICATION_MESSAGES.editedBookListBulk,
          false
        )
      } else {
        createNotificationMessage(NOTIFICATION_MESSAGES.addedBookToList, false)
      }
      setShowNotification(true)
    }
  }, [createNotificationMessage, modifyBooklistsState.data])

  // Book is not saved to any list
  useEffect(() => {
    if (
      !isEmpty(modifyBooklistsState.data) &&
      !modifyBooklistsState.data.bookSaved
    ) {
      createNotificationMessage(
        NOTIFICATION_MESSAGES.removedBookFromList,
        false
      )
      setShowNotification(true)
    }
  }, [modifyBooklistsState.data, createNotificationMessage])

  // Book removed from list
  useEffect(() => {
    if (!isEmpty(removedBookFromList.data)) {
      createNotificationMessage(
        NOTIFICATION_MESSAGES.removedBookFromList,
        false
      )
      setShowNotification(true)
    }
  }, [removedBookFromList.data, createNotificationMessage])

  // Book list edited
  useEffect(() => {
    if (!isEmpty(editedBookList.data)) {
      createNotificationMessage(NOTIFICATION_MESSAGES.editedBookList, false)
      setShowNotification(true)
    }
  }, [editedBookList.data, createNotificationMessage])

  // Book list deleted
  useEffect(() => {
    if (!isEmpty(removedBookList.data)) {
      createNotificationMessage(NOTIFICATION_MESSAGES.deletedBookList, false)
      setShowNotification(true)
    }
  }, [removedBookList.data, createNotificationMessage])

  // Book list created
  useEffect(() => {
    if (!isEmpty(addBookListState.data)) {
      createNotificationMessage(NOTIFICATION_MESSAGES.createdBookList, false)
      setShowNotification(true)
    }
  }, [addBookListState.data, createNotificationMessage])

  // Book deleted from wishlist
  useEffect(() => {
    if (!isEmpty(deletedWishlistBook.data)) {
      createNotificationMessage(
        NOTIFICATION_MESSAGES.removedFromWishlist,
        false
      )
      setShowNotification(true)
    }
  }, [deletedWishlistBook.data, createNotificationMessage])

  // Club joined
  useEffect(() => {
    if (!isEmpty(newMembership.data)) {
      createNotificationMessage(NOTIFICATION_MESSAGES.clubJoined, false)
      setShowNotification(true)
    }
  }, [newMembership.data, createNotificationMessage])

  // Text copied to clipboard
  useEffect(() => {
    if (copied) {
      createNotificationMessage(NOTIFICATION_MESSAGES.copiedToClipboard, false)
      setShowNotification(true)
    }

    return () => {
      if (copied) {
        clearClipboard()
      }
    }
  }, [copied, createNotificationMessage, clearClipboard])

  useEffect(() => {
    const isCardEdited = !isEmpty(cardAdded.data) && cardAdded.data.isEdited
    const isCardDeleted =
      !isEmpty(cardDeleted.data) && cardDeleted.data.isDeleted
    const isProfileUpdated =
      !isEmpty(profileUpdated.data) && profileUpdated.data.isUpdated
    const isPlanDeleted =
      !isEmpty(planDeleted.data) && planDeleted.data.isDeleted

    if (isCardEdited || isCardDeleted || isProfileUpdated || isPlanDeleted) {
      createNotificationMessage(NOTIFICATION_MESSAGES.infoUpdated, false)
      setShowNotification(true)
    }
  }, [
    cardAdded.data,
    cardDeleted.data,
    profileUpdated.data,
    planDeleted.data,
    createNotificationMessage,
  ])

  useEffect(() => {
    if (newsletter.data && newsletter.data.isSubscribed) {
      createNotificationMessage(
        NOTIFICATION_MESSAGES.newsletterSubscribed,
        false
      )
      setShowNotification(true)
    }
  }, [newsletter.data, createNotificationMessage])

  const active = showNotification ? 'active' : ''

  const renderSingleInstance = () => {
    const { type } = customConfig

    return (
      <div className={`notification ${active} ${type}`} ref={notificationBlock}>
        <div className="notification-icon">
          <img src={notificationContent.icon} alt="Notification Icon" />
        </div>
        <div
          className="notification-message"
          dangerouslySetInnerHTML={{ __html: notificationContent.message }}
        ></div>
      </div>
    )
  }

  const renderDefaultNotification = () => {
    return (
      <div className={`notification ${active}`} ref={notificationBlock}>
        <div className="notification-icon">
          <img src={notificationContent.icon} alt="Notification Icon" />
        </div>
        <div
          className="notification-message"
          dangerouslySetInnerHTML={{ __html: notificationContent.message }}
        ></div>
      </div>
    )
  }

  return customConfig && customConfig.isSingleInstance
    ? renderSingleInstance()
    : renderDefaultNotification()
}
