import { FlexBox, QuoteView, Text } from '@fable/components'
import { Club, UserPost } from '@fable/types'
import { css, useTheme } from '@fable/theme'
import { useRef } from 'react'
import { ActivityCard } from '../ActivityCard'
import { ActivityPostClub } from './ActivityPostClub'
import { ActivityPostBooks } from './ActivityPostBooks'
import { ActivityPostImage } from './ActivityPostImage'
import { getPostUserProps } from '../helpers'
import { ActivityPostBody } from './ActivityPostBody'

export interface ActivityPostOptions {
  stacked?: boolean
  onCardClick?: () => void
}
interface Props {
  post: UserPost
  onClickLike?: () => void
  onClickComment?: () => void
  showFullPost?: boolean
  options?: ActivityPostOptions
}

export const ActivityPost = ({
  post,
  onClickLike,
  onClickComment,
  showFullPost = false,
  options,
}: Props) => {
  const { mediaQueries, colors } = useTheme()
  const isEditorial = post.content_type === 'editorialPost'
  const testId = isEditorial ? 'editorial-post' : 'user-post'
  const bodyRef = useRef<HTMLDivElement>(null)
  const maxBodyHeight = 312
  const isMaxHeight = bodyRef.current?.clientHeight === maxBodyHeight
  const userProps = getPostUserProps({ post })

  return (
    <ActivityCard
      data-testid={`activity-${testId}`}
      author={userProps}
      counters={post.counters}
      onClickLike={onClickLike}
      onClickComment={onClickComment}
    >
      <FlexBox
        className={css`
          width: 100%;
          ${mediaQueries.mobileL} {
            padding: 4px 24px;
          }
        `}
        flexDirection="column"
        gap="20px"
      >
        {post?.title?.length && isEditorial ? (
          <Text
            type="header"
            color={colors.uiGraphite}
            custom={{
              fontSize: '26px',
              lineHeight: '28px',
              [mediaQueries.mobileL]: {
                fontSize: '32px',
                lineHeight: '32px',
              },
            }}
          >
            {post.title}
          </Text>
        ) : null}
        {post.featured_image ? (
          <ActivityPostImage image={post.featured_image} contain />
        ) : null}
        <div
          ref={bodyRef}
          className={css`
            position: relative;
            max-height: ${showFullPost ? 'none' : '312px'};
            overflow: hidden;
          `}
        >
          <ActivityPostBody
            body={post.body}
            truncate={isEditorial || showFullPost ? null : 10}
          />
          {isMaxHeight && isEditorial && !showFullPost ? (
            <span
              className={css`
                width: 100%;
                height: 75px;
                position: absolute;
                inset: auto auto 0 0;
                display: block;
                background: linear-gradient(
                  rgba(255, 255, 255, 0),
                  rgba(255, 255, 255, 1)
                );
              `}
            />
          ) : null}
        </div>
        {!!post.image ? <ActivityPostImage image={post.image} /> : null}
        {!!post.quotes?.length
          ? post.quotes.map((quote) => (
              <QuoteView
                key={quote.id}
                text={quote.text}
                book={quote.book}
                theme={quote.theme}
              />
            ))
          : null}
        {!!post.club ? (
          <ActivityPostClub club={post.club as Club} options={options} />
        ) : null}
        {!!post.books?.length ? (
          <ActivityPostBooks books={post.books} options={options} />
        ) : null}
      </FlexBox>
    </ActivityCard>
  )
}
