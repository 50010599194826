import {
  getErrorMessage,
  getInviteParams,
  formatFirebaseErrorMessage,
} from 'app/auth/utils'
import { useEffect, useRef, useState } from 'react'
import { FieldErrors, useForm, UseFormProps } from 'react-hook-form'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'
import { NO_USER_TEXT } from '../constants'
import {
  CompanyCapabilities,
  CompanyInvite,
  SignInFormInput,
} from '@fable/types'
import { FirebaseError } from '@firebase/util'
import { signinValidationSchema } from 'utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { requests } from '@fable/api'

type FormData = SignInFormInput

export interface UserSignin {
  invite?: CompanyInvite
  errorMessage?: string
  errors: FieldErrors<FormData>
  inviteParams?: {
    hasInvite?: boolean
    hasPersonalInvite?: boolean
    emailPattern?: string
    capabilities?: CompanyCapabilities
    companyName?: string
    termsAgreementText?: string
    hasAllowList?: boolean
  }
  watch: any
  register: any
  clearServerErrors: () => void
  setValue: any
  handleSubmit: any
}

export const useSignin = (params?: {
  invite?: CompanyInvite
  onSubmit?: () => void
  defaultValues?: FormData
}) => {
  const registered = useRef(false)
  const [apiError, setApiError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { clearSignIn, signInWithEmailAndPassword } = useActions()
  const { userSignIn } = useTypedSelector(({ auth }) => auth)
  const formOptions = (): UseFormProps<FormData> => {
    const obj: { [key: string]: any } = {
      resolver: yupResolver(signinValidationSchema),
    }

    if (params?.defaultValues) obj.defaultValues = params.defaultValues

    return obj
  }
  const formHook = useForm<FormData>(formOptions())
  const inviteParams = getInviteParams(params?.invite)

  const { hasPersonalInvite, companyName } = inviteParams

  const handleSignIn = async (values: { email: any; password: any }) => {
    const { email, password } = values

    if (params?.invite?.company && params?.invite?.has_allow_list) {
      try {
        await requests.getClubInvite({
          invite: params.invite,
          email,
        })
      } catch (error: unknown) {
        setErrorMessage((error as Error).message)
        return
      }
    }

    if (hasPersonalInvite) {
      values.email = params?.invite?.email
    }

    signInWithEmailAndPassword(email, password)

    if (!!params?.onSubmit) params.onSubmit()
  }

  const clearServerErrors = () => {
    if (errorMessage) setErrorMessage('')
    clearSignIn()
    setApiError(false)
  }

  useEffect(() => {
    let message = ''

    if (apiError && userSignIn.error?.message) {
      message = formatFirebaseErrorMessage(userSignIn?.error as FirebaseError)
    } else {
      message = getErrorMessage(formHook.formState.errors, companyName) || ''
    }

    if (message) {
      setErrorMessage(message)
    }
  }, [
    companyName,
    formHook.formState.errors,
    apiError,
    userSignIn,
    userSignIn.error?.message,
  ])

  useEffect(() => {
    if (hasPersonalInvite) {
      formHook.setValue('email', params?.invite?.email || '')
    }
  }, [hasPersonalInvite, formHook, params?.invite?.email])

  // check if error occurred signing in
  useEffect(() => {
    if (!!userSignIn.error && userSignIn.error.message !== NO_USER_TEXT) {
      setApiError(true)
    }

    return () => {
      if (apiError) {
        clearSignIn()
        setApiError(false)
      }
    }
  }, [apiError, clearSignIn, userSignIn.error])

  useEffect(() => {
    if (registered.current) return

    registered.current = true
    formHook.register('email')
    formHook.register('password')
  }, [formHook])

  return {
    invite: params?.invite,
    errorMessage,
    errors: formHook.formState.errors,
    inviteParams,
    watch: formHook.watch,
    register: formHook.register,
    clearServerErrors,
    setValue: formHook.setValue,
    handleSubmit: formHook.handleSubmit(handleSignIn),
  }
}
