import { Breakpoints, useTheme } from '@fable/theme'
import isString from 'lodash/isString'
import { textStyles } from './styles'
import {
  BreakpointMap,
  TextSizes,
  TextSizings,
  TextStyleProps,
  TextTypes,
} from './types'

export const useTextStyles = ({
  custom = null,
  sizing = null,
  type,
}: {
  custom?: TextStyleProps | null
  sizing?: TextSizings | null
  type: TextTypes
}) => {
  const { mediaQueries } = useTheme()
  const { styles, baseStyles } = textStyles[type]
  let allStyles: TextStyleProps = { ...baseStyles }

  if (custom && !sizing) return { ...allStyles, ...custom }
  if (isString(sizing)) return { ...allStyles, ...styles[sizing] }

  for (const breakpoint in sizing) {
    const bpSize = sizing[breakpoint as keyof BreakpointMap]
    const bpStyles = styles[bpSize as TextSizes]

    if (breakpoint === 'base') {
      allStyles = { ...baseStyles, ...bpStyles }
    } else {
      allStyles[mediaQueries[breakpoint as keyof Breakpoints]] = {
        ...baseStyles,
        ...bpStyles,
      }
    }
  }

  return allStyles
}

export const getTagDefault = (type: TextTypes) => {
  switch (type) {
    case 'header':
      return 'h1'
    case 'subhead':
      return 'h2'
    default:
      return 'span'
  }
}

export const getWeightDefault = (type: TextTypes) => {
  if (type === 'subhead' || type === 'link') return '600'
  return '400'
}
