import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_PAGE_CONTENT = gql`
  query PageContent($id: String!) {
    pageContent(id: $id, preview: ${isPreview}) {
        title
        valueProps {
            listCollection(limit: 10) {
                items {
                    title
                    subtitle
                    image {
                        url
                        title
                    }
                }
            }
        }
        faqs {
            listCollection(limit: 10) {
                items {
                    title
                    description {
                        json
                    }
                }
            }
        }
    }
  }
`
