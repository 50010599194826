import './SMSForm.scss'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { BookLinkSent } from './BookLinkSent'
import SMSForm from './SMSForm'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import isEmpty from 'lodash/isEmpty'
export const BookSMSForm = ({
  liftPhoneNumber,
  onSubmit,
  isModalOpen,
  isStatic,
  preview = false,
  hideButton = false,
}) => {
  const [smsError, setSmsError] = useState({ error: false, message: '' })
  const { clearFreeBook, openModal } = useActions()
  const { newFreeBook } = useTypedSelector(({ books }) => books)
  const { bookSMS } = useTypedSelector(({ user }) => user)

  useEffect(() => {
    return () => {
      if (!isModalOpen || isStatic) {
        setSmsError({ error: false, message: '' })
      }
    }
  }, [clearFreeBook, isModalOpen, isStatic])

  useEffect(() => {
    const isSmsSent = !isEmpty(bookSMS.data) && bookSMS.data.isSent
    if (isStatic && isSmsSent) {
      openModal(<BookLinkSent isOwned={true} />)
    }
  }, [bookSMS.data, isStatic, openModal])

  useEffect(() => {
    if (!isEmpty(bookSMS.error)) {
      setSmsError({ error: true, message: bookSMS.error.message })
    }

    if (!isEmpty(newFreeBook.error)) {
      setSmsError({ error: true, message: newFreeBook.error.mesage })
    }
  }, [bookSMS.error, newFreeBook.error])

  return (
    <div>
      <SMSForm
        onSend={onSubmit}
        // Temp to add last minute feature changes
        liftPhoneNumber={liftPhoneNumber}
        error={!isEmpty(smsError.message) ? smsError.message : ''}
        isSimple={false}
        description={`Enter your phone number and we will send a link to open your book ${
          preview ? 'sample' : ''
        } from your mobile device`}
        button={{ show: !hideButton, loading: bookSMS.loading }}
      />
    </div>
  )
}

BookSMSForm.propTypes = {
  liftPhoneNumber: PropTypes.func,
  onSubmit: PropTypes.func,
  book: PropTypes.object,
  isModalOpen: PropTypes.bool,
  isStatic: PropTypes.bool,
}

BookSMSForm.defaultProps = {
  liftPhoneNumber: () => null,
  onSubmit: () => null,
  book: {},
  isModalOpen: false,
  isStatic: false,
}
