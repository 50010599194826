import { IconSVGProps } from '../types'

export const UserHeartIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8676 13.0492L15.0143 13.1967L15.1626 13.0492C15.3367 12.8751 15.5434 12.737 15.7709 12.6428C15.9984 12.5485 16.2422 12.5 16.4884 12.5C16.7347 12.5 16.9785 12.5485 17.206 12.6428C17.4335 12.737 17.6402 12.8751 17.8143 13.0492C17.9884 13.2233 18.1265 13.43 18.2207 13.6575C18.3149 13.885 18.3634 14.1288 18.3634 14.375C18.3634 14.6213 18.3149 14.8651 18.2207 15.0926C18.1265 15.3201 17.9884 15.5268 17.8143 15.7009L15.0143 18.5L12.2159 15.7009C11.8643 15.3492 11.6668 14.8723 11.6668 14.375C11.6668 13.8778 11.8643 13.4008 12.2159 13.0492C12.5676 12.6976 13.0445 12.5 13.5418 12.5C14.0391 12.5 14.516 12.6976 14.8676 13.0492ZM10.0001 11.6667V18.3334H3.33344C3.33346 16.6014 4.00755 14.9373 5.21299 13.6936C6.41844 12.4499 8.06059 11.7242 9.79177 11.67L10.0001 11.6667ZM10.0001 0.833374C12.7626 0.833374 15.0001 3.07087 15.0001 5.83337C15.0001 8.59587 12.7626 10.8334 10.0001 10.8334C7.2376 10.8334 5.0001 8.59587 5.0001 5.83337C5.0001 3.07087 7.2376 0.833374 10.0001 0.833374Z" />
  </svg>
)
