import { Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { urlify } from '@fable/utils'

interface Props {
  className?: string
  body: string
  stopPropagation?: boolean
  truncate?: number | null
}

export const ActivityPostBody = ({
  body,
  className = '',
  stopPropagation = false,
  truncate,
}: Props) => {
  const { colors, motion, presetStyles } = useTheme()
  const urlClassName = 'user-post-link'

  var { text, hasUrl } = urlify({
    text: body,
    className: urlClassName,
    stopPropagation,
  })

  let postBody: React.ReactNode = body

  if (hasUrl) {
    postBody = (
      <span
        data-testid="post-text-url"
        className={cx(!!truncate ? presetStyles.truncate(truncate) : '')}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  return (
    <Text
      data-testid="post-text"
      className={cx(
        css`
          white-space: break-spaces;

          .${urlClassName} {
            color: ${colors.uiBlueLight};
            font-weight: bold;
            ${motion.transition('opacity')};
            &:hover {
              opacity: 0.7;
            }
          }
        `,
        className
      )}
      type="body"
      sizing="L"
      color={colors.uiGraphite}
      truncate={truncate}
    >
      {postBody}
    </Text>
  )
}
