import './ResultLoader.scss'
import React from 'react'

import times from 'lodash/times'

const ResultLoader = () => {
  let placeholders = []
  let maxPlaceholders = 3

  times(maxPlaceholders, (i) => {
    placeholders.push(
      <div className="item" key={i}>
        <div className="item-image"></div>
        <div className="item-detail">
          <div className="item-line"></div>
          <div className="item-line"></div>
        </div>
      </div>
    )
  })

  return <div className="result-loader">{placeholders}</div>
}

export default ResultLoader
