import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Icon, Text } from '@fable/components'
import Links from '../links'
import { footerLinks, socialLinks } from './links'

export const FooterLinks: React.VFC<{ className?: string }> = ({
  className,
}) => {
  const { colors, mediaQueries } = useTheme()

  const styles = {
    linksWrap: css`
      flex-grow: 1;
      flex-wrap: wrap;
      ${mediaQueries.tabletL} {
        margin-top: -50px;
        justify-content: flex-end;
      }
    `,
    linkSection: css`
      margin-top: 32px;
      width: 45%;
      :not(:last-child) {
        margin-right: 3%;
      }

      ${mediaQueries.tabletL} {
        margin-top: 50px;
        width: auto;
        :not(:last-child) {
          margin-right: 4%;
        }
      }
    `,
    linkHeader: css`
      margin: 4px 0 24px;
    `,
    linkItem: css`
      transition: opacity ease 0.2s;

      :not(:last-child) {
        margin-bottom: 12px;
      }

      :hover {
        opacity: 0.7;
      }

      ${mediaQueries.tablet} {
        :not(:last-child) {
          margin-bottom: 24px;
        }
      }
    `,
    link: css`
      display: table;
      color: ${colors.whiteFang};
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
    `,
  }

  const links = footerLinks.map((links, index) => (
    <div key={index} className={styles.linkSection}>
      <Text
        className={styles.linkHeader}
        type="body"
        sizing="M"
        text={links.title}
        color={colors.hurricane}
      />
      <div>
        <Links
          links={links.links}
          itemClass={styles.linkItem}
          linkClass={styles.link}
        />
      </div>
    </div>
  ))

  return (
    <FlexBox className={cx(styles.linksWrap, className)}>
      {links}
      <div
        className={cx(
          css`
            display: none;
            ${mediaQueries.tabletL} {
              display: block;
            }
          `,
          styles.linkSection
        )}
      >
        <Text
          className={styles.linkHeader}
          type="body"
          sizing="M"
          text="Follow us"
          color={colors.hurricane}
        />
        <div
          className={css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            flex-wrap: wrap;
            gap: 8px;
          `}
        >
          {socialLinks.map((link) => (
            <a key={link.icon} href={link.url}>
              <FlexBox
                className={css`
                  width: 40px;
                  height: 40px;
                `}
                centerAll
              >
                <Icon
                  className={css`
                    width: ${link.width?.desktop || '24px'};
                  `}
                  type={link.icon}
                  fill={colors.uiWhite}
                />
              </FlexBox>
            </a>
          ))}
        </div>
      </div>
    </FlexBox>
  )
}
