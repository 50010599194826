import { lazy, Suspense } from 'react'
import { PatternProps } from './PatternProps'
import ErrorBoundary from '../error_boundary/ErrorBoundary'

const Pattern = lazy(() => import('./Pattern'))

const LazyPattern = (props: PatternProps) => (
  <ErrorBoundary fallback={<div />}>
    <Suspense fallback={<div />}>
      <Pattern {...props} />
    </Suspense>
  </ErrorBoundary>
)

export default LazyPattern
