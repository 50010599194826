import { BookReview } from '@fable/components'
import { Review } from '@fable/types'
import { useActions } from 'hooks'
import { css, useTheme } from '@fable/theme'
import { ActivityCard } from '../ActivityCard'

interface Props {
  review: Review
  onClickLike?: () => void
  onClickComment?: () => void
  onClickFullReview?: () => void
  onClickLink?: () => void
}

export const ActivityReview = ({
  review,
  onClickComment,
  onClickLike,
  onClickFullReview,
  onClickLink,
}: Props) => {
  const { mediaQueries, colors } = useTheme()
  const { copyTextToClipboard } = useActions()
  const book = review.book

  return (
    <ActivityCard
      data-testid="activity-review"
      author={{
        name: review.user.display_name as string,
        image: review.user?.pic as string,
        label: 'Created a review: ',
        link: { title: book.title, url: book.url, onClick: onClickLink },
        createdAt: review.created_at,
        small: true,
        url: review.user.url as string,
      }}
      counters={review.counters}
      onClickComment={onClickComment}
      onClickLike={onClickLike}
    >
      <div
        className={css`
          ${mediaQueries.mobileL} {
            padding: 8px 24px 0;
          }
        `}
      >
        <BookReview
          review={review}
          showGiftBookButton={false}
          onCopyToClipboard={copyTextToClipboard}
          backgroundColor={colors.uiPearlUltraLight}
          onClickFullReview={onClickFullReview}
          isPreview
        />
      </div>
    </ActivityCard>
  )
}
