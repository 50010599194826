import { css, useTheme } from '@fable/theme'
import { useLocation } from 'react-router-dom'
import { FORM_TYPES } from '../../app/auth/constants'
import { Icon } from '@fable/components'
import { useActions, useTypedSelector } from '../../hooks'
import { HeaderUserMenu } from './HeaderUserMenu'

export const HeaderUser: React.VFC<{
  secondary: boolean
}> = ({ secondary }) => {
  const { colors, motion, mediaQueries } = useTheme()
  const { pathname } = useLocation()
  const { openAuthModal } = useActions()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { profile } = useTypedSelector(({ user }) => user)

  return isAuthenticated ? (
    <HeaderUserMenu
      image={profile.data?.pic_resize || (profile.data?.pic_resize as string)}
    />
  ) : (
    <div
      data-testid="headerJoinButton"
      className={css`
        flex: 0 0 auto;
        width: 24px;
        cursor: pointer;
        ${mediaQueries.mobileL} {
          ${motion.transition('opacity')};
          &:hover {
            opacity: 0.7;
          }
        }
      `}
      onClick={() => openAuthModal(FORM_TYPES.SIGN_UP, pathname)}
    >
      <Icon
        type="user"
        fill={secondary ? colors.uiPearlExtraLight : colors.uiGraphite}
      />
    </div>
  )
}
