import { TakeableChannel } from '@redux-saga/core'
import { put, call, select } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects'
import { AuthTypes } from '../state/action-types'
import { post } from '@fable/api'
import { getReferralId } from '../utils'
import { analytics } from 'segment_analytics'
import { clubDetailsSelector } from './selectors/clubsSelectors'
import { Club } from '@fable/types'

interface PostAuthParams {
  utm_source: string
  utm_medium: string
  referral_id?: string
}

export function* postAuthAnalytics({
  payload,
}: {
  payload: { method: string }
}) {
  const club: Club = yield select(clubDetailsSelector)
  const { storedId: referralId } = yield call(getReferralId)

  const params: PostAuthParams = {
    utm_source: analytics.utm.getUTMSource(),
    utm_medium: analytics.utm.getUTMMedium(),
  }

  if (!!referralId) {
    params.referral_id = referralId
  }

  const { data } = yield call(post, `/post_auth/`, params)

  if (data.is_signup) {
    yield put({
      type: AuthTypes.SIGNUP__SUCCESS,
      payload: { method: payload.method, club, referralId },
    })
  } else {
    yield put({
      type: AuthTypes.SIGNIN__SUCCESS,
      payload: { method: payload.method },
    })
  }
}

export default function* postAuthAnalyticsSaga() {
  yield takeLatest(
    AuthTypes.AUTH__SUCCESS as unknown as TakeableChannel<any>,
    postAuthAnalytics
  )
}
