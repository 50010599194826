import { ClipboardActions } from '../actions'
import { ClipboardTypes } from '../action-types'

interface ClipboardState {
  copied: boolean
}

const initialState: ClipboardState = {
  copied: false,
}
const reducer = (
  state: ClipboardState = initialState,
  action: ClipboardActions
): ClipboardState => {
  switch (action.type) {
    case ClipboardTypes.CLIPBOARD__CLEAR:
      return {
        ...state,
        copied: false,
      }
    case ClipboardTypes.CLIPBOARD__COPY:
      return {
        ...state,
        copied: true,
      }

    default:
      return state
  }
}

export default reducer
