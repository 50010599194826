import { IconSVGProps } from '../types'

export const QuoteIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.8012 5C19.1024 6.44828 15.3253 11.8276 15.3253 17.2069C15.3253 20.7931 17.4458 23 20.3614 23C23.0783 23 25 20.931 25 18.3103C25 15.8966 23.5422 14.1034 21.2229 13.6897C20.5602 13.5517 20.3614 13.4138 20.3614 12.7241C20.3614 9.62069 22.2831 6.58621 24.8675 5.13793L24.8012 5ZM12.4759 5C6.77711 6.44828 3 11.8276 3 17.2069C3 20.7931 5.12048 23 8.03614 23C10.753 23 12.6747 20.931 12.6747 18.3103C12.6747 15.8966 11.2169 14.1034 8.89759 13.6897C8.23494 13.5517 8.03614 13.4138 8.03614 12.7241C8.03614 9.62069 9.95783 6.58621 12.5422 5.13793L12.4759 5Z" />
  </svg>
)
