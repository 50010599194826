import { css, useTheme } from '@fable/theme'
import { useActions, useAccountNavItems } from '../../hooks'
import Dropdown from '../dropdown'
import { RoundImage } from '@fable/components'

interface Props {
  image: string
}

export const HeaderUserMenu: React.VFC<Props> = ({ image }) => {
  const { signOut } = useActions()
  const accountNavItems = useAccountNavItems()
  const { colors, motion, mediaQueries } = useTheme()

  const secondaryButtonConfig = {
    title: 'Log out',
    color: colors.denim,
    action: signOut,
  }

  return (
    <div>
      <Dropdown
        className={css`
          padding-top: 14px;
          right: -8px;
          top: 36px;
          * {
            white-space: nowrap;
          }
          .fds-ui--dropdown-box-content {
            width: auto;
          }
          ${mediaQueries.tablet} {
            top: 32px;
          }
        `}
        secondaryAction={secondaryButtonConfig}
        items={accountNavItems}
        customControl={
          <RoundImage
            className={css`
              cursor: pointer;
              ${motion.transition('opacity')};
              &:hover {
                opacity: 0.7;
              }
            `}
            src={image}
            alt={`user avatar`}
            size={100}
            diameter="32px"
          />
        }
        hover
      />
    </div>
  )
}
