import { TakeableChannel } from '@redux-saga/core'
import { call, put, takeLatest, select, take } from 'redux-saga/effects'
import { AuthTypes, UserTypes } from '../state/action-types'
import { patch, setAuthToken } from '@fable/api'
import { User } from '@fable/types'
import { getProfileData } from './selectors/userSelectors'

export function* setUserData({
  payload,
}: {
  payload: { user: { getIdToken: any }; method: string; emailOptIn: boolean }
}) {
  if (payload.user) {
    // Get token for logging in
    const idToken: string = yield call(
      [payload.user, payload.user.getIdToken],
      true
    )

    yield call(setAuthToken, idToken)

    yield put({ type: UserTypes.GET_USER_PROFILE__REQUEST })

    yield take(UserTypes.GET_USER_PROFILE__SUCCESS)

    /*
    Order matters here. User profile data needs to exist and have been stored in Redux
    so the analyticsSaga and postAuthSaga can read its data
    */
    yield put({
      type: AuthTypes.TOKEN__SUCCESS,
      payload: idToken,
    })

    const userData: User = yield select(getProfileData)

    yield put({
      type: AuthTypes.AUTH__SUCCESS,
      payload: { ...userData, method: payload.method },
    })

    if (payload.emailOptIn && userData?.id) {
      yield call(patch, `/v2/users/${userData.id}/prefs`, {
        notification_mailing_list: true,
      })
    }

    // This needs to be last to dismiss the loader after authentication completes
    yield put({
      type: AuthTypes.VERIFY_USER__SUCCESS,
    })
  }
}

export default function* authSaga(): Generator<any> {
  yield takeLatest(
    AuthTypes.TOKEN__REQUEST as unknown as TakeableChannel<any>,
    setUserData
  )
}
