import { css, cx, useTheme } from '@fable/theme'
import { Button, Logo, Text, TextInput } from '@fable/components'
import { CompanyInvite, SignInFormInput } from '@fable/types'
import { SIGNIN_HEADER_TEXT, SIGNIN_BUTTON_TEXT } from '../../../../constants'
import FormErrorMessage from '../../../../components/form_error_message/FormErrorMessage'
import { useSignin } from 'hooks'
import { PasswordField } from 'components/field/PasswordField'

export const SignIn: React.VFC<{
  hideHeader?: boolean
  onSubmit?: () => void
  submitTitle?: string
  invite?: CompanyInvite
}> = ({
  hideHeader = false,
  onSubmit,
  submitTitle = SIGNIN_BUTTON_TEXT,
  invite,
}) => {
  const { mediaQueries } = useTheme()

  const {
    errorMessage,
    errors,
    inviteParams,
    handleSubmit,
    clearServerErrors,
    setValue,
    watch,
  } = useSignin({
    onSubmit,
    invite,
    defaultValues: { email: '', password: '' },
  })

  const handleChange = (key: keyof SignInFormInput, value: string) => {
    clearServerErrors()
    setValue(key, value)
  }

  const email = watch('email')
  const password = watch('password')

  const styles = {
    wrapper: css`
      width: 100%;
    `,
    header: css`
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      h1 {
        margin-top: 24px;
        text-align: center;
      }

      .logo svg {
        width: 55px;
        height: 25px;
      }
      ${mediaQueries.tablet} {
        .logo svg {
          width: 60px;
          height: 30px;
        }
      }
      ${mediaQueries.desktop} {
        .logo svg {
          width: 65px;
          height: 35px;
        }
      }
    `,
    form: css`
      margin-top: 32px;
      display: block;
      width: 100%;
      position: relative;
    `,
  }

  const renderHeader = () => {
    return (
      <div className={cx(styles.header)}>
        <Logo isIcon={true} isLight={false} />
        <Text type="header" sizing="S" text={SIGNIN_HEADER_TEXT} />
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={cx(styles.form, 'fds-ui--auth-sign-in-form')}>
        <form onSubmit={handleSubmit}>
          {!!errorMessage && <FormErrorMessage message={errorMessage} />}
          <TextInput
            containerClass={css`
              margin-bottom: 12px;
            `}
            error={!!errors.email}
            id="email"
            type="email"
            placeholder="Email Address"
            value={email}
            onChangeText={(text) => handleChange('email', text)}
            autoComplete="email"
            disabled={inviteParams?.hasPersonalInvite}
          />
          <PasswordField
            containerClass={css`
              margin-bottom: 12px;
            `}
            id="password"
            placeholder="Password"
            value={password}
            onChangeText={(text) => handleChange('password', text)}
            error={!!errors.password}
            autoComplete="new-password"
          />
          <Button
            width="100%"
            type="submit"
            label={submitTitle}
            dataTestId="auth-provider-email-button"
          />
        </form>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className={cx(styles.wrapper)}>
        {hideHeader ? null : renderHeader()}
        {renderForm()}
      </div>
    )
  }

  return renderContent()
}
