import { css, cx, useTheme } from '@fable/theme'
import { Button } from '@fable/components'
import { CarouselControlProps } from './types'

export const CarouselControl: React.VFC<CarouselControlProps> = ({
  left = false,
  large = false,
  controller,
  className,
  disabled,
  hide,
  ...nativeProps
}) => {
  const { motion } = useTheme()

  if (!controller || !controller.instance) return null

  const { instance, currentSlide } = controller
  const maxIdx = instance?.track?.details?.maxIdx
  const firstSlide = currentSlide === 0
  const lastSlide = currentSlide === maxIdx
  const isTrigger = left ? firstSlide : lastSlide

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (left) return instance?.prev()
    return instance?.next()
  }

  const getDisabled = () => {
    if (controller.instance?.options.loop) return false
    if (disabled) return disabled
    return isTrigger
  }

  const getHidden = () => {
    if (!hide) return false

    return controller.allSlidesInView || isTrigger
  }

  const size = large ? '60px' : '40px'
  const hidden = getHidden()

  return (
    <Button
      className={cx(
        css`
          opacity: ${hidden ? 0 : 1};
          pointer-events: ${hidden ? 'none' : 'auto'};
          ${motion.transition(
            ['opacity', 'transform', 'background', 'border-color'],
            'ease-in-out',
            '0.2s'
          )};
          .fds-ui--icon {
            width: ${large ? '10px' : '5px'};
          }
        `,
        'fds--carousel-control',
        className
      )}
      width={size}
      height={size}
      icon={left ? 'chevron-left' : 'chevron-right'}
      onClick={onClick}
      disabled={getDisabled()}
      dataTestId="carousel-control"
      {...nativeProps}
    />
  )
}
