import { css, cx, useTheme } from '@fable/theme'
import { getTagDefault, getWeightDefault, useTextStyles } from './helpers'
import { TextBodyProps, TextHeaderProps, TextProps } from './types'

const Text = ({
  className = '',
  custom = null,
  sizing,
  type,
  children,
  color,
  text,
  align,
  truncate,
  tag,
  weight,
  ...nativeProps
}: TextProps) => {
  const { colors } = useTheme()
  const styles = useTextStyles({ custom, sizing, type })
  const textTag = tag ? tag : getTagDefault(type)
  const TextComponent = textTag as React.ElementType
  const content = children || text
  const textWeight = getWeightDefault(type)

  const sharedProps = {
    className: cx(
      css`
        display: block;
        color: ${color || colors.blackSwan};
        text-align: ${align || 'inherit'};
        font-weight: ${weight || textWeight};
        ${styles};
      `,
      !!truncate
        ? css`
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: ${truncate};
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
          `
        : '',
      className
    ),
    ...nativeProps,
  }

  const props =
    type === 'header' || type === 'subhead'
      ? (sharedProps as TextHeaderProps)
      : (sharedProps as TextBodyProps)

  return <TextComponent {...props}>{content}</TextComponent>
}

export default Text
