import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { css, cx, useTheme } from '@fable/theme'
import { Color } from '@fable/legacy'
import { BookImage, Text } from '@fable/components'
import { bookRoute } from '../../../app/store/routes'
import { BREAKPOINTS } from '../../../constants'
import './ResultCategories.scss'

const BookResult = ({ item, onSelectItem }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const styles = getStyles(theme)

  const goToBookDetail = () => {
    onSelectItem()
    navigate(bookRoute(item))
  }

  const renderBadge = () => {
    if (!item.is_free) return

    const title = 'FREE'

    return <span className={cx(styles.badge)}>{title}</span>
  }

  return (
    <div className="item" onClick={goToBookDetail}>
      <BookImage
        className={cx(styles.bookCover)}
        image={item.cover_image}
        title={item.title}
      />
      <div className={cx(styles.details)}>
        <div className={cx(styles.title)}>
          <Text className="item-title main" type="subhead" sizing="XS" tag="h4">
            {item.title}
          </Text>
          {renderBadge()}
        </div>
        <Text
          className="item-title sub"
          type="label"
          sizing="L"
          color={theme.colors.hurricane}
        >
          By {item.authors[0].name}
        </Text>
      </div>
    </div>
  )
}

BookResult.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func,
}

BookResult.defaultProps = {
  item: {},
  onSelectItem: () => {},
}

export default BookResult

const getStyles = ({ colors }) => ({
  details: css`
    width: calc(100% - 48px);
  `,
  bookCover: css`
    margin-right: 16px;
    position: relative;
    border-radius: 4px;
    width: 32px;
    height: 48px;
    overflow: hidden;
    background: ${colors.whiteSmoke};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  title: css`
    position: relative;
    display: inline-flex;
    max-width: 100%;

    @media only screen and (max-width: ${BREAKPOINTS.mobile}) {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  badge: css`
    margin-left: 8px;
    width: 44px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: ${Color.shamrock};
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    position: relative;
    top: 0;
    right: 0;
    overflow: hidden;

    :before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${Color.shamrock};
      border-radius: 4px;
      opacity: 0.1;
    }
  `,
})
