import { ReferralUser } from '@fable/types'
import { getReferralName } from 'app/social/helpers'
import { useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getReferralId } from 'utils'
import { useTypedSelector } from './useTypedSelector'
import { useNavigate } from 'react-router-dom'
import { routes } from 'app/social/routes'

export const useLoadReferral = ({
  enabled = false,
}: { enabled?: boolean } = {}) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { id, storedId, key } = getReferralId({ enabled })
  const hasReferralId = !!id

  const handleWelcomeRedirect = useCallback(() => {
    if (!!storedId && isAuthenticated) {
      localStorage.removeItem(key)
      navigate(routes.inviteWelcome)
    }
  }, [isAuthenticated, key, navigate, storedId])

  useEffect(() => {
    handleWelcomeRedirect()
  }, [handleWelcomeRedirect])

  const { data } = useQuery('referral', () => getReferralName(id as string), {
    enabled: hasReferralId,
    retry: false,
  })

  const referralUser = data?.data as ReferralUser

  return {
    hasReferralId,
    referralUser,
    enabled: hasReferralId && !!referralUser,
  }
}
