import { Text } from '@fable/components'
import { Book, ShareOption } from '@fable/types'
import { useShareOptions } from '@fable/hooks'
import { css, cx } from '@fable/theme'
import capitalize from 'lodash/capitalize'

interface Props {
  category: string
  hideTitle: boolean
  book?: Book
  url?: string
  showGiftBookButton: boolean
  onCopyToClipboard: () => void
}

export const ShareOptions: React.VFC<Props> = ({
  category,
  hideTitle,
  book,
  url,
  showGiftBookButton,
  onCopyToClipboard,
}) => {
  const options = useShareOptions({
    category,
    book,
    url,
    showGiftBookButton,
    onCopyToClipboard,
  })
  const categoryTitle = capitalize(category?.replace('-', ' '))
  const title = `Share this ${categoryTitle}`

  const handleAction = (option: ShareOption) => option.action()

  const renderOptions = options.map((option: ShareOption, i: number) => {
    const { icon, isExternal, title, id } = option

    const imgStyles = css`
      height: 24px;
    `

    if (isExternal) {
      return (
        <a
          href={handleAction(option) as string}
          target="_blank"
          rel="noopener noreferrer"
          className="modal-item no-underline"
          data-testid={`share-option-${id}`}
          key={i}
        >
          <img className={imgStyles} src={icon} alt={title} />
          <Text type="body" sizing={{ base: 'M', mobileL: 'L' }}>
            {title}
          </Text>
        </a>
      )
    } else {
      return (
        <div
          className="modal-item"
          key={i}
          data-testid={`share-option-${id}`}
          onClick={() => handleAction(option)}
        >
          <img className={imgStyles} src={icon} alt={title} />
          <Text type="body" sizing={{ base: 'M', mobileL: 'L' }}>
            {title}
          </Text>
        </div>
      )
    }
  })

  return (
    <>
      {hideTitle ? null : (
        <Text
          className={css`
            text-align: center;
            margin: 24px auto;
          `}
          type="subhead"
          sizing={{ base: 'XS', mobileL: 'S' }}
          tag="h4"
        >
          {title}
        </Text>
      )}
      <div
        className={cx(
          'modal-content list',
          css`
            margin-top: 18px;
          `
        )}
      >
        {renderOptions}
      </div>
    </>
  )
}
