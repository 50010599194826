import { useQuery } from '@apollo/client'
import { Loader } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useErrorModal } from 'hooks'
import { GET_ASSET } from 'queries'
import { CMSImage } from 'types'

interface Props extends React.HTMLProps<HTMLDivElement> {
  id: string
}

export const ImageAsset = ({ id, className }: Props) => {
  const { colors } = useTheme()
  const { data, loading, error } = useQuery(GET_ASSET, { variables: { id } })

  useErrorModal(error)

  const image = data?.asset as CMSImage

  if (!image) return null

  return (
    <div
      className={cx(
        css`
          width: 100%;
          background-color: ${colors.pewterGrey};
          position: relative;
        `,
        className
      )}
    >
      {loading ? (
        <Loader
          className={css`
            padding: 24px;
            height: auto;
          `}
        />
      ) : (
        <img
          className={css`
            display: block;
            width: 100%;
          `}
          src={image.url}
          alt={image.title}
        />
      )}
    </div>
  )
}
