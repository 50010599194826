import { css, CSSInterpolation, cx } from '@fable/theme'

const Grid: React.VFC<
  {
    children: React.ReactNode | React.ReactNode[]
    inline?: boolean
    gridTemplateColumns?: string
    gridTemplateRows?: string
    gridTemplateAreas?: string
    gap?: string
    justifyItems?: 'start' | 'end' | 'center' // default is stretch
    alignItems?: 'start' | 'end' | 'center' // default is stretch
  } & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  inline,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gap,
  justifyItems,
  alignItems,
  ...nativeProps
}) => {
  const styles: CSSInterpolation = { display: 'grid' }
  if (inline) styles.display = 'inline-grid'
  if (gridTemplateColumns) styles.gridTemplateColumns = gridTemplateColumns
  if (gridTemplateRows) styles.gridTemplateRows = gridTemplateRows
  if (gridTemplateAreas) styles.gridTemplateAreas = gridTemplateAreas
  if (gap) styles.gap = gap
  if (justifyItems) styles.justifyItems = justifyItems
  if (alignItems) styles.alignItems = alignItems

  return (
    <div {...nativeProps} className={cx(css(styles), nativeProps.className)}>
      {children}
    </div>
  )
}

export default Grid
