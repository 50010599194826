import React, { useEffect, useState } from 'react'
import { css, cx, useTheme, FableTheme } from '@fable/theme'
import { CompanyInvite } from '@fable/types'
import { useLocation } from 'react-router-dom'
import { AuthProviders } from '../auth_providers/AuthProviders'
import { ForgotPassword } from './ForgotPassword'
import Loader, { LoaderType } from '../../../../components/loader'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { routes as storeRoutes } from '../../../store/routes'
import { FORM_TYPES } from '../../constants'
import { Button, Text } from '@fable/components'
import { CompanyHeader } from './CompanyHeader'
import { lsKeys } from '../../../../constants'
import { useAuthProviders } from 'hooks'
import { Redirect } from '@fable/legacy'

export const AuthForm: React.FC<{
  className?: string
  closeButton?: React.ReactChild
  isCard?: boolean
  path?: string
  type?: string
  hideHeader?: boolean
  signInSubmitTitle?: string
  signUpSubmitTitle?: string
  onSubmit?: (type?: 'provider' | 'form') => void
  header?: React.ReactNode
  invite?: CompanyInvite
}> = ({
  className = '',
  closeButton,
  isCard,
  path,
  type,
  hideHeader,
  signInSubmitTitle,
  signUpSubmitTitle,
  onSubmit,
  header = null,
  invite,
}) => {
  const [formType, setFormType] = useState('')
  const { isAuthenticated, token } = useTypedSelector(({ auth }) => auth)
  const location = useLocation()
  const { SIGN_IN, SIGN_UP, FORGOT_PASSWORD } = FORM_TYPES
  const theme = useTheme()
  const styles = getStyles(theme)

  const providers = useAuthProviders(invite)

  useEffect(() => {
    if (type) setFormType(type)
  }, [type])

  const handleOnSubmit = (type?: 'provider' | 'form') => {
    if (path && location.pathname !== path) {
      localStorage.setItem(
        lsKeys.postAuthRedirect,
        JSON.stringify({ path, type })
      )
    }

    onSubmit && onSubmit(type)
  }

  const getRedirectPath = () => {
    if (path) {
      return path
    } else if (!path && isAuthenticated) {
      return `${location.pathname}${location.search}`
    } else {
      return storeRoutes.explore
    }
  }

  const renderForm = () => {
    if (!providers.has('email')) {
      return
    }
    switch (formType) {
      case SIGN_IN:
        return (
          <SignIn
            hideHeader={hideHeader}
            onSubmit={handleOnSubmit}
            submitTitle={signInSubmitTitle}
            invite={invite}
          />
        )
      case SIGN_UP:
        return (
          <SignUp
            hideHeader={hideHeader}
            onSubmit={handleOnSubmit}
            submitTitle={signUpSubmitTitle}
            invite={invite}
          />
        )
      case FORGOT_PASSWORD:
        return <ForgotPassword onCancel={() => setFormType(SIGN_IN)} />
      default:
        return (
          <SignIn
            hideHeader={hideHeader}
            onSubmit={onSubmit}
            submitTitle={signInSubmitTitle}
          />
        )
    }
  }

  const renderForgotPasswordControl = () => {
    const message = 'Forgot Password?'
    const buttonTitle = 'Reset it'

    return (
      formType === SIGN_IN &&
      providers.has('email') && (
        <div
          className={styles.messagePrompt}
          data-testid="auth-forgot-password-control"
        >
          <Text type="body" sizing="M">
            {message}&nbsp;
          </Text>
          <Button
            className={theme.nativeOverrides.buttonClear}
            onClick={() => setFormType(FORGOT_PASSWORD)}
            secondary
          >
            <b>{buttonTitle}</b>
          </Button>
        </div>
      )
    )
  }

  const renderAuthProviders = () => {
    return (
      (formType === SIGN_UP || formType === SIGN_IN) &&
      (providers.has('google') ||
        providers.has('apple') ||
        providers.has('microsoft')) && (
        <AuthProviders onSubmit={handleOnSubmit} providers={providers} />
      )
    )
  }

  const renderFormToggle = () => {
    const message =
      formType === SIGN_IN
        ? "Don't have an account?"
        : 'Already have an account?'
    const buttonTitle = formType === SIGN_IN ? 'Sign up' : 'Log in'

    return (
      formType !== FORGOT_PASSWORD &&
      providers.has('email') && (
        <div className={styles.messagePrompt} data-testid="auth-form-toggle">
          <Text type="body" sizing="M">
            {message}&nbsp;
          </Text>
          <Button
            className={theme.nativeOverrides.buttonClear}
            dataTestId={
              formType === SIGN_IN ? 'authFormSignUp' : 'authFormSignIn'
            }
            onClick={() =>
              setFormType(formType === SIGN_IN ? SIGN_UP : SIGN_IN)
            }
            secondary
          >
            {buttonTitle}
          </Button>
        </div>
      )
    )
  }

  const renderLoader = () => {
    if (token.loading)
      return (
        <Loader
          type={LoaderType.contained}
          backgroundColor={theme.colors.whiteFang}
        />
      )
  }

  const renderCompanyHeader = () => {
    if (!invite?.company) {
      return null
    }
    return <CompanyHeader company={invite.company} />
  }

  const renderContent = () => {
    if (isAuthenticated)
      return <Redirect to={`${getRedirectPath()}${location.search}`} />

    return (
      <>
        {closeButton}
        {renderCompanyHeader()}
        {!!invite?.company ? null : header}
        {renderForm()}
        {renderForgotPasswordControl()}
        {renderAuthProviders()}
        {renderFormToggle()}
        {renderLoader()}
      </>
    )
  }

  return (
    <div
      className={cx(styles.authForm, isCard && styles.card, className)}
      data-testid="authForm"
    >
      {renderContent()}
    </div>
  )
}

const getStyles = ({ colors, mediaQueries, breakpoints }: FableTheme) => ({
  authForm: css`
    padding: 22px;
    background: ${colors.whiteFang};
    max-width: 440px;
    min-height: 400px;
    width: 100%;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    overflow: auto;

    .loader {
      height: 100%;
    }
  `,
  card: css`
    margin: 0 auto;
    padding: 48px 24px;
    max-width: none;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    @media only screen and (max-width: ${breakpoints.mobileXS}px) {
      .auth-provider:first-child {
        margin-top: 16px;
      }
    }

    @media only screen and (max-width: ${breakpoints.mobileL}px) {
      min-height: initial;
      height: 100%;
      border-radius: 0;
    }

    ${mediaQueries.mobileL} {
      padding: 48px;
      max-width: 480px;
    }
  `,
  messagePrompt: css`
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      line-height: 23px;
      color: ${colors.blackSwan};
    }

    @media only screen and (max-width: ${breakpoints.mobile}px) and (max-height: 736px) {
      margin-top: 16px;
    }
  `,
})
