import { FABLE_TAGLINE } from '../../constants'

const SITE_NAME = `Fable | ${FABLE_TAGLINE}`
const DEFAULT_DESCRIPTION =
  'Delivering the world’s best social experience with exceptional stories in service of mental wellness.'
const DESCRIPTION_MAX_LEN = 155

const truncateString = (str: string, maxLen: number) =>
  str && str.length > maxLen
    ? str.replace(/\n/g, ' ').substring(0, maxLen - 3) + '...'
    : str

export const formatDescription = (desc: string) =>
  desc ? truncateString(desc, DESCRIPTION_MAX_LEN) : DEFAULT_DESCRIPTION

export const formatTitle = (title: string, isStrict?: boolean) => {
  if (title && isStrict) return title

  return title ? `${title} - ${SITE_NAME}` : SITE_NAME
}
