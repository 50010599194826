import { css, useTheme } from '@fable/theme'
import { FlexBox, Icon, Text } from '@fable/components'
import { formatMemberCount } from 'utils'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  count: number
  reversed?: boolean
  color?: string
  responsive?: boolean
}

export const MemberCount = ({
  count,
  reversed = false,
  color,
  responsive = false,
  ...nativeProps
}: Props) => {
  const { colors, mediaQueries } = useTheme()
  const mainColor = color || colors.graphiteLight
  const memberCount = formatMemberCount(count)

  return (
    <FlexBox
      flexDirection={reversed ? 'row-reverse' : 'row'}
      alignItems="center"
      {...nativeProps}
    >
      <Icon
        className={css`
          margin-left: ${reversed ? '4px' : 0};
          margin-right: ${reversed ? 0 : '4px'};
          width: ${responsive ? 10 : 14}px;
          ${mediaQueries.mobileL} {
            width: 14px;
          }
        `}
        type="members"
        fill={mainColor}
      />
      <Text
        className="fds--ui-member-text"
        type="label"
        sizing={responsive ? { base: 'M', mobileL: 'L' } : 'L'}
        text={memberCount}
        color={mainColor}
      />
    </FlexBox>
  )
}
