import { IconSVGProps } from '../types'

export const ArrowLeftIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    fill={fill}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    data-testid="arrow-left-icon"
  >
    <path d="M8.74984 0.0833755L10.1892 1.52275L4.49297 7.22921L16.9165 7.22921L16.9165 9.27087L4.49297 9.27087L10.1892 14.9773L8.74984 16.4167L0.583175 8.25004L8.74984 0.0833755Z" />
  </svg>
)
