export const colorHexToRgba = ({
  color,
  alpha = 1,
}: {
  color: string
  alpha?: number
}) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  color = color.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
  const colors = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null

  return colors ? `rgb(${colors.r}, ${colors.g}, ${colors.b}, ${alpha})` : null
}
