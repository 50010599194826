import { Button, FlexBox, PhoneNumberField, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { AppStoreBadges } from './AppStoreBadges'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { useActions } from 'hooks'
import { analytics } from 'segment_analytics'
import { AppDeepLinkModalProps } from './types'

interface PhoneNumber {
  code: string
  number: string
}

export const AppDeepLinkModalDesktop = ({
  link,
  title,
  description,
  onSendSMS,
  eventMetadata,
  hideSMS = false,
}: AppDeepLinkModalProps) => {
  const { mediaQueries, colors } = useTheme()
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
    code: '',
    number: '',
  })
  const [loading, setLoading] = useState(false)
  const [smsMessage, setSmsMessage] = useState<{
    type: 'success' | 'error'
    message: string
  } | null>(null)
  const { sendAppLinkSms } = useActions()

  const onSmsSuccess = () =>
    setSmsMessage({
      type: 'success',
      message: 'A download link has been sent to your mobile device!',
    })

  const onSmsError = (message: string) => {
    return setSmsMessage({ type: 'error', message })
  }

  const handleOnSendSMS = async () => {
    analytics.events.appDownload(eventMetadata)
    setLoading(true)
    setSmsMessage(null)
    const numberAsString = phoneNumber.code + phoneNumber.number
    const invalidNumber =
      !phoneNumber.code?.length || !phoneNumber.number?.length

    if (invalidNumber) {
      setLoading(false)
      onSmsError('Please enter a phone number.')
      return
    }

    try {
      const request = !!onSendSMS ? onSendSMS : sendAppLinkSms
      await request(numberAsString)
      onSmsSuccess()
    } catch (error) {
      onSmsError('Sorry, the text could not be sent, please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <FlexBox
      className={css`
        padding: 16px;
        width: 100%;
        text-align: center;
        ${mediaQueries.mobileL} {
          padding: 16px 32px 32px;
          width: 392px;
        }
      `}
      flexDirection="column"
      alignItems="center"
    >
      <Text
        className={css`
          max-width: 240px;
          ${mediaQueries.mobileL} {
            max-width: none;
          }
        `}
        type="header"
        sizing="S"
        color={colors.uiGraphite}
      >
        {title}
      </Text>
      {!!description?.length ? (
        <Text
          className={css`
            margin-top: 8px;
            max-width: 252px;
          `}
          type="body"
          sizing="L"
          color={colors.uiGraphiteLight}
        >
          {description}
        </Text>
      ) : null}
      <FlexBox
        className={css`
          margin: 20px 0;
          padding: 30px 0 50px;
          width: 100%;
          background-color: ${colors.uiPearlExtraLight};
          border-radius: 20px;
          overflow: hidden;
        `}
        flexDirection="column"
        alignItems="center"
        gap="24px"
      >
        <AppStoreBadges />
        <QRCodeSVG
          value={link as string}
          size={230}
          bgColor={colors.uiPearlExtraLight}
        />
      </FlexBox>
      {hideSMS ? null : (
        <FlexBox
          className={css`
            width: 100%;
          `}
          flexDirection="column"
          gap="8px"
        >
          <PhoneNumberField
            onChange={(number: PhoneNumber) => setPhoneNumber(number)}
          />
          <Button
            className={css`
              height: 50px;
            `}
            label="Text me the link"
            width={'100%'}
            onClick={handleOnSendSMS}
            loading={loading}
            custom={{
              borderColor: colors.uiGraphite,
              backgroundColor: colors.uiGraphite,
            }}
          />
          {!!smsMessage ? (
            <Text
              type="label"
              sizing="L"
              color={
                smsMessage.type === 'error'
                  ? colors.uiRose
                  : colors.uiGraphiteLight
              }
            >
              {smsMessage.message}
            </Text>
          ) : null}
        </FlexBox>
      )}
    </FlexBox>
  )
}
