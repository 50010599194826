import { IconSVGProps } from '../types'

export const ParentIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.66536 7.33334C4.2714 7.33334 3.88129 7.25575 3.51731 7.10498C3.15334 6.95422 2.82262 6.73324 2.54404 6.45466C2.26547 6.17609 2.04449 5.84537 1.89373 5.48139C1.74296 5.11742 1.66536 4.72731 1.66536 4.33334C1.66536 3.93938 1.74296 3.54927 1.89373 3.18529C2.04449 2.82132 2.26547 2.4906 2.54404 2.21202C2.82262 1.93345 3.15334 1.71247 3.51731 1.5617C3.88129 1.41094 4.2714 1.33334 4.66536 1.33334C5.46101 1.33334 6.22408 1.64941 6.78668 2.21202C7.34929 2.77463 7.66536 3.53769 7.66536 4.33334C7.66536 5.12899 7.34929 5.89205 6.78668 6.45466C6.22408 7.01727 5.46101 7.33334 4.66536 7.33334ZM11.6654 10C10.9581 10 10.2798 9.71906 9.77975 9.21896C9.27965 8.71886 8.9987 8.04059 8.9987 7.33334C8.9987 6.6261 9.27965 5.94782 9.77975 5.44772C10.2798 4.94763 10.9581 4.66668 11.6654 4.66668C12.3726 4.66668 13.0509 4.94763 13.551 5.44772C14.0511 5.94782 14.332 6.6261 14.332 7.33334C14.332 8.04059 14.0511 8.71886 13.551 9.21896C13.0509 9.71906 12.3726 10 11.6654 10ZM11.6654 10.6667C12.461 10.6667 13.2241 10.9827 13.7867 11.5454C14.3493 12.108 14.6654 12.871 14.6654 13.6667V14H8.66536V13.6667C8.66536 12.871 8.98143 12.108 9.54404 11.5454C10.1067 10.9827 10.8697 10.6667 11.6654 10.6667ZM4.66536 8.00001C5.1031 8.00001 5.53656 8.08623 5.94098 8.25374C6.34539 8.42126 6.71286 8.66679 7.02239 8.97632C7.33192 9.28585 7.57745 9.65331 7.74496 10.0577C7.91248 10.4622 7.9987 10.8956 7.9987 11.3333V14H1.33203V11.3333C1.33203 10.4493 1.68322 9.60144 2.30834 8.97632C2.93346 8.3512 3.78131 8.00001 4.66536 8.00001Z" />
  </svg>
)
