import { css, cx, useTheme } from '@fable/theme'

const GiftItem: React.VFC<
  {
    children: React.ReactChild | React.ReactChild[]
  } & React.HTMLProps<HTMLLIElement>
> = ({ children, ...nativeProps }) => {
  const { colors } = useTheme()

  return (
    <li
      {...nativeProps}
      className={cx(
        css`
          margin-bottom: 2px;
          background: ${colors.almostWhite};
          padding: 19px;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `,
        nativeProps.className
      )}
    >
      {children}
    </li>
  )
}

export default GiftItem
