/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { BookListItem, BookListResult } from '@fable/types'
import {
  Response,
  CreateBookListData,
  BookListResponse,
  CheckedBookLists,
  GetBookListPickerDataPayload,
} from '../../types'
import { BookActions } from '../actions'
import { BookTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

enum BookKeys {
  createdBookList = 'createdBookList',
  prependedBookLists = 'prependedBookLists',
  addBookListState = 'addBookListState',
  bookDetails = 'bookDetails',
  bookList = 'bookList',
  bookListBooks = 'bookListBooks',
  clubReadyBooks = 'clubReadyBooks',
  deletedWishlistBook = 'deletedWishlistBook',
  featuredBooks = 'featuredBooks',
  newFreeBook = 'newFreeBook',
  purchasedBooks = 'purchasedBooks',
  wishlist = 'wishlist',
  modifyBooklistsState = 'modifyBooklistsState',
  removedBookFromList = 'removedBookFromList',
  removedBookList = 'removedBookList',
  editedBookList = 'editedBookList',
  bookListsPaginated = 'bookListsPaginated',
  checkedBookLists = 'checkedBookLists',
  bookListPickerData = 'bookListPickerData',
  bookListBookIds = 'bookListBookIds',
}

interface BookState {
  [BookKeys.createdBookList]: CreateBookListData
  [BookKeys.prependedBookLists]: null | BookListItem[]
  [BookKeys.addBookListState]: Response<Partial<BookListResult>>
  [BookKeys.bookDetails]: Response
  [BookKeys.bookList]: Response
  [BookKeys.bookListBooks]: Response
  [BookKeys.clubReadyBooks]: Response
  [BookKeys.deletedWishlistBook]: Response
  [BookKeys.featuredBooks]: Response
  [BookKeys.newFreeBook]: Response
  [BookKeys.purchasedBooks]: Response
  [BookKeys.wishlist]: Response
  [BookKeys.modifyBooklistsState]: Response
  [BookKeys.removedBookFromList]: Response
  [BookKeys.removedBookList]: Response
  [BookKeys.editedBookList]: Response
  [BookKeys.bookListsPaginated]: Response<BookListResponse>
  [BookKeys.checkedBookLists]: CheckedBookLists
  [BookKeys.bookListPickerData]: GetBookListPickerDataPayload
  [BookKeys.bookListBookIds]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: BookState = {
  [BookKeys.createdBookList]: {},
  [BookKeys.prependedBookLists]: null,
  [BookKeys.addBookListState]: props,
  [BookKeys.bookDetails]: props,
  [BookKeys.bookList]: props,
  [BookKeys.bookListBooks]: props,
  [BookKeys.clubReadyBooks]: props,
  [BookKeys.deletedWishlistBook]: props,
  [BookKeys.featuredBooks]: props,
  [BookKeys.newFreeBook]: props,
  [BookKeys.purchasedBooks]: props,
  [BookKeys.wishlist]: props,
  [BookKeys.modifyBooklistsState]: props,
  [BookKeys.removedBookFromList]: props,
  [BookKeys.removedBookList]: props,
  [BookKeys.editedBookList]: props,
  [BookKeys.bookListsPaginated]: props,
  [BookKeys.checkedBookLists]: { book: null, bookId: '', bookListIds: [] },
  [BookKeys.bookListPickerData]: { bookId: '', uuid: '', bookListIds: [] },
  [BookKeys.bookListBookIds]: props,
}

const reducer = (
  state: BookState = initialState,
  action: BookActions
): BookState => {
  const stateProps = { state, props }

  switch (action.type) {
    // CREATE BOOK LIST
    case BookTypes.CREATE_BOOK_LIST:
      return {
        ...state,
        [BookKeys.createdBookList]: { ...action.payload },
      }
    case BookTypes.CREATE_BOOK_LIST__CLEAR:
      return {
        ...state,
        [BookKeys.createdBookList]: {},
      }

    case BookTypes.PREPEND_BOOK_LIST:
      return {
        ...state,
        [BookKeys.prependedBookLists]: action.payload,
      }

    // ADD BOOK LIST
    case BookTypes.ADD_BOOK_LIST__REQUEST:
      return setRequestState(stateProps, BookKeys.addBookListState)
    case BookTypes.ADD_BOOK_LIST__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.addBookListState,
        action.payload
      )
    case BookTypes.ADD_BOOK_LIST__ERROR:
      return setErrorState(
        stateProps,
        BookKeys.addBookListState,
        action.payload
      )

    // ADD FREE BOOK
    case BookTypes.ADD_FREE_BOOK__REQUEST:
      return setRequestState(stateProps, BookKeys.newFreeBook)
    case BookTypes.ADD_FREE_BOOK__SUCCESS:
      return setSuccessState(stateProps, BookKeys.newFreeBook, action.payload)
    case BookTypes.ADD_FREE_BOOK__ERROR:
      return setErrorState(stateProps, BookKeys.newFreeBook, action.payload)
    case BookTypes.ADD_FREE_BOOK__CLEAR:
      return setClearState(stateProps, BookKeys.newFreeBook)

    // GET BOOK DETAILS
    case BookTypes.GET_BOOK_DETAILS__REQUEST:
      return setRequestState(stateProps, BookKeys.bookDetails)
    case BookTypes.GET_BOOK_DETAILS__SUCCESS:
      return setSuccessState(stateProps, BookKeys.bookDetails, action.payload)
    case BookTypes.GET_BOOK_DETAILS__ERROR:
      return setErrorState(stateProps, BookKeys.bookDetails, action.payload)
    case BookTypes.GET_BOOK_DETAILS__CLEAR:
      return setClearState(stateProps, BookKeys.bookDetails)

    // GET BOOK LIST
    case BookTypes.GET_BOOK_LIST__REQUEST:
      return setRequestState(stateProps, BookKeys.bookList)
    case BookTypes.GET_BOOK_LIST__SUCCESS:
      return setSuccessState(stateProps, BookKeys.bookList, action.payload)
    case BookTypes.GET_BOOK_LIST__ERROR:
      const bookListCustomError = new Error()
      bookListCustomError.message =
        'Book list not found or the list is private.'
      return {
        ...state,
        [BookKeys.bookList]: {
          ...state[BookKeys.bookList],
          error: bookListCustomError,
        },
      }

    // GET BOOK LIST BOOKS
    case BookTypes.GET_BOOK_LIST_BOOKS__REQUEST:
      return setRequestState(stateProps, BookKeys.bookListBooks)
    case BookTypes.GET_BOOK_LIST_BOOKS__SUCCESS:
      return setSuccessState(stateProps, BookKeys.bookListBooks, action.payload)
    case BookTypes.GET_BOOK_LIST_BOOKS__ERROR:
      return setErrorState(stateProps, BookKeys.bookListBooks, action.payload)

    // GET FEATURED BOOKS
    case BookTypes.GET_FEATURED_BOOKS__REQUEST:
      return setRequestState(stateProps, BookKeys.featuredBooks)
    case BookTypes.GET_FEATURED_BOOKS__SUCCESS:
      return setSuccessState(stateProps, BookKeys.featuredBooks, action.payload)
    case BookTypes.GET_FEATURED_BOOKS__ERROR:
      return setErrorState(stateProps, BookKeys.featuredBooks, action.payload)

    // GET PURCHASED BOOKS
    case BookTypes.GET_PURCHASED_BOOKS__REQUEST:
      return setRequestState(stateProps, BookKeys.purchasedBooks)
    case BookTypes.GET_PURCHASED_BOOKS__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.purchasedBooks,
        action.payload
      )
    case BookTypes.GET_PURCHASED_BOOKS__ERROR:
      return setErrorState(stateProps, BookKeys.purchasedBooks, action.payload)

    // GET WISHLIST
    case BookTypes.GET_WISHLIST__REQUEST:
      return setRequestState(stateProps, BookKeys.wishlist)
    case BookTypes.GET_WISHLIST__SUCCESS:
      return setSuccessState(stateProps, BookKeys.wishlist, action.payload)
    case BookTypes.GET_WISHLIST__ERROR:
      return setErrorState(stateProps, BookKeys.wishlist, action.payload)

    // GET FEATURED BOOKS
    case BookTypes.GET_CLUB_READY_BOOKS__REQUEST:
      return setRequestState(stateProps, BookKeys.clubReadyBooks)
    case BookTypes.GET_CLUB_READY_BOOKS__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.clubReadyBooks,
        action.payload
      )
    case BookTypes.GET_CLUB_READY_BOOKS__ERROR:
      return setErrorState(stateProps, BookKeys.clubReadyBooks, action.payload)

    // ADD TO BOOKLISTS (V2)
    case BookTypes.MODIFY_BOOK_LIST__REQUEST:
      return setRequestState(stateProps, BookKeys.modifyBooklistsState)
    case BookTypes.MODIFY_BOOK_LIST__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.modifyBooklistsState,
        action.payload
      )
    case BookTypes.MODIFY_BOOK_LIST__ERROR:
      return setErrorState(
        stateProps,
        BookKeys.modifyBooklistsState,
        action.payload
      )

    // GET BOOK LISTS PAGINATED
    case BookTypes.GET_BOOK_LISTS_PAGINATED__REQUEST:
      return setRequestState(stateProps, BookKeys.bookListsPaginated)
    case BookTypes.GET_BOOK_LISTS_PAGINATED__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.bookListsPaginated,
        action.payload
      )
    case BookTypes.GET_BOOK_LISTS_PAGINATED__ERROR:
      return setErrorState(
        stateProps,
        BookKeys.bookListsPaginated,
        action.payload
      )

    // GET BOOK LISTS PAGINATED CLEAR
    case BookTypes.GET_BOOK_LISTS_PAGINATED__CLEAR:
      return setClearState(stateProps, BookKeys.bookListsPaginated)

    // SET CHECKED BOOK LIST IDS
    case BookTypes.SET_CHECKED_BOOK_LISTS:
      return {
        ...state,
        [BookKeys.checkedBookLists]: action.payload,
      }

    // GET BOOK LIST PICKER DATA STATUS
    case BookTypes.GET_BOOK_LIST_PICKER_DATA__REQUEST:
      return setRequestState(stateProps, BookKeys.bookListPickerData)
    case BookTypes.GET_BOOK_LIST_PICKER_DATA__SUCCESS:
      return {
        ...state,
        [BookKeys.bookListPickerData]: action.payload,
      }
    case BookTypes.GET_BOOK_LIST_PICKER_DATA__ERROR:
      return setErrorState(
        stateProps,
        BookKeys.bookListPickerData,
        action.payload
      )

    // REMOVE BOOK FROM BOOKLIST
    case BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__REQUEST:
      return setRequestState(stateProps, BookKeys.removedBookFromList)
    case BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.removedBookFromList,
        action.payload
      )
    case BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__ERROR:
      return setErrorState(
        stateProps,
        BookKeys.removedBookFromList,
        action.payload
      )

    // EDIT BOOK LIST
    case BookTypes.EDIT_BOOKLIST__REQUEST:
      return setRequestState(stateProps, BookKeys.editedBookList)
    case BookTypes.EDIT_BOOKLIST__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.editedBookList,
        action.payload
      )
    case BookTypes.EDIT_BOOKLIST__ERROR:
      return setErrorState(stateProps, BookKeys.editedBookList, action.payload)

    // DELETE BOOK LIST
    case BookTypes.DELETE_BOOKLIST__REQUEST:
      return setRequestState(stateProps, BookKeys.removedBookList)
    case BookTypes.DELETE_BOOKLIST__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.removedBookList,
        action.payload
      )
    case BookTypes.DELETE_BOOKLIST__ERROR:
      return setErrorState(stateProps, BookKeys.removedBookList, action.payload)

    // GET BOOK LIST BOOK IDS
    case BookTypes.GET_BOOK_LIST_BOOK_IDS__REQUEST:
      return setRequestState(stateProps, BookKeys.bookListBookIds)
    case BookTypes.GET_BOOK_LIST_BOOK_IDS__SUCCESS:
      return setSuccessState(
        stateProps,
        BookKeys.bookListBookIds,
        action.payload
      )
    case BookTypes.GET_BOOK_LIST_BOOK_IDS__ERROR:
      return setErrorState(stateProps, BookKeys.bookListBookIds, action.payload)

    default:
      return state
  }
}

export default reducer
