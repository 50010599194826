import { Builder, withChildren } from '@builder.io/react'
import { defaultTextChild } from '../helpers'
import { Accordion as FableAccordion } from '../../../../components/accordion'

interface Props {
  title: string
  children: React.ReactNode | React.ReactNode[]
}

const Accordion = ({ title, children }: Props) => (
  <FableAccordion title={title}>{children}</FableAccordion>
)

const AccordionWithChildren = withChildren(Accordion)

export const registerAccordion = () => {
  Builder.registerComponent(AccordionWithChildren, {
    name: 'Accordion',
    image:
      'https://tabler-icons.io/static/tabler-icons/icons-png/chevron-down.webp',
    inputs: [
      {
        name: 'title',
        type: 'text',
        defaultValue: 'This is the accordion title.',
      },
    ],
    defaultChildren: [defaultTextChild],
  })
}
