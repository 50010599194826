import { IconSVGProps } from '../types'

export const ChevronSlimIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.02317 10L13.9815 14.9583L12.8032 16.1367L6.6665 10L12.8032 3.86334L13.9815 5.04168L9.02317 10Z" />
  </svg>
)
