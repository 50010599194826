import { usePreloadImage } from '@fable/hooks'
import { css, cx, useTheme } from '@fable/theme'
import { ClubImage } from 'components/club_image'
import { Club } from '@fable/types'
import { ClubCardTag } from './ClubCardTag'

export const ClubCardHero: React.VFC<{ club: Club }> = ({ club }) => {
  const { mediaQueries, colors } = useTheme()
  const bookImage = usePreloadImage(club.current_book?.cover_image, 180)

  return (
    <div
      className={cx(
        css`
          width: 100%;
          height: 203px;
          position: relative;
          flex-shrink: 0;
          ${mediaQueries.desktop} {
            height: 245px;
          }
        `,
        'fds-ui--club-card-image'
      )}
    >
      <ClubCardTag club={club} />
      <ClubImage
        className={css`
          border-radius: 12px;
          mask-image: radial-gradient(white, black);
        `}
        club={club}
        position="top"
      />
      {/* book cover */}
      {!!bookImage?.src ? (
        <div
          className={css`
            width: 85px;
            height: 136px;
            border-radius: 8px;
            overflow: hidden;
            border: 2px solid ${colors.uiWhite};
            position: absolute;
            bottom: -17px;
            left: 14px;
            ${mediaQueries.desktop} {
              width: 79px;
              height: 117px;
            }
          `}
        >
          <img
            className={css`
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              transform: scale(1.1);
            `}
            src={bookImage.src}
            alt={`${club.current_book?.title} cover`}
          />
        </div>
      ) : null}
    </div>
  )
}
