import { get } from '@fable/api'
import { referralIdParamKey } from '../constants'
import { routes } from '../routes'

export const getReferralName = async (id: string) =>
  await get(`/v2/users/referral/${id}`)

export const getReferralUrl = (id: string | undefined) =>
  id!! &&
  `${window.location.origin}${routes.invite}/?${referralIdParamKey}=${id}`
