import { IconSVGProps } from '../types'

export const SearchIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={fill}
  >
    <path d="M14.0257 12.8475L17.5948 16.4158L16.4157 17.595L12.8473 14.0258C11.5196 15.0902 9.86817 15.6691 8.1665 15.6667C4.0265 15.6667 0.666504 12.3067 0.666504 8.16666C0.666504 4.02666 4.0265 0.666656 8.1665 0.666656C12.3065 0.666656 15.6665 4.02666 15.6665 8.16666C15.6689 9.86832 15.09 11.5198 14.0257 12.8475ZM12.354 12.2292C13.4116 11.1416 14.0022 9.68368 13.9998 8.16666C13.9998 4.94332 11.389 2.33332 8.1665 2.33332C4.94317 2.33332 2.33317 4.94332 2.33317 8.16666C2.33317 11.3892 4.94317 14 8.1665 14C9.68353 14.0024 11.1414 13.4118 12.229 12.3542L12.354 12.2292Z" />
  </svg>
)
