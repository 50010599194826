/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import get from 'lodash/get'
import { EditProfileData, User } from '@fable/types'
import { Response } from '../../types'
import { UserActions } from '../actions'
import { UserTypes } from '../action-types'
import {
  setClearState,
  setSuccessState,
  setRequestState,
  setErrorState,
} from '../stateHandlers'

export enum UserKeys {
  appSMS = 'appSMS',
  bookSMS = 'bookSMS',
  clubSMS = 'clubSMS',
  newsletter = 'newsletter',
  editedProfile = 'editedProfile',
  profile = 'profile',
  profileUpdated = 'profileUpdated',
  subscription = 'subscription',
}

export interface UserState {
  [UserKeys.appSMS]: Response
  [UserKeys.bookSMS]: Response
  [UserKeys.clubSMS]: Response
  [UserKeys.newsletter]: Response
  [UserKeys.editedProfile]: EditProfileData
  [UserKeys.profile]: Response<User>
  [UserKeys.profileUpdated]: Response
  [UserKeys.subscription]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: UserState = {
  [UserKeys.appSMS]: props,
  [UserKeys.bookSMS]: props,
  [UserKeys.clubSMS]: props,
  [UserKeys.newsletter]: props,
  [UserKeys.editedProfile]: {},
  [UserKeys.profile]: props,
  [UserKeys.profileUpdated]: props,
  [UserKeys.subscription]: props,
}

const reducer = (
  state: UserState = initialState,
  action: UserActions
): UserState => {
  const stateProps = { state, props }

  switch (action.type) {
    // EDIT PROFILE
    case UserTypes.EDIT_PROFILE:
      return {
        ...state,
        [UserKeys.editedProfile]: { ...action.payload },
      }

    // GET USER PROFILE
    case UserTypes.GET_USER_PROFILE__CLEAR:
      return setClearState(stateProps, UserKeys.profile)
    case UserTypes.GET_USER_PROFILE__REQUEST:
      return setRequestState(stateProps, UserKeys.profile)
    case UserTypes.GET_USER_PROFILE__SUCCESS:
      return setSuccessState(stateProps, UserKeys.profile, action.payload)
    case UserTypes.GET_USER_PROFILE__ERROR:
      return setErrorState(stateProps, UserKeys.profile, action.payload)

    // GET USER SUBSCRIPTION
    case UserTypes.GET_USER_SUBSCRIPTION__REQUEST:
      return setRequestState(stateProps, UserKeys.subscription)
    case UserTypes.GET_USER_SUBSCRIPTION__SUCCESS:
      return setSuccessState(stateProps, UserKeys.subscription, {
        result: action.payload,
        isSubscribed: Boolean(
          get(action.payload, 'subscriber.entitlements.basic')
        ),
      })
    case UserTypes.GET_USER_SUBSCRIPTION__ERROR:
      return setErrorState(stateProps, UserKeys.subscription, action.payload)

    // NEWSLETTER SUBSCRIBE
    case UserTypes.NEWSLETTER_SUBSCRIBE__REQUEST:
      return setRequestState(stateProps, UserKeys.newsletter)
    case UserTypes.NEWSLETTER_SUBSCRIBE__SUCCESS:
      return setSuccessState(stateProps, UserKeys.newsletter, {
        isSubscribed: true,
      })
    case UserTypes.NEWSLETTER_SUBSCRIBE__ERROR:
      return setErrorState(stateProps, UserKeys.newsletter, action.payload)

    // NEWSLETTER UNSUBSCRIBE
    case UserTypes.NEWSLETTER_UNSUBSCRIBE__REQUEST:
      return setRequestState(stateProps, UserKeys.newsletter)
    case UserTypes.NEWSLETTER_UNSUBSCRIBE__SUCCESS:
      return setSuccessState(stateProps, UserKeys.newsletter, {
        isSubscribed: false,
      })
    case UserTypes.NEWSLETTER_UNSUBSCRIBE__ERROR:
      return setErrorState(stateProps, UserKeys.newsletter, action.payload)

    // SEND APP LINK SMS
    case UserTypes.SEND_APP_LINK_SMS__REQUEST:
      return setRequestState(stateProps, UserKeys.appSMS)
    case UserTypes.SEND_APP_LINK_SMS__SUCCESS:
      return setSuccessState(stateProps, UserKeys.appSMS, {
        result: action.payload,
        isSent: true,
      })
    case UserTypes.SEND_APP_LINK_SMS__ERROR:
      return setErrorState(stateProps, UserKeys.appSMS, action.payload)
    case UserTypes.SEND_APP_LINK_SMS__CLEAR:
      return setClearState(stateProps, UserKeys.appSMS)

    // SEND BOOK LINK SMS
    case UserTypes.SEND_BOOK_LINK_SMS__REQUEST:
      return setRequestState(stateProps, UserKeys.bookSMS)
    case UserTypes.SEND_BOOK_LINK_SMS__SUCCESS:
      return setSuccessState(stateProps, UserKeys.bookSMS, {
        result: action.payload,
        isSent: true,
      })
    case UserTypes.SEND_BOOK_LINK_SMS__ERROR:
      return setErrorState(stateProps, UserKeys.bookSMS, action.payload)
    case UserTypes.SEND_BOOK_LINK_SMS__CLEAR:
      return setClearState(stateProps, UserKeys.bookSMS)

    // SEND CLUB INVITE SMS
    case UserTypes.SEND_CLUB_INVITE_SMS__REQUEST:
      return setRequestState(stateProps, UserKeys.clubSMS)
    case UserTypes.SEND_CLUB_INVITE_SMS__SUCCESS:
      return setSuccessState(stateProps, UserKeys.clubSMS, {
        result: action.payload,
        isSent: true,
      })
    case UserTypes.SEND_CLUB_INVITE_SMS__ERROR:
      return setErrorState(stateProps, UserKeys.clubSMS, action.payload)
    case UserTypes.SEND_CLUB_INVITE_SMS__CLEAR:
      return setClearState(stateProps, UserKeys.clubSMS)

    // UPDATE USER PROFILE
    case UserTypes.UPDATE_USER_PROFILE__REQUEST:
      return setRequestState(stateProps, UserKeys.profileUpdated)
    case UserTypes.UPDATE_USER_PROFILE__SUCCESS:
      return setSuccessState(stateProps, UserKeys.profileUpdated, {
        result: action.payload,
        isUpdated: true,
      })
    case UserTypes.UPDATE_USER_PROFILE__ERROR:
      return setErrorState(stateProps, UserKeys.profileUpdated, action.payload)
    case UserTypes.UPDATE_USER_PROFILE__CLEAR:
      return setClearState(stateProps, UserKeys.profileUpdated)

    default:
      return state
  }
}

export default reducer
