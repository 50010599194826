import { css, cx, useTheme } from '@fable/theme'
import { AUTH_PROVIDERS } from '@fable/api'
import { useActions } from '../../../../hooks/useActions'
import { Button, Icon, IconTypes } from '@fable/components'
import { ReactNode } from 'react'
import { useDeviceDetect } from '@fable/hooks'

export const AuthProviders: React.VFC<{
  redirectPath?: string
  onSubmit?: (type?: 'provider' | 'form') => void
  providers: Set<string>
  className?: string
}> = ({ onSubmit = null, providers, className }) => {
  const { authWithProvider } = useActions()
  const { colors, mediaQueries } = useTheme()
  const { isInAppBrowser } = useDeviceDetect()

  const onAuth = (provider: {
    name?: string
    icon?: ReactNode
    login: any
  }) => {
    authWithProvider(provider.login, isInAppBrowser)
    onSubmit && onSubmit('provider')
  }

  const renderProvider = AUTH_PROVIDERS.filter(
    (provider) => providers.has(provider.id) && provider.login
  ).map((provider, i) => (
    <Button
      dataTestId={`auth-provider-${provider.name.toLowerCase()}-button`}
      key={i}
      onClick={() => onAuth(provider)}
      secondary
      className={cx(
        css`
          width: 100%;
          margin-top: ${i === 0 ? '32px' : '12px'};
          .provider-logo {
            margin-right: 6px;
            position: relative;
            top: -1px;
          }
          span {
            margin-right: 4px;
          }
          .desktop-text {
            display: none;
          }
          .mobile-text {
            display: block;
          }
          ${mediaQueries.mobileXS} {
            .desktop-text {
              display: block;
            }
            .mobile-text {
              display: none;
            }
          }
        `,
        className
      )}
    >
      <Icon
        className={css`
          svg {
            width: 20px;
          }
        `}
        type={provider.icon as IconTypes}
        fill={colors.uiGraphite}
      />
      <span className="desktop-text">Continue with</span>
      <span className="mobile-text">Login with</span> {provider.name}
    </Button>
  ))

  return <div className="auth-providers">{renderProvider}</div>
}
