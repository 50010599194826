import { PageAnchor } from '../types'

const about: PageAnchor = {
  title: 'About',
  id: 'about',
}

const description: PageAnchor = {
  title: 'Description',
  id: 'description',
}

const features: PageAnchor = {
  title: 'Features',
  id: 'features',
}

const books: PageAnchor = {
  title: 'Books',
  id: 'books',
}

const hiw: PageAnchor = {
  title: 'How it works',
  id: 'how-it-works',
}

const faq: PageAnchor = {
  title: 'FAQ',
  id: 'faq',
}

const reviews: PageAnchor = {
  title: 'Reviews',
  id: 'reviews',
}

const author: PageAnchor = {
  title: 'Author',
  id: 'author',
}

const similarBooks: PageAnchor = {
  title: 'Similar Books',
  id: 'similar-books',
}

const bookClubsReading: PageAnchor = {
  title: 'Book Clubs Reading',
  id: 'book-clubs-reading',
}

const requestDemo: PageAnchor = {
  title: 'Request Demo',
  id: 'request-a-demo',
}

const startClub: PageAnchor = {
  title: 'Start a book club',
  id: 'start-club',
}

const clubPicks: PageAnchor = {
  title: 'Club picks',
  id: 'club-picks',
}

export const pageAnchors = {
  about,
  author,
  bookClubsReading,
  books,
  description,
  faq,
  features,
  hiw,
  requestDemo,
  reviews,
  similarBooks,
  startClub,
  clubPicks,
}
