import { Response } from '../../types'
import { GeoIpActions } from '../actions'
import { GeoIpTypes } from '../action-types'
import {
  setSuccessState,
  setRequestState,
  setErrorState,
} from '../stateHandlers'
import { GeoIpCountryResponse } from '../../types/geoIp'
import { GEO_IP_COUNTRY_SET } from '../../constants'
import { getCookie } from '../../utils'

export enum GeoIpKeys {
  geoIpCountry = 'geoIpCountry',
  geoIpBlocked = 'geoIpBlocked',
}

export interface GeoIpState {
  [GeoIpKeys.geoIpCountry]: Response<GeoIpCountryResponse>
  [GeoIpKeys.geoIpBlocked]: boolean
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: GeoIpState = {
  [GeoIpKeys.geoIpCountry]: {
    ...props,
    data: getCookie(GEO_IP_COUNTRY_SET)?.geoIpData || null,
  },
  [GeoIpKeys.geoIpBlocked]: getCookie(GEO_IP_COUNTRY_SET)?.blocked || false,
}

const reducer = (
  state: GeoIpState = initialState,
  action: GeoIpActions
): GeoIpState => {
  const stateProps = { state, props }

  switch (action.type) {
    // GET GEO IP COUNTRY
    case GeoIpTypes.GEO_IP_COUNTRY__REQUEST:
      return setRequestState(stateProps, GeoIpKeys.geoIpCountry)
    case GeoIpTypes.GEO_IP_COUNTRY__SUCCESS:
      return setSuccessState(stateProps, GeoIpKeys.geoIpCountry, action.payload)
    case GeoIpTypes.GEO_IP_COUNTRY__ERROR:
      return setErrorState(stateProps, GeoIpKeys.geoIpCountry, action.payload)

    // GEO IP BLOCKED
    case GeoIpTypes.GEO_IP_COUNTRY_BLOCKED:
      // Set via geoBlockSaga
      return {
        ...state,
        [GeoIpKeys.geoIpBlocked]: action.payload,
      }

    default:
      return state
  }
}

export default reducer
