import { FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Book, CartItem as CartItemType } from '@fable/types'
import { CartItemActions } from './CartItemActions'

export const CartItem = ({ item }: { item: CartItemType }) => {
  const { colors } = useTheme()
  const product = item.product as Book

  return (
    <FlexBox
      className={css`
        padding-bottom: 24px;
        &:not(:last-child) {
          margin-bottom: 24px;
          border-bottom: 1px solid ${colors.whiteSmoke};
        }
      `}
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexBox alignItems="stretch">
        <div
          className={css`
            width: 52px;
            height: 80px;
            border-radius: 4px;
            overflow: hidden;
            flex-shrink: 0;
          `}
        >
          <img
            className={css`
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              background-color: ${colors.uiPearlUltraLight};
            `}
            src={product.cover_image_small}
            alt={`${product.title} book cover`}
          />
        </div>
        <FlexBox
          className={css`
            margin-left: 12px;
          `}
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <Text tag="p" type="subhead" sizing="XS" truncate={2}>
              {product.title}
            </Text>
            {product.authors.length ? (
              <Text
                type="label"
                sizing="L"
                color={colors.hurricane}
                truncate={1}
              >
                By {product.authors[0].name}
              </Text>
            ) : null}
          </div>
          <Text tag="p" type="subhead" sizing="XS">
            ${product.price_usd}
          </Text>
        </FlexBox>
      </FlexBox>
      <CartItemActions item={item} />
    </FlexBox>
  )
}
