import './SMSForm.scss'
import React, { useEffect, useState } from 'react'

import { routes as marketingRoutes } from '../../app/marketing/routes'
import {
  Text,
  PhoneNumberField,
  PhoneNumber,
  PhoneNumberButton,
} from '@fable/components'
import { css, useTheme } from '@fable/theme'

interface Props {
  // Temp to add last minute feature changes
  liftPhoneNumber?: (phoneNumber: PhoneNumber) => void
  error: string
  onSend: (fullNumber: string) => void
  isSimple?: boolean
  description?: string
  button?: PhoneNumberButton
}

const SMSForm = ({
  liftPhoneNumber,
  error,
  onSend,
  isSimple = false,
  description,
  button,
}: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | null>(null)
  const { colors } = useTheme()

  useEffect(() => {
    // Temp to add last minute feature changes
    if (!phoneNumber) return
    liftPhoneNumber && liftPhoneNumber(phoneNumber)
  }, [liftPhoneNumber, phoneNumber])

  const errorMessage = (
    <Text
      className="error-message"
      type="label"
      sizing="L"
      color={colors.error}
    >
      {error}
    </Text>
  )

  const renderField = () => {
    return (
      <PhoneNumberField
        onChange={(number: PhoneNumber) => setPhoneNumber(number)}
        button={button}
      />
    )
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!phoneNumber?.code.length && !phoneNumber?.code.length) return
    const { code, number } = phoneNumber
    const fullNumber = code + number

    onSend(fullNumber)
  }

  const renderContent = () => {
    if (isSimple) {
      return (
        <form onSubmit={onSubmit}>
          {renderField()}
          {errorMessage}
        </form>
      )
    }

    return (
      <form
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
        `}
        onSubmit={onSubmit}
      >
        {description && (
          <Text
            className="description"
            type="body"
            sizing="M"
            color={colors.greyStrong}
            align="center"
          >
            {description}
          </Text>
        )}
        {renderField()}
        {errorMessage}
        <Text
          className={css`
            margin: 0 auto;
            max-width: 320px;
          `}
          type="label"
          sizing="L"
          align="center"
        >
          By clicking “Send”, I accept{' '}
          <a
            href={marketingRoutes.terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and have read the{' '}
          <a
            href={marketingRoutes.privacy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
        </Text>
      </form>
    )
  }

  return (
    <div
      className={css(
        'sms-form',
        isSimple ? 'simple' : '',
        !!error?.length ? 'error' : ''
      )}
    >
      {renderContent()}
    </div>
  )
}

export default SMSForm
