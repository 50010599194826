import { Quote } from '@fable/types'
import { css, useTheme } from '@fable/theme'
import { QuoteView } from '@fable/components'
import { ActivityCard } from '../ActivityCard'

interface Props {
  quote: Quote
  onClickLike?: () => void
  onClickComment?: () => void
}

export const ActivityQuote = ({
  quote,
  onClickComment,
  onClickLike,
}: Props) => {
  const { mediaQueries } = useTheme()

  return (
    <ActivityCard
      data-testid="activity-quote"
      author={{
        name: quote.user.display_name as string,
        image: quote.user?.pic as string,
        createdAt: quote.created_at,
        small: true,
        url: quote.user.url as string,
      }}
      // @note: counters object does not exist on Quote type currently.
      // Will need to set counters here when the BE is updated.
      counters={{ likes: 0, comments: 0 }}
      onClickComment={onClickComment}
      onClickLike={onClickLike}
    >
      <div
        className={css`
          ${mediaQueries.mobileL} {
            padding: 8px 24px 0;
          }
        `}
      >
        <QuoteView text={quote.text} book={quote.book} theme={quote.theme} />
      </div>
    </ActivityCard>
  )
}
