import { useRef, useState } from 'react'
import { Colors, css, cx, useTheme } from '@fable/theme'
import { useClickOutside } from '@fable/hooks'
import { COUNTRY_CODES, DEFAULT_COUNTRY_CODE } from '@fable/utils'
import {
  Text,
  ChevronIcon,
  TextInput,
  Button,
  PhoneNumberButton,
  PhoneNumber,
} from './'

/**
 * You MUST add the following script to your index.html file
 *
 * https://cdn.jsdelivr.net/npm/world-flags-sprite@0.0.2/stylesheets/flags32.css
 */

const PhoneNumberField = ({
  onChange,
  className = '',
  error,
  button,
}: {
  onChange: (args: { code: string; number: string }) => void
  className?: string
  error?: boolean
  button?: PhoneNumberButton
}) => {
  const { colors } = useTheme()
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
    code: DEFAULT_COUNTRY_CODE.dial_code,
    number: '',
  })
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const hasButton = !!button?.show || !!button?.onClick

  const optionsRef = useRef<null | HTMLDivElement>(null)

  useClickOutside(optionsRef, () => closeOptions())

  const openOptions = () => {
    if (!isOptionsOpen) setIsOptionsOpen(true)
  }

  const closeOptions = () => {
    if (isOptionsOpen) setIsOptionsOpen(false)
  }

  const onSelect = (code: string) => {
    setPhoneNumber({ ...phoneNumber, code })
    onChange({ ...phoneNumber, code })
    closeOptions()
  }

  const validatePhoneNumber = (number: string) => {
    const re = new RegExp(/^[0-9\s()]*$/g)

    if (number.match(re)) {
      setPhoneNumber({ ...phoneNumber, number })
      onChange({ ...phoneNumber, number })
    }
  }

  const renderDialCodeOptions = () => {
    const open = isOptionsOpen ? 'open' : ''

    const code =
      phoneNumber.code !== ''
        ? phoneNumber.code
        : DEFAULT_COUNTRY_CODE.dial_code

    return (
      <>
        <div
          className={styles(colors).dialCode}
          onFocus={openOptions}
          onBlur={closeOptions}
          onClick={openOptions}
        >
          <Text type="body" sizing="M">
            {code}
          </Text>
          <ChevronIcon />
        </div>
        <div
          className={cx(styles(colors).options, styles(colors).list, open)}
          ref={optionsRef}
        >
          <ul className="f32">{renderCountryCodes}</ul>
        </div>
      </>
    )
  }

  const renderCountryCodes = COUNTRY_CODES.map(
    (
      country: { code: string; name: string; dial_code: string },
      index: number
    ) => {
      const { code, name, dial_code } = country

      return (
        <li
          key={index}
          onMouseDown={() => onSelect(dial_code)}
          onTouchStart={() => onSelect(dial_code)}
        >
          <span className="country-info">
            <span className={cx('flag', code.toLocaleLowerCase())} />
            <Text
              className="name"
              type="subhead"
              sizing="XS"
              text={name}
              tag="p"
            />
          </span>
          <span className="dial-code">{dial_code}</span>
        </li>
      )
    }
  )

  return (
    <div
      className={cx(
        styles(colors).phoneNumberField,
        isOptionsOpen && styles(colors).open,
        hasButton && styles(colors).fieldWithButton,
        className
      )}
    >
      {renderDialCodeOptions()}
      <TextInput
        onChangeText={validatePhoneNumber}
        placeholder="Phone number"
        type="tel"
        value={phoneNumber.number}
        required
        name="user-tel"
        error={error}
      />
      {hasButton ? (
        <Button
          className={css`
            width: 100%;
            max-width: 60px;
            height: 40px;
            position: absolute;
            inset: 50% 8px auto auto;
            transform: translateY(-50%);
            z-index: 1;
          `}
          onClick={() => !!button?.onClick && button.onClick()}
          loading={!!button?.loading}
        >
          Send
        </Button>
      ) : null}
    </div>
  )
}

export default PhoneNumberField

const styles = (colors: Colors) => ({
  phoneNumberField: css`
    position: relative;
    width: 100%;

    input {
      padding-left: 100px !important;
    }
  `,
  fieldWithButton: css`
    input {
      width: calc(100% - 62px) !important;
    }
  `,
  dialCode: css`
    padding: 0 10px 0 24px;
    width: 88px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    span {
      max-width: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      width: 12px;
      height: 8px;
    }

    :after {
      content: '';
      width: 1px;
      height: 50%;
      background: ${colors.whiteSmoke};
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  options: css`
    padding: 12px 0;
    max-width: 260px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        padding: 4px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .country-info {
          margin-right: 8px;
          color: ${colors.greyStrong};
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .flag {
            margin-right: 8px;
            width: 24px !important;
            height: 24px !important;
            object-fit: cover;
            overflow: hidden;
            margin-right: 8px;
            position: relative;
            top: -3px;
          }

          .name {
            text-align: left;
            width: calc(100% - 32px);
          }
        }

        .dial-code {
          color: ${colors.hurricane};
        }
      }
    }
  `,
  open: css`
    svg {
      transform: rotate(180deg);

      path {
        fill: ${colors.greyMedium};
      }
    }
  `,
  list: css`
    border: 1px solid ${colors.whiteSmoke};
    background: #fff;
    border-radius: 8px;
    width: 100%;
    max-height: 160px;
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: 62px;
    box-shadow: 0 4px 14px ${colors.lightGrey};
    transition: opacity ease 0.2s;
    &.open {
      opacity: 1;
      pointer-events: auto;
    }
  `,
})
