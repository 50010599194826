import { extractColors } from 'extract-colors'
import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@fable/theme'

interface PaletteColor {
  hex: string
  red: number
  green: number
  blue: number
  area: number
  hue: number
  saturation: number
  lightness: number
  intensity: number
}

const getImageUrl = (url: string) => {
  if (
    !url ||
    (!url.includes('cdn.fable.co') && !url.includes('images.ctfassets.net'))
  )
    return null
  return url.replace('cdn.fable.co', 'img.fablecdn.net/images/cdn.fable.co')
}

const useImageColor = (url: string, options?: any) => {
  const [colors, setColors] = useState<PaletteColor[] | null>(null)
  const { colors: themeColors } = useTheme()

  const getColors = useCallback(async () => {
    const imageUrl = getImageUrl(url)
    if (!imageUrl) return null
    await extractColors(imageUrl, {
      crossOrigin: 'anonymous',
      ...options,
    }).then((data) => setColors(data))
  }, [url, options])

  useEffect(() => {
    if (!url) return
    getColors()
  }, [getColors, url])

  const getColor = () => {
    if (!colors?.length) return null
    const color = colors.reduce((prev, cur) => {
      return cur.hue > prev.hue ? prev : cur
    })
    const isTooLight =
      color?.red > 200 && color?.blue > 200 && color?.green > 200
    return isTooLight ? null : color.hex
  }

  const color = getColor()

  return !!color ? color : themeColors.fableGreen
}

export default useImageColor
