import './scss/global.scss'
import './app/marketing/global.scss'
import './app/community/global.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from 'app/App'
import { Providers } from 'state'
import { initSentry } from 'utils'
import { initBuilderIo } from 'services/builder.io'
import { analytics } from 'segment_analytics'

initSentry()
initBuilderIo()
analytics.handleTrackingParams()
analytics.utm.handleUTMTrackingParams()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
)
