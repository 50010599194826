export enum ClubTypes {
  // GET CLUB DETAILS
  GET_CLUB_DETAILS__ERROR = 'GET_CLUB_DETAILS__ERROR',
  GET_CLUB_DETAILS__REQUEST = 'GET_CLUB_DETAILS__REQUEST',
  GET_CLUB_DETAILS__SUCCESS = 'GET_CLUB_DETAILS__SUCCESS',
  GET_CLUB_DETAILS__CLEAR = 'GET_CLUB_DETAILS__CLEAR',

  // CREATE CLUB INVITE
  CREATE_CLUB_INVITE__ERROR = 'CREATE_CLUB_INVITE__ERROR',
  CREATE_CLUB_INVITE__REQUEST = 'CREATE_CLUB_INVITE__REQUEST',
  CREATE_CLUB_INVITE__SUCCESS = 'CREATE_CLUB_INVITE__SUCCESS',

  // CREATE CLUB MEMBERSHIP
  CREATE_CLUB_MEMBERSHIP__ERROR = 'CREATE_CLUB_MEMBERSHIP__ERROR',
  CREATE_CLUB_MEMBERSHIP__REQUEST = 'CREATE_CLUB_MEMBERSHIP__REQUEST',
  CREATE_CLUB_MEMBERSHIP__SUCCESS = 'CREATE_CLUB_MEMBERSHIP__SUCCESS',

  // GET FEATURED CLUBS
  GET_FEATURED_CLUBS__ERROR = 'GET_FEATURED_CLUBS__ERROR',
  GET_FEATURED_CLUBS__REQUEST = 'GET_FEATURED_CLUBS__REQUEST',
  GET_FEATURED_CLUBS__SUCCESS = 'GET_FEATURED_CLUBS__SUCCESS',

  // GET CLUB MEMBERSHIP
  GET_CLUB_MEMBERSHIP__ERROR = 'GET_CLUB_MEMBERSHIP__ERROR',
  GET_CLUB_MEMBERSHIP__REQUEST = 'GET_CLUB_MEMBERSHIP__REQUEST',
  GET_CLUB_MEMBERSHIP__SUCCESS = 'GET_CLUB_MEMBERSHIP__SUCCESS',
  GET_CLUB_MEMBERSHIP__CLEAR = 'GET_CLUB_MEMBERSHIP__CLEAR',

  // GET CLUB TRACK
  GET_CLUB_TRACK__ERROR = 'GET_CLUB_TRACK__ERROR',
  GET_CLUB_TRACK__REQUEST = 'GET_CLUB_TRACK__REQUEST',
  GET_CLUB_TRACK__SUCCESS = 'GET_CLUB_TRACK__SUCCESS',
  GET_CLUB_TRACK__CLEAR = 'GET_CLUB_TRACK__CLEAR',

  // TAPPED_CAROUSEL_CLUB_CARD
  TAPPED_CAROUSEL_CLUB_CARD = 'TAPPED_CAROUSEL_CLUB_CARD',
}
