import './SMSSent.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import successIcon from '../../assets/icons/standard/success-icon.svg'
import { routes } from '../../app/store/routes'
import { useActions } from '../../hooks/useActions'
import { Text } from '@fable/components'

export const BookLinkSent = ({ isOwned }) => {
  const { clearBookLinkSMS, closeModal } = useActions()

  useEffect(() => {
    setTimeout(() => {
      closeModal()
      clearBookLinkSMS()
    }, 3000)
  }, [closeModal, clearBookLinkSMS])

  const sentTitle = 'Link sent'
  const newBookInstructions = `Your free book has been added to your <a href="${routes.bookshelf}">Fable Bookshelf</a> and is ready to read on the app.`
  const bookInstructions = `This book has been added to your bookshelf in the app`
  const description = isOwned ? bookInstructions : newBookInstructions

  return (
    <div className="sms-sent book-link-sent">
      <img className="icon" src={successIcon} alt="Link Sent" />
      <Text
        className="title"
        type="header"
        sizing={{ base: 'M', mobileL: 'L' }}
        tag="h2"
      >
        {sentTitle}
      </Text>
      <Text
        className="description"
        type="body"
        sizing="M"
        dangerouslySetInnerHTML={{ __html: description }}
      ></Text>
    </div>
  )
}

BookLinkSent.propTypes = {
  isOwned: PropTypes.bool,
}

BookLinkSent.defaultProps = {
  isOwned: false,
}
