import { routes as storeRoutes } from '../../app/store/routes'
import { routes as marketingRoutes } from '../../app/marketing/routes'
import { routes as communityRoutes } from '../../app/community/routes'

const subheaderRoutes = [
  storeRoutes.folios,
  storeRoutes.bookshelf,
  storeRoutes.accountSettings,
  storeRoutes.profile,
  storeRoutes.accountOrders,
  storeRoutes.inviteReferral,
  storeRoutes.genres,
  marketingRoutes.company,
  marketingRoutes.press,
  communityRoutes.blog,
  communityRoutes.events,
]

export default subheaderRoutes
