import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation()
  const previousPath = useRef(location.pathname)

  useEffect(() => {
    if (location.pathname !== previousPath.current) {
      setTimeout(() => {
        window.scroll(0, 0)
      }, 300)
    }

    previousPath.current = location.pathname
  }, [location])

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scroll(0, 0)
    }
  }, [])

  return <>{children}</>
}

export default ScrollToTop
