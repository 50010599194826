import { Author, Book, ClubGenre } from '@fable/types'
import sortBy from 'lodash/sortBy'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import { isUUID } from './string'

export const getAuthorNames = (authors: Author[] | undefined) => {
  if (!authors?.length) return

  if (authors.length > 1) {
    let result = ''

    const authorsList = authors.slice(0, 2)

    authorsList.forEach((author, i) => {
      const suffix = i !== authors.length - 1 ? ' & ' : ''
      result += `${author.name}${suffix}`
    })

    return result
  }

  return authors[0].name
}

export const getAuthors = ({
  authors,
  limit = 2,
}: {
  authors: Author[]
  limit?: number
}) => {
  if (!authors?.length) return
  return authors.slice(0, limit)
}

export const isBookPreorder = (book: Book | undefined) =>
  !!book?.published_date &&
  book?.can_purchase &&
  isAfter(new Date(book?.published_date), new Date())

export const getBookDate = (date: string | undefined) => {
  if (!date) return

  // People in the US were seeing dates a day behind the date received in the API response
  const dateString = new Date(date)
  const dateWithNoTimezone = new Date(
    dateString.valueOf() + dateString.getTimezoneOffset() * 60 * 1000
  )

  return format(dateWithNoTimezone, 'MMMM d, yyyy')
}

export const sortGenres = (genres: ClubGenre[]) =>
  sortBy(genres, 'club_count').reverse()

export const getBookDiscount = ({
  price,
  discount = 5,
}: {
  price: string
  discount?: number
}) => {
  const priceNum = parseFloat(price)
  const discountedAmount = priceNum - discount
  const precision = 2
  const base = 10 ** precision
  const result = (Math.round(discountedAmount * base) / base).toFixed(precision)
  const percentage = Math.round((discount / priceNum) * 100)

  return { discount: '$' + result, percentageOff: percentage + '%' }
}

export const getIsbnFromSlug = (slug: string) => {
  const lastDashIndex = slug.lastIndexOf('-')
  if (lastDashIndex !== -1 && !isUUID(slug)) {
    return slug.substring(lastDashIndex + 1)
  }

  return slug
}

export const formatRating = (rating: number) => {
  const dec = rating % 1
  if (dec < 0.5) {
    const result = rating - dec

    return result.toFixed(1)
  }

  return rating - dec + 0.5
}
