import { css, CSSInterpolation, cx } from '@fable/theme'
import { forwardRef } from 'react'

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse'
  flexWrap?: 'wrap' | 'wrap-reverse'
  justifyContent?:
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?: 'flex-end' | 'center' | 'baseline' | 'stretch'
  centerAll?: boolean
  gap?: string
}

const FlexBox = (
  {
    children,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    centerAll,
    gap,
    ...nativeProps
  }: Props,
  ref: any
) => {
  const styles: CSSInterpolation = { display: 'flex' }

  if (flexDirection) styles.flexDirection = flexDirection
  if (flexWrap) styles.flexWrap = flexWrap
  if (justifyContent) styles.justifyContent = justifyContent
  if (alignItems) styles.alignItems = alignItems
  if (centerAll) {
    styles.justifyContent = 'center'
    styles.alignItems = 'center'
  }
  if (gap) {
    styles.gap = gap
  }

  return (
    <div
      ref={ref}
      {...nativeProps}
      className={cx(css(styles), nativeProps.className)}
    >
      {children}
    </div>
  )
}

export default forwardRef(FlexBox)
