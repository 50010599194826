import { FlexBox, marginEdgesRules } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import React from 'react'
import { CompProps } from 'types'
import { PageAnchorContainerWidth } from '../types'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  containerWidth?: PageAnchorContainerWidth
}

export const PageDetailAnchorNavContainer = ({
  children,
  containerWidth,
  className,
  ...props
}: CompProps<Props>) => {
  const { colors, mediaQueries } = useTheme()

  return (
    <FlexBox
      className={cx(
        css`
          overflow: hidden;
          padding: 0 ${marginEdgesRules.mobile};
          background-color: ${colors.uiWhite};
          ${mediaQueries.mobileL} {
            padding: 0 ${marginEdgesRules.tablet};
          }
        `,
        className
      )}
      width="100%"
      justifyContent="center"
      {...props}
    >
      <div
        className={css`
          max-width: ${containerWidth?.mobile || '352px'};
          width: 100%;
          ${mediaQueries.tabletL} {
            max-width: ${containerWidth?.desktop || '1044px'};
          }
        `}
      >
        {children}
      </div>
    </FlexBox>
  )
}
