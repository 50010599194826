import { useEffect, useState } from 'react'
import { Source as StripeSource } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { useTypedSelector } from '../hooks'
import isEmpty from 'lodash/isEmpty'

export const useRetrieveStripeSource = (): StripeSource => {
  const [stripeSource, setStripeSource] = useState({} as StripeSource)
  const stripe = useStripe()
  const { stripeSourceId, stripeSourceClientSecret } = useTypedSelector(
    ({ billing }) => billing
  )

  useEffect(() => {
    if (
      stripeSourceId &&
      isEmpty(stripeSource) &&
      stripeSourceClientSecret &&
      !!stripe
    ) {
      stripe
        .retrieveSource({
          id: stripeSourceId,
          client_secret: stripeSourceClientSecret,
        })
        .then((result) => setStripeSource(result.source as StripeSource))
    }
  }, [stripe, stripeSource, stripeSourceId, stripeSourceClientSecret])

  return stripeSource
}
