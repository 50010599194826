type StateProps = { state: any; props: any }

export const setAction = (type: any, payload?: any) => {
  if (payload) {
    return {
      type,
      payload,
    }
  }
  return {
    type,
  }
}

export const setClearState = ({ state, props }: StateProps, key: string) => {
  return {
    ...state,
    [key]: { ...props },
  }
}

export const setRequestState = ({ state, props }: StateProps, key: string) => {
  return {
    ...state,
    [key]: { ...props, loading: true },
  }
}

export const setSuccessState = (
  { state, props }: StateProps,
  key: string,
  data: any
) => {
  return {
    ...state,
    [key]: { ...props, data },
  }
}

export const setErrorState = (
  { state, props }: StateProps,
  key: string,
  error: Error
) => {
  return {
    ...state,
    [key]: { ...props, error },
  }
}
