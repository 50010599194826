type SegmentProduct = {
  product_id: string
  sku: string
  category: string
  name: string
  brand: string
  variant: string
  price: number
  quantity: number
  coupon: string
  position: number
  url: string
  image_url: string
}

type GaProduct = {
  item_name: string
  item_id: string
  price: number
  item_brand: string
  item_category: string
  item_variant: string
  quantity: number
}

const SEGMENT_TO_GA_PRODUCT_PROPERTIES: Record<
  keyof SegmentProduct,
  keyof GaProduct | undefined
> = {
  sku: 'item_id',
  name: 'item_name',
  category: 'item_category',
  price: 'price',
  brand: 'item_brand',
  variant: 'item_variant',
  quantity: 'quantity',
  coupon: undefined,
  image_url: undefined,
  position: undefined,
  product_id: undefined,
  url: undefined,
}

type MappingKeyPair = [keyof SegmentProduct, keyof GaProduct]

function segmentToGaProduct(
  segmentProduct: Partial<SegmentProduct>
): Partial<GaProduct> {
  const gaProduct: Partial<GaProduct> = {}
  for (const [segmentKey, gaKey] of Object.entries(
    SEGMENT_TO_GA_PRODUCT_PROPERTIES
  ) as MappingKeyPair[]) {
    if (gaKey && segmentProduct[segmentKey]) {
      // @ts-ignore - bizarre TS error below, not sure how to solve
      gaProduct[gaKey] = segmentProduct[segmentKey]
    }
  }
  return gaProduct
}

function segmentToGaProducts(segmentProducts: Partial<SegmentProduct>[]) {
  return segmentProducts.map(segmentToGaProduct)
}

type SegmentEcommProps = {
  products: Partial<SegmentProduct>[]
  revenue: number
  order_id: string
}

enum SegmentEcomEvent {
  ProductViewed = 'Product Viewed',
  CheckoutStarted = 'Checkout Started',
  PaymentInfoEntered = 'Payment Info Entered',
  OrderCompleted = 'Order Completed',
}

const SEGMENT_TO_GA: Record<
  SegmentEcomEvent,
  (event: string, properties: SegmentEcommProps) => any
> = {
  'Product Viewed': (event: string, properties: SegmentEcommProps) => ({
    event: 'view_item',
    ecommerce: {
      items: segmentToGaProducts(properties.products),
    },
  }),
  'Checkout Started': (event: string, properties: SegmentEcommProps) => ({
    event: 'begin_checkout',
    ecommerce: {
      items: segmentToGaProducts(properties.products),
    },
  }),
  'Payment Info Entered': (event: string, properties: SegmentEcommProps) => ({
    event: 'add_payment_info',
    ecommerce: {
      items: segmentToGaProducts(properties.products),
    },
  }),
  'Order Completed': (event: string, properties: SegmentEcommProps) => ({
    event: 'purchase',
    ecommerce: {
      transaction_id: properties.order_id,
      value: properties.revenue,
      items: segmentToGaProducts(properties.products),
    },
  }),
}

function isSegmentEcomEvent(event: string): event is SegmentEcomEvent {
  return SEGMENT_TO_GA.hasOwnProperty(event)
}

export const gtm = {
  sendEvent: (event: string, data?: Record<string, unknown>) => {
    if (isSegmentEcomEvent(event) && data) {
      const mapper = SEGMENT_TO_GA[event]
      const result = mapper(event, data as SegmentEcommProps)
      if (result.ecommerce) {
        // clear the ecommerce object
        // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
        window.dataLayer.push({ ecommerce: null })
      }
      window.dataLayer.push(result)
    } else {
      window.dataLayer.push({ event, ...data })
    }
  },
}
