// get the last segment of pathname
export const getSlug = (pathname = '') =>
  pathname.substring(pathname.lastIndexOf('/') + 1)

export const urlify = ({
  text,
  className = '',
  stopPropagation = false,
}: {
  text: string
  className?: string
  stopPropagation?: boolean
}) => {
  const urlRegex =
    /[-a-zA-Z0-9@:%_+.~#&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9-_//=]*)?(?:\?([a-zA-Z0-9-_&=/]+))?/gi

  const parsedText = text.replace(urlRegex, (url) => {
    const href = url.startsWith('http') ? url : `https://${url}`
    const hyperlink = `<a ${
      !!className?.length ? `class="${className}"` : ''
    } href="${href}" target="_blank" rel="noreferrer" ${
      stopPropagation ? 'onclick="event.stopPropagation();"' : ''
    }>${url}</a>`

    return hyperlink
  })

  const hasUrl = urlRegex.test(text)

  return { text: parsedText, hasUrl }
}

export function getParameterByName(name: string, str = window.location.href) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regexS = '[\\?&]' + name + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(str)
  if (results == null) return ''
  else return decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const isWebView = !!getParameterByName('wv')
