import { cx, css, useTheme, FableTheme } from '@fable/theme'
import { FlexBox } from '@fable/components'

interface Props {
  className?: string
  direction?: 'horizontal' | 'vertical'
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

export const DropdownControl: React.VFC<Props> = ({
  className,
  onClick,
  direction,
}) => {
  const theme = useTheme()
  const horizontal = direction === 'horizontal'
  const styles = getStyles(theme, horizontal)

  return (
    <FlexBox
      className={cx('dropdown-control', styles.control, className)}
      onClick={onClick}
      flexDirection={horizontal ? 'row' : 'column'}
      centerAll
    >
      <span className={styles.circle} />
      <span className={styles.circle} />
      <span className={styles.circle} />
    </FlexBox>
  )
}

const getStyles = ({ colors, motion }: FableTheme, horizontal: boolean) => ({
  control: css`
    padding: 0 5px;
    margin-right: ${horizontal ? 0 : 25}px;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover span {
      background: ${colors.blackSwan};
    }
  `,
  circle: css`
    display: block;
    background: ${colors.greyNeutral};
    border-radius: 50%;
    width: 4px;
    height: 4px;
    ${motion.transition('background')}

    :not(:last-child) {
      margin-bottom: ${horizontal ? 0 : 3}px;
      margin-right: ${horizontal ? 3 : 0}px;
    }
  `,
})
