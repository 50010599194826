import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { fork, all } from 'redux-saga/effects'

import { verifyAuth } from './action-creators'
import reducers from './reducers'
import sagas from '../sagas'

const sagaMiddleware = createSagaMiddleware()

function* rootSaga() {
  yield all(sagas.map(fork))
}

export const storeConfig = (persistedState) => {
  const store = createStore(
    reducers,
    persistedState,
    composeWithDevTools(applyMiddleware(thunk, sagaMiddleware))
  )
  sagaMiddleware.run(rootSaga)
  store.dispatch(verifyAuth())
  return store
}
