import { lsKeys } from '../constants'
import { useEffect } from 'react'
import { useActions } from './useActions'

export const useAuthProviderRedirect = () => {
  const { authProviderRedirect } = useActions()

  useEffect(() => {
    const redirect = localStorage.getItem(lsKeys.postAuthRedirect)
    if (!!redirect) {
      authProviderRedirect(JSON.parse(redirect))
    }
  }, [authProviderRedirect])
}
