import { Button } from '@fable/components'
import { css } from '@fable/theme'
import { Link } from 'react-router-dom'

interface Props {
  text: string
  link: string
}

export const HomeFeaturesLink = ({ text, link }: Props) => {
  return (
    <Link to={link}>
      <Button
        className={css`
          margin-top: 33px;
          width: 160px;
          height: 52px;
        `}
      >
        {text}
      </Button>
    </Link>
  )
}
