import { add, isAfter, isBefore, isToday, parse } from 'date-fns'
import isDate from 'lodash/isDate'

export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email)

export const validateDate = (date: string | Date) => {
  if (date === '') return false

  const dateFormat = 'yyyy-MM-dd'
  const parsedDate = isDate(date)
    ? date
    : parse(date as string, dateFormat, new Date())

  const before = isBefore(parsedDate, new Date())
  const after = isAfter(parsedDate, add(new Date(), { years: 1 }))
  return (!before && !after) || isToday(parsedDate as Date)
}
