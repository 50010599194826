import { useTheme } from '@fable/theme'

interface Props {
  className?: string
  light?: boolean
}

export const LogoV2 = ({ className = '', light = false }: Props) => {
  const { colors } = useTheme()
  const textColor = light ? colors.uiPearlExtraLight : colors.uiGraphite

  return (
    <svg
      className={className}
      width="82"
      height="33"
      viewBox="0 0 82 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1067 0C0 0 0 9.87337 0 16.1067C0 22.34 0 32.2134 16.1067 32.2134C32.2134 32.2134 32.2134 22.34 32.2134 16.1067C32.2134 9.87337 32.2134 0 16.1067 0Z"
        fill="#042119"
      />
      {/* Icon */}
      <g clipPath="url(#clip0_790_7642)">
        <path
          d="M11.3373 10.4023L10.0031 11.1942L13.8545 18.0513L15.1887 17.2594L11.3373 10.4023Z"
          fill="#FF916F"
        />
        <path
          d="M7.10537 14.1729L6.33508 15.5443L13.0057 19.5033L13.776 18.1319L7.10537 14.1729Z"
          fill="#E979C4"
        />
        <path
          d="M12.9809 19.6064H5.27808V21.1901H12.9809V19.6064Z"
          fill="#D12E2E"
        />
        <path
          d="M26.8477 19.6064H19.1449V21.1901H26.8477V19.6064Z"
          fill="#4252F1"
        />
        <path
          d="M25.0165 14.1729L18.3458 18.1319L19.1161 19.5033L25.7868 15.5443L25.0165 14.1729Z"
          fill="#43A1D7"
        />
        <path
          d="M20.788 10.4023L16.9365 17.2595L18.2707 18.0513L22.122 11.1942L20.788 10.4023Z"
          fill="#81BA6D"
        />
        <path
          d="M16.8318 9.31323H15.2913V17.2314H16.8318V9.31323Z"
          fill="#F2D63F"
        />
      </g>
      {/* Text */}
      <path
        d="M38.1946 13.0304V11.5038C38.1946 9.55799 40.0001 8.85864 41.5318 8.85864C42.736 8.85864 43.4833 9.18077 43.9934 9.52064L43.2823 11.3152C42.9721 11.0127 42.4799 10.7485 41.6778 10.7485C40.8755 10.7485 40.2003 11.0127 40.2003 11.9763V13.0314H43.4589V14.9203H40.2011V23.3105H38.1946V14.9203H35.8058V13.0304H38.1946Z"
        fill={textColor}
      />
      <path
        d="M67.2888 9.14219H69.2955V23.3112H67.2888V9.14219Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.8437 18.116C69.8437 21.0815 72.3062 23.5941 75.8624 23.5941C78.5441 23.5941 80.3489 22.1963 81.134 20.6286L79.2184 19.7593C78.7263 20.8368 77.7951 21.8004 75.8624 21.8004C73.8568 21.8004 72.4154 20.7603 72.0513 18.9853H81.2962C81.3153 18.7957 81.3521 18.229 81.3521 17.8891C81.3521 15.1496 78.8534 12.637 75.5884 12.637C72.3415 12.637 69.8437 15.1496 69.8437 18.116ZM79.1084 17.1898H72.0693C72.4334 15.5465 73.7658 14.4316 75.5884 14.4316C77.339 14.4316 78.7443 15.5082 79.1084 17.1898Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.2898 12.637C59.5573 12.637 58.2069 13.3364 57.2956 14.3569V9.14219H55.288V19.5259L55.2935 20.9377C55.2935 21.4139 54.9184 21.8014 54.4587 21.8014C53.999 21.8014 53.6259 21.4139 53.6259 20.9377V15.5082C53.6259 13.8649 51.7662 12.637 48.6472 12.637C45.9844 12.637 44.324 13.8462 43.4677 14.9228V14.9237L45.221 15.7183C46.1584 14.8593 47.3771 14.4316 48.7923 14.4316C50.6349 14.4316 51.6202 15.0936 51.6202 15.7351C51.6202 16.567 50.799 16.793 47.7719 17.0199C45.2191 17.2085 43.1395 18.0021 43.1395 20.2692C43.1395 22.8191 45.4742 23.5931 47.0977 23.5931C49.0845 23.5931 50.6349 22.8761 51.7103 21.6482C51.7153 21.6715 51.7212 21.693 51.7271 21.7144C51.7309 21.7283 51.7347 21.7423 51.7382 21.7565C51.7744 21.919 51.8202 22.0535 51.877 22.1618C52.372 23.3121 53.5654 23.5885 54.4596 23.5885C55.4511 23.5885 56.8151 23.2551 57.1801 21.7603L57.2956 21.8929C58.2069 22.9134 59.5573 23.5931 61.2898 23.5931C64.2807 23.5931 66.7434 21.2878 66.7434 18.1151C66.7434 14.961 64.2817 12.637 61.2898 12.637ZM51.622 19.9862C50.5277 21.3084 49.2691 21.8565 47.5366 21.8565C45.7862 21.8565 45.33 21.0068 45.33 20.2701C45.33 19.4382 45.8592 18.947 48.0288 18.7388C49.1961 18.6258 50.7467 18.4176 51.622 17.9265V19.9862ZM60.9616 21.7995C58.773 21.7995 57.2965 20.2122 57.2965 18.116C57.2965 16.0376 58.7738 14.4316 60.9616 14.4316C63.1503 14.4316 64.6458 16.0385 64.6458 18.116C64.6458 20.2122 63.1503 21.7995 60.9616 21.7995Z"
        fill={textColor}
      />
      <defs>
        <clipPath id="clip0_790_7642">
          <rect
            width="21.5682"
            height="12.957"
            fill="white"
            transform="translate(5.27869 8.77539)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
