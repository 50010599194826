import { useContext, useEffect } from 'react'
import { useScroll } from '@fable/hooks'
import { css } from '@fable/theme'
import { PaginationContainerContext } from './PaginationContainer'
import { Button, FlexBox, Logo } from '../'

const PaginationContainerFullPage = ({
  listEnd,
  children,
  refetchButton = false,
}: {
  listEnd?: React.ReactNode
  children: React.ReactNode
  refetchButton?: boolean
}) => {
  const { position, windowBottomReached } = useScroll()
  const { query } = useContext(PaginationContainerContext)

  useEffect(() => {
    if (refetchButton) return

    if (
      windowBottomReached &&
      query.isFetchedAfterMount &&
      !query.isFetchingNextPage &&
      query.hasNextPage
    ) {
      query.fetchNextPage()
    }
  }, [position, query, windowBottomReached, refetchButton])

  return (
    <>
      {children}
      <FlexBox
        className={css`
          margin-top: 24px;
        `}
        centerAll
      >
        {query.isFetchingNextPage && <Logo isIcon animate />}
        {!query.hasNextPage && listEnd}
        {refetchButton && !query.isFetchingNextPage && query.hasNextPage ? (
          <Button width="100%" onClick={() => query.fetchNextPage()}>
            View more
          </Button>
        ) : null}
      </FlexBox>
    </>
  )
}

export default PaginationContainerFullPage
