/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

import { createContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { emptyFunction } from '../../utils/function'

export type LightboxContextType = {
  isActive: boolean
  openLightbox: (id: string) => void
  closeLightbox: () => void
  videoId: string
}

export const LightboxContext = createContext<LightboxContextType>({
  isActive: false,
  openLightbox: emptyFunction,
  closeLightbox: emptyFunction,
  videoId: '',
})

interface LightboxProviderProps {
  children: React.ReactNode
}

export const LightboxProvider: React.FC<LightboxProviderProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [videoId, setVideoId] = useState('')

  const { Provider } = LightboxContext

  const openLightbox = (id: string) => {
    if (isEmpty(id)) return

    document.body.style.overflow = 'hidden'

    setVideoId(id)

    setTimeout(() => {
      setIsActive(true)
    }, 400)
  }

  const closeLightbox = () => {
    setIsActive(false)

    document.body.style.overflow = 'unset'

    setTimeout(() => {
      setVideoId('')
    }, 400)
  }

  const providerValue = {
    closeLightbox,
    videoId,
    isActive,
    openLightbox,
  }

  return <Provider value={providerValue}>{children}</Provider>
}
