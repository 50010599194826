import { useCallback, useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { get, patch, post } from '@fable/api'
import { Text } from '@fable/components'
import { useTheme } from '@fable/theme'
import { Club, Order, SubscriptionTier, User } from '@fable/types'
import { getClubEntryPoint } from '../app/marketing/utils'
import { useActions, useTypedSelector } from '.'
import { allPlanOptions } from '../components/plan_selection/constants'
import {
  PlanOrderParams,
  PlanSelectionProps,
} from '../components/plan_selection/types'
import { analytics } from 'segment_analytics'

export const getProfile = async () => await get('/settings/profile/')

export const createOrder = async (params: any) =>
  await post('/billing/orders/', params)

export const payOrder = async (orderId: string) =>
  await patch(`/billing/orders/${orderId}`, { is_paid: true })

export const useFreeTrial = ({
  club,
  successActions,
}: Partial<PlanSelectionProps> = {}) => {
  const {
    state: locationState = { startingFrom: getClubEntryPoint() },
  }: {
    state: { startingFrom: string }
  } = useLocation()
  const navigate = useNavigate()
  const { colors } = useTheme()
  const {
    getUserProfile,
    getUserProfileClear,
    updateAuthUser,
    createClubMembership,
    closeModal,
    setToast,
  } = useActions()
  const activated = useRef(false)
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const queryClient = useQueryClient()
  const planId = allPlanOptions.premiumPlan.id
  const queryDefaultOptions = {
    refetchOnWindowFocus: false,
  }

  // get user profile
  const profileQuery = useQuery('profile', getProfile, {
    ...queryDefaultOptions,
    enabled: isAuthenticated,
  })
  const profileData = profileQuery?.data?.data as User
  const freeTrialUsed = !!profileData?.free_trial_used

  const orderParams: PlanOrderParams = {
    line_items: [
      {
        product_code: `plan:${planId}`,
        quantity: 1,
      },
    ],
    free_trial: true,
  }

  // create the free trial order
  const createOrderQuery = useQuery(
    'createFreeTrial',
    () => createOrder(orderParams),
    {
      ...queryDefaultOptions,
      enabled: false,
    }
  )

  const orderData = createOrderQuery?.data?.data?.order as Order

  // confirm the free trial order
  const confirmOrderQuery = useQuery(
    'confirmFreeTrial',
    () => payOrder(orderData?.id),
    {
      ...queryDefaultOptions,
      enabled: createOrderQuery.isSuccess && !!orderData?.id,
    }
  )

  const confirmed = confirmOrderQuery.isSuccess

  useEffect(() => {
    if (confirmed && !activated.current) {
      updateAuthUser({ subscription_tier: SubscriptionTier.basic })
      if (club) {
        createClubMembership(club as Club, locationState?.startingFrom)
      }
      queryClient.clear()
      activated.current = true
    }
  }, [
    club,
    confirmed,
    createClubMembership,
    locationState?.startingFrom,
    updateAuthUser,
    queryClient,
  ])

  const refreshProfile = useCallback(async () => {
    getUserProfileClear()
    await getUserProfile()
    closeModal()
  }, [closeModal, getUserProfile, getUserProfileClear])

  useEffect(() => {
    if (confirmed) {
      analytics.events.trialStarted()

      if (successActions?.toast) {
        setToast({
          showToast: true,
          content: (
            <Text
              type="subhead"
              sizing="XS"
              tag="span"
              color={colors.whiteFang}
            >
              <b>Trial Activated.</b> You can now join premium clubs
            </Text>
          ),
        })
      }

      // Refresh user profile to trigger refetch subscriptions
      refreshProfile()
    }
  }, [
    closeModal,
    colors.whiteFang,
    confirmed,
    getUserProfile,
    getUserProfileClear,
    refreshProfile,
    setToast,
    successActions?.toast,
  ])

  useEffect(() => {
    if (confirmed && !!successActions?.redirect) {
      navigate(successActions?.redirect)
    }
  }, [navigate, confirmed, successActions?.redirect])

  return {
    activateFreeTrial: createOrderQuery.refetch,
    loading:
      createOrderQuery.isFetching ||
      confirmOrderQuery.isFetching ||
      profileQuery.isFetching,
    error:
      createOrderQuery.isError ||
      confirmOrderQuery.isError ||
      profileQuery.isError,
    freeTrialUsed,
  }
}
