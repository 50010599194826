import { useState, useEffect } from 'react'

export const usePagination = ({
  apiCall,
  array,
  loading,
}: {
  apiCall: () => void
  array: unknown[]
  loading: boolean
}) => {
  const [data, setData] = useState<any[]>([])
  const [updating, setUpdating] = useState(false)

  const loadMore = () => {
    setUpdating(true)
    apiCall()
  }

  useEffect(() => {
    // Set initial data on mount
    if (array && !data.length) {
      setData(array)
    }
  }, [array, data.length])

  useEffect(() => {
    if (loading) return
    if (updating && array?.length && data.length) {
      // If updating new data and local data exists, concatenate arrays
      setUpdating(false)
      setData((prevState) => [...prevState, ...array])
    } else if (data?.length && !array?.length) {
      setData([])
    }
  }, [setData, array, data.length, updating, loading])

  return { data, loadMore }
}
