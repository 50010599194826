import { create } from 'zustand'

export interface FooterConfig {
  hidden?: boolean
}

export const defaultFooterConfig: FooterConfig = {
  hidden: false,
}

export interface FooterState {
  config: FooterConfig
  setConfig: (config: FooterConfig) => void
}

export const useFooter = create<FooterState>((set) => ({
  config: defaultFooterConfig,
  setConfig: (config) => set({ config: { ...defaultFooterConfig, ...config } }),
}))
