/* This saga is for collecting all data needed for displaying book lists and pre-selecting them
for use in BooklistCreate.tsx. When adding or removing a book to or from a book list respectively,
the list's cover_image property may not be updated immediately. In that case, the book lists must be queried
again until the cover_image is updated. */

import { takeLatest, put } from '@redux-saga/core/effects'
import { TakeableChannel } from '@redux-saga/core'
import { fableApi } from '@fable/api'
import {
  BookListResponse,
  GetBookListPickerDataPayload,
  Response,
} from '../types'
import { BookTypes } from '../state/action-types'
import { BookListResult } from '@fable/types'

const {
  GET_BOOK_LISTS_PAGINATED__REQUEST,
  GET_BOOK_LIST_PICKER_DATA__REQUEST,
  GET_BOOK_LISTS_PAGINATED__SUCCESS,
  SET_CHECKED_BOOK_LISTS,
  GET_BOOK_LIST_PICKER_DATA__SUCCESS,
  GET_BOOK_LISTS_PAGINATED__ERROR,
} = BookTypes

const findCoverImageMismatch = (
  assignedBookListIds: string[],
  bookList: BookListResult[]
) =>
  !!bookList.find(
    (item) =>
      /*
      There is a mismatch if:

      the API response containing book lists filtered by a book's ID
      contains a book from the user's unfiltered book lists
      and
      its cover_image is the default empty book list image

      or

      the book list is not empty
      and
      its cover_image is the default empty book list image
      */
      (assignedBookListIds.includes(item.id) &&
        item.cover_image.includes(
          'fable.co/book_list_covers/empty-book-list.webp'
        )) ||
      (item.count > 0 &&
        item.cover_image.includes(
          'fable.co/book_list_covers/empty-book-list.webp'
        ))
  )

function* pollForUpdatedCoverImage({
  payload,
}: {
  payload: GetBookListPickerDataPayload
}): Generator<any, any, any> {
  const { bookId, uuid } = payload

  // Begin process
  yield put({
    type: GET_BOOK_LISTS_PAGINATED__REQUEST,
  })

  try {
    const assignedBookLists = yield fableApi.get(
      `v2/users/${uuid}/book_lists?book_uuid=${bookId}`
    )
    const paginatedList: Response<BookListResponse> = yield fableApi.get(
      `/v2/users/${uuid}/book_lists?limit=20`
    )
    const assignedBookListIds = assignedBookLists.data.results.map(
      (x: BookListResult) => x.id
    )

    if (assignedBookListIds.length) {
      const needsUpdate = findCoverImageMismatch(
        assignedBookListIds,
        paginatedList.data.results
      )

      if (needsUpdate) {
        // If there's a mismatch, run the process again until there are no mismatches
        yield put({
          type: GET_BOOK_LIST_PICKER_DATA__REQUEST,
          payload,
        })
      }
    }

    /*
    No mismatch. Update success statuses.

    Note: the setCheckedBookList and getBookListsPaginated action-creators
    both needs to be accessible from components
    */
    yield put({
      type: GET_BOOK_LISTS_PAGINATED__SUCCESS,
      payload: paginatedList.data,
    })

    yield put({
      type: SET_CHECKED_BOOK_LISTS,
      payload: {
        bookId,
        bookListIds: assignedBookListIds,
      },
    })

    yield put({
      type: GET_BOOK_LIST_PICKER_DATA__SUCCESS,
      payload: {
        bookId,
        uuid,
        bookListIds: assignedBookListIds, // This list is read by the modifyBooklists action-creator
      },
    })
  } catch (error) {
    yield put({
      type: GET_BOOK_LISTS_PAGINATED__ERROR,
      payload: error,
    })
  }
}

export default function* bookListsSaga(): Generator<any> {
  yield takeLatest(
    GET_BOOK_LIST_PICKER_DATA__REQUEST as unknown as TakeableChannel<any>,
    pollForUpdatedCoverImage
  )
}
