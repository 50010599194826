import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTypedSelector } from '../hooks'
import { main as analytics } from '../segment_analytics/main'

export const usePageTracking = ({ title = '' }: { title?: string }) => {
  const location = useLocation()
  const { geoIpCountry } = useTypedSelector(({ geoIp }) => geoIp)

  const trackPage = useCallback(() => {
    const countryCode = geoIpCountry.data?.country?.iso_code
    const properties = {
      country: countryCode || '',
      path: location.pathname,
      url: window.location.href,
      title,
    }

    analytics.page(title, properties)
  }, [geoIpCountry.data?.country, location, title])

  useEffect(() => {
    trackPage()
  }, [trackPage])
}
