/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Club, ClubTrack } from '@fable/types'
import { Response } from '../../types'
import { ClubActions } from '../actions'
import { ClubTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

export enum ClubKeys {
  clubDetails = 'clubDetails',
  clubInvite = 'clubInvite',
  membership = 'membership',
  featuredClubs = 'featuredClubs',
  newMembership = 'newMembership',
  track = 'track',
}

export interface ClubState {
  [ClubKeys.clubDetails]: Response<Club>
  [ClubKeys.clubInvite]: Response
  [ClubKeys.membership]: Response
  [ClubKeys.featuredClubs]: Response
  [ClubKeys.newMembership]: Response
  [ClubKeys.track]: Response<ClubTrack>
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: ClubState = {
  [ClubKeys.clubDetails]: props,
  [ClubKeys.clubInvite]: props,
  [ClubKeys.membership]: props,
  [ClubKeys.featuredClubs]: props,
  [ClubKeys.newMembership]: props,
  [ClubKeys.track]: props,
}

const reducer = (
  state: ClubState = initialState,
  action: ClubActions
): ClubState => {
  const stateProps = { state, props }

  switch (action.type) {
    // GET FEATURED CLUBS
    case ClubTypes.GET_FEATURED_CLUBS__REQUEST:
      return setRequestState(stateProps, ClubKeys.featuredClubs)
    case ClubTypes.GET_FEATURED_CLUBS__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.featuredClubs, action.payload)
    case ClubTypes.GET_FEATURED_CLUBS__ERROR:
      return setErrorState(stateProps, ClubKeys.featuredClubs, action.payload)

    // GET CLUB DETAILS
    case ClubTypes.GET_CLUB_DETAILS__REQUEST:
      return setRequestState(stateProps, ClubKeys.clubDetails)
    case ClubTypes.GET_CLUB_DETAILS__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.clubDetails, action.payload)
    case ClubTypes.GET_CLUB_DETAILS__ERROR:
      return setErrorState(stateProps, ClubKeys.clubDetails, action.payload)
    case ClubTypes.GET_CLUB_DETAILS__CLEAR:
      return setClearState(stateProps, ClubKeys.clubDetails)

    // GET CLUB MEMBERSHIP
    case ClubTypes.GET_CLUB_MEMBERSHIP__REQUEST:
      return setRequestState(stateProps, ClubKeys.membership)
    case ClubTypes.GET_CLUB_MEMBERSHIP__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.membership, action.payload)
    case ClubTypes.GET_CLUB_MEMBERSHIP__ERROR:
      return setErrorState(stateProps, ClubKeys.membership, action.payload)
    case ClubTypes.GET_CLUB_MEMBERSHIP__CLEAR:
      return setClearState(stateProps, ClubKeys.membership)

    // CREATE CLUB MEMBERSHIP
    case ClubTypes.CREATE_CLUB_MEMBERSHIP__REQUEST:
      return setRequestState(stateProps, ClubKeys.newMembership)
    case ClubTypes.CREATE_CLUB_MEMBERSHIP__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.newMembership, {
        result: action.payload.clubMembership,
        isComplete: true,
      })
    case ClubTypes.CREATE_CLUB_MEMBERSHIP__ERROR:
      return setErrorState(stateProps, ClubKeys.newMembership, action.payload)

    // CREATE CLUB INVITE
    case ClubTypes.CREATE_CLUB_INVITE__REQUEST:
      return setRequestState(stateProps, ClubKeys.clubInvite)
    case ClubTypes.CREATE_CLUB_INVITE__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.clubInvite, {
        result: action.payload,
        isComplete: true,
      })
    case ClubTypes.CREATE_CLUB_INVITE__ERROR:
      return setErrorState(stateProps, ClubKeys.clubInvite, action.payload)

    // GET CLUB TRACK
    case ClubTypes.GET_CLUB_TRACK__REQUEST:
      return setRequestState(stateProps, ClubKeys.track)
    case ClubTypes.GET_CLUB_TRACK__SUCCESS:
      return setSuccessState(stateProps, ClubKeys.track, action.payload)
    case ClubTypes.GET_CLUB_TRACK__ERROR:
      return setErrorState(stateProps, ClubKeys.track, action.payload)
    case ClubTypes.GET_CLUB_TRACK__CLEAR:
      return setClearState(stateProps, ClubKeys.track)

    default:
      return state
  }
}

export default reducer
