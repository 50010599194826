import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import { css, cx, useTheme } from '@fable/theme'
import { Button, FlexBox, Hyperlink, Text } from '@fable/components'
import { Banner as BannerType } from './types'
import {
  BLOCKS,
  Document,
  INLINES,
  NodeData,
} from '@contentful/rich-text-types'

interface Props {
  className?: string
  banner: BannerType | null
  onDismiss?: () => void
  show?: boolean
}

const Banner = ({ className = '', banner, onDismiss, show = false }: Props) => {
  const { colors, mediaQueries } = useTheme()

  if (!banner || !show) return null

  const handleDismiss = () => onDismiss && onDismiss()

  const contentOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: NodeData, children: React.ReactNode) => (
        <Text type="body" sizing="M" color={colors.graphite}>
          {children}
        </Text>
      ),
      [INLINES.HYPERLINK]: (node: NodeData, children: React.ReactNode) => (
        <Hyperlink href={node.data.uri} onClick={handleDismiss}>
          {children}
        </Hyperlink>
      ),
    },
  }

  const renderDesktopMessage = () => {
    let message = documentToReactComponents(
      banner.desktopMessage.json as Document,
      contentOptions
    )

    if (banner.desktopMessage.component) {
      message = banner.desktopMessage.component
    }

    return (
      <span
        className={css`
          display: ${banner.mobileMessage ? 'none' : 'block'};
          ${mediaQueries.mobileL} {
            display: block;
          }
        `}
      >
        {message}
      </span>
    )
  }

  const renderMobileMessage = () => {
    if (!banner.mobileMessage) return null

    let message = documentToReactComponents(
      banner.mobileMessage.json as Document,
      contentOptions
    )

    if (banner.mobileMessage.component) {
      message = banner.mobileMessage.component
    }

    return (
      <span
        className={css`
          ${mediaQueries.mobileL} {
            display: none;
          }
        `}
      >
        {message}
      </span>
    )
  }

  return (
    <FlexBox
      data-testid="banner"
      className={cx(
        css`
          padding: 14px 32px;
          width: 100%;
          background-color: ${colors.pinkMoonLight};
          position: relative;
          text-align: center;
          ${mediaQueries.mobileXS} {
            padding: 14px 48px;
          }
          ${mediaQueries.tablet} {
            margin-bottom: 32px;
            padding: 14px 80px;
          }
        `,
        className
      )}
      centerAll
    >
      <div
        className={css`
          max-width: 320px;
          width: 100%;
          ${mediaQueries.tablet} {
            max-width: none;
          }
        `}
      >
        {renderDesktopMessage()}
        {renderMobileMessage()}
      </div>
      {banner?.showDismissButton === 'Yes' ? (
        <Button
          className={css`
            width: auto;
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            ${mediaQueries.tablet} {
              right: 32px;
            }
          `}
          icon="close"
          onClick={handleDismiss}
          dataTestId="banner-dismiss-button"
          transparent
          negative
        />
      ) : null}
    </FlexBox>
  )
}

export default Banner
