import { FirebaseError } from '@firebase/util'
import { CompanyInvite } from '@fable/types'
import isEmpty from 'lodash/isEmpty'
import { WRONG_PASSWORD, USER_NOT_FOUND, EMAIL_IN_USE } from '../../constants'
import { FieldErrors } from 'react-hook-form'

interface ErrorObject {
  type: string
  message?: string
}

export interface ErrorMessageObject {
  email?: ErrorObject
  password?: ErrorObject
  name?: ErrorObject
  termsAgree?: ErrorObject
}

export const getErrorMessage = (
  errors: FieldErrors<ErrorMessageObject>,
  companyName?: string
): string => {
  const { email, password, name, termsAgree } = errors

  if (!!name) return name.message as string
  if (!!email) {
    if (email.type === 'pattern') return `Please use your ${companyName} email`
    return email.message as string
  }
  if (!!password) return password.message as string
  if (!!termsAgree) return termsAgree.message as string

  return ''
}

export const formatFirebaseErrorMessage = (firebaseError: FirebaseError) => {
  switch (firebaseError.code) {
    case 'auth/user-not-found':
      return USER_NOT_FOUND
    case 'auth/wrong-password':
      return WRONG_PASSWORD
    case 'auth/email-already-in-use':
      return EMAIL_IN_USE
    default:
      return firebaseError.message
  }
}

export const getInviteParams = (invite?: CompanyInvite | null) => ({
  hasInvite: !isEmpty(invite),
  hasPersonalInvite: Boolean(invite?.email),
  emailPattern: invite?.company?.email_whitelist_pattern,
  capabilities: invite?.capabilities,
  companyName: invite?.company?.name,
  termsAgreementText: invite?.terms_agree,
  hasAllowList: invite?.has_allow_list,
})
