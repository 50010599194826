import { routes } from './routes'
import { theme, Colors } from '@fable/theme'

export const getClubEntryPoint = (pathname?: string) => {
  switch (pathname) {
    case routes.home:
      return 'web_home_carousel'
    default:
      return 'web_club_detail'
  }
}

export const getContentfulColorFromTheme = (color?: string) => {
  // Example: Hazelnut Light (#FFF1D3) => theme.colors.hazelnutLight
  const hex = color?.match(/\((.*)\)/)?.pop() || ''

  for (const key in theme.colors) {
    const colorValue = theme.colors[key as string as keyof Colors]

    if (colorValue.toLowerCase() === hex.toLocaleLowerCase()) return colorValue
  }

  return ''
}
