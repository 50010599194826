import { TakeableChannel, EventChannel } from '@redux-saga/core'
import { call, put, take, takeLatest, select } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import { css } from '@fable/theme'
import { signOut } from 'firebase/auth'
import history from '../routes/history'
import { firebaseAuth } from '@fable/api'
import { UserTypes, AuthTypes, ModalTypes } from '../state/action-types'
import { routes } from '../app/store/routes'
import TimeoutAlertCard from '../components/timeout_alert_card/TimeoutAlertCard'
import { ModalState } from '../types/modal'
import { getModalState } from './selectors/modalSelectors'

// Copied from Saga docs: https://redux-saga.js.org/docs/advanced/Channels/
export function triggerModalTimeout(secs: number) {
  return eventChannel((emitter) => {
    let seconds = secs
    let iv: any

    const eventHandler = () => {
      if (iv) clearInterval(iv)
      seconds = secs
      iv = setInterval(async () => {
        seconds -= 1
        if (seconds > -1) {
          if (seconds === 30) {
            // Temporarily remove event listeners so interacting with the modal will not reset the timer
            window.removeEventListener('mousemove', eventHandler)
            window.removeEventListener('keydown', eventHandler)
            // Give modal a chance to render so the button click event can be listened to
            setTimeout(() => {
              const button = document.querySelector('#timeout-card-button')
              button?.addEventListener('click', () => {
                // On click, reset the timer and re-initiate event listeners
                seconds = secs
                window.addEventListener('mousemove', eventHandler)
                window.addEventListener('keydown', eventHandler)
              })
            }, 300)
          }
          emitter(seconds)
        } else {
          // this causes the channel to close
          emitter(END)
        }
      }, 1000)
    }

    if (!iv) eventHandler()

    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
      window.removeEventListener('mousemove', eventHandler)
      window.removeEventListener('keydown', eventHandler)
    }
  })
}

export function* forceSignOut() {
  yield put({
    type: AuthTypes.SIGNOUT__REQUEST,
  })
  yield call(signOut, firebaseAuth)
  yield put({
    type: AuthTypes.SIGNOUT__SUCCESS,
  })
  yield put({
    type: AuthTypes.AUTH__CLEAR,
  })
  yield call([history, history.push], routes.explore)
  window.location.reload()
}

export function* renderCountdown(seconds: number) {
  yield put({
    type: ModalTypes.MODAL__OPEN,
    payload: {
      preventClickOutside: true,
      isSignoutCountdown: true,
      modalType: css`
        align-items: center !important;
        justify-content: center !important;
        div.modal {
          height: calc(100% - 20px) !important;
          max-width: 311px;
          max-height: 467px;
          overflow-y: auto;
          border-radius: 20px;
          > div.close-modal {
            display: none;
          }
        }
      `,
      content: (
        <div
          className={css`
            margin-top: -30px;
            max-width: 310px;
            width: 100vw;
            height: calc(100% + 30px);
          `}
        >
          <TimeoutAlertCard
            className={css`
              width: 100%;
            `}
            seconds={seconds}
          />
        </div>
      ),
    },
  })
}

export function* inactivityTimeout() {
  const timeInSeconds: number = 900

  const chan: EventChannel<number> = yield call(
    triggerModalTimeout,
    timeInSeconds
  )

  while (true) {
    const seconds: number = yield take(chan)

    // Render modal with seconds for remaining 30 seconds
    const modalState: ModalState = yield select(getModalState)

    if (seconds <= 30 && seconds > 0) {
      yield call(renderCountdown, seconds)
    } else if (modalState.isSignoutCountdown && seconds > 30) {
      // See triggerModalTimeout for TimeoutAlertCard button click listener
      yield put({
        type: ModalTypes.MODAL__CLOSE,
      })
    } else if (modalState.isSignoutCountdown && seconds === 0) {
      // Sign out and reload if timeout ends
      yield call(forceSignOut)
    }
  }
}

export default function* signoutCountdownSaga() {
  // This saga is called if a user profile's capabilities object contains inactivity_timeout: true
  yield takeLatest(
    UserTypes.INACTIVITY_TIMEOUT as unknown as TakeableChannel<any>,
    inactivityTimeout
  )
}
