import { css, useTheme } from '@fable/theme'
import { PageAnchorElement } from './anchor_nav'
import { PageDetailSection as PageDetailSectionType } from './types'

export const PageDetailSection = ({
  element,
  id,
  hidden,
  ...props
}: PageDetailSectionType) => {
  const { colors, mediaQueries } = useTheme()

  if (hidden?.element || !element) return null

  return (
    <PageAnchorElement
      id={id}
      className={css`
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 34px;
          padding-bottom: 34px;
          border-bottom: 1px solid ${colors.whiteSmoke};
        }
        ${mediaQueries.tabletL} {
          max-width: 564px;
        }
      `}
      {...props}
    >
      {element}
    </PageAnchorElement>
  )
}
