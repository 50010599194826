import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { get } from '@fable/api'
import { BookReviewLinkUnfurlImage } from './BookReviewLinkUnfurlImage'
import { css } from '@fable/theme'

const BookReviewLinkUnfurl: React.VFC = () => {
  const { pathname } = useLocation()
  const reviewId = pathname.split('/')[2]

  const reviewQuery = useQuery(
    ['bookReview', [reviewId]],
    async () => await get(`/reviews/${reviewId}`)
  )

  if (!reviewQuery.data || reviewQuery.error) return null

  return (
    <BookReviewLinkUnfurlImage
      className={css`
        transform-origin: top left;
        transform: scale(2.7);
      `}
      review={reviewQuery.data.data}
    />
  )
}

export default BookReviewLinkUnfurl
