import { AppRouteProps } from '../types'
import UniversalLinkTest from '../../app/universal_links/pages/UniversalLinkTest'
import { routes } from '../../app/universal_links/routes'
import { lazyWithRetry } from '../../utils'

const UniversalLinkRedirect = lazyWithRetry(
  () => import('../../app/universal_links/pages/UniversalLinkRedirect')
)

export const universalRoutes: AppRouteProps[] = [
  {
    path: routes.read,
    element: <UniversalLinkRedirect />,
  },
  {
    path: routes.open,
    element: <UniversalLinkRedirect />,
  },
  {
    path: `${routes.userPosts}/:id`,
    element: <UniversalLinkRedirect />,
  },
  {
    path: routes.test,
    element: <UniversalLinkTest />,
  },
]
