import { get } from '../fable'

const searchContent = async ({
  query,
  limit = 10,
  offset = 0,
  type = 'book',
  availabilty,
}: {
  query: string
  limit?: number
  offset?: number
  type?: 'book' | 'club'
  availabilty?: string[]
}) => {
  const defaultAvailability =
    type === 'book' ? ['pre_launch', 'available', 'out_of_catalog'] : []
  const storeAvailability = availabilty || defaultAvailability

  let url = `/search?auto=${query}&type=${type}&limit=${limit}&offset=${offset}`

  if (!!storeAvailability.length) {
    url += `&store_availability=${storeAvailability.join(',')}`
  }

  return await get(url)
}

const searchBookSeries = async ({
  query,
  limit = 10,
  offset = 0,
}: {
  query: string
  limit: number
  offset: number
}) => {
  const url = `/book_series/search?q=${query}&limit=${limit}&offset=${offset}`
  return await get(url)
}

export const search = {
  searchContent,
  searchBookSeries,
}
