import analyticsSaga from './analyticsSaga'
import authSaga from './authSaga'
import bookListsSaga from './bookListsSaga'
import cancelSubscriptionSaga from './cancelSubscriptionSaga'
import errorSaga from './errorSaga'
import geoBlockSaga from './geoBlockSaga'
import getUserDataSaga from './getUserDataSaga'
import postAuthSaga from './postAuthSaga'
import postAuthAnalyticsSaga from './postAuthAnalyticsSaga'
import signoutCountdownSaga from './signoutCountdownSaga'
import loggingSaga from './loggingSaga'

const sagas = [
  analyticsSaga,
  authSaga,
  bookListsSaga,
  cancelSubscriptionSaga,
  errorSaga,
  geoBlockSaga,
  getUserDataSaga,
  postAuthSaga,
  postAuthAnalyticsSaga,
  signoutCountdownSaga,
  loggingSaga,
]

export default sagas
