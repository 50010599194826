import { css, cx, useTheme } from '@fable/theme'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FORM_TYPES } from '../../app/auth/constants'
import { COPYRIGHT_TEXT } from '../../constants'
import { Button, FlexBox, Text } from '@fable/components'
import { useActions, useTypedSelector, useVertExpEnabled } from '../../hooks'
import {
  HEADER_SIDE_MENU_LINKS,
  HEADER_SIDE_MENU_PRIMARY_LINKS,
  HEADER_SIDE_MENU_PRIMARY_LINKS_LEGACY,
} from './constants'
import { HeaderSideMenuButton } from './HeaderSideMenuButton'
import { HeaderNavSubItem } from './types'
import { routes } from '../../app/store/routes'
import { HeaderSideMenuLink } from './HeaderSideMenuLink'

export const HeaderSideMenu: React.VFC<{
  secondary: boolean
}> = ({ secondary }) => {
  const [open, setOpen] = useState(false)
  const { openAuthModal } = useActions()
  const { colors, motion, mediaQueries } = useTheme()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const vertExpEnabled = useVertExpEnabled()

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset'
  }, [open])

  window.onresize = () => {
    if (open) setOpen(false)
  }

  const closeMenu = () => open && setOpen(false)

  const largeButtonStyles = css`
    color: ${colors.uiBlack};

    :not(:last-child) {
      margin-bottom: 14px;
    }

    ${mediaQueries.mobileS} {
      margin-bottom: 14px;
      height: 60px;
      font-size: 24px;

      :not(:last-child) {
        margin-bottom: 18px;
      }
    }
  `

  const primaryLinks = vertExpEnabled
    ? HEADER_SIDE_MENU_PRIMARY_LINKS
    : HEADER_SIDE_MENU_PRIMARY_LINKS_LEGACY

  return (
    <>
      <div
        className={cx(
          css`
            margin-top: inherit;
            background-color: ${colors.uiPearlExtraLight};
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10000;
            transform: translateX(-100%);
            overflow: hidden;
            ${motion.transition('transform')}
          `,
          open &&
            css`
              transform: translateX(0);
            `
        )}
      >
        <FlexBox
          className={css`
            padding: 80px 30px 0;
            height: 100%;
            overflow-y: auto;
            gap: 30px;
            > div {
              width: 100%;
              max-width: 414px;
              margin: 0 auto;
            }
          `}
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            {primaryLinks.map((item: HeaderNavSubItem, i: number) => (
              <Button
                className={largeButtonStyles}
                key={i}
                width="100%"
                onClick={() => {
                  navigate(item.path)
                  closeMenu()
                }}
                negative
              >
                {item.title}
              </Button>
            ))}
            <Button
              className={largeButtonStyles}
              width="100%"
              onClick={() => {
                if (isAuthenticated) {
                  navigate(routes.profile)
                } else {
                  openAuthModal(FORM_TYPES.SIGN_IN, pathname)
                }

                closeMenu()
              }}
              negative
            >
              {isAuthenticated ? 'Your Profile' : 'Log in'}
            </Button>
          </div>
          <FlexBox
            className={css`
              margin-top: 36px;
              padding: 0 13px;
            `}
          >
            {HEADER_SIDE_MENU_LINKS.map((item, i: number) => (
              <div
                key={i}
                className={css`
                  width: 50%;
                `}
              >
                <Text
                  className={css`
                    margin-bottom: 20px;
                  `}
                  type="body"
                  sizing="M"
                  color={colors.fableGreen}
                >
                  {item.title}
                </Text>
                {item.links.map((link, k: number) => (
                  <div
                    key={k}
                    className={css`
                      display: block;
                      padding-right: 8px;
                      cursor: pointer;
                      &:not(:last-child) {
                        margin-bottom: 18px;
                      }
                    `}
                    onClick={closeMenu}
                  >
                    <HeaderSideMenuLink link={link} />
                  </div>
                ))}
              </div>
            ))}
          </FlexBox>
          <FlexBox
            className={css`
              margin-top: 20vw;
              padding: 0 13px;
              position: relative;
              ${mediaQueries.mobile} {
                margin-top: 90px;
              }
            `}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Text
              className={css`
                height: 72px;
              `}
              type="body"
              sizing="S"
            >
              {COPYRIGHT_TEXT}
            </Text>
          </FlexBox>
        </FlexBox>
      </div>
      <HeaderSideMenuButton
        onClick={() => setOpen(!open)}
        open={open}
        secondary={secondary}
      />
    </>
  )
}
