import { useTheme } from '@fable/theme'
import { useInView } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useHomeBgColor = ({
  defaultColor,
}: { defaultColor?: string } = {}) => {
  const { colors } = useTheme()
  const [bgColor, setBgColor] = useState('')
  const defaultBgColor = defaultColor || colors.uiYellow

  const discussRef = useRef<HTMLDivElement>(null)
  const discussInView = useInView(discussRef, { amount: 0.2 })

  const discoverRef = useRef<HTMLDivElement>(null)
  const discoverInView = useInView(discoverRef, { amount: 0.2 })

  const organizeRef = useRef<HTMLDivElement>(null)
  const organizeInView = useInView(organizeRef, { amount: 0.2 })

  const appreciationPostsRef = useRef<HTMLDivElement>(null)
  const appreciationPostsInView = useInView(appreciationPostsRef)

  const changeBackgroundColor = useCallback(() => {
    if (organizeInView) return colors.uiTeal
    if (discoverInView) return colors.uiGreenLight
    if (discussInView) return colors.uiYellow
    return appreciationPostsInView ? colors.uiTeal : defaultBgColor
  }, [
    discussInView,
    discoverInView,
    organizeInView,
    appreciationPostsInView,
    colors.uiYellow,
    colors.uiGreenLight,
    colors.uiTeal,
    defaultBgColor,
  ])

  useEffect(() => {
    setBgColor(changeBackgroundColor())
  }, [setBgColor, changeBackgroundColor])

  return { discoverRef, discussRef, organizeRef, appreciationPostsRef, bgColor }
}
