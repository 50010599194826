export enum UserTypes {
  // GET USER PROFILE
  GET_USER_PROFILE__CLEAR = 'GET_USER_PROFILE__CLEAR',
  GET_USER_PROFILE__ERROR = 'GET_USER_PROFILE__ERROR',
  GET_USER_PROFILE__REQUEST = 'GET_USER_PROFILE__REQUEST',
  GET_USER_PROFILE__SUCCESS = 'GET_USER_PROFILE__SUCCESS',

  // GET USER SUBSCRIPTION
  GET_USER_SUBSCRIPTION__ERROR = 'GET_USER_SUBSCRIPTION__ERROR',
  GET_USER_SUBSCRIPTION__REQUEST = 'GET_USER_SUBSCRIPTION__REQUEST',
  GET_USER_SUBSCRIPTION__SUCCESS = 'GET_USER_SUBSCRIPTION__SUCCESS',

  // NEWSLETTER SUBSCRIBE
  NEWSLETTER_SUBSCRIBE__ERROR = 'NEWSLETTER_SUBSCRIBE__ERROR',
  NEWSLETTER_SUBSCRIBE__REQUEST = 'NEWSLETTER_SUBSCRIBE__REQUEST',
  NEWSLETTER_SUBSCRIBE__SUCCESS = 'NEWSLETTER_SUBSCRIBE__SUCCESS',

  // NEWSLETTER UNSUBSCRIBE
  NEWSLETTER_UNSUBSCRIBE__ERROR = 'NEWSLETTER_UNSUBSCRIBE__ERROR',
  NEWSLETTER_UNSUBSCRIBE__REQUEST = 'NEWSLETTER_UNSUBSCRIBE__REQUEST',
  NEWSLETTER_UNSUBSCRIBE__SUCCESS = 'NEWSLETTER_UNSUBSCRIBE__SUCCESS',

  // SEND APP LINK SMS
  SEND_APP_LINK_SMS__CLEAR = 'SEND_APP_LINK_SMS__CLEAR',
  SEND_APP_LINK_SMS__ERROR = 'SEND_APP_LINK_SMS__ERROR',
  SEND_APP_LINK_SMS__REQUEST = 'SEND_APP_LINK_SMS__REQUEST',
  SEND_APP_LINK_SMS__SUCCESS = 'SEND_APP_LINK_SMS__SUCCESS',

  // SEND BOOK LINK SMS
  SEND_BOOK_LINK_SMS__CLEAR = 'SEND_BOOK_LINK_SMS__CLEAR',
  SEND_BOOK_LINK_SMS__ERROR = 'SEND_BOOK_LINK_SMS__ERROR',
  SEND_BOOK_LINK_SMS__REQUEST = 'SEND_BOOK_LINK_SMS__REQUEST',
  SEND_BOOK_LINK_SMS__SUCCESS = 'SEND_BOOK_LINK_SMS__SUCCESS',

  // SEND CLUB INVITE SMS
  SEND_CLUB_INVITE_SMS__CLEAR = 'SEND_CLUB_INVITE_SMS__CLEAR',
  SEND_CLUB_INVITE_SMS__ERROR = 'SEND_CLUB_INVITE_SMS__ERROR',
  SEND_CLUB_INVITE_SMS__REQUEST = 'SEND_CLUB_INVITE_SMS__REQUEST',
  SEND_CLUB_INVITE_SMS__SUCCESS = 'SEND_CLUB_INVITE_SMS__SUCCESS',

  // EDIT PROFILE
  EDIT_PROFILE = 'EDIT_PROFILE',

  // UPDATE USER PROFILE
  UPDATE_USER_PROFILE__CLEAR = 'UPDATE_USER_PROFILE__CLEAR',
  UPDATE_USER_PROFILE__ERROR = 'UPDATE_USER_PROFILE__ERROR',
  UPDATE_USER_PROFILE__REQUEST = 'UPDATE_USER_PROFILE__REQUEST',
  UPDATE_USER_PROFILE__SUCCESS = 'UPDATE_USER_PROFILE__SUCCESS',

  // INACTIVITY_TIMEOUT
  INACTIVITY_TIMEOUT = 'INACTIVITY_TIMEOUT',
}
