import { lazyWithRetry } from '../../utils'
import { AppRouteProps } from '../types'
import { routes } from '../../app/webview/routes'

const ScoutPickPage = lazyWithRetry(
  () => import('../../app/webview/pages/scout_pick/ScoutPickPage')
)

const ShelfiePage = lazyWithRetry(
  () => import('../../app/webview/pages/shelfie/ShelfiePage')
)

const defaultProps = {
  footer: { hidden: true },
  header: { hidden: true },
}

export const webviewRoutes: AppRouteProps[] = [
  {
    path: routes.scoutPick,
    element: <ScoutPickPage />,
    ...defaultProps,
  },
  {
    path: routes.shelfie,
    element: <ShelfiePage />,
    ...defaultProps,
  },
]
