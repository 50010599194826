import { useLocation } from 'react-router-dom'
import { cx, css } from '@fable/theme'
import { Book } from '@fable/types'
import { Button } from '@fable/components'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ShareOptions } from './ShareOptions'
import { GIFTS_ENABLED } from 'app/store/constants'
import { isUrlMatched } from 'utils'
import { routes as socialRoutes } from 'app/social/routes'

interface Props {
  className?: string
  category?: string
  title?: string | null
  iconType?: 'share' | 'more'
  translucent?: boolean
  secondary?: boolean
  negative?: boolean
  hideModalTitle?: boolean
  book?: Book
  url?: string
}

const ShareButton: React.VFC<Props> = ({
  className,
  category = '',
  title,
  iconType = 'share',
  translucent,
  secondary,
  negative,
  hideModalTitle = false,
  book,
  url,
}) => {
  const location = useLocation()
  const { closeModal, openModal, copyTextToClipboard } = useActions()
  const { isOpen } = useTypedSelector(({ modal }) => modal)

  const toggleModal = () => {
    if (isOpen) return closeModal()

    const showGiftBookButton =
      GIFTS_ENABLED &&
      !!book?.can_purchase &&
      !book.is_free &&
      !isUrlMatched(`${socialRoutes.bookList}/:id`, location.pathname, true)

    return openModal(
      <ShareOptions
        category={category}
        hideTitle={hideModalTitle}
        book={book}
        url={url}
        onCopyToClipboard={copyTextToClipboard}
        showGiftBookButton={showGiftBookButton}
      />,
      'm-card'
    )
  }

  const styles = {
    button: css`
      max-width: 121px;
    `,
    icon: css`
      width: 52px;
      height: 52px;
      padding: 0;
      border: none;
    `,
  }

  return (
    <Button
      icon={iconType}
      className={cx(styles.button, !title && styles.icon, className)}
      onClick={toggleModal}
      dataTestId="share-button"
      translucent={translucent}
      secondary={secondary}
      negative={negative}
      label={title || ''}
    />
  )
}

export default ShareButton
