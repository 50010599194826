import { css, cx, useTheme } from '@fable/theme'
import { HomeBlock } from '../HomeBlock'
import { HomeFeaturesImage } from '../../lib/types'
import { FlexBox, Text } from '@fable/components'
import { useRef } from 'react'
import {
  Carousel,
  useAutoplay,
  useCarouselController,
} from 'components/carousel'
import { HomeFeaturesCarouselControls } from './HomeFeaturesCarouselControls'

interface Props {
  title: string
  titleStyles?: string
  images: HomeFeaturesImage[]
}

export const HomeFeaturesCarouselDesktop = ({
  title,
  titleStyles = '',
  images,
}: Props) => {
  const { mediaQueries } = useTheme()
  const controller = useCarouselController()
  const timerDuration = 5000
  const autoplay = useAutoplay({ duration: timerDuration })
  const ref = useRef<HTMLDivElement>(null)
  const activeIndex = controller.currentSlide

  return (
    <HomeBlock
      ref={ref}
      className={css`
        display: none;
        ${mediaQueries.tablet} {
          display: flex;
          width: 100%;
          border-radius: 48px;
          padding: 40px 24px 34px;
          overflow: hidden;
        }
      `}
    >
      <Text
        className={cx(
          css`
            display: block;
            font-size: 70px;
            width: 100%;
            max-width: 310px;
          `,
          titleStyles
        )}
        type="note"
        sizing="M"
        align="center"
      >
        {title}
      </Text>
      {images.length > 1 ? (
        <>
          <FlexBox
            className={css`
              position: relative;
              width: 100%;
            `}
          >
            <Carousel
              className={css`
                padding: 40px 0 34px;
                width: 100%;
                max-width: 420px;
                margin: 0 auto;
              `}
              options={{
                loop: true,
                drag: false,
                defaultAnimation: {
                  duration: 0,
                },
                slides: {
                  perView: 1,
                  origin: 'center',
                  spacing: 24,
                },
              }}
              plugins={[autoplay]}
              controller={controller}
            >
              {images.map((img, i) => (
                <FlexBox
                  key={i}
                  className={css`
                    width: 100%;
                    height: 100%;
                    opacity: ${i === activeIndex ? 1 : 0};
                    transition: opacity 0.6s ease;
                    pointer-events: none;
                  `}
                  centerAll
                >
                  <img
                    key={i}
                    className={css`
                      display: block;
                      width: 100%;
                      max-width: 420px;
                    `}
                    src={img.image}
                    alt={img.altText}
                  />
                </FlexBox>
              ))}
            </Carousel>
          </FlexBox>
          <HomeFeaturesCarouselControls
            controller={controller}
            timerDuration={timerDuration}
          />
        </>
      ) : (
        <FlexBox
          className={css`
            margin-top: 50px;
            width: 100%;
          `}
        >
          <img
            className={cx(
              css`
                display: block;
                width: 100%;
              `,
              images[0].imageStyles
            )}
            src={images[0].image}
            alt={images[0].altText}
          />
        </FlexBox>
      )}
    </HomeBlock>
  )
}
