import { routes } from '../../app/auth/routes'
import { lazyWithRetry } from '../../utils'
import { pageMetadata } from '../pageMetadata'
import { AppRouteProps } from '../types'
import AuthHandler from '../../app/auth/pages/AuthHandler'
import ExtAuthPage from 'app/auth/pages/ExtAuthPage'

const AuthPage = lazyWithRetry(() => import('../../app/auth/pages/AuthPage'))
const ResetPassword = lazyWithRetry(
  () => import('../../app/auth/pages/ResetPassword')
)
const VerifyEmail = lazyWithRetry(
  () => import('../../app/auth/pages/VerifyEmail')
)

export const authRoutes: AppRouteProps[] = [
  {
    path: routes.signUp,
    element: <AuthPage />,
    head: pageMetadata.signUp,
    footer: { hidden: true },
  },
  {
    path: routes.signIn,
    element: <AuthPage />,
    head: pageMetadata.signIn,
    footer: { hidden: true },
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
    head: pageMetadata.resetPassword,
  },
  {
    path: routes.verifyEmail,
    element: <VerifyEmail />,
    head: pageMetadata.verifyEmail,
  },
  {
    path: routes.extAuth,
    element: <ExtAuthPage />,
    footer: { hidden: true },
    header: { hidden: true },
    guarded: true,
  },
  {
    path: routes.auth,
    element: <AuthHandler />,
  },
]
