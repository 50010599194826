import { useTheme } from '@fable/theme'

interface Props {
  className: string
  light?: boolean
  mixed?: boolean
}

const FableLogo: React.FC<Props> = ({ className, light, mixed }) => {
  const { colors } = useTheme()
  const white = colors.uiWhite
  const textColor = light || mixed ? white : colors.uiGraphite

  return (
    <svg
      width="118"
      height="24"
      viewBox="0 0 120 24"
      fill="none"
      className={className}
    >
      <path
        className="logo-icon"
        d="M15.7066 20.416H0V23.557H15.7066V20.416Z"
        fill={light ? white : colors.uiRoseDark}
      />
      <path
        className="logo-icon"
        d="M3.7313 9.63281L2.16064 12.3529L15.7625 20.2053L17.3332 17.4852L3.7313 9.63281Z"
        fill={light ? white : colors.uiPink}
      />
      <path
        className="logo-icon"
        d="M12.3554 2.16211L9.63501 3.73259L17.4883 17.333L20.2087 15.7625L12.3554 2.16211Z"
        fill={light ? white : colors.uiRoseLight}
      />
      <path
        className="logo-icon"
        d="M23.5603 0H20.4189V15.7048H23.5603V0Z"
        fill={light ? white : colors.uiYellow}
      />
      <path
        className="logo-icon"
        d="M31.6246 2.16211L23.7712 15.7625L26.4916 17.333L34.3448 3.7326L31.6246 2.16211Z"
        fill={light ? white : colors.uiGreenLight}
      />
      <path
        className="logo-icon"
        d="M40.2458 9.63477L26.6438 17.4872L28.2144 20.2073L41.8164 12.3549L40.2458 9.63477Z"
        fill={light ? white : colors.uiTeal}
      />
      <path
        className="logo-icon"
        d="M43.979 20.416H28.2725V23.557H43.979V20.416Z"
        fill={light ? white : colors.uiBlueLight}
      />
      <path
        className="logo-text"
        d="M51.5379 7.18209V4.78869C51.5379 1.73802 54.402 0.641602 56.8316 0.641602C58.7418 0.641602 59.9273 1.14663 60.7365 1.67947L59.6084 4.49298C59.1164 4.01869 58.3357 3.60443 57.0632 3.60443C55.7905 3.60443 54.7194 4.01869 54.7194 5.5294V7.18355H59.8887V10.1449H54.7208V23.2991H51.5379V10.1449H47.7485V7.18209H51.5379Z"
        fill={textColor}
      />
      <path
        className="logo-text"
        d="M97.689 1.08594H100.872V23.2998H97.689V1.08594Z"
        fill={textColor}
      />
      <path
        className="logo-text"
        d="M111.29 23.7437C105.649 23.7437 101.742 19.8045 101.742 15.1553C101.742 10.5047 105.705 6.56543 110.855 6.56543C116.034 6.56543 119.998 10.5047 119.998 14.7996C119.998 15.3324 119.94 16.221 119.909 16.5181H105.244C105.822 19.3009 108.108 20.9317 111.29 20.9317C114.356 20.9317 115.833 19.4209 116.613 17.7317L119.652 19.0945C118.407 21.5523 115.544 23.7437 111.29 23.7437ZM105.273 13.7031H116.439C115.861 11.0668 113.632 9.37894 110.855 9.37894C107.964 9.37894 105.85 11.1268 105.273 13.7031Z"
        fill={textColor}
      />
      <path
        className="logo-text"
        d="M88.1742 6.56512C85.4259 6.56512 83.2838 7.66155 81.8382 9.26152V1.08594H78.6536V17.3654L78.6623 19.5787H78.6609C78.6609 20.3253 78.0673 20.9328 77.3381 20.9328C76.6089 20.9328 76.017 20.3253 76.017 19.5787V11.0665C76.017 8.49009 73.067 6.56512 68.1193 6.56512C63.8953 6.56512 61.2615 8.46081 59.9031 10.1486V10.1501L62.6844 11.3958C64.1714 10.0491 66.1045 9.37863 68.3496 9.37863C71.2724 9.37863 72.8354 10.4165 72.8354 11.4222C72.8354 12.7265 71.5328 13.0807 66.7308 13.4364C62.6813 13.7321 59.3826 14.9764 59.3826 18.5306C59.3826 22.5284 63.0861 23.7419 65.6615 23.7419C68.8131 23.7419 71.2724 22.6177 72.9784 20.6927C72.9912 20.7527 73.0083 20.8054 73.0226 20.8625C73.0799 21.1172 73.1526 21.328 73.2427 21.4978C74.0279 23.3013 75.921 23.7346 77.3395 23.7346C78.9124 23.7346 81.076 23.212 81.655 20.8684L81.8382 21.0763C83.2838 22.6762 85.4259 23.7419 88.1742 23.7419C92.9185 23.7419 96.8251 20.1277 96.8251 15.1535C96.8251 10.2086 92.9202 6.56512 88.1742 6.56512ZM72.8382 18.0871C71.1023 20.1599 69.1059 21.0192 66.3577 21.0192C63.5809 21.0192 62.8573 19.6871 62.8573 18.5321C62.8573 17.2278 63.6967 16.4578 67.1384 16.1314C68.9901 15.9542 71.4497 15.6278 72.8382 14.8578V18.0871ZM87.6536 20.9299C84.1817 20.9299 81.8396 18.4413 81.8396 15.155C81.8396 11.8965 84.1831 9.37863 87.6536 9.37863C91.1255 9.37863 93.4978 11.8979 93.4978 15.155C93.4978 18.4413 91.1255 20.9299 87.6536 20.9299Z"
        fill={textColor}
      />
    </svg>
  )
}

export default FableLogo
