import { css, useTheme } from '@fable/theme'
import { CompProps } from 'types'
import { Counters } from '@fable/types'
import { FlexBox } from '@fable/components'
import { ActivityFooter } from './ActivityFooter'
import { ActivityUser, ActivityUserProps } from './ActivityUser'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  author: ActivityUserProps
  counters: Counters
  onClickLike?: () => void
  onClickComment?: () => void
}

export const ActivityCard = ({
  author,
  children,
  counters,
  onClickLike,
  onClickComment,
  ...props
}: CompProps<Props>) => {
  const { colors, mediaQueries } = useTheme()

  return (
    <FlexBox
      className={css`
        width: 100%;
        background-color: ${colors.uiWhite};
        border-radius: 24px;
        ${mediaQueries.mobileL} {
          padding: 16px 0;
        }
      `}
      flexDirection="column"
      gap="16px"
      {...props}
    >
      <div
        className={css`
          ${mediaQueries.mobileL} {
            padding: 0 24px;
          }
        `}
      >
        <ActivityUser {...author} />
      </div>
      <hr
        className={css`
          display: none;
          border: none;
          width: 100%;
          height: 1px;
          background-color: ${colors.uiPearlUltraLight};
          ${mediaQueries.mobileL} {
            display: block;
          }
        `}
      />
      {children}
      <div
        className={css`
          ${mediaQueries.mobileL} {
            padding: 0 24px;
          }
        `}
      >
        <ActivityFooter
          counters={counters}
          onClickLike={onClickLike}
          onClickComment={onClickComment}
        />
      </div>
    </FlexBox>
  )
}
