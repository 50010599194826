import { get, patch, post } from '@fable/api'
import { getClubType } from 'app/clubs/utils'
import { AxiosError, AxiosResponse } from 'axios'
import { allPlanOptions } from 'components/plan_selection/constants'
import { PlanOrderParams } from 'components/plan_selection/types'
import { ClubMembership, CompanyInvite } from '@fable/types'
import { keysToSnakeCase } from 'utils'
import { createMachine, assign, DoneInvokeEvent, raise } from 'xstate'
import {
  clubAuthKey,
  getFreeTrialFromSubscriptions,
  getFreeTrialStatus,
} from './helpers'
import { InvokeAction } from '../types'
import {
  ClubMachineContext,
  ClubMachineEventVerify,
  ClubMachineEvents,
  ClubMachineState,
  ClubMachineEventReset,
  FreeTrialSubscription,
} from './types'
import { analytics } from 'segment_analytics'
import { routes } from 'app/store/routes'
import { promoCodes } from '../../../constants'

type CTX = ClubMachineContext

const defaultContext: CTX = {
  club: null,
  clubType: null,
  error: null,
  freeTrialStatus: 'pending',
  inviteId: null,
  invite: null,
  isMember: false,
  premiumUser: false,
  user: null,
  postAuth: false,
  clubJoined: false,
}

const VERIFY = [
  {
    target: 'verifying',
    actions: 'setUserData',
    cond: 'authenticated',
  },
  {
    target: 'idle',
    cond: 'unauthenticated',
  },
]

export const clubMachine = createMachine<
  CTX,
  ClubMachineEvents,
  ClubMachineState
>(
  {
    predictableActionArguments: true,
    context: defaultContext,
    id: 'club',
    initial: 'init',
    on: {
      RESET: 'init',
    },
    states: {
      init: {
        entry: ['reset', 'setPostAuth', 'removeAuthKey'],
        exit: 'setInitialData',
        on: { VERIFY },
      },
      verifying: {
        initial: 'freeTrial',
        states: {
          freeTrial: {
            invoke: {
              id: 'verifyFreeTrial',
              src: 'verifyFreeTrial',
              onDone: {
                target: 'membership',
                actions: 'setFreeTrialStatus',
              },
              onError: {
                target: 'membership',
                actions: 'setError',
              },
            },
          },
          membership: {
            invoke: {
              id: 'verifyMembership',
              src: 'verifyMembership',
              onDone: [
                {
                  target: '#club.done.upgrade',
                  cond: 'freeTrialExpired',
                },
                {
                  target: '#club.done',
                  actions: 'setMembership',
                },
              ],
              onError: [
                {
                  target: '#club.done.upgrade',
                  cond: 'freeTrialExpired',
                },
                {
                  target: '#club.idle',
                  actions: 'joinPostAuth',
                  cond: 'isPostAuth',
                },
                {
                  target: '#club.idle',
                },
              ],
            },
          },
        },
      },
      idle: {
        initial: 'loaded',
        on: {
          VERIFY,
          JOIN: [
            {
              target: 'joining',
              cond: 'canJoin',
            },
            {
              target: '#club.joining.freeTrial',
              cond: 'canJoinFreeTrial',
            },
          ],
        },
        states: {
          invite: {
            invoke: {
              id: 'getInvite',
              src: 'getInvite',
              onDone: {
                target: 'loaded',
                actions: 'setInvite',
              },
              onError: {
                target: 'loaded',
                actions: 'setError',
              },
            },
          },
          loaded: {
            entry: raise('INVITE'),
            on: {
              INVITE: {
                target: 'invite',
                cond: 'hasInviteId',
              },
            },
          },
        },
      },
      joining: {
        initial: 'membership',
        states: {
          freeTrial: {
            invoke: {
              id: 'activateFreeTrial',
              src: 'activateFreeTrial',
              onDone: {
                target: 'membership',
                actions: 'freeTrialActivated',
              },
              onError: {
                target: '#club.idle',
                actions: 'setError',
              },
            },
          },
          membership: {
            invoke: {
              id: 'createMembership',
              src: 'createMembership',
              onDone: {
                target: '#club.done',
                actions: 'setClubJoined',
              },
              onError: {
                target: '#club.idle',
                actions: 'setError',
              },
            },
          },
        },
      },
      done: {
        entry: 'redirect',
        initial: 'joined',
        states: {
          joined: {},
          upgrade: {},
        },
      },
    },
  },
  {
    actions: {
      setInitialData: assign<CTX, ClubMachineEventVerify>({
        club: (_: unknown, event: any) => {
          return event.data?.club
        },
        clubType: (_: unknown, event: any) => {
          return getClubType({ club: event.data?.club, checkParam: true })
        },
        inviteId: (_: unknown, event: any) => event.data?.inviteId || null,
      }),
      setUserData: assign<CTX, ClubMachineEventVerify>({
        user: (_: unknown, event: any) => event.data?.user,
        premiumUser: (_: unknown, event: any) => {
          return event.data?.user?.subscription_tier === 'basic'
        },
      }),
      setInvite: assign<CTX, DoneInvokeEvent<AxiosResponse<CompanyInvite>>>({
        invite: (_: unknown, event: any) => event.data?.data,
      }),
      setMembership: assign<CTX, DoneInvokeEvent<AxiosResponse>>({
        isMember: (_: unknown, event: any) => !!event.data,
      }),
      setFreeTrialStatus: assign<
        CTX,
        DoneInvokeEvent<AxiosResponse<FreeTrialSubscription>>
      >({
        freeTrialStatus: (_: unknown, event: any) =>
          getFreeTrialStatus(event.data?.data),
      }),
      setError: assign<CTX, DoneInvokeEvent<AxiosError>>({
        error: (_: any, event: any) => event.data,
      }),
      setPostAuth: assign<CTX, ClubMachineEventReset>({
        postAuth: (_: unknown, event: any) =>
          !!event.data?.postAuth || !!localStorage.getItem(clubAuthKey),
      }),
      reset: assign<CTX>({ ...defaultContext }),
      setClubJoined: assign<
        CTX,
        DoneInvokeEvent<AxiosResponse<ClubMembership>>
      >({
        isMember: (_: unknown, event: any) => !!event.data,
        clubJoined: (ctx, event: any) => {
          analytics.events.clubJoined({
            club: ctx.club,
            clubType: ctx.clubType,
          })

          return !!event.data
        },
      }),
      redirect: (ctx: CTX) => {
        if (!ctx.postAuth) return
        window.location.href = `${routes.checkout}/${ctx.club?.current_book?.isbn}?code=${promoCodes.readMore}`
      },
      setAuthKey: () => {
        localStorage.setItem(clubAuthKey, 'true')
      },
      removeAuthKey: () => {
        localStorage.removeItem(clubAuthKey)
      },
      freeTrialActivated: () => {
        analytics.events.trialStarted()
      },
      joinPostAuth: raise('JOIN') as InvokeAction<CTX>,
    } as any,
    services: {
      verifyMembership: async (context: CTX) => {
        return await get(
          `/v2/clubs/${context.club?.id}/members/${context.user?.id}`
        )
      },
      verifyFreeTrial: async () => {
        const { data: profile } = await get('/settings/profile/')
        const { data: subscriptions } = await get('/billing/subscriptions/')
        const freeTrial = getFreeTrialFromSubscriptions(subscriptions)

        const data: FreeTrialSubscription = {
          free_trial_used: !!profile?.free_trial_used,
          subscription: freeTrial,
        }

        return { data }
      },
      activateFreeTrial: async () => {
        const planId = allPlanOptions.premiumPlan.id
        const orderParams: PlanOrderParams = {
          line_items: [
            {
              product_code: `plan:${planId}`,
              quantity: 1,
            },
          ],
          free_trial: true,
        }

        const { data } = await post('/billing/orders/', orderParams)
        const orderId = data?.order?.id

        return await patch(`/billing/orders/${orderId}`, { is_paid: true })
      },
      createMembership: async (ctx: CTX) => {
        const { club, inviteId } = ctx
        const url = `/clubs/${club?.id}/members/`
        const request = inviteId ? { inviteId } : {}
        return await post(url, keysToSnakeCase(request))
      },
      getInvite: async (ctx: CTX) => await get(`/invites/${ctx.inviteId}`),
    } as any,
    guards: {
      authenticated: (_: CTX, event: ClubMachineEventVerify) =>
        !!event.data?.club && !!event.data?.user,
      unauthenticated: (_: CTX, event: ClubMachineEventVerify) =>
        !event.data?.user && !!event.data?.club,
      freeTrialExpired: (ctx: CTX) =>
        ctx.clubType === 'premium' &&
        !ctx.premiumUser &&
        ctx.freeTrialStatus === 'expired',
      isPostAuth: (ctx: CTX) => ctx.postAuth,
      canJoin: (ctx: CTX) =>
        !ctx.isMember &&
        (ctx.clubType === 'free' ||
          ctx.freeTrialStatus === 'active' ||
          ctx.premiumUser),
      canJoinFreeTrial: (ctx: CTX) =>
        !ctx.isMember &&
        ctx.clubType === 'premium' &&
        ctx.freeTrialStatus === 'pending',
      hasInviteId: (ctx: CTX) => !ctx.invite && !ctx.error && !!ctx.inviteId,
    } as any,
  }
)
