const ReviewStar = ({
  color,
  isHalf,
  isEmpty,
  className = '',
}: {
  className?: string
  color: string
  isHalf: boolean
  isEmpty: boolean
}) => {
  if (isEmpty) {
    return (
      <svg
        data-testid="starEmpty"
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.99999 0.750015C9.28546 0.750015 9.54617 0.91207 9.67252 1.16805L11.8157 5.50987L16.6085 6.2104C16.8909 6.25168 17.1254 6.44969 17.2134 6.72119C17.3015 6.9927 17.2278 7.29064 17.0233 7.48978L13.5559 10.867L14.3742 15.6382C14.4225 15.9196 14.3068 16.204 14.0758 16.3718C13.8448 16.5396 13.5386 16.5617 13.2859 16.4288L8.99999 14.1749L4.71408 16.4288C4.46139 16.5617 4.15517 16.5396 3.92419 16.3718C3.69321 16.204 3.57752 15.9196 3.62578 15.6382L4.4441 10.867L0.976692 7.48978C0.772228 7.29064 0.698524 6.9927 0.786557 6.72119C0.87459 6.44969 1.1091 6.25168 1.39152 6.2104L6.18429 5.50987L8.32746 1.16805C8.45381 0.91207 8.71453 0.750015 8.99999 0.750015ZM8.99999 3.19446L7.35502 6.52698C7.24587 6.74812 7.03498 6.90146 6.79096 6.93713L3.1113 7.47497L5.77329 10.0677C5.9502 10.2401 6.03094 10.4884 5.9892 10.7318L5.36113 14.3938L8.6509 12.6637C8.86944 12.5488 9.13054 12.5488 9.34908 12.6637L12.6389 14.3938L12.0108 10.7318C11.969 10.4884 12.0498 10.2401 12.2267 10.0677L14.8887 7.47497L11.209 6.93713C10.965 6.90146 10.7541 6.74812 10.645 6.52698L8.99999 3.19446Z"
          fill={color}
        />
      </svg>
    )
  }
  if (isHalf) {
    return (
      <svg
        data-testid="starHalf"
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00002 0.750015C9.28549 0.750015 9.5462 0.91207 9.67255 1.16805L11.8157 5.50987L16.6085 6.2104C16.8909 6.25168 17.1254 6.44969 17.2135 6.72119C17.3015 6.9927 17.2278 7.29064 17.0233 7.48978L13.5559 10.867L14.3742 15.6382C14.4225 15.9196 14.3068 16.204 14.0758 16.3718C13.8448 16.5396 13.5386 16.5617 13.2859 16.4288L9.00002 14.1749L4.71411 16.4288C4.46142 16.5617 4.1552 16.5396 3.92422 16.3718C3.69324 16.204 3.57755 15.9196 3.62582 15.6382L4.44413 10.867L0.976722 7.48978C0.772259 7.29064 0.698554 6.9927 0.786587 6.72119C0.874621 6.44969 1.10913 6.25168 1.39155 6.2104L6.18433 5.50987L8.32749 1.16805C8.45385 0.91207 8.71456 0.750015 9.00002 0.750015ZM9.00002 3.19446L7.35505 6.52698C7.2459 6.74812 7.03501 6.90146 6.79099 6.93713L3.11133 7.47497L5.77332 10.0677C5.95023 10.2401 6.03097 10.4884 5.98923 10.7318L5.36116 14.3938L8.65093 12.6637C8.86947 12.5488 9.13057 12.5488 9.34911 12.6637L12.6389 14.3938L12.0108 10.7318C11.9691 10.4884 12.0498 10.2401 12.2267 10.0677L14.8887 7.47497L11.2091 6.93713C10.965 6.90146 10.7541 6.74812 10.645 6.52698L9.00002 3.19446Z"
          fill={color}
        />
        <g clipPath="url(#clip0_394_7380)">
          <path
            d="M9.00002 0.750015C9.28549 0.750015 9.5462 0.91207 9.67255 1.16805L11.8157 5.50987L16.6085 6.2104C16.8909 6.25168 17.1254 6.44969 17.2135 6.72119C17.3015 6.9927 17.2278 7.29064 17.0233 7.48978L13.5559 10.867L14.3742 15.6382C14.4225 15.9196 14.3068 16.204 14.0758 16.3718C13.8448 16.5396 13.5386 16.5617 13.2859 16.4288L9.00002 14.1749L4.71411 16.4288C4.46142 16.5617 4.15521 16.5396 3.92422 16.3718C3.69324 16.204 3.57755 15.9196 3.62582 15.6382L4.44413 10.867L0.976722 7.48978C0.772259 7.29064 0.698554 6.9927 0.786587 6.72119C0.874621 6.44969 1.10913 6.25168 1.39155 6.2104L6.18433 5.50987L8.32749 1.16805C8.45385 0.91207 8.71456 0.750015 9.00002 0.750015Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_394_7380">
            <rect width="9" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      data-testid="starFull"
      className={className}
      width="15"
      height="15"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.59977 0.666748C7.85352 0.666748 8.08526 0.810796 8.19758 1.03833L10.1026 4.89773L14.3629 5.52042C14.6139 5.55712 14.8223 5.73312 14.9006 5.97446C14.9789 6.2158 14.9133 6.48063 14.7316 6.65765L11.6495 9.65966L12.3768 13.9007C12.4197 14.1508 12.3169 14.4036 12.1116 14.5528C11.9063 14.7019 11.6341 14.7216 11.4095 14.6035L7.59977 12.6L3.79007 14.6035C3.56546 14.7216 3.29327 14.7019 3.08795 14.5528C2.88263 14.4036 2.7798 14.1508 2.8227 13.9007L3.55009 9.65966L0.467949 6.65765C0.286204 6.48063 0.220689 6.2158 0.298941 5.97446C0.377192 5.73312 0.585646 5.55712 0.836686 5.52042L5.09693 4.89773L7.00197 1.03833C7.11428 0.810796 7.34603 0.666748 7.59977 0.666748Z"
        fill={color}
      />
    </svg>
  )
}

export default ReviewStar
