/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import isEmpty from 'lodash/isEmpty'

import { BookActions } from '../actions'
import { BookTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get, post, put, remove } from '@fable/api'
import { Book } from '@fable/types'
import { keysToSnakeCase } from '../../utils'
import { CreateBookListData, CheckedBookLists, BookList } from '../../types'

type DispatchType = Dispatch<BookActions>

const profileUrl = '/settings/profile/'
const ownedUrl = '/v2/books/owned?include=preorder&include=owned'

// CREATE BOOK LIST
export const createBookList =
  (obj: CreateBookListData) =>
  (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const createdBookList = state.books.createdBookList || {}

    dispatch(
      setAction(BookTypes.CREATE_BOOK_LIST, { ...createdBookList, ...obj })
    )
  }

// CREATE BOOK LIST CLEAR
export const createBookListClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.CREATE_BOOK_LIST__CLEAR))
}

// PREPEND BOOK LIST
export const prependBookList =
  () => async (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const { addBookListState, prependedBookLists, checkedBookLists } =
      state.books
    const prependData = [
      addBookListState.data,
      // This array is for displaying on top of the list in BookListPicker
      ...(prependedBookLists || []),
    ]

    dispatch(setAction(BookTypes.PREPEND_BOOK_LIST, prependData))
    await setCheckedBookLists({
      book: null,
      bookId: checkedBookLists.bookId,
      bookListIds: [addBookListState.data.id, ...checkedBookLists.bookListIds],
    })(dispatch)
  }

export const prependBookListClear = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.PREPEND_BOOK_LIST, null))
}

// ADD BOOK LIST
export const addBookList =
  () => async (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const {
      createdBookList: { name, description, privacy },
    } = state.books

    dispatch(setAction(BookTypes.ADD_BOOK_LIST__REQUEST))

    try {
      const { data }: { data: BookList } = await post(
        `/v2/users/${state.user.profile.data?.id}/book_lists`,
        { name, description, privacy }
      )

      dispatch(setAction(BookTypes.ADD_BOOK_LIST__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BookTypes.ADD_BOOK_LIST__ERROR, error))
    }
  }

// ADD FREE BOOK
export const addFreeBookToBookshelf =
  (bookId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.ADD_FREE_BOOK__REQUEST))
    try {
      const url = ownedUrl
      const params = {
        bookId,
      }
      const { data } = await post(url, keysToSnakeCase(params))
      dispatch(setAction(BookTypes.ADD_FREE_BOOK__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BookTypes.ADD_FREE_BOOK__ERROR, error))
    }
  }

// CLEAR FREE BOOK
export const clearFreeBook = () => (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.ADD_FREE_BOOK__CLEAR))
}

// BOOK DETAIL VIEWED
export const viewedBookDetail = (book: any) => () => {
  setAction(BookTypes.BOOK_DETAIL__VIEWED, book)
}

// GET BOOK DETAILS
export const getBookDetails =
  (isbn: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.GET_BOOK_DETAILS__REQUEST))
    try {
      const url = `/books/${isbn}/`
      const { data } = await get(url)
      dispatch(setAction(BookTypes.GET_BOOK_DETAILS__SUCCESS, data.response))
    } catch (error) {
      dispatch(setAction(BookTypes.GET_BOOK_DETAILS__ERROR, error))
    }
  }

// CLEAR BOOK DETAILS
export const clearBookDetails = () => (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.GET_BOOK_DETAILS__CLEAR))
}

// GET FEATURED BOOKS
export const getFeaturedBooks = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.GET_FEATURED_BOOKS__REQUEST))
  try {
    const url = '/featured_book_lists/'
    const { data } = await get(url)
    dispatch(setAction(BookTypes.GET_FEATURED_BOOKS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BookTypes.GET_FEATURED_BOOKS__ERROR, error))
  }
}

// GET PURCHASED BOOKS
export const getPurchasedBooks = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.GET_PURCHASED_BOOKS__REQUEST))
  try {
    const url = ownedUrl
    const { data } = await get(url)
    dispatch(setAction(BookTypes.GET_PURCHASED_BOOKS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BookTypes.GET_PURCHASED_BOOKS__ERROR, error))
  }
}

// GET WISHLIST
export const getWishlist = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.GET_WISHLIST__REQUEST))

  try {
    const { data: profile } = await get(profileUrl)
    const profileId = profile.id

    if (!isEmpty(profileId)) {
      const url = `/users/${profileId}/book_lists/`
      const { data } = await get(url)
      dispatch(setAction(BookTypes.GET_WISHLIST__SUCCESS, data))
    }
  } catch (error) {
    dispatch(setAction(BookTypes.GET_WISHLIST__ERROR, error))
  }
}

// GET CLUB READY BOOKS
export const getClubReadyBooks = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BookTypes.GET_CLUB_READY_BOOKS__REQUEST))
  try {
    const url = '/featured_book_lists/8fde84a2-ff64-4c30-bbc9-90b0e7477181'
    const { data } = await get(url)
    dispatch(setAction(BookTypes.GET_CLUB_READY_BOOKS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BookTypes.GET_CLUB_READY_BOOKS__ERROR, error))
  }
}

// GET BOOK LIST
export const getBookList =
  (bookListId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.GET_BOOK_LIST__REQUEST))
    try {
      const url = `/book_lists/${bookListId}?include_user=true`
      const { data } = await get(url)
      dispatch(setAction(BookTypes.GET_BOOK_LIST__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BookTypes.GET_BOOK_LIST__ERROR, error))
    }
  }

// GET BOOK LIST BOOKS
export const getBookListBooks =
  (bookListId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.GET_BOOK_LIST_BOOKS__REQUEST))
    try {
      const url = `/book_lists/${bookListId}/books`
      const { data } = await get(url)
      dispatch(setAction(BookTypes.GET_BOOK_LIST_BOOKS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BookTypes.GET_BOOK_LIST_BOOKS__ERROR, error))
    }
  }

// GET BOOK LIST BOOK IDS
export const getListBookIds =
  (uuid: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.GET_BOOK_LIST_BOOK_IDS__REQUEST))

    try {
      const { data } = await get(`/v2/users/${uuid}/book_lists`)

      const bookLists = data.results
      const bookListWithBooks = []

      for (let list of bookLists) {
        const bookListId = list.id
        const { data: bookListBooks } = await get(
          `/book_lists/${bookListId}/books`
        )
        const bookIds = bookListBooks.results.map(
          (item: { book: Book }) => item.book.id
        )
        bookListWithBooks.push([...bookIds])
      }

      dispatch(
        setAction(BookTypes.GET_BOOK_LIST_BOOK_IDS__SUCCESS, {
          ids: bookListWithBooks.flat(1),
        })
      )
    } catch (error) {
      dispatch(setAction(BookTypes.GET_BOOK_LIST_BOOK_IDS__ERROR, error))
    }
  }

// ADD TO BOOKLISTS (V2)
export const modifyBooklists =
  () => async (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const uuid = state.user.profile.data?.id
    const { book, bookId, bookListIds } = state.books.checkedBookLists
    const bookListIdsToRemove = (
      state.books.bookListPickerData.bookListIds || []
    ).filter((x: string) => !bookListIds.includes(x))
    const params = {
      bookId,
      bookListIds: bookListIds,
    }

    dispatch(setAction(BookTypes.MODIFY_BOOK_LIST__REQUEST))

    try {
      /*
      There is no API to remove a book from multiple book lists in bulk
      Adding and removing a book from multiple book lists is handled here
      */

      let result = null
      let bookSaved = false

      if (bookListIds.length) {
        result = await post(
          `/v2/users/${uuid}/book_lists/book`,
          keysToSnakeCase(params)
        )
        bookSaved = true
      }

      if (bookListIdsToRemove.length) {
        await Promise.all(
          bookListIdsToRemove.map(async (bookListId: string) => {
            const removalResult = await remove(
              `/v2/users/${uuid}/book_lists/${bookListId}/books/${bookId}`
            )
            dispatch(
              setAction(BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__SUCCESS, {
                isRemoved: true,
                result: removalResult,
                book,
              })
            )
          })
        )
      }

      dispatch(
        setAction(BookTypes.MODIFY_BOOK_LIST__SUCCESS, {
          result,
          book,
          bookSaved,
          bookId,
          bookListIdsToRemove,
        })
      )
    } catch (error) {
      dispatch(setAction(BookTypes.MODIFY_BOOK_LIST__ERROR, error))
    }
  }

// EDIT BOOKLIST
export const editBookList =
  (bookListId: string, editedData: CreateBookListData) =>
  async (dispatch: DispatchType, getState: () => any) => {
    dispatch(setAction(BookTypes.EDIT_BOOKLIST__REQUEST))
    const state = getState()
    const uuid = state.user.profile.data?.id

    try {
      const result = await put(
        `/v2/users/${uuid}/book_lists/${bookListId}`,
        editedData
      )

      state.books.bookList.data = {
        ...state.books.bookList.data,
        ...editedData,
      }

      dispatch(
        setAction(BookTypes.EDIT_BOOKLIST__SUCCESS, { isEdited: true, result })
      )
    } catch (error) {
      dispatch(setAction(BookTypes.EDIT_BOOKLIST__ERROR, error))
    }
  }

// DELETE BOOKLIST
export const deleteBookList =
  (bookListId: string) =>
  async (dispatch: DispatchType, getState: () => any) => {
    dispatch(setAction(BookTypes.DELETE_BOOKLIST__REQUEST))
    const state = getState()
    const uuid = state.user.profile.data?.id

    try {
      const result = await remove(`/v2/users/${uuid}/book_lists/${bookListId}`)
      dispatch(
        setAction(BookTypes.DELETE_BOOKLIST__SUCCESS, {
          isDeleted: true,
          result,
        })
      )
    } catch (error) {
      dispatch(setAction(BookTypes.DELETE_BOOKLIST__ERROR, error))
    }
  }

// REMOVE BOOK FROM BOOKLIST
export const removeBookFromBookList =
  (bookListId: string, book: Book) =>
  async (dispatch: DispatchType, getState: () => any) => {
    dispatch(setAction(BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__REQUEST))
    const state = getState()
    const uuid = state.user.profile.data?.id

    try {
      const result = await remove(
        `/v2/users/${uuid}/book_lists/${bookListId}/books/${book.id}`
      )

      dispatch(
        setAction(BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__SUCCESS, {
          isRemoved: true,
          result,
          book,
        })
      )
    } catch (error) {
      dispatch(setAction(BookTypes.REMOVE_BOOK_FROM_BOOK_LIST__ERROR, error))
    }
  }

// GET BOOK LISTS PAGINATED
export const getBookListsPaginated =
  ({ uuid, loadMore }: { uuid: string; loadMore?: boolean }) =>
  async (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    dispatch(setAction(BookTypes.GET_BOOK_LISTS_PAGINATED__REQUEST))

    let endpoint = `/v2/users/${uuid}/book_lists?limit=20`

    if (loadMore) {
      endpoint = state.books.bookListsPaginated.data?.next
    }

    try {
      const { data } = await get(endpoint)

      dispatch(setAction(BookTypes.GET_BOOK_LISTS_PAGINATED__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BookTypes.GET_BOOK_LISTS_PAGINATED__ERROR, error))
    }
  }

// GET BOOK LISTS PAGINATED CLEAR
export const getBookListsPaginatedClear =
  () => async (dispatch: DispatchType) => {
    dispatch(setAction(BookTypes.GET_BOOK_LISTS_PAGINATED__CLEAR))
  }

// SET CHECKED BOOK LISTS
export const setCheckedBookLists =
  ({ book, bookId, bookListIds = [] }: CheckedBookLists) =>
  (dispatch: DispatchType) => {
    // Used to set checked items in BooklistPicker
    dispatch(
      setAction(BookTypes.SET_CHECKED_BOOK_LISTS, {
        book,
        bookId,
        bookListIds,
      })
    )
  }

// GET BOOK LIST PICKER DATA
export const getBookListPickerData =
  ({ uuid, bookId }: { uuid: string; bookId: string }) =>
  async (dispatch: DispatchType) => {
    /* Gathers all data needed for BooklistPicker
    this is intercepted by bookListSaga to run a chain of events */
    dispatch(
      setAction(BookTypes.GET_BOOK_LIST_PICKER_DATA__REQUEST, { uuid, bookId })
    )
  }
