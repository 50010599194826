import axios from 'axios'
import fileDownload from 'js-file-download'

const downloadDocument = async ({
  link,
  name,
}: {
  link: string
  name: string
}) => {
  try {
    const response = await axios
      .get(link, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, name)
      })
    return response
  } catch (error) {
    return error
  }
}

export const downloads = {
  downloadDocument,
}
