import { useQuery } from 'react-query'
import { getCreditBalance } from '@fable/api'
import { FlexBox, Icon, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { CreditBalance } from '@fable/types'
import { useActions, useTypedSelector } from '../../../../../hooks'
import { formatAmount } from '../../../../../utils'
import { InviteReferralInfoModal } from './InviteReferralInfoModal'

export const InviteReferralCredit = () => {
  const { colors } = useTheme()
  const { openModal } = useActions()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)

  const creditBalanceData = useQuery(
    'creditBalance',
    () => getCreditBalance(),
    { enabled: isAuthenticated, refetchOnWindowFocus: false }
  )

  const creditBalance = creditBalanceData?.data?.data as CreditBalance
  const amount = !!creditBalance ? creditBalance.amount : 0
  const amountText = formatAmount({ value: amount, minimumFractionDigits: 2 })

  return (
    <FlexBox
      className={css`
        margin-top: 24px;
        padding: 16px;
        background-color: ${colors.whiteFang};
        border-radius: 12px;
        cursor: pointer;
        * {
          cursor: inherit;
        }
      `}
      onClick={() => openModal(<InviteReferralInfoModal />, 'm-card')}
      flexDirection="column"
      alignItems="center"
    >
      <FlexBox
        className={css`
          margin-bottom: 2px;
        `}
        centerAll
      >
        <Text
          className={css`
            margin-right: 5px;
          `}
          type="label"
          sizing="L"
          color={colors.hurricane}
          text="Available Credits"
        />
        <Icon
          className={css`
            width: 13px;
          `}
          type="info"
          fill={colors.hurricane}
        />
      </FlexBox>
      <Text
        type="subhead"
        sizing="XL"
        text={amountText}
        color={colors.success}
      />
    </FlexBox>
  )
}
