import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_TITANS_OF_INDUSTRY = gql`
  query TitansOfIndustry {
    titansOfIndustryList(id: "2QL9Gw5xNDxX0GLhJa8DVD", preview: ${isPreview}) {
      titansOfIndustryCollection {
        items {
          folioDetailSlug
          backgroundColor
          shortName
          fullName
          title
          description
          pic {
            url
          }
        }
      }
    }
  }
`
