import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect } from 'react'
import { useCart } from './useCart'
import { useTypedSelector } from './useTypedSelector'

export const useLoadCart = () => {
  const { service } = useCart()
  const { isVerifying } = useTypedSelector(({ auth }) => auth)
  const { profile } = useTypedSelector(({ user }) => user)

  const loadCart = useCallback(() => {
    if (isVerifying) return
    service.send({
      type: 'LOAD',
      data: !isEmpty(profile.data) ? profile.data : null,
    })
  }, [isVerifying, service, profile.data])

  useEffect(() => loadCart(), [loadCart])
}
