import { useQuery } from 'react-query'
import { BookImage, Loader } from '@fable/components'
import { Book, BookListResult } from '@fable/types'
import { Carousel } from 'components/carousel'
import { css, useTheme } from '@fable/theme'
import { ActivityCard } from '../ActivityCard'
import { requests } from '@fable/api'

interface Props {
  booklist: BookListResult
  onClickLike?: () => void
  onClickComment?: () => void
  onClickLink?: () => void
}

export const ActivityBooklist = ({
  booklist,
  onClickLike,
  onClickComment,
  onClickLink,
}: Props) => {
  const { mediaQueries } = useTheme()
  const { data, isLoading } = useQuery(['activity_booklist_books'], () =>
    requests.getBookListBooks({ id: booklist.id })
  )

  const books = data?.data?.results || []

  return (
    <ActivityCard
      data-testid="activity-booklist"
      author={{
        name: booklist.user?.display_name as string,
        image: booklist.user?.pic as string,
        label: 'List: ',
        link: { title: booklist.name, url: booklist.url, onClick: onClickLink },
        createdAt: booklist.updated_at,
        small: true,
        url: booklist.user?.url as string,
      }}
      counters={booklist.counters}
      onClickLike={onClickLike}
      onClickComment={onClickComment}
    >
      <div
        className={css`
          width: 100%;
          ${mediaQueries.mobileL} {
            overflow: hidden;
          }
        `}
      >
        {isLoading ? (
          <Loader
            className={css`
              height: 144px;
            `}
          />
        ) : (
          <div
            className={css`
              padding: 24px 0;
              ${mediaQueries.mobileL} {
                padding: 20px 24px;
              }
            `}
          >
            <Carousel
              options={{
                slides: { perView: 'auto', spacing: 8 },
              }}
              childWidths={[{ width: '88px', breakpoint: 'base' }]}
              noOverflow
            >
              {books.map(({ book }: { book: Book }) => (
                <div
                  key={book.id}
                  className={css`
                    width: 100%;
                    height: 144px;
                    border-radius: 8px;
                    overflow: hidden;
                  `}
                >
                  <BookImage
                    className={css`
                      width: 100%;
                      height: 100%;
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                      }
                    `}
                    image={book?.cover_image_resize}
                    altTag={`${book.title} cover`}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </ActivityCard>
  )
}
