import { Button, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import PageHeroShapes from '../../../../components/PageHeroShapes'
import peopleGreeting from '../../../../assets/images/graphics/people/people-greeting-circle.webp'
import { useActions, useTypedSelector } from '../../../../hooks'
import { getName } from '../../../../utils'
import { FORM_TYPES } from '../../../auth/constants'
import { analytics } from 'segment_analytics'

export const InvitePageHero = ({
  referralName,
}: {
  referralName: string | undefined
}) => {
  const { mediaQueries } = useTheme()
  const { openAuthModal } = useActions()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)

  const getTitle = () => {
    if (!!referralName) {
      const firstName = getName(referralName).first
      return `${firstName} has gifted you $5 on Fable!`
    }

    return (
      <>
        You’ve been gifted
        <br />
        $5 on Fable!
      </>
    )
  }

  const handleSignup = () => {
    if (isAuthenticated) {
      analytics.actions.appDownloadDeeplink()
      return
    }

    openAuthModal(FORM_TYPES.SIGN_UP)
  }

  return (
    <PageHeroShapes
      className={css`
        padding: 126px 16px 116px;
        display: flex;
        justify-content: center;
        ${mediaQueries.tablet} {
          padding-top: 84px;
        }
      `}
    >
      <div
        className={css`
          max-width: 320px;
          ${mediaQueries.mobileL} {
            max-width: 520px;
          }
        `}
      >
        <img
          className={css`
            margin: 0 auto;
            display: block;
            width: 92px;
          `}
          src={peopleGreeting}
          alt="people greeting"
        />
        <Text
          className={css`
            margin: 24px auto;
            ${mediaQueries.mobileL} {
              margin: 16px auto;
              max-width: 360px;
            }
          `}
          type="header"
          sizing={{ base: 'M', mobileL: 'L' }}
          align="center"
        >
          {getTitle()}
        </Text>
        <Text type="body" sizing={{ base: 'M', mobileL: 'L' }} align="center">
          Welcome to Fable, the book club app designed for interactive reading.
          Create an account and then download the app to get a $5 credit to use
          towards an ebook.
        </Text>
        <Button
          className={css`
            margin: 24px auto 0;
            width: 100%;
            max-width: 300px;
            ${mediaQueries.mobileL} {
              margin-top: 20px;
            }
          `}
          label={isAuthenticated ? 'Download the app' : 'Sign up'}
          onClick={handleSignup}
        />
      </div>
    </PageHeroShapes>
  )
}
