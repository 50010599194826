import { css, useTheme } from '@fable/theme'
import { marginEdgesRules, Text } from '@fable/components'
import { capitalize } from 'lodash'

const previewMapping = [
  {
    type: 'header',
    sizes: ['XXS', 'XS', 'S', 'M', 'L', 'XL'],
  },
  {
    type: 'subhead',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
  },
  {
    type: 'body',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
  },
  {
    type: 'label',
    sizes: ['S', 'M', 'L'],
  },
  {
    type: 'link',
    sizes: ['S', 'M', 'L'],
  },
]

const Title = ({ title }: { title: string }) => {
  const { colors } = useTheme()

  return (
    <p
      className={css`
        color: ${colors.hurricane};
        font-size: 40px;
        font-weight: 600;
      `}
    >
      {capitalize(title)}
    </p>
  )
}

const TypographyPreviewPage = () => {
  const { colors, mediaQueries } = useTheme()
  const testing = false

  if (testing) {
    return (
      <div
        className={css`
          padding: 40px;
        `}
      >
        <Text
          type="header"
          sizing="XL"
          text="this is some text"
          color={colors.graphite}
        />
      </div>
    )
  }

  return (
    <div
      className={css`
        padding: 40px ${marginEdgesRules.mobile};
        ${mediaQueries.mobileL} {
          padding: 40px ${marginEdgesRules.desktop};
        }
      `}
    >
      {previewMapping.map((item: any, i: number) => (
        <div
          key={i}
          className={css`
            margin-bottom: 40px;
            padding: 24px;
            border-radius: 24px;
            border: 2px solid ${colors.almostWhite};
            ${mediaQueries.mobileL} {
              padding: 40px;
            }
          `}
        >
          <Title title={item.type} />
          <div
            className={css`
              margin-top: 24px;
            `}
          >
            {item.sizes.reverse().map((size: any, o: number) => (
              <div
                className={css`
                  margin-top: 40px;
                  border-radius: 16px;
                `}
                key={o}
              >
                <p
                  className={css`
                    margin-bottom: 24px;
                    color: ${colors.hurricane};
                    font-size: 24px;
                  `}
                >
                  {size}
                </p>
                <Text type={item.type} sizing={size}>
                  Read great books together,
                  <br />a social platform for book clubs.
                </Text>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default TypographyPreviewPage
