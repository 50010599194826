// CARD PROVIDER IMAGES
import amexIcon from '../../assets/images/cards/amex.svg'
import mastercardIcon from '../../assets/images/cards/mastercard.svg'
import visaIcon from '../../assets/images/cards/visa.svg'

// BOOK COVER PLACEHOLDERS
import bookCoverGreen from '../../assets/images/graphics/placeholders/default-book-cover-green.webp'
import bookCoverPink from '../../assets/images/graphics/placeholders/default-book-cover-pink.webp'
import bookCoverWhite from '../../assets/images/graphics/placeholders/default-book-cover-white.webp'

// SEARCH BAR

export const BOOK_SEARCH_PLACEHOLDER = 'Search by book title or author...'
export const BOOK_SEARCH_NO_RESULTS_TEXT = '0 results - Try different words...'

// BOOKS

export const START_READING_TITLE = 'Start reading'
export const FREE_BOOK_CONFIRMATION_TITLE = 'Free book confirmation'

export const BOOKS_PER_LOAD = 6
export const MORE_BOOKS_TEXT = 'View more'

export const BOOK_COVER_PLACEHOLDERS = [
  bookCoverGreen,
  bookCoverPink,
  bookCoverWhite,
]

// SORT OPTIONS

export const WISHLIST_SORT_OPTIONS = [
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Length',
    key: 'page_count',
  },
  {
    name: 'Author',
    key: 'authors',
  },
  {
    name: 'Price',
    key: 'price_usd',
  },
]

export const PURCHASED_BOOKS_SORT_OPTIONS = [
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Author',
    key: 'authors',
  },
]

export const ORDERS_SORT_OPTIONS = [
  {
    name: 'Date',
    key: 'created',
  },
  {
    name: 'Total',
    key: 'total',
  },
]

// CHECKOUT
export const BILLING_ERROR_TEXT = 'The billing address is invalid.'
export const CARDHOLDER_NAME_ERROR_TEXT = "Please enter the cardholder's name"

export const CHECKOUT_FLOW = {
  billing: 'billing',
  details: 'details',
  confirmation: 'confirmation',
}

export const PAYMENT_OPTIONS = {
  newCard: 'new-card',
  savedCard: 'saved-card',
  provider: 'provider',
}

export const CARD_PROVIDERS = [
  {
    name: 'visa',
    icon: visaIcon,
  },
  {
    name: 'mastercard',
    icon: mastercardIcon,
  },
  {
    name: 'amex',
    icon: amexIcon,
  },
]

// BASIC INFO

export const BASIC_INFO_TYPES = {
  addCard: 'add-card',
}

export const BASIC_INFO_TITLES = {
  addCard: 'Add new credit card',
  upgradeToAllAccess: 'Upgrade to Premium',
}

// STORAGE KEYS
export const PUBLIC_FOLIO_KEY = 'public_folio'
export const SELECTED_FOLIO_BOOK_KEY = 'selected_folio_book'

// BOOK LISTS
export const BOOK_LIST_CREATE = 'booklistCreate'
export const BOOK_LIST_EDIT = 'booklistEdit'
export const BOOK_LIST_SAVE = 'booklistSave'

// GIFTS
export const GIFT_ALL_ACCESS_LINE_ONE = "Here's a Year of"
export const GIFT_ALL_ACCESS_LINE_TWO = 'Stories'
export const GIFT_BOOK_LINE_ONE = 'Here’s a gift,'
export const GIFT_BOOK_LINE_TWO = 'just for you'
export const GIFT_RECIPIENT_ID_SEARCH_PARAM = 'id'
export const GIFT_CODE_SEARCH_PARAM = 'code'
export const GIFT_PURCHASE_TITLE_BREAK = (
  <span>
    Give the Gift
    <br />
    of Fable
  </span>
)
export const GIFT_PURCHASE_TITLE_BREAK_COMMON = 'Give the Gift of Fable'
export const GIFT_PURCHASE_TEXT =
  'Surprise the bookworms in your life with a gift they’ll love. Give a book or a membership and share the joy of reading together.'
export const GIFT_BOUGHT_A_BOOK = 'gave you an ebook!'
export const GIFT_BOUGHT_ALL_ACCESS =
  'gave you an Annual Premium Membership on Fable!'
export const GIFT_ALL_ACCESS_TOAST_MESSAGE =
  'Congratulations on your new Fable membership'
export const GIFT_BOOK_TOAST_MESSAGE = 'Congratulations on your new book'
export const GIFT_CODE_GENERIC_ERROR = 'That code didn’t work. Try another one!'
export const GIFTS_ENABLED = true
