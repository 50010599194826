import { useEffect } from 'react'
import { isTestEnv } from 'utils'
import { InvokeResponse } from './types'

export function useMachineLogger(service: any) {
  useEffect(() => {
    if (isTestEnv) {
      service
        .onTransition((state: any) => {
          console.group(`${service.id}.service`)
          console.log('state:', state.value)
          console.log('context:', state.context)
          console.groupEnd()
        })
        .onEvent((event: any) => {
          console.log(`${service.id}.event`, event)
        })
    }
  }, [service])
}

export const pickInvokeData = <TData>(event: InvokeResponse<TData>) =>
  event?.data?.data
