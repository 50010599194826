/* eslint require-yield: 0 */
import { css, cx } from '@fable/theme'
import { singularSdk } from 'singular-sdk'
import {
  AuthTypes,
  ClubTypes,
  GiftsTypes,
  ModalTypes,
  SocialTypes,
  BookTypes,
  ToastTypes,
  UserTypes,
} from '../state/action-types'
import { setAction } from '../state/stateHandlers'
import { all, fork, put, take, select, call, delay } from 'redux-saga/effects'
import { fableApi, post, get } from '@fable/api'
import { keysToSnakeCase, handleDeeplink, getGiftQueryParams } from '../utils'
import { getProfileData } from './selectors/userSelectors'
import { getSocialUserData } from './selectors/socialSelectors'
import getUserDataSaga from './getUserDataSaga'
import { BookModal } from '../app/store/components/books/book_modal/BookModal'
import { PlanSelection, PlanUpsell } from '../components/plan_selection'
import checkIcon from '../assets/icons/standard/check-icon-bold.svg'
import { FlexBox } from '@fable/components'
import history from '../routes/history'
import { httpErrorCode } from '../utils'
import { lsKeys } from '../constants'
import isToday from 'date-fns/isToday'
import parseISO from 'date-fns/parseISO'
import { routes } from 'app/store/routes'

const POST_AUTH_ACTIONS_KEY = 'postAuthActions'
const { MODAL_AUTH__OPEN, MODAL__CLOSE } = ModalTypes
const {
  POST_AUTH_PLAN_UPSELL,
  POST_AUTH_FOLLOW_USER,
  POST_AUTH_CREATE_CLUB_MEMBERSHIP,
  AUTH__SUCCESS,
  TOKEN__SUCCESS,
  SIGNOUT__SUCCESS,
  VERIFY_USER__SUCCESS,
} = AuthTypes
const {
  POST_AUTH_REDEEM_GIFT,
  POST_AUTH_SUBMIT_GIFT_ORDER_DRAFT,
  SUBMIT_GIFT_ORDER_DRAFT__REQUEST,
  SUBMIT_GIFT_ORDER_DRAFT__SUCCESS,
  SUBMIT_GIFT_ORDER_DRAFT__ERROR,
} = GiftsTypes
const {
  POST_AUTH_ADD_BOOK,
  POST_AUTH_OPEN_APP,
  POST_AUTH_OPEN_SMS,
  ADD_FREE_BOOK__REQUEST,
  ADD_FREE_BOOK__SUCCESS,
  ADD_FREE_BOOK__ERROR,
} = BookTypes
const { SET_TOAST } = ToastTypes

function* closeModal() {
  yield put({
    type: ModalTypes.MODAL__CLEAR,
  })

  yield put({
    type: ModalTypes.MODAL__CLOSE,
  })
}

function* postAuthActionSave() {
  while (true) {
    const openAuthModal = yield take(MODAL_AUTH__OPEN)
    const postAuthActions = openAuthModal.payload.postAuthActions

    if (postAuthActions) {
      localStorage.setItem(
        POST_AUTH_ACTIONS_KEY,
        JSON.stringify(postAuthActions)
      )
    } else {
      localStorage.removeItem(POST_AUTH_ACTIONS_KEY)
    }
  }
}

function* postAuthActionCancel() {
  while (true) {
    yield take(MODAL__CLOSE)
    localStorage.removeItem(POST_AUTH_ACTIONS_KEY)
  }
}

function* postAuthGetUserBySlug(uuid, socialUuid, pathname) {
  if (uuid !== socialUuid) return

  yield put(setAction(SocialTypes.USER_LOOKUP__REQUEST))

  try {
    const { data } = yield fableApi.get(`/usernames${pathname}`)

    yield put(setAction(SocialTypes.USER_LOOKUP__SUCCESS, data))
  } catch (error) {
    yield put(setAction(SocialTypes.USER_LOOKUP__ERROR, error))
  }
}

const postAuthHandlers = {
  [POST_AUTH_ADD_BOOK]: function* ({ bookId }) {
    yield put({ type: ADD_FREE_BOOK__REQUEST })
    try {
      const url = '/v2/books/owned/'
      const params = {
        bookId,
      }
      const { data } = yield call(post, url, keysToSnakeCase(params))
      yield put({ type: ADD_FREE_BOOK__SUCCESS, payload: data })
    } catch (error) {
      yield put({ type: ADD_FREE_BOOK__ERROR, payload: error })
    }
  },
  [POST_AUTH_OPEN_APP]: function* ({ appLink }) {
    yield call(handleDeeplink, appLink)
  },
  [POST_AUTH_OPEN_SMS]: function* ({ book }) {
    yield put({
      type: ModalTypes.MODAL__OPEN,
      payload: { content: <BookModal book={book} isOwned /> },
    })
  },
  [POST_AUTH_SUBMIT_GIFT_ORDER_DRAFT]: function* ({
    bookParam,
    planParam,
    bookIsbn,
    giftOrder,
  }) {
    yield put({ type: SUBMIT_GIFT_ORDER_DRAFT__REQUEST })
    const profile = yield select(getProfileData)
    const giftData = {
      ...keysToSnakeCase({
        ...giftOrder,
        delivery_date: isToday(parseISO(giftOrder.delivery_date))
          ? null
          : giftOrder.delivery_date,
      }),
      id: profile.id,
    }

    try {
      const { data } = yield call(post, '/gifts', giftData)
      yield put({ type: SUBMIT_GIFT_ORDER_DRAFT__SUCCESS, payload: data })

      // Navigate to checkout
      const queryParams = yield call(getGiftQueryParams, {
        bookParam,
        planParam,
        bookIsbn,
        giftOrder: data,
      })

      yield call(history, `${routes.checkout}${queryParams}`)
    } catch (error) {
      yield put({ type: SUBMIT_GIFT_ORDER_DRAFT__ERROR, payload: error })
    }
  },
  [POST_AUTH_REDEEM_GIFT]: function* ({ giftCode }) {
    yield put({
      type: GiftsTypes.SET_GIFT_CLAIMED__REQUEST,
    })
    try {
      yield call(fableApi.post, '/gifts/redemption', {
        code: giftCode,
      })
      // The redemption API doesn't resolve anything, just a 200
      // but we need check for data in the component
      yield put({
        type: GiftsTypes.SET_GIFT_CLAIMED__SUCCESS,
        payload: { success: true },
      })
    } catch (error) {
      yield put({
        type: GiftsTypes.SET_GIFT_CLAIMED__ERROR,
        payload: error,
      })
    }
  },
  [POST_AUTH_CREATE_CLUB_MEMBERSHIP]: function* ({
    club,
    inviteId,
    startingFrom,
  }) {
    yield put(setAction(ClubTypes.CREATE_CLUB_MEMBERSHIP__REQUEST))
    try {
      const subscriptions = yield call(get, '/billing/subscriptions')
      const isPremiumClub = club.labels?.includes('premium')
      const userIsFreeTier = !subscriptions?.data?.some(
        (x) => x.plan.type === 'all_access'
      )

      /**
       * @note
       * if the club is a premium club and the user does not have a premium membership
       * they will need to sign up for a premium membership
       */
      if (isPremiumClub && userIsFreeTier) {
        yield put({
          type: ModalTypes.MODAL__OPEN,
          payload: {
            content: <PlanSelection club={club} />,
            modalType: 'm-card',
          },
        })

        return
      }

      const request = inviteId ? { inviteId } : {}

      let clubMembership = {}

      try {
        const { data } = yield call(
          post,
          `/clubs/${club.id}/members/`,
          keysToSnakeCase(request)
        )

        if (data) clubMembership = data
      } catch (error) {
        const status = httpErrorCode(error)
        // as used in action-creators/clubs.ts
        if (status !== 404) throw error
      } finally {
        yield put({
          type: ClubTypes.CREATE_CLUB_MEMBERSHIP__SUCCESS,
          payload: {
            club,
            clubMembership,
            startingFrom,
          },
        })

        const appDownloadElement = yield call(
          [document, document.getElementById],
          'app'
        )

        if (appDownloadElement) {
          appDownloadElement.scrollIntoView()
        }

        yield put({
          type: SET_TOAST,
          payload: {
            showToast: true,
            content: (
              <FlexBox centerAll>
                <img
                  src={checkIcon}
                  alt="Checkmark"
                  className={css`
                    margin-right: 16px;
                  `}
                />
                Welcome to the club!
              </FlexBox>
            ),
          },
        })

        yield delay(3000)

        yield put({
          type: SET_TOAST,
          payload: {
            showToast: false,
          },
        })
      }

      /*
      If the club was joined via invitation, get user data again because
      certain actions must be taken based on the user's capabilities assigned by their organization
      (the inviter)
      */
      if (inviteId) yield call(getUserDataSaga)
    } catch (error) {
      yield put({
        type: ClubTypes.CREATE_CLUB_MEMBERSHIP__ERROR,
        payload: error,
      })
    }
  },
  [POST_AUTH_FOLLOW_USER]: function* ({ uuid }) {
    yield put(setAction(SocialTypes.POST_FOLLOW_USER__REQUEST))
    try {
      const { data } = yield fableApi.post(`users/${uuid}/followers/`)
      const socialUserData = yield select(getSocialUserData)
      const socialUuid = socialUserData.id

      yield postAuthGetUserBySlug(uuid, socialUuid, window.location.pathname)

      yield put(setAction(SocialTypes.POST_FOLLOW__SUCCESS, data))
    } catch (error) {
      yield put(setAction(SocialTypes.POST_FOLLOW_USER__ERROR, error))
    }
  },
  [POST_AUTH_PLAN_UPSELL]: function* ({ club }) {
    yield put({
      type: ModalTypes.MODAL__CLOSE,
    })
    yield put({
      type: ModalTypes.MODAL__CLEAR,
    })

    yield delay(300)

    yield put({
      type: ModalTypes.MODAL__OPEN,
      payload: {
        content: (
          <PlanUpsell small club={club} successActions={{ toast: true }} />
        ),
        modalType: cx(
          'm-card',
          css`
            .modal {
              max-height: calc(100% - 100px) !important;
              overflow-y: auto !important;
            }
          `
        ),
      },
    })
  },
}

function* postAuthActionExecute() {
  while (true) {
    yield take(TOKEN__SUCCESS)
    const postAuthActions = localStorage.getItem(POST_AUTH_ACTIONS_KEY)

    if (postAuthActions) {
      const postAuthActionsParsed = JSON.parse(postAuthActions)
      const { payload: user } = yield take(AUTH__SUCCESS)

      localStorage.removeItem(POST_AUTH_ACTIONS_KEY)

      yield call(closeModal)

      for (const action of postAuthActionsParsed) {
        if (postAuthHandlers[action.type]) {
          const payload = action.payload || {}
          yield* postAuthHandlers[action.type]({ ...payload, user })
        }
      }
    } else {
      yield call(closeModal)
    }
  }
}

function* postAuthActionRedirect() {
  while (true) {
    yield take(VERIFY_USER__SUCCESS)
    const postAuthRedirect = localStorage.getItem(lsKeys.postAuthRedirect)

    if (!!postAuthRedirect) {
      const redirect = JSON.parse(postAuthRedirect)
      yield call(history.push, redirect.path)
      localStorage.removeItem(lsKeys.postAuthRedirect)
    }
  }
}

function* postAuthSuccess() {
  yield take(AUTH__SUCCESS)
  const { payload } = yield take(UserTypes.GET_USER_PROFILE__SUCCESS)
  const userId = payload.id || ''
  singularSdk.login(userId)
}

function* postAuthSignOut() {
  yield take(SIGNOUT__SUCCESS)
  singularSdk.logout()
}

export default function* postAuthSaga() {
  yield all(
    [
      postAuthActionSave,
      postAuthActionExecute,
      postAuthActionCancel,
      postAuthActionRedirect,
      postAuthSuccess,
      postAuthSignOut,
    ].map(fork)
  )
}
