import { ApolloClient, InMemoryCache } from '@apollo/client'

const { REACT_APP_SPACE_ID, REACT_APP_CDA_TOKEN, REACT_APP_CPA_TOKEN } =
  process.env

export const isPreview = !!new URLSearchParams(window.location.search).get(
  'preview'
)

const apolloClient = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_SPACE_ID}`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${
      isPreview ? REACT_APP_CPA_TOKEN : REACT_APP_CDA_TOKEN
    }`,
  },
})

export default apolloClient
