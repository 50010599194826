import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import AppCodeSplitErrorBoundary from '../app_code_split_error_boundary/AppCodeSplitErrorBoundary'
import { ErrorBoundaryLoader } from 'components/error_boundary/ErrorBoundaryLoader'

const SuspenseWithErrorBoundary: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ErrorBoundary FallbackComponent={AppCodeSplitErrorBoundary}>
    <Suspense fallback={<ErrorBoundaryLoader />}>{children}</Suspense>
  </ErrorBoundary>
)

export default SuspenseWithErrorBoundary
