import { css, cx, useTheme } from '@fable/theme'
import greenShapesDesktop from '../assets/images/graphics/fable-hero-clouds-desktop.webp'
import greenShapesMobile from '../assets/images/graphics/fable-hero-clouds-mobile.webp'
import pinkShapesDesktop from '../assets/images/graphics/fable-hero-clouds-desktop-pink.webp'
import pinkShapesMobile from '../assets/images/graphics/fable-hero-clouds-mobile-pink.webp'

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[]
  color?: 'pink' | 'green'
  hideDivider?: boolean
}

const PageHeroShapes = ({
  children,
  color = 'pink',
  hideDivider = false,
  className,
  ...nativeProps
}: Props) => {
  const { colors, mediaQueries } = useTheme()

  const getColorScheme = () => {
    switch (color) {
      case 'pink':
        return {
          color: colors.pinkMoonLight,
          images: {
            mobile: pinkShapesMobile,
            desktop: pinkShapesDesktop,
          },
        }
      case 'green':
        return {
          color: colors.fableGreen,
          images: {
            mobile: greenShapesMobile,
            desktop: greenShapesDesktop,
          },
        }
    }
  }

  const colorScheme = getColorScheme()

  const heroImageStyles = css`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `

  return (
    <div
      className={cx(
        css`
          position: relative;
          background-color: ${colorScheme?.color};
        `,
        className
      )}
      {...nativeProps}
    >
      <img
        className={cx(
          css`
            display: block;

            ${mediaQueries.mobileL} {
              display: none;
            }
          `,
          heroImageStyles
        )}
        src={colorScheme?.images.mobile}
        alt="Fable hero clouds illustration"
      />
      <img
        className={cx(
          css`
            display: none;
            ${mediaQueries.mobileL} {
              display: block;
            }
          `,
          heroImageStyles
        )}
        src={colorScheme?.images.desktop}
        alt="Fable hero clouds illustration"
      />
      <div
        className={css`
          position: relative;
        `}
      >
        {children}
      </div>
      {hideDivider ? null : (
        <span
          className={css`
            display: block;
            background-color: ${colors.whiteFang};
            border-radius: 24px 24px 0 0;
            width: 100%;
            height: 60px;
            position: absolute;
            bottom: -2px;
            left: 0;
          `}
        />
      )}
    </div>
  )
}

export default PageHeroShapes
