import { requests } from '@fable/api'
import { useQuery } from 'react-query'
import { useTypedSelector } from './useTypedSelector'

const useCacheOwnedBooks = () => {
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)

  useQuery(
    ['owned-books'],
    () => requests.getOwnedBooks({ includePreorder: true }),
    {
      enabled: !!isAuthenticated,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}

export default useCacheOwnedBooks
