import { useEffect } from 'react'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'

export const useGiftCertificateLookup = ({
  recipientId,
  giftCode,
}: {
  recipientId?: string | null
  giftCode?: string | null
}) => {
  const { getGiftCardDetails } = useActions()
  const { giftCardDetails } = useTypedSelector(({ gifts }) => gifts)

  useEffect(() => {
    if (
      !giftCardDetails.data &&
      !giftCardDetails.loading &&
      !giftCardDetails.error
    ) {
      if (recipientId) getGiftCardDetails({ recipientId })
      else if (giftCode) getGiftCardDetails({ giftCode })
    }
  }, [
    getGiftCardDetails,
    giftCardDetails.data,
    giftCardDetails.error,
    giftCardDetails.loading,
    giftCode,
    recipientId,
  ])

  return giftCardDetails
}
