import { css, useTheme } from '@fable/theme'
import { Link } from 'react-router-dom'
import { FlexBox, Text } from '@fable/components'
import { HEADER_RIGHT_NAV_LINKS } from './constants'
import { HeaderNavItem } from './types'

export const HeaderNavRight: React.VFC<{
  secondary: boolean
}> = ({ secondary }) => {
  const { colors, motion, mediaQueries } = useTheme()

  return (
    <FlexBox
      className={css`
        display: none;

        ${mediaQueries.tablet} {
          display: block;
        }
      `}
      justifyContent="flex-end"
      alignItems="center"
    >
      {HEADER_RIGHT_NAV_LINKS.map((item: HeaderNavItem, i: number) => (
        <Link
          key={i}
          className={css`
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            ${motion.transition('opacity')};

            &:hover {
              opacity: 0.7;
            }

            ${mediaQueries.tabletL} {
              margin-right: 18px;
            }

            ${mediaQueries.desktop} {
              margin-right: 40px;
            }
          `}
          to={item.path}
        >
          <Text
            type="body"
            sizing={{ base: 'S', tabletL: 'M' }}
            text={item.title}
            color={secondary ? colors.uiPearlExtraLight : colors.uiGraphite}
          />
        </Link>
      ))}
    </FlexBox>
  )
}
