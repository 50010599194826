import { css, useTheme } from '@fable/theme'

export const PurchaseButtonContent = ({
  long,
  short,
  reverse = false,
}: {
  long?: string
  short: string
  reverse?: boolean
}) => {
  const { mediaQueries } = useTheme()

  if (reverse) {
    return (
      <>
        <span
          className={css`
            margin-right: 3px;
            display: none;
            ${mediaQueries.mobileL} {
              display: inline;
            }
          `}
        >
          {long}
        </span>
        {short}
      </>
    )
  }

  return (
    <>
      {short}
      {!!long && (
        <span
          className={css`
            margin-left: 3px;
            display: none;
            ${mediaQueries.mobileL} {
              display: inline;
            }
          `}
        >
          {long}
        </span>
      )}
    </>
  )
}
