import { IconSVGProps } from '../types'

export const StarIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg className={className} viewBox="0 0 22 21">
    <path
      className="fds-ui--icon-stroke"
      d="M22,8c-0.1-0.4-0.4-0.6-0.8-0.7l-6.4-0.9l-2.8-5.8C11.7,0.2,11.4,0,11,0s-0.7,0.2-0.9,0.6L7.2,6.3L0.9,7.3
C0.5,7.3,0.2,7.6,0,8c-0.1,0.4,0,0.8,0.2,1l4.6,4.5l-1.1,6.4c-0.1,0.4,0.1,0.8,0.4,1C4.5,21,5,21.1,5.3,20.9l5.7-3l5.7,3
c0.3,0.2,0.8,0.1,1.1-0.1c0.3-0.2,0.5-0.6,0.4-1l-1.1-6.4L21.7,9C22,8.7,22.1,8.3,22,8z M15.3,12.4C15.1,12.7,15,13,15,13.3l0.8,4.9
l-4.4-2.3c-0.3-0.2-0.6-0.2-0.9,0l-4.4,2.3L7,13.3c0-0.3-0.1-0.7-0.3-0.9L3.2,9L8,8.2c0.3,0,0.6-0.2,0.8-0.5L11,3.3l2.2,4.4
C13.3,8,13.6,8.2,14,8.2L18.8,9L15.3,12.4z"
      fill={fill}
    />
    <path
      className="fds-ui--icon-fill"
      d="M15.3,13.5l0.9,5.3l-4.7-2.5c-0.3-0.2-0.7-0.2-1,0l-4.7,2.5l0.9-5.3c0.1-0.4-0.1-0.7-0.3-1L2.5,8.8L7.8,8
C8.2,8,8.5,7.8,8.6,7.4L11,2.6l2.4,4.8C13.5,7.8,13.8,8,14.2,8l5.3,0.8l-3.8,3.7C15.4,12.8,15.3,13.2,15.3,13.5z"
      fill="transparent"
    />
  </svg>
)
