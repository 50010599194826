import { useTheme as useThemeBase } from '@emotion/react'
import { FableTheme } from './themeTypes'
import themeBase from './theme'
export { Global, ThemeProvider } from '@emotion/react'
// For TypeScript
export const theme = themeBase as FableTheme
export const useTheme = useThemeBase as () => FableTheme
export { default as injectGlobalMod } from './injectGlobalMod'
export { default as QUOTE_THEME } from './quoteThemes'
export * from './useGlobalStyles'

export * from '@emotion/css'
export * from './themeTypes'
