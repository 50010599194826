import cloudImageBaseUrl from './cloudImageBaseUrl'

const cloudImageResize = (url: string, width: number): string => {
  let imageUrl = url

  if (!imageUrl.includes(cloudImageBaseUrl)) {
    const formattedUrl = url.replace(
      /^https:\/\/(?:atsxghqeko.cloudimg.io\/v7\/)?(?:https:\/\/)?/i,
      ''
    )
    imageUrl = `${cloudImageBaseUrl}${formattedUrl}`
  }

  if (imageUrl.includes('?')) {
    if (imageUrl.includes('w=')) {
      imageUrl.replace(/w=\d+/, `w=${width}`)
    } else {
      imageUrl += `&w=${width}`
    }

    return imageUrl
  }

  if (!!width) {
    return `${imageUrl}?w=${width}`
  }

  return imageUrl
}

export default cloudImageResize
