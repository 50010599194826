import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import add from 'date-fns/add'
import { utcToZonedTime, format as formatTz } from 'date-fns-tz'
import intervalToDuration from 'date-fns/intervalToDuration'

const DEFAULT_DATE_FORMAT = 'MMM d'
const DEFAULT_DATE_FORMAT_WITH_YEAR = DEFAULT_DATE_FORMAT + ' yyyy'

export const DATE_FORMAT_REGULAR = 'MMM d, yyyy'
export const DATE_FORMAT_LONG = 'd MMMM yyyy'

export const formatDateCustom = (date: string, pattern: string): string => {
  return format(new Date(date), pattern)
}

export const formatDate = (date: string, includeYear = true): string => {
  return formatDateCustom(
    date,
    includeYear ? DEFAULT_DATE_FORMAT_WITH_YEAR : DEFAULT_DATE_FORMAT
  )
}

export const getToday = (pattern: string = DEFAULT_DATE_FORMAT): string => {
  return format(new Date(), pattern)
}

export const getDate = (date: string): Date => {
  return new Date(date)
}

export const isBeforeToday = (date: string): boolean => {
  const newDate = getDate(date)
  const today = new Date()
  return isBefore(newDate, today)
}

export const getDateTime = (date: string): Record<string, string> => {
  const day = formatDateCustom(date, 'd')
  const month = formatDateCustom(date, 'MMM')
  const time = formatDateCustom(date, 'h:mm a')

  return { day, month, time }
}

export const getYearsAsSeconds = (years: number): number => {
  const start = new Date()
  const end = add(new Date(), { years })
  return Math.abs((start.getTime() - end.getTime()) / 1000)
}

export const getDifferenceInMonths = (
  start: string | undefined,
  end: string | undefined,
  options?: { roundUp?: boolean }
) => {
  if (!start || !end) return
  const startDate = new Date(start)
  const endDate = new Date(end)

  const difference = intervalToDuration({
    start: startDate,
    end: endDate,
  }) as {
    years: number
    months: number
    days: number
    hours: number
    minutes: number
    seconds: number
  }

  if (options?.roundUp && difference.days > 15) {
    return difference.months + 1
  }

  return difference.months
}

export const getZonedTime = (date: string, pattern: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zonedTime = utcToZonedTime(date, timeZone)

  return formatTz(zonedTime, pattern, { timeZone })
}

export const getDatePattern = (date: string, pattern: string) => {
  const mins = new Date(date).getMinutes()

  if (mins !== 0) {
    const i = pattern.indexOf('h')
    return pattern.slice(0, i + 1) + ':mm' + pattern.slice(i + 1)
  }

  return pattern
}

export const getDateString = ({
  date,
  pattern = DATE_FORMAT_REGULAR,
  persist = false,
}: {
  date: string | undefined
  pattern?: string
  persist?: boolean
}) => {
  if (!date || !date.length) return null

  const dt = new Date(date)

  if (persist) {
    const originalDate = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    )
    return format(originalDate, pattern)
  }

  return getZonedTime(date, pattern)
}
