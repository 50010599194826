/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Response } from '../../types'
import { SearchActions } from '../actions'
import { SearchTypes } from '../action-types'
import {
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

enum SearchKeys {
  bookResults = 'bookResults',
}

interface SearchState {
  [SearchKeys.bookResults]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: SearchState = {
  [SearchKeys.bookResults]: props,
}

const reducer = (
  state: SearchState = initialState,
  action: SearchActions
): SearchState => {
  const stateProps = { state, props }

  switch (action.type) {
    // SEARCH BOOKS
    case SearchTypes.SEARCH_BOOKS__REQUEST:
      return setRequestState(stateProps, SearchKeys.bookResults)
    case SearchTypes.SEARCH_BOOKS__SUCCESS:
      return setSuccessState(stateProps, SearchKeys.bookResults, action.payload)
    case SearchTypes.SEARCH_BOOKS__ERROR:
      return setErrorState(stateProps, SearchKeys.bookResults, action.payload)

    default:
      return state
  }
}

export default reducer
