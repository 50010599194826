import { css, useTheme } from '@fable/theme'
import { Review } from '@fable/types'
import formatDistance from 'date-fns/formatDistance'
import { FlexBox, Text } from '../'
import RoundImage from '../RoundImage'
import BookReview from './BookReview'
import { TextTags } from '../text/types'

const BookReviewModal = ({
  review,
  showGiftBookButton,
  onCopyToClipboard,
  titleTag = 'h4',
}: {
  review: Review
  /** @note temporary until redux is removed */
  showGiftBookButton: boolean
  onCopyToClipboard: () => void
  titleTag?: TextTags
}) => {
  const { mediaQueries, colors } = useTheme()

  return (
    <div
      className={css`
        width: 100%;
        max-width: 508px;
        padding: 22px 35px 68px 35px;
        ${mediaQueries.mobileL} {
          width: 458px;
        }
        ${mediaQueries.tablet} {
          padding-right: 68px;
          padding-left: 68px;
        }
      `}
    >
      <FlexBox
        className={css`
          margin-bottom: 16px;
          background: ${colors.whiteFang};
        `}
        flexDirection="column"
        alignItems="center"
      >
        <RoundImage
          size={48}
          src={review.user.pic || ''}
          alt={review.user.display_name || ''}
        />
        <Text
          className={css`
            margin: 6px auto;
          `}
          type="subhead"
          sizing={{ base: 'XS', mobileL: 'S' }}
          tag={titleTag}
        >
          <b>{review.user.display_name}'s review</b>
        </Text>
        <Text type="body" sizing="S" color={colors.hurricane}>
          Created&nbsp;
          {formatDistance(new Date(review.created_at), new Date(), {
            addSuffix: true,
          })}
        </Text>
      </FlexBox>
      <BookReview
        review={review}
        isPreview={false}
        showGiftBookButton={showGiftBookButton}
        onCopyToClipboard={onCopyToClipboard}
      />
    </div>
  )
}

export default BookReviewModal
