import { useTheme, css } from '@fable/theme'
import { Club } from '@fable/types'
import { analytics } from 'segment_analytics'
import { getClubType } from 'app/clubs/utils'
import { ClubCard } from 'components/cards'
import { CarouselRow } from 'components/carousel'
import { useVertExpEnabled } from 'hooks'

const blacklisted = ['1f9efe35-5b60-4e0c-aa89-457511f7b6fc']

interface Props {
  title: string
  items: Club[]
  type?: 'default' | 'popular-clubs-starting-soon'
  hideControls?: boolean
}

export const HomeClubsRow = ({
  title,
  items,
  type = 'default',
  hideControls = false,
}: Props) => {
  const { mediaQueries, breakpoints, motion } = useTheme()
  const clubs =
    !!items?.length && items.filter((club) => !blacklisted.includes(club.id))

  const vertExpEnabled = useVertExpEnabled()

  const onSendClubEvent = (club: Club) => {
    if (vertExpEnabled) return

    const clubType = getClubType({ club })

    if (type === 'popular-clubs-starting-soon') {
      analytics.events.popularClubStartingSoon({ club, clubType })
    }
  }

  return (
    <CarouselRow
      data-testid="homeClubsCarousel"
      className={css`
        &:not(:last-child) {
          margin-bottom: 60px;
          ${mediaQueries.mobileL} {
            margin-bottom: 72px;
          }
        }
      `}
      title={title}
      hideControls={hideControls}
      config={{
        options: {
          loop: true,
          slides: { perView: 'auto', spacing: 20 },
          breakpoints: {
            [`(min-width: ${breakpoints.desktop}px)`]: {
              loop: false,
              slides: { perView: 'auto', spacing: 12 },
            },
          },
        },
        childWidths: [
          { width: '95%', breakpoint: 'base' },
          { width: '208px', breakpoint: breakpoints.mobile },
          { width: '256px', breakpoint: breakpoints.desktop },
        ],
        noOverflow: true,
      }}
    >
      {!clubs
        ? null
        : clubs.map((club: Club, i: number) => (
            <ClubCard
              key={i}
              className={css`
                height: 100%;
                ${mediaQueries.mobileL} {
                  ${motion.transition('transform', 'ease-in-out', '0.4s')}
                  &:hover {
                    transform: scale(1.01);
                  }
                }
                @media only screen and (max-width: ${breakpoints.mobile}px) {
                  .fds-ui--club-card-image {
                    height: 70vw;
                  }
                }
              `}
              club={club}
              onCardClick={() => onSendClubEvent(club)}
            />
          ))}
    </CarouselRow>
  )
}
