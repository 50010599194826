/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { FolioActions } from '../actions'
import { FolioTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get } from '@fable/api'

type DispatchType = Dispatch<FolioActions>

// GET FOLIOS
export const getFolios =
  (includeArchive = false, includeBooks = false) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(FolioTypes.GET_FOLIOS__REQUEST))
    const includeBooksParam = includeBooks ? 'true' : 'false'
    try {
      const currentUrl = `/folios/?platform=web&include_books=${includeBooksParam}`
      const archiveUrl = `/folios/?archive=true&platform=web&include_books=${includeBooksParam}`
      const [currentFoliosResponse, archivedFoliosResponse] = await Promise.all(
        [
          get(currentUrl),
          includeArchive
            ? get(archiveUrl)
            : (Promise.resolve({ data: [] }) as any),
        ]
      )

      const folios = [
        ...currentFoliosResponse.data,
        ...archivedFoliosResponse.data,
      ]
      dispatch(
        setAction(FolioTypes.GET_FOLIOS__SUCCESS, { folios, includeBooks })
      )
    } catch (error) {
      dispatch(setAction(FolioTypes.GET_FOLIOS__ERROR, error))
    }
  }

// GET FOLIO DETAILS
export const getFolioDetails =
  (folioId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(FolioTypes.GET_FOLIO_DETAILS__REQUEST))
    try {
      const url = `/folios/${folioId}/`
      const { data } = await get(url)
      dispatch(setAction(FolioTypes.GET_FOLIO_DETAILS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(FolioTypes.GET_FOLIO_DETAILS__ERROR, error))
    }
  }

// GET FOLIO DETAILS - CLEAR
export const clearFolioDetails = () => (dispatch: DispatchType) => {
  dispatch(setAction(FolioTypes.GET_FOLIO_DETAILS__CLEAR))
}

// GET FOLIO BOOKS
export const getFolioBooks =
  (folioId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(FolioTypes.GET_FOLIO_BOOKS__REQUEST))
    try {
      const url = `/folios/${folioId}/books/`
      const { data } = await get(url)
      dispatch(setAction(FolioTypes.GET_FOLIO_BOOKS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(FolioTypes.GET_FOLIO_BOOKS__ERROR, error))
    }
  }
