import { FlexBox, marginEdgesRules } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { CompChildren } from 'types'

export const PageDetailFooter = ({ footer }: { footer: CompChildren }) => {
  const { colors, mediaQueries } = useTheme()

  return (
    <FlexBox
      className={css`
        overflow: hidden;
        padding: 0 ${marginEdgesRules.mobile};
        ${mediaQueries.mobileL} {
          padding: 0 ${marginEdgesRules.tablet};
        }
      `}
      width="100%"
      justifyContent="center"
    >
      <div
        className={css`
          max-width: 352px;
          width: 100%;
          margin-top: 34px;
          padding-top: 28px;
          border-top: 1px solid ${colors.whiteSmoke};
          ${mediaQueries.tabletL} {
            max-width: 1044px;
            margin-top: 48px;
            padding-top: 40px;
          }
        `}
      >
        {footer}
      </div>
    </FlexBox>
  )
}
