import { IconSVGProps } from '../types'

export const InfoIconReverse: React.VFC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    fill={fill}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00002 17.3333C4.39752 17.3333 0.666687 13.6025 0.666687 8.99996C0.666687 4.39746 4.39752 0.666626 9.00002 0.666626C13.6025 0.666626 17.3334 4.39746 17.3334 8.99996C17.3334 13.6025 13.6025 17.3333 9.00002 17.3333ZM8.16669 8.16663V13.1666H9.83335V8.16663H8.16669ZM8.16669 4.83329V6.49996H9.83335V4.83329H8.16669Z"
      fill="#292229"
    />
  </svg>
)
