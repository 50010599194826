import { css, cx, useTheme } from '@fable/theme'
import { colorHexToRgba } from 'utils'

interface Props extends React.HTMLProps<HTMLSpanElement> {
  direction?: 'top' | 'bottom' | 'left' | 'right'
  height?: string
  color?: string
}

export const Gradient = ({
  direction = 'bottom',
  color,
  className,
  ...nativeProps
}: Props) => {
  const { motion, colors } = useTheme()
  const gradientColor = color || colors.uiWhite

  return (
    <span
      className={cx(
        css`
          display: block;
          width: 100%;
          height: 50%;
          background: linear-gradient(
            to ${direction},
            ${colorHexToRgba({ color: gradientColor, alpha: 0 })},
            ${colorHexToRgba({ color: gradientColor })}
          );
          position: absolute;
          bottom: 0;
          left: 0;
          pointer-events: none;
          opacity: 1;
          ${motion.transition('opacity')};
        `,
        className
      )}
      {...nativeProps}
    />
  )
}
