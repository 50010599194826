import './ResultList.scss'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import BookResult from '../result_categories/BookResult'
import ResultLoader from '../result_loader/ResultLoader'

import { BOOK_SEARCH_NO_RESULTS_TEXT } from '../../../app/store/constants'
import { CATEGORIES } from '../../../constants'

export const ResultList = ({
  category,
  didSearch,
  isSearching,
  onSelectItem,
  results,
}) => {
  let categoryTitle

  switch (category) {
    case CATEGORIES.book:
      categoryTitle = 'Books'
      break
    case CATEGORIES.author:
      categoryTitle = 'Authors'
      break
    default:
      break
  }

  const renderContent = () => {
    if (isSearching) return <ResultLoader />
    if (isEmpty(results))
      return <p className="no-results label">{BOOK_SEARCH_NO_RESULTS_TEXT}</p>

    const renderedResults = results.map((result) => {
      return (
        <BookResult item={result} key={result.id} onSelectItem={onSelectItem} />
      )
    })

    return (
      <>
        <label className="label">{categoryTitle}</label>
        <div className={`items ${category}-results`}>{renderedResults}</div>
      </>
    )
  }

  return <div className="result-list">{didSearch ? renderContent() : null}</div>
}

ResultList.propTypes = {
  category: PropTypes.string.isRequired,
  didSearch: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  onSelectItem: PropTypes.func,
  results: PropTypes.array,
}

ResultList.defaultProps = {
  category: '',
  didSearch: false,
  isSearching: false,
  onSelectItem: () => {},
  results: [],
}
