export enum GiftsTypes {
  // SET GIFT ORDER
  SET_GIFT_ORDER = 'SET_GIFT_ORDER',
  SET_GIFT_ORDER__CLEAR = 'SET_GIFT_ORDER__CLEAR',

  // SUBMIT GIFT ORDER DRAFT
  SUBMIT_GIFT_ORDER_DRAFT__REQUEST = 'SUBMIT_GIFT_ORDER_DRAFT__REQUEST',
  SUBMIT_GIFT_ORDER_DRAFT__SUCCESS = 'SUBMIT_GIFT_ORDER_DRAFT__SUCCESS',
  SUBMIT_GIFT_ORDER_DRAFT__ERROR = 'SUBMIT_GIFT_ORDER_DRAFT__ERROR',
  SUBMIT_GIFT_ORDER_DRAFT__CLEAR = 'SUBMIT_GIFT_ORDER_DRAFT__CLEAR',

  // GET GIFT CARD DETAILS
  GET_GIFT_CARD_DETAILS__REQUEST = 'GET_GIFT_CARD_DETAILS__REQUEST',
  GET_GIFT_CARD_DETAILS__SUCCESS = 'GET_GIFT_CARD_DETAILS__SUCCESS',
  GET_GIFT_CARD_DETAILS_ERROR = 'GET_GIFT_CARD_DETAILS_ERROR',
  GET_GIFT_CARD_DETAILS__CLEAR = 'GET_GIFT_CARD_DETAILS__CLEAR',

  // SET GIFT CLAIMED
  SET_GIFT_CLAIMED__REQUEST = 'SET_GIFT_CLAIMED__REQUEST',
  SET_GIFT_CLAIMED__SUCCESS = 'SET_GIFT_CLAIMED__SUCCESS',
  SET_GIFT_CLAIMED__ERROR = 'SET_GIFT_CLAIMED__ERROR',
  SET_GIFT_CLAIMED__CLEAR = 'SET_GIFT_CLAIMED__CLEAR',

  // POST AUTH REDEEM GIFT
  POST_AUTH_REDEEM_GIFT = 'POST_AUTH_REDEEM_GIFT',

  // POST AUTH SUBMIT GIFT ORDER DRAFT
  POST_AUTH_SUBMIT_GIFT_ORDER_DRAFT = 'POST_AUTH_SUBMIT_GIFT_ORDER_DRAFT',
}
