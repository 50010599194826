import {
  useRef,
  useEffect,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react'
import { motion, useMotionValue, useAnimationControls } from 'framer-motion'
import { css, cx } from '@fable/theme'
import { SwipeCardsContext } from './SwipeCardsContext'

const SwipeCard = ({
  onVote,
  isTop,
  drag,
  children,
}: {
  onVote: (bool: boolean) => void
  isTop: boolean
  drag: boolean
  children: ReactNode
}) => {
  const { swipeDirection, setSwipeDirection } = useContext(SwipeCardsContext)
  const cardElem = useRef<HTMLDivElement>(null)

  const x = useMotionValue(0)
  const controls = useAnimationControls()

  const [animating, setAnimating] = useState(false)
  const [dragging, setDragging] = useState(false)

  const animateOut = useCallback(
    (vote: boolean) => {
      const parentElement = cardElem.current?.parentElement
      if (!parentElement) return
      const parentDimensions = parentElement.getBoundingClientRect()
      const speeds = {
        swiped: 0.7,
        clicked: 0.3,
      }

      const options: { [key: string]: any } = {
        x: vote ? parentDimensions.width * 1.5 : -parentDimensions.width * 1.5,
        transition: { type: 'spring', duration: speeds.clicked },
        opacity: 0,
      }

      if (swipeDirection) {
        options.transition.duration = speeds.swiped
        options.rotate = swipeDirection === 'left' ? -40 : 40
      }

      // Even though this runs when dragged, its promise does not resolve
      controls.start(options)
    },
    [controls, swipeDirection]
  )

  useEffect(() => {
    // Clicking
    if (!isTop) return
    if (dragging) return
    if (!swipeDirection) return

    const parentElement = cardElem.current?.parentElement
    const vote = swipeDirection === 'right'

    setDragging(true)

    if (!!parentElement) {
      animateOut(vote)
      setTimeout(() => {
        onVote(vote)
      }, 100)
      setDragging(false)
      setSwipeDirection(undefined)
    }
  }, [
    animateOut,
    controls,
    dragging,
    isTop,
    onVote,
    setSwipeDirection,
    swipeDirection,
  ])

  useEffect(() => {
    // Dragging
    const unsubscribeX = x.onChange(() => {
      if (!!swipeDirection) return
      if (!dragging) return
      if (animating) return
      if (!cardElem.current) return

      const velocity = x.getVelocity()
      const cardDimensions = cardElem.current.getBoundingClientRect()
      const rightDistance = cardDimensions.width - cardDimensions.right
      const leftDistance = cardDimensions.width + cardDimensions.x

      const castVote = (vote: boolean) => {
        animateOut(vote)
        onVote(vote)
        setTimeout(() => {
          setAnimating(false)
        }, 100)
      }

      setAnimating(true)

      if (velocity > 3000 && cardDimensions.x > rightDistance) {
        castVote(true)
      } else if (velocity < -3000 && cardDimensions.x < leftDistance) {
        castVote(false)
      } else {
        setAnimating(false)
      }
    })

    return () => unsubscribeX()
  }, [animateOut, animating, controls, dragging, onVote, swipeDirection, x])

  return (
    <motion.div
      ref={cardElem}
      className={cx(
        css`
          position: absolute;
          width: 100%;
          height: 100%;
          transition: filter 0.3s;
        `,
        !isTop
          ? css`
              filter: brightness(95%);
            `
          : ''
      )}
      drag={drag}
      {...(drag
        ? {
            dragSnapToOrigin: true,
            dragElastic: 1,
            onDrag: () => setDragging(true),
            onDragEnd: () => setDragging(false),
          }
        : undefined)}
      animate={controls}
      style={{ x }}
    >
      {children}
    </motion.div>
  )
}

export default SwipeCard
