export enum FX {
  default = 'ease .2s',
}

export enum DropShadow {
  card = '0 4px 12px rgba(0, 0, 0, 0.08)',
}

export enum Color {
  fableGreen = '#064c37',
  blackSwan = '#161015',
  blackSwanFaded = 'rgba(22, 16, 21, 0.5)',
  pinkMoon = '#e4adbb',
  whiteFang = '#ffffff',

  // SECONDARY
  saffron = '#f4c635',
  mahogany = '#cb3f3f',
  tussock = '#c19c4e',
  shamrock = '#0a8e4f',
  lavenderBlush = '#ffeaef',
  denim = '#116ecd',
  mayaBlue = '#73d5ff',

  // NEUTRAL
  hurricane = '#847f7c',
  whisper = '#e9e1de',
  whiteSmoke = '#ededed',
  almostWhite = '#f9f9f9',

  // STATES
  blackSwanHover = '#332e32',

  // OTHER
  fableGreenDark = '#004a33',
  fableGreenDarker = '#053829',
  shamrockLight = '#9ae2bf',
  whiteUltra = '#ecf9f3',
  fadedBlue = '#0c4357',
  grey = '#545454',
  greyMedium = '#d1cfd0',
  greyFade = '#716d70',
  graphite = '#3F383D',
  greyStrong = '#2e272d',
  borderGrey = '#ececec',
  searchInputHover = '#dadada',
  orange = '#ef8132',
}

export enum Breakpoint {
  mobileXS = 321,
  mobileS = 376,
  mobile = 481,
  mobileL = 548,
  tablet = 769,
  desktop = 1152,
  desktopL = 1441,
  desktopXL = 1681,
  landscape = 767,
}

export interface Icon {
  customClass?: string
  color: string
}
