import { FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import handImage from '../../../../../assets/images/graphics/hand-holding-5-dollar-card.svg'
import { InviteReferralCardActions } from './InviteReferralCardActions'

export const InviteReferralCard = () => {
  const { colors, mediaQueries } = useTheme()

  return (
    <div
      className={css`
        padding: 28px;
        padding-top: 69px;
        background-color: ${colors.whiteFang};
        border-radius: 12px;
        position: relative;
        ${mediaQueries.mobileL} {
          padding-top: 56px;
        }
      `}
    >
      <img
        className={css`
          margin: 0 auto 8px;
          display: block;
          position: absolute;
          width: 84px;
          top: -70px;
          left: 50%;
          transform: translateX(-50%);
          ${mediaQueries.mobileL} {
            top: -80px;
          }
        `}
        src={handImage}
        alt="hand holding five dollar card"
      />
      <FlexBox flexDirection="column" alignItems="center">
        <Text
          type="subhead"
          sizing={{ base: 'L', mobileL: 'XL' }}
          align="center"
          text="Give $5, get $5"
        />
        <Text
          className={css`
            margin: 8px auto 28px;
            max-width: 308px;
          `}
          type="body"
          sizing="M"
          align="center"
          text="Have a friend who loves to read? Invite them to join you on Fable and you’ll each get a $5 credit to use on an ebook. They just need to sign up with this link and then download the app. Now that’s a win-win—or a read-read, if you will."
        />
        <InviteReferralCardActions />
      </FlexBox>
    </div>
  )
}
