import { useUser } from 'hooks/useUser'
import { useCallback, useEffect } from 'react'
import { useFeatureFlags } from './useFeatureFlags'

export const useLoadFeatureFlags = () => {
  const { user, loading } = useUser()
  const getFeatureFlags = useFeatureFlags((s) => s.getFeatureFlags)

  const handleLoadFeatureFlags = useCallback(() => {
    if (!loading) getFeatureFlags(user)
  }, [user, loading, getFeatureFlags])

  useEffect(() => {
    handleLoadFeatureFlags()
  }, [handleLoadFeatureFlags])
}
