import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const CONTENTFUL_PAGE_RICH_TEXT = gql`
  query ($id: String!) {
    page(id: $id, preview: ${isPreview}) {
      body {
        json
      }
    }
  }
`
