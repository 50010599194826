import { IconSVGProps } from '../types'

export const CaretDownIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_68_5101)">
      <path d="M10 13.3333L5 8.33333H15L10 13.3333Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_68_5101">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
