export enum BillingTypes {
  // CHECKOUT
  CHECKOUT__COMPLETE = 'CHECKOUT__COMPLETE',
  CHECKOUT__START = 'CHECKOUT__START',

  SET_CARD_HOLDER_NAME = 'SET_CARD_HOLDER_NAME',
  SET_CARD_HOLDER_NAME__CLEAR = 'SET_CARD_HOLDER_NAME__CLEAR',

  // DELETE CARD
  DELETE_CARD__CLEAR = 'DELETE_CARD__CLEAR',
  DELETE_CARD__ERROR = 'DELETE_CARD__ERROR',
  DELETE_CARD__REQUEST = 'DELETE_CARD__REQUEST',
  DELETE_CARD__SUCCESS = 'DELETE_CARD__SUCCESS',

  // ADD CARD
  ADD_CARD__CLEAR = 'ADD_CARD__CLEAR',
  ADD_CARD__ERROR = 'ADD_CARD__ERROR',
  ADD_CARD__REQUEST = 'ADD_CARD__REQUEST',
  ADD_CARD__SUCCESS = 'ADD_CARD__SUCCESS',

  // DEFAULT CARD
  DEFAULT_CARD__CLEAR = 'DEFAULT_CARD__CLEAR',
  DEFAULT_CARD__ERROR = 'DEFAULT_CARD__ERROR',
  DEFAULT_CARD__REQUEST = 'DEFAULT_CARD__REQUEST',
  DEFAULT_CARD__SUCCESS = 'DEFAULT_CARD__SUCCESS',

  // GET CARDS
  GET_CARDS__ERROR = 'GET_CARDS__ERROR',
  GET_CARDS__REQUEST = 'GET_CARDS__REQUEST',
  GET_CARDS__SUCCESS = 'GET_CARDS__SUCCESS',

  // ORDERS
  GET_ORDERS__ERROR = 'GET_ORDERS__ERROR',
  GET_ORDERS__REQUEST = 'GET_ORDERS__REQUEST',
  GET_ORDERS__SUCCESS = 'GET_ORDERS__SUCCESS',

  // CREATE ORDER
  CREATE_ORDER__CLEAR = 'CREATE_ORDER__CLEAR',
  CREATE_ORDER__ERROR = 'CREATE_ORDER__ERROR',
  CREATE_ORDER__REQUEST = 'CREATE_ORDER__REQUEST',
  CREATE_ORDER__SUCCESS = 'CREATE_ORDER__SUCCESS',

  // PAY FOR ORDER
  PAY_FOR_ORDER__ERROR = 'PAY_FOR_ORDER__ERROR',
  PAY_FOR_ORDER__REQUEST = 'PAY_FOR_ORDER__REQUEST',
  PAY_FOR_ORDER__SUCCESS = 'PAY_FOR_ORDER__SUCCESS',
  PAY_FOR_ORDER__CLEAR = 'PAY_FOR_ORDER__CLEAR',

  // UPDATE ORDER
  UPDATE_ORDER__CLEAR = 'UPDATE_ORDER__CLEAR',
  UPDATE_ORDER__ERROR = 'UPDATE_ORDER__ERROR',
  UPDATE_ORDER__REQUEST = 'UPDATE_ORDER__REQUEST',
  UPDATE_ORDER__SUCCESS = 'UPDATE_ORDER__SUCCESS',

  // GET SUBSCRIPTIONS
  GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR',
  GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_CLEAR = 'GET_SUBSCRIPTIONS_CLEAR',
  GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS',

  // GET PLAN
  GET_PLAN__ERROR = 'GET_PLAN__ERROR',
  GET_PLAN__REQUEST = 'GET_PLAN__REQUEST',
  GET_PLAN__SUCCESS = 'GET_PLAN__SUCCESS',
  GET_PLAN__CLEAR = 'GET_PLAN__CLEAR',

  // DELETE PLAN
  DELETE_PLAN_ERROR = 'DELETE_PLAN_ERROR',
  DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST',
  DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS',
  DELETE_PLAN_CLEAR = 'DELETE_PLAN_CLEAR',

  // GET PRICE
  GET_PRICE__ERROR = 'GET_PRICE__ERROR',
  GET_PRICE__REQUEST = 'GET_PRICE__REQUEST',
  GET_PRICE__SUCCESS = 'GET_PRICE__SUCCESS',
  GET_PRICE__CLEAR = 'GET_PRICE__CLEAR',

  // GET BUNDLE
  GET_BUNDLE__ERROR = 'GET_BUNDLE__ERROR',
  GET_BUNDLE__REQUEST = 'GET_BUNDLE__REQUEST',
  GET_BUNDLE__SUCCESS = 'GET_BUNDLE__SUCCESS',
  GET_BUNDLE__CLEAR = 'GET_BUNDLE__CLEAR',

  // GET OFFERS
  GET_OFFERS__ERROR = 'GET_OFFERS__ERROR',
  GET_OFFERS__REQUEST = 'GET_OFFERS__REQUEST',
  GET_OFFERS__SUCCESS = 'GET_OFFERS__SUCCESS',
  GET_OFFERS__CLEAR = 'GET_OFFERS__CLEAR',

  // SET ADDRESS
  SET_ADDRESS = 'SET_ADDRESS',
  SET_ADDRESS__CLEAR = 'SET_ADDRESS__CLEAR',

  // SET STRIPE SOURCE ID
  SET_STRIPE_SOURCE_ID = 'SET_STRIPE_SOURCE_ID',
  SET_STRIPE_SOURCE_CLIENT_SECRET = 'SET_STRIPE_SOURCE_CLIENT_SECRET',
  SET_STRIPE_SOURCE_ID__CLEAR = 'SET_STRIPE_SOURCE_ID__CLEAR',

  // SET ERROR BANNER
  SET_ERROR_BANNER_MESSAGE = 'SET_ERROR_BANNER_MESSAGE',
  SET_ERROR_BANNER_MESSAGE__CLEAR = 'SET_ERROR_BANNER_MESSAGE__CLEAR',

  // CANCEL PLAN
  SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION',
  SUBSCRIPTION_CANCELLATION__REQUEST = 'SUBSCRIPTION_CANCELLATION__REQUEST',
  SUBSCRIPTION_CANCELLATION__SUCCESS = 'SUBSCRIPTION_CANCELLATION__SUCCESS',
  SUBSCRIPTION_CANCELLATION__ERROR = 'SUBSCRIPTION_CANCELLATION__ERROR',

  // SET COUPON CODE
  SET_COUPON_CODE = 'SET_COUPON_CODE',

  // SET CREDIT BALANCE ENABLED
  SET_CREDIT_BALANCE_ENABLED = 'SET_CREDIT_BALANCE_ENABLED',

  // GET CREDIT BALANCE
  GET_CREDIT_BALANCE__REQUEST = 'GET_CREDIT_BALANCE__REQUEST',
  GET_CREDIT_BALANCE__SUCCESS = 'GET_CREDIT_BALANCE__SUCCESS',
  GET_CREDIT_BALANCE__ERROR = 'GET_CREDIT_BALANCE__ERROR',
}
