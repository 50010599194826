import { css, useTheme } from '@fable/theme'
import ErrorCard from '../components/error_card/ErrorCard'
import { FlexBox } from '@fable/components'

const GeoBlockErrorPage = () => {
  const { colors } = useTheme()

  return (
    <FlexBox alignItems="center">
      <ErrorCard
        className={css`
          margin: 0 auto;
          z-index: 1;
        `}
        message="Request denied due to OFAC sanctions"
      />
      <div
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 280px;
          background: ${colors.lavenderBlush};
          z-index: 0;
        `}
      />
    </FlexBox>
  )
}

export default GeoBlockErrorPage
