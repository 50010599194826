import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const FRAGMENT_GENRE = gql`
  fragment Genre on Genre {
    name
    description
    logo {
      url
    }
    color
    authorsCollection(limit: 5) {
      items {
        name
        avatar {
          url
        }
        slug
      }
    }
    content {
      json
    }
    quote {
      sentence
      author
    }
    slug
    seoTitleTag
    seoDescription
  }
`

export const GET_GENRES = gql`
  query Genres {
    genreList(id: "6tIThvkWIiNY0B81xiAM1R", preview: ${isPreview}) {
      genresCollection {
        items {
          ...Genre
        }
      }
    }
  }
  ${FRAGMENT_GENRE}
`

export const GET_GENRES_LINKS = gql`
  query Genres {
    genreCollection(preview: ${isPreview}) {
      items {
        name,
        color,
        logo {
          url
        }
        slug
      }
    }
  }
`

export const GET_GENRE_ID = gql`
  query ($slug: String!) {
    genreCollection(where: { slug: $slug }, preview: ${isPreview}) {
      items {
        sys {
          id
        }
      }
    }
  }
`

export const GET_GENRE = gql`
  query ($id: String!) {
    genre(id: $id, preview: ${isPreview}) {
        ...Genre
    }
  }
  ${FRAGMENT_GENRE}
`

export const GET_GENRE_LOGO = gql`
  query ($id: String!) {
    genre(id: $id, preview: ${isPreview}) {
      name
      color
      logo {
        url
      }
    }
  }
`
