/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import { UserActions } from '../actions'
import { UserTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get, patch, post, remove } from '@fable/api'
import revenueCat from '../../services/revenueCat'
import { keysToSnakeCase, camelToSnakeCase } from '../../utils'
import { Club, EditProfileData, User } from '@fable/types'

type DispatchType = Dispatch<UserActions>

const smsUrl = '/sms_messages/'

export const createProfileObject = (data: User & { edited?: boolean }) => ({
  profileImage: { render: data.pic_resize || data.pic },
  displayName: data.display_name,
  bio: data.bio,
  privateProfile: data.private_profile,
  pronouns: data.pronouns,
  edited: data.edited === true,
})

export const getUserProfile = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.GET_USER_PROFILE__REQUEST))
}

// GET USER PROFILE CLEAR
export const getUserProfileClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.GET_USER_PROFILE__CLEAR))
}

// GET USER SUBSCRIPTION
export const getUserSubscription = () => async (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.GET_USER_SUBSCRIPTION__REQUEST))
  const profileUrl = '/settings/profile/'
  const userResponse = await get(profileUrl)
  try {
    const subscribeUrl = `/v1/subscribers/${userResponse.data.id}`
    const { data } = await revenueCat.get(subscribeUrl)
    dispatch(setAction(UserTypes.GET_USER_SUBSCRIPTION__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(UserTypes.GET_USER_SUBSCRIPTION__ERROR, error))
  }
}

// NEWSLETTER SUBSCRIBE
export const newsletterSubscribe =
  (email: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(UserTypes.NEWSLETTER_SUBSCRIBE__REQUEST))
    try {
      const url = '/newsletter_subscription/'
      const params = { email }
      await post(url, params)
      dispatch(setAction(UserTypes.NEWSLETTER_SUBSCRIBE__SUCCESS))
    } catch (error) {
      dispatch(setAction(UserTypes.NEWSLETTER_SUBSCRIBE__ERROR, error))
    }
  }

// NEWSLETTER UNSUBSCRIBE
export const newsletterUnsubscribe =
  (messageId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(UserTypes.NEWSLETTER_UNSUBSCRIBE__REQUEST))
    try {
      const url = `/newsletter_subscription/?message_id=${messageId}`
      await remove(url)
      dispatch(setAction(UserTypes.NEWSLETTER_UNSUBSCRIBE__SUCCESS))
    } catch (error) {
      dispatch(setAction(UserTypes.NEWSLETTER_UNSUBSCRIBE__ERROR, error))
    }
  }

// SEND APP LINK SMS
export const sendAppLinkSms =
  (phone: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(UserTypes.SEND_APP_LINK_SMS__REQUEST))
    try {
      const params = {
        phone,
        notification_type: 'download_app',
      }
      const response = await post(smsUrl, keysToSnakeCase(params))
      dispatch(setAction(UserTypes.SEND_APP_LINK_SMS__SUCCESS, response))
    } catch (error) {
      dispatch(setAction(UserTypes.SEND_APP_LINK_SMS__ERROR, error))
    }
  }

// SEND APP LINK SMS - CLEAR
export const clearAppLinkSMS = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.SEND_APP_LINK_SMS__CLEAR))
}

// SEND BOOK LINK SMS
export const sendBookLinkSms =
  (book_id: string, phone: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(UserTypes.SEND_BOOK_LINK_SMS__REQUEST))
    try {
      const params = {
        book_id,
        phone,
        notification_type: 'read_book',
      }
      const response = await post(smsUrl, keysToSnakeCase(params))
      dispatch(setAction(UserTypes.SEND_BOOK_LINK_SMS__SUCCESS, response))
    } catch (error) {
      dispatch(setAction(UserTypes.SEND_BOOK_LINK_SMS__ERROR, error))
    }
  }

// SEND BOOK LINK SMS - CLEAR
export const clearBookLinkSMS = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.SEND_BOOK_LINK_SMS__CLEAR))
}

// SEND CLUB INVITE SMS
export const sendClubInviteSms =
  (phone: string, club?: Club) =>
  async (dispatch: DispatchType, getState: any) => {
    dispatch(setAction(UserTypes.SEND_CLUB_INVITE_SMS__REQUEST))
    const state = getState()
    const clubDetails = club || state.clubs?.clubDetails.data

    const params: {
      phone: string
      notificationType: string
      clubId?: string
    } = {
      phone,
      notificationType: 'club_invite',
    }
    if (clubDetails) {
      params.clubId = clubDetails.id
    } else {
      const error = new Error(
        'Could not find club invite or open club to invite to'
      )
      return dispatch(setAction(UserTypes.SEND_CLUB_INVITE_SMS__SUCCESS, error))
    }

    try {
      const response = await post(smsUrl, keysToSnakeCase(params))
      dispatch(setAction(UserTypes.SEND_CLUB_INVITE_SMS__SUCCESS, response))
    } catch (error) {
      dispatch(setAction(UserTypes.SEND_CLUB_INVITE_SMS__ERROR, error))
    }
  }

// SEND CLUB INVITE SMS - CLEAR
export const clearClubInviteSMS = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.SEND_CLUB_INVITE_SMS__CLEAR))
}

// EDIT USER PROFILE
export const editProfile =
  (obj: EditProfileData) => (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const editedProfile = state.user.editedProfile || {}

    dispatch(setAction(UserTypes.EDIT_PROFILE, { ...editedProfile, ...obj }))
  }

export const editProfileReset =
  () => (dispatch: DispatchType, getState: () => any) => {
    const {
      user: {
        profile: { data },
      },
    } = getState()

    editProfile(createProfileObject({ ...data, edited: false }))(
      dispatch,
      getState
    )
  }

// UPDATE USER PROFILE
export const updateUserProfile =
  () => async (dispatch: DispatchType, getState: () => any) => {
    const state = getState()
    const { profileImage, displayName, bio, privateProfile, pronouns } =
      state.user.editedProfile
    const paramsFiltered: { [key: string]: any } = {
      profileImage: profileImage?.fileData,
      displayName: displayName,
      bio: bio,
      privateProfile: privateProfile,
      pronouns: pronouns,
    }

    dispatch(setAction(UserTypes.UPDATE_USER_PROFILE__REQUEST))

    try {
      const url = '/v2/users/self/'
      const formData = new FormData()
      // Create form data
      for (const param in paramsFiltered) {
        const data = paramsFiltered[param]

        if (data !== undefined && data !== null) {
          formData.append(camelToSnakeCase(param), data)
        }
      }

      const { data } = await patch(url, formData)
      // Handled by getUserDataSaga
      dispatch(setAction(UserTypes.GET_USER_PROFILE__REQUEST))

      dispatch(setAction(UserTypes.UPDATE_USER_PROFILE__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(UserTypes.UPDATE_USER_PROFILE__ERROR, error))
    }
  }

// UPDATE USER PROFILE - CLEAR
export const clearUpdatedProfile = () => (dispatch: DispatchType) => {
  dispatch(setAction(UserTypes.UPDATE_USER_PROFILE__CLEAR))
}
