import { FlexBox, Icon, Tag, Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'

interface Props {
  className?: string
  colors?: {
    background?: string
    text?: string
  }
}

export const PremiumClubTag = ({ className, colors }: Props) => {
  const { colors: themeColors } = useTheme()
  const backgroundColor = colors?.background || themeColors.pewterGrey
  const textColor = colors?.text || themeColors.graphite

  return (
    <Tag
      className={cx(
        css`
          border-radius: 8px;
        `,
        className
      )}
      backgroundColor={backgroundColor}
    >
      <FlexBox alignItems="center">
        <Icon
          type="lock"
          className={css`
            margin-right: 4px;
            width: 14px;
          `}
          fill={textColor}
        />
        <Text type="label" sizing="L" text="Premium" color={textColor} />
      </FlexBox>
    </Tag>
  )
}
