import { css, cx, useTheme } from '@fable/theme'
import { COPYRIGHT_TEXT, LEGAL_LINKS } from '../../../constants'
import { Button, FlexBox, Text } from '@fable/components'
import { Link } from 'react-router-dom'
import { WindowWithAJS } from 'services/onetrust/types'
import { useGeo } from 'hooks'

export const FooterMid: React.VFC = () => {
  const { colors, motion, mediaQueries, nativeOverrides } = useTheme()
  const { isCA } = useGeo()

  const linkStyles = css`
    ${motion.transition('color')};
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      color: ${colors.whiteFang};
    }
  `

  return (
    <FlexBox
      className={css`
        ${mediaQueries.tablet} {
          justify-content: space-between;
        }
      `}
    >
      <Text
        className={css`
          flex-shrink: 0;
          margin-right: 10%;
          width: 40%;
          ${mediaQueries.tablet} {
            margin-right: 0;
            width: auto;
          }
        `}
        type="body"
        sizing="M"
        text={COPYRIGHT_TEXT}
        color="rgba(255, 255, 255, 0.5)"
      />
      <FlexBox
        className={css`
          flex-direction: column;
          gap: 12px;
          ${mediaQueries.tablet} {
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 12px 0;
          }
        `}
      >
        {LEGAL_LINKS.map((link, i) => (
          <Link
            key={i}
            className={css`
              ${mediaQueries.mobile} {
                width: 170px;
              }
              ${mediaQueries.tablet} {
                width: auto;
                display: flex;
                align-items: center;
              }
            `}
            to={link.link}
          >
            {i !== 0 ? (
              <span
                className={css`
                  display: none;
                  ${mediaQueries.tablet} {
                    display: block;
                    margin: 0 24px;
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 8px;
                  }
                `}
              >
                •
              </span>
            ) : null}
            <Text
              key={i}
              className={linkStyles}
              type="body"
              sizing="M"
              text={link.title}
            />
          </Link>
        ))}
        {isCA ? (
          <FlexBox alignItems="center">
            <span
              className={css`
                display: none;
                ${mediaQueries.tablet} {
                  display: block;
                  margin: 0 24px;
                  color: rgba(255, 255, 255, 0.5);
                  font-size: 8px;
                }
              `}
            >
              •
            </span>
            <Button
              className={cx(
                css`
                  ${nativeOverrides.buttonClear};
                  font-weight: 400;
                `,
                linkStyles
              )}
              onClick={() => {
                const wd = window as WindowWithAJS
                wd.OneTrust?.ToggleInfoDisplay()
              }}
            >
              Do not sell my personal information
            </Button>
          </FlexBox>
        ) : null}
      </FlexBox>
    </FlexBox>
  )
}
