import { Book, Folio } from '@fable/types'
import { getAuthorNames } from '@fable/utils'

const altTag = {
  clubBook: (book: Book) =>
    `${book.title} by ${getAuthorNames(book?.authors)} book club - Fable`,
  folioBook: (folio: Folio) =>
    `${folio.curator?.display_name} book recommendations - Fable`,
  storeBook: (book: Book) =>
    `${book?.title} by ${getAuthorNames(book?.authors)} digital book - Fable`,
}

export default altTag
