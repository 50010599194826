import { requests } from '@fable/api'
import { ClubGenre } from '@fable/types'
import { formatGenreParam, sortGenres } from '@fable/utils'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { CompProps } from 'types'
import { getParameterByName } from 'utils'

export interface ClubsPageContextProps {
  activeFilter: string
  setActiveFilter: Dispatch<SetStateAction<string>>
  genres: ClubGenre[]
  loading: boolean
}

export const ClubsPageContext = createContext({} as ClubsPageContextProps)

export const ClubsPageProvider = ({ children }: CompProps) => (
  <ClubsPageContext.Provider value={useClubsPageProvider()}>
    {children}
  </ClubsPageContext.Provider>
)

export const useClubsPageProvider = () => {
  const { data: genres, isLoading } = useQuery(
    ['genres'],
    () => requests.getGenres(),
    {
      select: (data) => {
        const genres = sortGenres(data?.data)

        if (!genres?.length) return []

        genres.unshift({
          id: 'featured',
          name: 'Featured',
        })

        return genres
      },
    }
  )

  const [activeFilter, setActiveFilter] = useState('featured')

  const getGenreIdFromParam = useCallback(() => {
    if (!isLoading) {
      const genreParam = getParameterByName('filter')
      const genre = genres?.find(
        (genre) => formatGenreParam(genre.name) === genreParam
      )
      if (!!genre) setActiveFilter(genre.id)
    }
  }, [isLoading, genres])

  useEffect(() => getGenreIdFromParam(), [getGenreIdFromParam])

  return {
    activeFilter,
    setActiveFilter,
    genres: genres || [],
    loading: isLoading,
  }
}
