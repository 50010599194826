import { cx, css, useTheme } from '@fable/theme'

/**
 *
 * @marginEdges {boolean} Applies responsive left and right margins. Typically used in top-level page components.
 * @contentPadEdges {boolean} Applies padding. Typically used in child components.
 * @card {boolean} Applies 12px border radius.
 * @background {string} Applies given string to background CSS property.
 */

interface ContainerProps {
  children?: React.ReactChild | React.ReactChild[] | null
  marginEdges?: boolean
  contentPadEdges?: boolean
  card?: boolean
  background?: string
}

/**
 * @note
 * These values are exported for components that need to set the same values,
 * for example a carousel that has to flow from edge to edge from within a PageContainer with marginEdges
 */
export const marginEdgesRules = {
  desktop: '32px',
  tablet: '20px',
  mobile: '16px',
}

const PageContainer: React.VFC<
  ContainerProps & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  marginEdges,
  contentPadEdges,
  card,
  background,
  ...nativeProps
}) => {
  const { mediaQueries, colors } = useTheme()

  return (
    <div
      {...nativeProps}
      className={cx(
        css`
          background: ${background || colors.whiteFang};
        `,
        card &&
          css`
            border-radius: 12px;
            overflow: hidden;
          `,
        marginEdges &&
          css`
            ${card && 'border-radius: 0;'}
            ${mediaQueries.tablet} {
              ${card && 'border-radius: 12px;'}
              margin-left: ${marginEdgesRules.tablet};
              margin-right: ${marginEdgesRules.tablet};
            }
            ${mediaQueries.desktop} {
              margin-left: ${marginEdgesRules.desktop};
              margin-right: ${marginEdgesRules.desktop};
            }
          `,
        contentPadEdges &&
          css`
            padding: 0 24px;
            ${mediaQueries.desktop} {
              padding: 0 32px;
            }
          `,
        nativeProps.className
      )}
    >
      {children}
    </div>
  )
}

export default PageContainer
