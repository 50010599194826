/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import { SocialActions } from '../actions'
import { SocialTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get, remove, post } from '@fable/api'

type DispatchType = Dispatch<SocialActions>

// GET USER BY SLUG CLEAR
export const userLookupClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(SocialTypes.USER_LOOKUP__CLEAR))
}

// GET USER BY SLUG
export const userLookup =
  (slugOrId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(SocialTypes.USER_LOOKUP__REQUEST))

    const uuidRegEx =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    try {
      if (uuidRegEx.test(slugOrId.split('/').join(''))) {
        const { data } = await get(`v2/users${slugOrId}`)
        dispatch(setAction(SocialTypes.USER_LOOKUP__SUCCESS, data))
      } else {
        const { data } = await get(`/usernames${slugOrId}`)
        for (const key in data) {
          if (data[key] === 'undefined') data[key] = undefined
        }

        dispatch(setAction(SocialTypes.USER_LOOKUP__SUCCESS, data))
      }
    } catch (error) {
      dispatch(setAction(SocialTypes.USER_LOOKUP__ERROR, error))
    }
  }

// GET BOOK LISTS CLEAR
export const getBookListsClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(SocialTypes.GET_BOOK_LISTS__CLEAR))
}

// GET BOOK LISTS
export const getBookLists =
  (uuid: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(SocialTypes.GET_BOOK_LISTS__REQUEST))

    try {
      const { data } = await get(`/v2/users/${uuid}/book_lists`)

      dispatch(setAction(SocialTypes.GET_BOOK_LISTS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(SocialTypes.GET_BOOK_LISTS__ERROR, error))
    }
  }

// GET CLUBS CLEAR
export const getClubsClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(SocialTypes.GET_CLUBS__CLEAR))
}

// GET CLUBS
export const getClubs = (uuid: string) => async (dispatch: DispatchType) => {
  dispatch(setAction(SocialTypes.GET_CLUBS__REQUEST))

  try {
    const { data } = await get(`users/${uuid}/clubs/`)

    dispatch(setAction(SocialTypes.GET_CLUBS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(SocialTypes.GET_CLUBS__ERROR, error))
  }
}

const refetchUserBySlug =
  (uuid: string) =>
  async (dispatch: DispatchType, getState: () => Record<string, any>) => {
    const state = getState()

    if (uuid === state.social.user?.data?.id) {
      // Update profile data of currently viewed profile page so the main Follow button and count updates.
      await userLookup(window.location.pathname)(dispatch)
    }
  }

// POST FOLLOW USER
export const postFollowUser =
  (uuid: string) =>
  async (dispatch: DispatchType, getState: () => Record<string, any>) => {
    dispatch(setAction(SocialTypes.POST_FOLLOW_USER__REQUEST))

    try {
      const { data } = await post(`users/${uuid}/followers/`)

      await refetchUserBySlug(uuid)(dispatch, getState)

      dispatch(setAction(SocialTypes.POST_FOLLOW__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(SocialTypes.POST_FOLLOW_USER__ERROR, error))
    }
  }

// DELETE FOLLOW USER
export const deleteFollowUser =
  (uuid: string) =>
  async (dispatch: DispatchType, getState: () => Record<string, any>) => {
    dispatch(setAction(SocialTypes.DELETE_FOLLOW_USER__REQUEST))

    try {
      const { data } = await remove(`users/${uuid}/followers/`)

      await refetchUserBySlug(uuid)(dispatch, getState)

      dispatch(setAction(SocialTypes.DELETE_FOLLOW_USER__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(SocialTypes.DELETE_FOLLOW_USER__ERROR, error))
    }
  }

// GET FOLLOWERS
export const getFollowers =
  (uuid: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(SocialTypes.GET_FOLLOWERS__REQUEST))

    try {
      const { data } = await get(`users/${uuid}/followers?limit=10&offset=0`)

      dispatch(setAction(SocialTypes.GET_FOLLOWERS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(SocialTypes.GET_FOLLOWERS__ERROR, error))
    }
  }

// GET FOLLOWING
export const getFollowing =
  (uuid: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(SocialTypes.GET_FOLLOWING__REQUEST))

    try {
      const { data } = await get(`users/${uuid}/following?limit=10&offset=0`)

      dispatch(setAction(SocialTypes.GET_FOLLOWING__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(SocialTypes.GET_FOLLOWING__ERROR, error))
    }
  }
