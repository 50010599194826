import { cx, css, useTheme } from '@fable/theme'

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode | React.ReactNode[]
  color?: string
  newTab?: boolean
}

const Hyperlink = ({
  children,
  color,
  className = '',
  newTab = false,
  ...nativeProps
}: Props) => {
  const { colors, motion } = useTheme()
  const linkColor = color || colors.uiBlueLight
  let sharedProps = { ...nativeProps }

  if (newTab) {
    sharedProps = {
      ...nativeProps,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <a
      className={cx(
        css`
          color: ${linkColor};
          font-weight: bold;
          ${motion.transition('opacity')};
          &:hover {
            opacity: 0.7;
          }
        `,
        className
      )}
      {...sharedProps}
    >
      {children}
    </a>
  )
}

export default Hyperlink
