import { IconSVGProps } from '../types'

export const WebsiteIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path d="M12.8,9.08l1.06,1.06A5.31,5.31,0,0,1,15,11.85a5.25,5.25,0,0,1,0,4,5.38,5.38,0,0,1-1.13,1.7l-.27.26a5.25,5.25,0,1,1-7.42-7.42l1.06,1.06a3.75,3.75,0,0,0-.82,1.22,3.76,3.76,0,0,0,0,2.87,3.82,3.82,0,0,0,2,2,3.76,3.76,0,0,0,2.87,0,3.75,3.75,0,0,0,1.22-.82l.27-.26a3.77,3.77,0,0,0,0-5.3l-1.07-1.07L12.8,9.08Zm5,4.51-1.06-1.06a3.75,3.75,0,0,0,.82-1.22,3.76,3.76,0,0,0,0-2.87,3.82,3.82,0,0,0-2-2,3.76,3.76,0,0,0-2.87,0,3.75,3.75,0,0,0-1.22.82l-.27.26a3.78,3.78,0,0,0,0,5.31l1.07,1.06L11.2,14.92l-1.06-1.06A5.31,5.31,0,0,1,9,12.15a5.25,5.25,0,0,1,0-4,5.38,5.38,0,0,1,1.13-1.7l.27-.26a5.25,5.25,0,1,1,7.42,7.42Z" />
  </svg>
)
