export const routes = {
  b2b: '/for-business',
  communityGuidelines: '/community-guidelines',
  company: '/company',
  copyrightPolicy: '/copyright-policy',
  download: '/download',
  employeeAndHrPrivacy: '/employee-and-hr-privacy-notice',
  home: '/',
  premiumClubs: '/premium-clubs',
  press: '/press',
  privacy: '/privacy',
  privacyIos: '/iosprivacy',
  termsIos: '/iosterms',
  support: '/support',
  terms: '/terms',
  termsMastercard: '/terms/mastercard',
  pride: '/pride',
  security: '/security',
  education: '/education',
  clubFeatures: '/club-features',
  clubsPage: '/club',
  homeFeedLp: '/your-book-community',
  readerLp: '/interactive-reading',
  buddyLp: '/buddy-read',
  goodreadsLp: '/your-new-book-app',
  meetcute: '/meetcute',
  shelfie: '/shelfie',
}
