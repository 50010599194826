import React, { useEffect } from 'react'
import { get } from '@fable/api'

function sendToParent(result: any, frame: string) {
  globalThis.parent.self.postMessage(result, frame)
}

const ExtAuthPage: React.FC = () => {
  useEffect(() => {
    ;(async () => {
      const parentFrame = document.location.ancestorOrigins[0]
      const result = await get('/auth/token')
      if (result.data.auth_token) {
        sendToParent(
          {
            action: 'setAuthToken',
            token: result.data.auth_token,
          },
          parentFrame
        )
      }
    })()
  }, [])

  return null
}

export default ExtAuthPage
