import { useEffect, useState } from 'react'

export type ScrollDirection = 'up' | 'down'

export interface ScrollProps {
  direction: ScrollDirection
  scrolled: boolean
  position: number
}

export const useScroll = () => {
  const [position, setPosition] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const [direction, setDirection] = useState<ScrollDirection>('up')
  const [windowBottomReached, setWindowBottomReached] = useState(false)
  const threshold = 100

  useEffect(() => {
    const updatePosition = () => {
      const pageYOffset = window.pageYOffset
      setPosition(pageYOffset)
      setScrolled(pageYOffset > 0)
      setWindowBottomReached(
        window.innerHeight + Math.ceil(pageYOffset + 2) >=
          document.body.offsetHeight
      )
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  useEffect(() => {
    let prevPos = window.pageYOffset

    const scrolledOverThreshold = (pos: number) =>
      Math.abs(pos - prevPos) > threshold

    const isScrollingUp = (currentPos: number) =>
      currentPos > prevPos &&
      !(prevPos > 0 && currentPos === 0) &&
      !(currentPos > 0 && prevPos === 0)

    const updateDirection = () => {
      const currentPos = window.pageYOffset

      if (scrolledOverThreshold(currentPos)) {
        const scrollDir = isScrollingUp(currentPos) ? 'down' : 'up'
        setDirection(scrollDir)
        prevPos = currentPos > 0 ? currentPos : 0
      }
    }
    const handleScroll = () => window.requestAnimationFrame(updateDirection)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return { position, scrolled, direction, windowBottomReached }
}
