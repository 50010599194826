import { get, patch, post, put, remove } from '@fable/api'
import { lsKeys } from '../../../constants'
import { assign, createMachine } from 'xstate'
import {
  CartMachineAddItem,
  CartMachineContext,
  CartMachineCreate,
  CartMachineEvents,
  CartMachineLoad,
  CartMachineRemoveItem,
} from './types'
import { pickInvokeData } from '../helpers'
import { InvokeResponse, InvokeResponseError } from '../types'
import sum from 'lodash/sum'
import { Book, Cart, CartItem } from '@fable/types'
import { getFilteredCartItems, prepItemsToMerge } from './helpers'

type CTX = CartMachineContext

const defaultContext: CTX = {
  id: null,
  cart: null,
  total: '0.00',
  error: null,
  success: false,
  owned: null,
  authenticated: false,
  user: null,
}

const getStoredId = () => localStorage.getItem(lsKeys.cartId)
const loadActions = ['setCartId', 'setAuth']

const LOAD = [
  {
    target: '#cart.idle',
    actions: 'setAuth',
    cond: 'noCartExists',
  },
  {
    target: '#cart.loadingAuth',
    actions: loadActions,
    cond: 'authenticated',
  },
  {
    target: '#cart.loading',
    actions: loadActions,
    cond: 'unauthenticated',
  },
]

export const cartMachine = createMachine<CTX, CartMachineEvents>(
  {
    predictableActionArguments: true,
    id: 'cart',
    context: defaultContext,
    initial: 'init',
    states: {
      init: {
        initial: 'start',
        states: {
          start: { on: { LOAD } },
        },
      },
      loadingAuth: {
        initial: 'fetchingOwnedBooks',
        states: {
          fetchingOwnedBooks: {
            exit: 'setOwned',
            invoke: {
              id: 'getOwnedBooks',
              src: 'getOwnedBooks',
              onDone: [
                {
                  target: '#cart.loadingAuth.associating',
                  cond: 'noUserCart',
                },
                {
                  target: '#cart.loadingAuth.merging',
                  cond: 'canMergeCart',
                },
                {
                  target: '#cart.loading',
                  cond: 'validUserCart',
                },
              ],
              onError: {
                target: '#cart.idle',
                actions: 'setError',
              },
            },
          },
          associating: {
            invoke: {
              id: 'associateUser',
              src: 'associateUser',
              onDone: {
                target: '#cart.idle',
                actions: [
                  'setCartId',
                  'setCart',
                  'setTotal',
                  'removeIdFromStorage',
                ],
              },
              onError: {
                target: '#cart.idle',
                actions: 'setError',
              },
            },
          },
          merging: {
            invoke: {
              id: 'mergeCart',
              src: 'mergeCart',
              onDone: {
                target: '#cart.idle',
                actions: [
                  'setCartId',
                  'setCart',
                  'setTotal',
                  'removeIdFromStorage',
                ],
              },
              onError: {
                target: '#cart.idle',
                actions: 'setError',
              },
            },
          },
        },
      },
      loading: {
        invoke: {
          id: 'getCart',
          src: 'getCart',
          onDone: {
            target: '#cart.idle',
            actions: ['setCart', 'setTotal'],
          },
          onError: {
            target: '#cart.idle',
            actions: 'setError',
          },
        },
      },
      idle: {
        on: {
          LOAD,
          CREATE: 'creating',
          ADD_ITEM: 'addingItem',
          REMOVE_ITEM: 'removingItem',
          CLEAR: 'clearing',
          CLEAR_SUCCESS: {
            target: 'idle',
            actions: 'clearSuccess',
          },
        },
      },
      creating: {
        invoke: {
          id: 'createCart',
          src: 'createCart',
          onDone: {
            target: 'idle',
            actions: ['setCart', 'setTotal', 'setCartId', 'setSuccess'],
          },
          onError: {
            target: 'idle',
            actions: 'setError',
          },
        },
      },
      addingItem: {
        invoke: {
          id: 'addItem',
          src: 'addItem',
          onDone: {
            target: 'idle',
            actions: ['setCart', 'setTotal', 'setSuccess'],
          },
          onError: {
            target: 'idle',
            actions: 'setError',
          },
        },
      },
      removingItem: {
        invoke: {
          id: 'removeItem',
          src: 'removeItem',
          onDone: {
            target: 'idle',
            actions: ['setCart', 'setTotal'],
          },
          onError: {
            target: 'idle',
            actions: 'setError',
          },
        },
      },
      clearing: {
        invoke: {
          id: 'clearCart',
          src: 'clearCart',
          onDone: {
            target: 'idle',
            actions: ['setCart', 'setTotal'],
          },
          onError: {
            target: 'idle',
            actions: 'setError',
          },
        },
      },
    },
  },
  {
    actions: {
      setCartId: assign<CTX, CartMachineLoad | InvokeResponse<Cart>>({
        id: (ctx, event) => {
          switch (event.type) {
            case 'done.invoke.createCart': {
              const cart = pickInvokeData(event) as Cart
              const cartId = cart?.uuid
              if (!ctx.authenticated) {
                localStorage.setItem(lsKeys.cartId, cartId)
              }
              return cartId
            }
            case 'done.invoke.associateUser': {
              const cart = pickInvokeData(event) as Cart
              return cart?.uuid
            }
            case 'done.invoke.mergeCart': {
              const cart = pickInvokeData(event) as Cart
              return cart?.uuid
            }
            default: {
              const e = event as CartMachineLoad
              const storedId = getStoredId()
              if (!!storedId) return storedId
              if (!!e.data?.cart_uuid) return e.data?.cart_uuid
              return ctx.id
            }
          }
        },
      }),
      setCart: assign<CTX, InvokeResponse<Cart>>({
        cart: (ctx, event) => {
          const cart = pickInvokeData(event)
          if (!ctx.authenticated || !ctx.owned?.length) return cart

          const cartItems = getFilteredCartItems({
            items: cart.items,
            ownedBooks: ctx.owned,
          }) as CartItem[]

          return { ...cart, items: cartItems }
        },
      }),
      setTotal: assign<CTX, InvokeResponse<Cart>>({
        total: (ctx) => {
          const cartItems = ctx.cart?.items
          if (!cartItems?.length) return '0.00'
          const totals = cartItems?.map((item) =>
            parseFloat(item.product.price_usd)
          )

          return sum(totals).toFixed(2)
        },
      }),
      setError: assign<CTX, InvokeResponseError>({
        error: (_, event) => event.data,
      }),
      setOwned: assign<CTX, InvokeResponse<Book[]>>({
        owned: (_, event) => pickInvokeData(event),
      }),
      setSuccess: assign<CTX>({ success: true }),
      setAuth: assign<CTX, CartMachineLoad>({
        user: (_, event) => event.data,
        authenticated: (_, event) => !!event.data,
      }),
      clear: assign({ ...defaultContext }),
      clearSuccess: assign<CTX>({ success: false }),
      removeIdFromStorage: () => localStorage.removeItem(lsKeys.cartId),
    } as any,
    services: {
      getCart: async (ctx: CTX) => await get(`/cart/${ctx.id}`),
      mergeCart: async (ctx: CTX) =>
        await patch(
          `/cart/${ctx.user?.cart_uuid}`,
          prepItemsToMerge({ cart: ctx.cart })
        ),
      createCart: async (_: CTX, event: CartMachineCreate) =>
        await post('/cart/create', event.data),
      addItem: async (ctx: CTX, event: CartMachineAddItem) =>
        await put(`/cart/${ctx.id}`, event.data),
      removeItem: async (ctx: CTX, event: CartMachineRemoveItem) =>
        await remove(`/cart/${ctx.id}`, event.data),
      updateCart: async (ctx: CTX) =>
        await put(`/cart/${ctx.id}`, ctx.cart?.items),
      clearCart: async (ctx: CTX) => await remove(`/cart/${ctx.id}/clear_all`),
      associateUser: async (ctx: CTX) => await get(`/cart/${ctx.id}/associate`),
      getOwnedBooks: async () => await get('/v2/books/owned/'),
    } as any,
    guards: {
      authenticated: (_: CTX, event: CartMachineLoad) => !!event.data,
      unauthenticated: (_: CTX, event: CartMachineLoad) => !event.data,
      noUserCart: (ctx: CTX) =>
        !!ctx.user && !!ctx.cart && !ctx.user?.cart_uuid && !!getStoredId(),
      canMergeCart: (ctx: CTX) => !!ctx.user?.cart_uuid && !!getStoredId(),
      validUserCart: (ctx: CTX) => !!ctx.user?.cart_uuid && !getStoredId(),
      noCartExists: (_: CTX, event: CartMachineLoad) =>
        !event.data?.cart_uuid && !getStoredId(),
    } as any,
  }
)
