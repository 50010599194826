import { css, cx, useTheme } from '@fable/theme'
import FlexBox from './FlexBox'

const CharacterCount: React.VFC<
  { input: string; maxLength: number } & React.HTMLProps<HTMLDivElement>
> = ({ input, maxLength }) => {
  const { colors, fonts } = useTheme()

  const styles = {
    text: css`
      font-size: 12px;
      font-family: ${fonts.body};
    `,
    textSecondary: css`
      color: ${colors.greyMedium};
    `,
  }

  return (
    <FlexBox alignItems="center">
      <div
        className={cx(
          styles.text,
          css`
            color: ${colors.blackSwan};
          `
        )}
      >
        {input.length}
      </div>
      <span className={cx(styles.text, styles.textSecondary)}>&#47;</span>
      <div className={cx(styles.text, styles.textSecondary)}>{maxLength}</div>
    </FlexBox>
  )
}

export default CharacterCount
