import { useLocation } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { Book, ShareOption } from '@fable/types'
import useDeviceDetect from './useDeviceDetect'
import facebookIcon from './assets/icons/facebook.svg'
import linkIcon from './assets/icons/link.svg'
import mailIcon from './assets/icons/mail.svg'
import giftIcon from './assets/icons/gift-icon.svg'
import messengerIcon from './assets/icons/messenger.svg'
import xIcon from './assets/icons/x.svg'

interface Props {
  category: string
  url?: string
  book?: Book
  /** @note temporary until redux is removed */
  showGiftBookButton: boolean
  onCopyToClipboard: () => void
}

const useShareOptions = ({
  category,
  url,
  book,
  /** @note temporary until redux is removed from /main */
  onCopyToClipboard,
  showGiftBookButton,
}: Props): ShareOption[] => {
  const { isMobile } = useDeviceDetect()
  const path = useLocation().pathname
  const hostname = window.location.hostname
  const shareUrl = url || `${hostname}${path}`
  const categoryTitle = category?.replace('-', ' ')

  const getEmailCopy = () => {
    let subject = `Check out this ${categoryTitle} from Fable `
    let body = `You can see the ${categoryTitle} here: ${shareUrl}`

    if (!category || !category?.length) {
      subject = 'Check this out from Fable'
      body = `You can see more here: ${shareUrl}`
    }

    if (category === 'invite') {
      subject = '🎁📚 Here’s $5 off your next book'
      body = `Sign up and download the Fable app via this link, and we’ll each get a $5 credit towards our next ebook. If you join one of Fable’s book clubs (there are hundreds of free clubs, or create your own!), you can see everyone’s shared highlights, comments, and emoji reactions within the text of the book! ${shareUrl}`
    }

    const encodedSubject = encodeURIComponent(subject)
    const encodedBody = encodeURIComponent(body)

    return `mailto:?subject=${encodedSubject}&body=${encodedBody}`
  }

  let SHARE_OPTIONS: ShareOption[] = [
    {
      icon: facebookIcon,
      title: 'Share on Facebook',
      id: 'facebook',
      isExternal: true,
      action: () => `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    },
    {
      icon: messengerIcon,
      title: 'Share to Messenger',
      id: 'messenger',
      isExternal: true,
      action: () => `fb-messenger://share?link=${shareUrl}`,
    },
    {
      icon: xIcon,
      title: 'Share to 𝕏',
      id: 'twitter',
      isExternal: true,
      action: () => `https://twitter.com/share?url=${shareUrl}`,
    },
    {
      icon: mailIcon,
      title: 'Share via Email',
      id: 'email',
      isExternal: true,
      action: getEmailCopy,
    },
    {
      icon: linkIcon,
      title: 'Copy Link',
      id: 'copy-link',
      isExternal: false,
      action: () => {
        copy(shareUrl)
        onCopyToClipboard()
        return ''
      },
    },
  ]

  if (!!book && showGiftBookButton) {
    SHARE_OPTIONS = [
      {
        icon: giftIcon,
        title: 'Give as a gift',
        id: 'gift',
        isExternal: true,
        action: () => {
          // Temporarily hardcoding the web address because this might load in Microsoft Teams
          // There is some data that relies on redux and other libraries not yet migrated to this library
          return `https://fable.co/gift?book=${book.isbn}`
        },
      },
      ...SHARE_OPTIONS,
    ]
  }

  const options = !isMobile
    ? SHARE_OPTIONS.filter((option) => option.id !== 'messenger')
    : SHARE_OPTIONS

  return options
}

export default useShareOptions
