import { FlexBox, Icon } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { CartItem, CartItemRemoveInput } from '@fable/types'
import { analytics } from 'segment_analytics'
import { useCart } from 'hooks'

export const CartItemActions = ({ item }: { item: CartItem }) => {
  const { colors, mediaQueries, motion } = useTheme()
  const { service } = useCart()
  const id = item.product.id

  return (
    <FlexBox>
      <div
        data-testid={`cartItemRemove-${id}`}
        className={css`
          cursor: pointer;
        `}
        onClick={() => {
          const data: CartItemRemoveInput = {
            product_id: id,
            product_type: 'book',
          }
          service.send({
            type: 'REMOVE_ITEM',
            data,
          })
          analytics.events.cart.itemRemoved(data)
        }}
      >
        <Icon
          className={css`
            width: 20px;
            svg {
              fill: ${colors.uiBlack};
              opacity: 0.4;
              ${mediaQueries.mobileL} {
                ${motion.transition('opacity')};
                &:hover {
                  opacity: 0.6;
                }
              }
            }
          `}
          type="close-circle"
          fill={colors.uiPewterGreyLight}
        />
      </div>
    </FlexBox>
  )
}
