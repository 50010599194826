import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import isString from 'lodash/isString'
import {
  DEFAULT_ERROR_MESSAGE,
  ENVIRONMENT,
  TRACKING_BLACKLIST,
} from '../constants'
import { BillingTypes, ClubTypes, GeoIpTypes } from '../state/action-types'

const isProd = ENVIRONMENT === 'production'

export function initSentry() {
  if (isProd) {
    Sentry.init({
      beforeSend(event) {
        if (TRACKING_BLACKLIST(window.location.pathname)) return null

        return event
      },
      dsn: 'https://95e60ceafc7c4661841cfc495ac26c16@o419209.ingest.sentry.io/5525893',
      normalizeDepth: 5,
      release: process.env.REACT_APP_GIT_REV,
      denyUrls: [/^chrome:\/\//i, /gtag\/js/],
      ignoreErrors: [
        /^Network Error$/,
        /^A network error \(such as timeout, interrupted connection or unreachable host\) has occurred\.$/,
        /^The Internet connection appears to be offline\.$/,
        /^SecurityError: Blocked a frame with origin "https:\/\/fable\.co" from accessing a cross-origin frame. Protocols, domains, and ports must match\.$/,
      ],
    })
  }
}

export function identify(user) {
  if (isProd) {
    Sentry.setUser({ id: user.id })
  }
}

export function captureException(err, ctx = {}) {
  if (isProd) {
    Sentry.captureException(err, ctx)
  }
  console.error('captureException', err, ctx)
}

export function captureMessage(msg, ctx = {}) {
  if (isProd) {
    Sentry.captureMessage(msg, ctx)
  }
  console.error('captureMessage', msg, ctx)
}

export function httpErrorCode(error) {
  return get(error, 'response.status', -1)
}

export function httpErrorDetail(error) {
  return get(error, 'response.data.detail')
}

export function httpErrorUrl(error) {
  return get(error, 'config.url')
}

export function humanReadableErrorMessage(
  actionType,
  error,
  defaultMessage = DEFAULT_ERROR_MESSAGE
) {
  if (isString(error)) {
    return error
  }

  const status = httpErrorCode(error)
  const detail = httpErrorDetail(error)
  const useDetail = [
    BillingTypes.UPDATE_ORDER__ERROR,
    ClubTypes.CREATE_CLUB_MEMBERSHIP__ERROR,
    GeoIpTypes.GEO_IP_COUNTRY__ERROR,
  ].includes(actionType)

  if (status === 400 && error.isAxiosError) {
    if (detail) return detail
    return defaultMessage
  } else if (status >= 400 && status < 600) {
    if (useDetail && detail) return detail
    return defaultMessage
  }

  return get(error, 'message', `${error}`)
}
