import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Text } from '@fable/components'
import { Club } from '@fable/types'
import { useActions } from '../../../hooks'
import { Link, useLocation } from 'react-router-dom'
import { CardContainerProps } from '../types'
import { getClubEntryPoint } from '../../../app/marketing/utils'
import { ClubCardHero } from './ClubCardHero'
import { MemberCount } from 'components/member_count'
import { clubRoute } from 'app/clubs/routes'
import { getClubModeratorText } from '@fable/utils'

interface Props extends CardContainerProps {
  club: Club
  newTab?: boolean
  onCardClick?: () => void
}

export const ClubCard = ({
  club,
  newTab,
  onCardClick,
  className,
  ...nativeProps
}: Props) => {
  const { colors } = useTheme()
  const { tappedCarouselClubCard } = useActions()
  const { pathname } = useLocation()
  const startingFrom = getClubEntryPoint(pathname)

  return (
    <Link
      to={clubRoute({ club }) as unknown as string}
      state={{ startingFrom }}
      {...(newTab ? { target: '_blank' } : null)}
    >
      <div
        data-testid="club-card"
        className={cx('fade', className)}
        onClick={() => {
          tappedCarouselClubCard(club)
          onCardClick && onCardClick()
        }}
        {...nativeProps}
      >
        <FlexBox
          className={css`
            width: 100%;
            height: 100%;
            * {
              cursor: pointer;
            }
          `}
          flexDirection="column"
        >
          <ClubCardHero club={club} />
          <FlexBox
            className={css`
              padding-top: 28px;
              padding-left: 14px;
              flex: 1;
            `}
            flexDirection="column"
          >
            <div>
              <Text
                type="label"
                sizing={{ base: 'M', desktop: 'L' }}
                color={colors.graphite}
                truncate={1}
              >
                <b>{getClubModeratorText({ club })}</b> moderates
              </Text>
              <Text
                className={css`
                  margin: 8px 0;
                `}
                tag="p"
                type="header"
                sizing={{ base: 'XS', desktop: 'S' }}
                weight="500"
                text={club.title}
                color={colors.graphite}
                truncate={2}
              />
            </div>
            <MemberCount
              count={club.member_count}
              color={`${colors.uiGraphiteLight}`}
              responsive
            />
          </FlexBox>
        </FlexBox>
      </div>
    </Link>
  )
}
