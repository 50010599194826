import { getParameterByName } from '../../utils'
import { getCookie } from '../../utils/cookie'
import { Destination, DestinationType } from './types'

const OPTANON_CONSENT_KEY = 'OptanonConsent'
const OPTANON_AWAITING_RECONSENT = 'AwaitingReconsent'

export const destinations = [
  {
    name: 'Customer.io',
    type: DestinationType.functional,
  },
  {
    name: 'Google Universal Analytics',
    type: DestinationType.performance,
  },
  {
    name: 'Gtag',
    type: DestinationType.performance,
  },
  {
    name: 'HotJar',
    type: DestinationType.performance,
  },
  {
    name: 'Mixpanel',
    type: DestinationType.performance,
  },
  {
    name: 'LinkedIn Tag',
    type: DestinationType.targeting,
  },
  {
    name: 'Main FB Pixel',
    type: DestinationType.targeting,
  },
  {
    name: 'TikTok Pixel',
    type: DestinationType.targeting,
  },
  {
    name: 'Twitter Ads',
    type: DestinationType.targeting,
  },
]

export const getIntegrations = (groups: DestinationType[]) => {
  const integrations: Record<string, boolean> = {}

  destinations.forEach((destination: Destination) => {
    if (!groups.includes(destination.type)) {
      integrations[destination.name] = false
    }
  })

  return integrations
}

export const getIntegrationsFromCookie = () => {
  const cookie = getCookie(OPTANON_CONSENT_KEY)
  const reconsent = cookie
    ? !!getParameterByName(OPTANON_AWAITING_RECONSENT, cookie)
    : false

  if (!reconsent) return {}

  const consentStatus = getParameterByName('groups', cookie).split(',')
  const groups = consentStatus
    .map((status) =>
      status.charAt(status.length - 1) === '1' ? status.slice(0, -2) : null
    )
    .filter((o) => o !== null)

  const integrations = getIntegrations(groups as DestinationType[])

  return integrations
}
