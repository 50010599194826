import { IconSVGProps } from '../types'

export const GoodreadsIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.64061 2.58429C8.64061 2.00666 8.64061 1.4244 8.64061 0.832902C9.10183 0.832902 9.53392 0.832902 10 0.832902C10 0.920703 10 1.00388 10 1.08706C10 4.18319 10 7.27931 10 10.3801C10 11.3274 9.89805 12.2562 9.50965 13.1388C8.99988 14.308 8.06773 15.0196 6.79087 15.2969C5.29069 15.625 3.79536 15.588 2.37772 14.9457C1.36788 14.4882 0.67848 13.7581 0.464862 12.6814C0.450297 12.6074 0.440587 12.5289 0.435732 12.4503C0.430877 12.4133 0.440587 12.3764 0.445442 12.3117C0.853258 12.3117 1.25622 12.307 1.65918 12.3163C1.70288 12.3163 1.78056 12.3717 1.79027 12.4133C2.10098 13.6656 3.05741 14.1647 4.276 14.3542C4.97026 14.4605 5.66452 14.4281 6.34422 14.2571C7.53368 13.9614 8.16483 13.1573 8.4367 12.076C8.55322 11.6093 8.60177 11.1194 8.6309 10.6388C8.66489 10.1213 8.63576 9.59447 8.63576 9.02146C8.58235 9.08615 8.55322 9.10926 8.53866 9.13698C7.6502 10.6666 6.2811 11.3366 4.45564 11.1703C3.40211 11.0732 2.45539 10.7636 1.65433 10.0843C0.974632 9.50667 0.537686 8.78578 0.309503 7.95861C-0.122588 6.41979 -0.137153 4.88559 0.489137 3.37912C1.63976 0.620332 4.75179 0.0242129 6.65979 0.832902C7.46086 1.17024 8.07744 1.71553 8.49982 2.45028C8.52895 2.50111 8.56293 2.54732 8.59692 2.59353C8.61148 2.58891 8.62605 2.58429 8.64061 2.58429ZM8.64547 5.98079C8.62119 5.07043 8.49011 4.20629 8.05316 3.41146C7.47542 2.35324 6.59667 1.69704 5.31497 1.61849C3.95558 1.53531 2.88749 2.02052 2.13011 3.10647C1.63005 3.81812 1.44071 4.62681 1.37274 5.46322C1.2902 6.51221 1.43585 7.52423 1.98446 8.45768C3.15936 10.4632 6.09661 10.722 7.62593 8.95214C8.37359 8.08338 8.61148 7.05288 8.64547 5.98079Z" />
  </svg>
)
