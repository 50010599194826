import { ThemeModes, css, cx } from '@fable/theme'
import getInputTheme from './utils/getInputTheme'

const TextInputLabel: React.FC<
  {
    theme: ThemeModes
  } & React.HTMLProps<HTMLLabelElement>
> = ({ children, theme, ...nativeProps }) => {
  const { labelColor } = getInputTheme(theme)

  return (
    <label
      {...nativeProps}
      className={cx(
        css`
          font-size: 10px;
          line-height: 10px;
          letter-spacing: -0.1px;
          color: ${labelColor};
        `,
        nativeProps.className
      )}
    >
      {children}
    </label>
  )
}

export default TextInputLabel
