import { Builder } from '@builder.io/react'
import { Button } from '@fable/components'
import { css, useTheme } from '@fable/theme'

interface Props {
  children: React.ReactNode | React.ReactNode[]
  open: boolean
  onClose: () => void
}

export const MenuDrawerContent = ({ open, onClose, children }: Props) => {
  const { colors, nativeOverrides, motion } = useTheme()
  const isEditor = Builder.isEditing && Builder.isPreviewing

  return (
    <div
      className={css`
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;
        pointer-events: none;
      `}
      onClick={() => !isEditor && onClose()}
    >
      <span
        className={css`
          display: block;
          background-color: ${colors.blackSwan}80;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: ${open ? 1 : 0};
          pointer-events: ${open ? 'auto' : 'none'};
          ${motion.transition('opacity')}
        `}
      />
      <div
        className={css`
          position: relative;
          max-width: 320px;
          width: 100%;
          height: 100%;
          background-color: ${colors.pearlWhite};
          transform: translateX(${open ? 0 : '-110%'});
          pointer-events: auto;
          ${motion.transition('transform')}
        `}
      >
        <Button
          className={css`
            ${nativeOverrides.buttonClear};
            position: absolute;
            top: 24px;
            right: 24px;
            z-index: 5;
            ${motion.transition('opacity')}
            &:hover {
              opacity: 0.7;
            }
            span {
              width: 24px;
            }
            svg {
              fill: ${colors.graphite};
            }
          `}
          icon="close"
          onClick={onClose}
          negative
        />
        <div
          className={css`
            height: 100%;
            overflow-y: scroll;
          `}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
