import { usePreloadImage } from '@fable/hooks'
import { css, cx, useTheme } from '@fable/theme'
import { getTheme, isModeratorDefaultAvatar } from 'app/clubs/utils'
import { Club } from '@fable/types'
import Pattern from '../pattern/LazyPattern'
interface Props extends React.HTMLProps<HTMLDivElement> {
  club: Club | null
  hero?: boolean
  position?: string
}

export const ClubImage = ({
  club,
  className,
  hero = false,
  position = 'center',
  ...props
}: Props) => {
  const { colors } = useTheme()

  const isModeratorDefaultImage = isModeratorDefaultAvatar(club?.creator.pic)
  const moderatorImage = usePreloadImage(club?.creator.pic || '', 416)
  const heroImage = usePreloadImage(club?.hero_image || '', 416)
  const image = hero || isModeratorDefaultImage ? heroImage : moderatorImage

  const fallbackPattern = () => {
    const clubTheme = getTheme(club?.theme as string)

    return (
      <div
        className={css`
          display: block;
          width: 100%;
          height: 100%;
          background: ${clubTheme.background};
        `}
      >
        <Pattern
          containerClass={css`
            width: 100%;
            height: 100%;
            opacity: 0.2;
            overflow: hidden;
          `}
          patternClass={css`
            width: 100%;
            height: 100%;
            transform: scale(1.7);
          `}
          color={colors.whiteFang}
          type={clubTheme.pattern}
        />
      </div>
    )
  }

  return !!club ? (
    <div
      className={cx(
        css`
          width: 100%;
          height: 100%;
          overflow: hidden;
        `,
        className
      )}
      {...props}
    >
      {image?.src ? (
        <img
          className={cx(
            css`
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: ${position};
              background-color: ${colors.whiteSmoke};
            `,
            'fade'
          )}
          src={image?.src || ''}
          alt={`${club.title} hero`}
        />
      ) : (
        fallbackPattern()
      )}
    </div>
  ) : null
}
