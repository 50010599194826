import { css } from '@fable/theme'
import { CompProps } from '@fable/types'

interface Props {
  backgroundColor: string
}

export const HomeFeaturesContainer = ({
  children,
  backgroundColor,
}: CompProps<Props>) => {
  return (
    <div
      className={css`
        padding-bottom: 70px;
        background-color: ${backgroundColor};
        transition: background-color 0.6s ease-in-out;
      `}
    >
      {children}
    </div>
  )
}
