import { FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useBranchView } from 'hooks'
import { useState } from 'react'

export const TikTokOverlay = () => {
  const [hidden, setHidden] = useState(false)
  const { colors } = useTheme()
  const bannerInView = useBranchView()

  if (hidden) return null

  return (
    <FlexBox
      className={css`
        padding: ${bannerInView ? '74px 6px 11px' : '11px 6px'};
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100dvh;
        z-index: 10000;
        background-color: ${colors.uiBlack40};
      `}
      onClick={() => setHidden(true)}
      justifyContent="flex-end"
    >
      <div
        className={css`
          padding: 8px 12px;
          background-color: ${colors.uiWhite};
          border-radius: 8px;
          width: 100%;
          max-width: 242px;
          box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
          position: relative;
          align-self: flex-start;
        `}
      >
        <span
          className={css`
            display: block;
            width: 20px;
            height: 20px;
            background-color: ${colors.uiWhite};
            transform: rotate(45deg);
            position: absolute;
            top: -3px;
            right: 12px;
            border-radius: 3px;
          `}
        />
        <Text
          className={css`
            position: relative;
            z-index: 1;
          `}
          type="label"
          sizing="L"
          align="center"
          weight="500"
          color={colors.uiGraphite}
        >
          Tap “Open in Browser” for full access - Tiktok blocks some features
        </Text>
      </div>
    </FlexBox>
  )
}
