import { Link } from 'react-router-dom'
import { css, useTheme } from '@fable/theme'
import { Logo, SocialLink, SocialLinks } from '@fable/components'
import { routes as marketingRoutes } from '../../../app/marketing/routes'
import { FlexBox } from '@fable/components'

export const FooterTop = () => {
  const { colors, mediaQueries } = useTheme()

  const socialLinks: SocialLink[] = [
    { id: 'x', url: 'https://twitter.com/getfable' },
    { id: 'instagram', url: 'https://www.instagram.com/fable/' },
    {
      id: 'linkedin',
      url: 'https://www.linkedin.com/company/fablegroup/mycompany/',
    },
    { id: 'facebook', url: 'https://www.facebook.com/getfable' },
    {
      id: 'youtube',
      url: 'https://www.youtube.com/channel/UCqI3TsDOJOa9wgz1XJ8sc-A',
    },
  ]

  return (
    <FlexBox
      className={css`
        flex-direction: column;
        align-items: flex-start;

        ${mediaQueries.mobileS} {
          flex-direction: row;
          align-items: flex-end;
        }
      `}
      justifyContent="space-between"
    >
      <Link
        className={css`
          margin-bottom: 20px;
          margin-right: 20px;

          ${mediaQueries.mobileS} {
            margin-bottom: 0;
          }
        `}
        to={marketingRoutes.home}
      >
        <Logo isIcon={false} isMixed={true} />
      </Link>
      <SocialLinks
        className={css`
          gap: 8px;
          button {
            width: 28px;
            height: 28px;
            .fds-ui--icon {
              width: 16px;
            }
            ${mediaQueries.mobileL} {
              gap: 12px;
              width: 40px;
              height: 40px;
              .fds-ui--icon {
                width: 20px;
              }
            }
          }
        `}
        links={socialLinks}
        backgroundColor={colors.uiGraphite}
        color={colors.uiWhite}
        borderRadius="8px"
      />
    </FlexBox>
  )
}
