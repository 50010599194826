/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Response } from '../../types'
import { AuthorActions } from '../actions'
import { AuthorTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

enum AuthorKeys {
  authorDetails = 'authorDetails',
}

interface AuthorState {
  [AuthorKeys.authorDetails]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: AuthorState = {
  [AuthorKeys.authorDetails]: props,
}

const reducer = (
  state: AuthorState = initialState,
  action: AuthorActions
): AuthorState => {
  const stateProps = { state, props }

  switch (action.type) {
    // GET AUTHOR DETAILS
    case AuthorTypes.GET_AUTHOR_DETAILS__REQUEST:
      return setRequestState(stateProps, AuthorKeys.authorDetails)
    case AuthorTypes.GET_AUTHOR_DETAILS__SUCCESS:
      return setSuccessState(
        stateProps,
        AuthorKeys.authorDetails,
        action.payload
      )
    case AuthorTypes.GET_AUTHOR_DETAILS__ERROR:
      return setErrorState(stateProps, AuthorKeys.authorDetails, action.payload)
    case AuthorTypes.GET_AUTHOR_DETAILS__CLEAR:
      return setClearState(stateProps, AuthorKeys.authorDetails)

    default:
      return state
  }
}

export default reducer
