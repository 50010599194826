import { gql } from '@apollo/client'
import { isPreview } from '../../services/contenful'

export const GET_BANNER = gql`
query {
  bannerCollection(limit:1, preview:${isPreview}) {
    items {
      name
      sys {
        id
      }
      desktopMessage {
        json
      }
      mobileMessage {
        json
      }
      showDismissButton
    }
  }
}
`
