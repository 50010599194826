import { css, cx, useTheme } from '@fable/theme'
import { Text } from '@fable/components'

const FormErrorMessage = ({ message }: { message?: string }) => {
  const { colors, motion } = useTheme()

  return (
    <Text
      className={cx(
        css`
          display: block;
          margin: 16px 0px;
          opacity: ${message ? 1 : 0};
          min-height: 16px;
          pointer-events: none;
          ${motion.transition('opacity')};
        `,
        'form-error-message'
      )}
      type="subhead"
      sizing={{ base: 'XS', mobileL: 'S' }}
      color={colors.error}
      align="center"
    >
      {message}
    </Text>
  )
}

export default FormErrorMessage
