import { css, cx, useTheme } from '@fable/theme'
import { FooterBottom, FooterMid, FooterTop } from './sections'
import { Breakpoint } from '@fable/legacy'
import { FooterLinks } from './FooterLinks'
import { FlexBox } from '@fable/components'
import { useFooter } from 'hooks'

const FooterLegacy = () => {
  const footer = useFooter((s) => s.config)
  const { mediaQueries, colors } = useTheme()

  return footer.hidden ? null : (
    <div className={cx(styles.footerWrap, 'fds-ui--footer')}>
      <div
        className={cx(
          css`
            background-color: ${colors.blackSwan};
            color: ${colors.uiWhite};
          `,
          styles.footer
        )}
      >
        <div className={cx(styles.sections)}>
          <FlexBox
            className={css`
              flex-direction: column;

              ${mediaQueries.tabletL} {
                flex-direction: row;
              }

              ${mediaQueries.desktop} {
                flex-direction: column;
              }
            `}
          >
            <div
              className={css`
                ${mediaQueries.tablet} {
                  margin-right: 5%;
                  width: 50%;
                  min-width: 396px;
                }

                ${mediaQueries.tabletL} {
                  margin-right: 10%;
                }

                ${mediaQueries.desktop} {
                  margin-right: 0;
                  width: 100%;
                }
              `}
            >
              <FooterTop />
              <FooterMid />
            </div>
            <FooterLinks
              className={css`
                ${mediaQueries.desktop} {
                  display: none;
                }
              `}
            />
          </FlexBox>
          <FooterBottom />
        </div>
      </div>
    </div>
  )
}

export default FooterLegacy

const styles = {
  footerWrap: css`
    padding: 0;
    /* 
    @media only screen and (min-width: ${Breakpoint.tablet}px) {
      padding: 60px 20px 20px;
    }

    @media only screen and (min-width: ${Breakpoint.desktop}px) {
      padding: 72px 32px 32px;
    } */
  `,
  footer: css`
    padding: 80px 24px;

    @media only screen and (min-width: ${Breakpoint.tablet}px) {
      padding: 52px 40px;
      // border-radius: 12px;
    }

    @media only screen and (min-width: ${Breakpoint.desktop}px) {
      padding: 100px 52px;
    }
  `,
  sections: css`
    margin: 0 auto;
    max-width: 1090px;
  `,
}
