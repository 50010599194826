import { FlexBox, marginEdgesRules } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { CompChildren } from 'types'

interface Props {
  header: CompChildren
  content: CompChildren
  mini?: boolean
}

export const PageDetailColumns = ({ header, content, mini }: Props) => {
  const { mediaQueries } = useTheme()

  /**
   * @note
   * Some additional styling has been added to this
   * layout that allows the header to use `position: sticky`
   * and the main content carousels to overflow their content.
   */
  const spacing = {
    header: '352px',
    gap: '128px',
    edges: '170px',
  }

  return (
    <FlexBox
      className={cx(
        css`
          margin: 0 auto;
          position: relative;
          flex-direction: column;
          overflow: hidden;
        `,
        !mini &&
          css`
            ${mediaQueries.tabletL} {
              padding: 0;
              flex-direction: row;
              max-width: none;
              overflow: visible;
            }
          `
      )}
    >
      <FlexBox
        className={cx(
          css`
            width: 100%;
            padding: 0 ${marginEdgesRules.mobile};
            justify-content: center;
          `,
          !mini &&
            css`
              ${mediaQueries.tabletL} {
                padding: 0;
                padding-left: ${marginEdgesRules.tablet};
                width: auto;
                width: ${spacing.header};
                justify-content: flex-end;
                flex-shrink: 0;
                z-index: 2;
              }
              ${mediaQueries.desktop} {
                width: calc(50% - ${spacing.edges});
              }
            `
        )}
      >
        <div
          className={cx(
            css`
              padding-top: 30px;
              width: 100%;
              max-width: ${spacing.header};
            `,
            !mini &&
              css`
                ${mediaQueries.tabletL} {
                  padding-top: '54px';
                  position: sticky;
                  top: '100px';
                  align-self: flex-start;
                }
              `
          )}
        >
          {header}
        </div>
      </FlexBox>
      <div
        className={cx(
          css`
            padding: 30px ${marginEdgesRules.mobile} 0;
            width: 100%;
          `,
          !mini &&
            css`
              ${mediaQueries.tabletL} {
                padding-top: 54px;
                padding-right: ${marginEdgesRules.tablet};
                padding-left: calc(${spacing.header} + ${spacing.gap});
                transform: translateX(-${spacing.header});
                flex-shrink: 0;
                overflow: hidden;
              }
              ${mediaQueries.desktop} {
                width: calc(50% + ${spacing.edges} + ${spacing.header});
              }
            `
        )}
      >
        <div
          className={cx(
            css`
              margin: 0 auto;
              max-width: ${spacing.header};
            `,
            !mini &&
              css`
                ${mediaQueries.tabletL} {
                  margin: 0;
                  max-width: none;
                }
              `
          )}
        >
          {content}
        </div>
      </div>
    </FlexBox>
  )
}
