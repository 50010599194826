import { IconSVGProps } from '../types'

export const AppleIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3892 0C14.5985 1.45774 14.0197 2.88585 13.256 3.89625C12.439 4.98003 11.0308 5.81827 9.66642 5.77452C9.41735 4.37887 10.0551 2.94089 10.831 1.97423C11.6822 0.907384 13.1411 0.0889039 14.3892 0ZM18.4878 20.5185C19.1912 19.4135 19.454 18.8561 20 17.6086C16.0286 16.0606 15.3922 10.2734 19.3226 8.05218C18.1238 6.51118 16.4391 5.61791 14.8489 5.61791C13.7031 5.61791 12.918 5.92439 12.2043 6.20302C11.6096 6.43522 11.0644 6.64806 10.4012 6.64806C9.68467 6.64806 9.0501 6.41485 8.38565 6.17066C7.65552 5.90232 6.8893 5.62073 5.93853 5.62073C4.15399 5.62073 2.25449 6.73838 1.0502 8.64911C-0.642649 11.3402 -0.353892 16.3993 2.39134 20.709C3.37257 22.2514 4.6836 23.9843 6.39835 23.9998C7.10986 24.0071 7.58333 23.7894 8.09569 23.5539C8.68213 23.2843 9.31953 22.9912 10.4231 22.9852C11.5333 22.9784 12.1606 23.275 12.7393 23.5486C13.2385 23.7846 13.7014 24.0035 14.4069 23.9956C16.123 23.9815 17.5066 22.0609 18.4878 20.5185Z"
    />
  </svg>
)
