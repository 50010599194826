import { css, useTheme } from '@fable/theme'
import { FooterBottom, FooterMid, FooterTop } from './sections'
import { Breakpoint } from '@fable/legacy'
import { useFooter } from 'hooks'

const Footer = () => {
  const footer = useFooter((s) => s.config)
  const { colors } = useTheme()

  return footer.hidden ? null : (
    <div
      className={css`
        margin-top: -1px;
        background-color: ${colors.uiBlack};
        color: ${colors.uiWhite};
        padding: 80px 24px;
        @media only screen and (min-width: ${Breakpoint.tablet}px) {
          padding: 52px 40px;
        }
        @media only screen and (min-width: ${Breakpoint.desktop}px) {
          padding: 100px 52px;
        }
      `}
    >
      <div
        className={css`
          margin: 0 auto;
          max-width: 1090px;
        `}
      >
        <FooterTop />
        <div
          className={css`
            margin: 32px 0;
            padding: 32px 0;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-left: none;
            border-right: none;
          `}
        >
          <FooterMid />
        </div>
        <FooterBottom />
      </div>
    </div>
  )
}

export default Footer
