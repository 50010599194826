import { getParameterByName } from '@fable/utils'
import { call, takeEvery } from 'redux-saga/effects'

interface ReducerType {
  type: string | any
  payload: Record<any, any>
}

function* handleEvent(action: ReducerType) {
  yield call(console.log, 'ACTION', action.type)
}

export default function* errorSaga() {
  if (getParameterByName('authToken')) {
    yield takeEvery('*', handleEvent)
  }
}
