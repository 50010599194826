import { Club } from '@fable/types'
import { ActivityPostCard } from './ActivityPostCard'
import { ActivityPostOptions } from './ActivityPost'

interface Props {
  club: Club
  options?: ActivityPostOptions
}

export const ActivityPostClub = ({ club, options }: Props) => (
  <ActivityPostCard
    link={club.url}
    type="club"
    title={club.title}
    description={club.about_us}
    image={club.hero_image}
    {...options}
  />
)
