import { css, useTheme } from '@fable/theme'
import { useSelector } from '@xstate/react'
import { useCart } from 'hooks'
import { useDeviceDetect, useClickOutside } from '@fable/hooks'
import { useEffect, useRef } from 'react'
import { CartEmpty } from './CartEmpty'
import { CartIconButton } from './CartIconButton'
import { CartItems } from './CartItems'

interface Props {
  secondary: boolean
}

export const Cart = ({ secondary }: Props) => {
  const { colors, motion, mediaQueries } = useTheme()
  const { isMobile } = useDeviceDetect()
  const { service, open, closeCart, openCart } = useCart()
  const state = useSelector(service, (state) => state)
  const ctx = state.context
  const cart = ctx.cart
  const ref = useRef(null)

  useClickOutside(ref, () => open && closeCart())

  useEffect(() => {
    if (ctx.success) {
      openCart()
      service.send('CLEAR_SUCCESS')
    }
  }, [openCart, ctx.success, service])

  return (
    <div
      ref={ref}
      className={css`
        margin-left: 8px;
        ${mediaQueries.mobileL} {
          margin-left: 16px;
        }
        ${mediaQueries.tablet} {
          position: relative;
        }
      `}
      onMouseEnter={() => !isMobile && !open && openCart()}
      onMouseLeave={() => !isMobile && open && closeCart()}
    >
      <CartIconButton secondary={secondary} />
      <div
        className={css`
          padding-top: 28px;
          display: table;
          position: absolute;
          top: 46px;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 16px);
          opacity: ${open ? 1 : 0};
          pointer-events: ${open ? 'auto' : 'none'};
          ${motion.transition('opacity')};
          ${mediaQueries.tablet} {
            padding-top: 26px;
            top: 30px;
            width: ${cart?.items?.length ? '380px' : '200px'};
            right: -12px;
            left: auto;
            transform: none;
          }
        `}
      >
        <div
          className={css`
            padding: 24px 0px;
            background-color: ${colors.whiteFang};
            border-radius: 12px;
            border: 1px solid ${colors.whiteSmoke};
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
            position: relative;
            transform: translateY(${open ? '-10px' : 0});
            ${motion.transition('transform')};
          `}
        >
          <span
            className={css`
              display: block;
              top: -7px;
              right: 20px;
              background: ${colors.whiteFang};
              border-top-left-radius: 2px;
              border: 1px solid ${colors.whiteSmoke};
              border-bottom: transparent;
              border-right: transparent;
              width: 12px;
              height: 12px;
              position: absolute;
              transform: rotate(45deg);
              ${mediaQueries.tablet} {
                right: 20px;
              }
            `}
          />
          {cart?.items?.length ? (
            <CartItems items={cart?.items} />
          ) : (
            <CartEmpty closeCart={closeCart} />
          )}
        </div>
      </div>
    </div>
  )
}
