import { IconSVGProps } from '../types'

export const GiftIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_8_133)">
      <path
        d="M15 2.00001C15.7023 1.99992 16.3922 2.18474 17.0004 2.53587C17.6086 2.887 18.1137 3.39207 18.4647 4.0003C18.8158 4.60853 19.0006 5.29847 19.0005 6.00075C19.0003 6.70304 18.8153 7.39291 18.464 8.00101L23 8.00001V10H21V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V10H1V8.00001L5.536 8.00101C5.03986 7.14189 4.88069 6.12906 5.08938 5.15918C5.29808 4.1893 5.85975 3.33157 6.66533 2.75256C7.47092 2.17354 8.46293 1.91456 9.44875 2.02588C10.4346 2.13721 11.3438 2.61091 12 3.35501C12.3746 2.92839 12.8361 2.58678 13.3535 2.35308C13.8709 2.11939 14.4323 1.99901 15 2.00001ZM11 10H5V19H11V10ZM19 10H13V19H19V10ZM9 4.00001C8.48102 3.99768 7.98145 4.19719 7.60685 4.55639C7.23224 4.91558 7.01193 5.40632 6.99246 5.92495C6.97299 6.44357 7.15588 6.94945 7.50249 7.33572C7.84911 7.722 8.3323 7.9584 8.85 7.99501L9 8.00001H11V6.00001C11 5.52207 10.8289 5.05992 10.5176 4.69726C10.2063 4.33461 9.77542 4.09541 9.303 4.02301L9.149 4.00501L9 4.00001ZM15 4.00001C14.4954 3.99985 14.0094 4.19041 13.6395 4.5335C13.2695 4.87659 13.0428 5.34685 13.005 5.85001L13 6.00001V8.00001H15C15.5046 8.00017 15.9906 7.8096 16.3605 7.46651C16.7305 7.12342 16.9572 6.65316 16.995 6.15001L17 6.00001C17 5.46957 16.7893 4.96087 16.4142 4.58579C16.0391 4.21072 15.5304 4.00001 15 4.00001Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_8_133">
        <rect width="24" height="24" fill={fill} />
      </clipPath>
    </defs>
  </svg>
)
