import { Element } from 'react-scroll'
import { CompProps } from 'types'
import { PageAnchor } from '../types'

interface Props extends Partial<PageAnchor> {
  className?: string
}

export const PageAnchorElement = ({
  children,
  id,
  className,
  hidden,
}: CompProps<Props>) =>
  hidden?.anchor ? null : (
    <Element
      data-testid={`page-anchor-element-${id}`}
      name={id as string}
      className={className}
    >
      {children}
    </Element>
  )
