import { IconSVGProps } from '../types'

export const ThumbsDownOutlineIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_195_51886)">
      <path d="M9.4 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V11.896C0.999731 11.6346 1.05069 11.3758 1.15 11.134L4.246 3.62C4.32128 3.43675 4.4493 3.28 4.6138 3.16961C4.77831 3.05923 4.97189 3.0002 5.17 3H22C22.2652 3 22.5196 3.10536 22.7071 3.29289C22.8946 3.48043 23 3.73478 23 4V14C23 14.2652 22.8946 14.5196 22.7071 14.7071C22.5196 14.8946 22.2652 15 22 15H18.518C18.3579 15 18.2001 15.0384 18.058 15.112C17.9158 15.1856 17.7934 15.2922 17.701 15.423L12.248 23.149C12.1791 23.2467 12.0774 23.3164 11.9615 23.3456C11.8455 23.3748 11.723 23.3614 11.616 23.308L9.802 22.4C9.2915 22.1448 8.88367 21.7227 8.64627 21.2037C8.40887 20.6847 8.3562 20.1001 8.497 19.547L9.4 16ZM17 13.412V5H5.84L3 11.896V14H9.4C9.7046 14 10.0052 14.0697 10.2788 14.2035C10.5524 14.3374 10.7918 14.532 10.9787 14.7725C11.1657 15.013 11.2952 15.293 11.3575 15.5912C11.4197 15.8893 11.4131 16.1978 11.338 16.493L10.435 20.041C10.4067 20.1517 10.4172 20.2687 10.4647 20.3726C10.5122 20.4765 10.5938 20.561 10.696 20.612L11.357 20.942L16.067 14.27C16.317 13.916 16.637 13.626 17 13.412ZM19 13H21V5H19V13Z" />
    </g>
    <defs>
      <clipPath id="clip0_195_51886">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
