import { FlexBox, Icon, IconTypes, Text } from '@fable/components'
import { css } from '@fable/theme'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  count: string
  icon: IconTypes
}

export const ActivityCounterButton = ({ count, icon, ...props }: Props) => (
  <FlexBox
    className={css`
      cursor: pointer;
    `}
    alignItems="center"
    gap="4px"
    {...props}
  >
    <Icon
      className={css`
        display: block;
        width: 24px;
      `}
      type={icon}
    />
    <Text
      className={css`
        font-weight: bold;
      `}
      type="body"
      sizing="M"
    >
      {count}
    </Text>
  </FlexBox>
)
