import { css } from '@fable/theme'
import { useContext } from 'react'
import DropdownContext from './DropdownContext'

/**
 * This component renders the native select for !searchable
 * or native datalist for searchable
 * instead of the custom dropdown used on a non-mobile device
 */

const DropdownMobileList = () => {
  const context = useContext(DropdownContext)
  const { mobileListName, items, onChangeText, mobileReturnMatchingItem } =
    context

  const attributes = {
    className: css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    `,
    id: mobileListName,
  }

  return (
    <select
      {...attributes}
      onChange={(e) =>
        mobileReturnMatchingItem({ text: e.target.value, onChangeText, items })
      }
    >
      {items.map((item, i) => (
        <option key={i} value={item.value}>
          {item.text}
        </option>
      ))}
    </select>
  )
}

export default DropdownMobileList
