import { useEffect, useMemo } from 'react'
import { css, cx } from '@fable/theme'
import PropTypes from 'prop-types'
import SearchIcon from '../../assets/icons/components/SearchIcon'
import Tooltip from '../tooltip/Tooltip'
import { CharacterCount, FlexBox } from '@fable/components'
import FieldWithOptions from './FieldWithOptions'

import './Field.scss'

const Field = ({
  disabled,
  fieldClass,
  formRef,
  hasSubmit,
  icon,
  isDark,
  label,
  maxLength,
  name,
  onChange,
  onClick,
  onOptionSelect,
  options,
  optionsPosition,
  placeholder,
  readOnly,
  required,
  tooltip,
  type,
  value,
  withCount,
  className,
  testId,
  error,
  onBlur,
  autoComplete,
  id,
}) => {
  const sharedInputProps = useMemo(
    () => ({
      label,
      icon,
      disabled,
      name,
      onChange,
      placeholder,
      type,
      maxLength,
      required,
      onClick,
      autoComplete,
      'data-testid': testId,
      // autoComplete, value, and id are added dynamically. See useEffect below
    }),
    [
      disabled,
      icon,
      label,
      maxLength,
      name,
      onChange,
      onClick,
      placeholder,
      required,
      testId,
      type,
      autoComplete,
    ]
  )

  useEffect(() => {
    /* If multiple ids are empty strings
    React will throw a warning that multiple elements have the same id */
    if (id && !sharedInputProps.id) {
      sharedInputProps.id = id
    }
    /* I'd rather not set autoComplete to anything since default is "on".
    Here the attribute is only added if it's specified to be "off" */
    if (autoComplete === 'off' && !sharedInputProps.autoComplete) {
      sharedInputProps.autoComplete = 'off'
    }
  }, [autoComplete, formRef, id, sharedInputProps, value])

  const renderFieldWithLabel = () => {
    const input = formRef ? (
      <input
        {...sharedInputProps}
        className={cx(error ? 'error' : '', fieldClass)}
        readOnly={readOnly}
        onBlur={onBlur}
        ref={formRef}
      />
    ) : (
      <input
        {...sharedInputProps}
        className={cx(error ? 'error' : '', fieldClass)}
        onBlur={onBlur}
        value={value}
      />
    )

    let disabledClass

    if (disabled) {
      disabledClass = 'disabled'
    } else if (readOnly) {
      disabledClass = 'read-only'
    } else {
      disabledClass = ''
    }

    return (
      <div className={`field-wrap has-label ${disabledClass} `}>
        <label>{label}</label>
        {input}
        {tooltip && (
          <Tooltip
            customClass="medium fixed right"
            isLabelDark={true}
            tip={tooltip}
          />
        )}
      </div>
    )
  }

  const renderFieldWithIcon = () => {
    return (
      <div className="field-wrap has-icon">
        <input
          {...sharedInputProps}
          className={cx(error ? 'error' : '', fieldClass)}
          value={value}
        />
        {icon === 'search' ? <SearchIcon /> : ''}
      </div>
    )
  }

  const renderFieldWithSubmitButton = () => {
    const dark = isDark ? 'dark' : ''
    return (
      <div className={`field-wrap has-submit ${dark}`}>
        <input
          {...sharedInputProps}
          className={cx(error ? 'error' : '', 'field', fieldClass)}
          value={value}
        />
        <input className="submit" type="submit" value="Send" />
      </div>
    )
  }

  const renderDefaultField = () => {
    return (
      <input
        {...sharedInputProps}
        className={cx(error ? 'error' : '', 'field', fieldClass)}
        value={value}
      />
    )
  }

  const renderField = () => {
    if (hasSubmit) return renderFieldWithSubmitButton()
    if (!!options)
      return (
        <FieldWithOptions
          sharedInputProps={sharedInputProps}
          onOptionSelect={onOptionSelect}
          optionsPosition={optionsPosition}
          options={options}
          value={value}
        />
      )
    if (!!icon) return renderFieldWithIcon()
    if (!!label) return renderFieldWithLabel()

    return renderDefaultField()
  }

  return (
    <div className={className}>
      {renderField()}
      {withCount && (
        <FlexBox
          justifyContent="flex-end"
          className={css`
            margin-top: 4px;
          `}
        >
          <CharacterCount
            input={value}
            maxLength={Number.parseInt(maxLength, 10)}
          />
        </FlexBox>
      )}
    </div>
  )
}

Field.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fieldClass: PropTypes.string.isRequired,
  formRef: PropTypes.any,
  hasSubmit: PropTypes.bool,
  icon: PropTypes.string,
  isDark: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onOptionSelect: PropTypes.func,
  options: PropTypes.array,
  optionsPosition: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  withCount: PropTypes.bool,
  testId: PropTypes.string,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
}

Field.defaultProps = {
  id: null,
  className: '',
  disabled: false,
  fieldClass: '',
  formRef: null,
  hasSubmit: false,
  icon: '',
  isDark: false,
  label: '',
  maxLength: '',
  name: '',
  onChange: null,
  onClick: null,
  onOptionSelect: null,
  optionsPosition: '',
  placeholder: '',
  readOnly: false,
  required: false,
  tooltip: '',
  type: '',
  value: '',
  withCount: false,
  testId: 'field',
  error: false,
  onBlur: () => {},
  autoComplete: null,
}

export default Field
