import { css, cx, useTheme } from '@fable/theme'
import { Subscription } from '@fable/types'
import { FlexBox, Text } from '@fable/components'
import { formatDate } from '../../../../utils'
import ClubThumbnail from '../../../../components/club_thumbnail/ClubThumbnail'
import monthlyPlanImage from '../../../../assets/images/graphics/monthly-plan.svg'
import yearlyPlanImage from '../../../../assets/images/graphics/yearly-plan.svg'

const SubscriptionListItem = ({
  subscription,
}: {
  subscription: Subscription
}) => {
  const { fonts, colors, mediaQueries } = useTheme()

  const styles = {
    detailContainer: css`
      margin-left: 10px;
      max-width: 300px;
    `,
    detail: css`
      font-family: ${fonts.body};
      color: ${colors.blackSwan};
      font-size: 12px;
      line-height: 16px;

      ${mediaQueries.mobileS} {
        font-size: 14px;
        line-height: 20px;
      }
    `,
    icon: css`
      width: 40px;
    `,
    clubThumnailOverride: css`
      margin-top: 10px;
      img {
        left: 5px;
        width: 40px;
      }
    `,
  }

  const itemTitle = (subscription: Subscription) => {
    switch (subscription.plan.type) {
      case 'free':
        return 'Fable Free Membership'
      case 'all_access':
        const statusText =
          subscription.status === 'trialing' ? '14 Day Free Trial' : 'All Clubs'
        return `Fable Premium - ${statusText}`
      case 'a_la_carte':
        return subscription.plan.club.title
    }
  }

  const statusText = () => {
    if (subscription.plan.type === 'free') {
      return 'Upgrade to enjoy more of Fable'
    }
    return `${
      subscription.cancel_at_period_end ? 'Ends' : 'Renews'
    } ${formatDate(subscription.current_period_end, true)}`
  }

  const planDetail = () => {
    switch (subscription.plan.interval) {
      case 'year':
        return 'Annual Membership'
      case 'free':
        return 'Access to free clubs only'
      case 'month':
        return subscription.plan.type === 'all_access'
          ? 'Monthly Memberhip'
          : 'Fable Premium - Single Club'
    }
  }

  const renderIcon = () => {
    if (subscription.plan.type === 'all_access') {
      return (
        <img
          src={
            subscription.plan.interval === 'month'
              ? monthlyPlanImage
              : yearlyPlanImage
          }
          className={styles.icon}
          alt="Card displaying text which reads 12 months"
        />
      )
    }
    return (
      <ClubThumbnail
        clubData={subscription.plan?.club}
        className={cx(styles.icon, styles.clubThumnailOverride)}
      />
    )
  }

  return (
    <FlexBox>
      {renderIcon()}
      <div className={styles.detailContainer}>
        <Text type="body" sizing="M">
          <b>{itemTitle(subscription)}</b>
        </Text>
        <Text type="body" sizing="M">
          {planDetail()}
        </Text>
        <Text type="body" sizing="M">
          {statusText()}
        </Text>
      </div>
    </FlexBox>
  )
}

export default SubscriptionListItem
