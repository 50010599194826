import { css, cx } from '@fable/theme'
import { Color } from '../../types'

export enum TextColor {
  fableGreen = 'fds-c--text-fable-green',
  blackSwan = 'fds-c--text-black-swan',
  pinkMoon = 'fds-c--text-pink-moon',
  whiteFang = 'fds-c--text-white-fang',
  saffron = 'fds-c--text-saffron',
  mahogany = 'fds-c--text-mahogany',
  tussock = 'fds-c--text-tussock',
  shamrock = 'fds-c--text-shamrock',
  lavenderBlush = 'fds-c--text-lavender-blush',
  denim = 'fds-c--text-denim',
  mayaBlue = 'fds-c--text-maya-blue',
  hurricane = 'fds-c--text-hurricane',
  whisper = 'fds-c--text-whisper',
  whiteSmoke = 'fds-c--text-white-smoke',
  almostWhite = 'fds-c--text-almost-white',
  greyStrong = 'fds-c--text-grey-strong',
}

export enum BgColor {
  fableGreen = 'fds-c--bg-fable-green',
  blackSwan = 'fds-c--bg-black-swan',
  pinkMoon = 'fds-c--bg-pink-moon',
  whiteFang = 'fds-c--bg-white-fang',
  saffron = 'fds-c--bg-saffron',
  mahogany = 'fds-c--bg-mahogany',
  tussock = 'fds-c--bg-tussock',
  shamrock = 'fds-c--bg-shamrock',
  lavenderBlush = 'fds-c--bg-lavender-blush',
  denim = 'fds-c--bg-denim',
  mayaBlue = 'fds-c--bg-maya-blue',
  hurricane = 'fds-c--bg-hurricane',
  whisper = 'fds-c--bg-whisper',
  whiteSmoke = 'fds-c--bg-white-smoke',
  almostWhite = 'fds-c--bg-almost-white',
  greyStrong = 'fds-c--bg-grey-strong',
}

const textColors = css`
  /* TEXT COLOR */

  .${TextColor.fableGreen} {
    color: ${Color.fableGreen};
  }

  .${TextColor.blackSwan} {
    color: ${Color.blackSwan};
  }

  .${TextColor.pinkMoon} {
    color: ${Color.pinkMoon};
  }

  .${TextColor.whiteFang} {
    color: ${Color.whiteFang};
  }

  .${TextColor.saffron} {
    color: ${Color.saffron};
  }

  .${TextColor.mahogany} {
    color: ${Color.mahogany};
  }

  .${TextColor.tussock} {
    color: ${Color.tussock};
  }

  .${TextColor.shamrock} {
    color: ${Color.shamrock};
  }

  .${TextColor.lavenderBlush} {
    color: ${Color.lavenderBlush};
  }

  .${TextColor.denim} {
    color: ${Color.denim};
  }

  .${TextColor.mayaBlue} {
    color: ${Color.mayaBlue};
  }

  .${TextColor.hurricane} {
    color: ${Color.hurricane};
  }

  .${TextColor.whisper} {
    color: ${Color.whisper};
  }

  .${TextColor.whiteSmoke} {
    color: ${Color.whiteSmoke};
  }

  .${TextColor.almostWhite} {
    color: ${Color.almostWhite};
  }

  .${TextColor.greyStrong} {
    color: ${Color.greyStrong};
  }
`

const backgroundColors = css`
  .${BgColor.fableGreen} {
    background-color: ${Color.fableGreen};
  }

  .${BgColor.blackSwan} {
    background-color: ${Color.blackSwan};
  }

  .${BgColor.pinkMoon} {
    background-color: ${Color.pinkMoon};
  }

  .${BgColor.whiteFang} {
    background-color: ${Color.whiteFang};
  }

  .${BgColor.saffron} {
    background-color: ${Color.saffron};
  }

  .${BgColor.mahogany} {
    background-color: ${Color.mahogany};
  }

  .${BgColor.tussock} {
    background-color: ${Color.tussock};
  }

  .${BgColor.shamrock} {
    background-color: ${Color.shamrock};
  }

  .${BgColor.lavenderBlush} {
    background-color: ${Color.lavenderBlush};
  }

  .${BgColor.denim} {
    background-color: ${Color.denim};
  }

  .${BgColor.mayaBlue} {
    background-color: ${Color.mayaBlue};
  }

  .${BgColor.hurricane} {
    background-color: ${Color.hurricane};
  }

  .${BgColor.whisper} {
    background-color: ${Color.whisper};
  }

  .${BgColor.whiteSmoke} {
    background-color: ${Color.whiteSmoke};
  }

  .${BgColor.almostWhite} {
    background-color: ${Color.almostWhite};
  }

  .${BgColor.greyStrong} {
    background-color: ${Color.greyStrong};
  }
`

export const colors = cx(textColors, backgroundColors)
