import { takeEvery, put } from 'redux-saga/effects'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isError from 'lodash/isError'
import isString from 'lodash/isString'
import { serializeError } from 'serialize-error'
import {
  AuthTypes,
  AuthorTypes,
  BillingTypes,
  BookTypes,
  ClubTypes,
  FolioTypes,
  SearchTypes,
  SocialTypes,
  UserTypes,
  ModalTypes,
  GiftsTypes,
  GeoIpTypes,
} from '../state/action-types'
import {
  captureException,
  httpErrorUrl,
  humanReadableErrorMessage,
} from '../utils/error'
import { CustomError } from '../types/error'
import ErrorCard from '../components/error_card/ErrorCard'

interface ReducerType {
  type: string | any
  payload: Record<any, any>
}

const trackableTypes = [
  AuthTypes,
  AuthorTypes,
  BillingTypes,
  BookTypes,
  ClubTypes,
  FolioTypes,
  GiftsTypes,
  SearchTypes,
  SocialTypes,
  UserTypes,
]

const trackables: any[] = []
const typeBlacklist = new Set([
  /*
    This error would be ignored since fable.co/username can return a user profile or
    show the 404 page if the user is not found.
  */
  SocialTypes.USER_LOOKUP__ERROR,
  BillingTypes.GET_CREDIT_BALANCE__ERROR, // temp until API returns 200 instead of 404 for not found
])

trackableTypes.forEach((x: { [key: string]: any }) => {
  for (const key in x) {
    if (key.includes('__ERROR') && !typeBlacklist.has(key as any)) {
      trackables.push(x[key])
    }
  }
})

function getError(action: ReducerType) {
  return get(action, 'payload') as CustomError
}

function getExtra(action: ReducerType) {
  const error = getError(action)
  const extra: { request?: any; response?: any; error?: any } = {}
  if (error.isAxiosError) {
    delete extra.error
    extra.request = pick(error.config, ['data', 'url'])
    extra.response = pick(error.response, ['data', 'status', 'headers'])
  } else {
    extra.error = serializeError(error)
  }
  return extra
}

function getErrorMessage(error: CustomError, action: ReducerType) {
  const message = humanReadableErrorMessage(action.type, error)
  const output = [action.type]

  const url = httpErrorUrl(error)
  if (url) {
    output.push(url)
  }

  output.push(message)

  return output.join(' ')
}

const blacklist = [
  /There is no current user/i,
  /The password is invalid or the user does not have a password/i,
  /JWT was found to be invalid, or the App’s project ID cannot be determined/i,
  /Network Error/i,
]

// Log the error but don't show a modal
const restrictModalList = [
  GiftsTypes.SET_GIFT_CLAIMED__ERROR,
  UserTypes.SEND_BOOK_LINK_SMS__ERROR,
  BillingTypes.CREATE_ORDER__ERROR, // The checkout form will display a red banner above the form instead of showing a modal
  BillingTypes.UPDATE_ORDER__ERROR, // Entering an invalid promo code doesn't need to display a modal since the error will display above the checkout form
  AuthTypes.SIGNUP__ERROR, // Any signup errors will be displayed in AuthForm, not a modal
  AuthTypes.RESET_PASSWORD__ERROR, // Any password reset errors will be displayed in ResetPassword, not a modal
  AuthTypes.VERIFY_ACTION_CODE__ERROR, // Same as above
  AuthTypes.APPLY_ACTION_CODE__ERROR, // This error occurs on the verify-email page which has its own error UI
  GeoIpTypes.GEO_IP_COUNTRY__ERROR, // If Geo IP fails, just let the user in, no need to show a modal
]

export function* handleEvent(action: ReducerType) {
  const error = getError(action)
  if (!error) {
    return
  }

  const message = getErrorMessage(error, action)
  if (message) {
    for (const blacklistItem of blacklist) {
      if (blacklistItem.exec(message)) {
        return
      }
    }
  }

  let finalError

  try {
    if (isError(error)) {
      // build a new error with a more detailed message
      finalError = new Error(message)
      finalError.name = error.name
      if (error.stack && isString(error.stack)) {
        finalError.stack = error.stack.replace(error.message, message)
      } else {
        finalError.stack = error.stack
      }
    } else {
      finalError = error
    }
    captureException(finalError, {
      extra: { action: action.type, ...getExtra(action) },
    })
  } catch (e) {
    finalError = e
    captureException(e)
  } finally {
    if (!restrictModalList.includes(action.type)) {
      yield put({
        type: ModalTypes.MODAL__OPEN,
        payload: (
          <ErrorCard message={humanReadableErrorMessage(action.type, error)} />
        ),
      })
    }
  }
}

// See e2e/errorHandling.spec.ts for test cases
export default function* errorSaga() {
  yield takeEvery(trackables, handleEvent)
}
