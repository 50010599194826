const createUrlSearchParams = (
  entries: { key: string; values: string[] }[]
) => {
  // https://stackoverflow.com/a/63660306/7569308
  const arr: string[][] = []

  entries.forEach((x) => {
    x.values.forEach((value: string) => {
      arr.push([x.key, value])
    })
  })

  return new URLSearchParams(arr).toString()
}

export default createUrlSearchParams
