import {
  authRoutes,
  clubRoutes,
  communityRoutes,
  globalRoutes,
  internalRoutes,
  useMarketingRoutes,
  socialRoutes,
  universalRoutes,
  useStoreRoutes,
  webviewRoutes,
} from './app_routes'

export const useRoutes = () => {
  const storeRoutes = useStoreRoutes()
  const marketingRoutes = useMarketingRoutes()

  return [
    ...authRoutes,
    ...clubRoutes,
    ...communityRoutes,
    ...globalRoutes,
    ...marketingRoutes,
    ...storeRoutes,
    ...internalRoutes,
    ...universalRoutes,
    ...socialRoutes,
    ...webviewRoutes,
  ]
}
