import { FlexBox } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { CompProps } from '@fable/types'
import { Ref, forwardRef } from 'react'

interface Props {
  className?: string
}

const Content = (
  { children, className = '' }: CompProps<Props>,
  ref: Ref<HTMLDivElement>
) => {
  const { colors } = useTheme()

  return (
    <FlexBox
      ref={ref}
      className={cx(
        css`
          padding: 12px;
          background-color: ${colors.uiPearlExtraLight};
          border-radius: 24px;
          overflow: hidden;
        `,
        className
      )}
      flexDirection="column"
      alignItems="center"
    >
      {children}
    </FlexBox>
  )
}

export const HomeBlock = forwardRef(Content)
