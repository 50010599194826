import {
  Source as StripeSource,
  StripeElement,
  SourceCreateParams,
} from '@stripe/stripe-js'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useActions } from '../hooks'

export const useCreateStripeSource = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { setStripeSourceId } = useActions()

  const createStripeSource = async (owner: Partial<StripeSource.Owner>) => {
    if (!!stripe && !!elements) {
      const card = elements.getElement(CardNumberElement)
      const sourceObj = { owner } as SourceCreateParams

      const result = await stripe.createSource(card as StripeElement, sourceObj)
      // stripe source id is stored in Redux for retrieving source in useRetrieveStripeSource
      setStripeSourceId(result.source?.id || '')

      return result
    }
  }

  return createStripeSource
}
