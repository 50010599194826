import { useEffect } from 'react'
import errorCardModalPayload from '../components/error_card/errorCardModalPayload'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'

export const useErrorModal = (error?: any) => {
  const { openModal } = useActions()
  const modal = useTypedSelector(({ modal }) => modal)

  useEffect(() => {
    if (!!error) {
      console.error(error)

      if (!modal?.isOpen) {
        const { content, modalType } = errorCardModalPayload(error)

        openModal(content, modalType, true)
      }
    }
  }, [error, modal?.isOpen, openModal])
}
