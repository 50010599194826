import { useUser } from 'hooks'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { singularSdk, SingularConfig } from 'singular-sdk'

const config = {
  sdkKey: process.env.REACT_APP_SINGULAR_SDK_KEY || '',
  sdkSecret: process.env.REACT_APP_SINGULAR_SDK_SECRET || '',
  productId: process.env.REACT_APP_SINGULAR_PRODUCT_ID || '',
}

export const useSingular = () => {
  const { pathname } = useLocation()
  const { loading } = useUser()
  const initialized = useRef(false)

  const initSingular = useCallback(() => {
    if (initialized.current) return

    const init = new SingularConfig(
      config.sdkKey,
      config.sdkSecret,
      config.productId
    ).withInitFinishedCallback((initParams: { singularDeviceId: string }) => {
      if (initParams.singularDeviceId) {
        initialized.current = true
      }
    })

    singularSdk.init(init)
  }, [])

  const pageVisit = useCallback(() => {
    if (pathname) singularSdk.pageVisit()
  }, [pathname])

  useEffect(() => {
    if (loading) return
    initSingular()
  }, [initSingular, loading])

  useEffect(() => {
    if (!initialized.current) return
    pageVisit()
  }, [pageVisit])
}
