import { css, cx } from '@fable/theme'
import { SpinnerIcon } from './SpinnerIcon'
import FlexBox from '../FlexBox'

export const Spinner = ({
  className,
  spinnerClassName,
  speed = 1,
}: {
  className?: string
  spinnerClassName?: string
  speed?: number
}) => (
  <FlexBox className={className} centerAll>
    <SpinnerIcon
      className={cx(
        css`
          animation: spin ${speed}s linear infinite;

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }

            to {
              transform: rotate(-360deg);
            }
          }
        `,
        spinnerClassName
      )}
    />
  </FlexBox>
)
