export type AJS = SegmentAnalytics.AnalyticsJS & {
  initialized: boolean
  track: (event: string, properties: { [key: string]: any }) => void
  addSourceMiddleware: (middleware: Middleware) => void
}

export type Middleware = (input: MiddlewareInput) => void

export interface MiddlewareInput {
  payload: {
    obj: Record<string, any>
    [key: string]: any
  }
  integrations?: Record<string, boolean>
  next: (payload: MiddlewareInput['payload']) => void
}

export type WindowWithAJS = Window &
  typeof globalThis & {
    analytics?: AJS
    OneTrust?: any
  }

export interface Destination {
  name: string
  type: DestinationType
}

export enum DestinationType {
  strict = 'C0001',
  performance = 'C0002',
  functional = 'C0003',
  targeting = 'C0004',
}
