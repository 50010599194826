import { Colors, css, cx, useTheme, QUOTE_THEME } from '@fable/theme'
import { usePreloadImage } from '@fable/hooks'
import BookImage from '../BookImage'
import { FlexBox, Icon, QuoteViewProps, Text } from '..'
import { QuoteViewText } from './QuoteViewText'

const QuoteView = ({ book, text, theme, imageSize = 500 }: QuoteViewProps) => {
  const { colors, mediaQueries } = useTheme()
  const bookImage = usePreloadImage(book?.cover_image_resize, imageSize)
  const themeColors = (theme && QUOTE_THEME[theme]) || QUOTE_THEME.theme1

  return (
    <FlexBox
      data-testid="quote"
      className={cx(
        css`
          position: relative;
          background: ${colors[themeColors.backgroundColor as keyof Colors]};
          padding: 20px;
          border-radius: 16px;
          width: 100%;
          max-height: 90vw;
          overflow-y: auto;
          ${mediaQueries.desktop} {
            max-height: 476px;
          }
        `
      )}
      flexDirection="column"
    >
      <FlexBox
        className={css`
          margin-bottom: 6px;
        `}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Icon
          size="28px"
          type="quote"
          fill={colors[themeColors.quoteColor as keyof Colors]}
        />
      </FlexBox>
      <QuoteViewText text={text} />
      <FlexBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <FlexBox
          className={css`
            width: 100%;
            padding-right: 20px;
            gap: 3px;
          `}
          flexDirection="column"
        >
          <Text
            className={css`
              cursor: inherit;
            `}
            type="link"
            sizing="M"
            color={colors[themeColors.bookTitleColor as keyof Colors]}
            tag="h3"
            align="left"
            truncate={2}
          >
            <b>{book?.title}</b>
          </Text>
          <Text
            className={css`
              cursor: inherit;
            `}
            type="body"
            sizing="S"
            color={colors[themeColors.authorColor as keyof Colors]}
            align="left"
            tag="h4"
            truncate={2}
          >
            By {book?.authors?.[0].name}
          </Text>
        </FlexBox>
        <FlexBox
          className={css`
            flex-shrink: 0;
            width: 60px;
            height: 60px;
            background-color: ${colors[
              themeColors.bookBackgroundColor as keyof Colors
            ]};
            border-radius: 10px;
            overflow: hidden;
          `}
          justifyContent="center"
          alignItems="flex-end"
        >
          {bookImage?.src ? (
            <BookImage
              className={css`
                width: 40px;
                transform: translateY(10px);
              `}
              size={55}
              image={bookImage.src}
            />
          ) : null}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default QuoteView
