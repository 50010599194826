import { css, theme } from '@fable/theme'
import { getCmsAsset } from '@fable/utils'
import {
  HomeFeaturesHeader,
  HomeFeaturesIllo,
  PopularClubsCarousel,
} from 'components/home_page/components'
import { HomeFeaturesProps } from 'components/home_page/lib/types'
import { InviteCta } from './InviteCta'

const organize: HomeFeaturesProps = {
  id: 'organize',
  header: (
    <HomeFeaturesHeader
      label="Get organized"
      title="Track your books in one place"
      description="Stay organized with beautiful lists, reading goals, and daily streaks that encourage you to read more."
      titleStyles={css`
        ${theme.mediaQueries.tablet} {
          max-width: 580px;
        }
      `}
      button={<InviteCta />}
    />
  ),
  illo: (
    <HomeFeaturesIllo
      className={css`
        margin-top: 20px;
        ${theme.mediaQueries.mobileL} {
          margin-top: 0;
        }
        z-index: 1;
      `}
      image={getCmsAsset(
        'UPGAWXPRbWGRvXSAV4X2z/902e729d0be6894922af1956ee564816/person-and-floating-shapes.png',
        { width: 1600 }
      )}
      altText="person and floating shapes"
    />
  ),
  scrollingCards: [
    {
      titleStyles: css`
        max-width: 200px;
      `,
      title: 'Build a habit with streaks',
      image: getCmsAsset(
        '2UYLAZnNR5P4YDhms8l9XB/b23d89bf091b437e587c06c59b362cd2/fable-stats-widget.webp'
      ),
      altText: 'fable stats widget',
    },
    {
      containerStyles: css`
        overflow: visible;
      `,
      titleStyles: css`
        max-width: 200px;
      `,
      title: 'Import your goodreads',
      image: getCmsAsset(
        '6QHbgQRkS9aqk5Nk5IBeIf/7d719a8977efb45b37a7053daa0d1ec0/import-goodreads.webp'
      ),
      altText: 'fable goodreads import icons',
    },
  ],
  slidesControlOuter: true,
  blockStyles: css`
    padding-bottom: 0 !important;
  `,
  slides: {
    mobile: [
      {
        title: 'Build better book lists',
        titleStyles: css`
          max-width: 160px;
          ${theme.mediaQueries.mobileL} {
            max-width: 200px;
          }
        `,
        imageStyles: css`
          width: 90%;
        `,
        image: getCmsAsset(
          '1X6gD54NnbYTs2om5WuLwf/ded306141afb700aee429295ea7b7fc8/want-to-read-list-mobile.webp'
        ),
        altText: 'want to read list preview',
      },
      {
        title: 'Import your goodreads',
        titleStyles: css`
          max-width: 180px;
          ${theme.mediaQueries.mobileL} {
            max-width: 290px;
          }
        `,
        imageStyles: css`
          width: 90%;
        `,
        image: getCmsAsset(
          '7A5LlmSYrFmEuykQwDSCjv/8de568d78c9ea4f1b71dd79d77b312ab/goodreads-import-mobile.webp'
        ),
        altText: 'goodreads import icons',
      },
      {
        title: 'Build a habit with streaks',
        titleStyles: css`
          max-width: 180px;
          ${theme.mediaQueries.mobileL} {
            max-width: 290px;
          }
        `,
        imageStyles: css`
          width: 90%;
        `,
        image: getCmsAsset(
          'npACIGNW76mjRaG0Lm8lq/568f68b6b88cfda8c2b89d47345b6846/streaks-mobile.webp'
        ),
        altText: 'streaks preview',
      },
    ],
    desktop: {
      title: 'Build better Book lists',
      titleStyles: css`
        max-width: 200px;
      `,
      images: [
        {
          imageStyles: css`
            margin: 0 auto -34px;
            width: 80%;
          `,
          image: getCmsAsset(
            '1BCgXC0K00F6U6QPZW6Fpd/6f3712dfb440c14ca0106d7a32be74be/want-to-read-list-mobile-large.webp',
            { width: 1800 }
          ),
          altText: 'want to read list preview',
        },
      ],
    },
  },
}

const discover: HomeFeaturesProps = {
  id: 'discover',
  reverse: true,
  header: (
    <HomeFeaturesHeader
      label="Reader insights"
      title="Visualize your reading life"
      description="Get a personalized reader summary that evolves with every book, along with insights that bring your shelves to life."
      button={<InviteCta />}
    />
  ),
  illo: (
    <HomeFeaturesIllo
      className={css`
        margin-bottom: -48px;
        ${theme.mediaQueries.mobileS} {
          margin-bottom: -58px;
        }
        ${theme.mediaQueries.mobileL} {
          margin-bottom: -48px;
        }
        ${theme.mediaQueries.tablet} {
          margin-bottom: -68px;
        }
      `}
      image={getCmsAsset(
        'iIzmkPWubiIM7T0cSU5mR/a32dc86d95c3a39dbdced0ce4a4036f2/person-with-bird-and-stars.png',
        { width: 1600 }
      )}
      altText="person sitting with bird and stars"
    />
  ),
  scrollingCards: [
    {
      titleStyles: css`
        max-width: 180px;
      `,
      title: 'See your most-read genres',
      image: getCmsAsset(
        'TYArRORsjlZkqxDffkBnB/5f39768d8b314cd8d4d8251635904951/stats-genres-card.webp'
      ),
      altText: 'stats most read genres',
    },
    {
      titleStyles: css`
        max-width: 180px;
      `,
      title: 'Share your monthly recap',
      image: getCmsAsset(
        '1Qxfn67ACyeo7ZC2fk5JWs/c097bc8734001bdabd0f5fd91c7c4680/stats-monthly-books-card.webp'
      ),
      altText: 'stats monthly books',
      position: { start: 400, end: -50 },
    },
  ],
  slidesControlOuter: true,
  blockStyles: css`
    padding-bottom: 0 !important;
  `,
  slides: {
    mobile: [
      {
        title: 'Discover your unique reader summary',
        titleStyles: css`
          max-width: 200px;
          ${theme.mediaQueries.mobileL} {
            max-width: 320px;
          }
        `,
        image: getCmsAsset(
          '2MJrHMLr9ZEuLqQHC4BnH1/16403ea3f5ce47d98bb4692837ac2988/stats-reading-goal.webp'
        ),
        imageStyles: css`
          width: 90%;
        `,
        altText: 'stats reading goal',
      },
      {
        title: 'Share your monthly recap',
        titleStyles: css`
          max-width: 180px;
          ${theme.mediaQueries.mobileL} {
            max-width: 230px;
          }
        `,
        image: getCmsAsset(
          'SBa3hgEkd6azIUieiucYN/899135e33e26abcd53e79aef41e030dc/stats-monthly-books.webp'
        ),
        imageStyles: css`
          width: 90%;
        `,
        altText: 'stats monthly books',
      },
      {
        title: 'See your most-read genres',
        titleStyles: css`
          max-width: 180px;
          ${theme.mediaQueries.mobileL} {
            max-width: 280px;
          }
        `,
        image: getCmsAsset(
          '6hqS3xbXsZlShjC3cmaDpO/3136c8d8a2a0bb7a76ac2502dbf911b6/stats-most-read-genres.webp'
        ),
        imageStyles: css`
          width: 90%;
        `,
        containerStyles: css`
          overflow: visible;
        `,
        altText: 'stats most read genres',
      },
    ],
    desktop: {
      title: 'Discover your unique reader summary',
      titleStyles: css`
        max-width: 320px;
      `,
      images: [
        {
          imageStyles: css`
            margin: 0 auto -34px;
            width: 80%;
          `,
          image: getCmsAsset(
            '1dMGczZy2eSaNOrDk61C9a/83409937e063c79cf857ce7f3a913a0f/stats-reading-goal-large.webp',
            { width: 1800 }
          ),
          altText: 'stats reading goal',
        },
      ],
    },
  },
}

const discuss: HomeFeaturesProps = {
  id: 'discuss',
  header: (
    <HomeFeaturesHeader
      label="Meet new friends"
      title="Community for story lovers"
      description="Join clubs or share posts to rabbit hole with fellow bookworms."
      button={<InviteCta />}
    />
  ),
  illo: (
    <HomeFeaturesIllo
      className={css`
        z-index: 2;
        margin-top: 20px;
        margin-bottom: -62%;
        width: 120%;
        right: -4%;
        position: relative;
        ${theme.mediaQueries.mobileL} {
          transform: scale(-1, 1);
          margin-bottom: -36%;
          width: 70%;
          right: -12%;
          left: auto;
        }
        ${theme.mediaQueries.tablet} {
          transform: scale(1);
          margin-bottom: -45.5%;
          left: -15%;
          align-self: flex-start;
          width: 88%;
          object-fit: contain;
          object-position: bottom;
        }
        ${theme.mediaQueries.desktopL} {
          max-width: 523px;
        }
      `}
      image={getCmsAsset(
        '3smdwTwU6DHhTo9tyOAElT/b5ac8699890d874a7fd32efe82af0cff/person-sitting-looking-at-phone.png',
        { width: 1600 }
      )}
      altText="person sitting looking at phone"
    />
  ),
  scrollingCards: [
    {
      title: 'Dedicated feeds all about books',
      titleStyles: css`
        max-width: 200px;
      `,
      image: getCmsAsset(
        '3hrlRp0kQjtekLMhMK6rfL/418bb0790aa19e086a4fa3ab40e7fea2/post-by-cameron-capello.webp'
      ),
      altText: 'post by cameron capello',
    },
    {
      title: 'Connect with similar readers',
      titleStyles: css`
        max-width: 200px;
      `,
      image: getCmsAsset(
        '786UIrIxWQxH0e3U8fjVZo/c4e6790cc4bf5a5a083111787b9de441/club-lobby.webp'
      ),
      altText: 'club lobby',
    },
  ],
  slides: {
    mobile: [
      {
        title: 'Spoiler-free discussion rooms',
        image: getCmsAsset(
          'h5C8PhEbERjueaFrQWO50/ab950b93aa2f36d870b61d4de721117c/milestones.webp'
        ),
        altText: 'milestones',
        titleStyles: css`
          max-width: 200px;
          ${theme.mediaQueries.mobileL} {
            max-width: 310px;
          }
        `,
      },
      {
        title: 'Dedicated feeds all about books',
        image: getCmsAsset(
          '3hrlRp0kQjtekLMhMK6rfL/418bb0790aa19e086a4fa3ab40e7fea2/post-by-cameron-capello.webp'
        ),
        altText: 'post by cameron capello',
      },
      {
        title: 'Connect with similar readers',
        image: getCmsAsset(
          '786UIrIxWQxH0e3U8fjVZo/c4e6790cc4bf5a5a083111787b9de441/club-lobby.webp'
        ),
        altText: 'club lobby',
      },
    ],
    desktop: {
      title: 'Spoiler-free discussion rooms',
      images: [
        {
          imageStyles: css`
            margin: 0 auto;
            padding-bottom: 34px;
            width: 80%;
          `,
          image: getCmsAsset(
            '1yyDejJyoowfrkyp4PdBun/2bd29d3ebf9564457deb77bc1815b592/group-chat.webp',
            { width: 1800 }
          ),
          altText: 'group chat',
        },
      ],
    },
  },
  footer: <PopularClubsCarousel />,
}

export const featuresMap = [organize, discover, discuss]
