import { BuilderComponent, Builder } from '@builder.io/react'
import { Loader } from '@fable/components'
import { useLocation } from 'react-router-dom'
import { useErrorModal, useFooter, useHeader } from '../../../hooks'
import { useBuilder } from './useBuilder'
import { loadCustomComponents } from './components'
import { Redirect } from '@fable/legacy'
import { useCallback, useEffect } from 'react'

const BuilderPage = () => {
  const { pathname } = useLocation()
  const model = 'page'
  const options = { userAttributes: { urlPath: pathname } }
  const { data: content, error, isFetched } = useBuilder({ model, options })
  const setHeader = useHeader((s) => s.setConfig)
  const setFooter = useFooter((s) => s.setConfig)

  const updateHeader = useCallback(() => {
    if (!content?.data) return
    setHeader({ hidden: !content?.data?.header })
  }, [content?.data, setHeader])

  const updateFooter = useCallback(() => {
    if (!content?.data) return
    setFooter({ hidden: !content?.data?.footer })
  }, [content?.data, setFooter])

  useEffect(() => {
    updateHeader()
    updateFooter()
  }, [updateHeader, updateFooter])

  useErrorModal(error as Error)

  loadCustomComponents()

  if (isFetched && !content) {
    return <Redirect to="/404" />
  }

  if (!Builder.isEditing && !Builder.isPreviewing && !content) {
    return <Loader />
  }

  return <BuilderComponent model={model} content={content} />
}

export default BuilderPage
