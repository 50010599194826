import { useEffect, useRef, useState } from 'react'
import { CompProps } from 'types'
import { Gradient } from './Gradient'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { css, cx } from '@fable/theme'

interface Props extends React.HTMLProps<HTMLDivElement> {
  overflows?: boolean
  containerClassName?: string
  gradientColor?: string
  triggerScroll?: boolean
}

export const ScrollContainer = ({
  children,
  overflows = false,
  className,
  containerClassName = '',
  gradientColor,
  triggerScroll = false,
  ...nativeProps
}: CompProps<Props>) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({ container: scrollRef })
  const [scrollEnd, setScrollEnd] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useMotionValueEvent(scrollYProgress, 'change', (pos) => {
    if (!overflows) return

    setScrolled(pos !== 0)
    setScrollEnd(pos === 1)
  })

  useEffect(() => {
    if (!scrollRef.current) return

    if (triggerScroll) {
      const height = scrollRef.current.scrollHeight
      scrollRef.current.scrollTo({ behavior: 'smooth', top: height })
    }
  }, [triggerScroll])

  return (
    <div
      className={cx(
        css`
          position: relative;
        `,
        containerClassName
      )}
    >
      {overflows ? (
        <Gradient
          className={css`
            height: 100px;
            opacity: ${scrolled ? 1 : 0};
            top: 0;
            bottom: auto;
            z-index: 2;
          `}
          direction="top"
          color={gradientColor}
        />
      ) : null}
      <div
        ref={scrollRef}
        className={cx(
          css`
            max-height: 362px;
            padding: 0 24px;
            overflow-y: auto;
            overscroll-behavior: contain;
          `,
          className
        )}
        {...nativeProps}
      >
        {children}
      </div>
      {overflows ? (
        <Gradient
          className={css`
            height: 100px;
            opacity: ${scrollEnd ? 0 : 1};
            z-index: 2;
          `}
          color={gradientColor}
        />
      ) : null}
    </div>
  )
}
