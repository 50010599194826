import { Text } from '@fable/components'
import React from 'react'
import handleDeeplink from '../../../utils/handleDeeplink'

const UniversalLinkTest = () => {
  return (
    <div
      style={{
        height: 700,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
      }}
    >
      <div>
        <button
          onClick={() =>
            handleDeeplink(
              'https://universal.fable.co/app/read/811de79f-53e5-40b9-9e76-677d3a5db3da'
            )
          }
        >
          Open by setting location.href
        </button>
      </div>
      <Text type="subhead" sizing="S" tag="h3">
        <a href="https://universal.fable.co/app/read/811de79f-53e5-40b9-9e76-677d3a5db3da">
          Open with a regular link
        </a>
      </Text>
    </div>
  )
}

export default UniversalLinkTest
