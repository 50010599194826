import {
  getErrorMessage,
  getInviteParams,
  formatFirebaseErrorMessage,
} from 'app/auth/utils'
import { useEffect, useRef, useState } from 'react'
import {
  FieldErrors,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form'
import {
  CompanyCapabilities,
  CompanyInvite,
  SignUpFormInput,
} from '@fable/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { FirebaseError } from '@firebase/util'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'
import { NO_USER_TEXT } from '../constants'
import { useGeo } from './useGeo'
import { signupValidationSchema } from 'utils'
import { requests } from '@fable/api'

type FormInput = SignUpFormInput

export interface UserSignup {
  invite: CompanyInvite | null
  errorMessage?: string
  errors: FieldErrors
  inviteParams?: {
    hasInvite?: boolean
    hasPersonalInvite?: boolean
    emailPattern?: string
    capabilities?: CompanyCapabilities
    companyName?: string
    termsAgreementText?: string
    hasAllowList?: boolean
  }
  gdprCountry: boolean
  register: any
  watch: any
  clearServerErrors: () => void
  setValue: any
  handleSubmit: any
}

export const useSignup = (params?: {
  invite: CompanyInvite | null
  onSubmit?: () => void
  defaultValues?: FormInput
}): UserSignup => {
  const registered = useRef(false)
  const [apiError, setApiError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { clearSignUp, signUp } = useActions()
  const { userSignUp } = useTypedSelector(({ auth }) => auth)
  const { gdprCountry } = useGeo()
  const inviteParams = getInviteParams(params?.invite)

  const { hasPersonalInvite, emailPattern, companyName } = inviteParams

  const formOptions = (): UseFormProps<FormInput> => {
    const obj: { [key: string]: any } = {
      resolver: yupResolver(signupValidationSchema(emailPattern)),
    }

    if (params?.defaultValues) obj.defaultValues = params.defaultValues

    return obj
  }

  const formHook = useForm<FormInput>(formOptions())

  const clearServerErrors = () => {
    clearSignUp()
    if (errorMessage) setErrorMessage('')
    if (apiError) setApiError(false)
  }

  const handleSignUp: SubmitHandler<FormInput> = async (values: FormInput) => {
    const { email, password, name, emailOptIn } = values
    const optIn = gdprCountry ? emailOptIn : true

    if (params?.invite?.company && params?.invite?.has_allow_list) {
      try {
        await requests.getClubInvite({
          invite: params.invite,
          email,
        })
      } catch (error: unknown) {
        setErrorMessage((error as Error).message)
        return
      }
    }

    if (hasPersonalInvite && params?.invite?.email) {
      signUp(params.invite.email, password, name, optIn)
      return
    }

    signUp(email, password, name, optIn)

    if (!!params?.onSubmit) params.onSubmit()
  }

  useEffect(() => {
    let message = ''

    if (apiError && userSignUp.error?.message) {
      message = formatFirebaseErrorMessage(userSignUp?.error as FirebaseError)
    } else {
      message = getErrorMessage(formHook.formState.errors, companyName) || ''
    }

    if (message) {
      setErrorMessage(message)
    }
  }, [companyName, formHook.formState.errors, apiError, userSignUp.error])

  useEffect(() => {
    if (hasPersonalInvite) {
      formHook.setValue('name', params?.invite?.display_name || '')
      formHook.setValue('email', params?.invite?.email || '')
    }
  }, [
    hasPersonalInvite,
    formHook,
    params?.invite?.display_name,
    params?.invite?.email,
  ])

  // check if error occurred signing up
  useEffect(() => {
    if (!!userSignUp.error && userSignUp.error?.message !== NO_USER_TEXT) {
      setApiError(true)
    }

    return () => {
      if (apiError) {
        clearSignUp()
        setApiError(false)
      }
    }
  }, [apiError, clearSignUp, userSignUp.error])

  useEffect(() => {
    if (registered.current) return

    registered.current = true
    formHook.register('name')
    formHook.register('email')
    formHook.register('password')
    formHook.register('termsAgree')
    formHook.register('emailOptIn')
  }, [formHook])

  return {
    invite: params?.invite || null,
    errorMessage,
    errors: formHook.formState.errors,
    inviteParams,
    gdprCountry,
    watch: formHook.watch,
    register: formHook.register,
    clearServerErrors,
    setValue: formHook.setValue,
    handleSubmit: formHook.handleSubmit(handleSignUp),
  }
}
