import { gql } from '@apollo/client'

import { FRAGMENT_IMAGE } from './image'
import { isPreview } from 'services/contenful'

export const FRAGMENT_PERSON_INFO = gql`
  fragment PersonInfo on Person {
    name
    bio {
      json
    }
    avatar {
      ...Image
    }
    handle
    fableUuid
    slug
    seoTitle
    seoDescription
    instagramUrl
    twitterUrl
    linkedinUrl
    facebookUrl
    tiktokUrl
    youtubeUrl
    websiteUrl
  }
  ${FRAGMENT_IMAGE}
`

export const GET_PERSON_ID = gql`
  query ($slug: String!) {
    personCollection(where: { slug: $slug }, preview: ${isPreview}) {
      items {
        sys {
          id
        }
      }
    }
  }
`

export const GET_PERSON = gql`
  query ($id: String!) {
    person(id: $id, preview: ${isPreview}) {
      ...PersonInfo
    }
  }
  ${FRAGMENT_PERSON_INFO}
`
