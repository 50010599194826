import { css, cx, useTheme } from '@fable/theme'
import { FlexBox } from '@fable/components'

export const HeaderSideMenuButton: React.VFC<{
  onClick: () => void
  open: boolean
  secondary: boolean
}> = ({ onClick, open, secondary }) => {
  const { colors, mediaQueries, motion } = useTheme()

  const closeStyles = css`
    span {
      &:first-child {
        transform: translateY(7px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(-10px);
      }

      &:last-child {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  `

  return (
    <FlexBox
      className={cx(
        css`
          width: 18px;
          height: 16px;
          position: absolute;
          top: 22px;
          left: 18px;
          z-index: 10000;
          cursor: pointer;
          span {
            width: 100%;
            height: 2px;
            background-color: ${!secondary || open
              ? colors.blackSwan
              : colors.whiteFang};
            ${motion.transition(['transform', 'opacity'])}
          }
          ${mediaQueries.mobileL} {
            &:hover span {
              opacity: 0.7;
            }
          }
          ${mediaQueries.tablet} {
            display: none;
          }
        `,
        open && closeStyles
      )}
      flexDirection="column"
      justifyContent="space-between"
      onClick={onClick}
    >
      <span />
      <span />
      <span />
    </FlexBox>
  )
}
