import { TextStyleProps, TextStyleMapping } from '../types'

const baseStyles: TextStyleProps = {
  fontFamily: `'Inter', sans-serif`,
}

const styles: TextStyleMapping = {
  L: {
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '-0.25px',
  },
  M: {
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '-0.25px',
  },
  S: {
    fontSize: '10px',
    lineHeight: '10px',
    letterSpacing: '0px',
  },
}

export const linkStyles = { styles, baseStyles }
