import PaginationContainerFullPage from './PaginationContainerFullPage'
import { PaginationContainerOptions } from './types'
import usePaginationResults from './usePaginationResults'
import { createContext } from 'react'
import { PaginationContainerResults } from './types'

export const PaginationContainerContext = createContext(
  {} as PaginationContainerResults
)
PaginationContainerContext.displayName = 'PaginationContainerContext'

export const PaginationContainer = (
  props: PaginationContainerOptions & {
    scrollLevel: 'fullPage'
    children: React.ReactNode
  }
) => (
  <PaginationContainerContext.Provider value={usePaginationResults(props)}>
    {props.scrollLevel === 'fullPage' ? (
      <PaginationContainerFullPage {...props}>
        {props.children}
      </PaginationContainerFullPage>
    ) : null}
  </PaginationContainerContext.Provider>
)
