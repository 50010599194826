import { UserPost } from '@fable/types'

export const getPostUserProps = ({ post }: { post: UserPost }) => {
  const getLink = () => {
    if (!isPostWithObj(post)) return

    if (!!post.quotes?.length) {
      return {
        title: post.quotes[0].book.title,
        url: post.quotes[0].book.url,
      }
    }

    if (!!post.books?.length) {
      return {
        title: post.books[0].title,
        url: post.books[0].url,
      }
    }

    if (!!post.club) {
      return {
        title: post.club.title,
        url: post.club.url,
      }
    }
  }

  return {
    name: post.author.display_name as string,
    image: post.author?.pic as string,
    createdAt: post.created_at,
    url: post.author?.url as string,
    label: post?.activity_text,
    link: getLink(),
  }
}

export const isPostWithObj = (post: UserPost) =>
  post.activity_text.includes(':')
