import { useCallback, useEffect, useRef, useState } from 'react'
import TimeAgo, { FormatStyleName } from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

interface Props {
  date: string
  style?: FormatStyleName
  hideSuffix?: boolean
}

export const useTimeAgo = ({
  date,
  style = 'mini-minute-now',
  hideSuffix = false,
}: Props) => {
  const timeInterval = useRef<NodeJS.Timeout | null>(null)
  const timeAgo = useRef<TimeAgo | null>(null)
  const [time, setTime] = useState('')

  const tick = useCallback(() => {
    if (!timeAgo.current) return
    const timeFormatted = timeAgo.current.format(new Date(date || ''), style)
    const suffix = timeFormatted === 'now' ? '' : ' ago'
    const timeString = `${timeFormatted}${hideSuffix ? '' : suffix}`

    setTime(timeString)
  }, [date, style, hideSuffix])

  useEffect(() => {
    TimeAgo.addLocale(en)
    const TimeAgoInstance = new TimeAgo('en-US')
    timeAgo.current = TimeAgoInstance
  }, [])

  useEffect(() => {
    if (!time) {
      tick()
    }

    timeInterval.current = setInterval(() => tick(), 30000)

    return () => {
      if (timeInterval.current) clearInterval(timeInterval.current)
    }
  }, [tick, time])

  return time
}
