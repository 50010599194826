import { IconSVGProps } from '../types'

export const PantoneIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.3338 15.7684L2.2088 15.3142C2.00392 15.2313 1.84036 15.0704 1.75409 14.867C1.66783 14.6635 1.66592 14.4341 1.7488 14.2292L3.3338 10.3059V15.7684ZM7.3838 17.5H5.8338C5.61278 17.5 5.40082 17.4122 5.24454 17.2559C5.08826 17.0997 5.00046 16.8877 5.00046 16.6667V11.6017L7.3838 17.5ZM5.0188 4.97336L12.7455 1.85169C12.9502 1.76908 13.1794 1.77112 13.3827 1.85737C13.586 1.94362 13.7467 2.10702 13.8296 2.31169L18.513 13.9017C18.554 14.0032 18.5746 14.1118 18.5736 14.2212C18.5727 14.3307 18.5501 14.4389 18.5073 14.5397C18.4645 14.6404 18.4023 14.7318 18.3242 14.8085C18.2461 14.8852 18.1536 14.9457 18.0521 14.9867L10.3255 18.1084C10.224 18.1494 10.1154 18.17 10.0059 18.169C9.89645 18.1681 9.78825 18.1455 9.68749 18.1027C9.58673 18.0599 9.49539 17.9977 9.41869 17.9196C9.34198 17.8415 9.28142 17.749 9.24046 17.6475L4.5588 6.05836C4.47623 5.85347 4.47839 5.62419 4.5648 5.42089C4.65121 5.21759 4.81481 5.05693 5.01963 4.97419L5.0188 4.97336ZM7.50046 7.50003C7.72148 7.50003 7.93344 7.41223 8.08972 7.25595C8.246 7.09967 8.3338 6.88771 8.3338 6.6667C8.3338 6.44568 8.246 6.23372 8.08972 6.07744C7.93344 5.92116 7.72148 5.83336 7.50046 5.83336C7.27945 5.83336 7.06749 5.92116 6.91121 6.07744C6.75493 6.23372 6.66713 6.44568 6.66713 6.6667C6.66713 6.88771 6.75493 7.09967 6.91121 7.25595C7.06749 7.41223 7.27945 7.50003 7.50046 7.50003Z" />
  </svg>
)
