import { Button, FlexBox, Logo, Text } from '@fable/components'
import { AppDeepLinkModalProps } from './types'
import { handleDeeplink } from 'utils'
import { css, useTheme } from '@fable/theme'
import { analytics } from 'segment_analytics'
import { useActions, useTypedSelector } from 'hooks'
import { FORM_TYPES } from 'app/auth/constants'

export const AppDeepLinkModalMobile = ({
  link,
  title,
  description,
  eventMetadata,
  hideAuth,
}: AppDeepLinkModalProps) => {
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { openAuthModal, closeModal } = useActions()
  const { colors, nativeOverrides } = useTheme()

  const handleOnClick = () => {
    analytics.events.appDownload(eventMetadata)
    handleDeeplink(link)
    closeModal()
  }

  const handleOpenAuth = (type: 'signup' | 'signin') => {
    closeModal()
    const { SIGN_IN, SIGN_UP } = FORM_TYPES
    openAuthModal(type === 'signup' ? SIGN_UP : SIGN_IN)
  }

  return (
    <FlexBox
      className={css`
        padding: 16px 24px 52px;
      `}
      justifyContent="center"
    >
      <FlexBox
        className={css`
          max-width: 300px;
          width: 100%;
        `}
        flexDirection="column"
        alignItems="center"
        gap="20px"
      >
        <FlexBox
          className={css`
            width: 48px;
            height: 48px;
            background-color: ${colors.fableGreenDarker};
            border-radius: 14px;
          `}
          justifyContent="center"
          alignItems="center"
        >
          <Logo
            className={css`
              width: 36px;
              svg {
                width: 100%;
              }
            `}
            isIcon
          />
        </FlexBox>
        <FlexBox flexDirection="column" alignItems="center" gap="4px">
          <Text
            type="subhead"
            sizing="S"
            tag="h3"
            align="center"
            color={colors.uiGraphite}
          >
            {title}
          </Text>
          {!description?.length ? null : (
            <Text
              type="body"
              sizing="M"
              align="center"
              color={colors.uiGraphiteLight}
            >
              {description}
            </Text>
          )}
        </FlexBox>
        <Button width="100%" onClick={handleOnClick}>
          Get the Fable app
        </Button>
        {!isAuthenticated && !hideAuth ? (
          <FlexBox alignItems="center" gap="3px">
            <Button
              className={css`
                ${nativeOverrides.buttonClear};
                color: ${colors.uiBlue};
                &:hover {
                  color: ${colors.uiBlue};
                }
              `}
              onClick={() => handleOpenAuth('signin')}
              label="Log in"
            />
            <Text type="body" sizing="M" color={colors.uiPewterGrey}>
              or
            </Text>
            <Button
              className={css`
                ${nativeOverrides.buttonClear};
                color: ${colors.uiBlue};
                &:hover {
                  color: ${colors.uiBlue};
                }
              `}
              onClick={() => handleOpenAuth('signup')}
              label="Sign up"
            />
          </FlexBox>
        ) : null}
      </FlexBox>
    </FlexBox>
  )
}
