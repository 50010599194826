import { ModalActions } from '../actions'
import { ModalTypes } from '../action-types'
import { ModalState } from '../../types/modal'

const initialState: ModalState = {
  authType: '',
  content: null,
  isAuth: false,
  isOpen: false,
  path: '',
  type: '',
  preventClickOutside: false,
  isSignoutCountdown: false,
}

const reducer = (
  state: ModalState = initialState,
  action: ModalActions
): ModalState => {
  switch (action.type) {
    // MODAL - OPEN AUTH
    case ModalTypes.MODAL_AUTH__OPEN:
      return {
        ...state,
        authType: action.payload.authType,
        isAuth: true,
        isOpen: true,
        path: action.payload.path,
        options: action.payload.options,
      }

    // MODAL - OPEN
    case ModalTypes.MODAL__OPEN:
      return {
        ...state,
        isOpen: true,
        content: action.payload.content,
        type: action.payload.modalType,
        preventClickOutside: action.payload.preventClickOutside,
        isSignoutCountdown: action.payload.isSignoutCountdown,
        options: action.payload.options,
      }

    // MODAL - CLOSE
    case ModalTypes.MODAL__CLOSE:
      return { ...state, isOpen: false }

    // MODAL - CLEAR
    case ModalTypes.MODAL__CLEAR:
      return {
        ...state,
        authType: '',
        content: null,
        isAuth: false,
        isOpen: false,
        path: '',
        type: '',
      }

    default:
      return state
  }
}

export default reducer
