import { useEffect, useRef, useState } from 'react'
import { cx, css } from '@fable/theme'
import { useDeviceDetect, useClickOutside } from '@fable/hooks'
import TextInput from '../TextInput'
import {
  DropdownProps,
  DropdownPropsItem,
  DropdownPropsItemWithSearch,
} from '../types'
import Icon from '../icon'
import DropdownContext from './DropdownContext'
import DropdownMobileList from './DropdownMobileList'
import DropdownDesktopList from './DropdownDesktopList'
import getInputTheme from '../utils/getInputTheme'

const Dropdown: React.FC<DropdownProps & React.HTMLProps<HTMLDivElement>> = (
  props
) => {
  const {
    items,
    maxLength,
    value,
    onChangeText,
    placeholder,
    theme = 'light',
    disabled,
    label,
    error,
    name,
    autoComplete,
    searchable,
    mobileListName,
    onBlur,
  } = props
  const { isMobile } = useDeviceDetect()
  const inputRef = useRef<HTMLInputElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const textInputProps = {
    maxLength,
    value,
    onChangeText,
    placeholder,
    theme,
    disabled,
    label,
    error,
    name,
    autoComplete,
    onBlur,
  }
  const { textColor, placeholderColor } = getInputTheme(theme)
  const [showDropdown, setShowDropdown] = useState(false)
  /**
   * itemsFiltered is a ref because every time it is updated
   * onChangeText is called, which the parent component should use to re-render
   */
  const itemsFiltered = useRef(items)
  const autofilled = useRef(false)

  const mobileReturnMatchingItem = ({
    items,
    onChangeText,
    text,
  }: {
    items: DropdownPropsItem[]
    onChangeText?: (s: string) => void
    text?: string
  }) => {
    const match = items.find((item) => item.value === text)

    if (match) {
      if (match.onClick) {
        match.onClick(match)
      }
      if (onChangeText) onChangeText(match.text)
    } else if (onChangeText) {
      onChangeText(text || '')
    }
  }

  const handleInput = (text: string) => {
    if (!isMobile) {
      if (!showDropdown) {
        setShowDropdown(true)
      }
      if (!text?.length) {
        itemsFiltered.current = items
      } else {
        // Filters down the list while searching
        itemsFiltered.current = (items as DropdownPropsItemWithSearch[]).filter(
          (item) =>
            item.keywords?.some(
              (keyword) =>
                keyword.toLowerCase().substring(0, text.length) ===
                text.toLowerCase()
            )
        )
      }

      if (onChangeText) onChangeText(text)
      if (autofilled.current) setShowDropdown(false)
      autofilled.current = false
    }
  }

  const handleFocus = () => {
    if (disabled) return

    if (!showDropdown) {
      setShowDropdown(true)
    }
  }

  useClickOutside(ref, () => setShowDropdown(false))

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute('list', mobileListName)
    }
  }, [mobileListName])

  useEffect(() => {
    if (!autofilled.current) {
      // via detect-autofill package
      document.addEventListener('onautocomplete', () => {
        autofilled.current = true
      })
    }
  }, [])

  useEffect(() => {
    if (items?.length && !itemsFiltered.current?.length) {
      itemsFiltered.current = items
    }
    if (!items?.length && !!itemsFiltered.current?.length) {
      itemsFiltered.current = []
    }
  }, [items])

  return (
    <DropdownContext.Provider
      value={{
        ...props,
        setShowDropdown,
        showDropdown,
        inputRef,
        itemsFiltered: itemsFiltered.current,
        mobileReturnMatchingItem,
      }}
    >
      <div ref={ref}>
        <TextInput
          {...textInputProps}
          inputRef={inputRef}
          onChangeText={handleInput}
          disabled={disabled}
          value={value}
          onFocus={handleFocus}
          containerClass={cx(
            css`
              position: relative;
              input {
                &::-webkit-calendar-picker-indicator,
                &::-webkit-list-button {
                  opacity: 0;
                }
                // must be above the dropdown list
                // without covering another dropdown rendered above it
                z-index: ${showDropdown ? '2' : '0'};
              }
            `,
            !searchable &&
              css`
                * {
                  cursor: pointer;
                  user-select: none;
                }
                input,
                input::placeholder {
                  color: ${textColor} !important;
                  // hide the caret while leaving the input enabled. See comment below.
                  caret-color: transparent;
                }
              `,
            props.className
          )}
        >
          <>
            <Icon
              fill={placeholderColor}
              className={css`
                position: absolute;
                right: max(20px, 1.66vw);
                width: 10px;
                z-index: ${showDropdown ? '2' : '0'};
              `}
              type="chevron"
            />
            {isMobile ? <DropdownMobileList /> : <DropdownDesktopList />}
          </>
        </TextInput>
      </div>
    </DropdownContext.Provider>
  )
}

export default Dropdown
