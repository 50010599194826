import {
  useRef,
  useEffect,
  useState,
  createContext,
  ReactNode,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useInfiniteQuery, useQuery, UseQueryResult } from 'react-query'
import { get, requests } from '@fable/api'
import { sortGenres, createUrlSearchParams } from '@fable/utils'
import { Book, Club } from '@fable/types'
import { routes } from 'app/clubs/routes'
import { useErrorModal } from 'hooks'
import { analytics } from 'segment_analytics'
import { ENVIRONMENT } from '../../constants'

export interface GenreItem {
  id: string
  name: string
}

export interface ClubQuizContextType {
  loading: boolean
  resultsQuery: UseQueryResult<{ data: Club[] }, unknown> | null
  allowNext: boolean
  nextRoute: string
  genreSelections: { [key: string]: string }
  selectedBookIds: string[]
  nextUrlSearchParams: string
  minSelections: number
  genres: GenreItem[] | null
  books: Book[] | null
  results: Club[] | null
  popularClubs: Club[] | null
  setGenreSelection: (args: GenreItem) => void
  setSelectedBookId: (bookId: string) => void
  getNextBooksPage: () => void
}

// exported for testing
export const useClubQuizContext = () => {
  const { pathname, search } = useLocation()

  const minSelections = useRef(5)
  const booksQueryOffset = useRef(0)
  const genreIdKey = useRef('genre_id')
  const bookIdKey = useRef('book_id')

  const [searchParamsString, setSearchParamsString] = useState(
    new URLSearchParams(search).toString()
  )
  const [genreSelections, setGenreSelections] = useState<{
    [key: string]: string
  }>({})
  const [genres, setgenres] = useState<GenreItem[]>([])
  const [selectedBookIds, setSelectedBookIds] = useState<string[]>([])

  const genresQuery = useQuery(['genres'], () => requests.getGenres(), {
    select: (data) => sortGenres(data?.data),
  })

  const bookListId =
    ENVIRONMENT === 'production'
      ? 'dfa9672b-09a3-4a23-a2c8-5cc819d4c00d'
      : '97078ab0-acbb-4bee-8199-06c4df92fa14'

  const booksQuery = useInfiniteQuery(
    'books',
    async () =>
      await get(
        `/book_lists/${bookListId}/books?${searchParamsString}&limit=20&offset=${booksQueryOffset.current}&ordering=sort_value`
      ),
    {
      keepPreviousData: false,
      getNextPageParam: (data) => data?.data?.next,
      enabled:
        pathname.includes(routes.clubQuizBooks) &&
        searchParamsString.includes(genreIdKey.current),
      select: (data) => ({
        pages: data.pages.slice(data.pages.length - 1),
        pageParams: data.pageParams,
      }),
    }
  )

  const booksFlattened = useMemo(
    () =>
      (booksQuery?.data?.pages || [])
        .reduce(
          (acc: any, curr: any) => [
            ...acc,
            ...(curr.data.results || [])?.map((x: { book: Book }) => x.book),
          ],
          []
        )
        .reverse(),
    [booksQuery?.data?.pages]
  )

  const resultsQuery = useQuery(
    ['results', searchParamsString],
    async () => await get(`/clubs/similar_clubs?${searchParamsString}`),
    {
      select: (data) => data?.data,
      enabled:
        pathname.includes(routes.clubQuizResults) &&
        searchParamsString.includes(bookIdKey.current),
    }
  )

  const popularClubsQuery = useQuery(
    ['popularClubs'],
    () =>
      requests.getClubsByGenre(
        new URLSearchParams(search).getAll(genreIdKey.current)?.toString()
      ),
    {
      select: (data) => data?.data?.results,
      enabled:
        pathname.includes(routes.clubQuizResults) &&
        !resultsQuery.isLoading &&
        !(resultsQuery.data || []).length,
    }
  )

  const getNextBooksPage = () => {
    if (!booksQuery?.hasNextPage) return

    booksQueryOffset.current += 20
    booksQuery?.fetchNextPage()
  }

  const getNextRoute = () => {
    if (pathname.includes(routes.clubQuizGenre)) {
      return routes.clubQuizBooks
    }
    if (pathname.includes(routes.clubQuizBooks)) {
      return routes.clubQuizResults
    }

    return ''
  }

  const getAllowNext = () => {
    if (pathname.includes(routes.clubQuizGenre)) {
      return Object.values(genreSelections).length > 0
    }
    if (pathname.includes(routes.clubQuizBooks)) {
      return selectedBookIds.length >= minSelections.current
    }

    return true
  }

  const setGenreSelection = (genre: { id: string; name: string }) => {
    if (genreSelections[genre.id]) {
      setGenreSelections((prev) => {
        const updated = { ...prev }
        delete updated[genre.id]

        return updated
      })
    } else {
      setGenreSelections((prev) => ({ ...prev, [genre.id]: genre.name }))
    }
  }

  const setSelectedBookId = (bookId: string) => {
    setSelectedBookIds((prev: string[]) => [...prev, bookId])
  }

  const determineUrlSearchParams = () => {
    const nextPath = getNextRoute()

    switch (nextPath) {
      case routes.clubQuizBooks:
        return createUrlSearchParams([
          { key: genreIdKey.current, values: Object.keys(genreSelections) },
        ])

      case routes.clubQuizResults:
        return createUrlSearchParams([
          { key: genreIdKey.current, values: Object.keys(genreSelections) },
          { key: bookIdKey.current, values: selectedBookIds },
        ])
    }

    return ''
  }

  useErrorModal(
    (genresQuery.error ||
      booksQuery?.error ||
      resultsQuery.error ||
      popularClubsQuery.error) as any
  )

  useEffect(() => {
    const genresData = genresQuery?.data

    if (!!genresData && !genres.length) {
      setgenres(genresData)
    }
  }, [genres.length, genresQuery?.data])

  useEffect(() => {
    if (resultsQuery.error) {
      analytics.events.clubQuizResultsFailed()
    }
  }, [resultsQuery.error])

  useEffect(() => {
    const searchParams = new URLSearchParams(search)

    if (searchParamsString !== searchParams.toString()) {
      setSearchParamsString(searchParams.toString())
    }
  }, [search, searchParamsString])

  return {
    loading:
      genresQuery.isFetching ||
      (booksQuery?.isFetching && !booksQuery?.isFetchingNextPage) ||
      resultsQuery.isFetching,
    resultsQuery,
    genreIdKey: genreIdKey.current,
    allowNext: getAllowNext(),
    nextRoute: getNextRoute(),
    genreSelections,
    nextUrlSearchParams: determineUrlSearchParams() as string,
    selectedBookIds,
    genres,
    books: booksFlattened,
    results: resultsQuery.data,
    popularClubs: popularClubsQuery.data,
    minSelections: minSelections.current,
    setSelectedBookId,
    setGenreSelection,
    getNextBooksPage,
  }
}

export const ClubQuizContext = createContext({
  loading: true,
  resultsQuery: null,
  genreIdKey: 'genre_id',
  allowNext: false,
  nextRoute: '',
  genreSelections: {},
  selectedBookIds: [],
  nextUrlSearchParams: '',
  genres: null,
  books: null,
  results: null,
  popularClubs: null,
  minSelections: 5,
  setGenreSelection: () => null,
  setSelectedBookId: () => null,
  getNextBooksPage: () => null,
} as ClubQuizContextType)

export const ClubQuizContextProvider = ({
  children,
}: {
  children: ReactNode
}) => (
  <ClubQuizContext.Provider value={useClubQuizContext()}>
    {children}
  </ClubQuizContext.Provider>
)
