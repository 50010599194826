import './Modal.scss'
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { AuthForm } from '../../app/auth/components/auth_form/AuthForm'
import CloseIcon from '../../assets/icons/components/CloseIcon'

import useWindowSize from '../../hooks/useWindowSize'
import { useTypedSelector, useActions } from '../../hooks'
import { useClickOutside } from '@fable/hooks'
import { css, cx, useTheme } from '@fable/theme'
import { useLocation } from 'react-router-dom'

export const Modal = ({ children, isStatic }) => {
  const { colors, mediaQueries } = useTheme()
  const { clearModal, closeModal } = useActions()
  const {
    content,
    isOpen,
    type,
    path,
    authType,
    isAuth,
    preventClickOutside,
    options,
  } = useTypedSelector(({ modal }) => modal)

  const { width, height } = useWindowSize()
  const modalRef = useRef()

  const onCloseModal = () => {
    closeModal()
    options?.onClose && options.onClose()
  }

  /**
   * @note
   * Temp hack to change the background color
   * for builder.io pages until Modal can be refactored
   */
  const { pathname } = useLocation()
  const isBuilderPage = pathname.includes('/p/')

  useClickOutside(modalRef, () => {
    if (!preventClickOutside) {
      if (isOpen) {
        onCloseModal()

        setTimeout(() => {
          clearModal()
        }, 300)
      }
    }
  })

  const close = () => {
    onCloseModal()

    setTimeout(() => {
      clearModal()
    }, 300)
  }

  useEffect(() => {
    if (isOpen) {
      if (isAuth && width <= 375 && height <= 700) {
        document.body.style.overflow = 'unset'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, width, height, isAuth])

  const closeButton = (
    <div className="close-modal" onClick={close}>
      <CloseIcon />
    </div>
  )

  const renderModal = () => {
    if (isAuth)
      return (
        <div className="modal-form" ref={modalRef}>
          <AuthForm
            closeButton={closeButton}
            isCard={true}
            path={path}
            type={authType}
            header={options?.header}
            invite={options?.invite}
          />
        </div>
      )

    return (
      <div
        data-testid="modal"
        className={cx(
          'modal',
          css`
            background-color: ${isBuilderPage
              ? colors.pearlWhite
              : colors.whiteFang};
          `
        )}
        ref={modalRef}
      >
        {closeButton}
        {content}
      </div>
    )
  }

  const open = isOpen && !isStatic ? 'open' : ''
  const auth = isAuth ? 'auth' : ''
  const modalType = type ? type : ''

  return isStatic ? (
    <div
      className={cx(
        css`
          margin-top: -60px;
        `,
        'modal-container static'
      )}
    >
      <div className="modal">{children}</div>
    </div>
  ) : (
    <div
      className={cx(
        `modal-container ${open} ${auth} default`,
        modalType,
        css`
          ${mediaQueries.mobileL} {
            &.auth {
              padding-top: 60px;
              align-items: unset !important;
              > div {
                height: fit-content;
                margin-bottom: 60px;
              }
            }
            overflow: auto;
          }
        `
      )}
    >
      {renderModal()}
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.object,
  isStatic: PropTypes.bool,
}

Modal.defaultProps = {
  children: {},
  isStatic: false,
}
