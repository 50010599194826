import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { Book } from '@fable/types'
import { useTypedSelector } from 'hooks'

export const useBookOwned = ({ bookId }: { bookId: string }) => {
  const queryClient = useQueryClient()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const query = queryClient.getQueryData(['owned-books']) as { data: Book[] }

  const books = query?.data

  const purchasedBookIds = useMemo(() => {
    if (!books || !books?.length) return null

    return new Set(
      Object.values(books).map((item) => {
        const book = item as Book
        return book.id
      })
    )
  }, [books])

  const sampled = useMemo(
    () => books?.find((book: Book) => book.id === bookId)?.status === 'preview',
    [bookId, books]
  )

  if (!isAuthenticated) {
    return {
      sampled: false,
      owned: false,
    }
  }

  return {
    sampled,
    owned:
      // I needed a third state besides true or false to notify that data exists -J
      !!books
        ? !isEmpty(purchasedBookIds) && purchasedBookIds?.has(bookId)
        : undefined,
  }
}
