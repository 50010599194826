import { Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useState } from 'react'
import { LegacyAccordionIcon } from './LegacyAccordionIcon'

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string
  children: React.ReactNode | React.ReactNode[]
}

export const LegacyAccordion = ({
  title,
  children,
  className,
  ...props
}: Props) => {
  const { colors, motion } = useTheme()
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  return (
    <div
      className={cx(
        css`
          padding: 16px;
          position: relative;
          border-radius: 8px;
          background-color: ${open ? colors.whiteFang : colors.almostWhite};
          border: 1px solid ${open ? colors.whiteSmoke : 'transparent'};
          ${motion.transition(['background', 'border-color'])};
        `,
        className
      )}
      onClick={toggle}
      {...props}
    >
      <Text
        className={css`
          width: calc(100% - 28px);
          color: ${colors.greyStrong};
        `}
        type="subhead"
        sizing={{ base: 'XS', mobileL: 'S' }}
      >
        {title}
      </Text>
      <div
        className={css`
          margin-top: ${open ? '12px' : 0};
          color: ${colors.greyStrong};
          max-height: ${open ? '1000px' : 0};
          overflow: hidden;
        `}
      >
        {children}
      </div>
      <LegacyAccordionIcon open={open} />
    </div>
  )
}
