import { Builder, withChildren } from '@builder.io/react'
import { Button } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useEffect, useState } from 'react'
import { defaultTextChild } from '../../helpers'
import { MenuDrawerContent } from './MenuDrawerContent'
import { MenuDrawerIcon } from './MenuDrawerIcon'

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

const MenuDrawer = ({ children }: Props) => {
  const { nativeOverrides, motion } = useTheme()
  const [open, setOpen] = useState(false)
  const handleOnClose = () => setOpen(false)

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <>
      <Button
        className={css`
          ${nativeOverrides.buttonClear};
          width: 20px;
          height: 14px;
          ${motion.transition('opacity')};
          &:hover {
            opacity: 0.7;
          }
        `}
        onClick={() => setOpen(true)}
      >
        <MenuDrawerIcon />
      </Button>
      <MenuDrawerContent open={open} onClose={handleOnClose}>
        {children}
      </MenuDrawerContent>
    </>
  )
}

const MenuDrawerWithChilden = withChildren(MenuDrawer)

export const registerMenuDrawer = () => {
  Builder.registerComponent(MenuDrawerWithChilden, {
    name: 'Menu Drawer',
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/menu-2.webp',
    defaultChildren: [defaultTextChild],
  })
}
