import emojiLol from '../assets/emoji/emoji-lol.svg'
import emojiLove from '../assets/emoji/emoji-love.svg'
import emojiMad from '../assets/emoji/emoji-mad.svg'
import emojiShocked from '../assets/emoji/emoji-shocked.svg'
import emojiSlightSmile from '../assets/emoji/emoji-slight-smile.svg'
import emojiSmile from '../assets/emoji/emoji-smile.svg'
import emojiSobbing from '../assets/emoji/emoji-sobbing.svg'
import emojiThinking from '../assets/emoji/emoji-thinking.svg'
import emojiAnxiousSweat from '../assets/emoji/emoji-anxious-sweat.svg'
import emojiExpressionless from '../assets/emoji/emoji-expressionless.svg'
import emojiThumbsUp from '../assets/emoji/emoji-thumbs-up.svg'

/**
 * @note
 *
 * Emoji used in chat/discussions and in reviews have some crossover
 * as well as some inconsistencies
 */

export const chatEmoji = {
  '🥰': { src: emojiLove, alt: 'Smiling Face with Heart-Eyes' },
  '😭': { src: emojiSobbing, alt: 'Loudly Crying Face' },
  '🤔': { src: emojiThinking, alt: 'Thinking Face' },
  '😱': { src: emojiShocked, alt: 'Surprised Face with Open Mouth' },
  '🤣': { src: emojiLol, alt: 'Rolling on the Floor Laughing Face' },
  '👍': { src: emojiThumbsUp, alt: 'Thumbs Up' },
}

export const reviewsEmoji = {
  '😡': { src: emojiMad, alt: 'Red Angry Face' },
  '😍': { src: emojiLove, alt: 'Smiling Face with Heart-Eyes' },
  '😭': { src: emojiSobbing, alt: 'Loudly Crying Face' },
  '😰': { src: emojiAnxiousSweat, alt: 'Anxious Face with sweat' },
  '🤔': { src: emojiThinking, alt: 'Thinking Face' },
  '😮': { src: emojiShocked, alt: 'Surprised Face with Open Mouth' },
  '🙂': { src: emojiSlightSmile, alt: 'Slightly Smiling Face' },
  '😁': { src: emojiSmile, alt: 'Beaming Face with Smiling Eyes' },
  '🤣': { src: emojiLol, alt: 'Rolling on the Floor Laughing Face' },
  '😑': { src: emojiExpressionless, alt: 'Expressionless Face' },
  '👍': { src: emojiThumbsUp, alt: 'Thumbs Up' },
}

export const allEmoji = {
  ...chatEmoji,
  ...reviewsEmoji,
}
