import { Loader } from '@fable/components'
import { usePreloadImage } from '@fable/hooks'
import { css, cx, useTheme } from '@fable/theme'
import { useState } from 'react'

interface Props {
  className?: string
  image: string
  radius?: number
  contain?: boolean
  size?: number
}

export const ActivityPostImage = ({
  image,
  className = '',
  size = 520,
  contain = false,
}: Props) => {
  const [loaded, setLoaded] = useState(false)
  const { colors, motion } = useTheme()
  const postImage = usePreloadImage(image || '', size)
  const loading = !!image.length && !loaded

  return (
    <div
      className={css`
        width: 100%;
        height: ${loading ? '150px' : 'auto'};
        border-radius: 16px;
        background-color: ${colors.uiSilverGreyLight};
        overflow: hidden;
      `}
    >
      {loading ? (
        <Loader
          className={css`
            height: 100%;
          `}
          iconClass={css`
            width: 60px;
            svg {
              width: 100% !important;
            }
          `}
        />
      ) : null}
      <img
        className={cx(
          css`
            display: block;
            width: 100%;
            aspect-ratio: ${contain ? '16 / 9' : 'auto'};
            object-fit: cover;
            object-position: center;
            opacity: ${loading ? 0 : 1};
            ${motion.transition('opacity')};
          `,
          className
        )}
        src={postImage?.src}
        alt={`user post featured pic`}
        onLoad={() => {
          setLoaded(true)
        }}
      />
    </div>
  )
}
