import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_BOOK_LIST = gql`
  query ($id: String!) {
    bookList(id: $id, preview: ${isPreview}) {
      name
      type
      booksCollection {
        items {
          title
          bookCover
          shortDescription
          author
          clubPick
          isbn
          slug
        }
      }
    }
  }
`
