import { PlanOptions } from '..'
import { PlanOption } from '../PlanOption'
import { PlanDetails } from '../../../types'
import { formatAmountDescription } from '../../../utils'

interface Props {
  className?: string
  onOptionSelect: (selectedOptions: PlanOptions) => void
  options: PlanOptions
  plan: PlanDetails
  tag?: string
  disabled?: boolean
}

export const AllAccessPlanOption: React.VFC<Props> = ({
  className,
  onOptionSelect,
  options,
  plan,
  tag,
  disabled,
}) => {
  const subtitle = formatAmountDescription(plan.amount, plan.recurring.interval)

  return (
    <PlanOption
      className={className}
      isSelected={options?.plan?.id === plan.id}
      tag={tag}
      isRadio={!plan.isGift}
      onChange={() => onOptionSelect({ ...options, plan })}
      image={plan.image}
      title={plan.title}
      description={plan.description}
      subtitle={subtitle}
      disabled={disabled}
    />
  )
}
