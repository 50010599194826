import { Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useSelector } from '@xstate/react'
import { useAnimationControls, motion } from 'framer-motion'
import { useCart } from 'hooks'
import { useCallback, useEffect } from 'react'

interface Props {
  inverted: boolean
}

export const CartQuantityIndicator = ({ inverted }: Props) => {
  const { colors } = useTheme()
  const controls = useAnimationControls()
  const { service } = useCart()
  const state = useSelector(service, (state) => state)
  const cart = state.context.cart
  const qty = cart?.items.length

  const sequence = useCallback(async () => {
    await controls.start({ scale: 1.4, transition: { duration: 0.1 } })
    await controls.start({ scale: 1 })
  }, [controls])

  useEffect(() => {
    if (state.matches('idle')) sequence()
  }, [sequence, state])

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={controls}
      transition={{ type: 'spring', duration: 0.6, bounce: 0.45 }}
      className={css`
        display: block;
        width: 16px;
        height: 16px;
        background-color: ${inverted
          ? colors.uiPearlExtraLight
          : colors.uiGraphite};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: -2px;
        right: -2px;
        pointer-events: none;
      `}
    >
      <Text
        tag="span"
        type="subhead"
        color={inverted ? colors.uiGraphite : colors.uiPearlExtraLight}
        custom={{ fontSize: '10px' }}
      >
        {qty}
      </Text>
    </motion.div>
  )
}
