import { Dispatch } from 'redux'
import { GiftsActions } from '../actions'
import { GiftsTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { GiftOrder } from '@fable/types'
import { post, get } from '@fable/api'
import { getGiftQueryParams, keysToSnakeCase } from '../../utils'
import { routes } from '../../app/store/routes'
import { isToday } from 'date-fns'

type DispatchType = Dispatch<GiftsActions>

export const postAuthRedeemGift = (giftCode: string) => () =>
  setAction(GiftsTypes.POST_AUTH_REDEEM_GIFT, { giftCode })

export const postAuthGiftDraft =
  (data: {
    bookParam: string | null
    planParam: string | null
    bookIsbn: string | null
    giftOrder: Partial<GiftOrder>
  }) =>
  () =>
    setAction(GiftsTypes.POST_AUTH_SUBMIT_GIFT_ORDER_DRAFT, data)

export const setGiftClaimed =
  (giftCode: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(GiftsTypes.SET_GIFT_CLAIMED__REQUEST))

    try {
      const { data } = await post('/gifts/redemption', { code: giftCode })
      // The redemption API doesn't resolve anything, just a 200
      // but we need check for data in the component
      dispatch(
        setAction(GiftsTypes.SET_GIFT_CLAIMED__SUCCESS, {
          ...data,
          success: true,
        })
      )
    } catch (error) {
      dispatch(setAction(GiftsTypes.SET_GIFT_CLAIMED__ERROR, error))
    }
  }

export const setGiftClaimedClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(GiftsTypes.SET_GIFT_CLAIMED__CLEAR))
}

export const getGiftCardDetailsClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(GiftsTypes.GET_GIFT_CARD_DETAILS__CLEAR))
}

export const getGiftCardDetails =
  ({ recipientId, giftCode }: { recipientId?: string; giftCode?: string }) =>
  async (dispatch: DispatchType) => {
    try {
      dispatch(setAction(GiftsTypes.GET_GIFT_CARD_DETAILS__REQUEST))

      let url = '/gifts/recipients'
      if (recipientId) url += `?recipient_id=${recipientId}`
      else if (giftCode) url += `?code=${giftCode}`

      const { data } = await get(url)

      dispatch(setAction(GiftsTypes.GET_GIFT_CARD_DETAILS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(GiftsTypes.GET_GIFT_CARD_DETAILS_ERROR, error))
    }
  }

export const setGiftOrder =
  (data: Partial<GiftOrder>) =>
  async (dispatch: DispatchType, getState: () => any) => {
    const {
      gifts: { giftOrderLocal },
    } = getState()

    dispatch(
      setAction(GiftsTypes.SET_GIFT_ORDER, {
        ...giftOrderLocal,
        ...data,
        delivery_method: 'email',
      })
    )
  }

export const setGiftOrderClear = () => async (dispatch: DispatchType) => {
  dispatch(setAction(GiftsTypes.SET_GIFT_ORDER__CLEAR))
}

export const submitGiftOrderDraft =
  ({
    bookParam,
    planParam,
    bookIsbn,
    navigation,
  }: {
    bookParam: string | null
    planParam: string | null
    bookIsbn: string | null
    navigation: (uri: string) => void
  }) =>
  async (dispatch: DispatchType, getState: () => any) => {
    dispatch(setAction(GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__REQUEST))

    const {
      user: {
        profile: {
          data: { id },
        },
      },
      gifts: { giftOrderLocal },
    } = getState()

    try {
      const { data } = await post('/gifts', {
        ...keysToSnakeCase({
          ...giftOrderLocal,
          delivery_date: isToday(giftOrderLocal.delivery_date)
            ? null
            : giftOrderLocal.delivery_date,
        }),
        id,
      })
      dispatch(setAction(GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__SUCCESS, data))

      // Navigate to checkout
      const queryParams = getGiftQueryParams({
        bookParam,
        planParam,
        bookIsbn,
        giftOrder: data,
      })
      navigation(`${routes.checkout}${queryParams}`)
    } catch (error) {
      dispatch(setAction(GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__ERROR, error))
    }
  }

export const submitGiftOrderDraftResponseClear =
  () => (dispatch: DispatchType) => {
    dispatch(setAction(GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__CLEAR))
  }
