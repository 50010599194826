import { Cookies } from 'react-cookie'
import addYears from 'date-fns/addYears'
import { TRACKING_BLACKLIST } from '../constants'

const cookies = new Cookies()

export function setCookie(
  name: string,
  value: string | number,
  expires = addYears(new Date(), 1)
) {
  if (TRACKING_BLACKLIST(window.location.pathname)) return

  cookies.set(name, value, {
    path: '/',
    expires,
  })
}
export function getCookie(name: string) {
  if (TRACKING_BLACKLIST(window.location.pathname)) return

  return cookies.get(name)
}
