import { Club } from '@fable/types'

export const getClubModeratorText = ({
  club,
  includePronouns = false,
}: {
  club: Club | null
  includePronouns?: boolean
}) => {
  if (!club) return

  const moderators = club?.moderators
  let text = club.creator.display_name
  let pronouns = club.creator.pronouns || ''

  if (includePronouns && pronouns) {
    text += ` (${pronouns})`
  }

  if (moderators?.length) {
    text = moderators[0].display_name
    pronouns = moderators[0].pronouns || ''

    if (includePronouns && pronouns) {
      text += ` (${pronouns})`
    }

    if (moderators.length > 1) {
      text += ` +${moderators.length - 1}`
    }
  }

  return text
}
