import { css, useTheme } from '@fable/theme'
import { Quote } from '@fable/types'
import formatDistance from 'date-fns/formatDistance'
import { FlexBox, Text } from '..'
import RoundImage from '../RoundImage'
import QuoteView from './QuoteView'
import Hyperlink from '../hyperlink'

const QuoteViewModal = ({
  quote,
  downloadLink,
}: {
  quote: Quote
  downloadLink: string
}) => {
  const { mediaQueries, colors } = useTheme()

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      className={css`
        max-width: 625px;
        padding: 20px 25px 20px 25px;
        ${mediaQueries.mobileL} {
          width: 465px;
        }
        ${mediaQueries.tablet} {
          padding: 20px 55px 20px 55px;
        }
      `}
    >
      <FlexBox
        className={css`
          margin-bottom: 16px;
          background: ${colors.whiteFang};
        `}
        flexDirection="column"
        alignItems="center"
      >
        <RoundImage
          size={48}
          src={quote.user.pic || ''}
          alt={quote.user.display_name || ''}
        />
        <Text
          className={css`
            margin: 6px auto;
          `}
          type="subhead"
          sizing={{ base: 'XS', mobileL: 'S' }}
          tag="h4"
          color={colors.blackSwan}
          align="center"
        >
          <b>{quote.user.display_name}'s quote</b>
        </Text>
        <Text type="body" sizing="S" color={colors.hurricane}>
          Created&nbsp;
          {formatDistance(new Date(quote.created_at), new Date(), {
            addSuffix: true,
          })}
        </Text>
      </FlexBox>
      <QuoteView text={quote.text} book={quote.book} theme={quote.theme} />
      <Text
        className={css`
          margin: 15px auto;
        `}
        type="link"
        sizing="M"
        color={colors.denim}
        tag="h4"
        align="center"
      >
        <Hyperlink href={downloadLink}>
          Download the app to create your own
        </Hyperlink>
      </Text>
    </FlexBox>
  )
}

export default QuoteViewModal
