import { css, cx, useTheme, FableTheme } from '@fable/theme'
import { Tag } from '@fable/components'
import Checkbox from '../checkbox/Checkbox'
import { BREAKPOINTS } from '../../constants'

interface PlanOptionProps {
  className?: string
  isRadio?: boolean
  isSelected: boolean
  onChange: () => void
  tag?: string
  image?: string
  title?: string
  subtitle?: string
  description?: string
  disabled?: boolean
}

export const PlanOption: React.FC<PlanOptionProps> = ({
  className,
  isRadio,
  isSelected,
  onChange,
  tag,
  image,
  title,
  subtitle,
  description,
  disabled,
}) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <Checkbox
      customClass={cx(styles.checkbox, className)}
      isChecked={isSelected}
      isRadio={isRadio}
      disabled={disabled}
      onChange={onChange}
      clickAll
    >
      <div className={styles.optionContainer}>
        <div
          className={cx(styles.optionImage, 'option-image fl-image-fill')}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div className={styles.optionInfo}>
          <p className={styles.optionTitle}>{title}</p>
          <b className={styles.optionSubtitle}>{subtitle}</b>
          <label className={styles.optionDescription}>{description}</label>
        </div>
      </div>
      {!!tag ? (
        <Tag
          className={css`
            padding: 3px;
            position: absolute;
            top: 8px;
            right: 8px;
            line-height: 1 !important;
            letter-spacing: 0.4px;
            cursor: pointer;
          `}
          backgroundColor={theme.colors.shamrock}
          color={theme.colors.whiteFang}
          title={tag}
        />
      ) : null}
    </Checkbox>
  )
}

const getStyles = ({ colors, mediaQueries }: FableTheme) => ({
  checkbox: css`
    padding: 0 18px;
    height: 108px;
    background-color: ${colors.whiteFang};
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    :last-child {
      margin-bottom: 0;
    }

    :hover {
      background-color: ${colors.almostWhite};
    }

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      height: 88px;
    }
  `,
  optionContainer: css`
    padding-left: 4px;
    display: flex;
    align-items: center;

    label {
      display: block;
      color: ${colors.hurricane};
      cursor: pointer;
    }
  `,
  optionInfo: css`
    width: 100%;
  `,
  optionTitle: css`
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: ${colors.blackSwan} !important;
    cursor: pointer;

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      font-size: 15px !important;
    }
  `,
  optionSubtitle: css`
    font-size: 12px !important;
    line-height: 20px !important;
    color: ${colors.blackSwan} !important;
    cursor: pointer;

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      margin-bottom: 4px;
      font-size: 15px !important;
    }
  `,
  optionDescription: css`
    max-width: 180px;

    ${mediaQueries.mobileS} {
      max-width: none;
    }
  `,
  optionImage: css`
    margin-right: 12px;
    width: 38px;
    height: 56px;
    border-radius: 4px;
    flex-shrink: 0;
  `,
})
