import { IconSVGProps } from '../types'

export const TikTokIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.09963 0.0124995C8.95419 0 9.7996 0.00937466 10.6481 0C10.6999 1.02184 11.057 2.0593 11.7865 2.78115C12.5159 3.52175 13.5444 3.85923 14.5455 3.97486V6.65913C13.6055 6.62788 12.6624 6.42789 11.8109 6.01228C11.4385 5.84041 11.0937 5.61855 10.7549 5.39355C10.7518 7.34036 10.764 9.28716 10.7427 11.2277C10.6908 12.1589 10.3917 13.087 9.86064 13.8557C9.00913 15.1338 7.53195 15.9682 6.01205 15.9932C5.08118 16.0463 4.15032 15.7869 3.35679 15.3088C2.04443 14.5151 1.11967 13.0589 0.985377 11.4996C0.970117 11.1652 0.964013 10.834 0.976221 10.509C1.0922 9.24029 1.70565 8.02783 2.65788 7.20286C3.73524 6.2404 5.24599 5.78416 6.66213 6.0529C6.67434 7.04037 6.63771 8.02783 6.63771 9.0153C5.99068 8.8028 5.23683 8.86218 4.67221 9.26216C4.26019 9.53715 3.94583 9.95589 3.78408 10.4277C3.64979 10.7652 3.68946 11.1402 3.69557 11.4996C3.85122 12.5933 4.8767 13.512 5.97542 13.4151C6.7018 13.4058 7.40071 12.9745 7.77916 12.3433C7.90125 12.1214 8.03859 11.8964 8.04774 11.634C8.11183 10.4402 8.08742 9.25279 8.09352 8.06221C8.09352 5.37168 8.08131 2.69365 8.09963 0.0124995Z" />
  </svg>
)
