/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Response } from '../../types'
import { BillingActions } from '../actions'
import { BillingTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'
import {
  Bundle,
  CreditBalance,
  Offers,
  Plan,
  Price,
  Address,
  Subscription,
} from '@fable/types'

export enum BillingKeys {
  cards = 'cards',
  cardDeleted = 'cardDeleted',
  cardAdded = 'cardAdded',
  defaultCard = 'defaultCard',
  orders = 'orders',
  order = 'order',
  subscriptions = 'subscriptions',
  plan = 'plan',
  planDeleted = 'planDeleted',
  bundle = 'bundle',
  offers = 'offers',
  price = 'price',
  address = 'address',
  stripeSourceId = 'stripeSourceId',
  stripeSourceClientSecret = 'stripeSourceClientSecret',
  cardHolderName = 'cardHolderName',
  subscriptionToCancel = 'subscriptionToCancel',
  subscriptionCancellation = 'subscriptionCancellation',
  couponCode = 'couponCode',
  creditBalance = 'creditBalance',
  creditBalanceEnabled = 'creditBalanceEnabled',
}

export interface BillingState {
  [BillingKeys.cards]: Response
  [BillingKeys.cardDeleted]: Response
  [BillingKeys.cardAdded]: Response
  [BillingKeys.defaultCard]: Response
  [BillingKeys.orders]: Response
  [BillingKeys.order]: Response
  [BillingKeys.subscriptions]: Response<Subscription[]>
  [BillingKeys.plan]: Response<Plan>
  [BillingKeys.planDeleted]: Response
  [BillingKeys.bundle]: Response<Bundle>
  [BillingKeys.offers]: Response<Offers>
  [BillingKeys.price]: Response<Price>
  [BillingKeys.address]: Address
  [BillingKeys.stripeSourceId]: string
  [BillingKeys.cardHolderName]: string
  [BillingKeys.subscriptionToCancel]: null | Subscription
  [BillingKeys.subscriptionCancellation]: Response
  [BillingKeys.couponCode]: string
  [BillingKeys.stripeSourceClientSecret]: string
  [BillingKeys.creditBalance]: Response<CreditBalance>
  [BillingKeys.creditBalanceEnabled]: boolean
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const addressDefault: Address = {
  country: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
}

const initialState: BillingState = {
  [BillingKeys.cards]: props,
  [BillingKeys.cardDeleted]: props,
  [BillingKeys.cardAdded]: props,
  [BillingKeys.defaultCard]: props,
  [BillingKeys.orders]: props,
  [BillingKeys.order]: props,
  [BillingKeys.subscriptions]: props,
  [BillingKeys.plan]: props,
  [BillingKeys.planDeleted]: props,
  [BillingKeys.bundle]: props,
  [BillingKeys.offers]: props,
  [BillingKeys.price]: props,
  [BillingKeys.address]: addressDefault,
  [BillingKeys.stripeSourceId]: '',
  [BillingKeys.cardHolderName]: '',
  [BillingKeys.subscriptionToCancel]: null,
  [BillingKeys.subscriptionCancellation]: props,
  [BillingKeys.couponCode]: '',
  [BillingKeys.stripeSourceClientSecret]: '',
  [BillingKeys.creditBalance]: {
    data: { amount: 0 },
    error: null,
    loading: false,
  },
  [BillingKeys.creditBalanceEnabled]: false,
}

export const reducer = (
  state: BillingState = initialState,
  action: BillingActions
): BillingState => {
  const stateProps = { state, props }

  switch (action.type) {
    // CARD HOLDER NAME
    case BillingTypes.SET_CARD_HOLDER_NAME:
      return {
        ...state,
        [BillingKeys.cardHolderName]: action.payload,
      }
    case BillingTypes.SET_CARD_HOLDER_NAME__CLEAR:
      return {
        ...state,
        [BillingKeys.cardHolderName]: '',
      }

    // DELETE CARD
    case BillingTypes.DELETE_CARD__REQUEST:
      return setRequestState(stateProps, BillingKeys.cardDeleted)
    case BillingTypes.DELETE_CARD__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.cardDeleted, {
        result: action.payload,
        isDeleted: true,
      })
    case BillingTypes.DELETE_CARD__ERROR:
      return setErrorState(stateProps, BillingKeys.cardDeleted, action.payload)
    case BillingTypes.DELETE_CARD__CLEAR:
      return setClearState(stateProps, BillingKeys.cardDeleted)

    // ADD CARD
    case BillingTypes.ADD_CARD__REQUEST:
      return setRequestState(stateProps, BillingKeys.cardAdded)
    case BillingTypes.ADD_CARD__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.cardAdded, {
        result: action.payload,
        isEdited: true,
      })
    case BillingTypes.ADD_CARD__ERROR:
      return setErrorState(stateProps, BillingKeys.cardAdded, action.payload)
    case BillingTypes.ADD_CARD__CLEAR:
      return setClearState(stateProps, BillingKeys.cardAdded)

    // DEFAULT CARD
    case BillingTypes.DEFAULT_CARD__REQUEST:
      return setRequestState(stateProps, BillingKeys.defaultCard)
    case BillingTypes.DEFAULT_CARD__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.defaultCard, {
        result: action.payload,
        isEdited: true,
      })
    case BillingTypes.DEFAULT_CARD__ERROR:
      return setErrorState(stateProps, BillingKeys.defaultCard, action.payload)
    case BillingTypes.DEFAULT_CARD__CLEAR:
      return setClearState(stateProps, BillingKeys.defaultCard)

    // GET CARDS
    case BillingTypes.GET_CARDS__REQUEST:
      return {
        ...state,
        [BillingKeys.cards]: {
          ...state[BillingKeys.cards],
          loading: true,
        },
      }
    case BillingTypes.GET_CARDS__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.cards, action.payload)
    case BillingTypes.GET_CARDS__ERROR:
      return setErrorState(stateProps, BillingKeys.cards, action.payload)

    // GET ORDERS
    case BillingTypes.GET_ORDERS__REQUEST:
      return setRequestState(stateProps, BillingKeys.orders)
    case BillingTypes.GET_ORDERS__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.orders, action.payload)
    case BillingTypes.GET_ORDERS__ERROR:
      return setErrorState(stateProps, BillingKeys.cards, action.payload)

    // CREATE ORDER
    case BillingTypes.CREATE_ORDER__REQUEST:
      return setRequestState(stateProps, BillingKeys.order)
    case BillingTypes.CREATE_ORDER__SUCCESS:
      return setSuccessState(
        stateProps,
        BillingKeys.order,
        action.payload.result
      )
    case BillingTypes.CREATE_ORDER__ERROR:
      return setErrorState(stateProps, BillingKeys.order, action.payload)
    case BillingTypes.CREATE_ORDER__CLEAR:
      return setClearState(stateProps, BillingKeys.order)

    // UPDATE ORDER
    // don't clear order state when updating
    case BillingTypes.UPDATE_ORDER__REQUEST:
      return {
        ...state,
        [BillingKeys.order]: {
          ...state[BillingKeys.order],
          loading: true,
          error: null,
        },
      }
    case BillingTypes.UPDATE_ORDER__SUCCESS:
      return setSuccessState(
        stateProps,
        BillingKeys.order,
        action.payload.result
      )
    // don't clear order state when updating
    case BillingTypes.UPDATE_ORDER__ERROR:
      return {
        ...state,
        [BillingKeys.order]: {
          ...state[BillingKeys.order],
          error: action.payload,
          loading: false,
        },
      }
    case BillingTypes.UPDATE_ORDER__CLEAR:
      return setClearState(stateProps, BillingKeys.order)

    // PAY FOR ORDER
    case BillingTypes.PAY_FOR_ORDER__REQUEST:
      return {
        ...state,
        [BillingKeys.order]: {
          ...state[BillingKeys.order],
          loading: true,
          error: null,
        },
      }
    case BillingTypes.PAY_FOR_ORDER__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.order, action.payload)
    case BillingTypes.PAY_FOR_ORDER__ERROR:
      return {
        ...state,
        [BillingKeys.order]: {
          ...state[BillingKeys.order],
          error: action.payload,
          loading: false,
        },
      }
    case BillingTypes.PAY_FOR_ORDER__CLEAR:
      return setClearState(stateProps, BillingKeys.order)

    // GET SUBSCRIPTIONS
    case BillingTypes.GET_SUBSCRIPTIONS_REQUEST:
      return setRequestState(stateProps, BillingKeys.subscriptions)
    case BillingTypes.GET_SUBSCRIPTIONS_CLEAR:
      return setClearState(stateProps, BillingKeys.subscriptions)
    case BillingTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return setSuccessState(
        stateProps,
        BillingKeys.subscriptions,
        action.payload
      )
    case BillingTypes.GET_SUBSCRIPTIONS_ERROR:
      return setErrorState(
        stateProps,
        BillingKeys.subscriptions,
        action.payload
      )

    // GET PLAN
    case BillingTypes.GET_PLAN__REQUEST:
      return setRequestState(stateProps, BillingKeys.plan)
    case BillingTypes.GET_PLAN__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.plan, action.payload)
    case BillingTypes.GET_PLAN__ERROR:
      return setErrorState(stateProps, BillingKeys.plan, action.payload)
    case BillingTypes.GET_PLAN__CLEAR:
      return setClearState(stateProps, BillingKeys.plan)

    // DELETE PLAN
    case BillingTypes.DELETE_PLAN_REQUEST:
      return setRequestState(stateProps, BillingKeys.planDeleted)
    case BillingTypes.DELETE_PLAN_SUCCESS:
      return setSuccessState(stateProps, BillingKeys.planDeleted, {
        result: action.payload,
        isDeleted: true,
      })
    case BillingTypes.DELETE_PLAN_ERROR:
      return setErrorState(stateProps, BillingKeys.planDeleted, action.payload)
    case BillingTypes.DELETE_PLAN_CLEAR:
      return setClearState(stateProps, BillingKeys.planDeleted)

    // GET PRICE
    case BillingTypes.GET_PRICE__REQUEST:
      return setRequestState(stateProps, BillingKeys.price)
    case BillingTypes.GET_PRICE__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.price, action.payload)
    case BillingTypes.GET_PRICE__ERROR:
      return setErrorState(stateProps, BillingKeys.price, action.payload)
    case BillingTypes.GET_PRICE__CLEAR:
      return setClearState(stateProps, BillingKeys.price)

    // GET BUNDLE
    case BillingTypes.GET_BUNDLE__REQUEST:
      return setRequestState(stateProps, BillingKeys.bundle)
    case BillingTypes.GET_BUNDLE__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.bundle, action.payload)
    case BillingTypes.GET_BUNDLE__ERROR:
      return setErrorState(stateProps, BillingKeys.bundle, action.payload)
    case BillingTypes.GET_BUNDLE__CLEAR:
      return setClearState(stateProps, BillingKeys.bundle)

    // GET OFFERS
    case BillingTypes.GET_OFFERS__REQUEST:
      return setRequestState(stateProps, BillingKeys.offers)
    case BillingTypes.GET_OFFERS__SUCCESS:
      return setSuccessState(stateProps, BillingKeys.offers, action.payload)
    case BillingTypes.GET_OFFERS__ERROR:
      return setErrorState(stateProps, BillingKeys.offers, action.payload)
    case BillingTypes.GET_OFFERS__CLEAR:
      return setClearState(stateProps, BillingKeys.offers)

    // SET ADDRESS
    case BillingTypes.SET_ADDRESS:
      return {
        ...state,
        [BillingKeys.address]: action.payload,
      }
    case BillingTypes.SET_ADDRESS__CLEAR:
      return {
        ...state,
        [BillingKeys.address]: addressDefault,
      }

    // STRIPE SOURCE ID
    case BillingTypes.SET_STRIPE_SOURCE_ID:
      return {
        ...state,
        [BillingKeys.stripeSourceId]: action.payload,
      }

    case BillingTypes.SET_STRIPE_SOURCE_CLIENT_SECRET:
      return {
        ...state,
        [BillingKeys.stripeSourceClientSecret]: action.payload,
      }

    case BillingTypes.SET_STRIPE_SOURCE_ID__CLEAR:
      return {
        ...state,
        [BillingKeys.stripeSourceId]: '',
        [BillingKeys.stripeSourceClientSecret]: '',
      }

    // CANCEL PLAN
    case BillingTypes.SUBSCRIPTION_CANCELLATION:
      // see cancelSubscriptionSaga
      return {
        ...state,
        [BillingKeys.subscriptionToCancel]: action.payload,
      }
    case BillingTypes.SUBSCRIPTION_CANCELLATION__REQUEST:
      return setRequestState(stateProps, BillingKeys.subscriptionCancellation)
    case BillingTypes.SUBSCRIPTION_CANCELLATION__SUCCESS:
      return setSuccessState(
        stateProps,
        BillingKeys.subscriptionCancellation,
        action.payload
      )
    case BillingTypes.SUBSCRIPTION_CANCELLATION__ERROR:
      return setErrorState(
        stateProps,
        BillingKeys.subscriptionCancellation,
        action.payload
      )

    // COUPON CODE
    case BillingTypes.SET_COUPON_CODE:
      return {
        ...state,
        [BillingKeys.couponCode]: action.payload,
      }

    // COUPON CODE
    case BillingTypes.SET_CREDIT_BALANCE_ENABLED:
      return {
        ...state,
        [BillingKeys.creditBalanceEnabled]: action.payload,
      }

    case BillingTypes.GET_CREDIT_BALANCE__REQUEST:
      return setRequestState(stateProps, BillingKeys.creditBalance)

    case BillingTypes.GET_CREDIT_BALANCE__SUCCESS:
      return setSuccessState(
        stateProps,
        BillingKeys.creditBalance,
        action.payload
      )

    case BillingTypes.GET_CREDIT_BALANCE__ERROR:
      return setErrorState(
        stateProps,
        BillingKeys.creditBalance,
        action.payload
      )

    default:
      return state
  }
}

export default reducer
