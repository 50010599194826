import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Ref, forwardRef, useRef } from 'react'
import { HomeFeaturesScrollingCards } from './HomeFeaturesScrollingCards'
import { CompProps } from '@fable/types'
import { HomeFeaturesProps } from '../../lib/types'
import { HomeFeatureCarouselContainer } from './HomeFeatureCarouselContainer'
import { HomeFeaturesCarouselDesktop } from './HomeFeaturesCarouselDesktop'
import { HomeFeaturesCarouselMobile } from './HomeFeaturesCarouselMobile'
import { useInView } from 'framer-motion'

const Content = (
  {
    reverse = false,
    scrollingCards,
    slides,
    slidesControlOuter,
    blockStyles,
    header,
    footer,
    illo,
  }: CompProps<HomeFeaturesProps>,
  ref: Ref<HTMLDivElement>
) => {
  const { mediaQueries } = useTheme()
  const carouselRef = useRef<HTMLDivElement>(null)
  const inView = useInView(carouselRef, { once: true, amount: 0.01 })

  return (
    <div ref={ref}>
      <FlexBox
        className={css`
          padding: 14px 24px;
        `}
        justifyContent="center"
      >
        <FlexBox
          className={css`
            width: 100%;
            max-width: 1200px;
            flex-direction: column;
            ${mediaQueries.tablet} {
              gap: 24px;
              flex-direction: ${reverse ? 'row-reverse' : 'row'};
            }
            ${mediaQueries.desktop} {
              gap: 40px;
            }
          `}
          justifyContent="space-between"
        >
          <div
            className={css`
              width: 100%;
              ${mediaQueries.tablet} {
                width: 50%;
              }
            `}
          >
            {header}
            {carouselRef.current ? (
              <HomeFeaturesScrollingCards
                containerRef={carouselRef}
                reverse={reverse}
                cards={scrollingCards}
              />
            ) : null}
          </div>
          <HomeFeatureCarouselContainer>
            {illo}
            <div
              ref={carouselRef}
              className={css`
                width: 100%;
                position: relative;
                z-index: 2;
                opacity: ${inView ? 1 : 0};
                transition: opacity 0.6s ease-in-out;
              `}
            >
              <HomeFeaturesCarouselMobile
                className={blockStyles}
                images={slides.mobile}
                controlsOuter={slidesControlOuter}
              />
              <HomeFeaturesCarouselDesktop {...slides.desktop} />
            </div>
          </HomeFeatureCarouselContainer>
        </FlexBox>
      </FlexBox>
      {footer || null}
    </div>
  )
}

export const HomeFeatures = forwardRef(Content)
