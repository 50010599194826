import { Text } from '@fable/components'
import { useTheme } from '@fable/theme'
import { useRef, useState } from 'react'

interface Option {
  name: string
  value: string
}

interface FieldWithOptionsProps {
  optionsPosition?: string
  onOptionSelect?: ({ name, value }: { name: string; value: string }) => void
  options: Option[]
  value: string
  sharedInputProps: {
    disabled?: boolean
    id?: string
    maxLength?: number
    name?: string
    onChange?: (s: string) => void
    onClick?: () => void
    placeholder?: string
    required?: boolean
    testId?: string
    type?: string
    value?: string
    label?: string
    icon?: string
    fieldClass?: string
  }
}

/**
 *
 * This component needs a refactor along with its parent Field.js
 * https://fablegroup.atlassian.net/browse/WEB-641
 */

const FieldWithOptions: React.VFC<FieldWithOptionsProps> = ({
  optionsPosition,
  onOptionSelect,
  options,
  value,
  sharedInputProps,
}) => {
  const { onChange, label, icon, fieldClass } = sharedInputProps
  const [activeOption, setActiveOption] = useState({})
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const { nativeOverrides } = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const matchingOption = options.find(
    (option) => option.name?.toLowerCase() === value?.toLowerCase()
  )

  const hasString = (a: string, b: string = '') =>
    a?.toLowerCase()?.startsWith(b?.toLowerCase())

  const openOptionsDropdown = () => {
    if (!isOptionsOpen) setIsOptionsOpen(true)
  }

  const closeOptionsDropdown = () => {
    if (isOptionsOpen) setIsOptionsOpen(false)
  }

  const handleClickOption = (option: Option, e?: InputEvent) => {
    setActiveOption(option)
    if (onChange) onChange(option.name)
    if (onOptionSelect) onOptionSelect(option)
    closeOptionsDropdown()
  }

  const handleInputChange = (s: string) => {
    if (onChange) onChange(s)
    if (!s) {
      handleClickOption({ name: '', value: '' })
    }
  }

  const handleInputBlur = () => {
    handleClickOption({
      name: matchingOption?.name || '',
      value: matchingOption?.value || '',
    })
  }

  const optionsFiltered = options.filter((option) =>
    hasString(option.name, value)
  )

  const renderOptions = optionsFiltered.map((option, index) => (
    <li key={index}>
      <button
        type="button"
        className={nativeOverrides.buttonClear}
        /*
        Mousedown fires before blur (see onBlur in input element below)
        blur is used to clear the field if there is no valid selection
        or select an option if there is a match
        so the user is not led to believe they've succesfully entered a valid US state
        */
        onMouseDown={() => handleClickOption(option)}
      >
        <Text type="body" sizing="L">
          {option.name}
        </Text>
      </button>
    </li>
  ))

  const open = isOptionsOpen ? 'open' : ''
  const selected = !!activeOption || !!value ? 'selected' : ''
  const labelClass = !!label ? 'has-label' : ''
  const iconClass = !!icon ? 'has-icon' : ''

  return (
    <div
      className={`field-wrap has-options ${labelClass} ${iconClass} ${fieldClass} ${open}`}
    >
      {label !== '' ? <label>{label}</label> : null}
      {icon !== '' ? <img src={icon} alt="Field Icon" /> : null}
      <input
        {...sharedInputProps}
        ref={inputRef}
        onFocus={openOptionsDropdown}
        className={`field field-active ${selected} ${fieldClass}`}
        onChange={(e) => handleInputChange(e.target.value)}
        onBlur={handleInputBlur}
        value={value}
      />
      {renderOptions.length !== 0 && (
        <div
          className={`field-options ${open} ${optionsPosition}`}
          data-testid="inputFieldOptions"
        >
          <ul>{renderOptions}</ul>
        </div>
      )}
    </div>
  )
}

export default FieldWithOptions
