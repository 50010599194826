import { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useClickOutside } from '@fable/hooks'
import { SearchBar } from '../search_bar/SearchBar'
import useWindowSize from '../../hooks/useWindowSize'
import { getParameterByName } from '../../utils'
import { useSubheader, useScrollTop } from '../../hooks'
import { SubheaderNavItem } from './SubheaderNavItem'
import { css, cx, useTheme, FableTheme } from '@fable/theme'
import { FlexBox, Icon, Text } from '@fable/components'
import { SubheaderNavLink } from '../../types'
import subheaderRoutes from './subheaderRoutes'

export const Subheader: React.VFC<{ secondary?: boolean }> = ({
  secondary = false,
}) => {
  const isSearching = Boolean(getParameterByName('search'))
  const [searching, setSearching] = useState(isSearching)
  const [scrolling, setScrolling] = useState(false)
  const [isNavOpen, setIsNavOpen] = useState(false)

  const location = useLocation()
  const windowWidth = useWindowSize().width
  const searchRef = useRef<HTMLDivElement>(null)
  const subheaderRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const styles = getStyles(theme, searching, isNavOpen, secondary)
  const { title, links, allowSearch } = useSubheader()

  useClickOutside(searchRef, () => searching && stopSearching())
  useClickOutside(subheaderRef, () => isNavOpen && setIsNavOpen(false))

  const detectSearchEnabled = useCallback(() => {
    if (allowSearch) setSearching(isSearching)
  }, [allowSearch, isSearching])

  useEffect(() => {
    detectSearchEnabled()
  }, [detectSearchEnabled])

  // check if user scrolled
  useScrollTop((scrollPosition) => {
    const currentPosition = scrollPosition.current

    if (currentPosition > 60) {
      setScrolling(true)
    } else {
      setScrolling(false)
    }

    if (currentPosition > 0 && isNavOpen) {
      setIsNavOpen(false)
    }
  })

  const onSearchToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (!allowSearch) return

    if (searching) {
      stopSearching()
    } else {
      setSearching(true)
      setIsNavOpen(false)
      document.body.style.overflow = 'hidden'
    }
  }

  const stopSearching = () => {
    setSearching(false)
    document.body.style.overflow = 'unset'
  }

  const onNavToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsNavOpen(!isNavOpen)
  }

  return !subheaderRoutes.includes(location.pathname) ? null : (
    <FlexBox
      className={cx(
        styles.subheader,
        'subheader pad-edges',
        allowSearch && 'has-search'
      )}
      justifyContent="center"
      data-testid="subheader"
    >
      <div
        className={styles.subheaderBar}
        ref={subheaderRef}
        onClick={(e) => !scrolling && onSearchToggle(e)}
      >
        {windowWidth > theme.breakpoints.tabletL ? (
          <Text
            type="subhead"
            sizing={{ base: 'M', mobileL: 'L' }}
            color={secondary ? theme.colors.blackSwan : theme.colors.whiteFang}
          >
            {title}
          </Text>
        ) : (
          <Text
            className={css`
              cursor: pointer;
              white-space: nowrap;
              svg {
                margin-left: 6px;
                transform: rotate(${isNavOpen ? 180 : 0}deg);
                ${theme.motion.transition('transform')};
              }
            `}
            type="subhead"
            sizing={{ base: 'XS', mobileL: 'S' }}
            color={
              isNavOpen || secondary
                ? theme.colors.blackSwan
                : theme.colors.whiteFang
            }
            onClick={onNavToggle}
          >
            {title}
            <Icon
              className={css`
                width: 10px;
                display: inline-block;
              `}
              type="chevron"
              fill={
                isNavOpen || secondary
                  ? theme.colors.blackSwan
                  : theme.colors.whiteFang
              }
            />
          </Text>
        )}

        <FlexBox justifyContent="flex-end">
          <ul className={styles.nav}>
            {links.map((item: SubheaderNavLink, i: number) => (
              <SubheaderNavItem key={i} item={item} secondary={secondary} />
            ))}
          </ul>
          {allowSearch && (
            <FlexBox
              className={styles.searchButton}
              onClick={onSearchToggle}
              centerAll
            >
              <Icon
                type="search"
                fill={
                  isNavOpen || secondary
                    ? theme.colors.blackSwan
                    : theme.colors.whiteFang
                }
              />
            </FlexBox>
          )}
        </FlexBox>
        {allowSearch && (
          <div className={styles.searchBarContainer} ref={searchRef}>
            <SearchBar
              className={styles.searchBar}
              onClose={stopSearching}
              isActive={searching}
            />
          </div>
        )}
      </div>
    </FlexBox>
  )
}

const getStyles = (
  { colors, motion, mediaQueries }: FableTheme,
  searching: boolean,
  isNavOpen: boolean,
  secondary: boolean
) => ({
  subheader: css`
    pointer-events: none;
    width: 100%;
    height: ${secondary ? '100vh' : 'calc(100vh - 60px)'};
    background-color: ${searching ? `${colors.blackSwan}70` : 'transparent'};
    position: ${secondary ? 'fixed' : 'absolute'};
    left: 0;
    top: 0;
    z-index: ${searching ? 5 : 1};
    ${motion.transition('background-color')}
  `,
  subheaderBar: css`
    margin: ${secondary ? 0 : '12px'} auto 0;
    padding: ${secondary ? '0 0 0 54px' : '0 24px'};
    max-width: ${secondary ? 'none' : '1072px'};
    background-color: ${isNavOpen || secondary
      ? 'transparent'
      : `${colors.blackSwan}16`};
    border-radius: 104px;
    width: ${secondary ? '100%' : 'calc(100% - 4px)'};
    height: ${secondary ? '60px' : '52px'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    pointer-events: auto;
    cursor: pointer;
    ${motion.transition('background-color')};

    .search-bar {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    ${mediaQueries.mobileL} {
      width: ${secondary ? '100%' : 'calc(100% - 16px)'};
      padding: ${secondary ? '0 0 0 70px' : '0 24px'};
    }

    ${mediaQueries.desktop} {
      width: ${secondary ? '100%' : 'calc(100% - 16px)'};
      padding: 0 ${secondary ? 0 : '24px'};
    }
  `,
  nav: css`
    margin-right: 12px;
    padding: ${secondary ? 0 : '52px 0 24px'};
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: ${secondary ? '60px' : 0};
    background-color: ${colors.whiteFang};
    border: 1px solid ${colors.whiteSmoke};
    border-top: ${secondary && 0};
    box-shadow: 0 8px 30px rgb(0 0 0 / 3%);
    width: 100%;
    border-radius: ${secondary ? '0 0 26px 26px' : '26px'};
    z-index: -1;
    pointer-events: ${isNavOpen ? 'auto' : 'none'};
    opacity: ${isNavOpen ? 1 : 0};
    ${motion.transition('opacity')};

    ${mediaQueries.tabletL} {
      padding: 0;
      flex-direction: row;
      width: auto;
      position: relative;
      background-color: transparent;
      border: none;
      border-radius: 0;
      position: relative;
      left: auto;
      top: auto;
      pointer-events: auto;
      opacity: 1;
      z-index: 0;
    }
  `,
  searchBarContainer: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: ${secondary ? '4.5px' : 0};
    left: 0;
    pointer-events: none;
    opacity: ${searching ? 1 : 0};
    pointer-events: ${searching ? 'auto' : 'none'};
    ${motion.transition('opacity')}
  `,
  searchBar: css`
    width: 100%;
  `,
  searchButton: css`
    cursor: pointer;
    ${motion.transition('opacity')}

    &:hover {
      opacity: 0.7;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  `,
})
