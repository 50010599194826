// https://mitchgavan.com/code-splitting-react-safely/
import { useEffect } from 'react'
import { getWithExpiry, setWithExpiry } from '../../utils'
import { ErrorBoundaryLoader } from 'components/error_boundary/ErrorBoundaryLoader'

const AppCodeSplitErrorBoundary = ({ error }: { error: any }) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      console.error(error)
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }
  }, [error])

  return <ErrorBoundaryLoader />
}
export default AppCodeSplitErrorBoundary
