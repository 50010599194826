import { IconSVGProps } from '../types'

export const StarSolidIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="m11.9999 18.26-7.05299 3.948 1.575-7.928-5.934996-5.488 8.026996-.952 3.38599-7.34 3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z" />
  </svg>
)
