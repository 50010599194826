import { useCallback, useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import { PageAnchor } from '../types'

export const usePageAnchorLoad = ({ anchors }: { anchors: PageAnchor[] }) => {
  const loaded = useRef(false)

  const scrollToElement = useCallback(() => {
    if (loaded.current) return
    const hash = window.location.hash.substring(1)
    let timeout: any

    if (!!hash && hash !== anchors[0].id) {
      timeout = setTimeout(() => {
        scroller.scrollTo(hash, {
          duration: 600,
          smooth: 'easeInOutQuad',
          offset: -170,
        })
        loaded.current = true
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [anchors, loaded])

  useEffect(() => scrollToElement(), [scrollToElement])
}
