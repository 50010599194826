// Don't import from index because functions that access ENVIRONMENT won't work before initialization
import { getSlug } from 'utils/getSlug'

const base = '/store'

export const routes = {
  explore: `${base}`,
  book: `/book`,
  author: `/author`,
  writer: `/writer`,
  folio: `/folio`,
  folios: `/folios`,
  yourClubs: `${base}/your-clubs`,
  bookshelf: `${base}/bookshelf`,
  profile: `${base}/profile`,
  accountSettings: `${base}/account/info`,
  accountOrders: `${base}/account/orders`,
  inviteReferral: `${base}/invite-friends`,
  checkout: `${base}/checkout`,
  checkoutSuccess: `${base}/checkout-success`,
  genres: `/genres`,
  redeem: '/redeem',
  giveGift: '/gift',
  giftPrint: '/gift/certificate',
}

export function bookRoute(book, isAbsolute = false) {
  if (book?.url && isAbsolute) return book?.url

  return book?.url
    ? new URL(book?.url).pathname
    : `${routes.book}/${book?.url ? getSlug(book?.url) : book?.isbn}`
}
