import { css } from '@fable/theme'

export enum Align {
  hLeft = 'fds-l--al-h-left',
  hRight = 'fds-l--al-h-right',
  hCenter = 'fds-l--al-h-center',
  vTop = 'fds-l--al-v-top',
  vBot = 'fds-l--al-v-bot',
  vCenter = 'fds-l--al-v-center',
  center = 'fds-l--al-center',
  around = 'fds-l--al-around',
  aroundCenter = 'fds-l--al-around-center',
  between = 'fds-l--al-between',
  betweenCenter = 'fds-l--al-between-center',
  colLeft = 'fds-l--al-col-left',
  colRight = 'fds-l--al-col-right',
  colCenter = 'fds-l--al-col-center',
}

export const layout = css`
  .${Align.hLeft} {
    display: flex;
    justify-content: flex-start;
  }

  .${Align.hRight} {
    display: flex;
    justify-content: flex-end;
  }

  .${Align.hCenter} {
    display: flex;
    justify-content: center;
  }

  .${Align.vTop} {
    display: flex;
    align-items: flex-start;
  }

  .${Align.vBot} {
    display: flex;
    align-items: flex-end;
  }

  .${Align.vCenter} {
    display: flex;
    align-items: center;
  }

  .${Align.center} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .${Align.around} {
    display: flex;
    justify-content: space-around;
  }

  .${Align.aroundCenter} {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .${Align.between} {
    display: flex;
    justify-content: space-between;
  }

  .${Align.betweenCenter} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .${Align.colLeft} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .${Align.colRight} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }

  .${Align.colCenter} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
