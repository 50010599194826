import { ENVIRONMENT } from '../constants'

const url =
  ENVIRONMENT === 'development'
    ? 'http://localhost:3000'
    : 'https://log.fablecdn.net'

export function page(
  title: string,
  properties = {},
  options: Record<string, any> = {}
): void {
  const pageProperties = {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: title || document.title,
    url: window.location.href,
  }
  const now = new Date().toISOString()
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      context: {
        ...options.context,
        locale: navigator.language || 'en-US',
        page: pageProperties,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userAgent: navigator.userAgent,
        library: {
          name: 'analytics.js',
          version: 'notsegment-0.1',
        },
      },
      messageId: `notsegment-${new Date().getTime()}-${Math.floor(
        Math.random() * 1000000
      )}`,
      properties: { ...properties, ...pageProperties },
      timestamp: now,
      sentAt: now,
      type: 'page',
      userId: null,
    }),
  }).catch((err) => {
    console.error(err)
  })
}
