import { combineReducers } from 'redux'
import authorsReducer from './authorsReducer'
import authReducer from './authReducer'
import billingReducer from './billingReducer'
import booksReducer from './booksReducer'
import clipboardReducer from './clipboardReducer'
import clubsReducer from './clubsReducer'
import errorBannerReducer from './errorBannerReducer'
import foliosReducer from './foliosReducer'
import geoIpReducer from './geoIpReducer'
import giftsReducer from './giftsReducer'
import modalReducer from './modalReducer'
import searchReducer from './searchReducer'
import socialReducer from './socialReducer'
import toastReducer from './toastReducer'
import userReducer from './userReducer'

const reducers = combineReducers({
  auth: authReducer,
  authors: authorsReducer,
  billing: billingReducer,
  books: booksReducer,
  clipboard: clipboardReducer,
  clubs: clubsReducer,
  errorBanner: errorBannerReducer,
  folios: foliosReducer,
  geoIp: geoIpReducer,
  gifts: giftsReducer,
  modal: modalReducer,
  search: searchReducer,
  user: userReducer,
  social: socialReducer,
  toast: toastReducer,
})

export default reducers

export type RootState = ReturnType<typeof reducers>
