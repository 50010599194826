import { css, useTheme } from '@fable/theme'

export const MenuDrawerIcon = () => {
  const { colors } = useTheme()

  return (
    <span
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `}
    >
      {[1, 2, 3].map((_, i) => (
        <span
          key={i}
          className={css`
            display: block;
            background-color: ${colors.graphite};
            width: 100%;
            height: 1px;
          `}
        />
      ))}
    </span>
  )
}
