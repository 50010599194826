import { useEffect, useState } from 'react'

type IABType = 'instagram' | 'tiktok' | 'facebook' | 'none'

const getInAppBrowserType = (userAgent: string): IABType => {
  if (userAgent.match(/Instagram/i)) return 'instagram'
  if (userAgent.match(/TikTok|BytedanceWebview|ByteLocale/i)) return 'tiktok'
  if (userAgent.match(/FBAN|FBAV/i)) return 'facebook'

  return 'none'
}

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isInAppBrowser, setIsInAppBrowser] = useState(false)
  const [inAppBrowser, setInAppBrowser] = useState<IABType>('none')

  // check if device is mobile
  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    const isIpad = !!(
      userAgent.match('Macintosh') && navigator.maxTouchPoints > 0
    )

    const inAppBrowser = !!userAgent.match(
      /Instagram|BytedanceWebview|TikTok|FBAN|FBAV|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv)/i
    )

    setIsMobile(mobile || isIpad)
    setIsInAppBrowser(inAppBrowser)
    setInAppBrowser(getInAppBrowserType(userAgent))
  }, [])

  return { isMobile, isInAppBrowser, inAppBrowser }
}

export default useDeviceDetect
