import { Button, FlexBox, Icon, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useActions } from '../../../../../hooks'

export const InviteReferralInfoModal = () => {
  const { colors } = useTheme()
  const { closeModal } = useActions()

  return (
    <FlexBox
      className={css`
        margin: 0 auto;
        padding: 0 32px 24px;
        max-width: 308px;
      `}
      flexDirection="column"
      centerAll
    >
      <FlexBox
        className={css`
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: ${colors.shamrockLight};
        `}
        centerAll
      >
        <Icon
          className={css`
            width: 24px;
          `}
          type="info"
        />
      </FlexBox>
      <Text
        className={css`
          margin: 12px 0;
        `}
        type="subhead"
        sizing="L"
        tag="h4"
        text="Total earned"
        align="center"
      />
      <Text
        type="body"
        sizing="M"
        text="This is the amount of credits you’ve accumulated so far to spend on Fable. Credits are automatically applied to your order during checkout on the webstore."
        color="graphite"
        align="center"
      />
      <Button
        className={css`
          margin-top: 12px;
        `}
        label="Got it"
        onClick={closeModal}
        outline
      />
    </FlexBox>
  )
}
