const InfoBorderIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 2.00008C4.68631 2.00008 2.00002 4.68637 2.00002 8.00008C2.00002 11.3138 4.68631 14.0001 8.00002 14.0001C11.3137 14.0001 14 11.3138 14 8.00008C14 4.68637 11.3137 2.00008 8.00002 2.00008ZM0.666687 8.00008C0.666687 3.94999 3.94993 0.666748 8.00002 0.666748C12.0501 0.666748 15.3334 3.94999 15.3334 8.00008C15.3334 12.0502 12.0501 15.3334 8.00002 15.3334C3.94993 15.3334 0.666687 12.0502 0.666687 8.00008Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 7.33341C8.36821 7.33341 8.66669 7.63189 8.66669 8.00008V10.6667C8.66669 11.0349 8.36821 11.3334 8.00002 11.3334C7.63183 11.3334 7.33335 11.0349 7.33335 10.6667V8.00008C7.33335 7.63189 7.63183 7.33341 8.00002 7.33341Z"
      fill="white"
    />
    <path
      d="M8.66669 5.33341C8.66669 5.7016 8.36821 6.00008 8.00002 6.00008C7.63183 6.00008 7.33335 5.7016 7.33335 5.33341C7.33335 4.96522 7.63183 4.66675 8.00002 4.66675C8.36821 4.66675 8.66669 4.96522 8.66669 5.33341Z"
      fill="white"
    />
  </svg>
)

export default InfoBorderIcon
