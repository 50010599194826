import { useLocation } from 'react-router-dom'
import { BookReviewModal } from '@fable/components'
import { Review } from '@fable/types'
import { css } from '@fable/theme'
import ReviewPage, { QuoteReview } from '../review_page/ReviewPage'
import { useActions } from 'hooks'
import { GIFTS_ENABLED } from 'app/store/constants'
import { isUrlMatched } from 'utils'
import { routes as socialRoutes } from 'app/social/routes'

const BookReviewPage = () => {
  const location = useLocation()
  const { copyTextToClipboard } = useActions()

  const showGiftBookButton = (item: QuoteReview) =>
    GIFTS_ENABLED &&
    !!item?.book.can_purchase &&
    !item.book.is_free &&
    !isUrlMatched(`${socialRoutes.bookList}/:id`, location.pathname, true)

  return (
    <ReviewPage
      apiBaseUrl="/reviews"
      renderModal={(item: QuoteReview) => (
        <BookReviewModal
          onCopyToClipboard={copyTextToClipboard}
          showGiftBookButton={showGiftBookButton(item)}
          review={item as Review}
          titleTag="h1"
        />
      )}
      getMetaData={(item: QuoteReview) =>
        item && {
          title: `${item.user.display_name}'s review`,
          image: (item as any).review_image,
        }
      }
      getClassName={(item: QuoteReview) => css`
        background: ${item?.book?.background_color};
      `}
    ></ReviewPage>
  )
}

export default BookReviewPage
