import { useNavigate } from 'react-router-dom'
import { css, cx, useTheme } from '@fable/theme'
import { Button, FlexBox } from '@fable/components'
import { DEFAULT_ERROR_MESSAGE } from '../../constants'
import { useActions } from '../../hooks'
import { routes } from 'app/marketing/routes'

const ErrorCard: React.FC<
  { message?: string } & React.HTMLProps<HTMLDivElement>
> = ({ message, ...nativeProps }) => {
  const { colors, fonts } = useTheme()
  const navigate = useNavigate()
  const { clearModal } = useActions()

  const handleClick = () => {
    clearModal()
    navigate(routes.home)
  }

  return (
    <div
      data-testid="errorCard"
      {...nativeProps}
      className={cx(
        css`
          max-width: 311px;
        `,
        nativeProps.className
      )}
    >
      <FlexBox
        centerAll
        flexDirection="column"
        className={css`
          background: ${colors.whiteFang};
          padding: 32px 39px;
        `}
      >
        <b
          className={css`
            text-align: center;
            font-family: ${fonts.body};
            font-size: 18px;
            color: ${colors.blackSwan};
          `}
        >
          Something went wrong
        </b>
        <div
          className={css`
            text-align: center;
            margin-top: 12px;
            font-family: ${fonts.body};
            font-size: 14px;
            line-height: 20.36px;
            color: ${colors.grey};
          `}
        >
          {message || DEFAULT_ERROR_MESSAGE}
        </div>
        <Button
          onClick={handleClick}
          width="94px"
          height="40px"
          className={css`
            flex: 0 0 auto;
            margin-top: 27px;
            padding: 0;
          `}
        >
          Okay
        </Button>
      </FlexBox>
    </div>
  )
}

export default ErrorCard
