import { Text, TextInput, TextInputProps } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useState } from 'react'

export const PasswordField = ({
  containerClass,
  ...nativeProps
}: TextInputProps) => {
  const { colors } = useTheme()
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  return (
    <TextInput
      containerClass={cx(
        css`
          position: relative;
        `,
        containerClass
      )}
      type={showPassword ? 'text' : 'password'}
      {...nativeProps}
    >
      <button
        className={css`
          width: 48px;
          height: 40px;
          border-radius: 12px;
          position: absolute;
          right: 10px;
          top: 53%;
          transform: translateY(-50%);
          border: 0;
          background-color: ${colors.uiWhite};
          cursor: pointer;
        `}
        onClick={toggleShowPassword}
        type="button"
      >
        <Text
          className={css`
            line-height: 14px;
          `}
          type="body"
          sizing="M"
          color={colors.hurricane}
        >
          {showPassword ? 'Hide' : 'Show'}
        </Text>
      </button>
    </TextInput>
  )
}
