import { IconSVGProps } from '../types'

export const ForbidIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_195_52376)">
      <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM8.523 7.109L16.891 15.477C16.5015 16.0236 16.0236 16.5015 15.477 16.891L7.109 8.523C7.49858 7.97673 7.97646 7.49919 8.523 7.11V7.109Z" />
    </g>
    <defs>
      <clipPath id="clip0_195_52376">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
