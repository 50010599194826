import { Book, CartItemAddInput } from '@fable/types'
import { useDeviceDetect } from '@fable/hooks'
import { useSelector } from '@xstate/react'
import { isBookPreorder } from '@fable/utils'
import { findItemInCart } from 'state'
import { BookModal } from 'app/store/components/books/book_modal/BookModal'
import { PurchaseButtonContent } from 'app/store/components/purchase_button/PurchaseButtonContent'
import { BOOK_APP_LINK } from '../constants'
import { handleDeeplink } from 'utils'
import { useActions } from './useActions'
import { useBookOwned } from './useBookOwned'
import { useTypedSelector } from './useTypedSelector'
import { useCart } from './useCart'
import { analytics } from 'segment_analytics'

export const useBuyBook = ({
  book,
  isTestActive = false,
}: {
  book: Book
  isTestActive?: boolean
}) => {
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { service, openCart } = useCart()
  const state = useSelector(service, (state) => state)
  const ctx = state.context
  const cart = ctx.cart
  const cartId = ctx.id
  const inCart = findItemInCart({ cart, id: book?.id })
  const { openModal } = useActions()
  const { isMobile } = useDeviceDetect()
  const { owned, sampled } = useBookOwned({ bookId: book?.id })
  const preorder = isBookPreorder(book)

  const price = book?.price_usd
  const ownedBook = isAuthenticated && owned && !sampled
  const unownedFreeBook = book?.is_free && !owned
  const unbuyable = price === null

  const loading =
    state.matches('addingItem') &&
    state.event.type === 'ADD_ITEM' &&
    state.event.data.product_id === book?.id

  const openBook = () => {
    if (isMobile) {
      const link = `${BOOK_APP_LINK}/${book?.id}`
      handleDeeplink(link)
      return
    }

    openModal(<BookModal book={book} isOwned={owned} />)
  }

  const handleAddToCart = () => {
    if (inCart) return openCart()
    const cartExists = !!cart && !!cartId
    const data: CartItemAddInput = {
      product_id: book?.id,
      product_type: 'book',
      quantity: 1,
    }

    service.send({
      type: cartExists ? 'ADD_ITEM' : 'CREATE',
      data,
    })

    const { itemAdded, created } = analytics.events.cart

    if (cartExists) return itemAdded(data)
    created(data)
  }

  const onSelectBook = () => {
    if (unbuyable) return window.open(book.url, '_blank')
    if ((ownedBook && !sampled) || book.is_free) return openBook()
    handleAddToCart()
  }

  const createBuyButton = () => {
    const createButtonContent = () => {
      if (inCart) return 'In Cart'

      if (unbuyable) return 'View'

      if (unownedFreeBook) {
        return <PurchaseButtonContent short="Read" long="in the app" />
      }

      if (preorder) {
        if (owned) return <PurchaseButtonContent short="Preordered" />
        return <PurchaseButtonContent short={`Preorder $${price}`} />
      }

      if (owned && !sampled) {
        return <PurchaseButtonContent short="Open" long="in the app" />
      }

      let buyText = `${isTestActive ? 'Buy ebook · ' : ''}$${price}`

      return <PurchaseButtonContent short={buyText} />
    }

    return {
      props: {
        disabled: owned && preorder,
      },
      content: createButtonContent(),
    }
  }

  return {
    onSelectBook,
    owned,
    sampled,
    inCart,
    loading,
    preorder,
    unownedFreeBook,
    button: createBuyButton(),
  }
}
