import { TextStyleProps, TextStyleMapping } from '../types'

const baseStyles: TextStyleProps = {
  fontFamily: `'Inter', serif`,
}

const styles: TextStyleMapping = {
  XL: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '-0.3px',
  },
  L: {
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.3px',
  },
  M: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
  },
  S: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },
  XS: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.2px',
  },
}

export const subheadStyles = { styles, baseStyles }
