import { bodyStyles } from './bodyStyles'
import { headerStyles } from './headerStyles'
import { labelStyles } from './labelStyles'
import { linkStyles } from './linkStyles'
import { noteStyles } from './noteStyles'
import { scriptStyles } from './scriptStyles'
import { subheadStyles } from './subheadStyles'

export const textStyles = {
  header: headerStyles,
  subhead: subheadStyles,
  body: bodyStyles,
  label: labelStyles,
  link: linkStyles,
  script: scriptStyles,
  note: noteStyles,
}
