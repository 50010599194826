import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { captureMessage } from '../../../utils/error'

const AuthHandler = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const search = location.search
  const mode = new URLSearchParams(search).get('mode')

  useEffect(() => {
    if (mode === 'verifyEmail') {
      navigate(
        {
          pathname: routes.verifyEmail,
          search: search,
        },
        { replace: true }
      )
    } else if (mode === 'resetPassword') {
      navigate(
        {
          pathname: routes.resetPassword,
          search: search,
        },
        { replace: true }
      )
    } else {
      captureMessage(`Unknown auth mode ${mode}`)
      navigate('/404')
    }
  }, [navigate, mode, search])

  return <>One moment...</>
}

export default AuthHandler
