import { useQuery } from 'react-query'
import { User } from '@fable/types'
import { Redirect } from '@fable/legacy'
import { css } from '@fable/theme'
import { InvitePageHero } from './InvitePageHero'
import { InvitePageValueProps } from './InvitePageValueProps'
import SupportLink from '../../../../components/support_link'
import { getReferralId } from '../../../../utils'
import { Page } from '../../../../components/Page'
import referralHeadImage from '../../../../assets/images/graphics/referral-credit-illustration.webp'
import { getReferralName } from '../../helpers'

const InvitePage = () => {
  const { id } = getReferralId()
  const hasReferralId = !!id

  const { data } = useQuery('referral', () => getReferralName(id as string), {
    retry: false,
  })

  if (!hasReferralId) {
    return <Redirect to="/404" />
  }

  const referralUser = data?.data as User

  return (
    <Page
      className={css`
        padding-bottom: 36px;
      `}
      head={{
        title: '🎁📚 Here’s $5 off an ebook on Fable, the social reading app',
        image: referralHeadImage,
      }}
    >
      <InvitePageHero referralName={referralUser?.display_name} />
      <InvitePageValueProps />
      <SupportLink />
    </Page>
  )
}

export default InvitePage
