import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import AppStoreLink from '../../../../components/app_store_link/AppStoreLink'
import { APP_DOWNLOAD_DETAILS } from '../../../../constants'

export const InviteWelcomePageLinks = () => {
  const { colors } = useTheme()

  return (
    <FlexBox justifyContent="center">
      {APP_DOWNLOAD_DETAILS.map((provider, index) => (
        <AppStoreLink
          className={css`
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44px;
            overflow: hidden;
            background-color: ${colors.blackSwan};
            max-width: 136px;
            img {
              width: 80%;
              display: block;
            }
          `}
          provider={provider}
          imageType="ultraDark"
          key={index}
        />
      ))}
    </FlexBox>
  )
}
