import { IconSVGProps } from '../types'

export const ShareIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_252_33828)">
      <path d="M6 19H18V14H20V20C20 20.2652 19.8946 20.5196 19.7071 20.7071C19.5196 20.8946 19.2652 21 19 21H5C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V14H6V19Z" />
      <path d="M6.914 10.778L10.864 6.828L10.864 16L12.864 16L12.864 6.828L16.814 10.778L18.228 9.364L11.864 3L5.5 9.364L6.914 10.778Z" />
    </g>
    <defs>
      <clipPath id="clip0_252_33828">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
