import { css, cx, useTheme } from '@fable/theme'
import { FOOTER_LINKS } from '../../constants'
import { FlexBox, Text } from '@fable/components'
import Links from '../links'

export const FooterLinks: React.VFC<{ className?: string }> = ({
  className,
}) => {
  const { colors, breakpoints, mediaQueries } = useTheme()

  const styles = {
    linksWrap: css`
      margin-top: 32px;
      flex-grow: 1;
      flex-wrap: wrap;

      ${mediaQueries.tabletL} {
        margin-top: -50px;
      }
    `,
    linkSection: css`
      margin-top: 50px;
      width: 45%;
      :not(:last-child) {
        margin-right: 5%;
      }

      ${mediaQueries.mobileL} {
        width: 40%;
        :not(:last-child) {
          margin-right: 10%;
        }
      }

      @media only screen and (min-width: ${breakpoints.tablet}px) and (max-width: 880px) {
        width: 50%;
        :not(:last-child) {
          margin-right: 0;
          padding-right: 5%;
        }
      }

      ${mediaQueries.desktop} {
        width: auto;
      }
    `,
    linkHeader: css`
      margin: 4px 0 28px;
      text-transform: uppercase;
      font-weight: 600;
    `,
    linkItem: css`
      transition: opacity ease 0.2s;

      :not(:last-child) {
        margin-bottom: 12px;
      }

      :hover {
        opacity: 0.7;
      }

      ${mediaQueries.tablet} {
        :not(:last-child) {
          margin-bottom: 20px;
        }
      }
    `,
    link: css`
      color: ${colors.whiteFang};
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
    `,
  }

  const links = FOOTER_LINKS.map((links, index) => (
    <div key={index} className={styles.linkSection}>
      <Text
        className={styles.linkHeader}
        type="label"
        sizing="L"
        text={links.title}
        color={colors.hurricane}
      />
      <div>
        <Links
          links={links.links}
          itemClass={styles.linkItem}
          linkClass={styles.link}
        />
      </div>
    </div>
  ))

  return <FlexBox className={cx(styles.linksWrap, className)}>{links}</FlexBox>
}
