import { Button, FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { getCmsAsset } from '@fable/utils'
import { Ref, forwardRef } from 'react'
import { CMS_GALLERY } from '../../lib/queries'
import { useQuery } from '@apollo/client'
import { CMSGallery } from '@fable/types'
import { ScrollingBanner } from '../scrolling_banner'
import { AppearInView } from '../motion'
import { useDeeplinkModal } from 'components/app_deep_link_modal'

const Content = ({ ...props }, ref: Ref<HTMLDivElement>) => {
  const { colors, mediaQueries } = useTheme()
  const { openDeeplinkModal } = useDeeplinkModal()
  const { data, loading } = useQuery(CMS_GALLERY, {
    variables: { id: '71216wgfzM5EGWZoaR6HK8' },
  })
  const gallery = data?.gallery as CMSGallery
  const images = gallery?.imagesCollection?.items || []
  const amount = 0.3

  return (
    <div
      ref={ref}
      className={css`
        padding: 60px 0;
        width: 100%;
        position: relative;
        background-color: ${colors.uiBlack};
        ${mediaQueries.mobileL} {
          padding-top: 80px;
        }
      `}
      {...props}
    >
      <FlexBox
        className={css`
          padding: 0 24px;
        `}
        justifyContent="center"
      >
        <FlexBox
          className={css`
            width: 100%;
            max-width: 370px;
            ${mediaQueries.tablet} {
              max-width: 560px;
            }
          `}
          flexDirection="column"
          alignItems="center"
        >
          <AppearInView amount={amount}>
            <img
              className={css`
                display: block;
                width: 60px;
              `}
              src={getCmsAsset(
                '1OsuB3wptmmTLFccXDD61t/23bb28cbac8a5d5ce6b51b05dae3aa94/fortune-logo.webp'
              )}
              alt="fortune logo"
            />
          </AppearInView>
          <AppearInView amount={amount}>
            <Text
              className={css`
                margin-top: 24px;
                ${mediaQueries.tablet} {
                  margin-top: 28px;
                }
              `}
              tag="h2"
              type="header"
              custom={{
                fontSize: '32px',
                lineHeight: '32px',
                [mediaQueries.tablet]: {
                  fontSize: '48px',
                  lineHeight: '42px',
                },
              }}
              weight="500"
              color={colors.uiPearlExtraLight}
              align="center"
            >
              “A more thoughtful—and less toxic—social media app”
            </Text>
            <Text
              className={css`
                margin: 24px auto 40px;
                font-size: 16px;
                ${mediaQueries.tablet} {
                  margin-top: 16px;
                }
              `}
              type="body"
              sizing="M"
              color={colors.uiWhite50}
              weight="500"
              align="center"
            >
              Fortune
            </Text>
          </AppearInView>
          <AppearInView amount={amount}>
            <Button
              className={css`
                width: 160px;
              `}
              onClick={() =>
                openDeeplinkModal({ hideSMS: true, skipModalOnMobile: true })
              }
              negative
            >
              Get the app
            </Button>
          </AppearInView>
        </FlexBox>
      </FlexBox>
      <AppearInView amount={amount}>
        <ScrollingBanner
          className={css`
            margin-top: 60px;
            ${mediaQueries.tablet} {
              margin-top: 80px;
            }
          `}
          loading={loading}
          images={images}
          imgSize={{ width: { mobile: '240px', desktop: '384px' } }}
          spacing={{ mobile: 12, desktop: 24 }}
          duration={20000}
        />
      </AppearInView>
    </div>
  )
}

export const AppreciationPosts = forwardRef(Content)
