import { downloads } from './downloads'

const downloadWhitePaper = () =>
  downloads.downloadDocument({
    link: 'https://assets.ctfassets.net/005nulnwwll2/4IoO4X5Obtg9LN6Spot6mh/ae3fd1909d0ac43fe111145032cfe6de/2023.01.10_Future_of_Work.pdf',
    name: '2023.01.10 Future of Work.pdf',
  })

export const helpers = {
  downloadWhitePaper,
}
