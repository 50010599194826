import { useLocation, useParams } from 'react-router-dom'

export const useInviteId = () => {
  let { inviteId }: { inviteId?: string } = useParams()
  const location = useLocation()
  if (!inviteId) {
    inviteId = new URLSearchParams(location.search?.slice(1)).get(
      'invite'
    ) as string
  }
  return inviteId
}
