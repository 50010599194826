const InfoIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99984 13.6667C10.6798 13.6667 13.6665 10.6801 13.6665 7.00008C13.6665 3.32008 10.6798 0.333416 6.99984 0.333415C3.31984 0.333415 0.333173 3.32008 0.333172 7.00008C0.333172 10.6801 3.31984 13.6667 6.99984 13.6667ZM6.99984 6.33341C7.3665 6.33341 7.6665 6.63341 7.6665 7.00008L7.6665 9.66675C7.6665 10.0334 7.3665 10.3334 6.99984 10.3334C6.63317 10.3334 6.33317 10.0334 6.33317 9.66675L6.33317 7.00008C6.33317 6.63341 6.63317 6.33341 6.99984 6.33341ZM6.33317 3.66675L7.66651 3.66675L7.66651 5.00008L6.33317 5.00008L6.33317 3.66675Z"
      fill="#D1CFD0"
    />
  </svg>
)

export default InfoIcon
