import { useDeviceDetect } from '@fable/hooks'
import { AppDeepLinkModalDesktop } from './AppDeepLinkModalDesktop'
import { AppDeepLinkModalProps } from './types'
import { AppDeepLinkModalMobile } from './AppDeepLinkModalMobile'
import { getDeeplink } from 'services/singular'

export const AppDeepLinkModal = ({
  link = getDeeplink('app'),
  title = 'Download the app',
  ...props
}: AppDeepLinkModalProps) => {
  const { isMobile } = useDeviceDetect()

  return isMobile ? (
    <AppDeepLinkModalMobile link={link} title={title} {...props} />
  ) : (
    <AppDeepLinkModalDesktop link={link} title={title} {...props} />
  )
}
