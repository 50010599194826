import { IconSVGProps } from '../types'

export const SendIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.09601 0.703985C8.4907 0.0986719 7.5093 0.0986719 6.90398 0.703985L0.703984 6.90398C0.0986718 7.5093 0.0986718 8.4907 0.703984 9.09601C1.3093 9.70133 2.2907 9.70133 2.89602 9.09601L6.45 5.54203V14.2C6.45 15.056 7.14396 15.75 8 15.75C8.85604 15.75 9.55 15.056 9.55 14.2V5.54203L13.104 9.09601C13.7093 9.70133 14.6907 9.70133 15.296 9.09601C15.9013 8.4907 15.9013 7.5093 15.296 6.90398L9.09601 0.703985Z"
      fill="white"
    />
  </svg>
)
