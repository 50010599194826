export enum FolioTypes {
  // GET FOLIO BOOKS
  GET_FOLIO_BOOKS__ERROR = 'GET_FOLIO_BOOKS__ERROR',
  GET_FOLIO_BOOKS__REQUEST = 'GET_FOLIO_BOOKS__REQUEST',
  GET_FOLIO_BOOKS__SUCCESS = 'GET_FOLIO_BOOKS__SUCCESS',

  // GET FOLIO DETAILS
  GET_FOLIO_DETAILS__CLEAR = 'GET_FOLIO_DETAILS__CLEAR',
  GET_FOLIO_DETAILS__ERROR = 'GET_FOLIO_DETAILS__ERROR',
  GET_FOLIO_DETAILS__REQUEST = 'GET_FOLIO_DETAILS__REQUEST',
  GET_FOLIO_DETAILS__SUCCESS = 'GET_FOLIO_DETAILS__SUCCESS',

  // GET FOLIOS
  GET_FOLIOS__ERROR = 'GET_FOLIOS__ERROR',
  GET_FOLIOS__REQUEST = 'GET_FOLIOS__REQUEST',
  GET_FOLIOS__SUCCESS = 'GET_FOLIOS__SUCCESS',
}
