import { css, cx, useTheme } from '@fable/theme'
import './Checkbox.scss'
import { LegacyRef, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

const Checkbox: React.VFC<
  {
    isChecked: boolean
    onChange?: () => void
    children?: React.ReactNode | React.ReactNode[]
    customClass?: string
    formConfig?: {
      name: string
      formRef: LegacyRef<HTMLInputElement> | undefined
    }
    isRadio?: boolean
    label?: React.ReactNode
    labelClass?: string
    clickAll?: boolean
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  isChecked,
  onChange,
  children,
  customClass = '',
  formConfig,
  isRadio,
  label,
  labelClass,
  clickAll = false,
  ...nativeProps
}) => {
  const { colors } = useTheme()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (isChecked) {
      setIsActive(true)
    }
  }, [isChecked])

  const handleClick = () => {
    setIsActive(!isActive)
    if (onChange) onChange()
  }

  const renderFormInput = () => {
    if (!formConfig) return null

    const { name, formRef } = formConfig

    return (
      <input
        style={{ display: 'none' }}
        id={formConfig.name || nativeProps.id}
        ref={formRef}
        name={name}
        type="checkbox"
        checked={isActive}
        onChange={() => {}}
      />
    )
  }

  const renderLabel = () => (
    <label
      className={cx(
        css`
          color: ${colors.hurricane};
        `,
        labelClass
      )}
      htmlFor={formConfig?.name || nativeProps.id}
    >
      {label}
    </label>
  )

  const checked =
    isChecked || (!isEmpty(formConfig) && isActive) ? 'checked' : ''
  const radio = isRadio ? 'radio' : ''

  return (
    <div
      {...nativeProps}
      className={cx(
        `checkbox ${customClass} ${checked} ${radio} `,
        clickAll &&
          css`
            cursor: pointer;
            user-select: none;
          `,
        nativeProps.disabled &&
          css`
            pointer-events: none;
            &.radio div.check-marker:after {
              background: ${colors.greyNeutral};
            }
          `,
        nativeProps.className
      )}
      onClick={() => clickAll && handleClick()}
    >
      <div
        className={'check-marker'}
        onClick={() => !clickAll && handleClick()}
      ></div>
      {children || renderLabel()}
      {renderFormInput()}
    </div>
  )
}

export default Checkbox
