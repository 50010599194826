import { css } from '@fable/theme'
import ErrorCard from './ErrorCard'

const errorCardModalPayload = (error?: any) => ({
  modalType: css`
    align-items: center !important;
    justify-content: center !important;
    div.modal {
      max-width: 311px;
      max-height: 463px;
      overflow-y: auto;
      border-radius: 20px;
      > div.close-modal {
        display: none;
      }
    }
  `,
  content: (
    <ErrorCard
      className={css`
        margin-top: -30px;
      `}
      message={error?.response?.data?.detail || error?.message || `${error}`}
    />
  ),
})

export default errorCardModalPayload
