import { CreditBalance, Offers, PriceInterval } from '@fable/types'
import { FormatAmountOptions } from '../types'

export const formatAmountDescription = (
  amount: number,
  interval?: PriceInterval
) => {
  if (!interval || amount === 0) return `$${amount}`

  return `$${amount}/${interval === 'year' ? 'yr' : 'mo'}`
}

export const getOffer = (offerData: Offers | undefined) =>
  offerData && offerData.offers[0]

export const userCreditAvailable = (creditBalance: CreditBalance) =>
  !!creditBalance && creditBalance.amount !== 0

export const formatAmount = ({
  value,
  currency = 'USD',
  locale = 'en-US',
  ...options
}: FormatAmountOptions) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  })

  return formatter.format(value)
}
