import { useInterpret } from '@xstate/react'
import { createContext, useState } from 'react'
import { cartMachine } from 'state/machines'
import { CompProps } from 'types'
import { ActorRefFrom } from 'xstate'

export type CartContextProps = {
  service: ActorRefFrom<typeof cartMachine>
  open: boolean
  openCart: () => void
  closeCart: () => void
}

export const CartContext = createContext({} as CartContextProps)

export const CartProvider = ({ children }: CompProps) => (
  <CartContext.Provider value={useCartProvider()}>
    {children}
  </CartContext.Provider>
)

const useCartProvider = () => {
  const service = useInterpret(cartMachine)
  const [open, setOpen] = useState(false)

  const openCart = () => setOpen(true)
  const closeCart = () => setOpen(false)

  return { service, open, openCart, closeCart }
}
