import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_PREMIUM_CLUBS = gql`
  query PremiumClubs {
    premiumClubList(id: "2ztbyU93iyJY1xhKDnZYB1", preview: ${isPreview}) {
      clubsCollection {
        items {
          name
          customTitle
          subtitle
          description
          image {
            url
          }
          pattern
          color
          videoId
          slug
        }
      }
    }
  }
`
