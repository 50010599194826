import { cx, css, useTheme } from '@fable/theme'
import { FlexBox, Logo, TransitionElement } from '@fable/components'
import { Club } from '@fable/types'
import { usePreloadImage } from '@fable/hooks'
import { getTheme } from '../../app/clubs/utils'

const ClubThumbnail: React.VFC<{
  clubData?: Club
  className?: string
}> = ({ clubData, className }) => {
  const { colors } = useTheme()
  const image = usePreloadImage(
    clubData?.current_book?.cover_image_small || '',
    45
  )
  const theme = getTheme(clubData?.theme as string)

  if (!clubData?.current_book?.cover_image_small) {
    return (
      <FlexBox
        centerAll
        className={cx(
          css`
            position: relative;
            width: 60px;
            background: ${colors.almostWhite};
          `,
          className
        )}
      >
        <Logo isIcon />
      </FlexBox>
    )
  }

  return (
    <div
      className={cx(
        css`
          position: relative;
          width: 60px;
          background: ${theme.background};
          border-radius: 3px 100px 3px 3px;
        `,
        className
      )}
    >
      {image?.src?.length ? (
        <TransitionElement transitionType="fadeIn">
          <img
            className={css`
              position: absolute;
              bottom: 8px;
              left: 8px;
              width: 45px;
              border-radius: 2px;
            `}
            src={image.src}
            alt={`${clubData?.title || ''}`}
          />
        </TransitionElement>
      ) : (
        <div
          className={css`
            position: absolute;
            bottom: -20px;
            left: 12px;
            .logo.animate svg {
              width: 30px;
            }
          `}
        >
          <Logo isIcon isLight animate />
        </div>
      )}
    </div>
  )
}

export default ClubThumbnail
