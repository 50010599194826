export enum SocialTypes {
  // GET USER BY SLUG
  USER_LOOKUP__CLEAR = 'USER_LOOKUP__CLEAR',
  USER_LOOKUP__REQUEST = 'USER_LOOKUP__REQUEST',
  USER_LOOKUP__SUCCESS = 'USER_LOOKUP__SUCCESS',
  USER_LOOKUP__ERROR = 'USER_LOOKUP__ERROR',

  // GET BOOK LISTS
  GET_BOOK_LISTS__CLEAR = 'GET_BOOK_LISTS__CLEAR',
  GET_BOOK_LISTS__REQUEST = 'GET_BOOK_LISTS__REQUEST',
  GET_BOOK_LISTS__SUCCESS = 'GET_BOOK_LISTS__SUCCESS',
  GET_BOOK_LISTS__ERROR = 'GET_BOOK_LISTS__ERROR',

  // GET CLUBS
  GET_CLUBS__CLEAR = 'GET_CLUBS__CLEAR',
  GET_CLUBS__REQUEST = 'GET_CLUBS__REQUEST',
  GET_CLUBS__SUCCESS = 'GET_CLUBS__SUCCESS',
  GET_CLUBS__ERROR = 'GET_CLUBS__ERROR',

  // POST FOLLOW USERS
  POST_FOLLOW_USER__REQUEST = 'POST_FOLLOW_USER__REQUEST',
  POST_FOLLOW__SUCCESS = 'POST_FOLLOW__SUCCESS',
  POST_FOLLOW_USER__ERROR = 'POST_FOLLOW_USER__ERROR',

  // DELETE FOLLOW USERS
  DELETE_FOLLOW_USER__REQUEST = 'DELETE_FOLLOW_USER__REQUEST',
  DELETE_FOLLOW_USER__SUCCESS = 'DELETE_FOLLOW_USER__SUCCESS',
  DELETE_FOLLOW_USER__ERROR = 'DELETE_FOLLOW_USER__ERROR',

  // GET FOLLOWERS
  GET_FOLLOWERS__REQUEST = 'GET_FOLLOWERS__REQUEST',
  GET_FOLLOWERS__SUCCESS = 'GET_FOLLOWERS__SUCCESS',
  GET_FOLLOWERS__ERROR = 'GET_FOLLOWERS__ERROR',

  // GET FOLLOWING
  GET_FOLLOWING__REQUEST = 'GET_FOLLOWING__REQUEST',
  GET_FOLLOWING__SUCCESS = 'GET_FOLLOWING__SUCCESS',
  GET_FOLLOWING__ERROR = 'GET_FOLLOWING__ERROR',
}
