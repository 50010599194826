import { IconSVGProps } from '../types'

export const GoogleIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M9.99998 3.86667C11.8778 3.86667 13.1444 4.67778 13.8667 5.35556L16.6889 2.6C14.9555 0.988889 12.7 0 9.99998 0C6.08887 0 2.71109 2.24444 1.06665 5.51111L4.29998 8.02222C5.11109 5.61111 7.35554 3.86667 9.99998 3.86667Z" />
    <path d="M19.6 10.2222C19.6 9.39996 19.5333 8.79996 19.3889 8.17773H10V11.8888H15.5111C15.4 12.8111 14.8 14.2 13.4667 15.1333L16.6222 17.5777C18.5111 15.8333 19.6 13.2666 19.6 10.2222Z" />
    <path d="M4.31111 11.9778C4.1 11.3556 3.97778 10.6889 3.97778 10C3.97778 9.31111 4.1 8.64444 4.3 8.02222L1.06667 5.51111C0.388889 6.86666 0 8.38889 0 10C0 11.6111 0.388889 13.1333 1.06667 14.4889L4.31111 11.9778Z" />
    <path d="M9.99986 20C12.6999 20 14.9665 19.1111 16.6221 17.5778L13.4665 15.1333C12.6221 15.7222 11.4887 16.1333 9.99986 16.1333C7.35541 16.1333 5.11097 14.3889 4.31097 11.9778L1.07764 14.4889C2.72208 17.7556 6.08875 20 9.99986 20Z" />
  </svg>
)
