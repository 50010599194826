import { FlexBox } from '@fable/components'
import { cx, css, useTheme } from '@fable/theme'
import { CarouselControl } from './CarouselControl'
import { CarouselController, CarouselControlProps } from './types'

export const CarouselControls = ({
  controller,
  controlProps,
  gap = '4px',
  className,
  dataTestId,
}: {
  controller: CarouselController
  controlProps?: Omit<CarouselControlProps, 'controller'>
  gap?: string
  className?: string
  dataTestId?: string
}) => {
  const { mediaQueries } = useTheme()

  return (
    <FlexBox
      className={cx(
        css`
          display: none;
          ${mediaQueries.tablet} {
            display: ${controller.allSlidesInView ? 'none' : 'flex'};
          }
        `,
        className
      )}
      data-test-id={dataTestId}
      alignItems="center"
      justifyContent="flex-end"
      gap={gap}
    >
      <CarouselControl controller={controller} left {...controlProps} />
      <CarouselControl controller={controller} {...controlProps} />
    </FlexBox>
  )
}
