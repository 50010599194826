import { create } from 'zustand'

export type HeaderTheme = 'primary' | 'secondary'

export interface HeaderConfig {
  theme?: HeaderTheme
  hidden?: boolean
  mobileSublinksHidden?: boolean
  fillSpace?: boolean
}

export const defaultHeaderConfig: HeaderConfig = {
  theme: 'primary',
  hidden: false,
  mobileSublinksHidden: true,
  fillSpace: true,
}

export interface HeaderState {
  config: HeaderConfig
  setConfig: (config: HeaderConfig) => void
}

export const useHeader = create<HeaderState>((set) => ({
  config: defaultHeaderConfig,
  setConfig: (config) => set({ config: { ...defaultHeaderConfig, ...config } }),
}))
