import { css, cx } from '@fable/theme'
import pluralize from 'pluralize'
import errorBird from '../../assets/images/graphics/empty_states/error/main-image.svg'
import curveBackground from '../../assets/images/graphics/curve-background.svg'
import { FlexBox, Button, Text } from '@fable/components'

const TimeoutAlertCard: React.VFC<
  {
    seconds: number
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ seconds, ...nativeProps }) => {
  return (
    <FlexBox
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      {...nativeProps}
      className={cx(
        css`
          position: relative;
          background-image: url(${curveBackground});
          background-size: cover;
          background-repeat: no-repeat;
          padding: 0px 20px;
        `,
        nativeProps.className
      )}
    >
      <img
        src={errorBird}
        alt="A red and pink bird chirping. You have been inactive and are prompted to continue or be automatically logged out."
        className={css`
          height: 243px;
          margin: 35px 0px 0px -40px;
        `}
      />
      <Text
        className={css`
          text-align: center;
        `}
        type="header"
        sizing={{ base: 'M', mobileL: 'L' }}
      >
        Still there?
      </Text>

      <Text
        type="body"
        sizing={{ base: 'M', mobileL: 'L' }}
      >{`You will be logged out in ${pluralize(
        'second',
        seconds,
        true
      )}`}</Text>
      {/* see signoutCountdownSaga for button click event listener */}
      <Button
        className={cx(
          css`
            margin-top: 35px;
          `
        )}
        id="timeout-card-button"
        label="Continue using Fable"
      />
    </FlexBox>
  )
}

export default TimeoutAlertCard
