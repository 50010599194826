import { css, cx, useTheme } from '@fable/theme'
import { HomeBlock } from '../HomeBlock'
import { HomeFeaturesImage } from '../../lib/types'
import { FlexBox, Text } from '@fable/components'
import { useRef } from 'react'
import {
  Carousel,
  useAutoplay,
  useCarouselController,
} from 'components/carousel'
import { HomeFeaturesCarouselControls } from './HomeFeaturesCarouselControls'

interface Props {
  images: HomeFeaturesImage[]
  controlsOuter?: boolean
  className?: string
}

export const HomeFeaturesCarouselMobile = ({
  images,
  className = '',
  controlsOuter = false,
}: Props) => {
  const { mediaQueries } = useTheme()
  const controller = useCarouselController()
  const timerDuration = 5000
  const autoplay = useAutoplay({ duration: timerDuration })
  const ref = useRef<HTMLDivElement>(null)
  const activeIndex = controller.currentSlide

  const controls = (
    <HomeFeaturesCarouselControls
      controller={controller}
      timerDuration={timerDuration}
    />
  )

  return (
    <div
      className={css`
        display: block;
        ${mediaQueries.tablet} {
          display: none;
        }
      `}
    >
      <HomeBlock
        ref={ref}
        className={cx(
          css`
            display: flex;
            width: 100%;
            border-radius: 24px;
            padding: 24px;
            overflow: hidden;
            ${mediaQueries.mobileL} {
              padding: 40px 24px 34px;
            }
          `,
          className
        )}
      >
        <FlexBox
          className={css`
            position: relative;
            width: 100%;
          `}
        >
          <Carousel
            className={cx(
              css`
                width: 100%;
              `,
              !controlsOuter
                ? css`
                    padding-bottom: 24px;
                    ${mediaQueries.mobileL} {
                      padding-bottom: 34px;
                    }
                  `
                : ''
            )}
            options={{
              loop: true,
              drag: false,
              defaultAnimation: {
                duration: 0,
              },
              slides: {
                perView: 1,
                origin: 'center',
                spacing: 24,
              },
            }}
            plugins={[autoplay]}
            controller={controller}
          >
            {images.map((img, i) => (
              <FlexBox
                key={i}
                className={cx(
                  css`
                    width: 100%;
                    height: 100%;
                    opacity: ${i === activeIndex ? 1 : 0};
                    transition: opacity 0.6s ease;
                    pointer-events: none;
                  `,
                  img.containerStyles
                )}
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  className={cx(
                    css`
                      margin-bottom: 24px;
                      display: block;
                      width: 100%;
                      max-width: 160px;
                      font-size: 44px;
                      ${mediaQueries.mobileL} {
                        margin-bottom: 40px;
                        max-width: 260px;
                        font-size: 70px;
                      }
                    `,
                    img.titleStyles
                  )}
                  type="note"
                  sizing="M"
                  align="center"
                >
                  {img.title}
                </Text>
                <img
                  key={i}
                  className={cx(
                    css`
                      display: block;
                      width: 100%;
                      height: 100%;
                      max-width: 420px;
                      object-fit: contain;
                      object-position: center;
                    `,
                    img.imageStyles
                  )}
                  src={img.image}
                  alt={img.altText}
                />
              </FlexBox>
            ))}
          </Carousel>
        </FlexBox>
        {!controlsOuter ? controls : null}
      </HomeBlock>
      <FlexBox
        className={css`
          margin-top: 36px;
          width: 100%;
        `}
        justifyContent="center"
      >
        {controlsOuter ? controls : null}
      </FlexBox>
    </div>
  )
}
