import { GiftOrder, GiftRecipientsResponse } from '@fable/types'
import { Response } from '../../types'
import { GiftsActions } from '../actions'
import { GiftsTypes } from '../action-types'
import {
  setSuccessState,
  setRequestState,
  setErrorState,
  setClearState,
} from '../stateHandlers'

enum GiftsKeys {
  giftOrderLocal = 'giftOrderLocal',
  submitGiftOrderDraftResponse = 'submitGiftOrderDraftResponse',
  giftCardDetails = 'giftCardDetails',
  giftClaimed = 'giftClaimed',
}

interface GiftsState {
  [GiftsKeys.giftOrderLocal]: GiftOrder
  [GiftsKeys.submitGiftOrderDraftResponse]: Response<GiftOrder>
  [GiftsKeys.giftCardDetails]: Response<GiftRecipientsResponse>
  [GiftsKeys.giftClaimed]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialGiftObject: GiftOrder = {
  line_items: null,
  recipients: null,
  delivery_method: 'email',
  delivery_date: new Date(),
}

const initialState: GiftsState = {
  [GiftsKeys.giftOrderLocal]: initialGiftObject,
  [GiftsKeys.submitGiftOrderDraftResponse]: props,
  [GiftsKeys.giftCardDetails]: props,
  [GiftsKeys.giftClaimed]: props,
}

const reducer = (
  state: GiftsState = initialState,
  action: GiftsActions
): GiftsState => {
  const stateProps = { state, props }

  switch (action.type) {
    // SET GIFT ORDER
    case GiftsTypes.SET_GIFT_ORDER:
      return {
        ...state,
        [GiftsKeys.giftOrderLocal]: action.payload,
      }

    case GiftsTypes.SET_GIFT_ORDER__CLEAR:
      return {
        ...state,
        // This state needs to be reset to default data with placeholders, it shouldn't be cleared completely
        [GiftsKeys.giftOrderLocal]: initialGiftObject,
      }

    // SUBMIT GIFT ORDER DRAFT
    case GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__REQUEST:
      return setRequestState(stateProps, GiftsKeys.submitGiftOrderDraftResponse)
    case GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__SUCCESS:
      return setSuccessState(
        stateProps,
        GiftsKeys.submitGiftOrderDraftResponse,
        action.payload
      )
    case GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__ERROR:
      return setErrorState(
        stateProps,
        GiftsKeys.submitGiftOrderDraftResponse,
        action.payload
      )
    case GiftsTypes.SUBMIT_GIFT_ORDER_DRAFT__CLEAR:
      return setClearState(stateProps, GiftsKeys.submitGiftOrderDraftResponse)

    // GET GIFT CARD DETAILS
    case GiftsTypes.GET_GIFT_CARD_DETAILS__REQUEST:
      return setRequestState(stateProps, GiftsKeys.giftCardDetails)
    case GiftsTypes.GET_GIFT_CARD_DETAILS__SUCCESS:
      return setSuccessState(
        stateProps,
        GiftsKeys.giftCardDetails,
        action.payload
      )
    case GiftsTypes.GET_GIFT_CARD_DETAILS_ERROR:
      return setErrorState(
        stateProps,
        GiftsKeys.giftCardDetails,
        action.payload
      )
    case GiftsTypes.GET_GIFT_CARD_DETAILS__CLEAR:
      return setClearState(stateProps, GiftsKeys.giftCardDetails)

    // CLAIM GIFT
    case GiftsTypes.SET_GIFT_CLAIMED__REQUEST:
      return setRequestState(stateProps, GiftsKeys.giftClaimed)
    case GiftsTypes.SET_GIFT_CLAIMED__SUCCESS:
      return setSuccessState(stateProps, GiftsKeys.giftClaimed, action.payload)
    case GiftsTypes.SET_GIFT_CLAIMED__ERROR:
      return setErrorState(stateProps, GiftsKeys.giftClaimed, action.payload)
    case GiftsTypes.SET_GIFT_CLAIMED__CLEAR:
      return setClearState(stateProps, GiftsKeys.giftClaimed)

    default:
      return state
  }
}

export default reducer
