import { IconSVGProps } from '../types'

export const MicrosoftIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill={fill}
  >
    <title>MS-SymbolLockup</title>
    <rect x="1" y="1" width="9" height="9" />
    <rect x="1" y="11" width="9" height="9" />
    <rect x="11" y="1" width="9" height="9" />
    <rect x="11" y="11" width="9" height="9" />
  </svg>
)
