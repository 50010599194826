import { IconSVGProps } from '../types'

export const RefreshIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_258_168)">
      <path d="M4.55246 3.69416C6.06459 2.38388 7.99911 1.66387 9.99996 1.66666C14.6025 1.66666 18.3333 5.3975 18.3333 10C18.3333 11.78 17.775 13.43 16.825 14.7833L14.1666 10H16.6666C16.6667 8.69302 16.2827 7.41483 15.5622 6.32438C14.8417 5.23392 13.8166 4.3793 12.6143 3.86677C11.412 3.35424 10.0856 3.20642 8.79992 3.44168C7.51429 3.67694 6.32617 4.28491 5.38329 5.19L4.55246 3.69416ZM15.4475 16.3058C13.9353 17.6161 12.0008 18.3361 9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 8.22 2.22496 6.57 3.17496 5.21666L5.83329 10H3.33329C3.33319 11.307 3.71725 12.5852 4.43774 13.6756C5.15823 14.7661 6.18335 15.6207 7.38564 16.1332C8.58794 16.6458 9.91436 16.7936 11.2 16.5583C12.4856 16.3231 13.6737 15.7151 14.6166 14.81L15.4475 16.3058Z" />
    </g>
    <defs>
      <clipPath id="clip0_258_168">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>
)
