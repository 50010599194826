import { FlexBox, Icon, Tag, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { PlanSelectionOption } from './types'

interface Props {
  plan: PlanSelectionOption
  selected: string
  onSelect: (id: string) => void
}

export const PlanSelectionItem = ({ plan, selected, onSelect }: Props) => {
  const { colors, motion } = useTheme()
  const isSelected = selected === plan.id

  return (
    <div
      className={css`
        margin-top: 16px;
        padding: ${isSelected ? 16 : 8}px 16px;
        border: 1px solid ${colors.graphite}${isSelected ? 'cc' : '1a'};
        border-radius: 8px;
        background-color: ${colors.whiteFang};
        position: relative;
        cursor: pointer;
        ${motion.transition('border-color')};
        &:hover {
          border-color: ${colors.graphite}cc;
        }
      `}
      onClick={() => onSelect(plan.id as string)}
      data-testid={`${plan.id}${isSelected ? '-selected' : ''}`}
    >
      <div
        className={css`
          width: 86%;
        `}
      >
        <Tag
          title={plan.tag}
          backgroundColor={colors.whiteSmoke}
          color={`${colors.graphite}cc`}
          textTransform="none"
        />
        <Text
          className={css`
            margin: 8px 0 4px;
            cursor: inherit;
          `}
          type="subhead"
          sizing="S"
          tag="h4"
          text={plan.title}
        />
        <Text
          type="label"
          sizing="L"
          color={colors.graphite}
          text={plan.description as string}
        />
        <ul
          className={css`
            max-height: ${isSelected ? 300 : 0}px;
            padding-left: 16px;
            overflow: hidden;
            list-style-type: disc;
          `}
        >
          {plan.benefits.map((b, i) => (
            <Text
              className={css`
                display: list-item;
              `}
              key={i}
              type="label"
              sizing="L"
              tag="li"
              text={b}
              color={colors.graphite}
            />
          ))}
        </ul>
      </div>
      <FlexBox
        className={css`
          width: 24px;
          height: 24px;
          border-radius: 4px;
          background-color: ${colors.almostWhite};
          position: absolute;
          right: 19px;
          top: 50%;
          transform: translateY(-50%);
          opacity: ${isSelected ? 1 : 0};
          pointer-events: none;
        `}
        centerAll
      >
        <Icon type="check" stroke={colors.graphite} />
      </FlexBox>
    </div>
  )
}
