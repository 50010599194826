import { analytics } from 'segment_analytics'
import { FeatureFlagKey, FeatureFlagAccess } from './types'
import { featureFlagConfig } from './config'
import { User } from '@fable/types'
import { ENVIRONMENT } from '../../constants'
import { useFeatureFlags } from './useFeatureFlags'
import { getParameterByName } from '@fable/utils'

// KG experiment: switch off anonymous Segment tracking
// This event fires with every page load - looking to see if we can reduce usage by silencing it.
// Can potentially bring this back the next time we run a test, maybe using `notsegment`.
const SPLIT_TESTS_ENABLED = false

export const triggerSplitTest = (flags: FeatureFlagKey[]) => {
  featureFlagConfig.forEach((flag) => {
    if (
      !SPLIT_TESTS_ENABLED ||
      !flag.enabled ||
      flag.source === 'client' ||
      !flag.splitTest?.length
    )
      return

    const flagExists = !!flags.includes(flag.name)

    analytics.events.splitTestTriggered({
      name: flag.splitTest,
      cohort: flagExists ? 'test' : 'control',
    })
  })
}

export const getFeatureFlagAccess = ({
  name,
  access,
  user,
}: {
  name: FeatureFlagKey
  access: FeatureFlagAccess | undefined
  user: User | null
}) => {
  const ffParam = getParameterByName('ff')
  if (!!ffParam && ffParam === name) return true
  if (access === 'staging') return ENVIRONMENT !== 'production'
  if (access === 'internal') return !!user?.email?.includes('@fable.co')
  return access === 'open'
}

export const useVertExpEnabled = () => {
  const flagEnabled = useFeatureFlags((s) => s.flagEnabled)
  return flagEnabled('vertExp')
}
