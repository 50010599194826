import { TextStyleProps, TextStyleMapping } from '../types'

const baseStyles: TextStyleProps = {
  fontFamily: `'Palmer Lake Print', sans-serif`,
}

const styles: TextStyleMapping = {
  M: {
    fontSize: '24px',
    lineHeight: '0.5em',
    letterSpacing: '-0.02em',
  },
}

export const noteStyles = { styles, baseStyles }
