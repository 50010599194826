import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

export interface ScrollTopPosition {
  previous: number
  current: number
}

export const useScrollTop = (
  callback: (scrollPosition: ScrollTopPosition) => void
) => {
  const [, setScrollPosition] = useState(0)
  let previous = 0

  const handleDocumentScroll = () => {
    const { scrollTop: current } = document.documentElement || document.body

    setScrollPosition((previousPosition) => {
      previous = previousPosition
      return current
    })
    callback({ previous, current })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleDocumentScrollThrottled)
    }
  }, [handleDocumentScrollThrottled])
}
