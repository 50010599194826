import { IconSVGProps } from '../types'

export const XIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path d="m13.86,10.47l7.29-8.47h-1.73l-6.33,7.35-5.05-7.35H2.22l7.64,11.12-7.64,8.88h1.73l6.68-7.77,5.34,7.77h5.83l-7.92-11.53h0Zm-2.36,2.75l-.77-1.11L4.56,3.3h2.65l4.97,7.11.77,1.11,6.46,9.24h-2.65l-5.27-7.54h0Z" />
  </svg>
)
