import { css, cx, useTheme } from '@fable/theme'
import { Review } from '@fable/types'
import { FlexBox, Logo, Emoji, ReviewStars } from '@fable/components'

export const BookReviewLinkUnfurlImage: React.VFC<{
  review?: Review
  className?: string
}> = ({ review, className = '' }) => {
  const { colors } = useTheme()

  if (!review) return null

  const reviewColor = review?.book?.background_color

  return (
    <FlexBox
      data-testid="reviewLinkUnfurlImage"
      className={cx(
        css`
          width: 400px;
          height: 400px;
          background-color: ${reviewColor};
          border-radius: 32px;
          position: relative;
          overflow: hidden;
        `,
        className
      )}
      centerAll
    >
      {/* fable icon */}
      <FlexBox
        className={css`
          width: 52px;
          height: 52px;
          border-radius: 18px;
          background-color: ${colors.blackSwan}30;
          position: absolute;
          top: 14px;
          left: 14px;
        `}
        centerAll
      >
        <Logo
          className={css`
            width: 36px;
            svg {
              width: 100%;
            }
          `}
          isIcon
          isLight
        />
      </FlexBox>
      {/* book cover image */}
      <div
        className={css`
          width: 214px;
          height: 316px;
          border-radius: 14px;
          overflow: hidden;
          box-shadow: 0px 29.6932px 39.5909px rgba(0, 0, 0, 0.06);
          z-index: 1;
        `}
      >
        <img
          data-testid="reviewLinkUnfurlBookCover"
          className={css`
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          `}
          src={review?.book?.cover_image_small || ''}
          alt={`${review?.book?.title}cover`}
        />
      </div>
      {/* book rating */}
      <FlexBox
        className={css`
          position: relative;
          width: 214px;
          height: 316px;
          border-radius: 14px;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
        `}
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
      >
        {review?.emoji_reaction && (
          <Emoji emoji={review?.emoji_reaction} size={116} />
        )}
        <div
          className={css`
            margin: 24px 0 7px;
          `}
        >
          <ReviewStars
            readOnly
            color={colors.whiteFang}
            rating={review?.rating || 0}
            size={32}
            spacing={11}
          />
        </div>
      </FlexBox>
      {/* background gradient */}
      <span
        className={css`
          display: block;
          background: linear-gradient(180deg, transparent, ${reviewColor} 55%);
          width: 100%;
          height: 50%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
        `}
      />
    </FlexBox>
  )
}
