import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_CAREER_CLUBS = gql`
  query CareerClubs {
    careerClubsList(id: "2JfZeiTmY4dJ1214HmR2po", preview: ${isPreview}) {
      careerClubsCollection {
        items {
          slug
          graphic {
            url
          }
          coachImage {
            url
          }
          header
          backgroundColor
          coachFullName
          coachShortName
          coachTitle
        }
      }
    }
  }
`
