import { serializeStyles } from '@emotion/serialize'
import { StyleSheet } from '@emotion/sheet'
import { serialize, compile, middleware, rulesheet, stringify } from 'stylis'

// https://github.com/emotion-js/emotion/issues/2131#issuecomment-732744875
export default function injectGlobal(...args: any) {
  const { name, styles } = serializeStyles([...args], {})
  const sheet = new StyleSheet({
    key: `global-${name}`,
    container: document.head,
  })
  const stylis = (styles: any) =>
    serialize(
      compile(styles),
      middleware([
        stringify,
        rulesheet((rule: any) => {
          sheet.insert(rule)
        }),
      ])
    )
  stylis(styles)
  return () => sheet.flush()
}
