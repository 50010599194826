import { IconSVGProps } from '../types'

export const ThumbsUpOutlineIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_195_51885)">
      <path d="M14.6 8.00039H21C21.5304 8.00039 22.0391 8.21111 22.4142 8.58618C22.7893 8.96125 23 9.46996 23 10.0004V12.1044C23.0003 12.3658 22.9493 12.6246 22.85 12.8664L19.755 20.3814C19.6795 20.5646 19.5513 20.7213 19.3866 20.8315C19.2219 20.9417 19.0282 21.0005 18.83 21.0004H2C1.73478 21.0004 1.48043 20.895 1.29289 20.7075C1.10536 20.52 1 20.2656 1 20.0004V10.0004C1 9.73518 1.10536 9.48082 1.29289 9.29329C1.48043 9.10575 1.73478 9.00039 2 9.00039H5.482C5.6421 9.00043 5.79986 8.96203 5.94203 8.88843C6.0842 8.81482 6.20662 8.70815 6.299 8.57739L11.752 0.850392C11.8209 0.752705 11.9226 0.682953 12.0385 0.653782C12.1545 0.624612 12.277 0.637955 12.384 0.691392L14.198 1.59839C14.7085 1.85355 15.1163 2.27571 15.3537 2.79471C15.5911 3.31371 15.6438 3.89831 15.503 4.45139L14.6 8.00039ZM7 10.5884V19.0004H18.16L21 12.1044V10.0004H14.6C14.2954 10.0003 13.9948 9.93073 13.7212 9.79685C13.4476 9.66297 13.2082 9.46836 13.0213 9.22788C12.8343 8.98739 12.7048 8.70739 12.6425 8.40922C12.5803 8.11105 12.5869 7.80259 12.662 7.50739L13.565 3.95939C13.5933 3.84872 13.5828 3.73171 13.5353 3.62782C13.4878 3.52393 13.4062 3.43944 13.304 3.38839L12.643 3.05839L7.933 9.73039C7.683 10.0844 7.363 10.3744 7 10.5884ZM5 11.0004H3V19.0004H5V11.0004Z" />
    </g>
    <defs>
      <clipPath id="clip0_195_51885">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
