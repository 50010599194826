import { get } from '../fable'

const getUser = async (userId: string) => await get(`/v2/users/${userId}/`)

const getUserPrefs = async (userId: string) =>
  await get(`/v2/users/${userId}/prefs`)

export const user = {
  getUser,
  getUserPrefs,
}
