import { cx, css, useTheme, ThemeModes } from '@fable/theme'
import { Virtuoso } from 'react-virtuoso'
import { DropdownPropsItem, DropdownPropsItemWithSearch } from '../types'
import { useContext } from 'react'
import DropdownContext from './DropdownContext'
import getInputTheme from '../utils/getInputTheme'

/**
 * This component renders a custom dropdown
 * See DropdownMobileList for list rendered on mobile devices
 */

const DropdownDesktopList = () => {
  const context = useContext(DropdownContext)
  const {
    searchable,
    theme = 'light',
    inputRef,
    showDropdown,
    setShowDropdown,
    itemsFiltered,
  } = context
  const { nativeOverrides } = useTheme()
  const { hoverBackground, textColor, hoverTextColor } = getInputTheme(
    theme as ThemeModes
  )

  const handleItemClick = (
    item: DropdownPropsItem | DropdownPropsItemWithSearch
  ) => {
    if (item.onClick) {
      item.onClick(item)
    }
    setShowDropdown(false)
  }

  return (
    <>
      {!searchable && (
        <div
          /**
           * When searchable is false, this component still needs
           * to capture clicks without allowing input
           * Rather than disabling the input when not searchable,
           * which prevents focusing (used to style the dropdown)
           * placing this above the input solves both of those problems
           */
          className={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            // must be above the input element
            z-index: 3;
          `}
          onClick={(e) => {
            e.stopPropagation()
            if (inputRef && !showDropdown) {
              inputRef.current?.focus()
            }
            setShowDropdown(!showDropdown)
          }}
        />
      )}
      <div
        className={cx(
          css`
            position: absolute;
            top: 20px;
            padding-top: 20px;
            left: -1px;
            width: calc(100% + 2px);
            height: max(140px, 9.7vw);
            border: inherit;
            border-top: none;
            border-radius: 0px 0px 22px 22px;
            background: inherit;
            overflow: auto;
            // must be above other elements on the page
            z-index: 1;
          `,
          showDropdown
            ? css`
                display: block;
              `
            : css`
                display: none;
              `
        )}
      >
        {/* for performance with large data sets https://virtuoso.dev/ */}
        <Virtuoso
          totalCount={itemsFiltered.length}
          data={itemsFiltered}
          itemContent={(i, item) => (
            <button
              key={i}
              onClick={(e) => {
                e.stopPropagation()
                handleItemClick(item)
              }}
              type="button"
              className={cx(
                nativeOverrides.buttonClear,
                css`
                  width: 100%;
                  height: inherit;
                  color: ${textColor};
                  height: 28px;
                  padding: 0 20px;
                  &:hover {
                    background: ${hoverBackground};
                    color: ${hoverTextColor};
                  }
                `
              )}
            >
              {item.component}
            </button>
          )}
        />
      </div>
    </>
  )
}

export default DropdownDesktopList
