import { Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { InviteValueProp } from '../../types'

export const InvitePageValueProp = ({ item }: { item: InviteValueProp }) => {
  const { colors } = useTheme()

  return (
    <div
      className={css`
        width: 100%;
        padding: 20px;
        background-color: ${colors.whiteSmoke}50;
        border-radius: 12px;
      `}
    >
      <img
        className={css`
          height: 104px;
        `}
        srcSet={`${item.imageS} 116w, ${item.imageL} 293w`}
        alt={item.altText}
      />
      <Text
        className={css`
          margin: 12px 0;
        `}
        type="subhead"
        sizing="XL"
        text={item.title}
      />
      <Text
        type="body"
        sizing="M"
        text={item.subtitle}
        color={colors.graphite}
      />
    </div>
  )
}
