import { useTypedSelector } from './useTypedSelector'

export const useUser = () => {
  const {
    profile: { data: user, loading: userLoading },
  } = useTypedSelector(({ user }) => user)
  const { isAuthenticated, isVerifying } = useTypedSelector(({ auth }) => auth)

  const authenticated = !userLoading && !!user && isAuthenticated
  const authUser = authenticated ? user : null

  return { user: authUser, loading: userLoading || isVerifying }
}
