import { PageDetailSection } from './PageDetailSection'
import { PageDetailSection as PageDetailSectionType } from './types'

interface Props {
  sections: PageDetailSectionType[]
}

export const PageDetailSectionMap = ({ sections }: Props) => {
  const renderSections = sections.map((section, i) => (
    <PageDetailSection key={i} {...section} />
  ))

  return <div>{renderSections}</div>
}
