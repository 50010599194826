import { css, cx, useTheme } from '@fable/theme'
import { COPYRIGHT_TEXT, LEGAL_LINKS } from '../../../constants'
import { Button, FlexBox, Text } from '@fable/components'
import { Link } from 'react-router-dom'
import { WindowWithAJS } from 'services/onetrust/types'
import { useGeo } from 'hooks'

export const FooterBottom: React.VFC = () => {
  const { colors, motion, mediaQueries, nativeOverrides } = useTheme()
  const { isCA } = useGeo()

  const linkStyles = css`
    ${motion.transition('color')};
    color: ${colors.hurricane};
    &:hover {
      color: ${colors.whiteFang};
    }
  `

  return (
    <FlexBox
      className={css`
        margin-top: 44px;
        padding-top: 40px;
        border-top: 1px solid ${colors.greyStrong};
        ${mediaQueries.tablet} {
          justify-content: space-between;
        }
      `}
    >
      <Text
        className={css`
          flex-shrink: 0;
          margin-right: 10%;
          width: 40%;
          ${mediaQueries.tablet} {
            margin-right: 0;
            width: auto;
          }
        `}
        type="body"
        sizing="M"
        text={COPYRIGHT_TEXT}
        color={colors.hurricane}
      />
      <FlexBox
        className={css`
          flex-direction: column;
          gap: 12px;
          ${mediaQueries.tablet} {
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 12px 25px;
          }
        `}
      >
        {LEGAL_LINKS.map((link, i) => (
          <Link
            key={i}
            className={css`
              ${mediaQueries.mobile} {
                width: 170px;
              }
              ${mediaQueries.tablet} {
                width: auto;
              }
            `}
            to={link.link}
          >
            <Text
              key={i}
              className={linkStyles}
              type="body"
              sizing="M"
              text={link.title}
            />
          </Link>
        ))}
        {isCA ? (
          <Button
            className={cx(
              css`
                ${nativeOverrides.buttonClear};
                font-weight: 400;
              `,
              linkStyles
            )}
            onClick={() => {
              const wd = window as WindowWithAJS
              wd.OneTrust?.ToggleInfoDisplay()
            }}
          >
            Do not sell my personal information
          </Button>
        ) : null}
      </FlexBox>
    </FlexBox>
  )
}
