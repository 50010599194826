import { routes } from '../../app/community/routes'
import { lazyWithRetry } from '../../utils'
import { pageMetadata } from '../pageMetadata'
import { AppRouteProps } from '../types'

const Blog = lazyWithRetry(() => import('../../app/community/pages/blog/Blog'))
const BlogDetail = lazyWithRetry(
  () => import('../../app/community/pages/blog_detail/BlogDetail')
)
const Events = lazyWithRetry(
  () => import('../../app/community/pages/events/Events')
)
const EventsPageHandler = lazyWithRetry(
  () => import('../../app/community/pages/EventPageHandler')
)
const EventConfirmation = lazyWithRetry(
  () => import('../../app/community/pages/EventConfirmation')
)

export const communityRoutes: AppRouteProps[] = [
  {
    path: routes.blog,
    element: <Blog />,
    head: pageMetadata.blog,
  },
  {
    path: `${routes.blog}/:slug`,
    element: <BlogDetail />,
  },
  {
    path: routes.events,
    element: <Events />,
    head: pageMetadata.events,
  },
  {
    path: `${routes.events}/:slug`,
    element: <EventsPageHandler />,
  },
  {
    path: routes.eventRsvpConfirmation,
    element: <EventConfirmation />,
    footer: { hidden: true },
    head: pageMetadata.eventConfirmation,
  },
]
