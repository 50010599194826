import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const FAQ_LIST = gql`
  query FaqList($id: String!) {
    faqList(id: $id, preview: ${isPreview}) {
        title
        listCollection(limit: 10) {
          items {
              title
              description {
                  json
              }
          }
      }
    }
  }
`
