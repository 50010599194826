import { PageDetailAnchorNav } from './anchor_nav'
import { PageDetailBanner } from './PageDetailBanner'
import { PageDetailColumns } from './PageDetailColumns'
import { PageDetailFooter } from './PageDetailFooter'
import { PageDetailSectionMap } from './PageDetailSectionMap'
import { PageDetailProps } from './types'

export const PageDetail = ({
  sections,
  columns,
  header,
  footer,
  url,
  category,
  bannerText,
  giftBookId,
  mini,
  ...nativeProps
}: PageDetailProps) => {
  return (
    <div {...nativeProps}>
      {!!bannerText && !mini ? <PageDetailBanner text={bannerText} /> : null}
      {mini ? null : (
        <PageDetailAnchorNav
          anchors={sections}
          shareOptions={{ url, category }}
          giftBookId={giftBookId}
        />
      )}
      {columns ? (
        <PageDetailColumns
          header={header}
          content={<PageDetailSectionMap sections={sections} />}
          mini={mini}
        />
      ) : null}
      {!!footer ? <PageDetailFooter footer={footer} /> : null}
    </div>
  )
}
