import { Company } from '@fable/types'
import { Text } from '@fable/components'
import React from 'react'
import { css, FableTheme, useTheme } from '@fable/theme'

export const CompanyHeader: React.FC<{
  company: Company
}> = ({ company }) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <div className={styles.clubHeader} data-testid="auth-company-header">
      <div className={styles.headerInfo}>
        <Text
          type="body"
          sizing="S"
          color={theme.colors.hurricane}
          text="You've been invited by"
        />
        <Text
          type="header"
          sizing={{ base: 'XXS', mobileL: 'XS' }}
          text={company.name}
        />
      </div>
      <div className={styles.headerLogo}>
        <img src={company.logo_image} alt="corporate icon" />
      </div>
    </div>
  )
}

const getStyles = ({ colors }: FableTheme) => ({
  clubHeader: css`
    margin-bottom: 32px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${colors.whiteSmoke};
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.05);
  `,
  headerInfo: css`
    margin-right: 21px;
    width: calc(100% - 68px - 21px);

    label {
      margin-bottom: 8px;
      display: block;
      color: ${colors.hurricane};
    }

    h3 {
      font-size: 24px !important;
      line-height: 24px !important;
    }
  `,
  headerLogo: css`
    width: 68px;
    height: 68px;
    border-radius: 12px;
    border: 1px solid ${colors.whiteSmoke};
    background: ${colors.whiteSmoke};
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  checkboxLabel: css`
    display: block;
    width: 100%;
    color: ${colors.blackSwan};

    a {
      color: ${colors.denim};
    }
  `,
})
