import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { getIntegrationsFromCookie } from 'services/onetrust'
import { getCookie, setCookie } from 'utils/cookie'
import addDays from 'date-fns/addDays'
import { currentSearchParams } from 'utils/url'
import { gtm } from 'services/gtm'
import * as notSegment from './notSegment'
import { ENVIRONMENT } from '../constants'
import { User } from '@fable/types'

const TIKTOK_CLICKID = 'ttclid'

const UTM_PARAMS = ['source', 'medium', 'campaign', 'term', 'content']

function setTrackingCookie(name: string, value: string) {
  setCookie(name, value, addDays(new Date(), 7))
}

function handleTrackingParams() {
  const params = currentSearchParams()
  const tikTokClickId = params.get(TIKTOK_CLICKID)
  if (tikTokClickId) {
    setTrackingCookie(TIKTOK_CLICKID, tikTokClickId)
  }
  for (const paramName of UTM_PARAMS) {
    const paramKey = 'utm_' + paramName
    const paramValue = params.get(paramKey)
    if (paramValue) {
      setTrackingCookie(paramKey, paramValue)
    }
  }
}

const getOptions = (options: Record<string, unknown> | null) => {
  const integrations = getIntegrationsFromCookie()
  const context = getContext()

  return {
    integrations,
    context,
    ...options,
  }
}

function getProperties(properties = {}) {
  const tikTokClickId = getCookie(TIKTOK_CLICKID)
  return tikTokClickId ? { ...properties, ttclid: tikTokClickId } : properties
}

function getContext() {
  const campaign = UTM_PARAMS.reduce(
    (acc: Record<string, unknown>, paramName: string) => {
      const paramKey = 'utm_' + paramName
      const paramValue = getCookie(paramKey)
      if (paramValue) {
        acc[paramName] = paramValue
      }
      return acc
    },
    {}
  )
  if (Object.keys(campaign).length > 0) {
    return { campaign }
  }
  return {}
}

const page = (title: string, properties = {}) => {
  const aid = new URLSearchParams(window.location.search).get('aid')
  let options = null
  if (aid) {
    options = { context: { referrer: { type: 'user', id: aid } } }
  }
  const mergedProps = getProperties(properties)
  const mergedOptions = getOptions(options)
  // KG experiment: switch off anonymous Segment tracking
  // window.analytics.page(title, mergedProps, mergedOptions)
  if (ENVIRONMENT === 'production') {
    notSegment.page(title, mergedProps, mergedOptions)
  }
}

function excludeNullValues(obj: Record<string, unknown>) {
  return pickBy(obj, identity)
}

const identify = (user: User, traits = {}, options = {}) => {
  window.analytics.identify(
    user.id as string,
    excludeNullValues(
      getProperties({
        createdAt: user.signed_up_at || '',
        ...traits,
      })
    ),
    getOptions(options)
  )
}

const reset = () => {
  window.analytics.reset()
}

const track = (event: string, properties = {}, options = {}) => {
  const trackingProps = excludeNullValues(getProperties(properties))
  window.analytics.track(event, trackingProps, getOptions(options))
  gtm.sendEvent(event, { ...trackingProps })
}

const track_conversion = (properties: Record<string, unknown>) => {
  if (window.lintrk) {
    window.lintrk('track', properties)
  }
}

export const main = {
  handleTrackingParams,
  page,
  identify,
  reset,
  track,
  track_conversion,
}
