import { useInterpret } from '@xstate/react'
import { createContext, useContext } from 'react'
import { ClubMachine, clubMachine, ClubMachineOptions } from 'state/machines'
import { CompProps } from 'types'
import { ActorRefFrom } from 'xstate'

export type ClubMachineContextProps = ActorRefFrom<typeof clubMachine>

export const ClubMachineContext = createContext({} as ClubMachineContextProps)

interface Props {
  options?: ClubMachineOptions
}

export const ClubMachineProvider = ({
  children,
  options,
}: CompProps<Props>) => (
  <ClubMachineContext.Provider
    value={useInterpret<ClubMachine>(clubMachine, options)}
  >
    {children}
  </ClubMachineContext.Provider>
)

export const useClubMachine = (): ClubMachineContextProps =>
  useContext(ClubMachineContext)
