import { Route, Routes } from 'react-router-dom'
import { routes } from '../../app/clubs/routes'
import { lazyWithRetry } from '../../utils'
import { AppRouteProps } from '../types'
import { ClubQuizContainer } from 'app/clubs/pages/ClubQuiz'

const ClubHandler = lazyWithRetry(() => import('app/clubs/pages/ClubHandler'))

const ClubChat = lazyWithRetry(() => import('app/clubs/pages/ClubChat'))

const ClubQuizGenres = lazyWithRetry(
  () => import('app/clubs/pages/ClubQuizGenres')
)
const ClubQuizBooks = lazyWithRetry(
  () => import('app/clubs/pages/ClubQuizBooks')
)
const ClubQuizResults = lazyWithRetry(
  () => import('app/clubs/pages/ClubQuizResults')
)

export const clubRoutes: AppRouteProps[] = [
  {
    path: `${routes.club}/:slug`,
    element: <ClubHandler />,
  },
  {
    path: routes.clubQuiz,
    element: (
      <ClubQuizContainer>
        <Routes>
          <Route path={routes.clubQuizGenre} element={<ClubQuizGenres />} />
          <Route path={routes.clubQuizBooks} element={<ClubQuizBooks />} />
          <Route path={routes.clubQuizResults} element={<ClubQuizResults />} />
        </Routes>
      </ClubQuizContainer>
    ),
    footer: { hidden: true },
  },
  {
    path: `${routes.club}/:slug${routes.chat}`,
    element: <ClubChat />,
    footer: { hidden: true },
    header: {
      fillSpace: false,
    },
  },
]
