import { Book } from './bookTypes';
import { CartItem } from './cartTypes';
import { Club } from './clubTypes';
import { PlanDetails } from './planTypes';

export type BundleItemProductObject = Book | Club;

export type BundleItemProductType = 'Book' | 'Club';

// 'free' is client-side
export type PlanType = 'free' | 'all_access' | 'a_la_carte';

export interface Price {
  bundle?: Bundle;
  club?: Partial<Club>;
  id: string;
  recurring: {
    aggregate_usage?: string | null;
    interval: PriceInterval;
    interval_count: number;
    trial_period_days?: number | null;
    usage_type?: PriceUsageType;
  };
  type: PriceType;
  unit_amount: number;
  amount: number;
}

export type PriceType = 'one_time' | 'recurring';

export type PriceInterval = 'month' | 'year';

export type PriceUsageType = 'metered' | 'licensed';

export interface BundleItem {
  id: string;
  is_primary: boolean;
  product_object: BundleItemProductObject;
  product_type: BundleItemProductType;
  quantity: number;
}

export interface Bundle {
  id: string;
  name: string;
  price: Price;
  price_usd: string;
  product_list: BundleItem[];
}

export interface Subscription {
  cancel_at_period_end: boolean;
  canceled_at: string;
  current_period_end: string;
  current_period_start: string;
  ended_at: string;
  id: string;
  plan: {
    type: PlanType;
    amount: number;
    id: string;
    interval: 'month' | 'year' | 'free';
    club: Club;
  };
  status: string;
}

export interface Offer {
  country: string;
  id: string;
  price: Price;
}

export interface Offers {
  offers: Offer[];
  reason?: string;
}
export interface Plan {
  amount?: number;
  id: string;
  interval?: 'month' | 'year';
  type?: PlanType;
  club?: {
    current_book: {
      cover_image_small: string;
      id: string;
    };
    id: string;
    pic: string;
    theme: string;
    title: string;
  };
  title?: string;
}

export interface Order {
  country: string;
  created: string;
  currency: string;
  id: string;
  receipt_url: string;
  title: string;
  total: string;
}

export interface OrderItem {
  item: Book;
  order: Order;
}

export interface CreditBalance {
  amount: number;
}

export interface Card {
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string;
  address_postal_code: string;
  address_state: string;
  name: string | null;
  id: string;
  brand: CardBrand;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export enum CardBrand {
  mastercard = 'MasterCard',
  visa = 'Visa',
  amex = 'Amex',
  other = 'Other',
}

export interface CheckoutItems {
  plan?: Plan | PlanDetails;
  book?: Book;
  bundle?: Bundle;
  cart?: CartItem[];
}
