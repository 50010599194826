import { ReactNode } from 'react'
import { css, cx, useTheme } from '@fable/theme'
import SwipeCard from './SwipeCard'
import { FlexBox } from '..'

const SwipeCards = ({
  onVote,
  emptyListCard = undefined,
  swipe,
  draggable,
  className,
  cards,
}: {
  onVote: (index: number, vote: boolean) => void
  emptyListCard?: ReactNode
  swipe?: { direction: 'left' | 'right' }
  draggable?: boolean
  className?: string
  cards: ReactNode[]
}) => {
  const { mediaQueries } = useTheme()

  const isTop = (index: number) => index === cards.length - 1

  const handleVote = (index: number, result: boolean) => {
    onVote(index, result)
  }

  return (
    <FlexBox
      centerAll
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          ${mediaQueries.tablet} {
            min-width: 360px;
          }
        `,
        className
      )}
    >
      {cards.map((item, index) => (
        <SwipeCard
          key={index}
          drag={!!draggable && isTop(index)}
          onVote={(result) => handleVote(index, result)}
          isTop={isTop(index)}
        >
          {item}
        </SwipeCard>
      ))}
      {!cards.length && emptyListCard && (
        <SwipeCard
          drag={!!draggable}
          onVote={(result) => handleVote(0, result)}
          isTop
        >
          {emptyListCard}
        </SwipeCard>
      )}
    </FlexBox>
  )
}

export default SwipeCards
