/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { ClubActions } from '../actions'
import { ClubTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get, post } from '@fable/api'
import { httpErrorCode } from '../../utils/error'
import { isUUID } from '../../utils/string'
import { keysToSnakeCase } from '../../utils'
import { Club } from '@fable/types'

type DispatchType = Dispatch<ClubActions>

// GET CLUB DETAILS
export const getClubDetails =
  (clubId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.GET_CLUB_DETAILS__REQUEST))
    const clubIdIsUuid = isUUID(clubId)
    const url = clubIdIsUuid ? `/clubs/${clubId}/` : `/clubs/?slug=${clubId}`
    try {
      const { data } = await get(url)
      const clubDetails = clubIdIsUuid ? data : data.results[0]

      dispatch(setAction(ClubTypes.GET_CLUB_DETAILS__SUCCESS, clubDetails))
    } catch (error) {
      dispatch(setAction(ClubTypes.GET_CLUB_DETAILS__ERROR, error))
    }
  }

// CLEAR CLUB DETAILS
export const clearClubDetails = () => (dispatch: DispatchType) => {
  dispatch(setAction(ClubTypes.GET_CLUB_DETAILS__CLEAR))
}

// CREATE CLUB INVITE
export const createClubInvite =
  (clubId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.CREATE_CLUB_INVITE__REQUEST))
    try {
      const url = `/clubs/${clubId}/club_invites/`
      const { data } = await post(url, {})
      dispatch(setAction(ClubTypes.CREATE_CLUB_INVITE__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(ClubTypes.CREATE_CLUB_INVITE__ERROR, error))
    }
  }

// CREATE CLUB MEMBERSHIP
export const createClubMembership =
  (club: Club, startingFrom: string, inviteId?: string) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.CREATE_CLUB_MEMBERSHIP__REQUEST))
    try {
      const url = `/clubs/${club.id}/members/`
      const request = inviteId ? { inviteId } : {}
      const { data } = await post(url, keysToSnakeCase(request))
      dispatch(
        setAction(ClubTypes.CREATE_CLUB_MEMBERSHIP__SUCCESS, {
          clubMembership: data,
          club,
          startingFrom,
        })
      )
    } catch (error) {
      dispatch(setAction(ClubTypes.CREATE_CLUB_MEMBERSHIP__ERROR, error))
    }
  }

// GET FEATURED CLUBS
export const getFeaturedClubs = () => async (dispatch: DispatchType) => {
  dispatch(setAction(ClubTypes.GET_FEATURED_CLUBS__REQUEST))
  try {
    const url = '/featured_clubs/?platform=web'
    const { data } = await get(url)
    dispatch(setAction(ClubTypes.GET_FEATURED_CLUBS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(ClubTypes.GET_FEATURED_CLUBS__ERROR, error))
  }
}

// GET CLUB MEMBERSHIP
export const getClubMembership =
  (clubId: string, userId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.GET_CLUB_MEMBERSHIP__REQUEST))
    try {
      const url = `/v2/clubs/${clubId}/members/${userId}`
      const { data } = await get(url)
      dispatch(setAction(ClubTypes.GET_CLUB_MEMBERSHIP__SUCCESS, data))
    } catch (error) {
      const status = httpErrorCode(error)
      if (status === 404) {
        dispatch(setAction(ClubTypes.GET_CLUB_MEMBERSHIP__SUCCESS, {}))
      } else {
        dispatch(setAction(ClubTypes.GET_CLUB_MEMBERSHIP__ERROR, error))
      }
    }
  }

// CLEAR CLUB MEMBERSHIP
export const clearClubMembership = () => (dispatch: DispatchType) => {
  dispatch(setAction(ClubTypes.GET_CLUB_MEMBERSHIP__CLEAR))
}

// GET CLUB TRACK
export const getClubTrack =
  (trackId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.GET_CLUB_TRACK__REQUEST))
    try {
      const url = `/club_tracks/${trackId}`
      const { data } = await get(url)
      dispatch(setAction(ClubTypes.GET_CLUB_TRACK__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(ClubTypes.GET_CLUB_TRACK__ERROR, error))
    }
  }

// CLEAR CLUB TRACK
export const clearClubTrack = () => (dispatch: DispatchType) => {
  dispatch(setAction(ClubTypes.GET_CLUB_TRACK__CLEAR))
}

// TAPPED CAROUSEL CLUB CARD
export const tappedCarouselClubCard =
  (club: Club) => (dispatch: DispatchType) => {
    dispatch(setAction(ClubTypes.TAPPED_CAROUSEL_CLUB_CARD, { club }))
  }
