/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { GeoIpActions } from '../actions'
import { GeoIpTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { GeoIpCountryResponse } from '../../types/geoIp'

type DispatchType = Dispatch<GeoIpActions>

// GET GEO IP COUNTRY
export const getGeoIpCountry = () => async (dispatch: DispatchType) => {
  dispatch(setAction(GeoIpTypes.GEO_IP_COUNTRY__REQUEST))

  const onSuccess = (res: GeoIpCountryResponse) => {
    dispatch(setAction(GeoIpTypes.GEO_IP_COUNTRY__SUCCESS, res))
  }
  const onError = (error: Error) => {
    dispatch(setAction(GeoIpTypes.GEO_IP_COUNTRY__ERROR, error))
  }

  if (window.geoip2) await window.geoip2.city(onSuccess, onError)
}
