import { css, ClassNamesArg } from '@fable/theme'
import { KeenSliderOptions } from 'keen-slider/react'
import {
  CarouselChildWidth,
  CarouselController,
  CarouselQueryDataResult,
  CarouselSlugs,
} from './types'
import find from 'lodash/find'
import { KeenSliderInstance } from 'keen-slider'

/**
 * FS: Currently there is an issue with keen-slider when using `{ perView: 'auto'}`,
 * where the child element styles are being lost after resizing the
 * window. This function is built to maintain those styles at certain breakpoints until
 * a fix has been implemented.
 */
export const getChildWidthStyles = (childWidths: CarouselChildWidth[]) => {
  let styles: ClassNamesArg = ''
  const space = ' '

  childWidths.forEach((style: CarouselChildWidth) => {
    if (style.breakpoint === 'base') {
      styles +=
        space +
        css`
          min-width: ${style.width} !important;
          max-width: ${style.width} !important;
        `
      return
    }

    const type = style.type ? style.type : 'min'

    styles +=
      space +
      css`
        @media only screen and (${type}-width: ${style.breakpoint}px) {
          min-width: ${style.width} !important;
          max-width: ${style.width} !important;
        }
      `
  })

  return styles
}

/**
 * Helper function to check if `perView: auto`
 * exists in the carousel options
 */
export const isPerViewAuto = (options: KeenSliderOptions | undefined) => {
  if (!options) return false

  let breakpointCheck = false

  if (options.breakpoints) {
    for (const breakpoint in options.breakpoints) {
      const option = options.breakpoints[breakpoint]
      if (option) {
        breakpointCheck = !!find(option, { perView: 'auto' })
      }
    }
  }

  return !!find(options, { perView: 'auto' }) || breakpointCheck
}

export const getCarouselRowQueryData = ({
  data,
}: {
  data: CarouselQueryDataResult
}) => {
  const slugs = data[0][1] as CarouselSlugs
  const title = slugs.listName
  const items = data[1]?.data

  return { title, items }
}

export const initAllSlidesInView = (
  controller: CarouselController,
  slider: KeenSliderInstance
) => {
  const maxIdx = slider?.track?.details?.maxIdx
  const allSlidesInView = maxIdx !== Infinity && maxIdx === 0
  controller?.setAllSlidesInView(allSlidesInView)
}
