import { analytics } from 'segment_analytics'
import { useEffect, useState } from 'react'

export const useBranch = () => {
  useEffect(() => {
    if (!window.branch) return

    const handleCTAClick = () => {
      analytics.events.appDownload()
    }

    window.branch.addListener('didClickJourneyCTA', handleCTAClick)
    return () => {
      window.branch.removeListener('didClickJourneyCTA', handleCTAClick)
    }
  }, [])
}

export const useBranchView = () => {
  const [inView, setInView] = useState(false)
  const branchBannerClass = 'branch-banner-is-active'

  useEffect(() => {
    const checkClass = () => {
      const bodyElement = document.body
      const isClassPresent = bodyElement.classList.contains(branchBannerClass)

      setInView(isClassPresent)

      if (isClassPresent) {
        bodyElement.style.marginTop = '65px'
      } else {
        bodyElement.style.marginTop = '0px'
      }
    }

    checkClass()

    const observer = new MutationObserver(checkClass)

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return inView
}
