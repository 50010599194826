import { useDeviceDetect } from '@fable/hooks'
import { analytics } from 'segment_analytics'
import { AppDeepLinkModal } from 'components/app_deep_link_modal'
import { useActions } from 'hooks'
import { modalHeightAuto } from 'utils'
import { OpenDeeplinkModalProps } from './types'

export const useDeeplinkModal = () => {
  const { openModal } = useActions()
  const { isMobile } = useDeviceDetect()

  const openDeeplinkModal = ({
    skipModalOnMobile,
    eventMetadata,
    ...props
  }: OpenDeeplinkModalProps = {}) => {
    if (!isMobile) analytics.events.appDownload(eventMetadata)

    if (isMobile && skipModalOnMobile) {
      analytics.actions.appDownloadDeeplink(eventMetadata)
      return
    }

    openModal(
      <AppDeepLinkModal eventMetadata={eventMetadata} {...props} />,
      modalHeightAuto
    )
  }

  return { openDeeplinkModal }
}
