import { Helmet } from 'react-helmet-async'
import { PageSchema } from 'seo'
import { formatDescription, formatTitle } from './utils'

const SITE_LOGO = 'https://fable.co/images/fable-opengraph.webp'

export interface HeadProps {
  title?: string
  description?: string
  image?: string
  seoTitle?: string
  seoDescription?: string
  siteName?: string
  httpCode?: number
  canonical?: boolean
  noIndex?: boolean
  isTitleStrict?: boolean
  schema?: PageSchema | PageSchema[]
  url?: string
}

const Head: React.FC<HeadProps> = ({
  title,
  description,
  image,
  seoTitle,
  seoDescription,
  siteName = 'Fable',
  httpCode = 200,
  canonical = true,
  noIndex = false,
  isTitleStrict = false,
  schema,
  url,
}) => {
  const formattedTitle = formatTitle(
    seoTitle || (title as string),
    isTitleStrict
  )

  const formattedDescription = formatDescription(
    seoDescription || (description as string)
  )
  const featuredImage = image || SITE_LOGO
  const formattedUrl = window.location.href.split('?')[0]
  const formattedHttpCode = httpCode.toString()

  return (
    <Helmet prioritizeSeoTags>
      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta name="description" content={formattedDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={formattedUrl} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={formattedDescription} />
      <meta property="og:image" content={featuredImage} />
      <meta property="og:site_name" content={siteName} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={formattedUrl} />
      <meta property="twitter:title" content={formattedTitle} />
      <meta property="twitter:description" content={formattedDescription} />
      <meta property="twitter:image" content={featuredImage} />
      <meta name="twitter:site" content="@getfable" />
      <meta name="twitter:creator" content="@getfable" />
      <meta name="application-name" content="fable" />
      <meta name="prerender-status-code" content={formattedHttpCode} />
      {canonical && <link rel="canonical" href={url || formattedUrl} />}
      {noIndex && <meta name="robots" content="noindex" />}
      {!!schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  )
}

export default Head
