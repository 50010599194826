import { useEffect, useRef } from 'react'
import useAccountNavItems from '../../../../hooks/useAccountNavItems'
import { useSubheader } from '../../../../hooks'
import { css, cx } from '@fable/theme'

const AccountSettings = ({ pageTitle, children, className = '' }) => {
  const accountNavItems = useAccountNavItems()
  const { setSubheaderContent } = useSubheader()
  const linksSet = useRef(false)

  useEffect(() => {
    if (!linksSet.current) {
      linksSet.current = true

      setSubheaderContent({
        title: pageTitle,
        links: accountNavItems,
      })
    }
  }, [setSubheaderContent, pageTitle, accountNavItems])

  return (
    <div
      className={cx(
        css`
          padding-bottom: 100px;

          .header-section.right {
            display: none;
          }

          .empty-state {
            margin-top: 60px;
          }

          .loader {
            margin-top: 32px;
            position: relative;
            height: 50vh;
          }
        `,
        className
      )}
    >
      <div className="page-content">{children}</div>
    </div>
  )
}

export default AccountSettings
