import { useFeatureFlags } from 'hooks'
import InvitePage from './InvitePage'
import InvitePageV2 from './v2/InvitePageV2'
import { FlexBox, Loader, LoaderType } from '@fable/components'
import { css } from '@fable/theme'
import { useEffect, useRef } from 'react'
import { analytics } from 'segment_analytics'

const InvitePageAB = () => {
  const [enabled, loading] = useFeatureFlags((s) => [
    s.flagEnabled('referralInviteV2'),
    s.loading,
  ])
  const mounted = useRef(false)
  const isTreatment =
    process.env.REACT_APP_ENVIRONMENT !== 'production' || enabled

  useEffect(() => {
    if (!loading && !mounted.current) {
      analytics.events.webExperimentBucketing({ isTreatment })
      mounted.current = true
    }
  }, [isTreatment, loading])

  if (loading) {
    return (
      <FlexBox
        className={css`
          width: 100%;
          min-height: 100dvh;
        `}
        centerAll
      >
        <Loader type={LoaderType.contained} />
      </FlexBox>
    )
  }

  return isTreatment ? <InvitePageV2 /> : <InvitePage />
}

export default InvitePageAB
