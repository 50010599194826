import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const VALUE_PROP_LIST = gql`
  query ValuePropList($id: String!) {
    valuePropList(id: $id, preview: ${isPreview}) {
      title
      listCollection(limit: 10) {
        items {
          title
          subtitle
          image {
            url
            title
          }
        }
      }
    }
  }
`
