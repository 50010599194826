import { IconSVGProps } from '../types'

export const DownloadIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_215_8423)">
      <path d="M5.00004 15.8333H15V11.6667H16.6667V16.6667C16.6667 16.8877 16.5789 17.0996 16.4226 17.2559C16.2663 17.4122 16.0544 17.5 15.8334 17.5H4.16671C3.94569 17.5 3.73373 17.4122 3.57745 17.2559C3.42117 17.0996 3.33337 16.8877 3.33337 16.6667V11.6667H5.00004V15.8333Z" />
      <path d="M5.76171 6.85167L9.05337 10.1433L9.05337 2.5L10.72 2.5L10.72 10.1433L14.0117 6.85167L15.19 8.03L9.88671 13.3333L4.58337 8.03L5.76171 6.85167Z" />
    </g>
    <defs>
      <clipPath id="clip0_215_8423">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>
)
