import { IconSVGProps } from '../types'

export const YouTubeIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.9523 5.41492C18.3332 6.89992 18.3332 9.99992 18.3332 9.99992C18.3332 9.99992 18.3332 13.0999 17.9523 14.5849C17.7407 15.4058 17.1215 16.0516 16.3373 16.2699C14.9132 16.6666 9.99984 16.6666 9.99984 16.6666C9.99984 16.6666 5.089 16.6666 3.66234 16.2699C2.87484 16.0483 2.2565 15.4033 2.04734 14.5849C1.6665 13.0999 1.6665 9.99992 1.6665 9.99992C1.6665 9.99992 1.6665 6.89992 2.04734 5.41492C2.259 4.59409 2.87817 3.94825 3.66234 3.72992C5.089 3.33325 9.99984 3.33325 9.99984 3.33325C9.99984 3.33325 14.9132 3.33325 16.3373 3.72992C17.1248 3.95159 17.7432 4.59659 17.9523 5.41492ZM8.33317 12.9166L13.3332 9.99992L8.33317 7.08325V12.9166Z" />
  </svg>
)
