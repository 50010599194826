import { singularSdk } from 'singular-sdk'

export type LinkName = 'app'

const baseLink = 'https://fable.sng.link/Ali7l/3i0m'
const fallbackLink = `${baseLink}?_dl=app`

export const getDeeplink = (name: LinkName) => {
  const link = singularSdk.buildWebToAppLink(baseLink, name)
  return link || fallbackLink
}

export const openDeeplink = (name: LinkName) =>
  singularSdk.openApp(baseLink, name)
