export enum BookTypes {
  // CREATE BOOK LIST
  CREATE_BOOK_LIST = 'CREATE_BOOK_LIST',
  CREATE_BOOK_LIST__CLEAR = 'CREATE_BOOK_LIST__CLEAR',

  // PREPEND BOOK LIST
  PREPEND_BOOK_LIST = 'PREPEND_BOOK_LIST',

  // ADD BOOK LIST
  ADD_BOOK_LIST__REQUEST = 'ADD_BOOK_LIST__REQUEST',
  ADD_BOOK_LIST__SUCCESS = 'ADD_BOOK_LIST__SUCCESS',
  ADD_BOOK_LIST__ERROR = 'ADD_BOOK_LIST__ERROR',

  // ADD FREE BOOK
  ADD_FREE_BOOK__CLEAR = 'ADD_FREE_BOOK__CLEAR',
  ADD_FREE_BOOK__ERROR = 'ADD_FREE_BOOK__ERROR',
  ADD_FREE_BOOK__REQUEST = 'ADD_FREE_BOOK__REQUEST',
  ADD_FREE_BOOK__SUCCESS = 'ADD_FREE_BOOK__SUCCESS',

  // BOOK DETAIL VIEWED
  BOOK_DETAIL__VIEWED = 'BOOK_DETAIL__VIEWED',

  // GET BOOK DETAILS
  GET_BOOK_DETAILS__CLEAR = 'GET_BOOK_DETAILS__CLEAR',
  GET_BOOK_DETAILS__ERROR = 'GET_BOOK_DETAILS__ERROR',
  GET_BOOK_DETAILS__REQUEST = 'GET_BOOK_DETAILS__REQUEST',
  GET_BOOK_DETAILS__SUCCESS = 'GET_BOOK_DETAILS__SUCCESS',

  // GET BOOKS
  GET_BOOKS__ERROR = 'GET_BOOKS__ERROR',
  GET_BOOKS__REQUEST = 'GET_BOOKS__REQUEST',
  GET_BOOKS__SUCCESS = 'GET_BOOKS__SUCCESS',

  // GET BOOK LIST
  GET_BOOK_LIST__ERROR = 'GET_BOOK_LIST__ERROR',
  GET_BOOK_LIST__REQUEST = 'GET_BOOK_LIST__REQUEST',
  GET_BOOK_LIST__SUCCESS = 'GET_BOOK_LIST__SUCCESS',

  // GET BOOK LIST BOOKS
  GET_BOOK_LIST_BOOKS__ERROR = 'GET_BOOK_LIST_BOOKS__ERROR',
  GET_BOOK_LIST_BOOKS__REQUEST = 'GET_BOOK_LIST_BOOKS__REQUEST',
  GET_BOOK_LIST_BOOKS__SUCCESS = 'GET_BOOK_LIST_BOOKS__SUCCESS',

  // GET BOOK LIST BOOK IDS
  GET_BOOK_LIST_BOOK_IDS__ERROR = ' GET_BOOK_LIST_BOOK_IDS__ERROR',
  GET_BOOK_LIST_BOOK_IDS__REQUEST = ' GET_BOOK_LIST_BOOK_IDS__REQUEST',
  GET_BOOK_LIST_BOOK_IDS__SUCCESS = ' GET_BOOK_LIST_BOOK_IDS__SUCCESS',

  // GET CLUB_READY BOOKS
  GET_CLUB_READY_BOOKS__ERROR = 'GET_CLUB_READY_BOOKS__ERROR',
  GET_CLUB_READY_BOOKS__REQUEST = 'GET_CLUB_READY_BOOKS__REQUEST',
  GET_CLUB_READY_BOOKS__SUCCESS = 'GET_CLUB_READY_BOOKS__SUCCESS',

  // GET FEATURED BOOKS
  GET_FEATURED_BOOKS__ERROR = 'GET_FEATURED_BOOKS__ERROR',
  GET_FEATURED_BOOKS__REQUEST = 'GET_FEATURED_BOOKS__REQUEST',
  GET_FEATURED_BOOKS__SUCCESS = 'GET_FEATURED_BOOKS__SUCCESS',

  // GET PURCHASED BOOKS
  GET_PURCHASED_BOOKS__ERROR = 'GET_PURCHASED_BOOKS__ERROR',
  GET_PURCHASED_BOOKS__REQUEST = 'GET_PURCHASED_BOOKS__REQUEST',
  GET_PURCHASED_BOOKS__SUCCESS = 'GET_PURCHASED_BOOKS__SUCCESS',

  // GET WISHLIST
  GET_WISHLIST__ERROR = 'GET_WISHLIST__ERROR',
  GET_WISHLIST__REQUEST = 'GET_WISHLIST__REQUEST',
  GET_WISHLIST__SUCCESS = 'GET_WISHLIST__SUCCESS',

  // ADD TO BOOKLISTS (V2)
  MODIFY_BOOK_LIST__REQUEST = 'MODIFY_BOOK_LIST__REQUEST',
  MODIFY_BOOK_LIST__SUCCESS = 'MODIFY_BOOK_LIST__SUCCESS',
  MODIFY_BOOK_LIST__ERROR = 'MODIFY_BOOK_LIST__ERROR',

  // EDIT BOOK LIST
  EDIT_BOOKLIST__REQUEST = 'EDIT_BOOKLIST__REQUEST',
  EDIT_BOOKLIST__SUCCESS = 'EDIT_BOOKLIST__SUCCESS',
  EDIT_BOOKLIST__ERROR = 'EDIT_BOOKLIST__ERROR',

  // DELETE BOOK LIST
  DELETE_BOOKLIST__REQUEST = 'DELETE_BOOKLIST__REQUEST',
  DELETE_BOOKLIST__SUCCESS = 'DELETE_BOOKLIST__SUCCESS',
  DELETE_BOOKLIST__ERROR = 'DELETE_BOOKLIST__ERROR',

  // REMOVE BOOK FROM BOOK LIST
  REMOVE_BOOK_FROM_BOOK_LIST__REQUEST = 'REMOVE_BOOK_FROM_LIST__REQUEST',
  REMOVE_BOOK_FROM_BOOK_LIST__SUCCESS = 'REMOVE_BOOK_FROM_LIST__SUCCESS',
  REMOVE_BOOK_FROM_BOOK_LIST__ERROR = 'REMOVE_BOOK_FROM_LIST__ERROR',

  // GET BOOK LISTS PAGINATED
  GET_BOOK_LISTS_PAGINATED__REQUEST = 'GET_BOOK_LISTS_PAGINATED__REQUEST',
  GET_BOOK_LISTS_PAGINATED__SUCCESS = 'GET_BOOK_LISTS_PAGINATED__SUCCESS',
  GET_BOOK_LISTS_PAGINATED__ERROR = 'GET_BOOK_LISTS_PAGINATED__ERROR',

  // GET BOOK LISTS PAGINATED
  GET_BOOK_LISTS_PAGINATED__CLEAR = 'GET_BOOK_LISTS_PAGINATED__CLEAR',

  // GET BOOK LISTS IDS BY BOOK
  GET_BOOK_LISTS_IDS_BY_BOOK__REQUEST = 'GET_BOOK_LISTS_IDS_BY_BOOK__REQUEST',
  GET_BOOK_LISTS_IDS_BY_BOOK__SUCCESS = 'GET_BOOK_LISTS_IDS_BY_BOOK__SUCCESS',
  GET_BOOK_LISTS_IDS_BY_BOOK__ERROR = 'GET_BOOK_LISTS_IDS_BY_BOOK__ERROR',
  GET_BOOK_LISTS_IDS_BY_BOOK__CLEAR = 'GET_BOOK_LISTS_IDS_BY_BOOK__CLEAR',

  // SET CHECKED BOOK LISTS
  SET_CHECKED_BOOK_LISTS = 'SET_CHECKED_BOOK_LISTS',

  // GET BOOK LIST PICKER DATA STATUS
  GET_BOOK_LIST_PICKER_DATA__REQUEST = 'GET_BOOK_LIST_PICKER_DATA__REQUEST',
  GET_BOOK_LIST_PICKER_DATA__SUCCESS = 'GET_BOOK_LIST_PICKER_DATA__SUCCESS',
  GET_BOOK_LIST_PICKER_DATA__ERROR = 'GET_BOOK_LIST_PICKER_DATA__ERROR',

  // POST AUTH (used in postAuthSaga)
  POST_AUTH_ADD_BOOK = 'POST_AUTH_ADD_BOOK',
  POST_AUTH_OPEN_APP = 'POST_AUTH_OPEN_APP',
  POST_AUTH_OPEN_SMS = 'POST_AUTH_OPEN_SMS',
}
