import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_ASSET = gql`
  query Asset($id: String!) {
    asset(id: $id, preview: ${isPreview}) {
        title
        url
    }
  }
`
