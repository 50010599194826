import { CompanyInvite } from '@fable/types'
import { get } from '../fable'

const getClubSuggestion = async (bookId: string | undefined) =>
  await get(`/books/${bookId}/club_suggestion`)

const getClubsByGenre = async (ids: string) =>
  await get(`/public_clubs?genre_ids=${ids}`)

const getClubInvite = async ({
  invite,
  email,
}: {
  invite: CompanyInvite
  email: string
}) => {
  if (!invite?.company?.id) return

  const allow404 = (status: number) =>
    (status >= 200 && status < 300) || status === 404

  const response = await get(
    `/companies/${invite.company.id}/allow/${encodeURIComponent(email)}`,
    {
      validateStatus: allow404,
    }
  )

  const result: { response: any; handledError: Error | null } = {
    response,
    handledError: null,
  }

  if (response.status >= 400) {
    throw new Error(
      'Your email is not on the allow list for this club, please contact your program administrator to be added.'
    )
  }

  return result
}

export const club = {
  getClubSuggestion,
  getClubsByGenre,
  getClubInvite,
}
