import { css, cx, useTheme } from '@fable/theme'
import { Review, ShareOption } from '@fable/types'
import { useRef, useState } from 'react'
import { Text, FlexBox, Button, ShareIcon } from '../'
import { useClickOutside, useShareOptions } from '@fable/hooks'

const BookReviewActions = ({
  review,
  showGiftBookButton,
  onCopyToClipboard,
}: {
  review: Review
  /** @note temporary until redux is removed */
  showGiftBookButton: boolean
  onCopyToClipboard: () => void
}) => {
  const { colors, nativeOverrides, shadows } = useTheme()
  const [showShareOptions, setShowShareOptions] = useState(false)
  const options = useShareOptions({
    category: 'review',
    url: review.url,
    showGiftBookButton,
    onCopyToClipboard,
  })
  const shareRef = useRef<HTMLDivElement>(null)

  useClickOutside(shareRef, () => setShowShareOptions(false))

  const handleClickShare = (option: ShareOption) => {
    if (option.isExternal) {
      window.open(option.action() as string, '_blank')
    } else {
      option.action()
    }
    setShowShareOptions(false)
  }

  return (
    <FlexBox data-testid="bookReviewActions" alignItems="center">
      <div>
        <Button
          transparent
          onClick={() => setShowShareOptions(!showShareOptions)}
          className={css`
            width: fit-content;
            margin-right: 10px;
          `}
        >
          <ShareIcon fill={colors.whiteFang} />
        </Button>
        {/* The share and options buttons will be redesigned soon
        this is temporary */}
        {showShareOptions && (
          <div
            ref={shareRef}
            className={css`
              position: absolute;
              width: fit-content;
              bottom: 33px;
              right: 0px;
              overflow: hidden;
              background: ${colors.whiteFang};
              border-radius: 8px;
              z-index: 2;
              box-shadow: ${shadows.card};
            `}
          >
            <ul>
              {options.map((option, i) => (
                <li key={i}>
                  <button
                    className={cx(
                      nativeOverrides.buttonClear,
                      i === 0 &&
                        css`
                          padding-top: 10px;
                        `,
                      i === options.length - 1 &&
                        css`
                          padding-bottom: 10px;
                        `,
                      css`
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 45px;
                        padding-right: 15px;
                        padding-left: 15px;
                        &:hover {
                          background: ${colors.almostWhite};
                        }
                      `
                    )}
                    onClick={() => handleClickShare(option)}
                  >
                    <FlexBox alignItems="center">
                      <img
                        className={css`
                          width: 20px;
                          margin-right: 10px;
                        `}
                        src={option.icon}
                        alt={option.title}
                      />
                      <Text
                        className={css`
                          white-space: nowrap;
                        `}
                        type="body"
                        sizing="M"
                      >
                        {option.title}
                      </Text>
                    </FlexBox>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </FlexBox>
  )
}

export default BookReviewActions
