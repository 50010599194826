import { FlexBox } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { QRCodeSVG } from 'qrcode.react'
import { getDeeplink } from 'services/singular'

export const SharePageQR = ({
  className,
  hideOnMobileLayout = true,
}: {
  className?: string
  hideOnMobileLayout?: boolean
}) => {
  const { colors, mediaQueries } = useTheme()
  const link = getDeeplink('app')

  return (
    <FlexBox
      className={cx(
        css`
          display: ${hideOnMobileLayout ? 'none' : 'flex'};
          position: sticky;
          width: max-content;
          margin-left: auto;
          bottom: 24px;
          border-radius: 20px;
          width: 140px;
          height: 140px;
          background-color: ${colors.uiWhite};
          overflow: hidden;
          z-index: 10;
          box-shadow: 0px 13.54838752746582px 16.935483932495117px 0px
            rgba(0, 0, 0, 0.05);
          ${mediaQueries.mobileL} {
            display: flex;
          }
        `,
        className
      )}
      centerAll
    >
      <QRCodeSVG value={link} size={104} bgColor={colors.uiWhite} />
    </FlexBox>
  )
}
