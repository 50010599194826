import { useState } from 'react'
import { createContext } from 'react'
import { emptyFunction } from '../../utils/function'

export type SidebarContextType = {
  isSidebarActive: boolean
  onOpenSidebar: (initialState: string, data?: any) => void
  onCloseSidebar: () => void
  initialContent: string
  sidebarData: any
}

export const SidebarContext = createContext<SidebarContextType>({
  isSidebarActive: false,
  onOpenSidebar: emptyFunction,
  onCloseSidebar: emptyFunction,
  initialContent: '',
  sidebarData: null,
})

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

export const SidebarProvider: React.VFC<Props> = ({ children }) => {
  const { Provider } = SidebarContext
  const value = useSidebarProvider()

  return <Provider value={value}>{children}</Provider>
}

const useSidebarProvider = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false)
  const [initialContent, setInitialContent] = useState('')
  const [data, setData] = useState(null)

  const onOpenSidebar = (state: string, data?: any) => {
    setInitialContent(state)
    if (data) setData(data)

    setTimeout(() => {
      setIsSidebarActive(true)
    }, 300)
  }

  const onCloseSidebar = () => {
    setIsSidebarActive(false)
    setInitialContent('')
    setData(null)
  }

  return {
    isSidebarActive,
    onOpenSidebar,
    onCloseSidebar,
    initialContent,
    sidebarData: data,
  }
}
