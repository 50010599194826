import { createContext } from 'react'
import { DropdownProps, DropdownPropsItem } from '../types'

interface DropdownContextProps {
  setShowDropdown: (bool: boolean) => void
  showDropdown: boolean
  itemsFiltered: DropdownPropsItem[]
  mobileReturnMatchingItem: ({
    text,
    items,
    onChangeText,
  }: {
    text: string
    items: DropdownPropsItem[]
    onChangeText?: (s: string) => void
  }) => void
}

const DropdownContext = createContext<DropdownProps & DropdownContextProps>({
  // defaults https://reactjs.org/docs/context.html#updating-context-from-a-nested-component
  items: [],
  maxLength: 0,
  value: '',
  onChangeText: () => null,
  placeholder: '',
  theme: 'light',
  disabled: false,
  label: '',
  error: false,
  name: '',
  autoComplete: 'on',
  searchable: true,
  mobileListName: '',
  setShowDropdown: () => null,
  showDropdown: false,
  itemsFiltered: [],
  mobileReturnMatchingItem: () => null,
})

DropdownContext.displayName = 'DropdownContext'

export default DropdownContext
