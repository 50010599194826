import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { CarouselController } from 'components/carousel'
import { useEffect, useState } from 'react'
import { useTimer } from './useTimer'

interface Props {
  timerDuration: number
  controller: CarouselController
}

export const HomeFeaturesCarouselControls = ({
  controller,
  timerDuration,
}: Props) => {
  const { mediaQueries, colors } = useTheme()
  const slideCount = controller.instance?.slides.length || 0
  const activeSlide = controller.currentSlide

  return (
    <FlexBox
      className={css`
        width: 77px;
        gap: 4px;
        height: 4px;
        ${mediaQueries.mobileL} {
          width: 124px;
          gap: 6px;
        }
      `}
    >
      {Array.from({ length: slideCount }).map((_, i) => (
        <span
          key={i}
          className={css`
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${activeSlide > i
              ? colors.uiGreenUltraDark
              : `${colors.uiPearl}80`};
            border-radius: 12px;
            overflow: hidden;
            cursor: pointer;
          `}
          onClick={() => controller.instance?.moveToIdx(i)}
        >
          {activeSlide === i ? (
            <TimerProgress duration={timerDuration} />
          ) : null}
        </span>
      ))}
    </FlexBox>
  )
}

export const TimerProgress = ({ duration }: { duration: number }) => {
  const { colors } = useTheme()
  const { progress, start } = useTimer({ duration })
  const [running, setRunning] = useState(false)

  useEffect(() => {
    if (!running) {
      setRunning(true)
      start()
    }
  }, [start, running])

  return (
    <span
      className={css`
        display: block;
        width: ${progress}%;
        height: 100%;
        background-color: ${colors.uiGreenUltraDark};
      `}
    />
  )
}
