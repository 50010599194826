export type CMSAssetFormat = 'webp' | 'jpg' | 'png' | 'gif' | 'avif'

export interface CMSAssetOptions {
  format?: CMSAssetFormat
  width?: number
}

export const getCmsAsset = (path: string, options?: CMSAssetOptions) => {
  const format = options?.format || 'webp'
  let url = `https://images.ctfassets.net/005nulnwwll2/${path}?fm=${format}`
  if (options?.width) url += `&w=${options.width}`

  return url
}
