import { css, cx, useTheme } from '@fable/theme'
import { useEffect } from 'react'

const ResponsiveRow: React.VFC<
  {
    children: React.ReactNode | React.ReactNode[]
    gap?: string
    rowGap?: string
    columnGap?: string
    width?: string
    mobileFlexDirection?: 'row' | 'column'
    breakpoint?: number
  } & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  gap,
  width = '100%',
  mobileFlexDirection = 'column',
  breakpoint,
  rowGap,
  columnGap,
  ...nativeProps
}) => {
  const { breakpoints } = useTheme()

  useEffect(() => {
    if ((gap && columnGap) || (gap && rowGap)) {
      console.warn(
        'ResponsiveRow warning: gap will override rowGap and columnGap'
      )
    }

    if ((columnGap && !rowGap) || (rowGap && !columnGap)) {
      console.warn('columnGap and rowGap should both be set')
    }
  }, [columnGap, gap, rowGap])

  return (
    <div
      {...nativeProps}
      className={cx(
        css`
          display: flex;
          flex-direction: ${mobileFlexDirection === 'row' ? 'row' : 'column'};
          width: ${width};
          @media only screen and (min-width: ${breakpoint ||
            breakpoints.tablet}px) {
            flex-direction: ${mobileFlexDirection === 'row' ? 'column' : 'row'};
          }
          > div {
            flex: 1 1 auto;
          }
        `,
        rowGap &&
          columnGap &&
          css`
            gap: ${mobileFlexDirection === 'row' ? rowGap : columnGap};
            @media only screen and (min-width: ${breakpoint ||
              breakpoints.tablet}px) {
              gap: ${mobileFlexDirection === 'row' ? columnGap : rowGap};
            }
          `,
        gap &&
          css`
            gap: ${gap};
          `,
        nativeProps.className
      )}
    >
      {children}
    </div>
  )
}

export default ResponsiveRow
