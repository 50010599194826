const searchParams = new URLSearchParams(window.location.search)

export function handleUTMTrackingParams(): void {
  const utm_source = searchParams.get('utm_source')
  const utm_medium = searchParams.get('utm_medium')

  if (utm_source) {
    localStorage.setItem('utm_source', utm_source)
  }

  if (utm_medium) {
    localStorage.setItem('utm_medium', utm_medium)
  }
}

export function getUTMSource(): string {
  return localStorage.getItem('utm_source') || ''
}

export function getUTMMedium(): string {
  return localStorage.getItem('utm_medium') || ''
}

export const utm = {
  handleUTMTrackingParams,
  getUTMSource,
  getUTMMedium,
}
