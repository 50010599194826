import { useEffect, useRef } from "react"
import { useUser } from "./useUser"
import * as error from "utils"
import { analytics } from "segment_analytics"

export const useLoadAnalytics = () => {
    const { user, loading } = useUser()
    const loaded = useRef(false)

    useEffect(() => {
        if (!loaded.current && user && !loading) {
            analytics.identify(user)
            error.identify(user)
            loaded.current = true
        }

    }, [loading, user])
}