import { css, cx, useTheme, ThemeModes } from '@fable/theme'
import CharacterCount from './CharacterCount'
import FlexBox from './FlexBox'
import TextInputLabel from './TextInputLabel'
import getInputTheme from './utils/getInputTheme'

const TextArea: React.FC<
  {
    label?: string
    withCount?: boolean
    maxLength?: number
    value: string | undefined
    onChangeText: (s: string) => void
    placeholder?: string
    fieldHeight?: string
    theme?: ThemeModes
  } & React.HTMLProps<HTMLDivElement>
> = ({
  label,
  onChangeText,
  withCount,
  maxLength,
  value,
  placeholder,
  fieldHeight,
  disabled,
  theme = 'light',
  ...nativeProps
}) => {
  const { colors, fonts } = useTheme()
  const {
    background,
    borderColor,
    focusBorderColor,
    textColor,
    placeholderColor,
  } = getInputTheme(theme)

  return (
    <div {...nativeProps}>
      <div
        className={cx(
          css`
            border-radius: 24px;
            background: ${background};
            position: relative;
            overflow: hidden;
            height: ${fieldHeight};
            border: 1px solid ${borderColor};
            &:focus-within {
              border: 1px solid ${focusBorderColor};
            }
            &.error {
              border-color: ${colors.error};
            }
          `
        )}
      >
        {!!label && !value && (
          <TextInputLabel
            htmlFor={label}
            theme={theme}
            className={css`
              position: absolute;
              top: 0px;
              left: 0px;
              padding: 10px 23px 0px 20px;
            `}
          >
            {label}
          </TextInputLabel>
        )}
        <textarea
          id={label}
          onChange={(e) => onChangeText(e.target.value)}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value || ''}
          disabled={disabled}
          data-testid="textArea"
          className={css`
            background: transparent;
            color: ${textColor};
            font-family: ${fonts.body};
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.25px;
            border: none;
            resize: none;
            width: 100%;
            height: 100%;
            padding: ${label ? '24px' : '12px'} 20px 12px 20px;
            ::placeholder {
              color: ${placeholderColor};
            }
          `}
        />
      </div>
      {withCount && (
        <FlexBox
          justifyContent="flex-end"
          className={css`
            margin-top: 4px;
          `}
        >
          <CharacterCount input={value || ''} maxLength={maxLength || 0} />
        </FlexBox>
      )}
    </div>
  )
}

export default TextArea
