export const StarSpinnerDoodle = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0221 23.6821C51.5325 22.6921 47.991 21.6602 45.3727 20.1856C46.498 17.3725 48.4581 14.4931 49.6937 11.9832C51.0544 9.21529 48.7854 5.45393 45.2403 6.49275C41.2796 7.65009 37.3925 8.97824 33.5605 10.4598C32.6338 8.05795 31.1996 6.29056 30.876 3.76323C30.4457 0.420187 25.5546 -1.7446 23.5283 1.87732C22.3405 3.99679 21.9066 5.34935 20.6194 7.41653C19.906 8.56342 19.1668 10.1112 18.2548 11.3278C16.9199 11.0071 15.4893 10.3447 14.342 10.1042C12.4885 9.71379 10.5983 9.50463 8.70436 9.36519C5.4792 9.12466 4.11852 12.6594 5.29899 14.9915C6.42799 17.2156 7.24807 19.5024 7.85118 21.8659C5.85798 23.072 3.87581 24.2921 1.92306 25.5575C-0.309182 26.9972 -0.952744 30.7202 1.92306 32.0135C3.83535 32.8711 6.13746 33.767 7.94311 35.0219C7.68937 37.9188 6.82884 40.9237 6.33973 43.7264C5.86901 46.4141 8.0608 49.1854 11.1903 48.3244C14.1728 47.5052 17.1552 46.6825 20.1377 45.8633C22.1088 49.0042 24.1278 52.1136 26.2386 55.1743C27.6986 57.2903 31.7586 57.9073 33.0494 55.1743C34.2924 52.5354 35.756 50.0465 37.5322 47.7283C40.0072 48.422 42.5005 49.0495 45.0196 49.6107C47.9285 50.2591 50.6719 47.8398 49.8702 45.0127C49.0391 42.0741 47.4762 38.616 46.9319 35.4368C48.2668 34.7082 49.7893 34.1086 51.0139 33.3312C52.6651 32.282 54.2281 31.1211 55.7689 29.9324C58.3505 27.9384 56.4346 24.6407 54.0295 23.6856L54.0221 23.6821Z"
        fill="#FFABE7"
      />
    </svg>
  )
}
