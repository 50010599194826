import { ErrorBannerActions } from '../actions'
import { ErrorBannerTypes } from '../action-types'

export enum ErrorBannerKeys {
  errorBannerMessage = 'errorBannerMessage',
}

interface ErrorBannerState {
  [ErrorBannerKeys.errorBannerMessage]: string
}

const initialState: ErrorBannerState = {
  [ErrorBannerKeys.errorBannerMessage]: '',
}

const reducer = (
  state: ErrorBannerState = initialState,
  action: ErrorBannerActions
): ErrorBannerState => {
  switch (action.type) {
    // ERROR BANNER MESSAGE
    case ErrorBannerTypes.SET_ERROR_BANNER_MESSAGE:
      return {
        ...state,
        [ErrorBannerKeys.errorBannerMessage]: action.payload,
      }
    case ErrorBannerTypes.SET_ERROR_BANNER_MESSAGE__CLEAR:
      return {
        ...state,
        [ErrorBannerKeys.errorBannerMessage]: '',
      }

    default:
      return state
  }
}

export default reducer
