import { css, useTheme } from '@fable/theme'
import { AppearInView } from '../motion'
import { FlexBox } from '@fable/components'
import { CompProps } from '@fable/types'

export const HomeFeatureCarouselContainer = ({ children }: CompProps) => {
  const { mediaQueries } = useTheme()

  return (
    <AppearInView
      className={css`
        width: 100%;
        ${mediaQueries.mobileL} {
          margin-top: -125px;
        }
        ${mediaQueries.tablet} {
          width: 50%;
          margin-top: 0;
        }
      `}
      amount={0.3}
    >
      <FlexBox
        className={css`
          width: 100%;
          align-items: flex-end;
          ${mediaQueries.tablet} {
            align-items: center;
          }
        `}
        flexDirection="column"
      >
        {children}
      </FlexBox>
    </AppearInView>
  )
}
