// Docs: https://fablegroup.atlassian.net/wiki/spaces/ENG/pages/834043907/Styled+Components+emotion
import { css, keyframes } from '@emotion/css'
import isArray from 'lodash/isArray'
import {
  Colors,
  Shadows,
  Motion,
  Fonts,
  Breakpoints,
  MediaQueries,
  PresetStyles,
  NativeOverrides,
} from './themeTypes'

const breakpoints = {
  mobileXS: 321,
  mobileS: 376,
  mobile: 481,
  mobileL: 548,
  tablet: 768,
  tabletL: 880,
  desktop: 1152,
  desktopL: 1441,
  desktopXL: 1681,
  desktopXXL: 1800,
}

const theme: {
  colors: Colors
  shadows: Shadows
  motion: Motion
  presetStyles: PresetStyles
  fonts: Fonts
  breakpoints: Breakpoints
  mediaQueries: MediaQueries
  nativeOverrides: NativeOverrides
} = {
  colors: {
    /* Legacy Colors */
    fableGreenDark: '#004a33',
    fableGreenDarker: '#053829',
    fableGreenExtraDark: '#0E241F',
    blackSwan: '#161015',
    blackSwanFaded: 'rgba(22, 16, 21, 0.5)',
    pinkMoon: '#e4adbb',
    whiteFang: '#ffffff',
    saffron: '#f4c635',
    saffronLight: '#FFF2CA',
    mahogany: '#cb3f3f',
    tussock: '#c19c4e',
    shamrock: '#0a8e4f',
    shamrockDark: '#0C6037',
    shamrockMedium: '#9ae2bf',
    shamrockLight: '#E0FFF0',
    lavenderBlush: '#ffeaef',
    denim: '#116ecd',
    denimDark: '#0F5EAE',
    mayaBlue: '#73d5ff',
    mayaBlueMedium: '#B0E7FF',
    mayaBlueLight: '#EBF9FF',
    pinkMoonDark: '#CB8E9E',
    pinkMoonMedium: '#FFC2D2',
    pinkMoonLight: '#FAEFF1',
    hazelnut: '#C19C4E',
    hazelnutLight: '#FFF1D3',
    orange: '#ef8132',
    orangeLight: '#FFDFC9',
    newColor: '#251C50',
    hurricane: '#847f7c',
    whisper: '#e9e1de',
    whiteSmoke: '#ededed',
    almostWhite: '#f9f9f9',
    blackSwanHover: '#332e32',
    errorHover: '#e76969',
    errorDisabled: '#f0a1a1',
    whiteUltra: '#ecf9f3',
    fadedBlue: '#0c4357',
    grey: '#545454',
    greyNeutral: '#bcbcbc',
    greyMedium: '#d1cfd0',
    greyFade: '#716d70',
    greyStrong: '#2e272d',
    borderGrey: '#ececec',
    searchInputHover: '#dadada',
    lightGrey: '#f5f5f5',
    sapphireBlue: '#126887',

    // Design System 2.0
    // https://www.figma.com/file/6dKv2PmTqn5Sg6atvID1L3/Fable-Library-2.0?node-id=126%3A2201
    fableGreen: '#064c37',
    green: '#47926E',
    mustard: '#EBC344',
    rose: '#EE7878',
    blue: '#116ECD',
    lilac: '#C0D3E4',
    graphite: '#3F383D',
    graphiteLight: '#696668',
    silverGrey: '#F9F9F9',
    silverGreyLight: '#F8F8F8',
    pearlWhite: '#F7F4EE',
    pewterGrey: '#EDEDED',
    pewterGreyLight: '#F4F4F4',
    pewterGreyMedium: '#DFDEDF',
    pewterGreyDark: '#949293',
    titaniumWhite: '#FFFFFF',
    success: '#1CB069',
    successLight: '#F1FDF7',
    error: '#e14444',
    errorLight: '#FFF3F3',
    warning: '#FFE458',
    warningLight: '#FFFAE1',

    /**
     * Color Map - Design System 2.1 (Latest)
     * https://www.figma.com/file/4G8gSEx4Jx32kF47luz9Ro/2.1-DS-Figma-Library?type=design&node-id=431-1704&mode=design&t=TKYUtUrL36nlUqx5-0
     */

    // Mono
    uiBlack: '#070607',
    uiGraphite: '#292229',
    uiGraphiteLight: '#433944',
    uiPewterGrey: '#89818A',
    uiPewterGreyLight: '#C5C1C5',
    uiSilverGrey: '#EFEFEF',
    uiSilverGreyLight: '#F8F8F8',
    uiWhite: '#FFFFFF',

    // Green
    uiGreenUltraDark: '#042119',
    uiGreenDark: '#526C03',
    uiGreen: '#6E9A36',
    uiGreenLight: '#81BA6D',
    uiGreenExtraLight: '#ABE0A6',
    uiGreenUltraLight: '#E8F9E6',

    // Brown
    uiBrownDark: '#4D2E06',
    uiBrown: '#9C886F',
    uiPearl: '#E0DBCA',
    uiPearlExtraLight: '#F7F4EE',
    uiPearlUltraLight: '#FCF9F5',

    // Pink
    uiPinkExtraDark: '#AD087E',
    uiPinkDark: '#D92FB3',
    uiPink: '#E979C4',
    uiPinkLight: '#FFABE7',
    uiPinkExtraLight: '#FDE0F5',
    uiPinkUltraLight: '#FFF2FC',

    // Yellow
    uiYellowDark: '#BF9C16',
    uiYellow: '#F2D63F',
    uiYellowLight: '#EDED0E',
    uiYellowExtraLight: '#F8F89C',
    uiYellowUltraLight: '#FEFEE8',

    // Rose
    uiRoseDark: '#D12E2E',
    uiRose: '#FC7676',
    uiRoseLight: '#FF855E',
    uiRoseExtraLight: '#F6C2B5',
    uiRoseUltraLight: '#FFEBE6',

    // Teal
    uiTealDark: '#0076BA',
    uiTeal: '#43A1D7',
    uiTealLight: '#7AC3ED',
    uiTealExtraLight: '#CAEBFF',
    uiTealUltraLight: '#EDF9FF',

    // Blue
    uiBlueDark: '#020096',
    uiBlue: '#211ED5',
    uiBlueLight: '#4252F1',
    uiBlueExtraLight: '#A4ADFF',
    uiBlueUltraLight: '#E6EFFF',

    // Orange
    uiOrangeDark: '#B74200',

    // Transparent
    uiWhite70: '#FFFFFFB3',
    uiWhite50: '#FFFFFF80',
    uiWhite20: '#FFFFFF33',
    uiBlack60: '#07060799',
    uiBlack40: '#07060766',
    uiBlack30: '#0706074D',
    uiBlack20: '#07060733',
    uiBlack10: '#0706071A',
  },
  shadows: {
    card: '0 4px 12px rgba(0, 0, 0, 0.08)',
  },
  motion: {
    defaultSpeed: '0.2s',
    transition: (attr?: string | string[], easing?: string, speed?: string) => {
      const tAttr = attr || ''
      const tEasing = easing || 'ease'
      const tSpeed = speed || '0.2s'
      const motion = `${tEasing} ${tSpeed}`

      if (isArray(tAttr)) {
        let attributes: string[] = []

        for (const a of tAttr) {
          attributes.push(`${a} ${motion}`)
        }

        return css`
          transition: ${attributes.join(',')};
        `
      }

      return css`
        transition: ${tAttr} ${motion};
      `
    },
    easeInOutCubic: 'cubic-bezier(0.65, 0, 0.35, 1)',
    easeOutQuad: 'cubic-bezier(0.5, 1, 0.89, 1)',
    easeInOutQuad: 'cubic-bezier(0.45, 0, 0.55, 1)',
    easeInOutQuint: 'cubic-bezier(0.83, 0, 0.17, 1)',
    easeInOutSine: 'cubic-bezier(0.37, 0, 0.63, 1)',
    staggerUp: (show: boolean, index: number) => css`
      transform: ${show ? 'translateY(0%)' : 'translateY(100%)'};
      opacity: ${show ? '1' : '0'};
      transition: 0.2s;
      transition-delay: ${100 * index}ms;
    `,
    moveY: (x: number) =>
      css`
        transform: translateY(${x}px);
      `,
    fadeOut: css`
      animation: ${keyframes`
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
          display: none;
        }
    `} 0.2s ease forwards;
    `,
    fadeIn: css`
      animation: ${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `} 0.2s ease forwards;
    `,
    fadeInOut: css`
      animation: ${keyframes`
        from {
          opacity: 0.2;
        }
        to {
          opacity: 0.4;
        }
      `} 0.6s infinite alternate;
    `,
    slideInFromRight: css`
      animation: ${keyframes`
        from {
          opacity: 0;
          transform: translateX(100%);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      `} 0.2s ease forwards;
    `,
    slideOutFromLeft: css`
      animation: ${keyframes`
        from {
          opacity: 1;
          transform: translateX(0);
        }
        to {
          opacity: 0;
          transform: translateX(100%);
        }
      `} 0.2s ease forwards;
    `,
    spin: css`
      animation: ${keyframes`
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      `} 1s linear infinite;
    `,
  },
  presetStyles: {
    cover: css`
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    `,
    background: (pos?: string, size?: string) => css`
      background-position: ${pos ? pos : 'center'};
      background-size: ${size ? size : 'cover'};
      background-repeat: no-repeat;
    `,
    textOutline: ({ color, width = 1 }: { color: string; width?: number }) => {
      return css`
        text-shadow: -${width}px ${width}px 0 ${color},
          ${width}px ${width}px 0 ${color}, ${width}px -${width}px 0 ${color},
          -${width}px -${width}px 0 ${color};
      `
    },
    truncate: (lines: number) => css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
    `,
  },
  fonts: {
    header: `'Heldane Display', serif`,
    body: `'Inter', serif`,
  },
  breakpoints,
  mediaQueries: Object.keys(breakpoints).reduce((obj, key) => {
    // https://emotion.sh/docs/media-queries#reusable-media-queries
    obj[key as keyof MediaQueries] = `@media only screen and (min-width: ${
      breakpoints[key as keyof Breakpoints]
    }px)`

    return obj
  }, {} as MediaQueries),
  nativeOverrides: {
    buttonClear: css`
      text-align: left;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      width: auto;
      height: auto;
      display: block !important;
      border-radius: 0;
      cursor: pointer;
      &:hover {
        border: none;
        outline: none;
        background: none;
      }
      &:disabled {
        background: none;
        border: none;
        color: inherit;
        opacity: 0.5;
      }
    `,
  },
}

export default theme
