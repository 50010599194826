/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { ClipboardActions } from '../actions'
import { ClipboardTypes } from '../action-types'
import { setAction } from '../stateHandlers'

type DispatchType = Dispatch<ClipboardActions>

export const copyTextToClipboard = () => (dispatch: DispatchType) => {
  dispatch(setAction(ClipboardTypes.CLIPBOARD__COPY))
}

export const clearClipboard = () => (dispatch: DispatchType) => {
  dispatch(setAction(ClipboardTypes.CLIPBOARD__CLEAR))
}
