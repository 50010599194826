import { Link } from 'react-router-dom'
import { css, useTheme } from '@fable/theme'
import { routes as marketingRoutes } from '../../../app/marketing/routes'
import { FlexBox, Icon } from '@fable/components'
import { LogoV2 } from 'components/logo_v2'
import { FooterLinks } from '../FooterLinks'
import { socialLinks } from '../links'

export const FooterTop = () => {
  const { mediaQueries, colors } = useTheme()

  return (
    <FlexBox
      className={css`
        flex-direction: column;
        ${mediaQueries.tabletL} {
          flex-direction: row;
        }
      `}
      justifyContent="space-between"
    >
      <FlexBox
        className={css`
          align-items: center;
          ${mediaQueries.tabletL} {
            align-items: flex-start;
          }
        `}
        justifyContent="space-between"
      >
        <Link to={marketingRoutes.home}>
          <LogoV2
            className={css`
              ${mediaQueries.tabletL} {
                width: 130px;
                height: auto;
              }
            `}
            light
          />
        </Link>
        <FlexBox
          className={css`
            gap: 4px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            ${mediaQueries.tabletL} {
              display: none;
            }
          `}
        >
          {socialLinks.map((link) => (
            <a key={link.icon} href={link.url}>
              <FlexBox
                key={link.icon}
                className={css`
                  width: 30px;
                  height: 30px;
                `}
                centerAll
              >
                <Icon
                  className={css`
                    width: ${link.width?.mobile || '20px'};
                  `}
                  type={link.icon}
                  fill={colors.uiWhite}
                />
              </FlexBox>
            </a>
          ))}
        </FlexBox>
      </FlexBox>
      <FooterLinks />
    </FlexBox>
  )
}
