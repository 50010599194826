import { useState, useEffect, useCallback } from 'react'

interface TimerConfig {
  duration?: number
}

interface TimerProps {
  progress: number
  start: () => void
  stop: () => void
  reset: () => void
  restart: () => void
  complete: boolean
}

export const useTimer = ({ duration = 5000 }: TimerConfig = {}): TimerProps => {
  const [progress, setProgress] = useState(0)
  const [active, setActive] = useState(false)
  const [complete, setComplete] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const start = useCallback(() => {
    setActive(true)
  }, [])

  const stop = useCallback(() => {
    setActive(false)
    if (timer) clearTimeout(timer)
  }, [timer])

  const reset = useCallback(() => {
    setProgress(0)
    setActive(false)
    setComplete(false)
    if (timer) clearTimeout(timer)
  }, [timer])

  const restart = (): void => {
    reset()
    setTimeout(() => start(), 10)
  }

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (active) {
      const startTime = Date.now()
      const endTime = startTime + duration

      const updateProgress = (): void => {
        const now = Date.now()
        const elapsedTime = now - startTime
        const remainingTime = endTime - now
        const progressValue = (elapsedTime / 5000) * 100

        if (remainingTime > 0) {
          setProgress(progressValue)
          interval = setTimeout(updateProgress, 10)
          setTimer(interval)
        } else {
          setProgress(100)
          setActive(false)
          setComplete(true)
        }
      }

      updateProgress()
    }

    return () => {
      if (interval) clearTimeout(interval)
    }
  }, [active, duration])

  return {
    progress,
    complete,
    start,
    stop,
    reset,
    restart,
  }
}
