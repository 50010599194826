import { Builder, withChildren } from '@builder.io/react'
import Link from 'react-anchor-link-smooth-scroll'
import { defaultTextChild } from '../helpers'

interface Props {
  link: string
  offset: string
  children: React.ReactNode | React.ReactNode[]
}

const AnchorLink = ({ link, offset, children }: Props) => (
  <Link href={`#${link}`} offset={offset}>
    {children}
  </Link>
)

const AnchorLinkWithChildren = withChildren(AnchorLink)

export const registerAnchorLink = () => {
  Builder.registerComponent(AnchorLinkWithChildren, {
    name: 'Anchor Link',
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/hash.webp',
    inputs: [
      { name: 'link', type: 'text' },
      { name: 'offset', type: 'number' },
    ],
    defaultChildren: [defaultTextChild],
  })
}
