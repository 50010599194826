import { IconSVGProps } from '../types'

export const FileListIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.6667 18.3333H3.33333C3.11232 18.3333 2.90036 18.2455 2.74408 18.0893C2.5878 17.933 2.5 17.721 2.5 17.5V2.5C2.5 2.27898 2.5878 2.06702 2.74408 1.91074C2.90036 1.75446 3.11232 1.66666 3.33333 1.66666H16.6667C16.8877 1.66666 17.0996 1.75446 17.2559 1.91074C17.4122 2.06702 17.5 2.27898 17.5 2.5V17.5C17.5 17.721 17.4122 17.933 17.2559 18.0893C17.0996 18.2455 16.8877 18.3333 16.6667 18.3333ZM6.66667 5.83333V7.5H13.3333V5.83333H6.66667ZM6.66667 9.16666V10.8333H13.3333V9.16666H6.66667ZM6.66667 12.5V14.1667H13.3333V12.5H6.66667Z" />
  </svg>
)
