import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const CURATED_CLUBS_LIST = gql`
  query CuratedClubsList($id: String!) {
    curatedClubsList(id: $id, preview: ${isPreview}) {
      curatedClubsListCollection {
        items {
          listName
          slugs
        }
      }
    }
  }
`
