import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_PRESS_POSTS = gql`
  query PressPosts {
    pressCollection(preview: ${isPreview}) {
      items {
        title
        pressName
        pressLogo {
            url
        }
        publishDate
        link
      }
    }
  }
`
