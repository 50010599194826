import { gql } from '@apollo/client'
import { isPreview } from '../services/contenful'

export const GET_TEAM = gql`
  query Staff {
    teamMemberCollection (order: name_ASC, preview: ${isPreview}) {
        items {
          name
          role
          image {
            url
          }
        }
    }
  }
`
