import { css, useTheme } from '@fable/theme'
import { FlexBox, Text } from '@fable/components'
import patternLoop from '../../assets/images/graphics/patterns/pattern-loop.svg'
import handsUpImage from '../../assets/images/graphics/art-Welcome.webp'

export const PlanSelectionHero = ({ small = false }: { small?: boolean }) => {
  const { colors, presetStyles, mediaQueries } = useTheme()

  return (
    <FlexBox
      className={css`
        position: relative;
        width: 100%;
        height: ${small ? '360px' : '460px'};
        background-color: ${colors.almostWhite};
        ${mediaQueries.mobile} {
          height: ${small ? '400px' : '530px'};
        }
      `}
      justifyContent="center"
      alignItems="flex-end"
    >
      <div
        className={css`
          ${presetStyles.cover};
        `}
      >
        <div
          className={css`
            display: none;
            ${presetStyles.cover};
            ${presetStyles.background('top')};
            ${mediaQueries.mobile} {
              display: block;
            }
          `}
          style={{ backgroundImage: `url(${patternLoop})` }}
        />
      </div>
      <div
        className={css`
          position: relative;
          transform: translateY(-60px);
        `}
      >
        <img
          className={css`
            margin: 0 auto;
            display: block;
            max-width: ${small ? '120px' : '180px'};
            ${mediaQueries.mobile} {
              max-width: ${small ? '150px' : '230px'};
            }
          `}
          src={handsUpImage}
          alt="Upgrade to Fable Premium"
        />
        <Text
          className={css`
            margin-top: 16px;
            max-width: 260px;
            ${mediaQueries.mobile} {
              max-width: 360px;
            }
          `}
          type="header"
          sizing={
            small ? { base: 'XS', mobileL: 'S' } : { base: 'M', mobileL: 'L' }
          }
          align="center"
        >
          Upgrade to
          <br />
          Fable Premium
        </Text>
        <Text
          className={css`
            margin: 16px auto 24px;
            max-width: 234px;
          `}
          type="label"
          sizing="L"
          align="center"
        >
          <b>Subscribe to Premium.</b> Enjoy great books and join a community of
          readers.
        </Text>
      </div>
    </FlexBox>
  )
}
