/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { css, cx } from '@fable/theme'
import YouTube from 'react-youtube'
import { BREAKPOINTS } from '../constants'

import { useLightbox } from '../hooks/useLightbox'
import CloseIcon from '../assets/icons/components/CloseIcon'
import { Color } from '@fable/legacy'

export const VideoLightbox: React.FC = () => {
  const { isActive, closeLightbox, videoId } = useLightbox()

  const closeIcon = (
    <div
      className={cx(styles.closeIcon, 'close')}
      onClick={() => closeLightbox()}
    >
      <CloseIcon />
    </div>
  )

  const onStateChange = (player: any) => {
    if (player.data === 0) {
      closeLightbox()
    }
  }

  const renderIframe = () => {
    if (!videoId) {
      return
    }
    const opts: any = {
      playerVars: {
        autoplay: 1,
      },
    }

    return (
      <YouTube opts={opts} videoId={videoId} onStateChange={onStateChange} />
    )
  }

  return (
    <div className={cx(styles.wrapper, isActive && styles.active)}>
      <div className={cx(styles.container)}>
        {closeIcon}
        <div className={cx(styles.videoContainer)}>{renderIframe()}</div>
      </div>
    </div>
  )
}

const styles = {
  wrapper: css`
    padding: 8px;
    width: 100%;
    height: 100vh;
    background-color: ${Color.blackSwanFaded};
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    pointer-events: none;
    opacity: 0;
    transition: opacity ease 0.2s;

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      padding: 48px;
    }

    @media only screen and (max-height: 520px) {
      height: 520px;
    }

    @media only screen and (max-height: 850px) {
      padding: 100px 48px;
    }
  `,
  container: css`
    max-width: 1280px;
    width: 100%;
    position: relative;

    @media only screen and (max-height: 520px) {
      width: 570px !important;
    }

    @media only screen and (min-width: 940px) and (max-height: 850px) {
      width: 132vh;
      height: 100%;
    }
  `,
  closeIcon: css`
    position: absolute;
    top: -34px;
    right: 0;
    width: 24px;
    height: 24px;
    background-color: ${Color.almostWhite};
    border-radius: 50%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity ease 0.2s;

    :hover {
      opacity: 0.8;
    }

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      top: -44px;
    }
  `,
  videoContainer: css`
    height: 720px;
    background-color: ${Color.blackSwan};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    overflow: hidden;

    iframe,
    div {
      display: block;
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: ${BREAKPOINTS.mobile}) {
      border-radius: 12px;
    }

    @media only screen and (max-width: 1320px) {
      height: 52vw;
    }

    @media only screen and (min-width: 940px) and (max-height: 850px) {
      height: 100%;
    }
  `,
  active: css`
    pointer-events: auto;
    opacity: 1;
  `,
}
