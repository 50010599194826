import { useLocation } from 'react-router-dom'
import { Redirect } from '@fable/legacy'
import { routes as authRoutes } from '../app/auth/routes'
import Head from '../components/head/Head'
import SuspenseWithErrorBoundary from '../components/suspense_with_error_boundary/SuspenseWithErrorBoundary'
import { useFooter, useHeader, useTypedSelector } from '../hooks'
import { AppRouteProps } from './types'
import { usePageTracking } from './usePageTracking'
import { useEffect } from 'react'

export const AppRoute: React.FC<AppRouteProps> = ({
  element,
  head,
  footer = null,
  header = null,
  guarded = false,
}) => {
  const { isAuthenticated, isVerifying } = useTypedSelector(({ auth }) => auth)
  const location = useLocation()
  const setHeader = useHeader((s) => s.setConfig)
  const setFooter = useFooter((s) => s.setConfig)

  usePageTracking({ title: head?.title })

  useEffect(() => {
    setHeader({ ...header })
  }, [header, setHeader])

  useEffect(() => {
    setFooter({ ...footer })
  }, [footer, setFooter])

  const content = (
    <SuspenseWithErrorBoundary>
      {head ? <Head {...head} /> : null}
      {element}
    </SuspenseWithErrorBoundary>
  )

  const renderGuarded = () => {
    if (isVerifying) return null
    if (isAuthenticated) return content

    return <Redirect state={{ from: location }} to={authRoutes.signIn} />
  }

  return guarded ? renderGuarded() : content
}
