import { useEffect, useState } from 'react'
import { useTypedSelector } from '../hooks'

import { Address, Card } from '@fable/types'

export const useCreateAddressList = (): Address[] => {
  const { cards } = useTypedSelector(({ billing }) => billing)
  const [addressList, setAddressList] = useState([] as Address[])

  useEffect(() => {
    const addresses = (cards?.data || []).map((card: Card) => ({
      line1: card.address_line1,
      line2: card.address_line2,
      city: card.address_city,
      state: card.address_state,
      postal_code: card.address_postal_code,
    }))
    setAddressList([...[], ...addresses])
  }, [cards])

  return addressList
}
