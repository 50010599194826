import { IconSVGProps } from '../types'

export const BookmarkAddIcon: React.VFC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.0568L11.4188 15.1863C11.7665 14.9379 12.2335 14.9379 12.5812 15.1863L18 19.0568V11C18 10.4477 18.4477 10 19 10C19.5523 10 20 10.4477 20 11V21C20 21.3746 19.7907 21.7178 19.4576 21.8892C19.1245 22.0606 18.7236 22.0315 18.4188 21.8137L12 17.2289L5.58124 21.8137C5.27642 22.0315 4.87549 22.0606 4.54242 21.8892C4.20935 21.7178 4 21.3746 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H11C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4H7Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0C18.5523 0 19 0.447715 19 1V7C19 7.55228 18.5523 8 18 8C17.4477 8 17 7.55228 17 7V1C17 0.447715 17.4477 0 18 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4C14 3.44772 14.4477 3 15 3H21C21.5523 3 22 3.44772 22 4C22 4.55228 21.5523 5 21 5H15C14.4477 5 14 4.55228 14 4Z"
    />
  </svg>
)
