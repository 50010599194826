import { FlexBox, Loader, LoaderType } from '@fable/components'
import { css } from '@fable/theme'

export const ErrorBoundaryLoader = () => (
  <FlexBox
    className={css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}
  >
    <Loader type={LoaderType.contained} />
  </FlexBox>
)
