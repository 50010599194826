import { IconSVGProps } from '../types'

export const PinOutlineIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.8277 1.68555L22.3137 10.1715L20.8987 11.5855L20.1917 10.8785L15.9497 15.1205L15.2427 18.6565L13.8277 20.0705L9.58568 15.8275L4.63568 20.7775L3.22168 19.3635L8.17168 14.4135L3.92868 10.1715L5.34268 8.75655L8.87968 8.04955L13.1217 3.80755L12.4147 3.10055L13.8287 1.68555H13.8277ZM14.5357 5.22155L9.86468 9.89155L7.04268 10.4565L13.5427 16.9565L14.1067 14.1345L18.7777 9.46455L14.5357 5.22155Z" />
  </svg>
)
