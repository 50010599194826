import { Club, ClubType } from '@fable/types'
import { getClubType } from './utils'

export const routes = {
  club: '/club',
  proDevRegistration: '/pro-club',
  clubQuiz: 'club-quiz/*',
  clubQuizGenre: 'genre',
  clubQuizBooks: 'books',
  clubQuizResults: 'results',
  // temp:
  chat: '/chat',
}

export const clubRoute = ({
  club,
  slug,
  clubType,
}: {
  club?: Club | null
  clubType?: ClubType
  slug?: string
}) => {
  const clubSlug = slug || club?.slug
  let type: ClubType | undefined

  if (club) type = getClubType({ club })
  if (clubType) type = clubType

  const to: { pathname: string; search?: string } = {
    pathname: !!clubSlug ? `${routes.club}/${clubSlug}` : '/',
  }

  if (type) {
    to.search = `?club_type=${type}`
  }

  return to
}

export const clubChatRoute = (club: Club | undefined) =>
  `${routes.club}/${club?.slug}${routes.chat}`
