import { get } from '@fable/api'
import { Loader } from '@fable/components'
import { useQuery } from 'react-query'
import { HomeClubsRow } from './HomeClubsRow'
import { CompChildren } from '@fable/types'
import { css, cx } from '@fable/theme'
import { useVertExpEnabled } from 'hooks'

interface Props {
  className?: string
  header?: CompChildren
}

export const HomePopularClubsList = ({ className = '', header }: Props) => {
  const { data, isLoading } = useQuery(['popular_club'], () =>
    get('/popular_clubs')
  )
  const vertExpEnabled = useVertExpEnabled()
  const title = vertExpEnabled
    ? 'Clubs starting soon'
    : 'Book Clubs Starting Books Soon'

  const popularClubs = data?.data || []

  if (isLoading) return <Loader />
  if (popularClubs.length < 5) return null

  return (
    <div
      className={cx(
        css`
          width: 100%;
        `,
        className
      )}
    >
      {header}
      <HomeClubsRow
        title={!!header ? '' : title}
        items={popularClubs}
        type="popular-clubs-starting-soon"
        hideControls={!!header}
      />
    </div>
  )
}
