import { css, useTheme } from '@fable/theme'
import Text from '../text/Text'

/**
 * @note
 * there is no reliable way to use CSS to add a readmore link, so
 * since this is a preview I'm just going with character count to meet design specs
 */
export const QuoteViewText = ({ text = '' }: { text?: string }) => {
  const { colors, mediaQueries } = useTheme()

  return (
    <Text
      className={css`
        margin-bottom: 40px;
        cursor: inherit;
      `}
      type="header"
      color={colors.whiteFang}
      align="left"
      custom={{
        fontSize: '18px',
        lineHeight: '20px',
        [mediaQueries.tablet]: {
          fontSize: '28px',
          lineHeight: '28px',
          letterSpacing: '-0.5px',
        },
      }}
    >
      {text.substring(0, 325)}
      ...
    </Text>
  )
}
