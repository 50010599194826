import { windowReady } from '@fable/utils'
import { initializeApp, getApps, FirebaseApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
  GoogleAuthProvider,
  OAuthProvider,
  connectAuthEmulator,
  UserCredential,
  User,
  onAuthStateChanged,
  Auth,
} from 'firebase/auth'
import { useEffect, useState } from 'react'

const AUTH_METHODS = {
  apple: 'apple',
  google: 'google',
  microsoft: 'microsoft',
  password: 'password',
  token: 'token',
}

let fbApp, fbAuth

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
}

if (!!fbConfig.apiKey && !getApps().length && windowReady) {
  fbApp = initializeApp(fbConfig)
  fbAuth = initializeAuth(fbApp as FirebaseApp, {
    popupRedirectResolver: browserPopupRedirectResolver,
    persistence: [browserLocalPersistence],
  })
}

export const firebaseApp = fbApp as FirebaseApp
export const firebaseAuth = fbAuth as Auth

const firebaseAuthEmulatorHost =
  process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST
if (firebaseAuthEmulatorHost) {
  connectAuthEmulator(firebaseAuth, `http://${firebaseAuthEmulatorHost}`)
}

export const getProviderCredential = (result: UserCredential) => {
  switch (result.providerId) {
    case 'google.com':
      return GoogleAuthProvider.credentialFromResult(result)
    case 'apple.com':
      return OAuthProvider.credentialFromResult(result)
    case 'microsoft.com':
      return OAuthProvider.credentialFromResult(result)
    default:
      return null
  }
}

export const getProviderMethod = (id: string) => {
  const { google, apple, microsoft } = AUTH_METHODS

  switch (id) {
    case 'google.com':
      return google
    case 'apple.com':
      return apple
    case 'microsoft.com':
      return microsoft
    default:
      return
  }
}

export function hasProvider(user: User, providerId: string): boolean {
  return !!user.providerData.filter(
    (provider) => provider.providerId === providerId
  ).length
}

export function useFirebaseAuthentication() {
  const [authUser, setAuthUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unlisten = onAuthStateChanged(firebaseAuth, async (authUser) => {
      authUser ? setAuthUser(authUser) : setAuthUser(null)
      setLoading(false)
    })
    return () => {
      unlisten()
    }
  }, [])

  return { loading, user: authUser, setUser: setAuthUser }
}
