import { css, cx } from '@fable/theme'
import { CompProps } from '@fable/types'
import { useInView } from 'framer-motion'
import { useRef } from 'react'

interface Props {
  className?: string
  once?: boolean
  amount?: number
}

export const AppearInView = ({
  className = '',
  children,
  once = true,
  amount = 1,
}: CompProps<Props>) => {
  const ref = useRef<HTMLDivElement>(null)
  const inView = useInView(ref, { once, amount })

  return (
    <div
      ref={ref}
      className={cx(
        css`
          opacity: ${inView ? 1 : 0};
          transform: translateY(${inView ? 0 : 60}px);
          transition: opacity 0.6s ease-in-out, transform 0.6s;
        `,
        className
      )}
    >
      {children}
    </div>
  )
}
