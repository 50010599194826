import { CartProvider } from './CartContext'
import { LightboxProvider } from './LightboxContext'
import { SidebarProvider } from './SidebarContext'
import { SubheaderProvider } from './SubheaderContext'

export const ContextProviders: React.FC<{
  children?: React.ReactNode | string | null
}> = ({ children }) => (
  <CartProvider>
    <SubheaderProvider>
      <SidebarProvider>
        <LightboxProvider>{children}</LightboxProvider>
      </SidebarProvider>
    </SubheaderProvider>
  </CartProvider>
)
