import { gql } from '@apollo/client'

export const FRAGMENT_IMAGE = gql`
  fragment Image on Asset {
    title
    description
    contentType
    fileName
    size
    url
    width
    height
  }
`
