import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const useProfileRedirect = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.host.includes('follow-me')) {
      let profile = searchParams.get('profile')

      if (profile) {
        navigate(profile, { replace: true })
      }
    }
  }, [navigate, searchParams])
}
