import { Dispatch } from 'redux'
import { ErrorBannerActions } from '../actions'
import { ErrorBannerTypes } from '../action-types'
import { setAction } from '../stateHandlers'

type DispatchType = Dispatch<ErrorBannerActions>

// SET ERROR BANNER
export const setErrorBannerMessage =
  (message: string) => (dispatch: DispatchType) => {
    dispatch(setAction(ErrorBannerTypes.SET_ERROR_BANNER_MESSAGE, message))
  }

// SET ERROR BANNER - CLEAR
export const clearErrorBannerMessage = () => (dispatch: DispatchType) => {
  dispatch(setAction(ErrorBannerTypes.SET_ERROR_BANNER_MESSAGE__CLEAR))
}
