import { useContext, useEffect, useState } from 'react'
import { SearchContext, SearchFilterKey } from 'state'
import { useHeader } from './useHeader'

export const useLoadSearch = ({ filter }: { filter: SearchFilterKey }) => {
  const [showSearch, setShowSearch] = useState(false)
  const setHeader = useHeader((s) => s.setConfig)
  const searchContext = useContext(SearchContext)
  const { setActiveFilter } = searchContext

  useEffect(() => {
    setActiveFilter(filter)
  }, [setActiveFilter, filter])

  // Header need to be hidden when the searchbar is moved to the top of the page
  useEffect(() => {
    setHeader({ hidden: showSearch, fillSpace: showSearch })
  }, [showSearch, setHeader])

  return { showSearch, setShowSearch, ...searchContext }
}
