import { striptags } from 'striptags'
import decode from 'entity-decode'

export const stripHtml = (str: string) =>
  decode(striptags(str as string, { tagReplacementText: '' }))
    .replace(/\s+/g, ' ')
    .trim()

export const truncateString = ({
  str,
  len = 100,
}: {
  str?: string
  len?: number
}) =>
  str && str.length > len
    ? str.replace(/\n/g, ' ').substring(0, len - 3) + '...'
    : str

export function isUUID(s: string) {
  return /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(
    s
  )
}

export const genHash = () => Math.random().toString(16).substring(2, 16)

export const stripText = (text: string) => text.replace(/<[^>]*>?/gm, '')
