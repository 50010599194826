/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from '@fable/types'
import { Response } from '../../types'
import { AuthActions } from '../actions'
import { AuthTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

export enum AuthKeys {
  actionCode = 'actionCode',
  invite = 'invite',
  resetPassword = 'resetPassword',
  resetPasswordEmail = 'resetPasswordEmail',
  userSignIn = 'userSignIn',
  userSignOut = 'userSignOut',
  userSignUp = 'userSignUp',
  actionCodeVerify = 'actionCodeVerify',
  token = 'token',
  initial_auth_method = 'initial_auth_method',
  isAuthenticated = 'isAuthenticated',
  isVerifying = 'isVerifying',
  user = 'user',
}

export interface AuthState {
  [AuthKeys.actionCode]: Response
  [AuthKeys.invite]: Response
  [AuthKeys.resetPassword]: Response
  [AuthKeys.resetPasswordEmail]: Response
  [AuthKeys.userSignIn]: Response
  [AuthKeys.userSignOut]: Response
  [AuthKeys.userSignUp]: Response
  [AuthKeys.actionCodeVerify]: Response
  [AuthKeys.token]: Response
  [AuthKeys.initial_auth_method]: string
  [AuthKeys.isAuthenticated]: boolean
  [AuthKeys.isVerifying]: boolean
  [AuthKeys.user]: User
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: AuthState = {
  [AuthKeys.actionCode]: props,
  [AuthKeys.invite]: props,
  [AuthKeys.resetPassword]: props,
  [AuthKeys.resetPasswordEmail]: props,
  [AuthKeys.userSignIn]: props,
  [AuthKeys.userSignOut]: props,
  [AuthKeys.userSignUp]: props,
  [AuthKeys.actionCodeVerify]: props,
  [AuthKeys.token]: props,
  initial_auth_method: '',
  isAuthenticated: false,
  isVerifying: false,
  user: {},
}

const reducer = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  const stateProps = { state, props }

  switch (action.type) {
    // APPLY ACTION CODE
    case AuthTypes.APPLY_ACTION_CODE__REQUEST:
      return setRequestState(stateProps, AuthKeys.actionCode)
    case AuthTypes.APPLY_ACTION_CODE__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.actionCode, action.payload)
    case AuthTypes.APPLY_ACTION_CODE__ERROR:
      return setErrorState(stateProps, AuthKeys.actionCode, action.payload)

    // AUTH
    case AuthTypes.AUTH__SUCCESS:
      return { ...state, isAuthenticated: true, user: action.payload }
    case AuthTypes.AUTH__CLEAR:
      return { ...state, isAuthenticated: false, user: {} }

    // UPDATE AUTH USER
    case AuthTypes.UPDATE_AUTH_USER:
      return { ...state, user: action.payload }

    // GET INVITE
    case AuthTypes.GET_INVITE__REQUEST:
      return setRequestState(stateProps, AuthKeys.invite)
    case AuthTypes.GET_INVITE__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.invite, action.payload)
    case AuthTypes.GET_INVITE__ERROR:
      return setErrorState(stateProps, AuthKeys.invite, action.payload)

    // RESET PASSWORD
    case AuthTypes.RESET_PASSWORD__REQUEST:
      return setRequestState(stateProps, AuthKeys.resetPassword)
    case AuthTypes.RESET_PASSWORD__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.resetPassword, {
        isReset: true,
      })
    case AuthTypes.RESET_PASSWORD__ERROR:
      return setErrorState(stateProps, AuthKeys.resetPassword, action.payload)

    // SEND RESET PASSWORD EMAIL
    case AuthTypes.SEND_RESET_PASSWORD_EMAIL__REQUEST:
      return setRequestState(stateProps, AuthKeys.resetPasswordEmail)
    case AuthTypes.SEND_RESET_PASSWORD_EMAIL__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.resetPasswordEmail, {
        isSent: true,
      })
    case AuthTypes.SEND_RESET_PASSWORD_EMAIL__ERROR:
      return setErrorState(
        stateProps,
        AuthKeys.resetPasswordEmail,
        action.payload
      )
    case AuthTypes.SEND_RESET_PASSWORD_EMAIL__CLEAR:
      return setClearState(stateProps, AuthKeys.resetPasswordEmail)

    // SIGN IN
    case AuthTypes.SIGNIN__REQUEST:
      return setRequestState(stateProps, AuthKeys.userSignIn)
    case AuthTypes.SIGNIN__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.userSignIn, {
        isSignedIn: true,
        initial_auth_method: state.initial_auth_method || action.payload.method,
      })
    case AuthTypes.SIGNIN__ERROR:
      return setErrorState(stateProps, AuthKeys.userSignIn, action.payload)
    case AuthTypes.SIGNIN__CLEAR:
      return setClearState(stateProps, AuthKeys.userSignIn)

    // SIGN OUT
    case AuthTypes.SIGNOUT__REQUEST:
      return setRequestState(stateProps, AuthKeys.userSignOut)
    case AuthTypes.SIGNOUT__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.userSignOut, {
        isSignedOut: true,
      })
    case AuthTypes.SIGNOUT__ERROR:
      return setErrorState(stateProps, AuthKeys.userSignOut, action.payload)
    case AuthTypes.SIGNOUT__CLEAR:
      return setClearState(stateProps, AuthKeys.userSignOut)

    // SIGN UP
    case AuthTypes.SIGNUP__REQUEST:
      return setRequestState(stateProps, AuthKeys.userSignUp)
    case AuthTypes.SIGNUP__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.userSignUp, {
        isSignedUp: true,
      })
    case AuthTypes.SIGNUP__ERROR:
      return setErrorState(stateProps, AuthKeys.userSignUp, action.payload)
    case AuthTypes.SIGNUP__CLEAR:
      return setClearState(stateProps, AuthKeys.userSignUp)

    // VERIFY ACTION CODE
    case AuthTypes.VERIFY_ACTION_CODE__REQUEST:
      return setRequestState(stateProps, AuthKeys.actionCodeVerify)
    case AuthTypes.VERIFY_ACTION_CODE__SUCCESS:
      return setSuccessState(
        stateProps,
        AuthKeys.actionCodeVerify,
        action.payload
      )
    case AuthTypes.VERIFY_ACTION_CODE__ERROR:
      return setErrorState(
        stateProps,
        AuthKeys.actionCodeVerify,
        action.payload
      )

    // VERIFY
    case AuthTypes.VERIFY_USER__REQUEST:
      return { ...state, isVerifying: true }
    case AuthTypes.VERIFY_USER__SUCCESS:
      return { ...state, isVerifying: false }

    // TOKEN
    case AuthTypes.TOKEN__REQUEST:
      return setRequestState({ state, props: action.payload }, AuthKeys.token)
    case AuthTypes.TOKEN__SUCCESS:
      return setSuccessState(stateProps, AuthKeys.token, action.payload)
    case AuthTypes.TOKEN__ERROR:
      return setErrorState(stateProps, AuthKeys.token, action.payload)

    default:
      return state
  }
}

export default reducer
