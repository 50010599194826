import { Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useState } from 'react'
import { AccordionIcon } from './AccordionIcon'
import { LegacyAccordion } from './legacy'

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string
  children: React.ReactNode | React.ReactNode[]
  /**
   * @note
   * this is a temporary prop until the
   * club 2.0 page is launched
   */
  legacy?: boolean
}

export const Accordion = ({ legacy, ...props }: Props) => {
  const { title, children, className, ...sharedProps } = props
  const { colors } = useTheme()
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  if (legacy) return <LegacyAccordion {...props} />

  return (
    <div
      data-testid="accordion"
      className={cx(
        css`
          position: relative;
          padding-top: 24px;
          margin-top: 24px;
          border-top: 1px solid ${colors.pewterGrey};
          cursor: pointer;
        `,
        className
      )}
      onClick={toggle}
      {...sharedProps}
    >
      <Text
        className={css`
          width: calc(100% - 28px);
          color: ${colors.graphite};
          cursor: inherit;
        `}
        tag="p"
        type="subhead"
        sizing="XS"
      >
        {title}
      </Text>
      {open ? (
        <div
          className={css`
            margin-top: 12px;
          `}
        >
          {children}
        </div>
      ) : null}
      <AccordionIcon open={open} />
    </div>
  )
}
