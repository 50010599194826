import { BookImage, Button, FlexBox, Text } from '@fable/components'
import { usePreloadImage } from '@fable/hooks'
import { ThemeModes, css, useTheme } from '@fable/theme'
import { Link } from 'react-router-dom'
import { CompProps } from 'types'

interface ActivityPostCardProps {
  backgroundColor?: string
  description?: string
  image?: string
  link: string
  subtitle?: string
  title: string
  type: 'book' | 'club'
  theme?: ThemeModes
  stacked?: boolean
  onCardClick?: () => void
}

export const ActivityPostCard = ({
  backgroundColor,
  description,
  image,
  link,
  subtitle,
  title,
  type,
  theme = 'light',
  stacked = false,
  onCardClick,
}: CompProps<ActivityPostCardProps>) => {
  const cardImage = usePreloadImage(image || '', 620)
  const { mediaQueries, colors } = useTheme()
  const bgColor = backgroundColor || colors.uiPearlExtraLight
  const isBook = type === 'book'
  const buttonText = isBook ? 'See details' : 'Check out club'
  const isLight = theme === 'light'
  const textColor = isLight ? colors.uiGraphite : colors.uiWhite

  const content = (
    <FlexBox
      className={css`
        padding: ${isBook ? '40px 20px' : '20px 20px 40px'};
        width: 100%;
        border-radius: 20px;
        background-color: ${bgColor};
        flex-direction: column;
        gap: 40px;
        ${mediaQueries.mobileL} {
          padding: 40px;
          flex-direction: ${stacked ? 'column' : 'row'};
          gap: ${stacked ? '40px' : '20px'};
        }
      `}
      justifyContent="space-around"
      alignItems="center"
    >
      <ActivityPostCardImage src={cardImage?.src} title={title} type={type} />
      <FlexBox
        className={css`
          width: 100%;
          max-width: 258px;
          text-align: center;
        `}
        flexDirection="column"
        alignItems="center"
        gap="14px"
      >
        <Text
          className={css`
            cursor: inherit;
          `}
          tag="h2"
          type="header"
          sizing="S"
          color={textColor}
          align="center"
          truncate={2}
        >
          {title}
        </Text>
        {!!description?.length ? (
          <FlexBox flexDirection="column" alignItems="center" gap="4px">
            {!!subtitle?.length ? (
              <Text
                className={css`
                  margin-bottom: 4px;
                `}
                type="body"
                sizing="M"
                color={textColor}
                align="center"
                truncate={2}
              >
                {subtitle}
              </Text>
            ) : null}
            <Text
              type="body"
              sizing="M"
              color={textColor}
              align="center"
              truncate={3}
            >
              {description}
            </Text>
          </FlexBox>
        ) : null}
        <Button
          className={css`
            margin: 6px auto 0;
            max-width: 200px;
          `}
          negative={!isLight}
        >
          {buttonText}
        </Button>
      </FlexBox>
    </FlexBox>
  )

  if (!!onCardClick) {
    return (
      <div
        className={css`
          cursor: pointer;
        `}
        onClick={() => onCardClick()}
      >
        {content}
      </div>
    )
  }

  if (!!link.length) return <Link to={link}>{content}</Link>

  return content
}

export const ActivityPostCardImage = ({
  src,
  type,
  title,
}: {
  src?: string
  title: string
  type: 'book' | 'club'
}) => {
  const { mediaQueries } = useTheme()
  const altTag = `${title} card pic`

  if (!src) return null

  if (type === 'book') {
    return (
      <BookImage
        className={css`
          width: 120px;
          height: 180px;
          flex-shrink: 0;
          box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.17);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          ${mediaQueries.mobileS} {
            width: 160px;
            height: 243px;
          }
        `}
        image={src}
        altTag={altTag}
      />
    )
  }

  return (
    <img
      className={css`
        width: 100%;
        aspect-ratio: 5 / 4;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;
        ${mediaQueries.mobileL} {
          border-radius: 16px;
          max-width: 244px;
        }
      `}
      src={src}
      alt={altTag}
    />
  )
}
