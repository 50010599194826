import { BookListResult, Quote, Review, UserPost } from '@fable/types'
import { isPostWithObj } from 'components/activity'
import { HeadProps } from 'components/head/Head'

const getUserPostTitle = (post: UserPost) => {
  if (!!post.title) return post.title

  if (isPostWithObj(post)) {
    const prefix =
      post.author.display_name + ' ' + post.activity_text.toLowerCase()
    if (!!post.quotes?.length) return `${prefix} ${post.quotes[0].book.title}`
    if (!!post.books.length) return `${prefix} ${post.books[0]?.title}`
    if (!!post.club) return `${prefix} ${post.club.title}`
  }

  return post.activity_text
}

const userPost = (post: UserPost): HeadProps => {
  switch (post.content_type) {
    case 'editorialPost':
      return {
        title: getUserPostTitle(post),
        description: post.body,
        image:
          post.featured_image ||
          post.club?.hero_image ||
          post.books[0]?.cover_image ||
          '',
        canonical: true,
      }
    case 'userPost':
      return {
        title: getUserPostTitle(post),
        description: post.body,
        image:
          post.featured_image || post.image || post.books[0]?.cover_image || '',
        canonical: true,
      }
  }
}

const bookList = (booklist: BookListResult): HeadProps => ({
  title: booklist?.name,
  description: booklist?.description || '',
  image: booklist?.cover_image || '',
  canonical: true,
})

const review = (review: Review): HeadProps => ({
  title: `${review?.user.display_name}'s review`,
  image: review?.review_image || '',
  canonical: true,
})

const quote = (quote: Quote): HeadProps => ({
  title: `${quote?.book?.authors?.[0].name} quote shared by ${quote?.user?.display_name} on Fable`,
  description: `Read, discover, and discuss ${quote?.book?.authors?.[0].name} books on Fable`,
  image: quote?.image,
  canonical: true,
})

export const pageMetadata = {
  signIn: { title: 'Log In' },
  signUp: { title: 'Sign Up' },
  resetPassword: { title: 'Reset Password' },
  verifyEmail: { title: 'Verify Email' },
  forBusiness: {
    title: 'Fable for Business',
    description:
      'Fable for Business empowers your team to read, discuss, and explore innovative ideas together. With book recommendations from business leaders, social reading is the perfect mental wellness perk!',
    isTitleStrict: true,
  },
  homeLegacy: {
    title: 'Fable: The Book Club App for Social Reading',
    description:
      'Share the joy of reading with online book clubs. Download the app to browse, buy, and read ebooks with friends.',
    isTitleStrict: true,
  },
  goodreadsLp: {
    title: 'The modern app for every reader',
    description:
      'Beautifully designed. Independently owned. Personalized just for you.',
    isTitleStrict: true,
  },
  main: {
    title: 'Fable: The social app for bookworms and bingewatchers',
    description:
      'Download the app to discover, organize, and discuss books and TV with a community of like-minded fans!',
    isTitleStrict: true,
  },
  premiumClubs: {
    title: 'Diverse Book Clubs for Every Reader | Fable',
    description:
      "Join one of Fable's exclusive online book clubs with famous authors like LeVar Burton, Scott Shute, Ly Tran, and more. Only on Fable!",
  },
  explore: {
    title: 'Shop Books Online | Fable',
    description:
      "In need of good book? Shop books online with Fable's collection of classic novels, poetry, mystery, self-help, and more. Free books available!",
    isTitleStrict: true,
  },
  company: {
    title: 'Social Reading: Connect with Fellow Readers | Fable',
    description:
      'Fable is the largest social reading app with personalized book recommendations and clubs available on the market. Download the app today!',
    isTitleStrict: true,
  },
  folios: {
    title: 'Book Recommendations for the Avid Reader | Fable',
    description:
      'Fable offers a collection of folios from a diverse set of authors showcasing their top book recommendations for topics close to their hearts.',
    isTitleStrict: true,
  },
  blog: {
    title: 'Blog on Books | Fable',
    description:
      "Fable's blog features insights for book clubs, read and watch book recommendations, mental health & wellness tips & more for everyone. Start your Journey.",
    isTitleStrict: true,
  },
  events: {
    title: 'Book Club Events Hosted by Fable',
    description:
      'Get the scoop on upcoming book club events hosted by Fable including exclusive interviews, poetry hour, mental health awareness, and more.',
  },
  eventConfirmation: {
    title: 'RSVP Confirmed',
  },
  support: {
    title: 'Book Reading Platform | Fable Help & Support',
    description:
      "Check out help & support for Fable's book reading platform packed with frequently asked questions from customers like you! Get the support you need.",
    isTitleStrict: true,
  },
  download: {
    title: 'Download the Fable app for the full experience',
  },
  pricing: { title: 'Compare plans' },
  terms: { title: 'Terms of Use' },
  termsIos: { title: 'Terms of Use' },
  privacy: { title: 'Privacy Policy' },
  privacyIos: { title: 'Privacy Policy' },
  security: { title: 'Security at Fable' },
  employeeAndHrPrivacy: { title: 'Employee and HR Privacy Notice' },
  communityGuidelines: { title: 'Fable Community Guidelines' },
  copyrightPolicy: { title: 'Copyright Policy' },
  press: { title: 'Press' },
  mastercardTerms: { title: 'Mastercard Terms of Use' },
  genres: { title: 'Genres' },
  bookshelf: { title: 'Your Bookshelf' },
  internalProfile: { title: 'Your Profile' },
  accountSettings: { title: 'Account Settings' },
  orders: { title: 'Orders' },
  inviteReferral: { title: 'Invite Friends & Manage Credits' },
  checkout: { title: 'Checkout ' },
  checkoutSuccess: { title: 'Checkout Success' },
  giftsRedeem: {
    title: 'Redeem a Gift',
    description: 'Get started on Fable by redeeming your gift here',
  },
  giftsPurchase: {
    title: 'Gift Purchase',
    description: 'Give the bookworms in your life a gift they’ll love',
  },
  giftsCertificate: {
    title: 'Gift Certificate',
  },
  pride: {
    title: 'Read with Pride',
    description:
      'Join one of our many LGBTQ+ Book Clubs and make every month Pride month',
  },
  goodreadsImport: {
    title: 'Goodreads Import',
  },
  plans: {
    title: 'Fable Membership Plans',
  },
  searchResults: {
    title: 'Search Results',
  },
  userPost,
  bookList,
  review,
  quote,
}
