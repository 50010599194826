import { css, cx, useTheme, FableTheme } from '@fable/theme'
import { Link } from 'react-router-dom'
import { FORM_TYPES } from '../../app/auth/constants'
import { useTypedSelector, useActions } from '../../hooks'
import useWindowSize from '../../hooks/useWindowSize'
import { SubheaderNavLink } from '../../types'
import { isExternalLink } from '../../utils'

interface Props {
  item: SubheaderNavLink
  secondary: boolean
}

export const SubheaderNavItem: React.VFC<Props> = ({ item, secondary }) => {
  const theme = useTheme()
  const styles = getStyles(theme, secondary)
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { openAuthModal } = useActions()
  const windowWidth = useWindowSize().width
  const active = window.location.pathname === item.path
  const itemClass = cx(styles.item, active && styles.active)

  if (windowWidth > 620 && item.title.includes('Explore Books')) return null

  const openAuth = (e: React.MouseEvent<HTMLElement>, path: string) => {
    e.stopPropagation()
    openAuthModal(FORM_TYPES.SIGN_UP, path)
  }

  let navItem = (
    <Link
      className={itemClass}
      to={item.path}
      onClick={(e) => e.stopPropagation()}
    >
      {item.title}
    </Link>
  )

  if (!isAuthenticated && item.isPrivate) {
    navItem = (
      <div className={itemClass} onClick={(e) => openAuth(e, item.path)}>
        {item.title}
      </div>
    )
  }

  if (isExternalLink(item.path)) {
    navItem = (
      <a
        className={itemClass}
        href={item.path}
        onClick={(e) => e.stopPropagation()}
        rel="noopener noreferrer"
        target="_blank"
      >
        {item.title}
      </a>
    )
  }

  return <li className={styles.itemContainer}>{navItem}</li>
}

const getStyles = (
  { colors, motion, mediaQueries }: FableTheme,
  secondary: boolean
) => ({
  itemContainer: css`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${colors.whiteSmoke};

    &:last-child {
      border-bottom: none;
    }

    ${mediaQueries.tabletL} {
      border-bottom: none;
      width: auto;
    }
  `,
  item: css`
    display: block;
    width: 100%;
    padding: 14px;
    position: relative;
    color: ${colors.blackSwan};
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    ${motion.transition('opacity')};

    &:hover {
      opacity: 0.7;
    }

    ${mediaQueries.tabletL} {
      width: auto;
      border: none;
      color: ${secondary ? colors.blackSwan : colors.whiteFang};

      &:after {
        content: '';
        width: calc(100% - 16px);
        height: 4px;
        background-color: ${colors.shamrock};
        border-radius: 4px 4px 0 0;
        position: absolute;
        left: 50%;
        bottom: ${secondary ? '-3px' : 0};
        transform: translateX(-50%);
        opacity: 0;
        ${motion.transition('opacity')};
      }
    }
  `,
  active: css`
    &:after {
      opacity: 1;
    }
  `,
})
