// import { Dispatch } from 'redux';
// import { ToastActions } from '../actions';
import { ToastTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { Toast } from '../../types'

// Not sure why this throws TypeScript errors
// type DispatchType = Dispatch<ToastActions>;

// SET TOAST
export const setToast = (args: Toast) => async (dispatch: any) => {
  dispatch(setAction(ToastTypes.SET_TOAST, args))
  if (args.showToast) {
    setTimeout(() => {
      dispatch(setAction(ToastTypes.SET_TOAST, { showToast: false }))
    }, 5000)
  }
}
