import { FlexBox } from '@fable/components'
import { PostBook } from '@fable/types'
import { ActivityPostCard } from './ActivityPostCard'
import { getAuthorNames } from '@fable/utils'
import { bookRoute } from 'app/store/routes'
import { ActivityPostOptions } from './ActivityPost'

interface Props {
  books: Partial<PostBook>[]
  options?: ActivityPostOptions
}

export const ActivityPostBooks = ({ books, options }: Props) => (
  <FlexBox flexDirection="column" gap="20px">
    {books.map((book) => {
      if (book?.book_type === 'tv_show') return null

      return (
        <ActivityPostCard
          key={book.id}
          type="book"
          theme="dark"
          title={book.title as string}
          subtitle={book.subtitle}
          description={`Written by ${getAuthorNames(book.authors)}`}
          link={bookRoute(book)}
          backgroundColor={book.background_color}
          image={book.cover_image}
          {...options}
        />
      )
    })}
  </FlexBox>
)
