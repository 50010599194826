/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import { ModalActions } from '../actions'
import { ModalTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { CompanyInvite } from '@fable/types'

type DispatchType = Dispatch<ModalActions>

// MODAL - OPEN AUTH
export const openAuthModal =
  (
    authType: string,
    path?: string | null,
    postAuthActions?: any,
    options?: {
      onClose?: () => void
      header?: React.ReactNode
      invite?: CompanyInvite | null
    }
  ) =>
  (dispatch: DispatchType) => {
    const data = {
      authType,
      path,
      postAuthActions,
      options,
    }

    setTimeout(() => {
      dispatch(setAction(ModalTypes.MODAL_AUTH__OPEN, data))
    }, 300)
  }

// MODAL - OPEN
export const openModal =
  (
    content: JSX.Element,
    modalType?: string,
    preventClickOutside?: boolean,
    options?: { onClose?: () => void; header?: React.ReactNode }
  ) =>
  (dispatch: DispatchType) => {
    const data = {
      content,
      modalType,
      preventClickOutside,
      options,
    }
    setTimeout(() => {
      dispatch(setAction(ModalTypes.MODAL__OPEN, data))
    }, 300)
  }

// MODAL - CLOSE
export const closeModal = () => (dispatch: DispatchType) => {
  dispatch(setAction(ModalTypes.MODAL__CLOSE))
}

// MODAL - CLEAR
export const clearModal = () => (dispatch: DispatchType) => {
  dispatch(setAction(ModalTypes.MODAL__CLEAR))
}
