import { css, cx } from '@fable/theme'
import { analytics } from 'segment_analytics'

interface AppStoreProviderImage {
  light: string
  outlineGreen: string
  regular: string
  dark: string
  ultraDark: string
}

interface AppStoreProvider {
  title: string
  link: string
  image: AppStoreProviderImage
  isVector: boolean
  isExternal: boolean
  newTab: boolean
}

const AppStoreLink = ({
  provider,
  imageType,
  ...nativeProps
}: {
  provider: AppStoreProvider
  imageType: keyof AppStoreProviderImage
} & React.HTMLProps<HTMLDivElement>) => {
  const styles = {
    appLink: css`
      :first-child {
        margin-right: 12px;
      }
    `,
    appLinkImage: css`
      width: 100%;
      max-width: 159px;
    `,
  }

  return (
    <a
      href={provider.link}
      target="_blank"
      rel="noreferrer"
      className={cx(styles.appLink, nativeProps.className)}
      onClick={() => analytics.events.appDownload()}
    >
      <img
        className={styles.appLinkImage}
        src={provider.image[imageType]}
        alt={`${provider.title} Badge`}
      />
    </a>
  )
}

export default AppStoreLink
