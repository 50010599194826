import { css, cx, CSSInterpolation } from '@fable/theme'

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  background?: string
  diameter: string
}

const Circle: React.VFC<Props> = ({
  children,
  diameter,
  background,
  ...nativeProps
}) => {
  const styles: CSSInterpolation = {
    width: diameter,
    height: diameter,
    borderRadius: '50%',
    overflow: 'hidden',
  }
  if (background) styles.background = background

  return (
    <div
      {...nativeProps}
      className={cx(css(styles), nativeProps.className)}
      onClick={nativeProps.onClick}
    >
      {children}
    </div>
  )
}

export default Circle
