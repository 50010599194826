import { get } from '../fable'

const getAllPosts = async () => await get(`/user_posts/?limit=10&offset=0`)

const getUserPosts = async (uid: string) =>
  await get(`/user/${uid}/user_posts/?limit=10&offset=0`)

const getUserPost = async (id: string) => await get(`/user_posts/${id}/`)

export const posts = {
  getAllPosts,
  getUserPosts,
  getUserPost,
}
