import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Text } from '@fable/components'
import { Carousel } from './Carousel'
import { CarouselControl } from './CarouselControl'
import { useCarouselController } from './useCarouselController'
import Loader from '../loader'
import { CarouselProps } from './types'

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string
  config: Partial<CarouselProps>
  hideControls?: boolean
}

export const CarouselRow: React.VFC<Props> = ({
  children,
  className,
  title,
  config,
  hideControls = false,
  ...nativeProps
}) => {
  const { mediaQueries, colors } = useTheme()
  const carouselController = useCarouselController()

  const controlStyles = css`
    border-color: ${colors.whiteSmoke};
    &:disabled {
      border-color: ${colors.whiteSmoke}66;
    }
  `

  return (
    <div className={className} {...nativeProps}>
      <FlexBox
        className={css`
          margin-bottom: 12px;
          justify-content: center;
          ${mediaQueries.mobileL} {
            margin-bottom: 22px;
            justify-content: space-between;
          }
        `}
        justifyContent="space-between"
      >
        <Text
          className={css`
            width: 100%;
            text-align: left;
          `}
          tag="h2"
          type="header"
          sizing="XS"
          text={title}
        />
      </FlexBox>
      {!children ? (
        <Loader />
      ) : (
        <div
          className={cx(
            css`
              position: relative;
            `,
            'fade'
          )}
        >
          <Carousel {...config} controller={carouselController}>
            {children}
          </Carousel>
          {hideControls ? null : (
            <FlexBox
              className={css`
                display: none;
                ${mediaQueries.tablet} {
                  display: ${carouselController.allSlidesInView
                    ? 'none'
                    : 'flex'};
                  position: absolute;
                  top: -54px;
                  right: 0;
                  z-index: 2;
                }
              `}
              alignItems="center"
            >
              <CarouselControl
                className={cx(
                  css`
                    margin-right: 4px;
                  `,
                  controlStyles
                )}
                controller={carouselController}
                outline
                left
              />
              <CarouselControl
                className={controlStyles}
                controller={carouselController}
                outline
              />
            </FlexBox>
          )}
        </div>
      )}
    </div>
  )
}
