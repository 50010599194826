import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Hyperlink, Text } from '@fable/components'
import { css } from '@fable/theme'
import { isUrlExternal } from 'is-url-external'
import { matchPath } from 'react-router-dom'
import { getSlug } from './getSlug'

export * from './date'
export { default as isFalseyObject } from './isFalseyObject'
export * from './altTag'
export * from './billing'
export * from './colors'
export * from './cookie'
export * from './error'
export * from './expiry'
export * from './getSlug'
export * from './modal'
export * from './promo'
export * from './referrals'
export * from './string'
export { default as sanctionedCountries } from './sanctionedCountries'
export { default as lazyWithRetry } from './lazyWithRetry'
export { default as handleDeeplink } from './handleDeeplink'
export * from './authValidationSchemas'

// check if array is empty
export function isEmptyArr(arr) {
  return arr.length === 0
}

// check if object is empty
export function isEmptyObj(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

// check if book exists
export function bookExists(arr, obj, isDefault) {
  if (
    arr.some((item) =>
      isDefault ? item.id === obj.id : item.book.id === obj.id
    )
  ) {
    return true
  }
  return false
}

// check if link is external
export function isExternalLink(url) {
  return url.includes('https') || url.includes('http') || url.includes('mailto')
}

// pick random item
export function getRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

// render video iframe
export function renderVideo(url) {
  let providerUrl

  if (url.includes('vimeo')) {
    const id = getSlug(url)
    providerUrl = `https://player.vimeo.com/video/${id}`
  }

  if (url.includes('youtube') || url.includes('youtu.be')) {
    const watchParam = 'watch?v='
    let id = getSlug(url)

    if (id.includes(watchParam)) {
      id = id.replace(watchParam, '')
    }

    providerUrl = `https://www.youtube.com/embed/${id}?modestbranding=1;showinfo=0;rel=0`
  }

  return (
    <iframe
      title="video-embed"
      src={providerUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )
}

// get first and last name initials
export function getInitials(name) {
  let initials = name.match(/\b\w/g) || []
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

  return initials
}

// get url parameter by name
export function getParameterByName(name, str = window.location.href) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regexS = '[\\?&]' + name + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(str)
  if (results == null) return ''
  else return decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function hyperLinkTarget(uri) {
  return isUrlExternal(uri) ? '_blank' : '_self'
}

function isVideo(uri) {
  return /youtu\.be\/|youtube\.com\/watch|player\.vimeo\.com/.test(uri)
}
const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) return node
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node) return getNodeText(node.props.children)
}

function headingId(children) {
  const textContent = getNodeText(children)
  return textContent.replace(/[^A-Za-z0-9]/g, '').substring(0, 64)
}

// description formatting styles
export const descriptionStyles = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Text
        id={headingId(children)}
        type="header"
        sizing={{ base: 'M', mobileL: 'L', desktop: 'XL' }}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Text
        id={headingId(children)}
        type="header"
        sizing={{ base: 'S', mobileL: 'M', desktop: 'L' }}
        tag="h2"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Text
        id={headingId(children)}
        type="subhead"
        sizing={{ base: 'L', desktop: 'XL' }}
        tag="h3"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Text
        id={headingId(children)}
        type="subhead"
        sizing={{ base: 'M', desktop: 'L' }}
        tag="h4"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Text
        id={headingId(children)}
        type="subhead"
        sizing={{ base: 'S', desktop: 'M' }}
        tag="h5"
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Text
        id={headingId(children)}
        type="subhead"
        sizing={{ base: 'XS', desktop: 'S' }}
        tag="h6"
      >
        {children}
      </Text>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text type="body" sizing="M">
        {children}
      </Text>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <Text
        className={css`
          padding: 24px;
          background: #f9f9f9;
          border-radius: 12px;
          font-weight: 600;
        `}
        type="subhead"
        sizing={{ base: 'XS', mobileL: 'S', desktop: 'M' }}
      >
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      if (isVideo(node.data.uri)) {
        return (
          <div className="inline-video-embed">{renderVideo(node.data.uri)}</div>
        )
      }
      return (
        <Hyperlink href={node.data.uri} target={hyperLinkTarget(node.data.uri)}>
          {children}
        </Hyperlink>
      )
    },
  },
}

// Convert camelCase keys to snake_case
export const camelToSnakeCase = (key) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

// Convert object keys from camelCase to snake_case
export const keysToSnakeCase = (params) => {
  const newParams = {}

  for (const param in params) {
    newParams[camelToSnakeCase(param)] = params[param]
  }

  return newParams
}

// Check if url matches
export const isUrlMatched = (path, currentPath, hasParam = false) => {
  if (!hasParam) return currentPath === path

  const match = matchPath(
    {
      path,

      strict: false,
    },
    currentPath
  )

  return match && match.isExact ? true : false
}

export const applyImagePrefix = (image, index = 8) => {
  const prefix = 'atsxghqeko.cloudimg.io/v7/'
  if (image.includes(prefix)) return image

  return image.slice(0, index) + prefix + image.slice(index)
}

// check privacy
export const isPrivate = (privacy) => {
  if (!privacy) return false

  return privacy !== 'everyone'
}

export const formatMemberCount = (count) => {
  if (count > 999) {
    return parseFloat((count / 1000).toFixed(1)) + 'k'
  }

  return count
}

export const getName = (fullName) => {
  const nameSplit = fullName.split(' ')
  const first = nameSplit[0]
  const last = nameSplit[nameSplit.length - 1]
  let name = {
    first,
    middle: null,
    last,
  }

  if (nameSplit.length === 3) {
    name.middle = nameSplit[1]
  }

  return name
}

export const isTestEnv = Boolean(window.location.host !== 'fable.co')

export const isValidCompanyEmail = ({ email, pattern }) => {
  if (!email) return false

  const regex = new RegExp(`^.*@(${pattern})$`, 'i')
  return !!email.match(regex)
}

export const getGiftQueryParams = ({
  bookParam,
  planParam,
  bookIsbn,
  giftOrder,
}) => {
  let queryParams = ''
  if (bookParam) {
    if (planParam) queryParams += `?p=${bookParam}&p=${planParam}&`
    else queryParams += `/${bookIsbn}?`
    queryParams += ``
  } else if (planParam) {
    queryParams += `?p=${planParam}&p=type:all_access&`
  }

  queryParams += `gift=${giftOrder.id}`

  return queryParams
}
