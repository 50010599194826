import { useTheme } from '@fable/theme'

export const ArrowDownDoodle = ({ className = '' }: { className?: string }) => {
  const { colors } = useTheme()

  return (
    <svg
      className={className}
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5372 21.7856C28.8911 21.277 29.6909 21.3036 30.1016 21.6818L30.1252 21.6882C30.5976 22.1232 30.5549 22.809 30.189 23.3144C28.8627 25.1583 27.5212 27.0114 26.195 28.8553C26.1018 29.7288 25.0057 30.0922 24.3676 29.5591C22.1761 27.7516 20.5427 25.3427 19.5436 22.621C19.0397 21.2523 21.1041 20.5717 21.6299 21.9061C22.1954 23.3315 22.9869 24.6298 23.9668 25.7506C23.8737 23.9958 23.5762 22.2398 23.2718 20.5089C22.9517 18.6932 22.5378 16.8927 21.9539 15.1539C20.7998 11.7202 18.9074 8.55795 16.1007 6.37103C11.8243 3.0407 6.43798 2.42957 1.31654 3.58464C-0.872074 3.93821 0.202122 2.33809 1.0128 1.49383C2.71393 1.11335 4.44683 0.921996 6.18066 0.963962C10.645 1.07398 15.0916 2.58727 18.4273 5.66688C21.402 8.42178 23.1847 12.1848 24.2371 16.1411C24.7831 18.1648 25.1678 20.2659 25.3868 22.3629C25.4904 23.4098 25.5666 24.4628 25.706 25.5193C25.7132 25.5883 25.7237 25.6447 25.7309 25.7137C26.6726 24.3971 27.5988 23.0897 28.5372 21.7856Z"
        fill={colors.uiGreenUltraDark}
      />
    </svg>
  )
}
