import { IconTypes } from '@fable/components'
import { routes as marketingRoutes } from 'app/marketing/routes'
import { routes as storeRoutes } from 'app/store/routes'
import { routes as communityRoutes } from 'app/community/routes'
import { START_A_CLUB_LINK, CAREERS_LINK } from '../../constants'

export interface FooterSocialLink {
  icon: IconTypes
  url: string
  width?: { desktop?: string; mobile?: string }
}

export const socialLinks: FooterSocialLink[] = [
  { icon: 'x', url: 'https://twitter.com/getfable', width: { mobile: '18px' } },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/fable/',
    width: { mobile: '18px' },
  },
  {
    icon: 'facebook-outline',
    url: 'https://www.facebook.com/getfable',
  },
  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/fablegroup/mycompany/',
  },
  {
    icon: 'youtube-outline',
    url: 'https://www.youtube.com/channel/UCqI3TsDOJOa9wgz1XJ8sc-A',
  },
  {
    icon: 'tiktok',
    url: 'https://www.tiktok.com/@getfable',
    width: { desktop: '20px', mobile: '15px' },
  },
]

export const footerLinks = [
  {
    title: 'Clubs',
    links: [
      {
        title: 'Join a club',
        link: marketingRoutes.clubsPage,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Start a club',
        link: START_A_CLUB_LINK,
        newTab: true,
        isExternal: true,
      },
    ],
  },
  {
    title: 'Explore',
    links: [
      {
        title: 'Find a book',
        link: storeRoutes.explore,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Folios',
        link: storeRoutes.folios,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Genres',
        link: storeRoutes.genres,
        newTab: false,
        isExternal: false,
      },
    ],
  },
  {
    title: 'About',
    links: [
      {
        title: 'Company',
        link: marketingRoutes.company,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Careers',
        link: CAREERS_LINK,
        newTab: true,
        isExternal: true,
      },
      {
        title: 'Press',
        link: marketingRoutes.press,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Community policy',
        link: marketingRoutes.communityGuidelines,
        newTab: false,
        isExternal: false,
      },
    ],
  },
  {
    title: 'Learn more',
    links: [
      {
        title: 'For Business',
        link: marketingRoutes.b2b,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'For Education',
        link: marketingRoutes.education,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Blog',
        link: communityRoutes.blog,
        newTab: false,
        isExternal: false,
      },
      {
        title: 'Events',
        link: communityRoutes.events,
        newTab: false,
        isExternal: false,
      },
    ],
  },
]
