import { IconSVGProps } from '../types'

export const PlayIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
    fill={fill}
  >
    <path d="M19,0A19,19,0,1,0,38,19,19,19,0,0,0,19,0ZM15.2,25.65V12.35a1,1,0,0,1,1.52-.76l8.87,6.65a.94.94,0,0,1,0,1.52l-8.87,6.65A1,1,0,0,1,15.2,25.65Z" />
  </svg>
)
