import { useMemo } from 'react'
import { CompanyInvite } from '@fable/types'
import { AUTH_PROVIDERS } from '@fable/api'

export const useAuthProviders = (invite?: CompanyInvite) =>
  useMemo(() => {
    const s = new Set<string>([])

    if (invite?.company) {
      if (invite?.capabilities?.auth_provider) {
        s.add(invite.capabilities.auth_provider)
      } else {
        s.add('email')
      }
    } else {
      for (const provider of AUTH_PROVIDERS) {
        s.add(provider.id)
      }
    }

    return s
  }, [invite?.company, invite?.capabilities?.auth_provider])
