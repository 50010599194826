import { useEffect, useRef } from 'react'
import { css, cx, useTheme } from '@fable/theme'
import { BookImage, Button, FlexBox, Text } from '@fable/components'
import { Book } from '@fable/types'
import InfoIcon from '../../../../../assets/icons/components/InfoIcon'
import { BookSMSForm } from '../../../../../components/sms_form/BookSMSForm'
import { BookLinkSent } from '../../../../../components/sms_form/BookLinkSent'
import Loader, { LoaderType } from '../../../../../components/loader'

import { useActions } from '../../../../../hooks/useActions'
import { useTypedSelector } from '../../../../../hooks/useTypedSelector'
import {
  FREE_BOOK_CONFIRMATION_TITLE,
  START_READING_TITLE,
} from '../../../constants'
import isEmpty from 'lodash/isEmpty'

import altTag from '../../../../../utils/altTag'
import './BookModal.scss'

export const BookModal = ({
  book,
  isOwned,
  preview = false,
}: {
  book: Book
  isOwned?: boolean
  preview?: boolean
}) => {
  const phoneNumber = useRef({ code: '', number: '' })
  const { authors, cover_image, is_free, title } = book

  const { colors, mediaQueries } = useTheme()
  const { addFreeBookToBookshelf, sendBookLinkSms, clearBookLinkSMS } =
    useActions()
  const { bookSMS } = useTypedSelector(({ user }) => user)
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { isOpen } = useTypedSelector(({ modal }) => modal)
  const isPublicDomainBook =
    !isOwned && is_free && book.source === 'standardebooks'

  useEffect(() => {
    if (!isOpen && !isEmpty(bookSMS.data) && bookSMS.data.isSent) {
      setTimeout(() => {
        clearBookLinkSMS()
      }, 300)
    }
  }, [isOpen, clearBookLinkSMS, bookSMS.data])

  const renderLoader = bookSMS.loading && (
    <Loader type={LoaderType.contained} backgroundColor={colors.whiteFang} />
  )

  const renderModalTitle = () => {
    if (isOwned) return START_READING_TITLE
    if (is_free) return FREE_BOOK_CONFIRMATION_TITLE
  }

  const handleSubmit = async () => {
    if (book) {
      const { id, is_free } = book
      if (is_free && isAuthenticated) {
        await addFreeBookToBookshelf(id)
      }
      const numberAsString =
        phoneNumber.current.code + phoneNumber.current.number
      await sendBookLinkSms(id, numberAsString)
    }
  }

  const renderContent = () => {
    const loading = bookSMS.loading ? 'loading' : ''

    if (!isEmpty(bookSMS.data) && bookSMS.data.isSent)
      return <BookLinkSent isOwned={isOwned} />

    return (
      <div
        className={cx(
          `book-modal-wrap ${loading}`,
          css`
            ${mediaQueries.tablet} {
              width: 500px;
            }
          `
        )}
      >
        <Text className="modal-title" type="subhead" sizing="S" tag="h3">
          {renderModalTitle()}
        </Text>
        <div className="modal-content book-modal">
          {isPublicDomainBook && (
            <div className="message">
              <InfoIcon />
              <p>Public domain book with no cost.</p>
            </div>
          )}

          <FlexBox
            className={css`
              margin-bottom: 16px;
              padding: 20px;
              background-color: ${colors.almostWhite};
              border-radius: 8px;
            `}
            alignItems="center"
          >
            <BookImage
              className={css`
                margin-right: 10px;
                width: 40px;
                height: 60px;
                flex-shrink: 0;
              `}
              image={cover_image}
              altTag={altTag.storeBook(book)}
              size={120}
            />
            <div className="book-details">
              <div className="top">
                <Text className="book-title" type="subhead" sizing="S" tag="h4">
                  {title}
                </Text>
                <Text
                  className="book-author"
                  type="body"
                  sizing="M"
                  color={colors.hurricane}
                >
                  By {authors[0].name}
                </Text>
              </div>
            </div>
          </FlexBox>
          <BookSMSForm
            // Temp to add last minute feature changes
            liftPhoneNumber={(phone: { code: string; number: string }) => {
              phoneNumber.current = phone
            }}
            onSubmit={handleSubmit}
            isModalOpen={isOpen}
            preview={preview}
            hideButton
          />
          <Button
            className={css`
              margin-top: 16px;
            `}
            width="100%"
            onClick={() => handleSubmit()}
          >
            Send Text
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderLoader}
      {renderContent()}
    </>
  )
}
