import { IconSVGProps } from '../types'

export const SparkleIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.98706 12.2112L7.62888 10.7479C8.20012 9.44576 9.22823 8.40918 10.5107 7.84255L12.2774 7.06199C12.839 6.81384 12.839 6.0005 12.2774 5.75234L10.5658 4.99614C9.25039 4.41494 8.20385 3.33999 7.64241 1.9934L6.99225 0.434012C6.75098 -0.144671 5.94768 -0.14467 5.70642 0.434012L5.05624 1.99339C4.4948 3.33999 3.44823 4.41494 2.13279 4.99614L0.421272 5.75234C-0.140424 6.0005 -0.140424 6.81384 0.421272 7.06199L2.18797 7.84255C3.47043 8.40918 4.49856 9.44576 5.06976 10.7479L5.71161 12.2112C5.95833 12.7736 6.74031 12.7736 6.98706 12.2112ZM13.4133 15.7739L13.5938 15.3621C13.9156 14.6279 14.4952 14.0433 15.2183 13.7235L15.7744 13.4776C16.0752 13.3446 16.0752 12.9095 15.7744 12.7765L15.2495 12.5443C14.5077 12.2163 13.9177 11.6101 13.6014 10.8509L13.4161 10.4059C13.2868 10.0958 12.8561 10.0958 12.7269 10.4059L12.5415 10.8509C12.2253 11.6101 11.6353 12.2163 10.8936 12.5443L10.3685 12.7765C10.0678 12.9095 10.0678 13.3446 10.3685 13.4776L10.9246 13.7235C11.6479 14.0433 12.2274 14.6279 12.5491 15.3621L12.7297 15.7739C12.8618 16.0754 13.2811 16.0754 13.4133 15.7739Z" />
  </svg>
)
