import { css, cx, useTheme } from '@fable/theme'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FlexBox,
  Logo,
  TransitionElement,
  Text,
} from '@fable/components'
import { Subscription } from '@fable/types'
import SubscriptionItem from '../subscription_list_item/SubscriptionListItem'
import xicon from '../../../../assets/icons/standard/x-red-icon.svg'
import { Response } from '../../../../types'
import { ModalTypes, BillingTypes } from '../../../../state/action-types'
import ContainerRounded from '../../../../components/container_rounded/ContainerRounded'

const SubscriptionCancellationModal = () => {
  const { colors, fonts, mediaQueries } = useTheme()
  // Using useActions and useTypedSelector here resulted in what seemed to be a circular dependency
  const dispatch = useDispatch()
  const { subscriptionToCancel, subscriptionCancellation, subscriptions } =
    useSelector(
      (state: {
        billing: {
          subscriptionToCancel: Subscription
          subscriptionCancellation: Response
          subscriptions: Response<Subscription[]>
        }
      }) => state.billing
    )

  const isSingleSubscription = subscriptionToCancel.plan?.type === 'a_la_carte'

  const subHeader = isSingleSubscription
    ? `Are you sure? If you cancel your subscription, you’ll lose access to ${subscriptionToCancel.plan?.club?.title}`
    : 'When you cancel your subscription, you will leave your premium clubs'

  const lossesTitle = isSingleSubscription
    ? 'Cancelling means missing out on:'
    : 'If you cancel you will lose:'

  const losses = isSingleSubscription
    ? [
        'Premium club chat messages',
        'Premium club milestones',
        'Premium club resources',
      ]
    : [
        'Access to Premium Reading Clubs',
        'Premium book club chat messages',
        'All Premium book club milestones',
        'All Premium book club-related resources',
      ]

  const mobileMaxWidth = css`
    max-width: calc(100% - 37px);
  `
  const styles = {
    fullWidth: css`
      width: 100%;
    `,
    container: css`
      padding: 57px 20px 30px 20px;
      height: calc(100vh - 90px);
      width: 100vw;
      max-width: 520px;
      max-height: 735px;
      overflow: auto;

      ${mediaQueries.mobileL} {
        padding: 57px 60px 30px 60px;
      }
    `,
    loader: css`
      height: 100%;
      width: 100%;
    `,
    headerContainer: css`
      max-width: 300px;
      margin: 0 auto;
      text-align: center;
    `,
    headerDetail: css`
      margin-top: 24px;
      text-align: center;
      width: 100%;
      ${mediaQueries.mobileL} {
        max-width: 300px;
      }
    `,
    info: css`
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      letter-spacing: 0.02em;
      font-family: ${fonts.body};

      ${mediaQueries.mobileXS} {
        font-size: 15px;
      }
    `,
    planItemContainer: css`
      margin: 26px 0px 23px 0px;
    `,
    planItem: css`
      padding: 12px;
      border-radius: 4px;
    `,
    persuasion: css`
      margin-bottom: 42px;
      width: 100%;
      ${mobileMaxWidth};
      > span {
        padding-left: 5px;
      }

      ${mediaQueries.mobileL} {
        max-width: 100%;
      }
      ul {
        margin: 10px 0 0 -5px;
        li + li {
          margin-top: 11px;
        }
        img {
          margin-right: 10px;
          height: 18px;
          width: 18px;
        }
      }
    `,
    buttonWrapper: css`
      > button {
        font-size: 13px;

        ${mediaQueries.mobileXS} {
          font-size: 16px;
        }
      }
      flex: 1 0 auto;
      width: 100%;
      ${mobileMaxWidth};

      ${mediaQueries.mobileL} {
        max-width: 400px;
      }
    `,
    support: css`
      width: 100%;
      text-align: center;
      a {
        font-size: 14px;
        color: ${colors.hurricane};
        &:after {
          display: none;
        }
      }
    `,
  }

  if (subscriptions.loading || subscriptionCancellation.loading) {
    return (
      <div className={styles.container}>
        <FlexBox centerAll className={styles.loader}>
          <TransitionElement transitionType="fadeIn">
            <Logo animate isIcon />
          </TransitionElement>
        </FlexBox>
      </div>
    )
  }

  return (
    <TransitionElement transitionType="fadeIn">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        className={styles.container}
      >
        <FlexBox
          flexDirection="column"
          alignItems="center"
          className={styles.headerContainer}
        >
          <Text type="header" sizing="S" text="Cancel subscription?" />
          <Text
            className={styles.headerDetail}
            data-testid="subheader"
            type="body"
            sizing="M"
            text={subHeader}
          />
        </FlexBox>
        <div className={cx(styles.planItemContainer, styles.fullWidth)}>
          {subscriptionToCancel && (
            <ContainerRounded className={styles.planItem}>
              <SubscriptionItem subscription={subscriptionToCancel} />
            </ContainerRounded>
          )}
        </div>
        <div className={styles.persuasion}>
          <span className={styles.info} data-testid="lossesTitle">
            {lossesTitle}
          </span>
          <ul>
            {losses.map((x: string, i: number) => (
              <li key={i}>
                <FlexBox>
                  <img src={xicon} alt="A red letter X" />
                  <span className={styles.info} data-testid={`loss-${i}`}>
                    {x}
                  </span>
                </FlexBox>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.support}>
          <a className={styles.info} href="/support" target="_blank">
            Have a question? Ask Help &amp; Support
          </a>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={css`
              margin: 16px 0px 10px 0px;
            `}
            width="100%"
            onClick={() =>
              dispatch({
                type: BillingTypes.SUBSCRIPTION_CANCELLATION__REQUEST,
              })
            }
            label="Cancel Subscription"
            destructive
          />
          <Button
            width="100%"
            onClick={() => dispatch({ type: ModalTypes.MODAL__CLOSE })}
            label="Continue my subscription"
            negative
          />
        </div>
      </FlexBox>
    </TransitionElement>
  )
}

export default SubscriptionCancellationModal
