import { css } from '@fable/theme'
import { Task } from '@redux-saga/core'
import { select, fork, take, put, cancel } from '@redux-saga/core/effects'
import { get, remove } from '@fable/api'
import { BillingTypes, ModalTypes } from '../state/action-types'
import SubscriptionCancellationModal from '../app/store/components/subscription_cancellation_modal/SubscriptionCancellationModal'
import { getSubscriptionToCancel } from './selectors/billingSelectors'

const {
  SUBSCRIPTION_CANCELLATION,
  SUBSCRIPTION_CANCELLATION__REQUEST,
  SUBSCRIPTION_CANCELLATION__SUCCESS,
  SUBSCRIPTION_CANCELLATION__ERROR,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_ERROR,
} = BillingTypes
const { MODAL__OPEN, MODAL__CLOSE } = ModalTypes

function* cancelSubscription(): Generator<any, any, any> {
  const subscriptionToCancel = yield select(getSubscriptionToCancel)

  while (true) {
    // open modal
    yield put({
      type: MODAL__OPEN,
      payload: {
        content: <SubscriptionCancellationModal />,
        modalType: css`
          &.default > div.modal {
            padding-top: 0 !important;
          }
        `,
      },
    })

    // Wait for request to cancel plan (as of 8/2021 it's clicking cancel plan button)
    yield take(SUBSCRIPTION_CANCELLATION__REQUEST)

    try {
      // DELETE plan
      yield remove(`/billing/subscriptions/${subscriptionToCancel.id}`)
      yield put({ type: SUBSCRIPTION_CANCELLATION__SUCCESS })
    } catch (error) {
      yield put({
        type: SUBSCRIPTION_CANCELLATION__ERROR,
        payload: error,
      })
    }

    // Refresh plans data
    yield put({ type: GET_SUBSCRIPTIONS_REQUEST })

    try {
      const { data } = yield get('/billing/subscriptions')

      yield put({
        type: GET_SUBSCRIPTIONS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      yield put({
        type: GET_SUBSCRIPTIONS_ERROR,
        payload: error,
      })
    }

    yield put({ type: MODAL__CLOSE })
  }
}

export default function* cancelSubscriptionSaga(): Generator<any> {
  while (yield take(SUBSCRIPTION_CANCELLATION)) {
    const cancelSubscriptionTask = yield fork(cancelSubscription)
    yield take(MODAL__CLOSE)
    // Cancel saga when modal is closed
    yield cancel(cancelSubscriptionTask as Task)
  }
}
