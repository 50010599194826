import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Text } from '@fable/components'
import ChevronIcon from '../../assets/icons/components/ChevronIcon'

const ContainerRounded: React.VFC<
  {
    children: React.ReactNode | React.ReactNode[]
    title?: string
    collapsible?: boolean
    animateHover?: boolean
    collapsed?: boolean
    onClickExpand?: () => void
    headerComponent?: React.ReactNode | React.ReactNode[]
  } & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  title,
  collapsible,
  animateHover,
  collapsed,
  onClickExpand,
  headerComponent,
  ...nativeProps
}) => {
  const { colors, motion } = useTheme()

  const styles = {
    container: css`
      padding: 20px;
      border: 1px solid ${colors.whiteSmoke};
      border-radius: 12px;
      background: ${colors.whiteFang};
      &.collapsed {
        background: ${colors.almostWhite};
      }
      &.hoverable {
        ${motion.transition('border-color')};
        &:hover {
          border-color: ${colors.greyMedium};
        }
      }
    `,
    header: css`
      cursor: ${collapsible ? 'pointer' : 'default'};
    `,
    chevron: css`
      ${collapsed && 'transform: rotate(180deg)'}
    `,
    children: css`
      height: auto;
      ${collapsible && `max-height: ${collapsed ? '0px' : '600px'}`};
      ${collapsible && 'transition: max-height 0.3s'};
    `,
  }

  return (
    <div
      {...nativeProps}
      className={cx(
        styles.container,
        animateHover ? 'hoverable' : '',
        collapsed ? 'collapsed' : '',
        nativeProps.className
      )}
    >
      <FlexBox
        className={styles.header}
        justifyContent="space-between"
        alignItems="center"
        onClick={() => (onClickExpand ? onClickExpand() : null)}
      >
        {title ? (
          <Text
            type="subhead"
            sizing={{ base: 'XS', mobileL: 'S' }}
            text={title}
          />
        ) : (
          headerComponent
        )}
        {collapsible && (
          <div className={styles.chevron}>
            <ChevronIcon />
          </div>
        )}
      </FlexBox>
      <div className={cx('container-rounded-children', styles.children)}>
        {children}
      </div>
    </div>
  )
}

export default ContainerRounded
