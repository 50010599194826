import { FlexBox, Icon } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useDeviceDetect } from '@fable/hooks'
import { useSelector } from '@xstate/react'
import { useCart } from 'hooks'
import { CartQuantityIndicator } from './CartQuantityIndicator'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  secondary: boolean
}

export const CartIconButton = ({ className, secondary, ...props }: Props) => {
  const { motion, mediaQueries, colors } = useTheme()
  const { service, closeCart, open, openCart } = useCart()
  const cart = useSelector(service, (state) => state.context.cart)
  const qty = cart?.items?.length
  const showQty = !!qty && qty !== 0
  const { isMobile } = useDeviceDetect()

  return (
    <FlexBox
      className={cx(
        css`
          position: relative;
          width: 32px;
          height: 32px;
          cursor: pointer;
        `,
        className
      )}
      onClick={() => {
        if (!isMobile) return
        open ? closeCart() : openCart()
      }}
      centerAll
      {...props}
    >
      <Icon
        className={css`
          ${mediaQueries.mobileL} {
            ${motion.transition('opacity')};
            &:hover {
              opacity: 0.7;
            }
          }
        `}
        type="cart"
        fill={secondary ? colors.uiPearlExtraLight : colors.uiGraphite}
      />
      {showQty ? <CartQuantityIndicator inverted={secondary} /> : null}
    </FlexBox>
  )
}
