// This temporarily replaces react-router-dom's Redirect component removed from v5
// https://medium.com/@maoznir/redirect-component-for-react-router-v6-c3b4821ecce
import React from 'react'
import { Navigate, Params } from 'react-router-dom'
import { RelativeRoutingType } from 'react-router/dist/lib/context'
import { useParams } from 'react-router'

const updateTo = (to: string, params: Readonly<Params<string>>) => {
  const entries = Object.entries(params)
  let path = `${to}`

  entries.forEach(([key, value]) => {
    path = path.replace(`:${key}`, `${value}`)
  })

  return path
}

export interface RedirectProps {
  to: string
  state?: any
  relative?: RelativeRoutingType
}

/**
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue"
 */
export const Redirect: React.FC<RedirectProps> = ({ to, ...rest }) => {
  const params = useParams()
  return <Navigate replace to={updateTo(to, params)} {...rest} />
}
