import { Button, FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { routes } from 'app/store/routes'
import { useNavigate } from 'react-router-dom'

export const CartEmpty = ({ closeCart }: { closeCart: () => void }) => {
  const { colors } = useTheme()
  const navigate = useNavigate()

  return (
    <FlexBox
      data-testid="cartEmpty"
      className={css`
        height: 100%;
      `}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text tag="p" type="subhead" sizing="S" align="center">
        Your cart is empty
      </Text>
      <Button
        custom={{
          marginTop: '16px',
          padding: '0 20px',
          width: 'auto',
          height: '40px',
          backgroundColor: colors.uiGraphite,
          borderColor: colors.uiGraphite,
        }}
        onClick={() => {
          navigate(routes.explore)
          closeCart()
        }}
      >
        Shop books
      </Button>
    </FlexBox>
  )
}
