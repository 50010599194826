import { css, cx } from '@fable/theme'
import { useLocation } from 'react-router-dom'
import { Page } from '../../../components/Page'
import { FlexBox } from '@fable/components'
import useSlugQuery from '../../../hooks/useSlugQuery'
import { GET_GENRE_ID, GET_GENRE_LOGO } from '../../../queries'

const GenresLinkUnfurl = () => {
  const { pathname } = useLocation()
  const slug = pathname.split('/')[2]

  const { data, loading, error } = useSlugQuery(
    GET_GENRE_ID,
    slug,
    'genreCollection',
    GET_GENRE_LOGO
  )

  if (loading || error) return null

  const genre = data.genre

  const styles = {
    image: css`
      padding: 10px;
      border-radius: 70px 10px 70px 10px;
      width: 350px;
      height: 200px;
      background-color: ${genre.color};

      img {
        max-width: 70%;
        max-height: 70%;
      }
    `,
  }

  const title = `${genre?.name} Genre Logo`

  return (
    <Page head={{ title }}>
      <FlexBox className={cx(styles.image)} centerAll={true}>
        <img src={genre?.logo.url} alt={title} />
      </FlexBox>
    </Page>
  )
}

export default GenresLinkUnfurl
