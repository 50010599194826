import { IconSVGProps } from '../types'

export const PersonBadgeIcon: React.FC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.6667 2.0013H10V1.33464C10 0.967969 9.7 0.667969 9.33333 0.667969C8.96667 0.667969 8.66667 0.967969 8.66667 1.33464V2.0013H3.33333V1.33464C3.33333 0.967969 3.03333 0.667969 2.66667 0.667969C2.3 0.667969 2 0.967969 2 1.33464V2.0013H1.33333C0.593333 2.0013 0 2.6013 0 3.33464V12.668C0 13.4013 0.6 14.0013 1.33333 14.0013H10.6667C11.4 14.0013 12 13.4013 12 12.668V3.33464C12 2.6013 11.4 2.0013 10.6667 2.0013ZM6 4.0013C7.10667 4.0013 8 4.89464 8 6.0013C8 7.10797 7.10667 8.0013 6 8.0013C4.89333 8.0013 4 7.10797 4 6.0013C4 4.89464 4.89333 4.0013 6 4.0013ZM10 12.0013H2V11.3346C2 10.0013 4.66667 9.26797 6 9.26797C7.33333 9.26797 10 10.0013 10 11.3346V12.0013Z" />
  </svg>
)
