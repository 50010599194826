import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { css } from '@fable/theme'
import { Accordion, contentOptions } from 'components/accordion'
import { CMSFaq } from 'types'

export const PageDetailFAQ = ({ faqs }: { faqs: CMSFaq[] }) => (
  <div
    className={css`
      margin-top: 16px;
    `}
  >
    {faqs?.map((faq, i) => (
      <Accordion key={i} title={faq.title}>
        {faq.description?.json
          ? documentToReactComponents(faq.description.json, contentOptions)
          : ''}
      </Accordion>
    ))}
  </div>
)
