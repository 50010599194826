import { IconSVGProps } from '../types'

export const EditIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7444 1.91107C13.0698 1.58563 13.5975 1.58563 13.9229 1.91107L18.0896 6.07774C18.415 6.40317 18.415 6.93081 18.0896 7.25625L7.25625 18.0896C7.09997 18.2459 6.88801 18.3337 6.66699 18.3337H2.50033C2.04009 18.3337 1.66699 17.9606 1.66699 17.5003V13.3337C1.66699 13.1126 1.75479 12.9007 1.91107 12.7444L12.7444 1.91107ZM3.33366 13.6788V16.667H6.32181L16.3218 6.66699L13.3337 3.67884L3.33366 13.6788Z"
    />
  </svg>
)
