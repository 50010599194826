import { css, cx, useTheme } from '@fable/theme'
import Button from '../Button'
import FlexBox from '../FlexBox'
import Icon from '../icon/Icon'
import { SocialLink } from './types'
import { ButtonProps } from '../types'

interface Props {
  className?: string
  links: SocialLink[]
  backgroundColor?: string
  color?: string
  borderRadius?: string
  size?: string
  spacing?: string
}

export const SocialLinks = ({
  className = '',
  links,
  backgroundColor,
  color,
  borderRadius,
  size,
  spacing,
}: Props) => {
  const { colors, motion } = useTheme()
  if (!links || !links?.length) return null

  const bgColor = backgroundColor || colors.uiSilverGrey
  const iconColor = color || colors.uiGraphite

  const sharedProps: ButtonProps = {
    width: size || '32px',
    height: size || '32px',
    custom: {
      backgroundColor: bgColor,
      borderColor: bgColor,
      borderRadius: borderRadius || '50%',
      '&:hover': {
        backgroundColor: bgColor,
        borderColor: bgColor,
      },
    },
  }

  return (
    <FlexBox
      data-testid="social-links"
      className={cx(
        css`
          &:hover a {
            opacity: 0.7;
          }
        `,
        className
      )}
      flexWrap="wrap"
      gap={spacing || '4px'}
      centerAll
    >
      {links.map((linkItem, i) => (
        <a
          className={css`
            ${motion.transition('opacity')};
            &:hover {
              opacity: 1 !important;
            }
          `}
          key={i}
          href={linkItem.url}
          target="_blank"
          rel="noreferrer"
          data-testid={`${linkItem.id}Link`}
        >
          <Button {...sharedProps}>
            <Icon type={linkItem.id} fill={iconColor} />
          </Button>
        </a>
      ))}
    </FlexBox>
  )
}
