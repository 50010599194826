export interface PatternSVG {
  customClass?: string
  color: string
}

export enum PatternType {
  curve = 'curve',
  poly = 'poly',
  stripes = 'stripes',
  wave = 'wave',
}

export interface PatternProps {
  color: string
  containerClass?: string
  patternClass?: string
  type: PatternType
}
