import { ClubPatternMap } from '../types'

import laserBlue from '../../../assets/images/graphics/patterns/premium/laser-blue.svg'
import laserGreen from '../../../assets/images/graphics/patterns/premium/laser-green.svg'
import laserPink from '../../../assets/images/graphics/patterns/premium/laser-pink.svg'
import laserYellow from '../../../assets/images/graphics/patterns/premium/laser-yellow.svg'

import loopBlue from '../../../assets/images/graphics/patterns/premium/loop-blue.svg'
import loopGreen from '../../../assets/images/graphics/patterns/premium/loop-green.svg'
import loopPink from '../../../assets/images/graphics/patterns/premium/loop-pink.svg'
import loopYellow from '../../../assets/images/graphics/patterns/premium/loop-yellow.svg'

import rippleBlue from '../../../assets/images/graphics/patterns/premium/ripple-blue.svg'
import rippleGreen from '../../../assets/images/graphics/patterns/premium/ripple-green.svg'
import ripplePink from '../../../assets/images/graphics/patterns/premium/ripple-pink.svg'
import rippleYellow from '../../../assets/images/graphics/patterns/premium/ripple-yellow.svg'

import stripesBlue from '../../../assets/images/graphics/patterns/premium/stripes-blue.svg'
import stripesGreen from '../../../assets/images/graphics/patterns/premium/stripes-green.svg'
import stripesPink from '../../../assets/images/graphics/patterns/premium/stripes-pink.svg'
import stripesYellow from '../../../assets/images/graphics/patterns/premium/stripes-yellow.svg'

export const clubPatterns: ClubPatternMap = {
  laser: {
    blue: laserBlue,
    green: laserGreen,
    pink: laserPink,
    yellow: laserYellow,
  },
  loop: {
    blue: loopBlue,
    green: loopGreen,
    pink: loopPink,
    yellow: loopYellow,
  },
  ripple: {
    blue: rippleBlue,
    green: rippleGreen,
    pink: ripplePink,
    yellow: rippleYellow,
  },
  stripes: {
    blue: stripesBlue,
    green: stripesGreen,
    pink: stripesPink,
    yellow: stripesYellow,
  },
}
