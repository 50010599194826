import { css, cx, theme } from '@fable/theme'

export const SpinnerIcon = ({
  color = theme.colors.uiPearlExtraLight,
  className,
  size = '60px',
}: {
  color?: string
  className?: string
  size?: string
}) => (
  <svg
    className={cx(
      css`
        width: ${size};
      `,
      className
    )}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    fill="none"
    color={color}
  >
    <defs>
      <linearGradient id="spinner1">
        <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
        <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
      </linearGradient>
      <linearGradient id="spinner2">
        <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
        <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
      </linearGradient>
    </defs>

    <g strokeWidth="16" transform="translate(5, 0)">
      <path stroke="url(#spinner1)" d="M 4 100 A 86 86 0 0 1 186 100" />
      <path stroke="url(#spinner2)" d="M 186 100 A 86 86 0 0 1 4 100" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M 4 100 A 96 96 0 0 1 4 98"
      />
    </g>
  </svg>
)
