import { pageAnchors } from 'components/page_detail'
import { ClubDetailSection } from '../types'
import { BookType } from '@fable/types'

export const clubAnchors = [
  pageAnchors.about,
  pageAnchors.clubPicks,
  pageAnchors.hiw,
  pageAnchors.faq,
]

export const clubAnchorsLegacy = [
  pageAnchors.about,
  pageAnchors.books,
  pageAnchors.hiw,
  pageAnchors.faq,
]

export const clubSections = (type: BookType = 'book'): ClubDetailSection[] => {
  const isTvShow = type === 'tv_show'

  return [
    {
      id: pageAnchors.about.id,
    },
    {
      id: pageAnchors.clubPicks.id,
      sectionProps: {
        title: 'Club picks',
      },
    },
    {
      id: pageAnchors.hiw.id,
      sectionProps: {
        title: `How ${isTvShow ? 'TV show' : 'book'} clubs work on Fable`,
      },
    },
    {
      id: pageAnchors.faq.id,
      sectionProps: {
        title: 'Frequently Asked Questions',
      },
    },
  ]
}

export const clubSectionsLegacy: ClubDetailSection[] = [
  {
    id: pageAnchors.about.id,
  },
  {
    id: pageAnchors.books.id,
    sectionProps: {
      title: 'Book club picks',
    },
  },
  {
    id: pageAnchors.hiw.id,
    sectionProps: {
      title: 'How book clubs work on Fable',
    },
  },
  {
    id: pageAnchors.faq.id,
    sectionProps: {
      title: 'Frequently Asked Questions',
    },
  },
]
