import GiftCertificate from 'app/store/components/gift_certificate/GiftCertificate'
import InviteReferralPage from 'app/store/pages/account_settings/invite_referral_page/InviteReferralPage'
import { routes } from 'app/store/routes'
import { useUrlQuery } from 'hooks'
import { SearchContextProvider } from 'state'
import { lazyWithRetry } from 'utils'
import { pageMetadata } from '../pageMetadata'
import { AppRouteProps } from '../types'
import { isWebView } from '@fable/utils'

const BasicInfo = lazyWithRetry(
  () => import('app/store/pages/account_settings/basic_info/BasicInfo')
)
const Bookstore = lazyWithRetry(
  () => import('app/store/pages/bookstore/Bookstore')
)
const Checkout = lazyWithRetry(
  () => import('app/store/pages/checkout/Checkout')
)
const Genres = lazyWithRetry(() => import('app/store/pages/genres/Genres'))
const GenresDetail = lazyWithRetry(
  () => import('app/store/pages/genres_detail/GenresDetail')
)
const BookDetailPage = lazyWithRetry(
  () => import('app/store/pages/book_detail/BookDetailPage')
)
const CheckoutSuccess = lazyWithRetry(
  () => import('app/store/pages/checkout/checkout_success/CheckoutSuccess')
)
const Bookshelf = lazyWithRetry(
  () => import('app/store/pages/bookshelf/Bookshelf')
)
const AuthorDetailPage = lazyWithRetry(
  () => import('app/store/pages/person_detail_page/AuthorDetailPage')
)
const WriterDetailPage = lazyWithRetry(
  () => import('app/store/pages/person_detail_page/WriterDetailPage')
)
const ProfileInternal = lazyWithRetry(
  () =>
    import(
      'app/store/pages/account_settings/basic_info/profile_internal/ProfileInternal'
    )
)
const FolioDetail = lazyWithRetry(
  () => import('app/store/pages/folio_detail/FolioDetail')
)
const Folios = lazyWithRetry(() => import('app/store/pages/folios/Folios'))

const Orders = lazyWithRetry(
  () => import('app/store/pages/account_settings/orders/Orders')
)
const GiftsRedeem = lazyWithRetry(
  () => import('app/store/pages/gifts/GiftsRedeem')
)
const GiftsPurchase = lazyWithRetry(
  () => import('app/store/pages/gifts/GiftsPurchase')
)

const YourClubs = lazyWithRetry(
  () => import('app/store/pages/your_clubs/YourClubs')
)

export const useStoreRoutes = (): AppRouteProps[] => {
  const queryParams = useUrlQuery()

  return [
    {
      path: `${routes.folio}/:slug`,
      element: <FolioDetail />,

      footer: { hidden: true },
    },
    {
      path: `${routes.yourClubs}`,
      element: <YourClubs />,
      header: {
        fillSpace: false,
      },
    },
    {
      path: routes.folios,
      element: <Folios />,
      head: pageMetadata.folios,
    },
    {
      path: routes.explore,
      element: (
        <SearchContextProvider allowFilters={false}>
          <Bookstore />
        </SearchContextProvider>
      ),
      head: pageMetadata.explore,
      header: {
        fillSpace: false,
      },
    },
    {
      path: `${routes.book}/:id`,
      element: <BookDetailPage />,
      header: {
        hidden: isWebView,
      },
      footer: {
        hidden: isWebView,
      },
    },
    {
      path: `${routes.author}/:slug`,
      element: <AuthorDetailPage />,
      footer: { hidden: true },
    },
    {
      path: `${routes.writer}/:slug`,
      element: <WriterDetailPage />,
      footer: { hidden: true },
    },
    {
      path: routes.genres,
      element: <Genres />,
      head: pageMetadata.genres,
    },
    {
      path: `${routes.genres}/:slug`,
      element: <GenresDetail />,
      footer: { hidden: true },
    },
    {
      path: routes.redeem,
      element: <GiftsRedeem />,
      head: pageMetadata.giftsRedeem,
    },
    {
      path: routes.giveGift,
      element: <GiftsPurchase />,
      head: pageMetadata.giftsPurchase,
    },
    {
      path: routes.giftPrint,
      element: <GiftCertificate recipientId={queryParams.get('id')} />,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
      head: pageMetadata.giftsCertificate,
    },
    {
      path: routes.bookshelf,
      element: <Bookshelf />,
      guarded: true,
      head: pageMetadata.bookshelf,
    },
    {
      path: routes.profile,
      element: <ProfileInternal />,
      guarded: true,
      head: pageMetadata.internalProfile,
    },
    {
      path: routes.accountSettings,
      element: <BasicInfo />,
      guarded: true,
      head: pageMetadata.accountSettings,
    },
    {
      path: routes.accountOrders,
      element: <Orders />,
      guarded: true,
      head: pageMetadata.orders,
    },
    {
      path: routes.inviteReferral,
      element: <InviteReferralPage />,
      guarded: true,
      head: pageMetadata.inviteReferral,
    },
    {
      path: `${routes.checkoutSuccess}/*`,
      element: <CheckoutSuccess />,
      guarded: true,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
      head: pageMetadata.checkoutSuccess,
    },
    {
      path: `${routes.checkout}/*`,
      element: <Checkout />,
      guarded: true,
      header: {
        hidden: true,
      },
      footer: { hidden: true },
      head: pageMetadata.checkout,
    },
  ]
}
