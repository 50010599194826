import isAbsoluteUrl from 'is-absolute-url'
import { cloudImageResize } from '@fable/utils'

const createImage = (url: string = '', size: number) => {
  const img = new Image()

  if (url && isAbsoluteUrl(url) && !url.startsWith('data:image')) {
    img.src = cloudImageResize(url, size)
  } else if (url) {
    img.src = url
  }

  return img
}

export default createImage
