import { TakeableChannel } from '@redux-saga/core'
import { call, put, takeLatest } from 'redux-saga/effects'
import { UserTypes } from '../state/action-types'
import { createProfileObject } from '../state/action-creators'
import { get } from '@fable/api'

export function* getUserData() {
  try {
    // Trailing slash required for Safari
    const { data } = yield call(get, '/settings/profile/')

    for (const key in data) {
      if (data[key] === 'undefined') data[key] = undefined
    }

    yield put({
      type: UserTypes.GET_USER_PROFILE__SUCCESS,
      payload: data,
    })

    if (data.capabilities?.inactivity_timeout) {
      yield put({
        type: UserTypes.INACTIVITY_TIMEOUT,
      })
    }
    yield put({
      type: UserTypes.EDIT_PROFILE,
      payload: createProfileObject(data),
    })
  } catch (error) {
    yield put({
      type: UserTypes.GET_USER_PROFILE__ERROR,
      payload: error,
    })
  }
}

export default function* getUserDataSaga() {
  yield takeLatest(
    UserTypes.GET_USER_PROFILE__REQUEST as unknown as TakeableChannel<any>,
    getUserData
  )
}
