import { CompChildren } from '@fable/types'
import Head, { HeadProps } from './head/Head'

interface Props extends React.HTMLProps<HTMLDivElement> {
  head: HeadProps | null
  children: CompChildren
}

export const Page = ({ children, head, ...nativeProps }: Props) => {
  return (
    <div {...nativeProps}>
      <Head {...head} />
      {children}
    </div>
  )
}
