import { css, cx, useTheme } from '@fable/theme'
import ShareButton from 'components/share_button/ShareButton'
import { Button, FlexBox } from '@fable/components'
import { useDeviceDetect } from '@fable/hooks'
import { useBranchView } from 'hooks'
import { PageAnchor, PageAnchorContainerWidth } from '../types'
import { PageDetailAnchorNavItem } from './PageDetailAnchorNavItem'
import { usePageAnchorLoad } from '../hooks'
import { PageDetailAnchorNavContainer } from './PageDetailAnchorNavContainer'
import { useNavigate } from 'react-router-dom'
import { routes } from 'app/store/routes'

interface Props {
  anchors: PageAnchor[]
  containerWidth?: PageAnchorContainerWidth
  shareOptions?: {
    url: string
    category?: string
  }
  giftBookId?: string
}

export const PageDetailAnchorNav = ({
  anchors,
  shareOptions,
  containerWidth,
  giftBookId,
}: Props) => {
  const navigate = useNavigate()
  const { colors, mediaQueries, nativeOverrides } = useTheme()
  const bannerInView = useBranchView()
  const { isMobile } = useDeviceDetect()
  const bannerTop = bannerInView ? '124px' : '60px'

  usePageAnchorLoad({ anchors })

  return (
    <div
      className={cx(
        css`
          width: 100%;
          position: sticky;
          top: ${bannerTop};
          z-index: 5;
        `,
        !isMobile &&
          css`
            ${mediaQueries.tabletL} {
              top: 0;
              padding-top: 60px;
            }
          `
      )}
    >
      <PageDetailAnchorNavContainer containerWidth={containerWidth}>
        <div
          className={css`
            position: relative;
          `}
        >
          <FlexBox
            className={css`
              border-bottom: 1px solid ${colors.whiteSmoke};
              background-color: ${colors.whiteFang};
              overflow-x: auto;
              -ms-overflow-style: none;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }
            `}
            alignItems="center"
          >
            <ul
              className={css`
                position: relative;
                z-index: 2;
                display: flex;
                align-items: stretch;
                flex-shrink: 0;
                padding-right: 100px;
              `}
            >
              {anchors.map((anchor, i) => (
                <PageDetailAnchorNavItem
                  key={i}
                  item={anchor}
                  title={anchor.title}
                />
              ))}
            </ul>
          </FlexBox>
          <FlexBox
            className={css`
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 3;
            `}
            alignItems="center"
            gap="16px"
          >
            {!!giftBookId?.length ? (
              <Button
                className={css`
                  ${nativeOverrides.buttonClear};
                `}
                negative
                icon="gift"
                onClick={() =>
                  navigate(`${routes.giveGift}?book=${giftBookId}`)
                }
              />
            ) : null}
            {!!shareOptions ? (
              <ShareButton
                className={css`
                  ${nativeOverrides.buttonClear};
                  svg {
                    fill: ${colors.blackSwan};
                  }
                `}
                category={shareOptions.category}
                url={shareOptions.url}
                hideModalTitle
              />
            ) : null}
          </FlexBox>
          <span
            className={css`
              display: block;
              width: 100px;
              height: 96%;
              background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1) 50%
              );
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              pointer-events: none;
              z-index: 2;
            `}
          />
        </div>
      </PageDetailAnchorNavContainer>
    </div>
  )
}
