import { Button } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { CartItem as CartItemType } from '@fable/types'
import { useSelector } from '@xstate/react'
import { FORM_TYPES } from 'app/auth/constants'
import { routes } from 'app/store/routes'
import { ScrollContainer } from 'components/ScrollContainer'
import { useActions, useCart, useTypedSelector } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { CartItem } from './CartItem'

interface Props {
  items: CartItemType[]
}

export const CartItems = ({ items }: Props) => {
  const navigate = useNavigate()
  const { colors } = useTheme()
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { openAuthModal } = useActions()
  const { service, closeCart } = useCart()
  const state = useSelector(service, (state) => state)
  const loading = !state.matches('idle')
  const total = state.context.total

  const goToCheckout = () => {
    closeCart()

    const checkoutUrl = `${routes.checkout}?p=cart`
    if (!isAuthenticated) {
      return openAuthModal(FORM_TYPES.SIGN_IN, checkoutUrl)
    }

    return navigate(checkoutUrl)
  }

  return (
    <div>
      <ScrollContainer
        overflows={items.length > 3}
        triggerScroll={state.context.success}
      >
        {items.map((item) => (
          <CartItem key={item.product.id} item={item} />
        ))}
      </ScrollContainer>
      <div
        className={css`
          padding: 0 24px;
        `}
      >
        <Button
          dataTestId="cartCheckoutButton"
          width="100%"
          disabled={total === '0.00'}
          onClick={goToCheckout}
          loading={loading}
          custom={{
            backgroundColor: colors.uiGraphite,
            borderColor: colors.uiGraphite,
          }}
        >
          Checkout ${total}
        </Button>
      </div>
    </div>
  )
}
