import { IconSVGProps } from '../types'

export const EyeOffIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M17.8817 19.2971C16.1229 20.4126 14.0824 21.0034 11.9997 21.0001C6.60766 21.0001 2.12166 17.1201 1.18066 12.0001C1.61069 9.67078 2.78229 7.54296 4.52066 5.93407L1.39166 2.80807L2.80666 1.39307L22.6057 21.1931L21.1907 22.6071L17.8807 19.2971H17.8817ZM5.93466 7.35007C4.57567 8.58566 3.62898 10.2088 3.22266 12.0001C3.53496 13.3665 4.16193 14.6412 5.05367 15.7227C5.94541 16.8041 7.07729 17.6625 8.35922 18.2294C9.64114 18.7963 11.0377 19.0561 12.4378 18.9882C13.8378 18.9203 15.2026 18.5264 16.4237 17.8381L14.3957 15.8101C13.5324 16.3539 12.5099 16.5882 11.4959 16.4745C10.4819 16.3608 9.53671 15.906 8.81523 15.1845C8.09376 14.463 7.63889 13.5178 7.52523 12.5038C7.41156 11.4899 7.64585 10.4674 8.18966 9.60407L5.93466 7.35007ZM12.9137 14.3281L9.67166 11.0861C9.49373 11.539 9.45185 12.034 9.55117 12.5104C9.65049 12.9868 9.88668 13.4238 10.2308 13.7679C10.5749 14.1121 11.0119 14.3482 11.4883 14.4476C11.9647 14.5469 12.4597 14.505 12.9127 14.3271L12.9137 14.3281ZM20.8067 16.5921L19.3757 15.1621C20.0441 14.2094 20.5201 13.1353 20.7767 12.0001C20.5049 10.8098 19.9939 9.68721 19.2748 8.70056C18.5557 7.71391 17.6435 6.88379 16.5935 6.26067C15.5436 5.63755 14.378 5.23443 13.1674 5.07583C11.9568 4.91723 10.7267 5.00645 9.55166 5.33807L7.97366 3.76007C9.22066 3.27007 10.5797 3.00007 11.9997 3.00007C17.3917 3.00007 21.8777 6.88007 22.8187 12.0001C22.5122 13.6658 21.8235 15.2376 20.8067 16.5921ZM11.7227 7.50807C12.3592 7.46873 12.9968 7.56513 13.5932 7.79088C14.1896 8.01663 14.7313 8.36658 15.1822 8.81752C15.6332 9.26846 15.9831 9.81009 16.2088 10.4065C16.4346 11.003 16.531 11.6406 16.4917 12.2771L11.7217 7.50807H11.7227Z" />
  </svg>
)
