/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Response } from '../../types'
import { FolioActions } from '../actions'
import { FolioTypes } from '../action-types'
import {
  setClearState,
  setErrorState,
  setRequestState,
  setSuccessState,
} from '../stateHandlers'

enum FolioKeys {
  folioBooks = 'folioBooks',
  folioDetails = 'folioDetails',
  folios = 'folios',
}

interface FolioState {
  [FolioKeys.folioBooks]: Response
  [FolioKeys.folioDetails]: Response
  [FolioKeys.folios]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: FolioState = {
  [FolioKeys.folioBooks]: props,
  [FolioKeys.folioDetails]: props,
  [FolioKeys.folios]: props,
}

const reducer = (
  state: FolioState = initialState,
  action: FolioActions
): FolioState => {
  const stateProps = { state, props }

  switch (action.type) {
    // GET FOLIOS
    case FolioTypes.GET_FOLIOS__REQUEST:
      return setRequestState(stateProps, FolioKeys.folios)
    case FolioTypes.GET_FOLIOS__SUCCESS:
      return setSuccessState(stateProps, FolioKeys.folios, {
        [action.payload.includeBooks ? 'foliosListIncludeBooks' : 'foliosList']:
          action.payload.folios,
      })
    case FolioTypes.GET_FOLIOS__ERROR:
      return setErrorState(stateProps, FolioKeys.folios, action.payload)

    // GET FOLIO DETAILS
    case FolioTypes.GET_FOLIO_DETAILS__REQUEST:
      return setRequestState(stateProps, FolioKeys.folioDetails)
    case FolioTypes.GET_FOLIO_DETAILS__SUCCESS:
      return setSuccessState(stateProps, FolioKeys.folioDetails, action.payload)
    case FolioTypes.GET_FOLIO_DETAILS__ERROR:
      return setErrorState(stateProps, FolioKeys.folioDetails, action.payload)
    case FolioTypes.GET_FOLIO_DETAILS__CLEAR:
      return setClearState(stateProps, FolioKeys.folioDetails)

    // GET FOLIO BOOKS
    case FolioTypes.GET_FOLIO_BOOKS__REQUEST:
      return setRequestState(stateProps, FolioKeys.folioBooks)
    case FolioTypes.GET_FOLIO_BOOKS__SUCCESS:
      return setSuccessState(stateProps, FolioKeys.folioBooks, action.payload)
    case FolioTypes.GET_FOLIO_BOOKS__ERROR:
      return setErrorState(stateProps, FolioKeys.folioBooks, action.payload)

    default:
      return state
  }
}

export default reducer
