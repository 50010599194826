import { openDeeplink } from 'services/singular'
import { events } from './events'
import { AnalytEventAppDownloadProps } from './types'

const appDownloadDeeplink = ({
  linkOnly = false,
  ...props
}: { linkOnly?: boolean } & AnalytEventAppDownloadProps = {}) => {
  if (!linkOnly) events.appDownload({ ...props })
  openDeeplink('app')
}

export const actions = {
  appDownloadDeeplink,
}
