import { useEffect, useRef, useCallback, RefObject } from 'react'

export const useResizeObserver = (
  callback: (rect: DOMRectReadOnly) => void,
  { enabled = true }: { enabled?: boolean } = {}
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null)

  const observerResize = useCallback(() => {
    const element = ref.current
    if (!element || !enabled) return

    const observer = new ResizeObserver((entries) => {
      if (entries[0]) callback(entries[0].contentRect)
    })

    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  }, [callback, enabled])

  useEffect(() => {
    const cleanup = observerResize()
    return () => {
      if (cleanup) cleanup()
    }
  }, [observerResize])

  return ref
}
