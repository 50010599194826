import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { css, cx, useTheme, FableTheme } from '@fable/theme'
import { Button, Text, TextInput } from '@fable/components'
import { CompanyInvite, SignUpFormInput } from '@fable/types'
import { routes as marketingRoutes } from '../../../marketing/routes'
import { SIGNUP_BUTTON_TEXT } from '../../../../constants'
import FormErrorMessage from '../../../../components/form_error_message/FormErrorMessage'
import { useSignup } from 'hooks'
import { PasswordField } from 'components/field/PasswordField'
import Checkbox from 'components/checkbox/Checkbox'

export const SignUp: React.VFC<{
  hideHeader?: boolean
  onSubmit?: () => void
  submitTitle?: string
  invite?: CompanyInvite
}> = ({
  hideHeader = false,
  onSubmit,
  submitTitle = SIGNUP_BUTTON_TEXT,
  invite = null,
}) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const {
    errorMessage,
    inviteParams,
    errors,
    gdprCountry,
    handleSubmit,
    watch,
    setValue,
    clearServerErrors,
  } = useSignup({
    invite,
    onSubmit,
    defaultValues: { name: '', email: '', password: '', termsAgree: true },
  })

  const handleChange = (
    key: keyof SignUpFormInput,
    value: string | boolean
  ) => {
    clearServerErrors()
    setValue(key, value)
  }

  const name = watch('name')
  const email = watch('email')
  const password = watch('password')
  const termsAgree = watch('termsAgree')
  const emailOptIn = watch('emailOptIn')

  const renderDefaultHeader = () => {
    const title = 'Create your account'

    return (
      <div
        className={css`
          margin-bottom: 24px;
          text-align: center;
        `}
      >
        <Text
          className={css`
            margin-bottom: 16px;
          `}
          type="header"
          sizing="XS"
          text={title}
        />
      </div>
    )
  }

  const renderCheckboxTermsContent = () => {
    if (inviteParams?.termsAgreementText) {
      return (
        <ReactMarkdown
          className={cx(
            css`
              p,
              a {
                font-size: 12px;
                line-height: 16px;
              }
            `,
            styles.checkboxLabel
          )}
          children={inviteParams?.termsAgreementText}
          linkTarget="_blank"
        />
      )
    }

    const linkProps = {
      className: css`
        color: ${theme.colors.denim};
      `,
      target: '_blank',
    }

    return (
      <Text
        type="body"
        sizing="S"
        className={styles.checkboxLabel}
        color={theme.colors.graphite}
      >
        By signing up, you accept Fable’s&nbsp;
        <Link to={marketingRoutes.terms} {...linkProps}>
          Terms of Use
        </Link>
        &nbsp;and acknowledge its&nbsp;
        <Link to={marketingRoutes.privacy} {...linkProps}>
          Privacy Policy
        </Link>
        &nbsp;applies.
      </Text>
    )
  }

  const renderForm = () => {
    const emailLabel = inviteParams?.emailPattern
      ? `Enter your ${invite?.company?.name} email`
      : 'Email Address'

    return (
      <form onSubmit={handleSubmit} className="signup-form">
        {!!errorMessage && <FormErrorMessage message={errorMessage} />}
        <TextInput
          containerClass={css`
            margin-bottom: 12px;
          `}
          error={!!errors.name}
          type="username"
          placeholder="Display Name"
          value={name}
          onChangeText={(text: string) => handleChange('name', text)}
          autoComplete="name"
        />
        <TextInput
          containerClass={css`
            margin-bottom: 12px;
          `}
          error={!!errors.email}
          type="email"
          placeholder={emailLabel}
          value={email}
          onChangeText={(text: string) => handleChange('email', text)}
          autoComplete="email"
        />
        <PasswordField
          containerClass={css`
            margin-bottom: 12px;
          `}
          id="password"
          placeholder="Password"
          value={password}
          onChangeText={(text: string) => handleChange('password', text)}
          error={!!errors.password}
          autoComplete="new-password"
        />
        <div
          className={css`
            padding: 0 16px 20px;
          `}
        >
          <Checkbox
            onChange={() => handleChange('termsAgree', !termsAgree)}
            isChecked={termsAgree}
            label={renderCheckboxTermsContent()}
          />
        </div>
        {gdprCountry ? (
          <div
            className={css`
              padding: 0 16px 20px;
            `}
          >
            <Checkbox
              onChange={() => handleChange('emailOptIn', !emailOptIn)}
              isChecked={emailOptIn}
              label={
                <Text
                  className={css`
                    max-width: 256px;
                  `}
                  type="body"
                  sizing="S"
                  color={theme.colors.graphite}
                >
                  I want to receive exclusive offers, feature updates, and more
                  from Fable
                </Text>
              }
            />
          </div>
        ) : null}

        <Button
          width="100%"
          type="submit"
          label={submitTitle}
          dataTestId="auth-provider-email-button"
        />
      </form>
    )
  }

  const renderHeader = () => {
    if (hideHeader) return null

    return renderDefaultHeader()
  }

  return (
    <div>
      {renderHeader()}
      {renderForm()}
    </div>
  )
}

const getStyles = ({ colors }: FableTheme) => ({
  checkboxLabel: css`
    display: block;
    width: 100%;
    color: ${colors.blackSwan};

    a {
      color: ${colors.denim};
    }
  `,
})
