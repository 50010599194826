import { Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { AppearInView } from '../motion'
import { HomeFeaturesCta } from './HomeFeaturesCta'
import { HomeFeaturesLink as HFLinkType } from '../../lib/types'
import { HomeFeaturesLink } from './HomeFeatureLink'
import { CompChildren } from '@fable/types'

export interface Props {
  className?: string
  label: string
  labelColor?: string
  title: string
  titleStyles?: string
  description: string
  link?: HFLinkType
  button?: CompChildren
}

export const HomeFeaturesHeader = ({
  className = '',
  label,
  labelColor,
  title,
  titleStyles = '',
  description,
  link,
  button,
}: Props) => {
  const { colors, mediaQueries } = useTheme()
  const amount = 0.5
  const labelColorValue = labelColor || colors.uiGraphite

  const getCta = () => {
    if (button) return button
    return !!link ? <HomeFeaturesLink {...link} /> : <HomeFeaturesCta />
  }

  return (
    <div
      className={cx(
        css`
          position: relative;
          z-index: 1;
          padding-top: 48px;
          ${mediaQueries.tablet} {
            padding-top: 100px;
          }
        `,
        className
      )}
    >
      <AppearInView amount={amount}>
        <Text
          className={css`
            font-size: 50px;
            ${mediaQueries.tablet} {
              font-size: 70px;
            }
          `}
          type="note"
          sizing="M"
          color={labelColorValue}
        >
          {label}
        </Text>
      </AppearInView>
      <AppearInView amount={amount}>
        <Text
          className={cx(
            css`
              margin: 16px 0;
              width: 100%;
              max-width: 297px;
              ${mediaQueries.tablet} {
                margin: 28px 0 17px;
                max-width: 540px;
              }
            `,
            titleStyles
          )}
          tag="h2"
          type="header"
          custom={{
            fontSize: '46px',
            lineHeight: '40px',
            [mediaQueries.tablet]: {
              fontSize: '72px',
              lineHeight: '68px',
            },
            [mediaQueries.desktop]: {
              fontSize: '80px',
              lineHeight: '75px',
            },
          }}
          weight="500"
          color={colors.uiGraphite}
        >
          {title}
        </Text>
      </AppearInView>
      <AppearInView amount={amount}>
        <Text
          className={css`
            width: 100%;
            max-width: 355px;
            ${mediaQueries.tablet} {
              max-width: 500px;
            }
          `}
          tag="h2"
          type="body"
          custom={{
            fontSize: '18px',
            lineHeight: '24px',
            [mediaQueries.tablet]: {
              fontSize: '24px',
              lineHeight: '34px',
            },
          }}
          weight="500"
          color={colors.uiGraphite}
        >
          {description}
        </Text>
      </AppearInView>
      <AppearInView amount={amount}>{getCta()}</AppearInView>
    </div>
  )
}
