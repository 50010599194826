import { ENVIRONMENT } from '../../constants'
import { PlanSelectionOption } from './types'

export const premiumFreeTrialId = 'premium-free-trial'

const premiumBenefits = [
  'Join book clubs moderated by authors, celebrities, and influencers',
  'Access curated resources designed for interactive reading',
  'Attend exclusive live events hosted by authors and celebrities',
]

const singleClubBenefits = [
  'Join this club',
  'Access curated resources designed for interactive reading',
  'Attend exclusive live events hosted by authors and celebrities',
]

const freeTrialPlan: PlanSelectionOption = {
  id: premiumFreeTrialId,
  tag: 'All premium clubs',
  title: '14 day free trial',
  description: 'No credit card required',
  benefits: premiumBenefits,
}

const premiumPlan: PlanSelectionOption = {
  id:
    ENVIRONMENT === 'production'
      ? 'price_1Im41kECzSVzFPPcnubpbt2j'
      : 'price_1Ij2PJECzSVzFPPcN3un9f7J',
  tag: 'All premium clubs',
  title: 'Annual subscription',
  description: '$5.83/month billed annually at $69.99/year',
  benefits: premiumBenefits,
}

const singleClubPlan: PlanSelectionOption = {
  tag: 'Single club',
  title: 'Monthly subscription',
  benefits: singleClubBenefits,
}

export const allPlanOptions = {
  freeTrialPlan,
  premiumPlan,
  singleClubPlan,
}
