import { FlexBox, Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { ReferralUser } from '@fable/types'
import { useRef, useState } from 'react'
import { useClickOutside } from '@fable/hooks'
import { InviteUserCardButton } from './InviteUserCardButton'

interface Props {
  user: ReferralUser
  className?: string
}

export const InviteUserCard = ({ user, className = '' }: Props) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { mediaQueries, colors } = useTheme()
  const firstName = user.display_name?.split(' ')[0]

  useClickOutside(ref, () => open && setOpen(false))

  const userActivities = Object.entries(user.extra)
    .map(([key, value]) => {
      switch (key) {
        case 'clubs_joined':
          return value > 0
            ? `${value} club${value > 1 ? 's' : ''} joined`
            : null
        case 'reviews':
          return value > 0 ? `${value} review${value > 1 ? 's' : ''}` : null
        case 'days_streak':
          return value > 0 ? `${value} day streak` : null
        case 'posts':
          return value > 0 ? `${value} post${value > 1 ? 's' : ''}` : null
        default:
          return null
      }
    })
    .filter(Boolean) as string[]

  return (
    <FlexBox
      className={cx(
        css`
          padding: 12px;
          width: 100%;
          height: 100dvh;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 100;
          pointer-events: none;
          ${mediaQueries.mobileL} {
            padding-top: 36px;
          }
        `,
        className
      )}
      justifyContent="center"
    >
      <span
        className={css`
          width: 100%;
          height: 100%;
          background-color: ${colors.uiWhite70};
          opacity: ${open ? 1 : 0};
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity ease 0.3s;
          pointer-events: ${open ? 'auto' : 'none'};
        `}
      />
      <FlexBox
        ref={ref}
        className={css`
          padding: 16px;
          background-color: ${colors.uiGraphite};
          border-radius: 40px;
          width: 100%;
          max-height: ${open ? '500px' : '72px'};
          align-self: flex-start;
          box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.17);
          position: relative;
          z-index: 1;
          overflow: hidden;
          transition: max-height ease 0.3s;
          pointer-events: auto;
          ${mediaQueries.mobileL} {
            width: 368px;
          }
        `}
        flexDirection="column"
      >
        {/* Header */}
        <FlexBox
          className={css`
            width: 100%;
            gap: 8px;
            cursor: pointer;
          `}
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setOpen(!open)}
        >
          <FlexBox
            className={css`
              gap: 8px;
            `}
            alignItems="center"
          >
            <img
              className={css`
                width: 40px;
                height: 40px;
                background-color: ${colors.uiBlack};
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
              `}
              src={user.pic_resize || ''}
              alt={`${user.display_name} avatar`}
            />
            <FlexBox
              className={css`
                gap: 4px;
              `}
              flexDirection="column"
            >
              <Text type="label" sizing="L" weight="500" color={colors.uiWhite}>
                {user.display_name}
              </Text>
              <Text type="body" sizing="S" color={colors.uiPewterGreyLight}>
                Has gifted you a $5 ebook credit!
              </Text>
            </FlexBox>
          </FlexBox>
          <Text
            className={css`
              padding-right: 16px;
            `}
            type="label"
            sizing="M"
            weight="600"
            align="right"
            color={colors.uiWhite}
          >
            View {open ? '-' : '+'}
          </Text>
        </FlexBox>
        {/* Dropdown */}
        <FlexBox
          className={css`
            width: 100%;
            overflow: hidden;
            gap: 16px;
            padding: 4px;
            padding-top: 16px;
            margin-top: 16px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          `}
          flexDirection="column"
        >
          {/* Activity */}
          {!!userActivities.length ? (
            <FlexBox
              data-testid="referral-user-activity"
              className={css`
                gap: 8px;
              `}
              flexDirection="column"
            >
              <Text type="body" sizing="S" color={colors.uiPewterGrey}>
                About {firstName}'s activity
              </Text>
              <FlexBox
                className={css`
                  gap: 6px;
                `}
                alignItems="center"
                flexWrap="wrap"
              >
                {userActivities.map((item, i) => (
                  <FlexBox
                    key={i}
                    className={css`
                      padding: 0 12px;
                      height: 28px;
                      background-color: ${colors.uiWhite20};
                      border-radius: 20px;
                    `}
                    centerAll
                  >
                    <Text
                      type="label"
                      sizing="L"
                      align="center"
                      weight="500"
                      color={colors.uiWhite}
                    >
                      {item}
                    </Text>
                  </FlexBox>
                ))}
              </FlexBox>
            </FlexBox>
          ) : null}
          {/* Info */}
          <FlexBox
            className={css`
              gap: 6px;
            `}
            flexDirection="column"
          >
            <Text type="body" sizing="S" color={colors.uiPewterGrey}>
              About your credit
            </Text>
            <Text type="body" sizing="M" color={colors.uiWhite}>
              Sign up and download the app to claim your Fable credit, then use
              it towards any ebook in our catalog (available in US only).
            </Text>
          </FlexBox>
          {/* CTA */}
          <InviteUserCardButton
            className={css`
              margin-top: 0;
              width: 100%;
            `}
            negative
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
