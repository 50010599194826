import { BLOCKS, INLINES, NodeData } from '@contentful/rich-text-types'
import { Hyperlink, Text } from '@fable/components'
import { css, theme } from '@fable/theme'
import { ImageAsset } from 'components/cms'
import React from 'react'
import { hyperLinkTarget } from 'utils'

const spacing = css`
  margin-bottom: 16px;
`

export const contentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: NodeData, children: React.ReactNode) => (
      <Text
        className={spacing}
        type="body"
        sizing="M"
        color={theme.colors.graphite}
      >
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node: NodeData, children: React.ReactNode) => (
      <Hyperlink
        className={spacing}
        href={node.data.uri}
        target={hyperLinkTarget(node.data.uri)}
      >
        {children}
      </Hyperlink>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: NodeData, children: React.ReactNode) => {
      const id = node?.data?.target?.sys?.id
      return <ImageAsset className={spacing} id={id} />
    },
  },
}
