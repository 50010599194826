import { Book, Cart, CartItem } from '@fable/types'
import find from 'lodash/find'

export const findItemInCart = ({
  cart,
  id,
}: {
  cart: Cart | null
  id: string
}) => {
  if (!cart || !cart?.items?.length) return null
  return find(cart?.items, (item) => item.product.id === id)
}

export const getFilteredCartItems = ({
  items,
  ownedBooks,
}: {
  items: CartItem[] | undefined
  ownedBooks: Book[] | null
}) => {
  const cartItems = items
    ?.map((item: CartItem) => {
      const ownedBook = find(
        ownedBooks,
        (o) => o.id === item.product.id && o.status === 'owned'
      )
      return !!ownedBook ? null : item
    })
    .filter((o) => o !== null)

  return cartItems
}

export const prepItemsToMerge = ({ cart }: { cart: Cart | null }) => {
  if (!cart?.items.length) return []

  const items = cart?.items.map((item) => ({
    product_id: item.product.id,
    product_type: 'book',
    quantity: 1,
  }))

  return items
}
