import { Button, ButtonProps } from '@fable/components'
import { css, cx } from '@fable/theme'
import { FORM_TYPES } from 'app/auth/constants'
import { useActions, useTypedSelector } from 'hooks'
import { analytics } from 'segment_analytics'

export const InviteCta = ({ className = '', ...props }: ButtonProps) => {
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { openAuthModal } = useActions()

  const handleSignup = () => {
    if (isAuthenticated) {
      analytics.actions.appDownloadDeeplink()
      return
    }

    openAuthModal(FORM_TYPES.SIGN_UP)
  }

  return (
    <Button
      className={cx(
        css`
          margin-top: 33px;
          width: 160px;
          height: 52px;
        `,
        className
      )}
      onClick={handleSignup}
      {...props}
    >
      {isAuthenticated ? 'Get the app' : 'Sign up'}
    </Button>
  )
}
