import { Button, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import find from 'lodash/find'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../app/marketing/routes'
import { DEFAULT_ERROR_MESSAGE } from '../../constants'
import { useActions, useFreeTrial } from '../../hooks'
import { allPlanOptions } from './constants'
import { getPlanCheckoutLink, getPlans, isFreeTrial } from './helpers'
import { PlanSelectionItem } from './PlanSelectionItem'
import { PlanSelectionOption, PlanSelectionProps } from './types'

export const PlanSelection = ({
  club,
  hideLabel = false,
  successActions,
  ...nativeProps
}: PlanSelectionProps) => {
  const { activateFreeTrial, loading, error, freeTrialUsed } = useFreeTrial({
    club,
    successActions,
  })
  const plans = getPlans({
    club,
    freeTrialUsed,
  })
  const { colors, mediaQueries } = useTheme()
  const { closeModal } = useActions()
  const [selectedPlanId, setSelectedPlanId] = useState(plans[0].id)
  const buttonStyles = css`
    margin: 16px 0;
    width: 100%;
  `

  useEffect(() => {
    if (!loading && freeTrialUsed) {
      setSelectedPlanId(allPlanOptions.premiumPlan.id)
    }
  }, [freeTrialUsed, loading])

  const getCheckoutLink = () => {
    const plan = find(plans, { id: selectedPlanId })
    if (!plan) return ''

    return getPlanCheckoutLink(plan)
  }

  return (
    <div
      className={css`
        padding: 13px 16px 30px;
        width: 100%;
        overflow-y: scroll;
        height: 480px;
        ${mediaQueries.mobileXS} {
          overflow-y: unset;
          height: 100%;
        }
        ${mediaQueries.mobileL} {
          min-width: 470px;
        }
      `}
      data-testid="planSelection"
      {...nativeProps}
    >
      {hideLabel ? null : (
        <Text
          text="Choose your plan"
          type="subhead"
          sizing="S"
          tag="h3"
          color={colors.graphite}
        />
      )}

      <div
        className={css`
          margin: 16px 0;
        `}
      >
        {plans.map((plan: PlanSelectionOption, i: number) => (
          <PlanSelectionItem
            key={i}
            selected={selectedPlanId as string}
            onSelect={(id) => setSelectedPlanId(id)}
            plan={plan}
          />
        ))}
      </div>
      {isFreeTrial(selectedPlanId) ? (
        <Button
          className={buttonStyles}
          onClick={() => activateFreeTrial()}
          label="Continue"
          loading={loading}
        />
      ) : (
        <Link to={getCheckoutLink()} onClick={closeModal}>
          <Button className={buttonStyles} label="Continue to checkout" />
        </Link>
      )}
      {error ? (
        <Text
          className={css`
            margin: 0 auto 8px;
          `}
          type="label"
          sizing="L"
          text={DEFAULT_ERROR_MESSAGE}
          color={colors.error}
          align="center"
        />
      ) : null}
      <Link to={routes.terms}>
        <Text
          type="body"
          sizing="S"
          text="Terms & Conditions"
          color={colors.hurricane}
          align="center"
        />
      </Link>
    </div>
  )
}
