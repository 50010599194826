import { Button, FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import copy from 'copy-to-clipboard'
import { useActions, useTypedSelector } from '../../../../../hooks'
import { getReferralUrl } from '../../../../social/helpers'
import ShareButton from '../../../../../components/share_button/ShareButton'
import { analytics } from 'segment_analytics'

export const InviteReferralCardActions = () => {
  const { colors } = useTheme()
  const { setToast } = useActions()
  const { profile } = useTypedSelector(({ user }) => user)
  const inviteLink = profile.data && getReferralUrl(profile.data?.referral_id)

  if (!inviteLink) return null

  const copyInviteLink = () => {
    copy(inviteLink)
    analytics.events.referralsTappedCopyShare()

    setToast({
      showToast: true,
      content: (
        <Text
          type="subhead"
          sizing="S"
          text="Link copied!"
          color={colors.whiteFang}
        />
      ),
    })
  }

  return (
    <div
      className={css`
        max-width: 272px;
        width: 100%;
      `}
    >
      <FlexBox
        className={css`
          margin-bottom: 20px;
          padding: 10px 20px;
          min-height: 44px;
          border: 2px dashed ${colors.whiteSmoke};
          border-radius: 12px;
        `}
        centerAll
      >
        <Text
          className={css`
            cursor: inherit;
          `}
          type="subhead"
          sizing={{ base: 'XS', mobileL: 'S' }}
          text={inviteLink}
          color={colors.denim}
          tag="h4"
          align="center"
          onCopy={copyInviteLink}
        />
      </FlexBox>
      <Button icon="copy" width="100%" label="Copy" onClick={copyInviteLink} />
      <ShareButton
        className={css`
          margin-top: 8px;
          width: 100%;
          max-width: none;
          background-color: ${colors.almostWhite};
          border-color: ${colors.almostWhite};
        `}
        title="Share"
        category="invite"
        url={inviteLink}
        secondary
      />
    </div>
  )
}
