import { QuoteThemeKey } from '@fable/types'
import { Colors, QuoteTheme } from './themeTypes'

const getQuoteColors = ({
  name,
  backgroundColor,
  bookBackgroundColor = 'uiBlack30',
}: {
  name: QuoteThemeKey
  backgroundColor: keyof Colors
  bookBackgroundColor?: keyof Colors
}): QuoteTheme => ({
  name,
  backgroundColor,
  bookTitleColor: 'uiWhite',
  authorColor: 'uiWhite70',
  quoteColor: 'uiWhite50',
  bookBackgroundColor,
})

const QUOTE_THEME: Record<QuoteThemeKey, QuoteTheme> = {
  theme1: {
    name: 'theme1',
    backgroundColor: 'fableGreen',
    bookTitleColor: 'whiteFang',
    authorColor: 'pinkMoon',
    quoteColor: 'pinkMoon',
    bookBackgroundColor: 'uiBlack30',
  },
  theme2: {
    name: 'theme2',
    backgroundColor: 'hazelnut',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
    bookBackgroundColor: 'uiBlack30',
  },
  theme3: {
    name: 'theme3',
    backgroundColor: 'denim',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
    bookBackgroundColor: 'uiBlack30',
  },
  theme4: {
    name: 'theme4',
    backgroundColor: 'orange',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
    bookBackgroundColor: 'uiBlack30',
  },
  theme5: {
    name: 'theme5',
    backgroundColor: 'pinkMoon',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'fableGreen',
    bookBackgroundColor: 'uiBlack30',
  },
  theme6: getQuoteColors({
    name: 'theme6',
    backgroundColor: 'uiGraphite',
    bookBackgroundColor: 'uiGraphiteLight',
  }),
  theme7: getQuoteColors({ name: 'theme7', backgroundColor: 'uiGreenDark' }),
  theme8: getQuoteColors({ name: 'theme8', backgroundColor: 'uiBlueDark' }),
  theme9: getQuoteColors({ name: 'theme9', backgroundColor: 'uiTealDark' }),
  theme10: getQuoteColors({ name: 'theme10', backgroundColor: 'uiYellowDark' }),
  theme11: getQuoteColors({
    name: 'theme11',
    backgroundColor: 'uiPinkExtraDark',
  }),
  theme12: getQuoteColors({
    name: 'theme12',
    backgroundColor: 'uiOrangeDark',
  }),
}

export default QUOTE_THEME
