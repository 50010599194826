import { IconSVGProps } from '../types'

export const FlashlightIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.8333 8.33337H16.6666L9.16665 19.1667V11.6667H3.33331L10.8333 0.833374V8.33337Z" />
  </svg>
)
