import { routes } from '../../app/store/routes'
import { Club } from '@fable/types'
import { allPlanOptions, premiumFreeTrialId } from './constants'
import { PlanSelectionOption } from './types'

export const isFreeTrial = (id: string | undefined) =>
  !!id && id === premiumFreeTrialId

export const getPlans = ({
  club = null,
  freeTrialUsed = false,
}: {
  club?: Club | null
  freeTrialUsed?: boolean
}) => {
  const freeTrialPlan: PlanSelectionOption = {
    ...allPlanOptions.freeTrialPlan,
    club,
  }

  const clubPlan: PlanSelectionOption = {
    ...allPlanOptions.singleClubPlan,
    id: club?.price.id,
    description: `$${club?.price.amount}/month billed monthly`,
    club,
  }

  const premiumPlan: PlanSelectionOption = {
    ...allPlanOptions.premiumPlan,
    club,
  }

  const plans = [premiumPlan]

  if (club) {
    plans.push(clubPlan)
  }

  if (!freeTrialUsed) {
    plans.unshift(freeTrialPlan)
  }

  return plans
}

export const getPlanCheckoutLink = (plan: PlanSelectionOption) => {
  if (!plan || !plan.id) return ''

  const { id, club } = plan
  const planTypeParam = club ? '&p=type:club' : ''
  const checkoutPath = routes.checkout
  let path = `${checkoutPath}?p=plan:${id}${planTypeParam}`

  if (club) {
    path += `&c=${club?.id}`
  }

  return path
}
