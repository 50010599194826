import { css, useTheme } from '@fable/theme'
import { Link } from 'react-router-dom'
import { FlexBox } from '@fable/components'
import { useScroll } from '@fable/hooks'
import { HeaderNavLeft } from './HeaderNavLeft'
import { HeaderNavRight } from './HeaderNavRight'
import { HeaderUser } from './HeaderUser'
import { HeaderSideMenu } from './HeaderSideMenu'
import { Cart } from 'components/cart'
import { useBranchView, useHeader } from 'hooks'
import { LogoV2 } from 'components/logo_v2'
import { routes } from 'app/marketing/routes'

const Header = () => {
  const header = useHeader((s) => s.config)
  const { colors, mediaQueries, motion } = useTheme()
  const scroll = useScroll()
  const secondary = header.theme === 'secondary'
  const isSecondary = secondary && !scroll.scrolled
  const bannerInView = useBranchView()

  if (header.hidden) return null

  return (
    <>
      {!header.fillSpace ? null : (
        <span
          className={css`
            display: block;
            width: 100%;
            height: ${header.mobileSublinksHidden ? 61 : 103}px;
            ${mediaQueries.tablet} {
              height: 61px;
            }
          `}
        />
      )}
      <div
        className={css`
          margin-top: ${bannerInView ? '64px' : 0};
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 10000;
          box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.04);
        `}
      >
        <FlexBox
          className={css`
            padding: 0 18px;
            background-color: ${header.fillSpace || scroll.scrolled
              ? colors.uiWhite
              : 'transparent'};
            width: 100%;
            height: 60px;
            ${motion.transition('background-color')};

            ${mediaQueries.mobileL} {
              padding: 0 20px;
            }

            ${mediaQueries.desktop} {
              padding: 0 32px;
            }
          `}
          justifyContent="space-between"
          alignItems="center"
        >
          <span
            className={css`
              width: 64px;
              ${mediaQueries.tablet} {
                display: none;
              }
            `}
          />
          <FlexBox
            className={css`
              width: 100%;
              justify-content: center;
              ${mediaQueries.tablet} {
                width: auto;
                justify-content: flex-end;
              }
            `}
            alignItems="center"
          >
            <Link to={routes.home}>
              <LogoV2 light={isSecondary} />
            </Link>
            <HeaderNavLeft scroll={scroll} />
          </FlexBox>
          <FlexBox justifyContent="flex-end" alignItems="center">
            <HeaderNavRight secondary={isSecondary} />
            <HeaderUser secondary={isSecondary} />
            <Cart secondary={isSecondary} />
          </FlexBox>
        </FlexBox>
        <HeaderSideMenu secondary={isSecondary} />
      </div>
    </>
  )
}

export default Header
