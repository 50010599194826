import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Logo } from '@fable/components'
import { LoaderType } from '.'
import { STYLE_ATTRIBUTES } from '../../constants'

interface Props {
  backgroundColor?: string
  className?: string
  iconClass?: string
  isIcon?: boolean
  isLight?: boolean
  type?: LoaderType
}

const Loader: React.VFC<Props> = ({
  backgroundColor = 'transparent',
  className = '',
  iconClass = '',
  isIcon = true,
  isLight = false,
  type,
}) => {
  const { breakpoints } = useTheme()

  const getTypeStyles = () => {
    if (!type) return null

    switch (type) {
      case LoaderType.contained:
        return cx(styles.contained)
      case LoaderType.fullscreen:
        return cx(styles.fullscreen)
    }
  }

  const styles = {
    loader: css`
      width: 100%;
      height: 500px;
      background-color: ${backgroundColor};
      z-index: 100;
    `,
    contained: css`
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    `,
    fullscreen: css`
      height: calc(100vh - ${STYLE_ATTRIBUTES.headerHeight});
    `,
    icon: css`
      svg {
        width: 75px !important;
        height: 75px !important;

        @media only screen and (min-width: ${breakpoints.mobile}px) {
          width: 90px !important;
          height: 90px !important;
        }
      }
    `,
  }

  return (
    <FlexBox
      centerAll={true}
      data-testid="loader"
      className={cx(
        styles.loader,
        className,
        getTypeStyles(),
        'fds-ui--loader'
      )}
    >
      <Logo
        className={cx(styles.icon, iconClass)}
        isLight={isLight}
        isIcon={isIcon}
        animate
      />
    </FlexBox>
  )
}

export default Loader
