import { FlexBox, Text } from '@fable/components'
import { TmdbLogo } from '../TmdbLogo'
import { css, useTheme } from '@fable/theme'

export const FooterBottom = () => {
  const { mediaQueries } = useTheme()

  return (
    <FlexBox
      className={css`
        width: 100%;
        gap: 12px;
        flex-direction: column-reverse;
        ${mediaQueries.tablet} {
          justify-content: flex-end;
          flex-direction: row;
          gap: 28px;
        }
      `}
    >
      <Text type="body" sizing="XS" color="rgba(255, 255, 255, 0.5)">
        Fable uses the TMDB API but is not endorsed or certified by TMDB
      </Text>
      <TmdbLogo />
    </FlexBox>
  )
}
