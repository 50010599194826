import * as yup from 'yup'

const passwordSignup = yup
  .string()
  .required('Please enter a password')
  .min(8, 'Your password needs to be at least 8 characters')
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
    'Your password must include at least one uppercase, at least one lowercase and at least one number'
  )

const passwordSignin = yup.string().required('Please enter a password')

const getEmailValidation = (emailPattern?: string) =>
  yup
    .string()
    .matches(
      emailPattern ? new RegExp(`^.*@(${emailPattern})$`, 'i') : new RegExp(''),
      `Make sure your email address matches ${emailPattern?.split('|').join(' or ')}`
    )
    .email()
    .required('Please enter a valid email address')

const signupValidationSchema = (emailPattern?: string) =>
  yup.object({
    name: yup.string().required('Please enter a display name'),
    email: getEmailValidation(emailPattern),
    password: passwordSignup,
    termsAgree: yup
      .boolean()
      .oneOf([true], 'You must agree to the terms')
      .default(true),
  })

const signinValidationSchema = yup.object({
  email: getEmailValidation(),
  password: passwordSignin,
})

const forgotPasswordValidationSchema = yup.object({
  email: getEmailValidation(),
})

export {
  signupValidationSchema,
  signinValidationSchema,
  forgotPasswordValidationSchema,
}
