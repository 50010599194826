import { css, useTheme } from '@fable/theme'
import { CMSImage } from '@fable/types'
import { Carousel } from 'components/carousel'
import { KeenSliderInstance } from 'keen-slider'

interface Props {
  className?: string
  duration?: number
  reverse?: boolean
  loading?: boolean
  images: CMSImage[]
  imgSize: {
    width: {
      mobile: string
      desktop: string
    }
    height?: {
      mobile: string
      desktop: string
    }
  }
  spacing?: {
    mobile: number
    desktop: number
  }
}

export const ScrollingBanner = ({
  className = '',
  reverse = false,
  loading = false,
  duration = 15000,
  images,
  imgSize,
  spacing,
}: Props) => {
  const { breakpoints, mediaQueries } = useTheme()
  const animation = { duration, easing: (t: number) => t }
  const createMoveIndex = reverse ? -5 : 5

  const getMoveIndex = (s: KeenSliderInstance) => {
    const abs = s.track.details.abs
    const index = reverse ? abs - 5 : abs + 5
    s.moveToIdx(index, true, animation)
  }

  if (loading || !images.length) return null

  return (
    <Carousel
      className={className}
      options={{
        loop: true,
        renderMode: 'performance',
        mode: 'free',
        dragSpeed: 0.5,
        created(s) {
          s.moveToIdx(createMoveIndex, true, animation)
        },
        updated(s) {
          getMoveIndex(s)
        },
        animationEnded(s) {
          getMoveIndex(s)
        },
        slides: { perView: 'auto', spacing: spacing?.mobile || 8 },
        breakpoints: {
          [`(min-width: ${breakpoints.mobileL}px)`]: {
            slides: { perView: 'auto', spacing: spacing?.desktop || 12 },
          },
        },
      }}
      childWidths={[
        { breakpoint: 'base', width: imgSize.width.mobile },
        {
          breakpoint: breakpoints.mobileL,
          width: imgSize.width.desktop,
        },
      ]}
      noOverflow
    >
      {images.map((image, i) => (
        <img
          key={i}
          className={css`
            display: block;
            width: 100%;
            height: ${imgSize.height?.mobile || 'auto'};
            object-fit: contain;
            object-position: top;
            ${mediaQueries.mobileL} {
              height: ${imgSize.height?.desktop || 'auto'};
            }
          `}
          src={image.url}
          alt={image.title}
        />
      ))}
    </Carousel>
  )
}
