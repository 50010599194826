import { Button, FlexBox, Hyperlink, RoundImage, Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { useTimeAgo } from 'hooks'
import { Link } from 'react-router-dom'

export interface ActivityUserProps {
  image: string
  name: string
  createdAt: string
  small?: boolean
  label?: string
  url: string
  link?: {
    title: string
    url?: string
    onClick?: () => void
  }
}

export const ActivityUser = ({
  image,
  name,
  createdAt,
  small = false,
  label = 'Shared a post',
  link,
  url,
}: ActivityUserProps) => {
  const { colors, mediaQueries, nativeOverrides } = useTheme()
  const time = useTimeAgo({ date: createdAt, hideSuffix: true })

  const getLink = () => {
    if (!link) return null

    if (!!link.onClick) {
      return (
        <Button
          className={css`
            ${nativeOverrides.buttonClear};
            color: ${colors.uiBlueLight};
            display: inline-block !important;
            font-size: inherit;
            line-height: inherit;
            &:hover {
              color: ${colors.uiBlueLight};
            }
          `}
          onClick={(e) => {
            e.stopPropagation()
            link.onClick && link.onClick()
          }}
        >
          {link.title}
        </Button>
      )
    }

    if (!!link.url) {
      return (
        <Hyperlink href={link.url} onClick={(e) => e.stopPropagation()}>
          {link.title}
        </Hyperlink>
      )
    }
  }

  return (
    <FlexBox
      className={cx(css`
        gap: 8px;
        ${mediaQueries.mobileL} {
          gap: ${small ? 8 : 16}px;
        }
      `)}
      alignItems="center"
    >
      <Link to={url} onClick={(e) => e.stopPropagation()}>
        <RoundImage
          className={css`
            width: 40px;
            height: 40px;
            ${mediaQueries.mobileL} {
              width: ${small ? 40 : 50}px;
              height: ${small ? 40 : 50}px;
            }
          `}
          src={image || ''}
          alt={`${name} profile pic`}
          size={150}
        />
      </Link>
      <FlexBox flexDirection="column">
        <FlexBox alignItems="flex-end" gap="4px">
          <Link to={url} onClick={(e) => e.stopPropagation()}>
            <Text
              className={css`
                cursor: inherit;
              `}
              as="h2"
              type="header"
              sizing={small ? 'XXS' : { base: 'XXS', mobileL: 'XS' }}
              color={colors.uiBlack}
            >
              {name}
            </Text>
          </Link>
          <Text
            className={css`
              margin-bottom: 1px;
              ${mediaQueries.mobileL} {
                margin-bottom: ${small ? 1 : 2}px;
              }
            `}
            type="label"
            sizing="L"
            color={colors.uiPewterGrey}
          >
            {time}
          </Text>
        </FlexBox>
        <Text
          type="body"
          sizing={small ? 'S' : { base: 'S', mobileL: 'M' }}
          color={colors.uiGraphiteLight}
          truncate={2}
        >
          <FlexBox alignItems="center" gap="2px" flexWrap="wrap">
            {label}
            {getLink()}
          </FlexBox>
        </Text>
      </FlexBox>
    </FlexBox>
  )
}
