/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import { BillingActions } from '../actions'
import { BillingTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get, patch, post, remove, put } from '@fable/api'
import { keysToSnakeCase } from '../../utils'
import { Address, CartItem, Subscription } from '@fable/types'
import { ALL_ACCESS_PLAN_YEARLY, isFreeTrial } from '../../components/plans'

type DispatchType = Dispatch<BillingActions>

// CHECKOUT - STARTED
export const startedCheckout =
  (checkoutItems: any) =>
  (dispatch: DispatchType, getState: () => Record<string, any>) => {
    const state = getState()
    const country = state.geoIp.geoIpCountry.data?.country.iso_code
    const properties = country ? { country } : {}
    const data = { checkoutItems, properties }
    dispatch(setAction(BillingTypes.CHECKOUT__START, data))
  }

// CHECKOUT - COMPLETE
export const completedCheckout =
  (checkoutItems: any, invoice: any, startingFrom: string) =>
  (dispatch: DispatchType) => {
    const data = { checkoutItems, invoice, startingFrom }
    dispatch(setAction(BillingTypes.CHECKOUT__COMPLETE, data))
  }

// SET CARD HOLDER NAME
export const setCardHolderName = (name: string) => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.SET_CARD_HOLDER_NAME, name))
}

// SET CARD HOLDER NAME - CLEAR
export const clearCardHolderName = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.SET_CARD_HOLDER_NAME__CLEAR))
}

// DELETE CARD
export const deleteCard =
  (cardId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.DELETE_CARD__REQUEST))

    try {
      const url = `/billing/cards/${cardId}/`
      const { data } = await remove(url)
      await getCards()(dispatch)
      dispatch(setAction(BillingTypes.DELETE_CARD__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.DELETE_CARD__ERROR, error))
    }
  }

// DELETE CARD - CLEAR
export const clearDeletedCard = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.DELETE_CARD__CLEAR))
}

// ADD CARD
export const addCard = (sourceId: string) => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.ADD_CARD__REQUEST))

  try {
    const url = '/billing/cards'
    const { data } = await post(url, { source_id: sourceId, is_default: true })

    dispatch(setAction(BillingTypes.ADD_CARD__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.ADD_CARD__ERROR, error))
  }
}

// SET DEFAULT CARD
export const setDefaultCard =
  (cardId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.DEFAULT_CARD__REQUEST))

    try {
      const url = `/billing/cards/${cardId}`

      const { data } = await patch(url, { is_default: true })
      dispatch(setAction(BillingTypes.DEFAULT_CARD__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.DEFAULT_CARD__ERROR, error))
    }
  }

// ADD CARD - CLEAR
export const clearAddedCard = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.ADD_CARD__CLEAR))
}

// GET CARDS
export const getCards = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_CARDS__REQUEST))
  try {
    const url = '/billing/cards/'
    const { data } = await get(url)
    dispatch(setAction(BillingTypes.GET_CARDS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.GET_CARDS__ERROR, error))
  }
}

// GET ORDERS
export const getOrders = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_ORDERS__REQUEST))
  try {
    const url = '/billing/invoices/'
    const { data } = await get(url)

    dispatch(setAction(BillingTypes.GET_ORDERS__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.GET_ORDERS__ERROR, error))
  }
}

const makeOrderParams = ({
  checkoutItems,
  sourceId,
  clubId,
  giftId,
  credit,
}: {
  checkoutItems: any
  sourceId: string
  clubId: string
  giftId?: string
  credit?: number
}) => {
  const lineItems = []
  if (checkoutItems.cart) {
    const items = checkoutItems.cart as CartItem[]
    items.forEach((item) => {
      lineItems.push({
        product_code: `book:${item.product.isbn}`,
        quantity: 1,
      })
    })
  }
  if (checkoutItems.book && !checkoutItems.book.is_free) {
    const book = checkoutItems.book
    lineItems.push({
      product_code: `book:${book.isbn}`,
      quantity: 1,
    })
  }
  if (checkoutItems.plan) {
    const plan = checkoutItems.plan
    const planId = isFreeTrial(plan.id) ? ALL_ACCESS_PLAN_YEARLY.id : plan.id
    lineItems.push({
      product_code: `plan:${planId}`,
      quantity: 1,
    })
  }
  if (checkoutItems.bundle) {
    const bundle = checkoutItems.bundle
    lineItems.push({
      product_code: `price:${bundle.price.id}`,
      quantity: 1,
    })
  }
  if (checkoutItems.coupon) {
    lineItems.push({
      product_code: `coupon:${checkoutItems.coupon.trim().toLowerCase()}`,
      quantity: 1,
    })
  }
  const params: { [key: string]: any } = {
    sourceId,
    lineItems,
    clubId,
    credit,
  }

  if (giftId) params.giftId = giftId

  if (isFreeTrial(checkoutItems.plan?.id)) {
    return {
      sourceId,
      lineItems,
      freeTrial: true,
    }
  }

  return params
}

// CREATE ORDER
export const createOrder =
  ({
    sourceId,
    checkoutItems,
    clubId,
    giftId,
    credit,
  }: {
    sourceId: string
    checkoutItems: any
    clubId: string
    giftId?: string
    credit: number
  }) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.CREATE_ORDER__REQUEST))
    try {
      const params = makeOrderParams({
        checkoutItems,
        sourceId,
        clubId,
        giftId,
        credit,
      })
      const url = '/billing/orders/'
      const { data } = await post(url, keysToSnakeCase(params))
      dispatch(
        setAction(BillingTypes.CREATE_ORDER__SUCCESS, {
          result: data,
          checkoutItems,
        })
      )
    } catch (error) {
      dispatch(setAction(BillingTypes.CREATE_ORDER__ERROR, error))
    }
  }

// CREATE ORDER - CLEAR
export const clearOrder = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.CREATE_ORDER__CLEAR))
}

// UPDATE ORDER
export const updateOrder =
  ({
    orderId,
    sourceId,
    checkoutItems,
    clubId,
    credit,
  }: {
    orderId: string
    sourceId: string
    checkoutItems: any
    clubId: string
    credit: number
  }) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.UPDATE_ORDER__REQUEST))
    try {
      const url = `/billing/orders/${orderId}`
      const params = makeOrderParams({
        checkoutItems,
        sourceId,
        clubId,
        credit,
      })
      const { data } = await put(url, keysToSnakeCase(params))
      dispatch(
        setAction(BillingTypes.UPDATE_ORDER__SUCCESS, {
          result: data,
          checkoutItems,
        })
      )
    } catch (error) {
      dispatch(setAction(BillingTypes.UPDATE_ORDER__ERROR, error))
      throw error
    }
  }

// PAY FOR ORDER
export const payForOrder =
  (orderId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.PAY_FOR_ORDER__REQUEST))
    try {
      const url = `/billing/orders/${orderId}`
      const params = { isPaid: true }
      const { data } = await patch(url, keysToSnakeCase(params))
      dispatch(setAction(BillingTypes.PAY_FOR_ORDER__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.PAY_FOR_ORDER__ERROR, error))
    }
  }

// PAY FOR ORDER - CLEAR
export const clearPaidOrder = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.PAY_FOR_ORDER__CLEAR))
}

// GET SUBSCRIPTIONS
export const getSubscriptions = () => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_SUBSCRIPTIONS_REQUEST))

  try {
    const { data } = await get('/billing/subscriptions')
    if (!data.length) {
      data.push({
        plan: {
          type: 'free',
          interval: 'free',
        },
      })
    }

    dispatch(setAction(BillingTypes.GET_SUBSCRIPTIONS_SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.GET_SUBSCRIPTIONS_ERROR, error))
  }
}

// GET SUBSCRIPTIONS  - CLEAR
export const clearSubscriptions = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_SUBSCRIPTIONS_CLEAR))
}

// GET PLAN
export const getPlan = (planId: string) => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_PLAN__REQUEST))

  try {
    const url = `/billing/plans/${planId}`

    const { data } = await get(url)

    dispatch(setAction(BillingTypes.GET_PLAN__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.GET_PLAN__ERROR, error))
  }
}

// CLEAR PLAN
export const clearPlan = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_PLAN__CLEAR))
}

// GET PRICE
export const getPrice = (priceId: string) => async (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_PRICE__REQUEST))

  try {
    const url = `/billing/prices/${priceId}`

    const { data } = await get(url)

    dispatch(setAction(BillingTypes.GET_PRICE__SUCCESS, data))
  } catch (error) {
    dispatch(setAction(BillingTypes.GET_PRICE__ERROR, error))
  }
}

// CLEAR PRICE
export const clearPrice = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_PRICE__CLEAR))
}

// GET BUNDLE
export const getBundle =
  (bundleId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.GET_BUNDLE__REQUEST))

    try {
      const url = `/billing/bundles/${bundleId}`

      const { data } = await get(url)

      dispatch(setAction(BillingTypes.GET_BUNDLE__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.GET_BUNDLE__ERROR, error))
    }
  }

// CLEAR BUNDLE
export const clearBundle = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_BUNDLE__CLEAR))
}

// GET OFFERS
export const getOffers =
  ({
    id,
    query,
    country = 'US',
  }: {
    id: string
    query: 'club' | 'bundle'
    country?: string
  }) =>
  async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.GET_OFFERS__REQUEST))

    try {
      const url = `/billing/offers/?query=${query}:${id}&country=${country}`
      const { data } = await get(url)
      dispatch(setAction(BillingTypes.GET_OFFERS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.GET_OFFERS__ERROR, error))
    }
  }

// CLEAR OFFERS
export const clearOffers = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.GET_OFFERS__CLEAR))
}

// DELETE PLAN
export const deletePlan =
  (subscriptionId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.DELETE_PLAN_REQUEST))

    try {
      const url = `/billing/subscriptions/${subscriptionId}`
      const { data } = await remove(url)
      // Refetch subscriptions
      await getSubscriptions()(dispatch)

      dispatch(setAction(BillingTypes.DELETE_PLAN_SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.DELETE_PLAN_ERROR, error))
    }
  }

// DELETE PLAN - CLEAR
export const clearDeletedPlan = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.DELETE_PLAN_CLEAR))
}

// SET ADDRESS
export const setAddress =
  ({ country, line1, line2, city, state, postal_code }: Address) =>
  (dispatch: DispatchType) => {
    dispatch(
      setAction(BillingTypes.SET_ADDRESS, {
        country,
        line1,
        line2,
        city,
        state,
        postal_code,
      })
    )
  }

// SET ADDRESS - CLEAR
export const setAddressClear = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.SET_ADDRESS__CLEAR))
}

// SET STRIPE SOURCE ID
export const setStripeSourceId =
  (id: string, clientSecret?: string) => (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.SET_STRIPE_SOURCE_ID, id))
    dispatch(
      setAction(BillingTypes.SET_STRIPE_SOURCE_CLIENT_SECRET, clientSecret)
    )
  }

// SET STRIPE SOURCE ID - CLEAR
export const clearStripeSourceId = () => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.SET_STRIPE_SOURCE_ID__CLEAR))
}

// CANCEL PLAN
export const cancelSubscription =
  (plan: Subscription) => (dispatch: DispatchType) => {
    // handled by cancelSubscriptionSaga
    dispatch(setAction(BillingTypes.SUBSCRIPTION_CANCELLATION, plan))
  }

// SET COUPON CODE
export const setCouponCode = (message: string) => (dispatch: DispatchType) => {
  dispatch(setAction(BillingTypes.SET_COUPON_CODE, message))
}

// SET CREDIT BALANCE ENABLED
export const setCreditBalanceEnabled =
  (isEnabled: boolean) => (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.SET_CREDIT_BALANCE_ENABLED, isEnabled))
  }

// GET CREDIT BALANCE
export const getCreditBalance =
  (subscriptionId: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(BillingTypes.GET_CREDIT_BALANCE__REQUEST))

    try {
      const url = `/credit/balance`
      const { data } = await get(url)
      dispatch(setAction(BillingTypes.GET_CREDIT_BALANCE__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(BillingTypes.GET_CREDIT_BALANCE__ERROR, error))
    }
  }
