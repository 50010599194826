import './SearchBar.scss'
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useClickOutside } from '@fable/hooks'
import CloseIcon from '../../assets/icons/components/CloseIcon'

import { ResultList } from './result_list/ResultList'
import Field from '../field/Field'

import { useTypedSelector, useActions } from '../../hooks'
import { BOOK_SEARCH_PLACEHOLDER } from '../../app/store/constants'
import { CATEGORIES } from '../../constants'
import isEmpty from 'lodash/isEmpty'

export const SearchBar = ({ className, config, isActive, onClose }) => {
  const [debounceTerm, setDebounceTerm] = useState('')
  const [didSearch, setDidSearch] = useState(false)
  const [isResultListExpanded, setIsResultListExpanded] = useState(false)
  const [term, setTerm] = useState('')

  const { searchBooks } = useActions()
  const { bookResults } = useTypedSelector(({ search }) => search)
  const searchBarRef = useRef()

  useClickOutside(searchBarRef, () => {
    if (isResultListExpanded) {
      setTerm('')
      setIsResultListExpanded(false)
      setDidSearch(false)
    }
  })

  // NB: config can be later used for multiple instances of SearchBar with different data
  config = {
    placeholder: BOOK_SEARCH_PLACEHOLDER,
    type: 'default',
  }

  const { onChange, placeholder, type } = config

  useLayoutEffect(() => {
    if (searchBarRef.current) {
      isActive
        ? searchBarRef.current.children[0].firstChild.focus()
        : searchBarRef.current.children[0].firstChild.blur()
    }
  }, [isActive])

  // toggle dynamic search results container
  useEffect(() => {
    if (term !== '') {
      setIsResultListExpanded(true)
    } else {
      setDidSearch(false)
      setIsResultListExpanded(false)
    }
  }, [term])

  // handle search term
  useEffect(() => {
    if (!type) onChange(term)

    const timerId = setTimeout(() => {
      if (term !== '') {
        setDebounceTerm(term.toLowerCase().trim())
      }
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [term, type, onChange])

  // debounce search term
  useEffect(() => {
    if (debounceTerm) {
      if (type) {
        setDidSearch(true)
        searchBooks(debounceTerm)
      }
    }
  }, [searchBooks, debounceTerm, type])

  const clearSearch = () => {
    if (isResultListExpanded && type) {
      setIsResultListExpanded(false)
      setDidSearch(false)
      onClose()
    }

    setTerm('')
  }

  const resultsExpand = isResultListExpanded ? 'expand' : ''
  const resultLength = !isEmpty(bookResults.data) ? bookResults.data.length : 0
  const reduceHeight =
    resultLength === 0 && didSearch && !bookResults.loading ? 'reduce' : ''
  const showClearIcon = term !== '' ? 'show' : ''

  const onClick = (e) => {
    e.stopPropagation()
  }

  return type ? (
    <div className={`search-bar dynamic ${className}`} ref={searchBarRef}>
      <Field
        icon="search"
        onChange={(e) => setTerm(e.target.value)}
        placeholder={placeholder}
        type="text"
        value={term}
        onClick={onClick}
      />
      <div
        className={`search-results-container ${resultsExpand} ${reduceHeight}`}
      >
        <div className="search-results">
          <ResultList
            category={CATEGORIES.book}
            didSearch={didSearch}
            onSelectItem={clearSearch}
            results={bookResults.data}
            isSearching={bookResults.loading}
          />
        </div>
      </div>
      <div className={`clear-icon ${showClearIcon}`} onClick={clearSearch}>
        <CloseIcon />
      </div>
    </div>
  ) : (
    <div className={`search-bar ${className}`} ref={searchBarRef}>
      <Field
        onChange={(e) => setTerm(e.target.value)}
        placeholder={placeholder}
        type="text"
        value={term}
        icon="search"
        onClick={onClick}
      />
      <div className={`clear-icon ${showClearIcon}`} onClick={clearSearch}>
        <CloseIcon />
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
}

SearchBar.defaultProps = {
  className: '',
  config: {},
  isActive: false,
  onClose: () => {},
}
