import { ApolloProvider } from '@apollo/client'
import { ThemeProvider, theme } from '@fable/theme'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { LegacyTheme } from '@fable/legacy'
import contenful from '../services/contenful'
import ScrollToTop from '../utils/ScrollToTop'
import { ContextProviders } from './context'
import { storeConfig } from './store'
import { HelmetProvider } from 'react-helmet-async'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const Providers: React.FC<{
  children?: React.ReactNode | string | null
}> = ({ children }) => (
  <HelmetProvider>
    <ReduxProvider store={storeConfig()}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={contenful}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <LegacyTheme>
                <ContextProviders>
                  <ScrollToTop>{children}</ScrollToTop>
                </ContextProviders>
              </LegacyTheme>
            </ThemeProvider>
          </BrowserRouter>
        </ApolloProvider>
      </QueryClientProvider>
    </ReduxProvider>
  </HelmetProvider>
)
