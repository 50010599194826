import { get as getApi } from '@fable/api'
import { create } from 'zustand'
import { getFeatureFlagAccess, triggerSplitTest } from './helpers'
import { FeatureFlagState } from './types'
import { featureFlagConfig } from './config'

export const useFeatureFlags = create<FeatureFlagState>((set, get) => ({
  loading: true,
  user: null,
  config: featureFlagConfig,
  flags: [],
  setFlags: (flags) => set({ flags }),
  getFeatureFlags: async (user) => {
    set({ loading: true })

    try {
      const response = await getApi('/app_config/')
      const flags = response.data?.feature_flags || []
      set({ flags })
      triggerSplitTest(flags)
    } catch (e) {
      console.error('Could not fetch feature flags:', e)
      set({ flags: [] })
    } finally {
      set({ user, loading: false })
    }
  },
  flagEnabled: (key) => {
    const state = get()
    const flag = state.config.find((o) => o.name === key)

    if (!flag || !flag.enabled) return false

    const accessGranted = getFeatureFlagAccess({
      name: flag.name,
      access: flag.access,
      user: state.user,
    })

    if (flag.source === 'client') return accessGranted

    if (state.loading) return false

    return accessGranted || state.flags.includes(key)
  },
}))
