import {
  BookListItem,
  BookListMediaType,
  BookListSystemType,
  BooklistBooksResponse,
  Pagination,
} from '@fable/types'
import { get, post, remove } from '../fable'

const getLists = async ({
  userId,
  mediaType = 'book',
}: {
  userId: string
  mediaType?: BookListMediaType
}) => {
  const url = `/v2/users/${userId}/book_lists/?media_type=${mediaType}`
  return await get(url)
}

const getSystemList = async ({
  userId,
  type,
  mediaType = 'book',
}: {
  userId: string
  type: BookListSystemType
  mediaType?: BookListMediaType
}) => {
  try {
    const res = await getLists({ userId, mediaType })
    const bookListData = res.data as Pagination<BookListItem>
    return bookListData.results?.find((list) => list.system_type === type)
  } catch (e: any) {
    console.error('Error fetching WTR list', e)
  }
}

const getListBooks = async ({
  id,
  pageParam,
  limit = 10,
  offset = 0,
}: {
  id: string
  pageParam?: string
  limit?: number
  offset?: number
}) => {
  let url = `/book_lists/${id}/books?limit=${limit}&offset=${offset}`
  if (!!pageParam?.length) url = pageParam

  return (await get(url)) as BooklistBooksResponse
}

const getListItems = async ({
  userId,
  booklistId,
  pageParam,
  limit = 10,
  offset = 0,
  ordering,
  withRatings = false,
}: {
  userId: string
  booklistId: string
  pageParam?: string
  limit?: number
  offset?: number
  ordering?: string
  withRatings?: boolean
}) => {
  let url = `/v2/users/${userId}/book_lists/${booklistId}/books?limit=${limit}&offset=${offset}`
  if (!!pageParam?.length) url = pageParam

  if (ordering) url += `&ordering=${ordering}`
  if (withRatings) url += `&with_ratings=true`

  return (await get(url)) as BooklistBooksResponse
}

const addBookToList = async ({
  userId,
  listId,
  bookId,
  favorite,
  sortValue,
}: {
  userId: string
  listId: string
  bookId: string
  favorite?: boolean
  sortValue?: number
}) => {
  const payload: {
    book_id: string
    favorite?: boolean
    sort_value?: number
  } = { book_id: bookId }

  if (favorite) payload.favorite = favorite
  if (sortValue) payload.sort_value = sortValue

  return await post(`/v2/users/${userId}/book_lists/${listId}/books/`, payload)
}

const removeBookFromList = async ({
  userId,
  listId,
  bookId,
}: {
  userId: string
  listId: string
  bookId: string
}) => {
  return await remove(
    `/v2/users/${userId}/book_lists/${listId}/books/${bookId}/`
  )
}

export const booklist = {
  addBookToList,
  getListBooks,
  getLists,
  getSystemList,
  removeBookFromList,
  getListItems,
}
