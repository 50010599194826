import { useEffect, useState } from 'react'
import { Address } from '@fable/types'

export const useAddressValidation = (
  address: Address
): {
  isValid: boolean
  isStreetValid: boolean
  isCityValid: boolean
  isStateValid: boolean
  isZipValid: boolean
} => {
  const [addressValidation, setAddressValidation] = useState({
    isValid: false,
    isStreetValid: false,
    isCityValid: false,
    isStateValid: false,
    isZipValid: false,
  })
  useEffect(() => {
    const { line1, city, state, postal_code } = address

    const isStreetValid = line1 !== '' ? true : false
    const isCityValid = city !== '' ? true : false
    const isStateValid = state !== '' ? true : false
    const isZipValid = postal_code !== '' ? true : false

    setAddressValidation({
      isValid: isStreetValid && isCityValid && isStateValid && isZipValid,
      isStreetValid,
      isCityValid,
      isStateValid,
      isZipValid,
    })
  }, [address])

  return addressValidation
}

export const useAddressValidationWithCountryCode = (
  address: Address
): {
  isValid: boolean
  isCountryValid: boolean
  isStreetValid: boolean
  isCityValid: boolean
  isStateValid: boolean
  isZipValid: boolean
} => {
  const [addressValidation, setAddressValidation] = useState({
    isValid: false,
    isCountryValid: false,
    isStreetValid: false,
    isCityValid: false,
    isStateValid: false,
    isZipValid: false,
  })
  useEffect(() => {
    const { country, line1, city, state, postal_code } = address

    const isCountryValid = country !== '' ? true : false
    const isStreetValid = line1 !== '' ? true : false
    const isCityValid = city !== '' ? true : false
    const isStateValid = state !== '' ? true : false
    const isZipValid = postal_code !== '' ? true : false

    setAddressValidation({
      isValid:
        isCountryValid &&
        isStreetValid &&
        isCityValid &&
        isStateValid &&
        isZipValid,
      isCountryValid,
      isStreetValid,
      isCityValid,
      isStateValid,
      isZipValid,
    })
  }, [address])

  return addressValidation
}
