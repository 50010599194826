import { useQuery } from '@apollo/client'
import get from 'lodash/get'

export default function useSlugQuery(
  slugToIdQuery,
  slug,
  collectionKey,
  idQuery,
  skip = false
) {
  const {
    data: slugQueryData,
    error: slugQueryError,
    loading: slugQueryLoading,
  } = useQuery(slugToIdQuery, { variables: { slug }, skip })
  const id = get(slugQueryData, `${collectionKey}.items[0].sys.id`)
  const {
    data: idQueryData,
    error: idQueryError,
    loading: idQueryLoading,
  } = useQuery(idQuery, {
    variables: { id },
    skip: !id,
  })
  return {
    loading: slugQueryLoading || idQueryLoading,
    error: slugQueryError || idQueryError,
    data: idQueryData,
  }
}
