import { IconSVGProps } from '../types'

export const PriceTagIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.08353 0.75L16.3327 1.92917L17.511 10.1792L9.85103 17.8392C9.69476 17.9954 9.48284 18.0832 9.26187 18.0832C9.0409 18.0832 8.82897 17.9954 8.6727 17.8392L0.4227 9.58917C0.266474 9.43289 0.178711 9.22097 0.178711 9C0.178711 8.77903 0.266474 8.56711 0.4227 8.41083L8.08353 0.75ZM10.4402 7.82167C10.595 7.97641 10.7788 8.09914 10.981 8.18287C11.1832 8.26659 11.4 8.30967 11.6188 8.30963C11.8377 8.30959 12.0544 8.26644 12.2566 8.18264C12.4588 8.09885 12.6425 7.97605 12.7973 7.82125C12.952 7.66645 13.0748 7.4827 13.1585 7.28047C13.2422 7.07824 13.2853 6.8615 13.2852 6.64262C13.2852 6.42375 13.2421 6.20702 13.1583 6.00482C13.0745 5.80262 12.9517 5.61891 12.7969 5.46417C12.6421 5.30943 12.4583 5.18669 12.2561 5.10296C12.0539 5.01924 11.8371 4.97617 11.6182 4.97621C11.1762 4.97629 10.7523 5.15196 10.4398 5.46458C10.1273 5.77721 9.95175 6.20117 9.95182 6.64321C9.9519 7.08525 10.1276 7.50915 10.4402 7.82167Z" />
  </svg>
)
