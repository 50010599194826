import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth'

const AUTH_PROVIDERS = [
  {
    id: 'google',
    name: 'Google',
    icon: 'google',
    login: new GoogleAuthProvider(),
  },
  {
    id: 'apple',
    name: 'Apple',
    icon: 'apple',
    login: new OAuthProvider('apple.com'),
  },
  {
    id: 'microsoft',
    name: 'Microsoft',
    icon: 'microsoft',
    login: new OAuthProvider('microsoft.com'),
  },
  {
    id: 'email',
    name: 'Email',
    icon: undefined,
    login: undefined,
  },
]

export default AUTH_PROVIDERS
