import { css, cx, useTheme } from '@fable/theme'

interface Props {
  open: boolean
}

export const AccordionIcon = ({ open }: Props) => {
  const { colors, motion } = useTheme()
  const buttonBarStyles = css`
    display: block;
    width: 2px;
    height: 14px;
    background-color: ${colors.graphite};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    ${motion.transition(['background-color', 'transform'])};
  `

  return (
    <div
      className={css`
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 24px;
        right: 0;
        cursor: pointer;
        ${motion.transition('background-color')};
      `}
    >
      <span
        className={cx(
          buttonBarStyles,
          css`
            transform: translate(-50%, -50%) rotate(${open ? 90 : 0}deg);
          `
        )}
      />
      <span className={buttonBarStyles} />
    </div>
  )
}
