import { useQuery as gqlQuery } from '@apollo/client'
import { useQueries, useQueryClient } from 'react-query'
import { CURATED_CLUBS_LIST } from '../queries'
import { get } from '@fable/api'
import { CarouselQueryData, CarouselSlugs } from '../components/carousel'

interface Props {
  /** @property {string} listId - id of the list of slugs to be fetched from contentful */
  listId: string
  /** @property {string} queryKey - key for querying the list of child items within the carousel  */
  queryKey: string
}

export const useClubsList = ({ listId, queryKey }: Props) => {
  // fetch the the slug data from contentful
  const slugsQuery = gqlQuery(CURATED_CLUBS_LIST, {
    variables: { id: listId },
  })
  const clubSlugsList =
    slugsQuery.data?.curatedClubsList?.curatedClubsListCollection?.items || []

  // fecth the list of club data from the API
  const itemsQuery = useQueries(
    clubSlugsList.map((clubSlugs: CarouselSlugs) => ({
      queryKey: [queryKey, clubSlugs],
      queryFn: () =>
        get(
          '/clubs/club_list?' +
            clubSlugs.slugs
              .map((slug) => `club=${encodeURIComponent(slug)}`)
              .join('&')
        ),
    }))
  )

  const queryClient = useQueryClient()
  const data = queryClient.getQueriesData<CarouselQueryData>(queryKey)
  const itemsQueryError = itemsQuery.find((item) => item.error)

  return {
    data,
    loading: slugsQuery.loading,
    error: slugsQuery.error || (itemsQueryError?.error as Error),
  }
}
