import {
  useScroll,
  useTransform,
  motion,
  useMotionValueEvent,
} from 'framer-motion'
import { StarSpinnerDoodle } from './doodles'
import { css, useTheme } from '@fable/theme'
import { useState } from 'react'

export const StarSpinner = () => {
  const { mediaQueries } = useTheme()
  const { scrollYProgress, scrollY } = useScroll()
  const [scrolled, setScrolled] = useState(false)

  const rotate = useTransform(scrollYProgress, [0, 1], [-360, 360])
  const opacity = useTransform(scrollYProgress, [0, 0.9, 1], [1, 1, 0])

  useMotionValueEvent(scrollY, 'change', (pos) => {
    setScrolled(pos > 480)
  })

  return (
    <motion.div
      style={{ rotate, opacity }}
      className={css`
        display: none;
        ${mediaQueries.desktopL} {
          display: flex;
          justify-content: center;
          width: 9.5%;
          position: ${scrolled ? 'fixed' : 'absolute'};
          z-index: 5;
          top: ${scrolled ? '100px' : '580px'};
        }
      `}
    >
      <StarSpinnerDoodle />
    </motion.div>
  )
}
