import { gql } from '@apollo/client'

export const CMS_GALLERY = gql`
  query Gallery($id: String!) {
    gallery(id: $id) {
      name
      imagesCollection {
        items {
          url
          title
        }
      }
    }
  }
`
