import { css, cx, useTheme } from '@fable/theme'
import { Redirect } from '@fable/legacy'
import { Link } from 'react-router-dom'
import appIcon from '../../../../assets/images/logo/app-icon.svg'
import { APP_DOWNLOAD_DETAILS } from '../../../../constants'
import ContainerRounded from '../../../../components/container_rounded/ContainerRounded'
import AppStoreLink from '../../../../components/app_store_link/AppStoreLink'
import { FlexBox, Text } from '@fable/components'
import pinkGradient from '../../../../assets/images/graphics/pink-gradient-overlayed-reverse.webp'
import clubCollage from '../../../../assets/images/graphics/club-collage.webp'
import giftHalf from '../../../../assets/images/graphics/gift-half.svg'
import Loader from '../../../../components/loader/Loader'
import GiftItem from '../gift_detail_item/GiftItem'
import GiftItemTitle from '../gift_detail_item/GiftItemTitle'
import { useGiftCertificateLookup } from '../../../../hooks/useGiftCertificateLookup'
import {
  GIFT_BOOK_LINE_ONE,
  GIFT_BOOK_LINE_TWO,
  GIFT_ALL_ACCESS_LINE_ONE,
  GIFT_ALL_ACCESS_LINE_TWO,
  GIFT_BOUGHT_A_BOOK,
  GIFT_BOUGHT_ALL_ACCESS,
  GIFT_RECIPIENT_ID_SEARCH_PARAM,
} from '../../constants'
import { useUrlQuery } from '../../../../hooks'

const GiftCertificate: React.VFC<
  {
    preview?: boolean
    recipientId: string | null
  } & React.HTMLProps<HTMLDivElement>
> = ({ preview, recipientId, ...nativeProps }) => {
  const queryParams = useUrlQuery()
  const { colors, fonts } = useTheme()
  const id = recipientId || queryParams.get(GIFT_RECIPIENT_ID_SEARCH_PARAM)
  const giftCardDetails = useGiftCertificateLookup({ recipientId: id })

  if (!id) {
    return <Redirect to="/404" />
  }

  if (!giftCardDetails.data) {
    return <Loader />
  }

  const { data } = giftCardDetails

  const hasNote = !!data?.note
  const hasBook = data?.books?.length > 0

  return (
    <div
      {...nativeProps}
      id="gift-certificate"
      className={cx(
        css`
          padding: 20px;
          background: ${colors.whiteFang};
          * {
            text-align: center;
          }
        `,
        preview &&
          css`
            width: 300px;
            * {
              width: 100%;
            }
          `,
        nativeProps.className
      )}
    >
      <FlexBox
        flexDirection="column"
        alignItems="center"
        className={cx(
          css`
            position: relative;
            margin: 40px 40px 34px 40px;
            width: 595px;
            background: url(${pinkGradient}) left top / cover no-repeat;
            @media print {
              margin: 0 auto;
            }
            ${preview && 'width: 100%;margin: 0;'};
          `
        )}
      >
        <FlexBox
          flexDirection="column"
          alignItems="center"
          className={css`
            height: inherit;
            width: 100%;
            max-width: 302px;
            margin-top: 40px;
            ${preview && 'margin-top: 0;transform: scale(0.8);'}
          `}
        >
          <img
            className={cx(
              css`
                width: 32px;
                height: 32px;
                margin-bottom: 8px;
              `,
              'app-icon'
            )}
            src={appIcon}
            alt="Fable app icon"
          />
          <Text
            type="header"
            sizing="XL"
            className={css`
              margin-bottom: 14px;
            `}
          >
            {hasBook
              ? `${GIFT_BOOK_LINE_ONE} ${GIFT_BOOK_LINE_TWO}`
              : `${GIFT_ALL_ACCESS_LINE_ONE} ${GIFT_ALL_ACCESS_LINE_TWO}`}
          </Text>
          <Text type="subhead" sizing={{ base: 'S', mobileL: 'M' }}>
            <b>{`${data?.sender_name} ${
              hasBook ? GIFT_BOUGHT_A_BOOK : GIFT_BOUGHT_ALL_ACCESS
            }`}</b>
          </Text>
          <ContainerRounded
            className={css`
              width: 100%;
              margin: 18px 0px 22px 0px;
              padding: 0;
              background: none;
              overflow: hidden;
              ${preview && 'transform: scale(0.8);'}
            `}
          >
            <ul>
              {hasBook && (
                <GiftItem
                  className={css`
                    background: rgba(255, 255, 255, 0.5);
                    .top {
                      display: none;
                    }

                    .book-price {
                      display: none;
                    }
                    div.book {
                      justify-content: center;
                      &-details {
                        width: fit-content;
                      }
                      &.small {
                        margin-bottom: 0;
                        .bottom p {
                          color: ${colors.blackSwan};
                          font-family: ${fonts.body};
                          margin-top: 0;
                          font-size: 8px;
                          line-height: 12px !important;
                        }
                        .book-cover {
                          margin-right: 12px;
                          img {
                            box-shadow: none;
                            width: 32px;
                            height: auto;
                          }
                        }
                      }
                    }
                  `}
                >
                  <FlexBox
                    className={css`
                      align-items: center;
                    `}
                  >
                    <img
                      className={css`
                        width: 28px;
                        height: 44px;
                        border-radius: 2px;
                      `}
                      src={data?.books[0].cover_image}
                      alt="book cover"
                    />
                    <Text
                      className={css`
                        margin-left: 8px;
                        font-size: 8px;
                        line-height: 12px;
                      `}
                      type="body"
                      sizing="S"
                    >
                      {data?.books[0].author}
                      <br />
                      {data?.books[0].title}
                    </Text>
                  </FlexBox>
                </GiftItem>
              )}
              {hasNote && (
                <GiftItem
                  className={css`
                    background: rgba(255, 255, 255, 0.5);
                  `}
                >
                  <GiftItemTitle text="A personal note for you" />
                  <Text
                    className={css`
                      font-size: 8px;
                      line-height: 12px;
                    `}
                    type="body"
                    sizing="S"
                  >
                    {data?.note}
                  </Text>
                </GiftItem>
              )}
              <GiftItem
                className={css`
                  margin-bottom: 0;
                  background: rgba(255, 255, 255, 0.5);
                `}
              >
                <GiftItemTitle text="Here's your gift code" />
                <Text type="subhead" sizing={{ base: 'M', mobileL: 'L' }}>
                  {data?.code}
                </Text>
                <Text
                  className={css`
                    font-size: 10px;
                    line-height: 12px;
                    span,
                    a {
                      color: ${colors.denim};
                    }
                  `}
                  type="body"
                  sizing="S"
                >
                  Go to{' '}
                  {preview ? (
                    <span>fable.co/redeem</span>
                  ) : (
                    <Link to="/redeem" target="_blank">
                      fable.co/redeem
                    </Link>
                  )}{' '}
                  and enter this code
                </Text>
              </GiftItem>
            </ul>
          </ContainerRounded>
          <ContainerRounded
            className={css`
              position: relative;
              background: url(${clubCollage}) ${colors.fableGreen} right top /
                contain no-repeat;
              min-height: 88px;
              width: 100%;
              max-width: 302px;
              padding: 14px 0px 0px 17px;
              overflow: hidden;
              ${preview && 'transform: scale(0.8);'}
            `}
          >
            <div
              className={css`
                width: 60%;
              `}
            >
              <Text
                className={css`
                  line-height: 14px;
                  display: block;
                `}
                type="body"
                sizing="S"
                color={colors.pinkMoon}
              >
                <b>Fable Premium Annual Membership</b>
              </Text>
              <div
                className={css`
                  margin-top: 5px;
                `}
              >
                <Text
                  className={css`
                    font-size: 6px;
                    line-height: 8px;
                    display: block;
                  `}
                  type="body"
                  sizing="S"
                  color={colors.pinkMoonLight}
                >
                  With Fable, you can browse, buy, read and discuss great books,
                  all in one place. A premium annual membership gives you
                  acccess to all premium clubs, so there are no limits to who
                  you can read with.
                </Text>
              </div>
            </div>
          </ContainerRounded>
          <div
            className={css`
              font-size: 7px;
              line-height: 11px;
              width: 211px;
              margin: 12px 0px;
            `}
          >
            <Text
              className={css`
                font-size: 7px;
                line-height: 11px;
              `}
              type="body"
              sizing="S"
              color={colors.fableGreen}
            >
              <b>Download the app to read</b>
            </Text>
            <Text
              className={css`
                font-size: 7px;
                line-height: 11px;
              `}
              type="body"
              sizing="S"
              color={colors.fableGreen}
            >
              Enjoy all the social reading features of Fable in the app,
              available on iOS and Android devices.
            </Text>
          </div>
          <FlexBox
            centerAll
            className={css`
              div {
                width: auto;
              }
              img {
                width: 86px;
              }
            `}
          >
            {APP_DOWNLOAD_DETAILS.map((provider, index) => (
              <AppStoreLink
                provider={provider}
                imageType="outlineGreen"
                key={index}
              />
            ))}
          </FlexBox>
        </FlexBox>
        <img
          className={css`
            margin-bottom: -34px;
            ${preview && 'transform: scale(0.7);'}
          `}
          src={giftHalf}
          alt="A giant, wrapped present"
        />
      </FlexBox>
    </div>
  )
}

export default GiftCertificate
