import { registerAccordion } from './Accordion'
import { registerAnchorLink } from './AnchorLink'
import { registerButton } from './Button'
import { registerMenuDrawer } from './menu_drawer/MenuDrawer'

export const loadCustomComponents = () => {
  registerAccordion()
  registerAnchorLink()
  registerButton()
  registerMenuDrawer()
}
