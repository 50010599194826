import { IconSVGProps } from '../types'

export const LockIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg className={className} width="17" height="16" viewBox="0 0 17 16">
    <g clipPath="url(#clip0_1_1730)">
      <path
        d="M13.1667 6.66665H13.8333C14.0101 6.66665 14.1797 6.73688 14.3047 6.86191C14.4298 6.98693 14.5 7.1565 14.5 7.33331V14C14.5 14.1768 14.4298 14.3464 14.3047 14.4714C14.1797 14.5964 14.0101 14.6666 13.8333 14.6666H3.16667C2.98986 14.6666 2.82029 14.5964 2.69526 14.4714C2.57024 14.3464 2.5 14.1768 2.5 14V7.33331C2.5 7.1565 2.57024 6.98693 2.69526 6.86191C2.82029 6.73688 2.98986 6.66665 3.16667 6.66665H3.83333V5.99998C3.83333 5.38714 3.95404 4.78031 4.18856 4.21412C4.42308 3.64794 4.76683 3.13349 5.20017 2.70015C5.63351 2.26681 6.14796 1.92306 6.71414 1.68854C7.28033 1.45402 7.88716 1.33331 8.5 1.33331C9.11284 1.33331 9.71967 1.45402 10.2859 1.68854C10.852 1.92306 11.3665 2.26681 11.7998 2.70015C12.2332 3.13349 12.5769 3.64794 12.8114 4.21412C13.046 4.78031 13.1667 5.38714 13.1667 5.99998V6.66665ZM11.8333 6.66665V5.99998C11.8333 5.11592 11.4821 4.26808 10.857 3.64296C10.2319 3.01784 9.38406 2.66665 8.5 2.66665C7.61595 2.66665 6.7681 3.01784 6.14298 3.64296C5.51786 4.26808 5.16667 5.11592 5.16667 5.99998V6.66665H11.8333ZM7.83333 9.33331V12H9.16667V9.33331H7.83333Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1_1730">
        <rect width="16" height="16" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
