import './Tooltip.scss'
import React from 'react'

import PropTypes from 'prop-types'

import InfoBorderIcon from '../../assets/icons/components/InfoBorderIcon'

const Tooltip = ({ customClass, isLabelDark, tip }) => {
  const darkLabel = isLabelDark ? 'dark' : ''

  return (
    <div className={`tooltip ${customClass}`}>
      <InfoBorderIcon />
      <label className={darkLabel}>{tip}</label>
    </div>
  )
}

Tooltip.propTypes = {
  customClass: PropTypes.string.isRequired,
  isLabelDark: PropTypes.bool,
  tip: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  customClass: '',
  isLabelDark: false,
  tip: '',
}

export default Tooltip
