import { css, cx, useTheme, FableTheme } from '@fable/theme'
import MailingListForm from '../../mailing_list_form/MailingListForm'
import { Align } from '@fable/legacy'
import { APP_DOWNLOAD_DETAILS } from '../../../constants'
import AppStoreLink from '../../app_store_link/AppStoreLink'
import { FooterLinks } from '../FooterLinks'
import { Text } from '@fable/components'

export const FooterMid: React.VFC = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderMailForm = () => {
    const title = 'Subscribe to our newsletter'
    return (
      <div>
        <Text
          tag="p"
          className={styles.label}
          type="subhead"
          sizing="XS"
          text={title}
          color={theme.colors.whiteFang}
        />
        <MailingListForm isDark={true} isNoteActive={false} />
      </div>
    )
  }

  const renderAppLinks = () => {
    const title = 'Download the app'

    const links = APP_DOWNLOAD_DETAILS.map((provider, index) => (
      <AppStoreLink provider={provider} imageType="dark" key={index} />
    ))

    return (
      <div className={cx(styles.appLinksWrap)}>
        <Text
          tag="p"
          className={styles.label}
          type="subhead"
          sizing="XS"
          text={title}
          color={theme.colors.whiteFang}
        />
        <div className={Align.hLeft}>{links}</div>
      </div>
    )
  }

  return (
    <section className={cx(styles.section, Align.between)}>
      <div className={cx(styles.leftContent)}>
        {renderMailForm()}
        {renderAppLinks()}
      </div>
      <FooterLinks
        className={css`
          display: none;
          ${theme.mediaQueries.desktop} {
            display: flex;
          }
        `}
      />
    </section>
  )
}

const getStyles = ({ colors, mediaQueries }: FableTheme) => ({
  section: css`
    margin-top: 32px;
    padding-top: 40px;
    border-top: 1px solid ${colors.greyStrong};

    @media only screen and (max-width: 468px) {
      flex-direction: column;
    }
  `,
  leftContent: css`
    margin-right: 91px;
    width: 100%;

    ${mediaQueries.mobileS} {
      width: 336px;
    }
  `,
  label: css`
    margin-bottom: 12px;
  `,
  appLinksWrap: css`
    margin-top: 28px;
  `,
})
