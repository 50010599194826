import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useDelayedScrollIntoView(
  id: string,
  options?: Record<string, string>
) {
  const location = useLocation()

  // Anchor tags have broken in some browsers due to lazy loading
  useEffect(() => {
    if (location.hash?.split('#')[1] === id) {
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView(options)
      }, 500)
    }
  }, [id, location.hash, options])
}
