import { ReactNode, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { css } from '@fable/theme'
import {
  Button,
  FlexBox,
  Loader,
  Logo,
  Text,
  TransitionElement,
} from '@fable/components'
import { ClubQuizContext, ClubQuizContextProvider } from 'state/context'
import { routes } from 'app/clubs/routes'
import { analytics } from 'segment_analytics'

export const ClubQuiz = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation()
  const { loading, allowNext, nextRoute, nextUrlSearchParams } =
    useContext(ClubQuizContext)

  const renderLoader = () => {
    if (pathname.includes(routes.clubQuizResults)) {
      return (
        <FlexBox
          gap="65px"
          flexDirection="column"
          centerAll
          className={css`
            margin-top: 224px;
            user-select: none;
          `}
        >
          <Text sizing="M" type="header" text="Putting it all together..." />
          <Logo animate isIcon />
        </FlexBox>
      )
    }

    return (
      <TransitionElement transitionType="fadeOut">
        <Loader />
      </TransitionElement>
    )
  }

  const handleClickButton = () => {
    if (pathname.includes(routes.clubQuizGenre)) {
      analytics.events.clubQuizGenresSelected()
    }
  }

  return (
    <FlexBox
      centerAll
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100%;
        margin: 120px auto 100px auto;
        padding: 0 20px;
        overflow-x: hidden;
      `}
    >
      {loading ? (
        renderLoader()
      ) : (
        <>
          {children}
          {!pathname.includes(routes.clubQuizResults) ? (
            <Button
              dataTestId="continue-button"
              className={css`
                height: 52px;
                width: fit-content;
                margin-top: 32px;
                a {
                  color: inherit;
                }
              `}
              onClick={handleClickButton}
              disabled={!allowNext}
            >
              <Link
                className={css`
                  display: flex;
                  align-items: center;
                  height: 100%;
                  width: 100%;
                  padding: 0 28px;
                `}
                to={{ pathname: nextRoute, search: nextUrlSearchParams }}
              >
                {pathname.includes(routes.clubQuizGenre)
                  ? 'Continue'
                  : 'Finish'}
              </Link>
            </Button>
          ) : null}
        </>
      )}
    </FlexBox>
  )
}

// Separated to faciliate mocking ClubQuizContaner.Provider in tests
export const ClubQuizContainer = (props: { children: ReactNode }) => (
  <ClubQuizContextProvider>
    <ClubQuiz {...props} />
  </ClubQuizContextProvider>
)
