import { Hyperlink, Text } from '@fable/components'

const SupportLink = (props: React.HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    <Text type="body" sizing="M" text="Any questions?" align="center" />
    <Text type="body" sizing="M" align="center">
      Visit our
      <Hyperlink href="https://help.fable.co/" newTab>
        &nbsp;Help &amp; Support&nbsp;
      </Hyperlink>
      center
    </Text>
  </div>
)

export default SupportLink
