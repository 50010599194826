import { IconSVGProps } from '../types'

export const FacebookIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.5625 20V12.9694H13.8926L14.3359 10.0611H11.5625V8.17387C11.5625 7.37822 11.95 6.60261 13.1922 6.60261H14.4531V4.12663C14.4531 4.12663 13.3088 3.93012 12.2146 3.93012C9.93047 3.93012 8.4375 5.32296 8.4375 7.84452V10.0611H5.89844V12.9694H8.4375V20C3.65684 19.2452 0 15.0828 0 10.0611C0 4.50451 4.47715 0 10 0C15.5229 0 20 4.50451 20 10.0611C20 15.0828 16.3432 19.2452 11.5625 20Z" />
  </svg>
)
