import { ENVIRONMENT } from '../../constants'
import keyBy from 'lodash/keyBy'
import { Club } from '@fable/types'
import monthlyPlanImage from '../../assets/images/graphics/monthly-plan.svg'
import yearlyPlanImage from '../../assets/images/graphics/yearly-plan.svg'
import freeTrialImage from '../../assets/images/graphics/free-trial-plan.svg'
import { PlanOptions } from '.'
import { PlanDetails } from '../../types'

export const ALL_ACCESS_PLAN_YEARLY: PlanDetails = {
  id:
    ENVIRONMENT === 'production'
      ? 'price_1Im41kECzSVzFPPcnubpbt2j'
      : 'price_1Ij2PJECzSVzFPPcN3un9f7J',
  image: yearlyPlanImage,
  description: 'Access unlimited premium book clubs. Billed annually.',
  unit_amount: 6999,
  amount: 69.99,
  title: 'Fable Premium: All Clubs',
  type: 'recurring',
  recurring: {
    interval: 'year',
    interval_count: 12,
  },
  tag: 'Best Value',
}

export const ALL_ACCESS_PLAN_MONTHLY: PlanDetails = {
  id:
    ENVIRONMENT === 'production'
      ? 'price_1Im41kECzSVzFPPcHbGnpqvB'
      : 'price_1Ij2PJECzSVzFPPcxOo3Vv5i',
  image: monthlyPlanImage,
  description: 'Billed every 4 weeks. Cancel anytime.',
  unit_amount: 999,
  amount: 9.99,
  title: 'Fable Premium: All Clubs',
  type: 'recurring',
  recurring: {
    interval: 'month',
    interval_count: 1,
  },
}

const premiumFreeTrialId = 'premium-free-trial'

export const PREMIUM_PLAN_FREE_TRIAL: PlanDetails = {
  id: premiumFreeTrialId,
  image: freeTrialImage,
  title: 'Fable Premium: 14 Day Free Trial',
  subtitle: 'No credit card required',
  description: 'Includes access to all premium book clubs.',
  unit_amount: 0,
  amount: 0,
  type: 'one_time',
  recurring: {
    interval: 'year',
    interval_count: 12,
  },
}

export const isFreeTrial = (id: string | undefined) =>
  !!id && id === premiumFreeTrialId

export const ALL_ACCESS_PLANS: PlanDetails[] = [
  ALL_ACCESS_PLAN_YEARLY,
  ALL_ACCESS_PLAN_MONTHLY,
  PREMIUM_PLAN_FREE_TRIAL,
]

export const CLUB_PLAN_DETAILS = {
  image: monthlyPlanImage,
  description: (name: string) => `Access to ${name}. Billed monthly.`,
  title: 'Fable Premium: Single Club',
}

export const PLANS_BY_ID = keyBy(ALL_ACCESS_PLANS, (plan) => plan.id)
export const PLANS_BY_TYPE = keyBy(ALL_ACCESS_PLANS, (plan) => plan.type)

export const DEFAULT_PLAN_VALUES: PlanOptions = {
  plan: ALL_ACCESS_PLAN_YEARLY,
  isBookSelected: true,
}

export const getPlansList = ({
  club,
  freeTrialUsed = false,
}: {
  club: Club
  freeTrialUsed?: boolean
}) => {
  const freeTrialPlan: PlanDetails = {
    ...PREMIUM_PLAN_FREE_TRIAL,
    club,
  }

  const clubPlan: PlanDetails = {
    ...club.price,
    ...CLUB_PLAN_DETAILS,
    description: CLUB_PLAN_DETAILS.description(club?.title as string),
  }

  const plans = [ALL_ACCESS_PLAN_YEARLY, clubPlan]

  if (!freeTrialUsed) {
    plans.unshift(freeTrialPlan)
  }

  return plans
}
