import { routes } from '../../app/internal/routes'
import { lazyWithRetry } from '../../utils'
import { pageMetadata } from '../pageMetadata'
import { AppRouteProps } from '../types'
import BookReviewLinkUnfurl from '../../app/internal/link_unfurling/book_review/BookReviewLinkUnfurl'
import GenresLinkUnfurl from '../../app/internal/link_unfurling/GenresLinkUnfurl'

const SmclLandingPage = lazyWithRetry(
  () => import('app/smcl/pages/SmclLandingPage')
)

const GoodreadsImport = lazyWithRetry(
  () => import('../../app/internal/goodreads_import/GoodreadsImport')
)

export const internalRoutes: AppRouteProps[] = [
  {
    path: routes.goodreadsImport,
    element: <GoodreadsImport />,
    guarded: true,
    head: pageMetadata.goodreadsImport,
  },
  {
    path: `${routes.review}/:id/image`,
    element: <BookReviewLinkUnfurl />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  {
    path: `${routes.genres}/:slug/image`,
    element: <GenresLinkUnfurl />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  // The routes below all go to SmclLandingPage.tsx which has its own Context API (SmclContext)
  // which provides copy, club ID and invite ID for each club based on its pathname
  {
    path: '/san-mateo-county-libraries',
    element: <SmclLandingPage />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  {
    path: '/carlmont-freshman-summer-reading',
    element: <SmclLandingPage />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  {
    path: '/carlmont-sophomore-summer-reading',
    element: <SmclLandingPage />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  {
    path: '/carlmont-junior-summer-reading',
    element: <SmclLandingPage />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
  {
    path: '/carlmont-senior-summer-reading',
    element: <SmclLandingPage />,
    footer: { hidden: true },
    header: {
      hidden: true,
    },
  },
]
