import { css, cx, useTheme } from '@fable/theme'
import { FlexBox } from '@fable/components'
import { useTypedSelector } from '../../hooks'

const Toast = () => {
  const { colors, motion, mediaQueries } = useTheme()
  const {
    toastOptions: { showToast, backgroundColor, content },
  } = useTypedSelector(({ toast }) => toast)

  return (
    <FlexBox
      data-testid="toast"
      alignItems="center"
      className={cx(
        css`
          position: fixed;
          top: -132px;
          width: 90%;
          opacity: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 999999;
          padding: 8px 14px;
          min-height: 52px;
          background: ${backgroundColor || colors.shamrock};
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          color: ${colors.whiteFang};
          ${motion.transition()};
          ${mediaQueries.mobile} {
            max-width: fit-content;
          }
        `,
        showToast &&
          css`
            top: 80px;
            opacity: 1;
          `
      )}
    >
      {content}
    </FlexBox>
  )
}

export default Toast
