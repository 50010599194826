import { IconSVGProps } from '../types'

export const InstagramIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2988 0.0502569C9.82682 -0.0184086 6.18082 -0.0150753 4.70749 0.0502569C3.41282 0.110922 2.27082 0.423584 1.35015 1.34423C-0.0267216 2.72108 -0.0140208 4.52606 0.00387418 7.06919C0.00597699 7.36803 0.00815151 7.67706 0.00815151 7.99678C0.00815151 8.29603 0.00654877 8.58522 0.00499887 8.86488C-0.00956927 11.4935 -0.0194687 13.2797 1.35015 14.6493C2.72955 16.0281 4.55446 16.0145 7.0689 15.9959C7.37017 15.9936 7.68133 15.9913 8.00282 15.9913H8.04624C11.0955 15.9913 12.1546 15.9913 13.2395 15.5713C14.7215 14.996 15.8402 13.6714 15.9495 11.2921C16.0188 9.81942 16.0148 6.17415 15.9495 4.70084C15.8175 1.89222 14.3102 0.188921 11.2988 0.0502569ZM13.6288 13.6314C12.6999 14.5603 11.4396 14.5574 8.71749 14.5511C8.48383 14.5506 8.2394 14.55 7.98349 14.55L7.52561 14.5502C4.52504 14.5524 3.26755 14.5533 2.33815 13.6214C1.37126 12.6591 1.38725 11.195 1.41045 9.07009V9.07008C1.41421 8.72628 1.41815 8.36518 1.41815 7.98612C1.41815 7.66466 1.41527 7.35105 1.41247 7.04624C1.38321 3.85859 1.36278 1.6337 4.73682 1.4609L4.77508 1.45955C5.59417 1.4306 5.86859 1.4209 7.97415 1.4209L8.00415 1.4409C8.30857 1.4409 8.60736 1.43817 8.89938 1.4355C12.0153 1.40702 14.3585 1.3856 14.5115 4.76484C14.5495 5.60816 14.5582 5.86149 14.5582 7.99612C14.5581 8.18747 14.5583 8.37224 14.5585 8.55075L14.5585 8.55244C14.5612 11.4462 14.5624 12.6934 13.6288 13.6314ZM8.00276 3.89234C5.73408 3.89234 3.89741 5.73097 3.89741 7.9976C3.89741 10.2662 5.73608 12.1029 8.00276 12.1029C10.2714 12.1029 12.1081 10.2642 12.1081 7.9976C12.1081 5.72897 10.2694 3.89234 8.00276 3.89234ZM8.00276 10.6622C6.53009 10.6622 5.33808 9.46957 5.33808 7.9976C5.33808 6.52562 6.53075 5.33298 8.00276 5.33298C9.47477 5.33298 10.6674 6.52562 10.6674 7.9976C10.6681 9.46957 9.47544 10.6622 8.00276 10.6622ZM13.2314 3.72881C13.2314 4.25863 12.8019 4.68813 12.2721 4.68813C11.7423 4.68813 11.3128 4.25863 11.3128 3.72881C11.3128 3.199 11.7423 2.7695 12.2721 2.7695C12.8019 2.7695 13.2314 3.199 13.2314 3.72881Z"
    />
  </svg>
)
