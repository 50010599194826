import { css, cx, useTheme } from '@fable/theme'
import { Link, useLocation } from 'react-router-dom'
import { FlexBox, Text } from '@fable/components'
import { ScrollProps } from '@fable/hooks'
import Dropdown from '../dropdown'
import { PAGE_LINKS } from './constants'
import { HeaderNavItem } from './types'
import { routes } from 'app/marketing/routes'
import { routes as storeRoutes } from 'app/store/routes'
import { useHeader, useVertExpEnabled } from 'hooks'

interface Props {
  scroll: ScrollProps
}

export const HeaderNavLeft: React.VFC<Props> = ({ scroll }) => {
  const { breakpoints, colors, motion, mediaQueries } = useTheme()
  const { pathname } = useLocation()
  const header = useHeader((s) => s.config)
  const secondary = header.theme === 'secondary' && !scroll.scrolled
  const vertExpEnabled = useVertExpEnabled()

  const joinLink = vertExpEnabled
    ? PAGE_LINKS.joinClub
    : PAGE_LINKS.joinBookClub
  const links = [joinLink, PAGE_LINKS.findBook]

  const getUnderlineColor = () => {
    switch (pathname) {
      case routes.clubsPage:
        return colors.uiBlueLight
      case storeRoutes.explore:
        return colors.uiGreen
      case routes.home:
        return vertExpEnabled ? 'transparent' : colors.uiBlueLight
      default:
        return 'transparent'
    }
  }

  return (
    <FlexBox
      className={cx(
        css`
          display: ${header.mobileSublinksHidden ? 'none' : 'flex'};
          padding: 0 50px;
          width: 100%;
          height: 42px;
          justify-content: center;
          background-color: ${scroll.scrolled || header.fillSpace
            ? colors.uiWhite
            : 'transparent'};
          position: absolute;
          left: 0;
          top: 59px;
          z-index: -1;
          overflow: visible;
          ${motion.transition(['height', 'background-color'])}

          .fds-ui--header-nav-text {
            ${motion.transition('opacity')}
            transition-delay: 0.2s;
          }

          ${mediaQueries.mobileXS} {
            padding: 0 40px;
          }

          ${mediaQueries.tablet} {
            display: flex;
            padding: 0;
            background-color: transparent;
            margin-left: 30px;
            width: auto;
            height: auto;
            justify-content: flex-start;
            position: relative;
            left: auto;
            top: auto;
            z-index: 0;
          }

          ${mediaQueries.desktop} {
            margin-left: 100px;
          }
        `,
        scroll.direction === 'down' &&
          css`
            @media only screen and (max-width: ${breakpoints.tablet}px) {
              height: 0;
              overflow: hidden;

              .fds-ui--header-nav-text {
                transition-delay: 0s;
                opacity: 0;
              }
            }
          `
      )}
    >
      {links.map((item: HeaderNavItem, i: number) => {
        const text = (
          <Link
            to={item.path}
            /**
             * @note
             * Temporarily hiding for cyber monday changes
             */
            // onClick={(e) => {
            //   const tabletQuery = window.matchMedia(
            //     `(max-width: ${breakpoints.tablet}px)`
            //   );

            //   if (item.subItems?.length && tabletQuery.matches) {
            //     e.preventDefault();
            //   }
            // }}
          >
            <Text
              key={i}
              className={cx(
                css`
                  display: block;
                  margin-top: 12px;
                  position: relative;
                  ${motion.transition('color')};

                  &:hover {
                    color: ${secondary
                      ? colors.whiteSmoke
                      : colors.blackSwanHover};
                  }

                  ${mediaQueries.tablet} {
                    margin-top: 0;
                  }
                `,
                'fds-ui--header-nav-text'
              )}
              type="link"
              sizing="L"
              color={secondary ? colors.uiPearlExtraLight : colors.uiGraphite}
            >
              {item.title}
              {pathname === item.path && (
                <span
                  className={css`
                    display: block;
                    border-radius: 10px;
                    background-color: ${getUnderlineColor()};
                    width: ${i === 0 ? 107 : 77}px;
                    height: 2px;
                    position: absolute;
                    bottom: -16px;
                    left: 50%;
                    transform: translateX(-50%);
                    ${mediaQueries.tablet} {
                      bottom: -23px;
                    }
                  `}
                />
              )}
            </Text>
          </Link>
        )

        return (
          <div
            key={i}
            className={css`
              width: 50%;
              text-align: center;

              &:not(:last-child) {
                margin-right: 40px;
              }

              ${mediaQueries.tablet} {
                width: auto;
                text-align: left;

                &:not(:last-child) {
                  margin-right: 24px;
                }
              }

              ${mediaQueries.desktop} {
                &:not(:last-child) {
                  margin-right: 40px;
                }
              }
            `}
          >
            {!item.subItems?.length ? (
              text
            ) : (
              <Dropdown
                className={css`
                  padding-top: 32px;

                  .fds-ui--dropdown-box-content {
                    width: 164px;
                    ${mediaQueries.mobileXS} {
                      width: 180px;
                    }
                  }
                `}
                items={item.subItems}
                customControl={text}
                hover
                centered
              />
            )}
          </div>
        )
      })}
    </FlexBox>
  )
}
