import { Button, FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Counters } from '@fable/types'
import { ShareOptions } from 'components/share_button/ShareOptions'
import { useActions } from 'hooks'
import { useLocation } from 'react-router-dom'
import { ActivityCounterButton } from './ActivityCounterButton'

interface Props {
  counters: Counters
  onClickLike?: () => void
  onClickComment?: () => void
}

export const ActivityFooter = ({
  counters,
  onClickLike,
  onClickComment,
}: Props) => {
  const { pathname, search } = useLocation()
  const { nativeOverrides } = useTheme()
  const { openModal, copyTextToClipboard } = useActions()
  const hostname = window.location.hostname
  const shareUrl = `${hostname}${pathname}${search}`

  const likes = counters.likes.toString()
  const comments = counters.comments.toString()

  return (
    <FlexBox justifyContent="space-between" alignItems="center">
      <FlexBox alignItems="center" gap="12px">
        <ActivityCounterButton
          icon="heart"
          count={likes}
          onClick={onClickLike}
        />
        <ActivityCounterButton
          icon="chat"
          count={comments}
          onClick={onClickComment}
        />
      </FlexBox>
      <Button
        className={css`
          ${nativeOverrides.buttonClear};
          .fds-ui--icon {
            display: block;
            width: 24px;
          }
        `}
        icon="share"
        negative
        onClick={() => {
          openModal(
            <ShareOptions
              category="post"
              onCopyToClipboard={copyTextToClipboard}
              showGiftBookButton={false}
              url={shareUrl}
              hideTitle
            />
          )
        }}
      />
    </FlexBox>
  )
}
