import routes from '../../app/global/routes'
import { lazyWithRetry } from '../../utils'
import { AppRouteProps } from '../types'

const SearchResultsPage = lazyWithRetry(
  () => import('app/global/pages/SearchResultsPage')
)

export const globalRoutes: AppRouteProps[] = [
  {
    path: routes.searchResults,
    element: <SearchResultsPage />,
    footer: { hidden: true },
  },
]
