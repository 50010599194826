import { marginEdgesRules, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import AccountSettings from '../AccountSettings'
import { InviteReferralCard } from './InviteReferralCard'
import SupportLink from '../../../../../components/support_link/SupportLink'
import { InviteReferralCredit } from './InviteReferralCredit'
import { TITLES } from '../../../../../constants'

const InviteReferralPage = () => {
  const { colors, mediaQueries } = useTheme()
  const pageTitle = TITLES.store.invite

  return (
    <AccountSettings
      className={css`
        padding-bottom: 0;
      `}
      pageTitle={pageTitle}
    >
      <div className="wrap masthead small">
        <Text
          className={css`
            padding: 0 16px;
          `}
          type="header"
          sizing={{ base: 'S', tablet: 'M', desktop: 'XL' }}
          color={colors.pinkMoon}
          align="center"
        >
          Invite Friends &amp; <br />
          Manage Credits
        </Text>
      </div>
      <div
        className={css`
          padding: 28px 24px 40px;
          background-color: ${colors.pinkMoonLight};
          ${mediaQueries.mobileL} {
            padding-top: 60px;
          }
          ${mediaQueries.tablet} {
            border-radius: 16px;
            margin: 32px ${marginEdgesRules.tablet} 0;
          }
          ${mediaQueries.desktop} {
            margin: 32px ${marginEdgesRules.desktop} 0;
          }
        `}
      >
        <div
          className={css`
            margin: 0 auto;
            max-width: 414px;
          `}
        >
          <InviteReferralCard />
          <InviteReferralCredit />
          <SupportLink
            className={css`
              margin-top: 24px;
            `}
          />
        </div>
      </div>
    </AccountSettings>
  )
}

export default InviteReferralPage
