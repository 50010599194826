import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { get } from '@fable/api'
import { PaginationContainerOptions } from './types'

const usePaginationResults = (options: PaginationContainerOptions) => {
  const [error, setError] = useState<any>()
  const { queryArgs, endpoint, limit } = options

  const query = useInfiniteQuery(
    queryArgs || 'query',
    ({ pageParam }) =>
      get(`${endpoint}/?limit=${limit}&offset=${pageParam || 0}`),
    {
      enabled: !!queryArgs && !!endpoint && !!limit,
      retry: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage: {
        data: { next: string; results?: any[] }
      }) => new URLSearchParams(lastPage.data.next).get('offset'),
      onError: (err) => setError(err),
    }
  )

  const list = query.data?.pages?.flatMap(
    // Shape of returned
    (page) => page.data.results || page.data
  )

  return { list, query, queryArgs, endpoint, limit, error }
}

export default usePaginationResults
