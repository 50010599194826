export const formatGenreParam = (genreName: string) => {
  const name = genreName.toLowerCase()

  if (genreName === 'LGBTQ+') {
    return name.replace('+', '-plus')
  }

  return name
    .replace('&', 'and')
    .replace(/ +/g, '-')
    .replace(/[^a-zA-Z-]/g, '')
}
