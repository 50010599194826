import { Subscription } from '@fable/types'
import differenceInDays from 'date-fns/differenceInDays'
import isPast from 'date-fns/isPast'
import find from 'lodash/find'
import { FreeTrialSubscription, FreeTrialStatus } from '.'

export const clubAuthKey = 'club_auth_key'
export const clubPromoCode = 'club_promo_code'

export const getFreeTrialFromSubscriptions = (subscriptions: Subscription[]) =>
  find(subscriptions, (subscription) => {
    const diff = differenceInDays(
      new Date(subscription.current_period_end),
      new Date(subscription.current_period_start)
    )
    return diff === 14
  })

export const getFreeTrialStatus = (
  freeTrial: FreeTrialSubscription
): FreeTrialStatus => {
  if (freeTrial.free_trial_used) {
    if (freeTrial?.subscription?.status === 'trialing') return 'active'
    if (
      !freeTrial.subscription ||
      freeTrial?.subscription?.status === 'canceled' ||
      isPast(new Date(freeTrial?.subscription?.current_period_end as string))
    ) {
      return 'expired'
    }
  }

  return 'pending'
}
