import { FlexBox, Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { ArrowDownDoodle } from './doodles'
import { HomePopularClubsList } from 'app/marketing/components/home_clubs_list/HomePopularClubsList'
import { AppearInView } from './motion'

export const PopularClubsCarousel = () => {
  const { colors, mediaQueries } = useTheme()

  return (
    <AppearInView amount={0.5}>
      <FlexBox justifyContent="center">
        <div
          className={css`
            width: 100%;
            max-width: 1200px;
          `}
        >
          <HomePopularClubsList
            className={css`
              padding: 30px 24px 0;
              ${mediaQueries.tablet} {
                padding: 80px 24px;
              }
            `}
            header={
              <FlexBox
                className={css`
                  margin-bottom: 12px;
                  gap: 16px;
                  ${mediaQueries.tablet} {
                    margin-bottom: 30px;
                    gap: 24px;
                  }
                `}
                alignItems="center"
              >
                <Text
                  className={css`
                    font-size: 50px;
                    ${mediaQueries.tablet} {
                      font-size: 70px;
                    }
                  `}
                  type="note"
                  sizing="M"
                  color={colors.uiBlack}
                >
                  Popular clubs
                </Text>
                <ArrowDownDoodle
                  className={css`
                    position: relative;
                    top: 4px;
                    width: 24px;
                    ${mediaQueries.tablet} {
                      top: 6px;
                      width: 31px;
                    }
                  `}
                />
              </FlexBox>
            }
          />
        </div>
      </FlexBox>
    </AppearInView>
  )
}
