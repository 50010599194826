import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { cx, css, useTheme } from '@fable/theme'
import { Quote, Review } from '@fable/types'
import { FlexBox, Text, Hyperlink } from '@fable/components'
import { useActions, useTypedSelector } from '../../../../hooks'
import { fableApi } from '@fable/api'
import { getSlug } from '../../../../utils'
import appIcon from '../../../../assets/images/logo/app-icon.svg'
import { routes as marketingRoutes } from '../../../marketing/routes'
import Banner from '../../../../components/banner'
import { Page } from '../../../../components/Page'
import { HeadProps } from '../../../../components/head/Head'

export type QuoteReview = Quote | Review

export type ReviewPageProps = {
  apiBaseUrl: string
  getClassName: (item: QuoteReview) => string
  renderModal: (item: QuoteReview) => React.ReactElement
  getMetaData: (item: QuoteReview) => HeadProps | null
}

const ReviewPage = ({
  apiBaseUrl,
  renderModal,
  getMetaData,
  getClassName,
}: ReviewPageProps) => {
  const { mediaQueries } = useTheme()
  const location = useLocation()
  const itemId = getSlug(location.pathname)
  const { isOpen } = useTypedSelector(({ modal }) => modal)
  const { isAuthenticated } = useTypedSelector(({ auth }) => auth)
  const { closeModal, openModal } = useActions()
  const [showBanner, setShowBanner] = useState<undefined | boolean>(undefined)

  const itemQuery = useQuery(
    ['itemReview', apiBaseUrl, itemId],
    async () => await fableApi.get(`${apiBaseUrl}/${itemId}`)
  )

  const item = itemQuery?.data?.data

  useEffect(() => {
    if (showBanner === undefined && !isAuthenticated) {
      setShowBanner(true)
    } else if (showBanner !== false && isAuthenticated) {
      setShowBanner(false)
    }
  }, [isAuthenticated, showBanner])

  useEffect(
    () => () => {
      if (isOpen) {
        closeModal()
      }
    },
    [closeModal, isOpen]
  )

  useEffect(() => {
    if (!isOpen && itemQuery?.isSuccess) {
      openModal(
        renderModal(item),
        // Overwriting some styles here since this design calls for a modal without some of the existing behavior
        cx(
          'm-card',
          css`
            &.modal-container.default {
              z-index: 1;
              &.open {
                background: none;
              }
              .modal {
                margin-top: 60px;
                height: auto;
                > .close-modal {
                  display: none;
                }
                ${mediaQueries.tablet} {
                  margin-top: 0;
                }
              }
            }
          `
        ),
        true
      )
    }
  }, [
    isOpen,
    mediaQueries.tablet,
    openModal,
    item,
    itemQuery.isSuccess,
    renderModal,
  ])

  return (
    <Page
      head={getMetaData(item)}
      className={cx(
        css`
          width: 100vw;
          height: 100vh;
          > div {
            position: fixed;
            top: auto;
            bottom: 0;
            margin: unset;
          }
        `,
        getClassName(item)
      )}
    >
      <Banner
        onDismiss={() => setShowBanner(false)}
        show={showBanner}
        banner={{
          showDismissButton: 'Yes',
          desktopMessage: {
            component: (
              <FlexBox alignItems="center">
                <img
                  className={css`
                    width: 48px;
                    height: 48px;
                    margin-right: 12px;
                  `}
                  src={appIcon}
                  alt="Fable icon"
                />
                <Text type="body" sizing="L">
                  <b>Welcome to Fable!</b> Get started with Fable in seconds for
                  free,&nbsp;
                  <Hyperlink href={marketingRoutes.download}>
                    download the app.
                  </Hyperlink>
                </Text>
              </FlexBox>
            ),
          },
        }}
      />
    </Page>
  )
}

export default ReviewPage
