import './PageNotFound.scss'
import errorImage from '../../assets/images/graphics/404.webp'
import { Button, Text } from '@fable/components'
import { css } from '@fable/theme'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()
  const title = 'Oops!'
  const description = "The page you were looking for couldn't be found"
  const buttonText = 'Go back'

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="page-not-found">
      <div className="content fade">
        <div className="error-image">
          <img src={errorImage} alt="Page Not Found Error" />
        </div>
        <Text type="subhead" sizing={{ base: 'L', mobileL: 'XL' }}>
          {title}
        </Text>
        <Text
          className={css`
            margin: 12px auto;
            width: 240px;
          `}
          type="body"
          sizing="M"
        >
          {description}
        </Text>
        <Button onClick={goBack}>{buttonText}</Button>
      </div>
    </div>
  )
}

export default PageNotFound
