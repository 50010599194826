import { trackEvent } from 'segment_analytics/events'

const defaultEventProps = {
  platform: 'web',
}

const shelfieProps = {
  starting_from: 'web_shelfie',
  ...defaultEventProps,
}

const bookSelectionStart = () => {
  return trackEvent('shelfie_book_selection_start', defaultEventProps)
}

const bookSearchOpened = () => {
  return trackEvent('book_search_opened', shelfieProps)
}

const bookSearchDismissed = () => {
  return trackEvent('book_search_dismissed', shelfieProps)
}

const bookSearchTappedBook = () => {
  return trackEvent('book_search_tapped_book', shelfieProps)
}

const showSearchOpened = () => {
  return trackEvent('show_search_opened', shelfieProps)
}

const showSearchDismissed = () => {
  return trackEvent('show_search_dismissed', shelfieProps)
}

const showSearchTappedShow = () => {
  return trackEvent('show_search_tapped_show', shelfieProps)
}

const customize = () => {
  return trackEvent('shelfie_customize', defaultEventProps)
}

const exitWarning = () => {
  return trackEvent('shelfie_exit_warning', defaultEventProps)
}

const exitCanceled = () => {
  return trackEvent('shelfie_exit_canceled', defaultEventProps)
}

const closed = () => {
  return trackEvent('shelfie_closed', defaultEventProps)
}

const backButtonWarning = () => {
  return trackEvent('shelfie_back_button_warning', defaultEventProps)
}

const backButtonCanceled = () => {
  return trackEvent('shelfie_back_button_canceled', defaultEventProps)
}

const backButton = () => {
  return trackEvent('shelfie_back_button', defaultEventProps)
}

const objectCustomized = ({ ...props }: { shelfie_object: string }) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  return trackEvent('shelfie_object_customized', properties)
}

const bookCustomized = () => {
  return trackEvent('shelfie_book_customized', defaultEventProps)
}

export const shelfie = {
  bookSelectionStart,
  bookSearchOpened,
  bookSearchDismissed,
  bookSearchTappedBook,
  showSearchOpened,
  showSearchDismissed,
  showSearchTappedShow,
  customize,
  exitWarning,
  exitCanceled,
  closed,
  backButtonWarning,
  backButtonCanceled,
  backButton,
  objectCustomized,
  bookCustomized,
}
