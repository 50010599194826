// https://keen-slider.io/examples#auto-switch

import { KeenSliderPlugin } from 'keen-slider'

export const useAutoplay = ({
  duration = 2000,
}: {
  duration?: number
} = {}): KeenSliderPlugin => {
  return (slider) => {
    let timeout: NodeJS.Timeout | null = null
    let mouseOver = false
    function clearNextTimeout() {
      clearTimeout(timeout!)
    }
    function nextTimeout() {
      clearTimeout(timeout!)
      if (mouseOver) return
      timeout = setTimeout(() => {
        slider.next()
      }, duration)
    }
    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true
        clearNextTimeout()
      })
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false
        nextTimeout()
      })
      nextTimeout()
    })
    slider.on('dragStarted', clearNextTimeout)
    slider.on('animationEnded', nextTimeout)
    slider.on('updated', nextTimeout)
  }
}
