import { COUNTRY_CODES } from '@fable/utils'
import { gdprCountries } from 'services/onetrust/constants'
import { useTypedSelector } from './useTypedSelector'

const isGDPRCountry = (iso_code: string) => {
  const codes = COUNTRY_CODES.map(
    (o) => gdprCountries.includes(o.name) && o.code
  ).filter((o) => !!o)

  return codes.includes(iso_code)
}

export const useGeo = () => {
  const { geoIpCountry } = useTypedSelector(({ geoIp }) => geoIp)
  const geo = geoIpCountry.data
  const subdivision = !!geo?.subdivisions?.length ? geo?.subdivisions[0] : null
  const isUS = !geo?.country?.iso_code || geo?.country?.iso_code === 'US'
  const isCA = subdivision?.iso_code === 'CA'
  const gdprCountry = isGDPRCountry(geo?.country?.iso_code)

  return { isUS, isCA, subdivision, gdprCountry, geo }
}
