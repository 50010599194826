import { css } from '@fable/theme'
import { Logo, Text, TextInput } from '@fable/components'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { getErrorMessage } from '../../utils'
import { useActions } from '../../../../hooks/useActions'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import {
  EMPTY_EMAIL_TEXT,
  RESET_PASSWORD_HEADER_TEXT,
  USER_NOT_FOUND,
} from '../../../../constants'
import FormErrorMessage from '../../../../components/form_error_message/FormErrorMessage'
import { Button, FlexBox } from '@fable/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { forgotPasswordValidationSchema } from 'utils'

interface FormInput {
  email: string
}

export const ForgotPassword = ({
  onCancel,
  emailAddress,
}: {
  onCancel: () => void
  emailAddress?: string
}) => {
  const registered = useRef(false)
  const [apiError, setApiError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(EMPTY_EMAIL_TEXT)
  const [formSuccess, setFormSuccess] = useState({
    isSuccess: false,
    email: '',
  })
  const { clearSendResetPasswordEmail, sendResetPasswordEmail } = useActions()
  const { resetPasswordEmail } = useTypedSelector(({ auth }) => auth)

  const resolver = yupResolver(forgotPasswordValidationSchema)

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm<FormInput>({
    resolver,
    defaultValues: { email: emailAddress || '' },
  })

  const handleChange = (key: keyof FormInput, value: string) => {
    clearServerError()
    setValue(key, value)
  }

  const email = watch('email')

  useEffect(() => {
    if (registered.current) return

    registered.current = true
    register('email')
  }, [register])

  // get reset password response
  useEffect(() => {
    if (!!resetPasswordEmail.data && resetPasswordEmail.data.isSent) {
      const { email } = getValues()
      setFormSuccess({ isSuccess: true, email })
    } else if (!!resetPasswordEmail.error) {
      setApiError(true)
      setErrorMessage(USER_NOT_FOUND)
    }
  }, [getValues, resetPasswordEmail.error, resetPasswordEmail.data])

  const onResetPassword: SubmitHandler<FormInput> = (values: FormInput) => {
    const { email } = values

    sendResetPasswordEmail(email)
    setApiError(false)
  }

  const backToLogin = () => {
    setApiError(false)
    clearSendResetPasswordEmail()
    onCancel()
  }

  const renderErrorMessage = () => {
    let message

    if (apiError) {
      message = errorMessage
    } else {
      message = getErrorMessage(errors)
    }

    return message !== '' && <FormErrorMessage message={message} />
  }

  const clearServerError = () => {
    if (!apiError) return
    clearSendResetPasswordEmail()
    setApiError(false)
  }

  const renderForm = () => {
    return (
      <>
        <Logo isIcon={true} isLight={false} />
        <Text
          className={css`
            margin: 28px auto 0;
            width: 200px;
          `}
          type="header"
          sizing="S"
          text={RESET_PASSWORD_HEADER_TEXT}
        />
        <div
          className={css`
            margin: 20px 0 0;
          `}
        >
          <form onSubmit={handleSubmit(onResetPassword)}>
            {renderErrorMessage()}
            <TextInput
              containerClass={css`
                margin-bottom: 12px;
              `}
              error={!!errors.email}
              value={email}
              onChangeText={(text) => handleChange('email', text)}
              placeholder="Enter your email address"
              type="email"
            />
            <Button width="100%" type="submit" label="Reset Password" />
          </form>
          <Button
            width="100%"
            className={css`
              margin-top: 12px;
            `}
            negative
            transparent
            onClick={backToLogin}
            label="Cancel"
          />
        </div>
      </>
    )
  }

  const renderFormSuccess = () => {
    const { email } = formSuccess

    return (
      <FlexBox flexDirection="column" centerAll>
        <Text
          className={css`
            margin: 80px 0;
          `}
          type="body"
          sizing="L"
        >
          An email has been sent to <b>{email}</b> with instructions on
          resetting your password. If it doesn't arrive, be sure to check your
          spam folder.
        </Text>
        <Button
          width="100%"
          negative
          transparent
          onClick={backToLogin}
          label="Back to Log in"
        />
      </FlexBox>
    )
  }

  return (
    <div
      className={css`
        text-align: center;
      `}
    >
      {formSuccess.isSuccess ? renderFormSuccess() : renderForm()}
    </div>
  )
}
