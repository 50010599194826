/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { AuthorActions } from '../actions'
import { AuthorTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get } from '@fable/api'

type DispatchType = Dispatch<AuthorActions>

// GET AUTHOR DETAILS
export const getAuthorDetails =
  (slug: string) => async (dispatch: DispatchType) => {
    dispatch(setAction(AuthorTypes.GET_AUTHOR_DETAILS__REQUEST))
    if (!slug) {
      const emptyParams = { id: '', name: '', biography: '', books: [] }
      dispatch(setAction(AuthorTypes.GET_AUTHOR_DETAILS__SUCCESS, emptyParams))
      return
    }

    try {
      const url = `/authors/${slug}/`
      const { data } = await get(url)

      dispatch(setAction(AuthorTypes.GET_AUTHOR_DETAILS__SUCCESS, data))
    } catch (error) {
      dispatch(setAction(AuthorTypes.GET_AUTHOR_DETAILS__ERROR, error))
    }
  }

// GET AUTHOR DETAILS - CLEAR
export const clearAuthorDetails = () => (dispatch: DispatchType) => {
  dispatch(setAction(AuthorTypes.GET_AUTHOR_DETAILS__CLEAR))
}
