import { RefObject, useEffect } from 'react'

interface Props {
  ref: RefObject<HTMLElement>
  id: string
  trigger?: boolean
}

export const useAnchorScroll = ({ ref, id, trigger }: Props) => {
  useEffect(() => {
    const hash = window.location.hash.substring(1)
    if (!hash || hash !== id || !ref.current) return

    const timeout = setTimeout(() => {
      const offsetTop = ref.current?.offsetTop
      if (!offsetTop || offsetTop === 0) return

      // Scroll will happen when:
      // - No trigger has been set
      // - Trigger has been set and its value is true
      const scroll = trigger || trigger === undefined

      if (scroll) {
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [ref, id, trigger])
}
