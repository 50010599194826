import { FlexBox, Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { HomeBlock } from '../HomeBlock'
import { useScroll, useTransform, motion } from 'framer-motion'
import { Ref, RefObject } from 'react'
import { HomeScrollingCards } from '../../lib/types'

interface Props {
  containerRef: Ref<HTMLDivElement>
  reverse?: boolean
  cards: HomeScrollingCards[]
}

export const HomeFeaturesScrollingCards = ({
  containerRef,
  reverse = false,
  cards,
}: Props) => {
  const { mediaQueries } = useTheme()

  const firstCard = cards[0]
  const secondCard = cards[1]

  const { scrollYProgress } = useScroll({
    target: containerRef as RefObject<HTMLDivElement>,
    offset: ['start end', 'center start'],
  })

  const firstCardPos = firstCard.position || { start: 200, end: 0 }
  const secondCardPos = secondCard.position || { start: 400, end: -100 }

  const y1 = useTransform(
    scrollYProgress,
    [0, 1],
    [firstCardPos.start, firstCardPos.end]
  )
  const y2 = useTransform(
    scrollYProgress,
    [0, 1],
    [secondCardPos.start, secondCardPos.end]
  )

  return (
    <FlexBox
      className={css`
        display: none;
        ${mediaQueries.tablet} {
          display: flex;
          margin-top: 64px;
          gap: 24px;
          align-items: flex-end;
          flex-direction: ${reverse ? 'row-reverse' : 'row'};
        }
        ${mediaQueries.desktop} {
          gap: 40px;
        }
      `}
    >
      <motion.div
        className={css`
          width: 50%;
        `}
        style={{ y: y1 }}
      >
        <HomeBlock
          className={cx(
            css`
              width: 100%;
            `,
            firstCard.containerStyles
          )}
        >
          <Text
            className={cx(
              css`
                display: block;
                padding: 8px 0 24px;
                font-size: 50px;
                width: 100%;
                max-width: 170px;
              `,
              firstCard.titleStyles
            )}
            type="note"
            sizing="M"
            align="center"
          >
            {firstCard.title}
          </Text>
          <img
            className={cx(
              css`
                display: block;
                width: 100%;
              `,
              firstCard.imageStyles
            )}
            src={firstCard.image}
            alt={firstCard.altText}
          />
        </HomeBlock>
      </motion.div>
      <motion.div
        className={css`
          width: 50%;
        `}
        style={{ y: y2 }}
      >
        <HomeBlock
          className={cx(
            css`
              width: 100%;
              position: relative;
              bottom: -24px;
            `,
            secondCard.containerStyles
          )}
        >
          <Text
            className={cx(
              css`
                display: block;
                padding: 8px 0 28px;
                font-size: 50px;
                width: 100%;
                max-width: 170px;
              `,
              secondCard.titleStyles
            )}
            type="note"
            sizing="M"
            align="center"
          >
            {secondCard.title}
          </Text>
          <img
            className={cx(
              css`
                display: block;
                width: 100%;
              `,
              secondCard.imageStyles
            )}
            src={secondCard.image}
            alt={secondCard.altText}
          />
        </HomeBlock>
      </motion.div>
    </FlexBox>
  )
}
