import {
  Button,
  Circle,
  FlexBox,
  Icon,
  IconTypes,
  Text,
} from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { useState } from 'react'

interface Props extends React.HTMLProps<HTMLDivElement> {
  text: string
  persist?: boolean
  backgroundColor?: string
  icon?: IconTypes
}

export const PageDetailBanner = ({
  text,
  persist = false,
  backgroundColor,
  icon = 'info-reverse',
  ...nativeProps
}: Props) => {
  const { colors } = useTheme()
  const [hide, setHide] = useState(false)
  const bgColor = backgroundColor || colors.silverGreyLight

  if (hide) return null

  return (
    <div {...nativeProps}>
      <FlexBox
        centerAll
        className={css`
          height: 56px;
          background: ${bgColor};
          padding: 0 20px;
        `}
        gap="8px"
      >
        <Circle
          diameter="32px"
          className={css`
            flex: 0 0 auto;
            background: ${colors.uiWhite};
            padding: 7.5px;
          `}
        >
          <Icon type={icon} />
        </Circle>
        <Text
          type="label"
          sizing="L"
          text={text}
          className={css`
            font-weight: 500;
          `}
        />
        <div
          className={css`
            width: 42px;
          `}
        />
        {persist ? null : (
          <Button
            className={css`
              position: absolute;
              right: 20px;
              width: 32px;
              height: 32px;
              svg {
                width: 15px;
              }
            `}
            transparent
            onClick={() => setHide(true)}
          >
            <Icon type="close" />
          </Button>
        )}
      </FlexBox>
    </div>
  )
}
