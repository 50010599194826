import { IconSVGProps } from '../types'

export const PinIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M22.3137 10.172L20.8987 11.586L20.1917 10.879L15.9497 15.121L15.2427 18.657L13.8277 20.071L9.58568 15.828L4.63568 20.778L3.22168 19.364L8.17168 14.414L3.92868 10.172L5.34268 8.75704L8.87968 8.05004L13.1217 3.80804L12.4147 3.10104L13.8287 1.68604L22.3137 10.172Z" />
  </svg>
)
