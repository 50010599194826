import { useState } from 'react'
import { KeenSliderInstance } from 'keen-slider/react'
import { CarouselController } from './types'

export const useCarouselController = (): CarouselController => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [instance, setInstance] = useState<KeenSliderInstance | null>(null)
  const [allSlidesInView, setAllSlidesInView] = useState(false)

  return {
    instance,
    currentSlide,
    setCurrentSlide,
    setInstance,
    allSlidesInView,
    setAllSlidesInView,
  }
}
