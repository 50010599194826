/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'

import { SearchActions } from '../actions'
import { SearchTypes } from '../action-types'
import { setAction } from '../stateHandlers'
import { get } from '@fable/api'

type DispatchType = Dispatch<SearchActions>

// SEARCH BOOKS
export const searchBooks = (term: string) => async (dispatch: DispatchType) => {
  dispatch(setAction(SearchTypes.SEARCH_BOOKS__REQUEST))
  try {
    const url = '/books/search/'
    const params = {
      params: {
        auto: term,
      },
    }
    const { data } = await get(url, params)

    dispatch(setAction(SearchTypes.SEARCH_BOOKS__SUCCESS, data.response.books))
  } catch (error) {
    dispatch(setAction(SearchTypes.SEARCH_BOOKS__ERROR, error))
  }
}
