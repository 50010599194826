import { QUOTE_THEME } from '@fable/theme'
import { QuoteViewModal } from '@fable/components'
import { Quote } from '@fable/types'
import ReviewPage, { QuoteReview } from '../review_page/ReviewPage'
import { routes as marketingRoutes } from '../../../marketing/routes'
import { Colors, css, useTheme } from '@fable/theme'

const QuoteViewPage = () => {
  const { colors } = useTheme()

  return (
    <ReviewPage
      apiBaseUrl="/quotes"
      renderModal={(item: QuoteReview) => (
        <QuoteViewModal
          quote={item as Quote}
          downloadLink={marketingRoutes.download}
        />
      )}
      getMetaData={(item: QuoteReview) =>
        item && {
          title: `${item?.book?.authors?.[0].name} quote shared by ${item?.user?.display_name} on Fable`,
          description: `Read, discover, and discuss ${item?.book?.authors?.[0].name} books on Fable`,
          image: (item as Quote)?.image,
          noIndex: true,
        }
      }
      getClassName={(item: QuoteReview) => {
        const quoteThemeColors =
          ((item as Quote)?.theme && QUOTE_THEME[(item as Quote).theme]) ||
          QUOTE_THEME.theme1

        return css`
          background: ${colors[
            quoteThemeColors.backgroundColor as keyof Colors
          ]};
        `
      }}
    ></ReviewPage>
  )
}

export default QuoteViewPage
