import { css, cx, useTheme } from '@fable/theme'

const Toggle: React.FC<
  {
    onToggle: () => void
    name: string
    checked: boolean
    offIndicator?: React.ReactNode | React.ReactNode[]
    onIndicator?: React.ReactNode | React.ReactNode[]
  } & React.HTMLProps<HTMLDivElement>
> = ({
  onToggle,
  name,
  checked,
  offIndicator,
  onIndicator,
  ...nativeProps
}) => {
  const { colors, motion } = useTheme()

  const styles = {
    toggle: css`
      width: 48px;
      height: 28px;
      border-radius: 60px;
      border: 2px solid ${colors.hurricane};
      overflow: hidden;
      user-select: none;
    `,
    label: css`
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    `,
    input: css`
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .toggle-slider {
        left: 0;
      }
    `,
    slider: css`
      position: absolute;
      top: 0;
      left: -18px;
      width: 100%;
      height: 100%;
      padding: 0 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${motion.transition('left')};
    `,
    toggleIndicator: css`
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
    `,
    toggleKnob: css`
      flex: 1 0 auto;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background-color: ${checked ? colors.shamrock : colors.hurricane};
      margin: 0 3px;
    `,
  }

  /*   Class names provided hopefully to make it easier to override styles
  Example:
  <Toggle
    onToggle={() => setToggle(!toggle)}
    name="exampleToggle"
    checked={toggled}
    className={css`
      width: 70px;
      height: 40px;
      border-color: #ffd184;
      background: #266b8e;
      input:checked + .toggle-slider {
        left: -30px;
      }
      .toggle-knob {
        width: 25px;
        height: 25px;
        background: #ffd184;
      }
      .toggle-indicator-left,
      .toggle-indicator-right {
        width: 30px;
      }
    `}
  /> */

  return (
    <div {...nativeProps} className={cx(styles.toggle, nativeProps.className)}>
      <label className={styles.label}>
        <input
          className={styles.input}
          onChange={onToggle}
          type="checkbox"
          role="switch"
          name={name}
          checked={checked}
          data-testid="toggle"
        />
        <div className={cx(styles.slider, 'toggle-slider')}>
          <div className={cx(styles.toggleIndicator, 'toggle-indicator-left')}>
            {onIndicator}
          </div>
          <div className={cx(styles.toggleKnob, 'toggle-knob')} />
          <div className={cx(styles.toggleIndicator, 'toggle-indicator-right')}>
            {offIndicator}
          </div>
        </div>
      </label>
    </div>
  )
}

export default Toggle
