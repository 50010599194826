import { Routes, Route } from 'react-router-dom'
import { lazyWithRetry } from '../utils'
import PageNotFound from '../pages/404/PageNotFound'
import BuilderPage from '../app/marketing/builder_io'
import { AppRoute } from './AppRoute'
import { useRoutes } from './useRoutes'
import { AppRouteProps } from './types'

const Profile = lazyWithRetry(
  () => import('../app/social/pages/profile/Profile')
)

const AppRoutes = () => {
  const routes = useRoutes()

  return (
    <Routes>
      {routes.map((route: AppRouteProps, i: number) => (
        <Route key={i} {...route} element={<AppRoute {...route} />} />
      ))}
      <Route path="/p/*" element={<AppRoute element={<BuilderPage />} />} />
      {/* SOCIAL PROFILE (will redirect to 404 if user not found) */}
      <Route
        path="/404"
        element={
          <AppRoute
            element={<PageNotFound />}
            head={{ title: 'Page Not Found', httpCode: 404 }}
          />
        }
      />
      <Route path="*" element={<AppRoute element={<Profile />} />} />
    </Routes>
  )
}

export default AppRoutes
