import { css, useTheme } from '@fable/theme'
import { isCareerClub, isPremiumClub } from '../../../app/clubs/utils'
import { FlexBox, Icon } from '@fable/components'
import { Club } from '@fable/types'
import { PremiumClubTag } from 'components/PremiumClubTag'

export const ClubCardTag: React.VFC<{ club: Club }> = ({ club }) => {
  const { colors } = useTheme()
  const isPremium = isPremiumClub(club)
  const isPaid = isCareerClub(club)
  const backgroundColor = 'rgba(0, 0, 0, 0.5)'

  const tag = (
    <PremiumClubTag
      colors={{ background: backgroundColor, text: colors.whiteFang }}
    />
  )

  const icon = (
    <FlexBox
      className={css`
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background-color: ${backgroundColor};
      `}
      centerAll
    >
      <Icon type="dollar" fill={colors.whiteFang} />
    </FlexBox>
  )

  const renderContent = () => {
    if (isPremium) return tag
    if (isPaid) return icon
  }

  return (
    <div
      className={css`
        position: absolute;
        top: 16px;
        left: 24px;
        z-index: 10;
      `}
    >
      {renderContent()}
    </div>
  )
}
