// Modified via https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
import { lazy } from 'react'

/**
 * Usage:
 *
 * const SomeComponent = lazyWithRetry(() => import('../some_component/SomeComponent'))
 */
const lazyWithRetry = (importFn: any, retriesLeft = 5, interval = 1000) =>
  lazy(
    () =>
      new Promise((resolve, reject) => {
        let retries = retriesLeft

        const retry = () =>
          importFn()
            .then(resolve)
            .catch((error: Error) => {
              console.warn(
                `Lazy load component failed. Trying again. Tries left: ${retries}`
              )
              setTimeout(() => {
                if (retries === 1) {
                  reject(error)
                  return
                }
                retries -= 1
                retry()
              }, interval)
            })

        retry()
      })
  )

export default lazyWithRetry
