import { Button, FlexBox, Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import shapes from '../../lib/assets/home-hero-shapes.svg'
import { useQuery } from '@apollo/client'
import { CMS_GALLERY } from '../../lib/queries'
import { ScrollingBanner } from '../scrolling_banner'
import { CMSGallery, CompChildren } from '@fable/types'
import { getCmsAsset } from '@fable/utils'
import { useDeeplinkModal } from 'components/app_deep_link_modal'

export const HomeHero = ({
  className = '',
  title,
  description,
  label = '',
  imagesTopId,
  imagesBottomId,
  button,
}: {
  className?: string
  title: string
  description: string
  label?: string
  imagesTopId: string
  imagesBottomId: string
  button?: CompChildren
}) => {
  const { colors, mediaQueries } = useTheme()
  const { openDeeplinkModal } = useDeeplinkModal()
  const topGalleryQuery = useQuery(CMS_GALLERY, {
    variables: { id: imagesTopId },
  })
  const bottomGalleryQuery = useQuery(CMS_GALLERY, {
    variables: { id: imagesBottomId },
  })

  const galleryTop = topGalleryQuery?.data?.gallery as CMSGallery
  const imagesTop = galleryTop?.imagesCollection?.items || []
  const galleryBottom = bottomGalleryQuery?.data?.gallery as CMSGallery
  const imagesBottom = galleryBottom?.imagesCollection?.items || []
  const galleryLoading = topGalleryQuery.loading || bottomGalleryQuery.loading

  return (
    <FlexBox
      className={cx(
        css`
          background-color: ${colors.fableGreen};
          padding-bottom: 14px;
          ${mediaQueries.mobileL} {
            padding-bottom: 56px;
          }
        `,
        className
      )}
      flexDirection="column"
      alignItems="center"
    >
      <FlexBox
        className={css`
          padding: 60px 24px 220px;
          width: 100%;
          max-width: 1600px;
          position: relative;
          ${mediaQueries.mobileL} {
            padding-top: 130px;
            padding-bottom: 150px;
          }
        `}
        justifyContent="center"
      >
        <img
          className={css`
            display: block;
            width: 1600px;
            position: absolute;
            top: 0;
            left: 40%;
            transform: translateX(-50%);
            ${mediaQueries.mobileL} {
              left: 50%;
            }
          `}
          src={shapes}
          alt="hero background shapes"
        />
        <img
          className={css`
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            ${mediaQueries.mobileS} {
              width: 95%;
            }
            ${mediaQueries.mobileL} {
              width: 70%;
            }
            ${mediaQueries.tablet} {
              width: 65%;
            }
            ${mediaQueries.desktop} {
              margin-bottom: -20px;
              width: 100%;
              max-width: 900px;
            }
          `}
          src={getCmsAsset(
            '3lZNIBc8GqmRDrAd62AUZg/1de08f8924fbfd1b31708ad51d6aac06/person-with-books-on-phone.png',
            { width: 3000 }
          )}
          alt="person sitting by books and looking at phone"
        />
        <FlexBox
          className={css`
            width: 100%;
            max-width: 1200px;
            position: relative;
            z-index: 2;
          `}
        >
          <FlexBox
            className={cx(
              css`
                max-width: 320px;
                width: 100%;
                gap: 14px;
                ${mediaQueries.tablet} {
                  gap: 20px;
                  max-width: 500px;
                }
              `,
              !label?.length
                ? css`
                    padding-top: 20px;
                    ${mediaQueries.desktopL} {
                      padding-top: 100px;
                    }
                  `
                : ''
            )}
            flexDirection="column"
          >
            {label.length ? (
              <Text
                className={css`
                  font-size: 50px;
                  margin-bottom: -12px;
                  ${mediaQueries.tablet} {
                    font-size: 60px;
                  }
                `}
                type="note"
                color={colors.uiPearlExtraLight}
              >
                {label}
              </Text>
            ) : null}
            <Text
              type="header"
              weight="500"
              color={colors.uiPearlExtraLight}
              custom={{
                fontSize: '46px',
                lineHeight: '40px',
                [mediaQueries.tablet]: {
                  fontSize: '72px',
                  lineHeight: '62px',
                },
              }}
            >
              {title}
            </Text>
            <Text
              type="body"
              weight="500"
              color={colors.uiPearlExtraLight}
              custom={{
                fontSize: '18px',
                lineHeight: '24px',
                letterSpacing: '-0.3px',
                [mediaQueries.tablet]: {
                  fontSize: '24px',
                  lineHeight: '34px',
                },
              }}
            >
              {description}
            </Text>
            {button || (
              <Button
                className={css`
                  margin-top: 10px;
                  width: 100%;
                  max-width: 160px;
                  height: 52px;
                  ${mediaQueries.tablet} {
                    margin-top: 12px;
                  }
                `}
                onClick={() =>
                  openDeeplinkModal({ hideSMS: true, skipModalOnMobile: true })
                }
                negative
              >
                Get the app
              </Button>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <div
        className={css`
          margin-top: -2px;
          width: 100%;
          ${mediaQueries.mobileL} {
            margin-top: -4px;
          }
        `}
      >
        <ScrollingBanner
          images={imagesTop}
          imgSize={{
            height: { mobile: '158px', desktop: '225px' },
            width: { mobile: '107px', desktop: '151px' },
          }}
          loading={galleryLoading}
        />
        <ScrollingBanner
          className={css`
            margin-top: 10px;
            ${mediaQueries.mobileL} {
              margin-top: 24px;
            }

            .keen-slider__slide:nth-child(4) {
              min-width: 164px !important;
              max-width: 164px !important;
              ${mediaQueries.mobileL} {
                min-width: 252px !important;
                max-width: 252px !important;
              }
            }
            .keen-slider__slide:nth-child(8) {
              min-width: 196px !important;
              max-width: 196px !important;
              ${mediaQueries.mobileL} {
                min-width: 296px !important;
                max-width: 296px !important;
              }
            }
            .keen-slider__slide:nth-child(12) {
              min-width: 160px !important;
              max-width: 160px !important;
              ${mediaQueries.mobileL} {
                min-width: 240px !important;
                max-width: 240px !important;
              }
            }
          `}
          images={imagesBottom}
          imgSize={{
            width: { mobile: '130px', desktop: '200px' },
            height: { mobile: '195px', desktop: '300px' },
          }}
          loading={galleryLoading}
          reverse
        />
      </div>
    </FlexBox>
  )
}
