export enum AuthTypes {
  // APPLY ACTION CODE
  APPLY_ACTION_CODE__ERROR = 'APPLY_ACTION_CODE__ERROR',
  APPLY_ACTION_CODE__REQUEST = 'APPLY_ACTION_CODE__REQUEST',
  APPLY_ACTION_CODE__SUCCESS = 'APPLY_ACTION_CODE__SUCCESS',

  // AUTH
  AUTH__SUCCESS = 'AUTH__SUCCESS',
  AUTH__CLEAR = 'AUTH__CLEAR',

  // GET INVITE
  GET_INVITE__ERROR = 'GET_INVITE__ERROR',
  GET_INVITE__REQUEST = 'GET_INVITE__REQUEST',
  GET_INVITE__SUCCESS = 'GET_INVITE__SUCCESS',

  // POST_AUTH_CREATE_CLUB_MEMBERSHIP
  POST_AUTH_CREATE_CLUB_MEMBERSHIP = 'POST_AUTH_CREATE_CLUB_MEMBERSHIP',

  // POST_AUTH_FOLLOW_USER
  POST_AUTH_FOLLOW_USER = 'POST_AUTH_FOLLOW_USER',

  // POST_AUTH_PLAN_UPSELL
  POST_AUTH_PLAN_UPSELL = 'POST_AUTH_PLAN_UPSELL',

  // RESET PASSWORD
  RESET_PASSWORD__ERROR = 'RESET_PASSWORD__ERROR',
  RESET_PASSWORD__REQUEST = 'RESET_PASSWORD__REQUEST',
  RESET_PASSWORD__SUCCESS = 'RESET_PASSWORD__SUCCESS',

  // SEND RESET PASSWORD EMAIL
  SEND_RESET_PASSWORD_EMAIL__CLEAR = 'SEND_RESET_PASSWORD_EMAIL__CLEAR',
  SEND_RESET_PASSWORD_EMAIL__ERROR = 'SEND_RESET_PASSWORD_EMAIL__ERROR',
  SEND_RESET_PASSWORD_EMAIL__REQUEST = 'SEND_RESET_PASSWORD_EMAIL__REQUEST',
  SEND_RESET_PASSWORD_EMAIL__SUCCESS = 'SEND_RESET_PASSWORD_EMAIL__SUCCESS',

  // SIGN IN
  SIGNIN__CLEAR = 'SIGNIN__CLEAR',
  SIGNIN__ERROR = 'SIGNIN__ERROR',
  SIGNIN__REQUEST = 'SIGNIN__REQUEST',
  SIGNIN__SUCCESS = 'SIGNIN__SUCCESS',

  // SIGN OUT
  SIGNOUT__CLEAR = 'SIGNOUT__CLEAR',
  SIGNOUT__ERROR = 'SIGNOUT__ERROR',
  SIGNOUT__REQUEST = 'SIGNOUT__REQUEST',
  SIGNOUT__SUCCESS = 'SIGNOUT__SUCCESS',

  // SIGN UP
  SIGNUP__CLEAR = 'SIGNUP__CLEAR',
  SIGNUP__ERROR = 'SIGNUP__ERROR',
  SIGNUP__REQUEST = 'SIGNUP__REQUEST',
  SIGNUP__SUCCESS = 'SIGNUP__SUCCESS',

  // TOKEN
  TOKEN__ERROR = 'TOKEN__ERROR',
  TOKEN__REQUEST = 'TOKEN__REQUEST',
  TOKEN__SUCCESS = 'TOKEN__SUCCESS',

  // VERIFY ACTION CODE
  VERIFY_ACTION_CODE__ERROR = 'VERIFY_ACTION_CODE__ERROR',
  VERIFY_ACTION_CODE__REQUEST = 'VERIFY_ACTION_CODE__REQUEST',
  VERIFY_ACTION_CODE__SUCCESS = 'VERIFY_ACTION_CODE__SUCCESS',

  // VERIFY
  VERIFY_USER__REQUEST = 'VERIFY_USER__REQUEST',
  VERIFY_USER__SUCCESS = 'VERIFY_USER__SUCCESS',

  // UPDATE AUTH USER
  UPDATE_AUTH_USER = 'UPDATE_AUTH_USER',
}
