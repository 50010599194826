import { ACCOUNT_NAV_ITEMS } from '../constants'
import { useTypedSelector } from './useTypedSelector'
import { routes as storeRoutes } from '../app/store/routes'
import { useFeatureFlags } from './useFeatureFlags'

const filterNavItems = (user, chatFlag) =>
  ACCOUNT_NAV_ITEMS.filter((item) => {
    if (item.path === storeRoutes.yourClubs && !chatFlag) {
      return false
    }

    return true
  })

export default function useAccountNavItems() {
  const { user } = useTypedSelector(({ auth }) => auth)
  const flagEnabled = useFeatureFlags((s) => s.flagEnabled)
  const chatFlag = flagEnabled('chat')
  const navItems = filterNavItems(user, chatFlag)

  return navItems
}
