/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { BookListItem, User, Club } from '@fable/types'
import { Response } from '../../types'
import { SocialActions } from '../actions'
import { SocialTypes } from '../action-types'
import {
  setErrorState,
  setRequestState,
  setSuccessState,
  setClearState,
} from '../stateHandlers'

export enum SocialKeys {
  user = 'user',
  bookLists = 'bookLists',
  clubs = 'clubs',
  followUser = 'followUser',
  followers = 'followers',
  following = 'following',
}

export interface SocialState {
  [SocialKeys.user]: Response<User>
  [SocialKeys.bookLists]: Response<{ results: BookListItem[] }>
  [SocialKeys.clubs]: Response<Club[]>
  [SocialKeys.followUser]: Response
  [SocialKeys.followers]: Response
  [SocialKeys.following]: Response
}

const props: Response = {
  data: null,
  error: null,
  loading: false,
}

const initialState: SocialState = {
  [SocialKeys.user]: props,
  [SocialKeys.bookLists]: props,
  [SocialKeys.clubs]: props,
  [SocialKeys.followUser]: props,
  [SocialKeys.followers]: props,
  [SocialKeys.following]: props,
}

const reducer = (
  state: SocialState = initialState,
  action: SocialActions
): SocialState => {
  const stateProps = { state, props }

  switch (action.type) {
    // GET USER BY SLUG
    case SocialTypes.USER_LOOKUP__CLEAR:
      // Passing existing data so subsequent queries will replace existing data without clearing it first
      // This is useful for showing a loader in the "Following" button in ProfileActions.tsx
      return setClearState(stateProps, SocialKeys.user)
    case SocialTypes.USER_LOOKUP__REQUEST:
      // Passing existing data so subsequent queries will replace existing data without clearing it first
      // This is useful for showing a loader in the "Following" button in ProfileActions.tsx
      return setRequestState({ state, props: state.user }, SocialKeys.user)
    case SocialTypes.USER_LOOKUP__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.user, action.payload)
    case SocialTypes.USER_LOOKUP__ERROR:
      return setErrorState(stateProps, SocialKeys.user, action.payload)

    // GET BOOK LISTS
    case SocialTypes.GET_BOOK_LISTS__CLEAR:
      return setClearState(stateProps, SocialKeys.bookLists)
    case SocialTypes.GET_BOOK_LISTS__REQUEST:
      return setRequestState(stateProps, SocialKeys.bookLists)
    case SocialTypes.GET_BOOK_LISTS__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.bookLists, action.payload)
    case SocialTypes.GET_BOOK_LISTS__ERROR:
      return setErrorState(stateProps, SocialKeys.bookLists, action.payload)

    // GET CLUBS
    case SocialTypes.GET_CLUBS__CLEAR:
      return setClearState(stateProps, SocialKeys.clubs)
    case SocialTypes.GET_CLUBS__REQUEST:
      return setRequestState(stateProps, SocialKeys.clubs)
    case SocialTypes.GET_CLUBS__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.clubs, action.payload)
    case SocialTypes.GET_CLUBS__ERROR:
      return setErrorState(stateProps, SocialKeys.clubs, action.payload)

    // POST FOLLOW USER
    case SocialTypes.POST_FOLLOW_USER__REQUEST:
      return setRequestState(stateProps, SocialKeys.followUser)
    case SocialTypes.POST_FOLLOW__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.followUser, action.payload)
    case SocialTypes.POST_FOLLOW_USER__ERROR:
      return setErrorState(stateProps, SocialKeys.followUser, action.payload)

    // DELETE FOLLOW USER
    case SocialTypes.DELETE_FOLLOW_USER__REQUEST:
      return setRequestState(stateProps, SocialKeys.followUser)
    case SocialTypes.DELETE_FOLLOW_USER__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.followUser, action.payload)
    case SocialTypes.DELETE_FOLLOW_USER__ERROR:
      return setErrorState(stateProps, SocialKeys.followUser, action.payload)

    // GET FOLLOWERS
    case SocialTypes.GET_FOLLOWERS__REQUEST:
      return setRequestState(stateProps, SocialKeys.followers)
    case SocialTypes.GET_FOLLOWERS__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.followers, action.payload)
    case SocialTypes.GET_FOLLOWERS__ERROR:
      return setErrorState(stateProps, SocialKeys.followers, action.payload)

    // GET FOLLOWING
    case SocialTypes.GET_FOLLOWING__REQUEST:
      return setRequestState(stateProps, SocialKeys.following)
    case SocialTypes.GET_FOLLOWING__SUCCESS:
      return setSuccessState(stateProps, SocialKeys.following, action.payload)
    case SocialTypes.GET_FOLLOWING__ERROR:
      return setErrorState(stateProps, SocialKeys.following, action.payload)

    default:
      return state
  }
}

export default reducer
