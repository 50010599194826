import { builder } from '@builder.io/react'
import { BuilderProps } from './types'
import { useQuery } from 'react-query'

const getBuilderContent = async ({ model, options }: BuilderProps) =>
  await builder.get(model, options).promise()

export const useBuilder = (props: BuilderProps) => {
  const builderQuery = useQuery([`builder-${props.model}`, props], () =>
    getBuilderContent(props)
  )

  return builderQuery
}
