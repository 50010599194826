import './MailingListForm.scss'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { css, cx } from '@fable/theme'

import Field from '../field/Field'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Color } from '@fable/legacy'
import { validateEmail } from '../../utils/validations'

const MailingListForm = ({ isNoteActive, isDark }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const { newsletterSubscribe } = useActions()
  const { newsletter } = useTypedSelector(({ user }) => user)

  useEffect(() => {
    if (newsletter.error) {
      setError(newsletter.error.message)
    }
  }, [newsletter.error])

  const note =
    "By clicking Send, you'll receive occasional emails from Fable. You always have the choice to unsubscribe within every email you receive."

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!isEmpty(email) && validateEmail(email)) {
      newsletterSubscribe(email)
      setEmail('')
    } else {
      setError('Please enter a valid email address')
    }
  }

  return (
    <form
      className="mailing-list-form"
      method="post"
      target="_blank"
      onSubmit={handleSubmit}
    >
      <Field
        fieldClass="error"
        hasSubmit={true}
        isDark={isDark}
        name="EMAIL"
        onChange={(e) => {
          setEmail(e.target.value)
          if (error) setError(null)
        }}
        placeholder="Enter your email"
        required={true}
        type="email"
        value={email}
      />
      {error && <label className={cx(styles.error)}>{error}</label>}
      {isNoteActive && <label className="note">{note}</label>}
    </form>
  )
}

export default MailingListForm

const styles = {
  error: css`
    margin-top: 8px;
    display: block;
    color: ${Color.mahogany};
  `,
}
