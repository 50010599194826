import { isBefore } from 'date-fns'
import { PatternType } from '../../components/pattern/PatternProps'
import { theme } from '@fable/theme'
import { Bundle, Club, ClubMembership, ClubType } from '@fable/types'
import { PremiumClubColor, PremiumClubPattern } from 'types'
import { clubPatterns } from './constants'
import { ClubPatternColor } from './types'
import { getParameterByName, getSlug } from 'utils'

export const getTheme = (theme: string) => {
  const themeColors = {
    pinkMoon: {
      light: '228,173,187', // #E4ADBB
      dark: '112,84,91', // #70545B
    },
    saffron: {
      light: '244,198,53', // #F4C635
      dark: '143,115,26', // #8F731A
    },
    mint: {
      light: '154,226,191', // #9AE2BF
      dark: '76,113,95', // #4C715F
    },
    sky: {
      light: '115,213,255', // #73D5FF
      dark: '39,76,91', // #274C5B
    },
    mayaBlue: {
      light: '#73D5FF',
      dark: '#73D5FF',
    },
  }

  let colors, pattern

  switch (theme) {
    case 'theme1':
      colors = themeColors.pinkMoon
      pattern = PatternType.poly
      break
    case 'theme2':
      colors = themeColors.saffron
      pattern = PatternType.stripes
      break
    case 'theme3':
      colors = themeColors.mint
      pattern = PatternType.curve
      break
    case 'theme4':
      colors = themeColors.sky
      pattern = PatternType.wave
      break
    case 'theme5':
      colors = themeColors.mayaBlue
      pattern = PatternType.poly
      break
    default:
      colors = themeColors.pinkMoon
      pattern = PatternType.curve
  }

  const background = `radial-gradient(104.45% 104.45% at 50.16% 37.04%, rgba(${colors.light}, 0.7) 28.82%, rgba(${colors.dark}, 0.7) 100%)`

  return {
    background,
    pattern,
    colors,
  }
}

// https://www.figma.com/file/Rd9HrBSvaaV0V2UZlYdURD/Home-Updates-Dec.2021?node-id=51%3A1855
export const getClubColors = (color: PremiumClubColor) => {
  const { colors } = theme
  let primary, secondary

  switch (color) {
    case 'Pink':
      primary = colors.pinkMoon
      secondary = colors.pinkMoonLight
      break
    case 'Yellow':
      primary = colors.warning
      secondary = colors.warningLight
      break
    case 'Green':
      primary = colors.shamrockMedium
      secondary = colors.shamrockLight
      break
    case 'Blue':
      primary = colors.mayaBlue
      secondary = colors.mayaBlueLight
      break
    default:
      primary = colors.pinkMoon
      secondary = colors.pinkMoonLight
  }

  return { primary, secondary }
}

export const getClubPattern = (
  pattern: PremiumClubPattern,
  color: PremiumClubColor
) => {
  const { laser, loop, ripple, stripes } = clubPatterns
  const colorKey = color.toLowerCase() as keyof ClubPatternColor

  switch (pattern) {
    case 'Laser':
      return laser[colorKey]
    case 'Loop':
      return loop[colorKey]
    case 'Ripple':
      return ripple[colorKey]
    case 'Stripes':
      return stripes[colorKey]
  }
}

export const getPremiumClubTheme = (
  curatorPattern: PremiumClubPattern,
  curatorColor: PremiumClubColor
) => {
  const colors = getClubColors(curatorColor)
  const pattern = getClubPattern(curatorPattern, curatorColor)

  return { colors, pattern }
}

export const isCareerClub = (club: Club | undefined) =>
  club?.labels?.includes('career')

export const isPremiumClub = (club: Club | undefined | null) =>
  club?.labels?.includes('premium')

export const getClubType = ({
  club,
  checkParam = false,
}: {
  club: Club | undefined
  checkParam?: boolean
}): ClubType => {
  if (checkParam) {
    const typeFromParam = getParameterByName('club_type') as ClubType
    if (!!typeFromParam) return typeFromParam
  }
  if (isPremiumClub(club)) return 'premium'
  if (isCareerClub(club)) return 'career'
  return 'free'
}

export const getClubSlug = (pathname: string) => {
  const urlSlug = getSlug(pathname)
  const clubType = getParameterByName('club_type')

  if (!!clubType) {
    const stripped = urlSlug.split('?')
    const slug = stripped.shift()
    return slug as string
  }

  return urlSlug
}

export const getClubBundleItemCount = (bundle: Bundle | undefined) =>
  bundle ? bundle.product_list.length - 1 : 0

export const isClubRegistrationClosed = (closedDate: string | undefined) =>
  closedDate ? isBefore(new Date(closedDate), new Date()) : false

export const isClubMember = (
  membership: ClubMembership | undefined,
  authenticated: boolean
) => authenticated && membership?.status === 'active'

export const isModeratorDefaultAvatar = (src: string | undefined) =>
  !!src?.includes('/default-avatars/')
