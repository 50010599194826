import { Text } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { inviteValueProps } from '../../constants'
import { InvitePageValueProp } from './InvitePageValueProp'

export const InvitePageValueProps = () => {
  const { mediaQueries } = useTheme()
  return (
    <div
      className={css`
        padding: 0 24px 36px;
      `}
    >
      <Text
        className={css`
          margin-bottom: 32px;
        `}
        type="header"
        sizing={{ base: 'S', mobileL: 'M' }}
        align="center"
        text="Get to know Fable"
      />
      <div
        className={css`
          margin: 0 auto;
          display: grid;
          max-width: 328px;
          grid-template-columns: repeat(1, 1fr);
          gap: 24px;
          ${mediaQueries.tablet} {
            max-width: 680px;
            grid-template-columns: repeat(2, 1fr);
          }
        `}
      >
        {inviteValueProps.map((item, i) => (
          <InvitePageValueProp key={i} item={item} />
        ))}
      </div>
    </div>
  )
}
