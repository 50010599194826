import { ToastActions } from '../actions'
import { ToastTypes } from '../action-types'
import { Toast } from '../../types'

enum ToastKeys {
  toastOptions = 'toastOptions',
}

interface ToastState {
  [ToastKeys.toastOptions]: Toast
}

const initialState: ToastState = {
  [ToastKeys.toastOptions]: {} as Toast,
}

const reducer = (
  state: ToastState = initialState,
  action: ToastActions
): ToastState => {
  switch (action.type) {
    // SET TOAST
    case ToastTypes.SET_TOAST:
      return {
        ...state,
        [ToastKeys.toastOptions]: action.payload,
      }

    default:
      return state
  }
}

export default reducer
