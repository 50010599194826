import { useEffect, useState } from 'react'
import { useActions } from './useActions'
import { useTypedSelector } from './useTypedSelector'

interface Props {
  status: boolean
  otherId: string
}

export const useFollowStatus = ({ status, otherId }: Props) => {
  const [isFollowing, setIsFollowing] = useState(status)
  const [updatingFollowStatus, setUpdatingFollowStatus] = useState(false)
  const { deleteFollowUser, postFollowUser } = useActions()
  const { followUser } = useTypedSelector(({ social }) => social)

  useEffect(() => {
    if (followUser.data?.user?.id === otherId && updatingFollowStatus) {
      setIsFollowing(!isFollowing)
      setUpdatingFollowStatus(false)
    }
  }, [followUser, isFollowing, otherId, updatingFollowStatus])

  const updateFollowStatus = () => {
    setUpdatingFollowStatus(true)

    return isFollowing ? deleteFollowUser(otherId) : postFollowUser(otherId)
  }

  return {
    updateFollowStatus,
    updatingFollowStatus,
    isFollowing,
  }
}
