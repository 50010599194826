import { IconSVGProps } from '../types'

export const MembersIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.114 8.75336C12.0273 9.37336 12.6673 10.2134 12.6673 11.3334V13.3334H14.6673C15.034 13.3334 15.334 13.0334 15.334 12.6667V11.3334C15.334 9.88002 12.954 9.02002 11.114 8.75336Z"
    />
    <path d="M6.0026 8.00002C7.47536 8.00002 8.66927 6.80611 8.66927 5.33335C8.66927 3.86059 7.47536 2.66669 6.0026 2.66669C4.52984 2.66669 3.33594 3.86059 3.33594 5.33335C3.33594 6.80611 4.52984 8.00002 6.0026 8.00002Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0007 8.00002C11.474 8.00002 12.6673 6.80669 12.6673 5.33335C12.6673 3.86002 11.474 2.66669 10.0007 2.66669C9.68735 2.66669 9.39401 2.73335 9.11401 2.82669C9.66735 3.51335 10.0007 4.38669 10.0007 5.33335C10.0007 6.28002 9.66735 7.15335 9.11401 7.84002C9.39401 7.93335 9.68735 8.00002 10.0007 8.00002Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0013 8.66669C4.2213 8.66669 0.667969 9.56002 0.667969 11.3334V12.6667C0.667969 13.0334 0.967969 13.3334 1.33464 13.3334H10.668C11.0346 13.3334 11.3346 13.0334 11.3346 12.6667V11.3334C11.3346 9.56002 7.7813 8.66669 6.0013 8.66669Z"
    />
  </svg>
)
