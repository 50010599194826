import { Button } from '@fable/components'
import { css } from '@fable/theme'
import { useDeeplinkModal } from 'components/app_deep_link_modal'

export const HomeFeaturesCta = () => {
  const { openDeeplinkModal } = useDeeplinkModal()

  return (
    <Button
      className={css`
        margin-top: 33px;
        width: 160px;
        height: 52px;
      `}
      onClick={() =>
        openDeeplinkModal({ hideSMS: true, skipModalOnMobile: true })
      }
    >
      Get the app
    </Button>
  )
}
