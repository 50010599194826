import { createContext, useState } from 'react'
import { SubheaderNavLink } from '../../types'
import { emptyFunction } from '../../utils/function'

export interface SubheaderProps {
  title: string
  links: SubheaderNavLink[]
  allowSearch?: boolean
}

export interface SubheaderContextProps extends SubheaderProps {
  setSubheaderContent: (content: SubheaderProps) => void
}

export const SubheaderContext = createContext<SubheaderContextProps>({
  title: '',
  links: [],
  allowSearch: false,
  setSubheaderContent: emptyFunction,
})

export const SubheaderProvider: React.FC<{
  children?: React.ReactNode | string | null
}> = ({ children }) => (
  <SubheaderContext.Provider value={useSubheaderProvider()}>
    {children}
  </SubheaderContext.Provider>
)

const useSubheaderProvider = () => {
  const [title, setTitle] = useState('')
  const [links, setLinks] = useState<SubheaderNavLink[]>([])
  const [allowSearch, setAllowSearch] = useState(false)

  const setSubheaderContent = (content: SubheaderProps) => {
    setTitle(content.title)
    setLinks(content.links)
    setAllowSearch(content.allowSearch || false)
  }

  return {
    title,
    links,
    allowSearch,
    setSubheaderContent,
  }
}
