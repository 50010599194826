import { actions } from './actions'
import { main } from './main'
import { events } from './events'
import { utm } from './utm'

export const analytics = {
  events,
  actions,
  utm,
  ...main,
}

export * from './types'
