import { Text } from '@fable/components'
import { css, cx, useTheme } from '@fable/theme'
import { Link } from 'react-scroll'
import { PageAnchor } from '../types'

export const PageDetailAnchorNavItem = ({
  item,
  title,
  ...props
}: {
  item: PageAnchor
  title: string
}) => {
  const { colors, motion, mediaQueries } = useTheme()

  if (item.hidden?.anchor) return null

  return (
    <li {...props}>
      <Link
        className={css`
          display: block;
          padding: 15px 6px;
          position: relative;
          cursor: pointer;
          color: ${colors.graphite}66;
          ${motion.transition('color')};
          &:hover {
            color: ${colors.graphite}cc;
          }
          ${mediaQueries.mobileL} {
            padding: 15px 10px;
          }
        `}
        to={item.id}
        duration={600}
        offset={-200}
        activeClass={css`
          color: ${colors.graphite};
          span.underline {
            opacity: 1;
          }
        `}
        smooth
        spy
        hashSpy
        isDynamic
      >
        <Text
          tag="span"
          className={css`
            cursor: inherit;
            color: inherit;
          `}
          type="subhead"
          sizing="XS"
        >
          {title}
        </Text>
        <span
          className={cx(
            'underline',
            css`
              display: block;
              width: calc(100% - 12px);
              height: 3px;
              border-radius: 2px;
              background-color: ${colors.graphite};
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: translate(-50%);
              pointer-events: none;
              opacity: 0;
              ${motion.transition('opacity')};
              ${mediaQueries.mobileL} {
                width: calc(100% - 20px);
              }
            `
          )}
        />
      </Link>
    </li>
  )
}
