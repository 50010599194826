import { IconSVGProps } from '../types'

export const EditAltIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.345 13.3334L13.7967 4.8817L12.6183 3.70336L4.16667 12.155V13.3334H5.345ZM6.03583 15H2.5V11.4642L12.0292 1.93503C12.1854 1.7788 12.3974 1.69104 12.6183 1.69104C12.8393 1.69104 13.0512 1.7788 13.2075 1.93503L15.565 4.29253C15.7212 4.4488 15.809 4.66073 15.809 4.8817C15.809 5.10267 15.7212 5.31459 15.565 5.47086L6.03583 15ZM2.5 16.6667H17.5V18.3334H2.5V16.6667Z" />
  </svg>
)
